const followPath = (objectToTraverse: {[key: string]: any}, fieldPath: string[]): any => {
  let currentValue = objectToTraverse;
  fieldPath.forEach((path) => {
    if (currentValue === undefined) return;
    currentValue = currentValue[path];
  });
  return currentValue;
};

const followPathAndSetValue = (
  objectToTraverse: { [key: string]: any },
  fieldPath: string[],
  value: any,
  createWhenUndefined = false,
): void => {
  let currentValue = objectToTraverse;
  fieldPath.forEach((path, idx) => {
    if (currentValue === undefined && !createWhenUndefined) return;
    if (idx === fieldPath.length - 1) {
      currentValue[path] = value;
    } else {
      currentValue[path] = currentValue[path] || {};
      currentValue = currentValue[path];
    }
  });
}

type Addressable = {
  streetAddress?: string;
  country?: string;
  city?: string;
  state?: string;
  postalCode?: string;
};

const formatSingleLineAddress = (obj: Addressable): string =>
  `${obj.streetAddress || ""}${obj.streetAddress && ", "}${formatCityStatePostal(obj)}`;

const formatCityStatePostal = (obj: Addressable): string => {
  let str = obj.city || "";
  if (obj.state) str = `${str}, ${obj.state}`;
  if (obj.postalCode) str = `${str}, ${obj.postalCode}`;
  return str;
};

export { followPath, followPathAndSetValue, formatSingleLineAddress, formatCityStatePostal };
