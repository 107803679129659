import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, Formik, FormikProps } from "formik";
import { ComponentType } from "react";
import { Review, ReviewData, ReviewStatus } from "../passport-partners.types";
import { createReview, patchReview } from "../passport-partners.api";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import { FieldTypes } from "../../forms/formik-components/formTypes";
import { DirtyChecker } from "../../../context/DirtyFormContext";

type UpsertReviewFormProps = {
  existingReview: Review;
  onSubmit?: () => void;
  renderSubmitButtons: (formikContext: FormikProps<ReviewData>) => JSX.Element;
  FormWrapper: ComponentType<{ children: JSX.Element[] }>;
  onUpsertSuccess?: () => void;
  onUpsertError?: () => void;
  setDirty?: (dirty: boolean) => void;
};

const labels = { score: "Rating", text: "Review", anonymous: "Submit review anonymously" };
const types = { score: FieldTypes.STAR_RATING, text: FieldTypes.TEXTAREA, anonymous: FieldTypes.CHECKBOX };
const placeholders = { text: "Please describe your experience with this product" };

export const UpsertReviewForm = ({
  existingReview,
  onSubmit,
  renderSubmitButtons,
  FormWrapper,
  onUpsertSuccess,
  onUpsertError,
}: UpsertReviewFormProps) => {
  const queryClient = useQueryClient();
  const upsertReviewMutation = useMutation(
    (values: ReviewData) => {
      const reviewData = { ...existingReview, ...values, reviewStatus: ReviewStatus.COMPLETED };
      if (!existingReview?.uuid) {
        return createReview(reviewData);
      }
      return patchReview(existingReview.uuid, reviewData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["passportPartnersPartners"]);
        queryClient.invalidateQueries(["passportPartnersPartnerReviews", existingReview.partnerId]);
        queryClient.invalidateQueries(["passportPartnersCompanyReviews"]);
        onUpsertSuccess?.();
      },
      onError: () => {
        onUpsertError?.();
      },
    },
  );

  return (
    <Formik
      onSubmit={(values) => {
        if (onSubmit) {
          onSubmit();
        }
        upsertReviewMutation.mutate(values);
      }}
      initialValues={
        {
          score: existingReview?.score ?? 0,
          text: existingReview?.text ?? "",
          anonymous: existingReview?.anonymous ?? false,
        } as ReviewData
      }
      validate={(values) => {
        const errors = {} as Record<keyof ReviewData, string>;
        if (!values.score) {
          errors.score = "Required";
        }
        return errors;
      }}
      validateOnMount={true}
    >
      {(formikContext) => (
        <Form>
          <FormWrapper>
            <FondoFormGroup
              formikContext={formikContext}
              fieldName="score"
              labels={labels}
              types={types}
              placeholders={placeholders}
            />
            <FondoFormGroup
              formikContext={formikContext}
              fieldName="text"
              labels={labels}
              types={types}
              placeholders={placeholders}
            />
            <FondoFormGroup
              formikContext={formikContext}
              fieldName="anonymous"
              labels={labels}
              types={types}
              placeholders={placeholders}
              checkboxType="switch"
              hideLabel={true}
            />
          </FormWrapper>
          {renderSubmitButtons(formikContext)}
          <DirtyChecker dirty={formikContext.dirty} />
        </Form>
      )}
    </Formik>
  );
};
