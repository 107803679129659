import { Form, InputGroup, Offcanvas } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Fragment, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import PurpleAIChatBotMessage from "../../../generative-ai/PurpleAIChatBotMessage";
import { askReportingAIAssistant } from "./reportingAIChatBot.api";
import { getBotTrack } from "../../../../utils/util.api";
import { BotTrack, BotTrackStatus } from "../../../../utils/util.type";
import AIAssistantLoadingState from "../../../generative-ai/AIAssistantLoadingState";
import { ReportingAIChatBotMessage } from "./reportingAIChatBot.type";

type ReportingAIChatBotProps = {
  show: boolean;
  handleClose: () => void;
  reportingUuid: string;
};

const ReportingAIChatBot = ({ show, handleClose, reportingUuid }: ReportingAIChatBotProps) => {
  const welcomeMessage = {
    message: `
      Welcome to our Financial Reporting Assistant! 
      Ask me anything about your financial report and
      I will provide you with the information you need!
    `,
    isUser: false,
  };

  const [messages, setMessages] = useState<ReportingAIChatBotMessage[]>([welcomeMessage]);
  const [chatMessage, setChatMessage] = useState("");
  const [assistantIsProcessing, setAssistantIsProcessing] = useState(false);
  // Polling bot track below
  const [currentBotTrackInstanceLoadingUuid, setCurrentBotTrackInstanceLoadingUuid] = useState("");
  const [refetchingBotTrackingInstance, setRefetchingBotTrackingInstance] = useState(false);
  const [failureCount, setFailureCount] = useState(0);
  // Using a failure limit in case the response takes a lot of time to come back.
  // Setting a limit of 20 retries. Considering that we refetch each 6 seconds, this
  // should result in waiting for 40 seconds max for each response.
  const REFETCH_TIME = 2000;
  const FAILURE_LIMIT = 20;

  const getConversationHistory = () => {
    // Remove the last message since it's the one
    // the user just sent.
    const conversationHistory = messages.slice(0, -1);
    return conversationHistory.map((message) => ({
      message: message.message,
      isUser: message.isUser,
    }));
  }

  const askReportingAIAssistantMutation = useMutation({
    mutationFn: (question: string) =>
      askReportingAIAssistant({
        question,
        conversationHistory: getConversationHistory(),
        reportingUuid,
      }),
    onSuccess: (data) => {
      setCurrentBotTrackInstanceLoadingUuid(data.botTrackUuid);
      setRefetchingBotTrackingInstance(true);
      setAssistantIsProcessing(true);
      handleScrollToBottom();
    },
  });

  const handleAsk = () => {
    setMessages([...messages, { message: chatMessage, isUser: true }]);
    askReportingAIAssistantMutation.mutate(chatMessage);
    setChatMessage("");
  };

  const addBotResponse = (message: string, botTrack: BotTrack) => {
    setMessages([...messages, { message, isUser: false, botTrack }]);
  };

  const handleSuccessBotResponse = () => {
    setAssistantIsProcessing(false);
    setRefetchingBotTrackingInstance(false);
    setCurrentBotTrackInstanceLoadingUuid("");
    setFailureCount(0);
    handleScrollToBottom();
  };

  const scrollToBottomRef = useRef<HTMLDivElement | null>(null);

  const handleScrollToBottom = () => {
    if (scrollToBottomRef.current) {
      scrollToBottomRef.current.scrollIntoView();
    }
  };

  useQuery<any, Error>(
    ["botTrackInstance", currentBotTrackInstanceLoadingUuid],
    () => getBotTrack(currentBotTrackInstanceLoadingUuid),
    {
      enabled: !!currentBotTrackInstanceLoadingUuid,
      refetchInterval: refetchingBotTrackingInstance ? REFETCH_TIME : false,
      refetchIntervalInBackground: true,
      onSuccess: (botTrack) => {
        if (botTrack.status === BotTrackStatus.SUCCESS) {
          handleSuccessBotResponse();
          let message = botTrack.processedResults;
          if (message && typeof message !== "string") {
            message = message.error;
          }
          addBotResponse(message, botTrack);
        } else if (failureCount >= FAILURE_LIMIT) {
          handleSuccessBotResponse();
          addBotResponse("Unable to process query, please try again later.", botTrack);
        } else {
          setFailureCount(failureCount + 1);
        }
      },
    },
  );

  const isPromptLoading = (): boolean => refetchingBotTrackingInstance && !!currentBotTrackInstanceLoadingUuid;

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      scroll={true}
      backdrop={false}
      className="border-start"
      style={{ width: "100%", maxWidth: "550px", marginTop: "62px" }}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="fs-3 mb-0">AI Assistant</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        <div className="p-4" style={{ marginBottom: "140px" }}>
          {messages?.map((message: any, idx: number) => (
            <Fragment key={idx}>
              <PurpleAIChatBotMessage
                message={message.message}
                isUser={message.isUser}
                botTrack={message.botTrack}
              />
            </Fragment>
          ))}
          {isPromptLoading() && <AIAssistantLoadingState />}
          <div ref={scrollToBottomRef}></div>
        </div>
        <div className="position-absolute bottom-0 w-100">
          <p className="small p-3 text-center text-muted mb-0 bg-white">
            <FeatherIcon icon="info" size="1em" className="mt-n1 me-1"/>
            Our AI-powered chatbot helps you understand your financial information,
            but always double-check details and consult your Fondo accountant before making decisions.
          </p>
          <InputGroup style={{ height: "45px" }}>
            <InputGroup.Text className="border-start-0 rounded-0">
              <FeatherIcon icon="cpu" size="1.5em" />
            </InputGroup.Text>
            <Form.Control
              onChange={(event) => setChatMessage(event.target.value)}
              onKeyDown={(event) => {
                if (event.code === "Enter" && !assistantIsProcessing) handleAsk();
              }}
              value={chatMessage}
              placeholder={"Ask AI"}
              aria-label="ask-ai"
              aria-describedby="ask-ai"
              className="text-gray ps-2"
            />
            <InputGroup.Text
              onClick={() => !assistantIsProcessing && handleAsk()}
              className="border-start-0 rounded-0"
              style={{ cursor: "pointer" }}
            >
              <FeatherIcon icon="send" size="1.2em" />
            </InputGroup.Text>
          </InputGroup>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ReportingAIChatBot;
