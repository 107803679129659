import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useLocation } from "react-router-dom";
import fondoLogo from "../../assets/img/fondo-icon.png";
import { getFlowTracking } from "../onboarding/onboarding.api";
import { LoggedInUserContext } from "../../context/LoggedInUserContext";

export interface ProgressTimelineProps {
  currentStep: string;
  hideTimeline: boolean;
  timeLineItems: { [key:string]: string }[];
  inlineStep?: boolean
  formatCompletedSteps?: boolean
}

const ProgressTimeline = (
  {
    currentStep,
    hideTimeline,
    timeLineItems,
    inlineStep = false,
    formatCompletedSteps = false,
  }: ProgressTimelineProps,
) => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const { currentCompanyId } = useContext(LoggedInUserContext);

  let step = "";
  if (
    ["signup", "product", "checkout", "user", "company", "tax-plan", "connections"].includes(currentStep)
  ) {
    step = currentStep;
  } else {
    step = currentStep.substring(0, currentStep.length - 1);
  }
  let progressTimelineClassName = "js-step-progress step step-sm step-icon-sm step-item-between mb-3 mb-md-5 ";
  if (inlineStep) progressTimelineClassName += "step-inline";

  const [completedSteps, setCompletedSteps] = useState<string[]| null>(null);
  let onboardingFlow = "";
  if (path?.includes("dashboard-onboarding")) onboardingFlow = "dashboard-onboarding";
  else if (path?.includes("onboarding")) onboardingFlow = "onboarding";
  useQuery<any, Error>(
    ["flowTrackingSteps"],
    () => getFlowTracking(onboardingFlow),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          // "user" and "signup" are not included in the steps,
          // but if the user reaches this page, it means that part was already completed.
          setCompletedSteps(["user", "signup", ...data]);
        }
      },
      enabled: !!onboardingFlow && !!currentCompanyId,
    },
  );

  const getItemBackground = (key: string) => {
    const className = "step-icon";
    if (completedSteps?.includes(key) && formatCompletedSteps) {
      return `${className} step-icon-success`;
    }
    return `${className} step-icon-soft-dark`;
  };

  const getItemText = (key: string, num: string) => {
    if (completedSteps?.includes(key) && formatCompletedSteps) {
      return (<FeatherIcon icon="check" size="1em" />);
    }
    return num;
  };

  const navbarLogoProps: { [key:string]: string } = {
    className: "navbar-brand mr-auto",
  };
  if (currentCompanyId) navbarLogoProps.href = "/";

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container justify-content-center">
          <a {...navbarLogoProps}>
            <img src={fondoLogo} alt="Fondo" className="navbar-brand-img" />
          </a>
        </div>
      </nav>
      {
        !hideTimeline
        && <div className="row justify-content-lg-center py-md-5 mt-5">
          <div className="col-lg-8">
            <ul className={progressTimelineClassName}>
              {timeLineItems.map((obj) => (
                <li key={obj.key} className={`step-item ${step === obj.key ? "is-valid" : ""}`}>
                  <a className=" step-content-wrapper">
                    <span className={getItemBackground(obj.key)}>
                      {getItemText(obj.key, obj.number)}
                    </span>
                    <div className="step-content">
                      <span className="step-title">{obj.title}</span>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      }
      {hideTimeline && <div className="row py-md-5 "></div>}
    </>
  );
};

export default ProgressTimeline;
