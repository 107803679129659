import { Subdivision } from "../../utils/util.type";
import { InstitutionSource } from "../integrations/integrations.type";

export type BalanceAccount = {
  uuid?: string;
  account: AccountInfo;
  date: string;
  balance: string;
};

export type AccountInfo = {
  uuid?: string;
  bankName: string;
  name: string;
  type?: string;
  subtype?: string;
  validItem: boolean;
  source: InstitutionSource;
  needsHistoricalEndingBalances?: boolean;
  isInvestmentType?: boolean;
  mask?: string;
}

export type BurnRateMonth = {
  avgBurnRate: number;
  burnRate: number;
  cashZeroDate: string;
  month: string;
  period: string;
  runway?: string;
}

export type BurnRateResponse = BurnRateMonth[];

export type BalanceAccountChartData = BalanceAccount & {
  others: boolean;
  referenceColor: string;
};

export type CashBalanceResponse = {
  cashBalance: number;
  hasInvalidItems: boolean;
  balances: BalanceAccountChartData[];
  latestCashBalanceDate: string;
}

export type CashBalanceChartData = CashBalanceResponse & {
  graphDisplayAccounts: BalanceAccountChartData[];
  balances: BalanceAccountChartData[];
}

export interface CashBalanceOverTimeAccount {
  [key: string]: BalanceAccount[];
}

export type CashBalanceOverTimeResponse = CashBalanceOverTimeAccount & {
  cashBalance: number;
  hasInvalidItems: boolean;
}

export type Transaction = {
  displayAmount: number;
  date: string;
  description: string;
  manuallyEnteredCategory: string;
  uuid: string;
  source?: string;
  aiVerificationStatus: TransactionAIVerificationStatus;
}

export enum TransactionCategory {
  REVENUE = "REVENUE",
  INVESTMENT = "INVESTMENT",
  TRANSFER = "TRANSFER",
  OTHER = "OTHER",
  VERIFY = "VERIFY",
}

export type ProfitAndLossPeriod = {
  period: string,
  revenueAmount: number,
  expensesAmount: number,
}

export type GrowthRatePeriod = {
  period: string,
  revenueAmount: number,
  growthRate: number,
}

export type CompoundGrowthRatePeriod = {
  period: string,
  revenueAmount: number,
  compoundGrowthRate: number,
}

export type FundingPeriod = {
  period: string,
  fundingAmount: number,
}

export type FundingData = {
  totalFunding: number;
  fundingHistory: FundingPeriod[];
  amountOfAccounts: number;
}

export type Jurisdiction = {
  city?: string;
  name: string;
  subdivisionCode: string;
  subdivisionId: number;
  uuid: string;
}

export type FilingType = {
  name: string;
}

export type Filing = {
  filingTypes: FilingType[];
  jurisdiction: Jurisdiction;
  name: string;
  taxYear: number;
  uuid: string;
  verified: boolean;
  verifiedAt: string;
  verifiedBy: string;
}

export type FilingDeadline = {
  dueDate: string;
  endDate: string;
  filing: Filing;
  startDate: string;
  uuid: string;
  verified: boolean;
  verifiedAt: string;
  verifiedBy: string;
}

export type CompanyFilingsDeadline = {
  accepted: false;
  acceptedOn: string;
  amount: string;
  date: string;
  endDate: string;
  exactAmount: boolean;
  exactTaxCreditAmount: boolean;
  filingDeadline: FilingDeadline;
  startDate: string;
  taxCreditAmount: string;
  uuid: string;
  zeroDue: boolean;
}

export type CompanyFilingsDeadlinesPaginatedResponse = {
  count: number;
  next?: string;
  previous?: string;
  results: CompanyFilingsDeadline[];
  totalPages: number;
}

export type InternationalActivity = {
  foreignShareholders: number;
  foreignBankAccounts: number;
  internationalSubsidiaries: number;
};

export const mapTransactionCategoryToDropdownLabel: {
  [key in TransactionCategory]: string
} = {
  REVENUE: "Revenue",
  INVESTMENT: "Investment",
  TRANSFER: "Transfer",
  OTHER: "Other",
  VERIFY: "Verify",
};

export const mapTransactionCategoryToColor: {
  [key in TransactionCategory]: string;
} = {
  REVENUE: "primary",
  INVESTMENT: "warning",
  TRANSFER: "info",
  OTHER: "secondary",
  VERIFY: "danger",
};

export type NetIncomeDetail = {
  currentPeriodNetIncome?: number;
  percentageChangeFromLastPeriod?: number;
}

export type Payroll = {
  uuid: string;
  taxYear: number;
  states: Subdivision[];
  totalEstimated?: string;
  estimatedPercentThatIsTechnical?: number;
  section174Expenses?: string;
  taxableIncome?: string;
  income?: string;
  expenses?: string;
  assets?: string;
  equityAndLiabilities?: string;
  netIncomeDetail?: NetIncomeDetail;
  rDCreditEstimateAmount: string;
  taxesOwedEstimateAmount: string;
  totalEstimatedUsPdRelatedPayroll: string;
  totalEstimatedInternationalPdRelatedPayroll: string;
  numberOfForeignBankAccounts: number;
  numberOfForeignShareholders: number;
  numberOfInternationalSubsidiaries: number;
};

export type PayrollManualConnectionFormValues = {
  taxYear: string;
  states: Subdivision[];
  totalEstimatedUsPdRelatedPayroll: number|string;
  totalEstimatedInternationalPdRelatedPayroll: number|string;
  numberOfForeignBankAccounts: number;
  numberOfForeignShareholders: number;
  numberOfInternationalSubsidiaries: number;
  income: number;
  expenses: number;
  assets: number;
};

export type PayrollManualConnectionFormValuesToSubmit = {
  taxYear: string;
  states: number[];
  totalEstimatedUsPdRelatedPayroll: number|string;
  totalEstimatedInternationalPdRelatedPayroll: number|string;
  numberOfForeignBankAccounts: number|string;
  numberOfForeignShareholders: number|string;
  income: number|string;
  expenses: number|string;
  assets: number|string;
};

export enum TransactionOrdering {
  DATE = "date",
  REVERSED_DATE = "-date",
  AMOUNT = "amount",
  REVERSED_AMOUNT = "-amount",
  SOURCE = "source",
  REVERSED_SOURCE = "-source",
  CATEGORY = "manually_entered_category",
  REVERSED_CATEGORY = "-manually_entered_category",
  AI_VERIFICATION_STATUS = "ai_verification_status",
  REVERSED_AI_VERIFICATION_STATUS = "-ai_verification_status"
}

export const mapTransactionCategoryToHexColor: {
  [key in TransactionCategory]: string;
} = {
  REVENUE: "#007BFF", // Hexadecimal value for "primary" class
  INVESTMENT: "#FFC107", // Hexadecimal value for "warning" class
  TRANSFER: "#17A2B8", // Hexadecimal value for "info" class
  OTHER: "#6C757D", // Hexadecimal value for "secondary" class
  VERIFY: "#DC3545", // Hexadecimal value for "danger" class
};

export type TransactionsStats = {
  allCount: number;
  revenueCount: number;
  investmentCount: number;
  transferCount: number;
  otherCount: number;
  verifyCount: number;
};

export type DateFilterTypes = {
  startDateValue: string|undefined,
  endDateValue: string|undefined,
  last30DaysValue: boolean|undefined,
  last6MonthsValue: boolean|undefined,
  lastYearValue: boolean|undefined
};

export type EndingBalanceUpdated = {
  uuid?: string;
  manuallyEditedBalance: string;
  accountName?: string;
  institutionName?: string;
}

export type NewBankAccount = {
  accountName: string;
  institutionUuid?: string | null;
  institutionName: string;
  selectedMonthEndingBalance: string;
  selectedYearAndMonth: string;
  accountCreatedAt: string;
};

export type BalancesPayloadToSubmit = {
  endingBalancesUpdated: EndingBalanceUpdated[],
  bankAccountsToAdd: NewBankAccount[],
}

export type FinancialHubInstitution = {
  uuid: string;
  name: string;
}

export type DateRangeIntervals = {
  months: string[],
  quarters: string[],
  years: string[],
};

export type TimeRange = {
  interval: string;
  year?: string;
  timePeriod?: string; // Month or Quarter selected
  cycles?: string[]; // List of Months/Quarters to show in the dropdown
};

export type EndingBalancePayload = {
  accountUuid: string,
  accountOpenedOn: string,
  endingBalances: {[key: string]: string}
};


export enum TransactionAIVerificationStatus {
  AI_INCORRECT_VERIFICATION = "AI_INCORRECT_VERIFICATION",
  AI_VERIFYING = "AI_VERIFYING",
  AI_VERIFIED = "AI_VERIFIED",
  NOT_VERIFIED_BY_AI = "NOT_VERIFIED_BY_AI",
  AI_VERIFICATION_ERROR = "AI_VERIFICATION_ERROR",
}

export const mapTransactionAIVerificationStatusToLabel: {
  [key in TransactionAIVerificationStatus]: string
} = {
  AI_INCORRECT_VERIFICATION: "AI Incorrect Verification",
  AI_VERIFYING: "AI Verifying",
  AI_VERIFIED: "AI Verified",
  NOT_VERIFIED_BY_AI: "Not verified by AI",
  AI_VERIFICATION_ERROR: "AI verification error",
};

export const mapTransactionAIVerificationStatusToColor: {
  [key in TransactionAIVerificationStatus]: string;
} = {
  AI_INCORRECT_VERIFICATION: "danger",
  AI_VERIFYING: "primary",
  AI_VERIFIED: "success",
  NOT_VERIFIED_BY_AI: "secondary",
  AI_VERIFICATION_ERROR: "warning",
};