// Mutations

import { LOGOUT } from "../../constants/network-calls";
import { postFondoApi } from "../../utils/util.api";

export async function logoutApi() {
  const res = await postFondoApi(LOGOUT, {}, {}, { ignoreReactifyApi: true });
  return res;
}

// Queries
