import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Alert, Badge, Spinner } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { getInstitutions } from "./integrations.api";
import {
  Institution,
  InstitutionSource,
  mapAccountStatusToColor,
  mapAccountStatusToLabel,
  MigrationToPlaidStatus,
} from "./integrations.type";
import DeleteTrashButton from "../fusion-kit/DeleteTrashButton";
import { API_INTEGRATION_ITEMS } from "../../constants/network-calls";
import AccountActionDropdown from "./AccountActionsDropdown";
import PlaidConnectionButton from "./PlaidConnectionButton";

const BankAccountList = () => {
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [numberOfAccounts, setNumberOfAccounts] = useState<number>(0);
  const institutionsData = useQuery<any, Error>(["itemAccounts"], getInstitutions, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setInstitutions(data);
      const successNumberOfAccounts = data.reduce(
        (accumulator: number, currentValue: Institution) => accumulator + currentValue.accounts.length,
        0,
      );
      setNumberOfAccounts(successNumberOfAccounts);
    },
  });

  if (institutionsData.isFetching) {
    return (
      <div className="row justify-content-center">
        <Spinner className="text-primary my-4"/>
      </div>
    );
  }

  return (
    <>
      <div className="mb-3">
        <ul className="list-group list-group-flush">
          {
            <li
              className="list-group-item d-flex align-items-center justify-content-between"
            >
              <span>{numberOfAccounts ? "Connected" : "Not Connected"}</span>
              <FeatherIcon
                icon={numberOfAccounts ? "check-circle" : "x"}
                className={numberOfAccounts ? "text-success" : "text-danger"}
                size="1.1em"
              />
            </li>
          }
          <li className="list-group-item d-flex align-items-center justify-content-between px-0">
            <span>Accounts</span>
            <span>
              {numberOfAccounts}{" "}
              {numberOfAccounts === 1 ? "Account" : "Accounts"}
            </span>
          </li>
        </ul>
      </div>
      <div>
        {institutions?.length > 0 && institutions.map((itemAccount: Institution, idx: number) => (
          <div key={`item-${itemAccount.name}-${idx}`}>
            <div
              className="font-weight-bold mb-2 d-flex justify-content-between"
              style={{ fontWeight: "bold" }}
            >
              <span
                className="font-weight-bold mb-1"
                style={{ fontWeight: "bold" }}
              >
                {itemAccount.name}
                {itemAccount.source === InstitutionSource.MANUAL && (
                  <Badge
                    bg="light"
                    className="text-secondary ms-2"
                  >
                    Manually Added
                  </Badge>
                )}
              </span>
              {(!itemAccount.validLink || !itemAccount.accounts.length) && (
                <DeleteTrashButton
                  id={itemAccount.uuid}
                  deleteURL={API_INTEGRATION_ITEMS}
                  queryKey={["itemAccounts"]}
                  confirmationModalBodyText="Remove this connection along with
                  all associated accounts"
                  askConfirmation={true}
                />
              )}
            </div>
            {(!itemAccount.validLink || !itemAccount.accounts.length
              || itemAccount.migrationToPlaidStatus === MigrationToPlaidStatus.RECONNECTION_NEEDED
            ) && (
              <PlaidConnectionButton
                buttonContent="It looks like we're having issues connecting to this account and will need
                  you to re-enter your credentials. Click here to do so."
                className="btn btn-block btn-danger full-width p-3 text-white text-start"
                accessToken={
                  itemAccount.migrationToPlaidStatus === MigrationToPlaidStatus.RECONNECTION_NEEDED ? undefined
                  : itemAccount.item?.accessToken
                }
              />
            )}
            {itemAccount.migrationToPlaidStatus === MigrationToPlaidStatus.INSTITUTION_NOT_SUPPORTED_BY_PLAID && (
              <Alert variant="warning" className="px-3 mb-0">
                <b>Important Update: Bank Institution Support</b>
                <p>Due to recent updates and improvements in our app, your connected bank institution,
                  <b> {itemAccount.name}</b>, is no longer supported.
                  Please contact our support team for further assistance.
                </p>
              </Alert>
            )}
            <table
              className={`
                table table-sm position-relative ${itemAccount.validLink ? "" : "invalid-link"}
              `}
            >
              <thead>
                <tr>
                  <th scope="col">Account</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {itemAccount.accounts?.length > 0 && itemAccount.accounts.map((account) => (
                  <tr key={`${account.name}-index`}>
                    <th style={{"width": "50%"}}>
                      {account.name + (account.mask ? ` - ${account.mask}` : "")}
                    </th>
                    <td>
                      {(
                        <Badge bg={mapAccountStatusToColor[account.status]}>
                          {mapAccountStatusToLabel[account.status]}
                        </Badge>
                      )}
                    </td>
                    <td className="text-end">
                      <AccountActionDropdown
                        accountUuid={account.uuid}
                        accountStatus={account.status}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </>
  );
};

export default BankAccountList;
