import { Navigate, useLocation, matchPath } from "react-router-dom";
import { PropsWithChildren } from "react";
import { cookies } from "../App";
import { PDF_COOKIE_NAME, PUBLIC_ROUTES, SESSION_COOKIE_NAME } from "../../constants/general";
import { REPORT_PDF } from "../../constants/network-calls";
import { matchPathInArray } from "../../utils/common.util";

export default function Protected(props: PropsWithChildren) {
  const location = useLocation();
  const sessionCookie = cookies.get(SESSION_COOKIE_NAME);
  const pdfToken = cookies.get(PDF_COOKIE_NAME);

  if ((pdfToken && matchPath(REPORT_PDF, location.pathname) != null)
     || sessionCookie || matchPathInArray(PUBLIC_ROUTES, location.pathname)) {
    return <>{props.children}</>;
  }

  return <Navigate to="/login" />;
}
