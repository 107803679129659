import ReactPlayer from "react-player";
import {
  ProgressBar, Alert, Card, Row, Col, Badge,
} from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import FeatherIcon from "feather-icons-react";
import { useContext, useState } from "react";
import FondoLayout from "../fusion-kit/FondoLayout";
import { ActionItemsData, ActionItemsUserServicesData } from "./actionItems.type";
import ActionItemsOnboardingGuidedViewCard from "./ActionItemsOnboardingGuidedViewCard";
import ActionItemsOnboardingListView from "./ActionItemsOnboardingListView";
import caret from "../../assets/img/caret.svg";
import writinghand from "../../assets/img/icons/writingHand.png";
import { CompanyLevelSettingsContext } from "../../context/FeatureFlagsContext";
import SimpleFeaturedPartnerBanner from "../passport-partners-marketplace/partners/SimpleFeaturedPartnerBanner";
import { getFeaturedPartner } from "../passport-partners-marketplace/passport-partners.api";

type ActionItemsOnboardingProps = {
  actionItemsData: ActionItemsData;
  actionItemsUserServicesData: ActionItemsUserServicesData;
  actionItemsUserServicesDataIsLoading: boolean;
};

const FondoActionItemsOnboarding = ({
  actionItemsData,
  actionItemsUserServicesData,
  actionItemsUserServicesDataIsLoading,
}: ActionItemsOnboardingProps) => {
  const [listView, setListView] = useState(false);

  const companyLevelSettings = useContext(CompanyLevelSettingsContext);
  const showActivationHeader = companyLevelSettings.showActionItemOnboardingHeaderFeatureFlag;

  const calculatePercentageOfActionItemsCompletion = () => {
    const percentage = (1 - actionItemsData.numberOfPendingActionItems / actionItemsData.numberOfActionItems) * 100
      || 0;
    return Math.round(percentage);
  };

  const [headerOpen, setHeaderOpen] = useState(calculatePercentageOfActionItemsCompletion() === 0);
  const { data: featuredPartner } = useQuery(
    ["featuredPartner"],
    getFeaturedPartner,
    {
      enabled: companyLevelSettings.showActionItemsRhoBanner,
      refetchOnWindowFocus: false
    }
  );

  return (
    <FondoLayout
      title="Action Items"
    >
      {showActivationHeader && <Alert variant="light" className="text-secondary">
        <FeatherIcon icon='info' color="secondary" />
        <span className="ms-3 align-middle">
          Complete signup and activate your account and <span className="fw-bold">receive $500 off your bill!</span>
        </span>
      </Alert>}
      {companyLevelSettings.showActionItemsRhoBanner && featuredPartner && (
        <SimpleFeaturedPartnerBanner partner={featuredPartner} />
      )}
      <Card className="mb-4">
        <Row className="g-0 m-0">
          <Card.Body
            className="p-0 col-lg-8 col-md-12"
            style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
          >
            {headerOpen && <div className="p-5">
              <Row className="fs-5 text-xl-end mb-5">
                <Col>
                  <img src={writinghand}
                    className="pe-4"
                    alt="hand-writing"
                    style={{ height: "70px" }}
                  />
                </Col>
                <Col sm={10} md={10} lg={10} className="text-start">
                  <Card.Title as="h1" className="mb-4">
                    We need a little information from you so we can get started
                  </Card.Title>
                  <Card.Text as="h3" className="fw-normal text-secondary mb-3">
                    Please complete onboarding below to activate your account.
                    Fondo cannot begin work until your account is activated.
                  </Card.Text>
                </Col>
                <Col>
                  <span className="pe-1" onClick={() => setHeaderOpen(false)}>
                    <img
                      src={caret}
                      alt="icon"
                      style={{
                        fill: "#57657D",
                        transform: "rotate(180deg)",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </Col>
              </Row>
              <div className="d-flex justify-content-center bg-dark mx-0">
                <ReactPlayer
                  url='https://upload-image-production.s3.us-east-2.amazonaws.com/videos/action_item_welcome.mp4'
                  controls={true}
                  // ReactPlayer seems to work best with a 16:9 aspect ratio`
                  // Changes to the width and height should keep this ratio
                  width={1024}
                  height={576}
                />
              </div>
            </div>}
            <div className={`px-5 pb-3 ${headerOpen ? "pt-2" : "pt-5"}`}>
              <Row className="fs-5 text-xl-end mb-2">
                <Col>
                  <h4 className="fw-normal text-muted text-start">
                    ONBOARDING PROGRESS
                  </h4>
                </Col>
                <Col sm={8} md={8} lg={8}>
                  <div className="d-flex justify-content-end">
                    <h3 className="pe-3">
                      <Badge bg={`${actionItemsData.activated ? "success-soft" : "danger-soft"}`} >
                        {actionItemsData.activated ? "Account: Activated" : "Account: Inactive"}
                      </Badge>
                    </h3>
                    <h3 className="pe-3">
                      <Badge bg={`${actionItemsData.depositStatus ? "success-soft" : "danger-soft"}`}>
                        {actionItemsData.depositStatus ? "Deposit: Paid" : "Deposit: Unpaid"}
                      </Badge>
                    </h3>
                    {!headerOpen && <span className="pe-1" onClick={() => setHeaderOpen(true)}>
                      <img
                        src={caret}
                        alt="icon"
                        style={{ fill: "#57657D", height: "20px", cursor: "pointer" }}
                      />
                    </span>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h3 fw-normal">{calculatePercentageOfActionItemsCompletion()}%</p>
                </Col>
                <Col sm={11} md={11} lg={11}>
                  <ProgressBar
                    now={calculatePercentageOfActionItemsCompletion()}
                    className="mt-2"
                    style={{ height: "10px" }}
                  />
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Row>
      </Card>

      {listView
        ? <ActionItemsOnboardingListView
          actionItemsUserServicesData={actionItemsUserServicesData}
          switchToGuidedView={() => setListView(false)}
        /> : <ActionItemsOnboardingGuidedViewCard
          actionItemsUserServicesData={actionItemsUserServicesData}
          switchToListView={() => setListView(true)}
          actionItemsUserServicesDataIsLoading={actionItemsUserServicesDataIsLoading}
        />}
    </FondoLayout>
  );
};

export default FondoActionItemsOnboarding;
