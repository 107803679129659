import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CONNECT_PAYROLL_MANUALLY } from "../../../constants/network-calls";
import addPayrollDataIcon from "./icons/addPayrollData.png";

const AddPayrollDataAlert = () => {
  const navigate = useNavigate();
  const darkBlueGradientStyle = {
    background: "linear-gradient(99deg, #293C67 -22.16%, #505E80 183.52%), #1F4290",
  };

  return (
    <>
      <div style={darkBlueGradientStyle} className="rounded text-white p-4 d-flex align-items-center">
        <div>
          <img src={addPayrollDataIcon} width="80px" />
        </div>
        <div className="ms-3">
          <p className="text-warning fs-2 mb-1">
            Please update your TaxPlan data to activate your TaxPlan
          </p>
        </div>
        <div className="ms-auto align-self-end">
          <Button
            size="sm"
            onClick={() => navigate(`/${CONNECT_PAYROLL_MANUALLY}`)}
          >
            Add TaxPlan Data
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddPayrollDataAlert;
