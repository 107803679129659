import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";
import { AxiosError } from "axios";
import {
  ErrorMessage, Field, Form, Formik,
} from "formik";
import * as Yup from "yup";
import humps from "humps";
import {
  FORM_ERROR_MSG,
} from "../../constants/general";
import fondoLogo from "../../assets/img/fondo-icon.png";
import { resetPasswordConfirm } from "./passwordResetConfirm.api";
import { PasswordResetConfirmType } from "./passwordResetConfirm.type";

export default function PasswordResetConfirm() {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const passwordResetConfirmMutation = useMutation<any, AxiosError<any>, PasswordResetConfirmType>((
    data,
  ) => resetPasswordConfirm(uid || "", token || "", data));

  function handleSignInClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    navigate("/dashboard");
  }

  return (
        <div className="d-flex row vh-100 justify-content-center">
            <div className="d-flex flex-column col-12 col-md-6 col-xl-6 justify-content-center">
                <div className="row mb-5">
                    <div className="d-flex col justify-content-center">
                        <img className="logo-img" src={fondoLogo} />
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-3" />
                    <div className="d-flex col-6 justify-content-center">
                        <h1 className="display-4 mb-3 text-center">Password Reset</h1>
                    </div>
                    <div className="col-3" />
                </div>

                <div className="row ">
                    <div className="col-3" />
                    <div className="d-flex col-6 justify-content-center">
                        <p className="text-muted mb-5 text-center">Enter your new password</p>
                    </div>
                    <div className="col-3" />
                </div>

                <Formik
                    initialValues={{
                      newPassword1: "",
                      newPassword2: "",
                    }}
                    validationSchema={Yup.object({
                      newPassword1: Yup.string().min(
                        8,
                        "Length must be at least 8 characters",
                      ).required(FORM_ERROR_MSG.REQUIRED_FIELD),
                      newPassword2: Yup.string()
                        .oneOf([Yup.ref("newPassword1"), null], "Passwords must match")
                        .min(8, "Length must be at least 8 characters")
                        .required("Confirm password is required"),
                    })}
                    onSubmit={(values, {
                      setStatus, setFieldError,
                    }) => {
                      setSubmitting(true);
                      setStatus("");
                      passwordResetConfirmMutation.mutate(values, {
                        onSuccess: () => {
                          setSubmitting(false);
                          navigate("/login");
                        },
                        onError: (err) => {
                          setSubmitting(false);
                          const res = err?.response?.data;
                          const errorsList: string[] = res.newPassword2
                          if (errorsList) {
                            const nonFieldErrors = `${errorsList.join("\n")} Please try again!`;
                            setStatus({ error: nonFieldErrors });
                          }
                        },
                      });
                    }}>
                    {(props) => (
                      <div className="row justify-content-center">
                          <div className="col-12 col-lg-10 col-xl-8">
                              <Form className="my-1 mx-3">
                                  <div className="row">
                                      <div className="d-flex col justify-content-center align-items-center">
                                          {props.status
                                            && props.status.error
                                            && <div className="text-center text-danger">{props.status.error}</div>}
                                      </div>
                                  </div>
                                  <div className="row mb-3 mt-2">
                                      <div className="col-12 col-md-12">
                                          <label htmlFor="newPassword1">New Password</label>
                                          <Field
                                            placeholder={"New Password"}
                                            type="password"
                                            name="newPassword1"
                                            className={"form-control"}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            className="text-danger mt-1"
                                            name="newPassword1"
                                          />
                                      </div>
                                  </div>
                                  <div className="row mb-3 mt-2">
                                      <div className="col-12 col-md-12">
                                          <label htmlFor="newPassword2">Confirm Password</label>
                                          <Field
                                            placeholder={"Re-enter Password"}
                                            type="password"
                                            name="newPassword2"
                                            className={"form-control"}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            className="text-danger mt-1"
                                            name="newPassword2"
                                          />
                                      </div>
                                  </div>

                                  <div className="row my-4">
                                      <div className="col d-grid">
                                          <button
                                            type="submit"
                                            className="btn btn-primary btn-lg"
                                            disabled={submitting}
                                          >
                                            {submitting ? "Resetting" : "Reset Password"}
                                          </button>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="d-flex flex-row col justify-content-center">
                                          <small className="text-muted">
                                              Remember your password?{" "}
                                              <a href="#" onClick={(e) => handleSignInClick(e)}>
                                                  Sign in
                                              </a>
                                          </small>
                                      </div>
                                  </div>
                              </Form>
                          </div>
                      </div>
                    )}
                </Formik>
            </div>
        </div>
  );
}
