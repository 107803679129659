import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { AsyncSelectItem } from "./asyncSelectors.type";
import { getApiClient } from "../../utils/util.api";
import { Components } from "../../types/openapi.d";

type ConnectionType = Components.Schemas.ConnectionType;

type AsyncExternalConnectionTypeSelectParams = {
  handleSelection: (selected: AsyncSelectItem[]) => any;
  multiple: boolean;
  selected: AsyncSelectItem[];
  widgetClassName?: string;
  widgetStyle?: object;
  widgetSize?: "sm" | "lg";
  isInvalid?: boolean;
  allowNew?: boolean;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
};

const AsyncExternalConnectionTypeSelect = ({
  selected,
  multiple,
  handleSelection,
  widgetClassName,
  widgetStyle,
  widgetSize,
  isInvalid,
  allowNew = false,
  onBlur,
}: AsyncExternalConnectionTypeSelectParams) => {
  const [currentSearch, setCurrentSearch] = useState("");
  const externalConnectionTypesSearch = useQuery(
    ["externalConnectionTypeSearch", currentSearch],
    async (): Promise<AsyncSelectItem[]> => {
      const apiClient = await getApiClient();
      const response = await apiClient.listReactifyExternalConnectionsConnectionTypes(currentSearch);
      return response.data.map((institution: ConnectionType) => ({
        id: institution.id,
        label: institution.name,
      }));
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  return (
    <AsyncTypeahead
      id="external-connection-type-select"
      style={widgetStyle}
      size={widgetSize}
      className={widgetClassName}
      isLoading={externalConnectionTypesSearch.isLoading}
      minLength={0}
      onSearch={setCurrentSearch}
      options={externalConnectionTypesSearch.data?.length ? externalConnectionTypesSearch.data : []}
      placeholder="Search by services offered"
      selected={selected}
      onChange={(newSelected: any[]) => handleSelection(newSelected)}
      multiple={multiple}
      isInvalid={isInvalid}
      allowNew={allowNew}
      onBlur={onBlur}
    />
  );
};

export default AsyncExternalConnectionTypeSelect;
