import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";

export interface DoubleContentCardProps {
  primaryTitle: string;
  primaryText?: string|React.ReactNode;
  secondaryTitle?: string;
  secondaryText?: string|React.ReactNode;
  icon: string;
  loading?: boolean;
  inactive?: boolean;
  buttonUrl?: string;
  buttonText?: string;
  showButton?: boolean;
}

const DoubleContentCard = ({
  primaryTitle,
  primaryText,
  secondaryTitle,
  secondaryText,
  icon,
  loading,
  inactive,
  buttonUrl,
  buttonText,
  showButton = true,
}: DoubleContentCardProps) => {
  if (loading) {
    return (
      <Card className="rounded">
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <h6 className="text-uppercase text-muted mb-2">
                {primaryTitle}
              </h6>
              {loading && (
                <Spinner className="mt-3" animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </Col>
            <Col className="col-auto">
              <img src={icon} style={ { filter: "invert(1)" } } width="25px"/>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  if (inactive) {
    return (
      <Card className="rounded">
        <Card.Body>
          <Row className="align-items-center">
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="text-uppercase text-muted mb-2">
                  {primaryTitle}
                </h5>
                <p className="fs-5 text-muted">
                  No bank connected
                </p>
              </div>
              <div>
                <img src={icon} style={ { filter: "invert(1)" } } width="25px"/>
              </div>
            </div>
          </Row>
          <Row>
            <hr />
            <h5 className="text-uppercase text-muted mb-2">
              {secondaryText}
            </h5>
            <Link to={buttonUrl || ""}>
              <Button className="mt-3" variant="primary" size="sm">
                {buttonText}
              </Button>
            </Link>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="rounded">
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">
              {primaryTitle}
            </h6>
            <span className="h2 mb-0">
              {primaryText}
            </span>
          </Col>
          <Col className="col-auto">
            <img src={icon} style={ { filter: "invert(1)" } } width="25px"/>
          </Col>
        </Row>
        <hr />
        <h6 className="text-uppercase text-muted mb-2">
          {secondaryTitle}
        </h6>
        <span className="h3 mb-0">
          {secondaryText}
        </span>
        { showButton && (
          <Row className="align-items-end mt-n4">
            <Col className="d-inline-flex flex-row-reverse">
              <Link to={(loading || inactive) ? "/dashboard/integrations" : buttonUrl || ""}>
                <Button className="mt-1 ms-3" variant="primary" size="sm">
                  {(loading || inactive) ? "Activate" : buttonText}
                </Button>
              </Link>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default DoubleContentCard;
