export const loadingStatePhrases = [
  "Unleashing the power of AI...",
  "Analyzing data for the best response...",
  "Exploring the digital realms for answers...",
  "Evaluating possibilities in the data universe...",
  "Connecting with the AI brain for your query...",
  "Loading knowledge nodes, just a moment...",
  "Processing your question, almost there...",
  "Transforming your query into wisdom...",
  "Synthesizing information for clarity...",
];
