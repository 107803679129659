import { Col, Container, Row } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

type PassportSortableItemProps = { children: React.ReactNode; id: string; className?: string; };
export const PassportSortableItem = ({ children, id, className }: PassportSortableItemProps) => {
  const {
    attributes, listeners, setNodeRef, transform, transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Container ref={setNodeRef} style={style} fluid>
      <Row className="flex-nowrap">
        <Col className={className}>
          {children}
        </Col>
        <Col xs={1} className="d-flex flex-column justify-content-center p-0 ms-auto">
          <a {...attributes} {...listeners} className="cursor-grab text-grey">
            <FeatherIcon icon="align-justify" size="1.1em" strokeWidth="1.5px" />
          </a>
        </Col>
      </Row>
    </Container>
  );
};
