import { COMPANY_PAYMENT_INFO, USER, CURRENT_USER_COMPANY, COMPANY_LOGO_IMAGE } from "../../constants/network-calls";
import { UserCompaniesResponse } from "../side-nav/sidenav.type";
import { CompanyPaymentInfo, CustomerLogo, User, UserCompany } from "./settings.type";
import { getFondoApi, postFondoApi, putFondoApi } from "../../utils/util.api";
import { PresignedURLResponse } from "../../types/PreSignedUrl";

export async function getUser(): Promise<User> {
  const result = await getFondoApi(USER);
  return result as Promise<User>;
}

export async function getUserCompany(): Promise<UserCompany> {
  const result = await getFondoApi(CURRENT_USER_COMPANY);
  return result as UserCompany;
}

export async function saveUser(user: User): Promise<any> {
  const result = await putFondoApi(USER, user);
  return result as Promise<UserCompaniesResponse>;
}

export async function getCompanyPaymentInfo(): Promise<CompanyPaymentInfo> {
  const result = await getFondoApi(COMPANY_PAYMENT_INFO);
  return result as CompanyPaymentInfo;
}

export async function saveCompanyPaymentInfo(companyPaymentInfo: CompanyPaymentInfo): Promise<any> {
  const result = postFondoApi(COMPANY_PAYMENT_INFO, companyPaymentInfo);
  return result;
}

export async function getCustomerLogoImage(): Promise<CustomerLogo> {
  const result = await getFondoApi(COMPANY_LOGO_IMAGE);
  return result as CustomerLogo;
}

export async function uploadCustomerLogoImage(file: File): Promise<any> {
  const presignedUrlResponse = await getCustomerLogoPresignedURL(file);
  // Reactify doesn't like when we use axios (likely because of the CSRF Token) so instead we use xhr
  const xhr = new XMLHttpRequest();
  xhr.open("PUT", presignedUrlResponse.url);
  xhr.send(file);
  const result = await new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    };
  });
  return result;
}

export async function getCustomerLogoPresignedURL(file: File): Promise<PresignedURLResponse> {
  const fileSizeMB = file.size / 1024 ** 2;
  const requestBody = {
    appName: "company_logos",
    fileName: file.name,
    fileSize: fileSizeMB,
  };
  const result = await postFondoApi(COMPANY_LOGO_IMAGE, requestBody);
  return result as PresignedURLResponse;
}
