import { FieldArrayRenderProps, useFormikContext } from "formik";
import { useState } from "react";
import {
  CapitalStructureFormFields,
  ForeignSubsidiaryFormFields,
  ForeignSubsidiaryFormStep,
} from "../foreignSubsidiary.type";
import AddCapitalStructureModal from "../modals/AddCapitalStructureModal";

type CapitalStructureProps = {
  arrayHelpers: FieldArrayRenderProps;
  stepInfo: ForeignSubsidiaryFormStep;
}

const CapitalStructure = ({ arrayHelpers, stepInfo }: CapitalStructureProps) => {
  const formikContext = useFormikContext<ForeignSubsidiaryFormFields>();

  const error = "";

  const [showAddCapitalStructureModal, setShowAddCapitalStructureModal] = useState(false);
  const [
    currentlySelectedCapitalStructureIndex,
    setCurrentlySelectedCapitalStructureIndex,
  ] = useState<number | undefined>();

  const handleCancel = () => {
    setShowAddCapitalStructureModal(false);
    setCurrentlySelectedCapitalStructureIndex(undefined);
  };

  const handleSave = (capitalStructureData: CapitalStructureFormFields) => {
    if (currentlySelectedCapitalStructureIndex !== undefined) {
      arrayHelpers.replace(currentlySelectedCapitalStructureIndex, capitalStructureData);
    } else {
      arrayHelpers.push(capitalStructureData);
    }

    setCurrentlySelectedCapitalStructureIndex(undefined);
    setShowAddCapitalStructureModal(false);
  };

  const handleDelete = (index: number, capitalStructure: CapitalStructureFormFields) => {
    if (capitalStructure.uuid) {
      formikContext.setFieldValue(
        "oldCapitalStructure",
        [...formikContext.values.oldCapitalStructure, capitalStructure.uuid],
      );
    }
    arrayHelpers.remove(index);
  };

  const fieldHasError = (index: number, fields: string[]): boolean => (
    fields.some((field) => !!(formikContext.errors?.capitalStructure?.[index] as any)?.[field])
  );

  const renderCapitalStructure = () => (
    formikContext.values.capitalStructure.map((capitalStructure, index: number) => (
      <div className="col-lg-6 col-xl-4 mb-5">
        <div className="d-flex justify-content-between">
          <h3 className={`${formikContext.errors?.capitalStructure?.[index] ? "text-danger" : ""}`}>
            {capitalStructure.description || ""}
            {formikContext.errors?.capitalStructure?.[index] && (<i
              className="fe fe-alert-circle text-danger ms-2"
              style={{ marginBottom: "-0.1em" }}
            />)}
          </h3>
          <div>
            <span>
              <i
                className="fe fe-edit-2 mx-2 action-icon text-secondary"
                onClick={() => {
                  setShowAddCapitalStructureModal(true);
                  setCurrentlySelectedCapitalStructureIndex(index);
                }}
                style={{ cursor: "pointer" }}
                title="Edit"
              ></i>
            </span>
            <span>
              <i
                className="fe fe-trash mx-2 action-icon text-secondary"
                title="Delete"
                style={{ cursor: "pointer" }}
                onClick={() => handleDelete(index, capitalStructure)}
              ></i>
            </span>
          </div>
        </div>
        <hr className='mt-0' />
        <div className='row'>
          <p
            className={`data-item ${fieldHasError(index, ["firstName", "lastName"]) ? "text-danger" : ""}`}
          >
            Stock Type:{" "}
            {capitalStructure.description}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["title"]) ? "text-danger" : ""}`}
          >
            Issued at start of period:{" "}
            {capitalStructure?.issuedCountAtPeriodStart}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["title"]) ? "text-danger" : ""}`}
          >
            Outstanding at start of period:{" "}
            {capitalStructure?.outstandingCountAtPeriodStart}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["title"]) ? "text-danger" : ""}`}
          >
            Issued at end of period:{" "}
            {capitalStructure?.issuedCountAtPeriodEnd}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["title"]) ? "text-danger" : ""}`}
          >
            Outstanding at end of period:{" "}
            {capitalStructure?.outstandingCountAtPeriodEnd}
          </p>
        </div>
      </div>
    ))
  );

  return (<>
    {formikContext.values.capitalStructure.length > 0 && (
      <div>
        <div className="row mt-5">
          {renderCapitalStructure()}
        </div>
        <div className="mb-5">
          <button type="button" className="btn btn-primary" onClick={() => {
            setShowAddCapitalStructureModal(true);
          }}>Add another</button>
        </div>
      </div>
    )}

    {(formikContext.values.capitalStructure.length === 0)
      && <div className='text-align-center' style={{ margin: "200px" }}>
        <h3 className="text-secondary mb-4">Start by adding a new shareholder</h3>
        <button type="button" className="btn btn-primary" onClick={() => {
          setShowAddCapitalStructureModal(true);
        }}>Add new</button>
        {error
          && <h3 className="text-danger mt-4 field-error text-center">You need to add at least one shareholder.</h3>}
      </div>
    }

    <AddCapitalStructureModal
      show={showAddCapitalStructureModal}
      handleClose={handleCancel}
      handleSave={handleSave}
      capitalStructure={currentlySelectedCapitalStructureIndex !== undefined
        ? formikContext.values.capitalStructure[currentlySelectedCapitalStructureIndex] : undefined}
      header="Add new Capital Structure"
      taxYear={stepInfo.taxYear}
    />
  </>);
};

export default CapitalStructure;
