import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ProductsGroupedByTypeCard from "./ProductsGroupedByTypeCard";
import AlwaysSelectedProductsCard from "./AlwaysSelectedProductsCard";
import { PLATFORM_PRODUCT } from "./constants";
import {
  addProductToOrder,
  getAvailableProducts,
  getProductOrders,
  removeLineItemFromOrder,
} from "../../products/products.api";
import { ProductPricingCategory, SelectedProductProps } from "../product/product.type";

const ProductsListCard = () => {
  const queryClient = useQueryClient();

  const availableProducts = useQuery(
    ["availableProducts"],
    () => getAvailableProducts(true),
    {
      refetchOnWindowFocus: false,
    },
  );

  const orders = useQuery(
    ["productOpenOrder"],
    getProductOrders,
    {
      refetchOnWindowFocus: false,
    },
  );

  const addProduct = useMutation({
    mutationFn: (data: SelectedProductProps[]) => addProductToOrder(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["productOpenOrder"] });
      queryClient.invalidateQueries({ queryKey: ["availableProducts"] });
    },
  });

  const removeLineItem = useMutation({
    mutationFn: (lineItemUuid: string) => removeLineItemFromOrder(lineItemUuid),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["productOpenOrder"] });
      queryClient.invalidateQueries({ queryKey: ["availableProducts"] });
    },
  });

  const handleSelectProduct = (productUuid: string) => {
    addProduct.mutate([{ productId: productUuid, units: 1 }]);
  };

  const handleRemoveProduct = (lineItemUuid: string) => {
    removeLineItem.mutate(lineItemUuid);
  };

  return (
    <>
      <AlwaysSelectedProductsCard
        product={PLATFORM_PRODUCT}
      />
      {availableProducts.data?.map(
        (productsGroupedByType: ProductPricingCategory) => (
          <ProductsGroupedByTypeCard
            productsGroupedByType={productsGroupedByType}
            openOrder={orders.data?.openOrder}
            handleSelectProduct={handleSelectProduct}
            handleRemoveProduct={handleRemoveProduct}
          />
        ),
      )}
    </>
  );
};

export default ProductsListCard;
