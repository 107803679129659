import { useNavigate } from "react-router-dom";
import confetti from "../../assets/img/badges/confetti.png";
import { growSurfSnippet } from "../../utils/common.util";

export default function OnboardingSuccess() {
  const navigate = useNavigate();

  function onGoToDashboardClicked() {
    navigate("/dashboard/action-items");
  }

  return (
        <div>
            {growSurfSnippet}
            <div className="row justify-content-center">
                <div className="col-12 col-lg-10 col-xl-8 card">
                    <div className="row">
                        <div className='col-12 col-md-12 py-3 px-0 text-align-center'>
                            <img className='my-4' src={confetti}></img>
                            <h1 className="mb-4">
                                Welcome on board!
                            </h1>
                            <p className="mb-4 text-muted">
                                Your payment has been processed successfully. Thank You!
                            </p>
                            <button
                                onClick={() => onGoToDashboardClicked()}
                                className="btn btn-primary btn-md mb-4"
                                style={{ width: "40%" }}>
                                    Get started
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
}
