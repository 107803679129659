import { Dropdown } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import AmountFilter from "./filters/AmountFilter";
import DateFilter from "./filters/DateFilter";
import { DateFilterTypes } from "../financeDashboard.type";

type TransactionsFilterButtonProps = {
  minAmountValue?: number|undefined;
  maxAmountValue?: number|undefined;
  handleAmountFilters: (
    minAmountValue: number|undefined,
    maxAmountValue: number|undefined
  ) => void;
  startDateValue?: string|undefined;
  endDateValue?: string|undefined;
  handleDateFilters: (filters: DateFilterTypes) => void;
}

const TransactionsFilterButton = (
  {
    minAmountValue,
    maxAmountValue,
    handleAmountFilters,
    startDateValue,
    endDateValue,
    handleDateFilters,
  }: TransactionsFilterButtonProps,
) => {
  const [selectedOption, setSelectedOption] = useState<string|null>();
  const [showDropdown, setShowDropdown] = useState(false);
  const handleSelectOption = (eventKey: string|null) => {
    setSelectedOption(eventKey);
    setShowDropdown(true);
  };

  return (
    <Dropdown
      align='end'
      style={{ position: "static" }}
      onSelect={(eventKey) => handleSelectOption(eventKey)}
      show={showDropdown}
    >
      <Dropdown.Toggle
        variant="outline-secondary"
        role='button'
        onClick={() => setShowDropdown(!showDropdown)}
      >
       <FeatherIcon
          icon="sliders"
          size="17"
          style={{ cursor: "pointer" }}
        />
        <span className="ms-2">
          Filter
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {!(selectedOption === "amount") && !(selectedOption === "date") && (
          <>
            <Dropdown.Item
              eventKey="date"
            >
              Date
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="amount"
            >
              Amount
            </Dropdown.Item>
          </>
        )}
        {selectedOption === "amount" && (
          <AmountFilter
            minAmountValue={minAmountValue?.toString()}
            maxAmountValue={maxAmountValue?.toString()}
            setSelectedOption={setSelectedOption}
            handleAmountFilters={handleAmountFilters}
          />
        )}
        {selectedOption === "date" && (
          <DateFilter
            startDateValue={startDateValue}
            endDateValue={endDateValue}
            handleDateFilters={handleDateFilters}
            setSelectedOption={setSelectedOption}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TransactionsFilterButton;
