import { Button, Modal } from "react-bootstrap";

type ConfirmationModalProps = {
  show: boolean;
  handleClose: () => any;
  handleConfirm: () => any;
  cancelButtonText?: string;
  confirmButtonText?: string;
  titleText?: string;
  bodyText?: string;
  modalOverModal?: boolean;
};

const ConfirmationModal = ({
  show,
  handleClose,
  handleConfirm,
  cancelButtonText,
  confirmButtonText,
  titleText,
  bodyText,
  modalOverModal,
}: ConfirmationModalProps) => (
    <Modal
      show={show}
      onHide={handleClose}
      style={{ backgroundColor: modalOverModal ? "rgba(18, 38, 63, .5)" : "" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{titleText || "Are you sure?"}</Modal.Title>
      </Modal.Header>
      {bodyText && <Modal.Body>{bodyText}</Modal.Body>}
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {cancelButtonText || "Cancel"}
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          {confirmButtonText || "Confirm"}
        </Button>
      </Modal.Footer>
    </Modal>
);

export default ConfirmationModal;
