import { useContext } from "react";
import FondoLayout from "../fusion-kit/FondoLayout";
import { PassportContext } from "../../context/PassportContext";
import { LoggedInUserContext } from "../../context/LoggedInUserContext";
import DashboardOnboardingHomeCard from "../dashboard-onboarding/dashboard/DashboardOnboardingHomeCard";

export default function Dashboard() {
  const { passportOpen, setPassportOpen } = useContext(PassportContext);
  const { currentDashboardOnboarding, currentLoggedInUserName } = useContext(LoggedInUserContext);

  const title: React.ReactNode = (
    <>
      <span>Welcome, </span> <span style={{ color: "#2968ED" }}>{currentLoggedInUserName}</span>
    </>
  );
  let actionButton = true;
  if (currentDashboardOnboarding) {
    actionButton = false;
  }

  return (
    <FondoLayout
      title={title}
      actionButton={actionButton}
      actionButtonText={"Fondo Passport"}
      handleActionButtonClick={() => setPassportOpen(!passportOpen)}
      documentTitle={"Home"}
    >
      <DashboardOnboardingHomeCard />
    </FondoLayout>
  );
}
