import { matchPath } from "react-router-dom";

export function isEmailValid(email: string): boolean {
  const emailRegex = new RegExp(["^(([^<>()[\\]\\\\.,;:\\s@\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))",
    "@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z",
    "\\-0-9]+\\.)+[a-zA-Z]{2,}))$"].join(""));
  return emailRegex.test(email);
}

export function removeLeadingAndTrailingSlashes(string: string) {
  return string.replace(/^[\\/]+|[\\/]+$/g, "");
}

// find next element of el from arr
export function getNext(arr: string[], el: string) {
  const index = arr.indexOf(el);
  if (index > -1 && arr[index + 1]) {
    return arr[index + 1];
  }
  return undefined;
}

export const formatDateToStringYMDWithDash = (date?: string) => {
  // Transforms a string date with format yyyy-MM-dd'T'HH:mm:ss.SSSZZZ
  // to a string with format "Y-m-d"

  const newDate = date ? new Date(date) : new Date();
  const newDateToStr = newDate.toLocaleDateString("en-US").split("/");
  const m = newDateToStr[0];
  const d = newDateToStr[1];
  const y = newDateToStr[2];

  return `${y}-${m}-${d}`;
};

export const formatDateToWords = (dateString: string, excludeDay = false) => {
  // Takes a date like "2022-08-01" and parses it
  // to a string like "August 1, 2022"
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    timeZone: "UTC",
  };
  if (!excludeDay) options.day = "numeric";

  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

export const getLastDayOfMonth = (year: number, month: number) => {
  // Takes a year and month and returns the last day of that month

  // The Date object can take (year, month, day), where month is usually a number 0-11 (ex: Jan 0, Feb 1, Mar 2, etc.)
  // However, if you give the day a "0", it will get the last of the previous month.
  // Ex: Date(2015, 4, 3) -> May 3, 2015, but Date(2015, 4, 0) -> April 30, 2015
  const date = new Date(year, month, 0);

  // Formatting to return it as YYYY-MM-DD
  const formattedYear = date.getFullYear().toString();
  // We use padStart to pad month or day with a 0 if the len < 2
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
  const formattedDay = date.getDate().toString().padStart(2, "0");

  return `${formattedYear}-${formattedMonth}-${formattedDay}`;
};

export const placeParams = (pathRegex: string, params: any) => {
  // https://stackoverflow.com/a/50424896/1627360
  const segments = pathRegex.split("/");
  return segments
    .map((segment) => {
      const offset = segment.indexOf(":") + 1;
      if (!offset) return segment;

      const key = segment.slice(offset);
      return params[key];
    })
    .join("/");
};

export const matchPathInArray = (pathsArray: string[], currentLocation: string) => {
  const find = pathsArray.find((path) => !!matchPath(path, currentLocation));
  return !!find;
};

export function deepCopy(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function formatCurrency(input: string|number) {
  if (typeof input === "number") {
    input = input.toString();
  }

  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(parseFloat(input));
}

export function getYesNoFromPythonBoolean(value?: string) {
  if (value === "True") return "Yes";
  if (value === "False") return "No";
  return value;
}

export function post2023Form(value?: number | string) {
  return Number(value) >= 2023;
}

export function getListOfYears(
  startYear = 2000,
  endYear = new Date().getFullYear(),
  reversed = true,
) {
  const listOfYears = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index,
  );
  if (reversed) return listOfYears.reverse();
  return listOfYears;
}

export const ConditionalWrapper = ({ condition, wrapper, children }: any) => (
  // Component that wraps an html element based on a condition.
  condition ? wrapper(children) : children
);

export const removeCommas = (value: string|number) => {
  if (typeof value === "number") {
    value = value.toString();
  }
  return value.replaceAll(",", "");
};

export const growSurfSnippet = process.env.REACT_APP_FONDO_ENVIRONMENT === "production" ? (
  <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          (function(g,r,s,f){
            g.grsfSettings={campaignId:"k66nlb",version:"2.0.0"};
            s=r.getElementsByTagName("head")[0];
            f=r.createElement("script");
            f.async=1;
            f.src="https://app.growsurf.com/growsurf.js"+"?v="+g.grsfSettings.version;
            f.setAttribute("grsf-campaign", g.grsfSettings.campaignId);
            !g.grsfInit?s.appendChild(f):"";
          })(window,document);
        `,
      }}
    />
) : null;
