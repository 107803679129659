import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ProgressManagerStatus } from "../../../common/type";
import { FINANCE_DASHBOARD_TAX_PLAN } from "../../../constants/network-calls";
import AlertModal from "../../fusion-kit/AlertModal";
import { getMergeDevLinkedAccounts, syncLinkedAccount } from "../../integrations/integrations.api";
import MergeDevCard from "../../integrations/MergeDevCard";
import { getPayrollData } from "../FinanceDashboard.api";
import { Payroll } from "../financeDashboard.type";

const PayrollConnectionCard = () => {
  const navigate = useNavigate();
  const category = "hris";
  const [showAlertModal, setShowAlertModal] = useState(false);

  const {
    data: payrollData,
  } = useQuery<Payroll, Error>(
    ["payrollData"],
    () => getPayrollData(2023),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const linkedAccountsQueryData = useQuery<any, Error>(
    ["linkedAccountData", category],
    () => getMergeDevLinkedAccounts(category),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  let disableConnectButton = !payrollData && !linkedAccountsQueryData?.data?.length;

  const syncLinkedAccountMutation = useMutation<ProgressManagerStatus, AxiosError<any>, any>(
    () => syncLinkedAccount(category),
  );

  const handleSyncLinkedAccount = () => {
    disableConnectButton = false;
    syncLinkedAccountMutation.mutate(
      null,
      {
        onSuccess: () => {
          navigate(FINANCE_DASHBOARD_TAX_PLAN);
        },
        onError: () => {
          setShowAlertModal(true);
        },
      },
    );
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-10 col-xl-8 mx-3">
        <h2>Connect Payroll Account</h2>
        <p className="text-muted">
          Connect your payroll account to populate your TaxPlan
        </p>
        <div>
          {disableConnectButton && (
            <Alert variant="secondary" className="d-flex justify-content-between align-items-center">
              <span className="mb-1">
                If you prefer you can activate your TaxPlan by inputting your payroll data manually.
              </span>
              <Link to="/payroll_manual_connection">
                <button
                  className="btn btn-outline-light"
                >
                  Input Manually
                </button>
              </Link>
            </Alert>
          )}
          <MergeDevCard
            category={category}
            title="Payroll Account"
            addButtonTitle="Add Payroll"
            queriesToRefresh={["payrollData", "linkedAccountData"]}
          />
          <hr className="my-5"/>
          <div className="row mb-3 mt-2">
            <div className="d-flex flex-row col-12 col-md-12 justify-content-between">
              <Button
                className="btn btn-lg btn-light"
                variant="white"
                onClick={() => navigate(FINANCE_DASHBOARD_TAX_PLAN)}
              >
                Back
              </Button>
              <Button
                className={
                  `btn btn-lg btn-primary ms-4 ${disableConnectButton && "disabled"}`
                }
                onClick={handleSyncLinkedAccount}
              >
                <div>Connect Now</div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        show={showAlertModal}
        title={"Error"}
        handleClose={() => setShowAlertModal(false)}
        error={true}
      />
    </div>
  );
};

export default PayrollConnectionCard;
