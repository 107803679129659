import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { ChartData } from "./types";
import { lightenHexColor } from "../../runway/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
);

type FondoLineChartProps = {
  minYAxisValue?: number;
  maxYAxisValue?: number;
  labels: string[];
  chartData: ChartData[];
  noDataState?: boolean;
  stacked?: boolean;
}

const FondoLineChart = ({
  minYAxisValue,
  maxYAxisValue,
  labels,
  chartData,
  noDataState = false,
  stacked = false,
}: FondoLineChartProps) => {
  const options = {
    maintainAspectRatio: false,
    interaction: {
      intersect: true,
    },
    scales: {
      y: {
        border: { dash: [4, 4] },
        min: minYAxisValue,
        max: maxYAxisValue,
        stacked,
      },
      x: {
        border: { dash: [4, 4] },
      },
    },
  };

  const datasets = chartData.map((object: any) => {
    const newObject = { ...object };
    newObject.borderColor = object.color;
    // lightenHexColor takes the color and makes it 50% lighter to have
    // a lighter color than the border color and match the design.
    // "CC" makes the color a bit transparent to still see the lines behind.
    newObject.backgroundColor = `${lightenHexColor(object.color, 50)}`;
    newObject.fill = true;
    newObject.tension = 0.3;
    delete newObject.color;
    return newObject;
  });

  let dataToPrune: number | undefined;
  let dataExists = false;

  const reducedLabels = labels.filter((label, index) => {
    if (dataExists) {
      return true;
    }
    const hasData = datasets.some((dataset) => (
      !(dataset.data[index] === null || dataset.data[index] === undefined)
    ));
    if (!dataExists && hasData) {
      dataExists = true;
    } else if (!dataExists && !hasData) {
      dataToPrune = index;
    }
    return hasData;
  });

  let prunedDatasets: any[] = [];
  if (dataToPrune !== undefined) {
    prunedDatasets = datasets.map((dataset) => {
      const newDatasetCopy = { ...dataset };
      newDatasetCopy.data = dataset.data.slice(dataToPrune ? dataToPrune + 1 : 0);
      return newDatasetCopy;
    });
  } else {
    prunedDatasets = datasets;
  }

  const data = {
    labels: reducedLabels,
    datasets: prunedDatasets,
  };

  return (
    <>
      {noDataState ? (
        <Line options={options} data={ { datasets: [] } } height="270px" />
      ) : (
        <Line options={options} data={data} height="270px" />
      )}
    </>
  );
};

export default FondoLineChart;
