import { useFormikContext } from "formik";
import {
  CorpTaxFormFields,
  CorporateTaxStepProps,
  researchExpenseFieldToLabelMap,
  researchExpenseFieldToTypeMap,
} from "./corporateTax.type";
import FondoFormGroup from "../forms/formik-components/FondoFormGroup";

export default function ResearchExpenses(props: CorporateTaxStepProps) {
  const formikContext = useFormikContext<CorpTaxFormFields>();

  return (<>
      <h2 className="mb-3">Research & Experimental Expenses</h2>
      <p className="text-secondary mb-5">
          Research and experimental (R&E) expenses include all costs associated with the development or improvement
          of a product, software, invention or something similar in order to help run your business.
          Beginning in 2022, taxpayers can no longer fully deduct expenses related to R&E.
          Instead, the expenses will be capitalized on the balance sheet and amortized over five years if expenses
          are sourced domestically and 15 years if sourced abroad. In order to calculate the {props.stepInfo.taxYear}
          R&E expense total please provide an allocation percentage for R&E to the following expense categories:
      </p>
      <div>
          <FondoFormGroup
            formikContext={formikContext}
            fieldName="researchExpenses.wages"
            labels={researchExpenseFieldToLabelMap}
            types={researchExpenseFieldToTypeMap}
          />
          <FondoFormGroup
            formikContext={formikContext}
            fieldName="researchExpenses.contractorsExpenseDomestic"
            labels={researchExpenseFieldToLabelMap}
            types={researchExpenseFieldToTypeMap}
          />
          <FondoFormGroup
            formikContext={formikContext}
            fieldName="researchExpenses.contractorsExpenseInternational"
            labels={researchExpenseFieldToLabelMap}
            types={researchExpenseFieldToTypeMap}
          />
          <FondoFormGroup
            formikContext={formikContext}
            fieldName="researchExpenses.officeExpenses"
            labels={researchExpenseFieldToLabelMap}
            types={researchExpenseFieldToTypeMap}
          />
          <FondoFormGroup
            formikContext={formikContext}
            fieldName="researchExpenses.suppliesAndMaterials"
            labels={researchExpenseFieldToLabelMap}
            types={researchExpenseFieldToTypeMap}
          />
          <FondoFormGroup
            formikContext={formikContext}
            fieldName="researchExpenses.utilities"
            labels={researchExpenseFieldToLabelMap}
            types={researchExpenseFieldToTypeMap}
          />
      </div>
  </>);
}
