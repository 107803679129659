import {
  GET_FOREIGN_SUBSIDIARIES,
  GET_FOREIGN_SUBSIDIARY_FORMS,
} from "../../constants/network-calls";
import { ForeignSubsidiaryFormFields, ForeignSubsidiaryInfo } from "./foreignSubsidiary.type";
import { deleteFondoApi, getFondoApi, postFondoApi } from "../../utils/util.api";

export async function getForeignSubsidiariesInfo(taxYear: number|string): Promise<ForeignSubsidiaryInfo[]> {
  const result = await getFondoApi(GET_FOREIGN_SUBSIDIARIES, { taxYear });
  return result as ForeignSubsidiaryInfo[];
}

export async function deleteForeignSubsidiary(foreignSubsidiaryUuid: string): Promise<any> {
  const result = await deleteFondoApi(`${GET_FOREIGN_SUBSIDIARIES}${foreignSubsidiaryUuid}`);
  return result;
}

export async function addForeignSubsidiary(taxYear: number|string): Promise<any> {
  const result = await postFondoApi(`${GET_FOREIGN_SUBSIDIARIES}`, {}, { taxYear });
  return result;
}

export async function markForeignSubYearComplete(taxYear: number|string): Promise<any> {
  const result = await postFondoApi(`${GET_FOREIGN_SUBSIDIARIES}mark_year_complete/`, { taxYear });
  return result;
}

export async function getForeignSubsidiaryFormsData(foreignSubsidiaryUuid: string): Promise<any> {
  const result = await getFondoApi(GET_FOREIGN_SUBSIDIARY_FORMS, { foreignSubsidiaryInfoPk: foreignSubsidiaryUuid });
  const data = result as any;
  data.oldCapitalStructure = [];
  data.oldMajorityShareholders = [];
  data.oldCapitalContributions = [];
  data.oldDistributionAndDividends = [];
  data.oldForeignBankAccounts = [];
  return data as ForeignSubsidiaryFormFields;
}

export async function updateForeignSubsidiaryForms(
  formsData: ForeignSubsidiaryFormFields,
  foreignSubsidiaryUuid: string,
): Promise<ForeignSubsidiaryFormFields> {
  const result = await postFondoApi(
    GET_FOREIGN_SUBSIDIARY_FORMS,
    formsData,
    { foreignSubsidiaryInfoPk: foreignSubsidiaryUuid },
  );
  const data = result as any;
  data.oldCapitalStructure = [];
  data.oldMajorityShareholders = [];
  data.oldCapitalContributions = [];
  data.oldDistributionAndDividends = [];
  data.oldForeignBankAccounts = [];
  return data as ForeignSubsidiaryFormFields;
}
