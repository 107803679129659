import { Modal, Form, Button, Card, FormGroup, Container, ListGroup } from "react-bootstrap";
import { ErrorMessage, FieldArray, Formik, FormikProps } from "formik";
import FeatherIcon from "feather-icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import { createRecipientGroup, editRecipientGroup, getRecipientGroupItem } from "../Reporting.api";
import { CreateRecipientGroupType, RecipientEmail, RecipientGroupRouter, RecipientGroupType } from "../Reporting.type";
import { FORM_ERROR_MSG } from "../../../constants/general";
import Spinner from "../../spinner/Spinner";

type RecipientGroupUpsertType = {
  editing: boolean;
  recipientGroupItemId?: number;
  setCurrentRoute: React.Dispatch<React.SetStateAction<RecipientGroupRouter>>;
}

const RecipientGroupUpsert = ({ editing, recipientGroupItemId, setCurrentRoute }: RecipientGroupUpsertType) => {
  const queryClient = useQueryClient();

  const { data: recipientGroupData, isLoading, refetch } = useQuery(
    ["recipientGroupItemId", recipientGroupItemId],
    () => getRecipientGroupItem(recipientGroupItemId || 0),
    {
      enabled: editing && !!recipientGroupItemId,
    }
  );

  const createRecipientGroupMutation = useMutation(
    createRecipientGroup,
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ["recipientGroupListData"] })
    }
  );

  const editRecipientGroupMutation = useMutation(
    editRecipientGroup,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["recipientGroupListData"] });
        refetch();
      }
    }
  );

  const getInitialValues = (): CreateRecipientGroupType => {
    if (editing && recipientGroupData) {
      return recipientGroupData;
    }
    return {
      name: "",
      emailList: [
        {
          firstName: "",
          lastName: "",
          email: "",
        }
      ]
    };
  };

  const returnToGroups = () => {
    setCurrentRoute(RecipientGroupRouter.SELECT_GROUPS);
  };

  const upsertRecipientGroup = (formikRecipientGroupData: CreateRecipientGroupType) => {
    if (editing && recipientGroupItemId) {
      const editedRecipientGroup: RecipientGroupType = {
        id: recipientGroupItemId,
        name: formikRecipientGroupData.name,
        emailList: formikRecipientGroupData.emailList,
      }
      editRecipientGroupMutation.mutate(editedRecipientGroup);
    } else {
      createRecipientGroupMutation.mutate(formikRecipientGroupData);
    }
    returnToGroups();
  }

  const fieldHasError = (
    index: number,
    field: string,
    formik: FormikProps<CreateRecipientGroupType>,
  ) => (!!(formik.errors?.emailList?.[index] as any)?.[field] && (formik.touched?.emailList?.[index] as any)?.[field]);

  if (editing && isLoading) {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title as="h3" className="mb-0">
            Update Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Spinner />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="white" onClick={returnToGroups}>
            Cancel
          </Button>
          <Button>
            Update Group
          </Button>
        </Modal.Footer>
      </>
    );
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={upsertRecipientGroup}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
        emailList: Yup.array(Yup.object({
          firstName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
          lastName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
          email: Yup.string().email("Invalid email.").required(FORM_ERROR_MSG.REQUIRED_FIELD),
        })).min(1, "You need at least one user and email!")
      })}
    >
      {(formik) => (
        <>
          <Modal.Header closeButton>
            <Modal.Title as="h3" className="mb-0">
              {editing ? "Update Group" : "Create New Group"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Text className="text-secondary">Group name</Form.Text>
                <Form.Control
                  value={formik.values.name}
                  placeholder="Name your group"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="text-danger">
                  <ErrorMessage name="name" />
                </div>
              </Form.Group>
              <FieldArray
                name="emailList"
                render={(arrayHelper) => (
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4" className="mb-0">
                        Add Members (need at least 1 member)
                      </Card.Title>
                      <Button size="sm" onClick={() => arrayHelper.push({
                        firstName: "",
                        lastName: "",
                        email: ""
                      })}>
                        Add
                      </Button>
                    </Card.Header>
                    <ListGroup className="mb-0">
                      {formik.values.emailList.map((email: RecipientEmail, idx: number) => (
                        <ListGroup.Item key={idx}>
                          <Form>
                            <FormGroup className="my-3">
                              <Form.Control
                                placeholder="First Name"
                                value={email.firstName}
                                name={`emailList[${idx}].firstName`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={fieldHasError(idx, "firstName", formik)}
                              />
                            </FormGroup>
                            <FormGroup className="my-3">
                              <Form.Control
                                placeholder="Last Name"
                                value={email.lastName}
                                name={`emailList[${idx}].lastName`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={fieldHasError(idx, "lastName", formik)}
                              />
                            </FormGroup>
                            <FormGroup className="my-3">
                              <Form.Control
                                placeholder="Email"
                                value={email.email}
                                name={`emailList[${idx}].email`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={fieldHasError(idx, "email", formik)}
                              />
                            </FormGroup>
                            <Container
                              className="d-flex justify-content-end"
                            >
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => arrayHelper.remove(idx)}
                              >
                                <span>Delete this member </span>
                                <FeatherIcon
                                  icon="trash-2"
                                  size={17}
                                />
                              </div>
                            </Container>
                          </Form>
                        </ListGroup.Item>
                      ))}
                        </ListGroup>
                  </Card>
                )}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="white" onClick={returnToGroups}>
              Cancel
            </Button>
            <Button onClick={() => formik.handleSubmit()}>
              {editing ? "Update Group" : "Create Group"}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};

export default RecipientGroupUpsert;
