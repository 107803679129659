import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { useEffect, useRef, useState } from "react";
import { createInstitution, runQboObjectSync } from "./bankingIntegration.api";
import { OnSuccessIntegrationProps } from "./bankingIntegration.type";
import { BREX_CLIENT_ID, MERCURY_CLIENT_ID, QBO_CLIENT_ID } from "../../constants/config";
import Integrations from "../integrations/Integrations";
import FondoToast from "../fusion-kit/FondoToast";
import { INTEGRATIONS } from "../../constants/network-calls";

const OnSuccessIntegration: React.FC<OnSuccessIntegrationProps> = ({ clientId }) => {
  const [showToast, setShowToast] = useState(true);
  const [hasError, setHasError] = useState(false);
  const effectExecuted = useRef(false);  

  const createInstitutionMutation = useMutation<any, AxiosError<any>, any>(() => createInstitution(clientId));

  const runQboObjectSyncMutation = useMutation<any, AxiosError<any>, any>(() => runQboObjectSync());

  const onSuccessCallback = (mutation: UseMutationResult) => {
    mutation.mutate(null, {
      onError: () => {
        setHasError(true);
      },
    });
  };

  const onSuccessMapping = {
    [BREX_CLIENT_ID || ""]: createInstitutionMutation,
    [MERCURY_CLIENT_ID || ""]: createInstitutionMutation,
    [QBO_CLIENT_ID || ""]: runQboObjectSyncMutation,
  };

  useEffect(() => {
    if (effectExecuted.current) return;
    effectExecuted.current = true;
    const mappingCallback = onSuccessMapping[clientId];
    onSuccessCallback(mappingCallback);
  }, [clientId]);

  window.history.replaceState(null, document.title, INTEGRATIONS);
  return (
    <>
      <Integrations />
      <FondoToast
        show={showToast}
        handleClose={() => setShowToast(false)}
        error={hasError}
        message={hasError ? "Connecting account failed! Please try again later." : "Successfully connected account!"}
      />
    </>
  );
};

export default OnSuccessIntegration;
