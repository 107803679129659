import { Components } from "../../types/openapi.d"

export type IntegrationContext = {
    featureFlagIntegrationFinch: boolean;
    totalAccounts: number;
    items: any[];
    itemAccounts: any[];
    finchIntegrations: [];
}

export type BankAccount = {
    uuid: string,
    name: string,
    type: string,
    subtype?: string,
    fusePlaidAccount?: number,
    status: AccountStatus,
    mask?: string,
}

export type PlaidItem = {
    id: number,
    name: string,
    accessToken?: string,
    fuseAccessToken?: string,
    validLink: boolean,
}

export enum InstitutionSource {
    BREX = "BREX",
    FUSE = "FUSE",
    PLAID = "PLAID",
    MANUAL = "MANUAL",
}

export type Institution = {
    accounts: BankAccount[];
    uuid: string;
    name: string;
    source: InstitutionSource;
    validLink: boolean;
    item: PlaidItem;
    migrationToPlaidStatus: MigrationToPlaidStatus | null;
}

export type LinkToken = {
    linkToken: string
}

export type LinkedAccount = {
    uuid: string;
    integration: string;
}

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
}

export const mapAccountStatusToColor: { [ key in AccountStatus]: string } = {
  ACTIVE: "primary",
  CLOSED: "secondary",
};

export const mapAccountStatusToLabel: { [key in AccountStatus]: string } = {
  ACTIVE: "Active",
  CLOSED: "Closed",
};

export enum MigrationToPlaidStatus {
    RECONNECTION_NEEDED = "reconnection needed",
    INSTITUTION_NOT_SUPPORTED_BY_PLAID = "institution not supported by plaid",
}

export type CompanyExternalConnections = Components.Schemas.CompanyExternalConnections

export type ExternalConnection = Components.Schemas.ExternalConnection

export type ExchangePlaidPublicToken = Components.Schemas.ExchangePlaidPublicTokenRequestRequest
