import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import FeatherIcon from "feather-icons-react";
import { Product } from "./product.type";

export default function ProductCard(props: any) {
  function handleProductChecked(event: any) {
    props.handleProductChecked(event, props.productCard.product.productName);
  }

  const isOnboardingFeeProduct = props.productCard.product.relatedService.service === "Onboarding Fee";

  function renderProduct() {
    return (
      props.productCard
      && props.productCard.product
      && (
        <label
          htmlFor={props.productCard.product.id}
          className={`w-100 cursor-pointer ${isOnboardingFeeProduct && "d-none"}`}>
          <div className={`row product-card px-1 py-2 my-3 mx-1 ${props.productCard.checked
            ? "checked" : ""}`}>
            <div className="col-12 col-md-8 pb-3 pt-4 product-text display-flex">
              <Form.Check
                id={props.productCard.product.id}
                type="checkbox"
                label={props.productCard.displayName
                  || props.productCard.product.relatedService.displayName}
                checked={props.productCard.checked}
                onChange={(event) => handleProductChecked(event)}
                disabled={props.productCard.product.disabled === true
                  || !props.productCard.product.id}
              // props.productCard.product.id for 'Platform' - should always remain checked
              ></Form.Check>
              {props.productCard.product.tooltip && (
                <span className="tooltip-container ms-2">
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="right"
                    overlay={<Tooltip>{props.productCard.product.tooltip}</Tooltip>}>
                    <span>
                      <FeatherIcon className="cursor-pointer" icon="info" size="1.1em" />
                    </span>
                  </OverlayTrigger>
                </span>
              )}
            </div>
            <div className="col-12 col-md-4 price-container">
              {renderPrice(props.productCard.product)}
              <span className="price-text-container subtext-small text-grey">
                {props.productCard.product.discountPrice ? props.productCard.product.discountDuration : ""}
              </span>
              {props.productCard.product.priceTooltip && (
                <span className="tooltip-container ms-2">
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="right"
                    overlay={<Tooltip>{props.productCard.product.priceTooltip}</Tooltip>}>
                    <span>
                      <FeatherIcon className="cursor-pointer" icon="info" size="1.1em" />
                    </span>
                  </OverlayTrigger>
                </span>
              )}
            </div>
          </div>
        </label>
      )
    );
  }

  return renderProduct();
}

export function renderPrice(product: Product) {
  if (product.specialPrice) {
    if (product.specialDiscountPrice) {
      return (
        <>
          <span className="price-text-container subtext subtext-bold text-grey strikethrough">
            {product.specialPrice}
          </span>
          <span className="price-text-container price-text">{product.specialDiscountPrice}</span>
        </>
      );
    }
    return <span className="price-text-container price-text">{product.specialPrice}</span>;
  } if (!product.discountPrice) {
    return (
      <>
        {product.discountDuration && (
          <span className="price-text-container subtext subtext-bold text-grey">
          {product.discountDuration}
        </span>
        )}
        <NumericFormat
          className="price-text-container price-text"
          value={product.price}
          displayType={"text"}
          thousandSeparator={true}
          fixedDecimalScale={true}
          decimalScale={2}
          prefix={"$"}
        />
      </>
    );
  } if (Number(product.discountPrice) === 0) {
    return (
      <>
        <NumericFormat
          className="price-text-container subtext subtext-bold text-grey strikethrough"
          value={product.price}
          displayType={"text"}
          thousandSeparator={true}
          fixedDecimalScale={true}
          decimalScale={2}
          prefix={"$"}
        />
        <span className="price-text-container price-text">FREE</span>
      </>
    );
  }
  return (
    <>
      <NumericFormat
        className="price-text-container subtext subtext-bold text-grey strikethrough"
        value={product.price}
        displayType={"text"}
        thousandSeparator={true}
        fixedDecimalScale={true}
        decimalScale={2}
        prefix={"$"}
      />
      <NumericFormat
        className="price-text-container price-text"
        value={product.discountPrice}
        displayType={"text"}
        thousandSeparator={true}
        fixedDecimalScale={true}
        decimalScale={2}
        prefix={"$"}
      />
    </>
  );
}
