import { useState } from "react";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import { CorpTaxFormFields, CorporateTaxStep, ForeignBankAccountFormFields } from "./corporateTax.type";
import AddForeignBankAccountModal from "./modals/AddForeignBankAccountModal";
import { getYesNoFromPythonBoolean } from "../../utils/common.util";
import { Post2023CorporateTaxStep } from "./post-2023/post2023CorporateTax.type";
import DisplaySingleLineAddress from "../forms/display-helpers/DisplaySingleLineAddress";

type ForeignBankAccountsProps = {
  arrayHelpers: FieldArrayRenderProps;
  stepInfo: CorporateTaxStep | Post2023CorporateTaxStep;
}

export default function ForeignBankAccounts({ arrayHelpers }: ForeignBankAccountsProps) {
  const formikContext = useFormikContext<CorpTaxFormFields>();

  const needOneError = typeof formikContext.errors?.foreignBanks === "string";

  const [showAddForeignBankAccountModal, setShowAddForeignBankAccountModal] = useState<boolean>(false);
  const [currentlySelectedBankAccountIndex, setCurrentlySelectedBankAccountIndex] = useState<number | undefined>();

  const handleCancel = () => {
    setShowAddForeignBankAccountModal(false);
    setCurrentlySelectedBankAccountIndex(undefined);
  };

  const handleSave = (foreignBankAccountData: ForeignBankAccountFormFields) => {
    if (currentlySelectedBankAccountIndex !== undefined) {
      arrayHelpers.replace(currentlySelectedBankAccountIndex, foreignBankAccountData);
    } else {
      arrayHelpers.push(foreignBankAccountData);
    }

    setCurrentlySelectedBankAccountIndex(undefined);
    setShowAddForeignBankAccountModal(false);
  };

  const handleDelete = (index: number, foreignBank: ForeignBankAccountFormFields) => {
    if (foreignBank.uuid) {
      formikContext.setFieldValue("oldForeignBanks", [...formikContext.values.oldForeignBanks, foreignBank.uuid]);
    }
    arrayHelpers.remove(index);
  };

  const fieldHasError = (index: number, fields: string[]): boolean => (
    fields.some((field) => !!(formikContext.errors?.foreignBanks?.[index] as any)?.[field])
  );

  function renderForeignBankAccounts() {
    return (
      formikContext.values.foreignBanks.map((foreignBankAccount, index) => (

        <div className="col-lg-6 col-xl-4 mb-5">
          <div className="d-flex justify-content-between">
            <h3>{foreignBankAccount?.bankName}</h3>
            <div>
              <span>
                <i
                  className="fe fe-edit-2 mx-2 action-icon text-secondary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCurrentlySelectedBankAccountIndex(index);
                    setShowAddForeignBankAccountModal(true);
                  }}
                  title="Edit"
                ></i>
              </span>
              <span>
                <i
                  style={{ cursor: "pointer" }}
                  className="fe fe-trash mx-2 action-icon text-secondary"
                  title="Delete"
                  onClick={() => handleDelete(index, foreignBankAccount)}
                ></i>
              </span>
            </div>
          </div>
          <hr className='mt-0' />
          <div className='row'>
            <p className={`data-item ${fieldHasError(index, ["bankName"]) ? "text-danger" : ""}`}>
              Bank Name: {foreignBankAccount?.bankName}
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["type"]) ? "text-danger" : ""}`}
            >
              Type: {foreignBankAccount.type}
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["accountNumber"]) ? "text-danger" : ""}`}
            >
              Account Number: {foreignBankAccount.accountNumber}
            </p>
            <p
              className={`data-item ${fieldHasError(index, [
                "streetAddress", "city", "state", "postalCode",
              ]) ? "text-danger" : ""}`}
            >
              Address: <DisplaySingleLineAddress addressable={foreignBankAccount} />
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["currency"]) ? "text-danger" : ""}`}
            >
              Currency: {foreignBankAccount?.currency}
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["maxValueDuringYear"]) ? "text-danger" : ""}`}
            >
              Highest Balance: {foreignBankAccount.maxValueDuringYear}
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["dateOfMaxValue"]) ? "text-danger" : ""}`}
            >
              Date of highest balance: {foreignBankAccount.dateOfMaxValue}
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["openedDuringYear"]) ? "text-danger" : ""}`}
            >
              Opened during year: {getYesNoFromPythonBoolean(foreignBankAccount.openedDuringYear)}
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["closedDuringYear"]) ? "text-danger" : ""}`}
            >
              Closed during year: {getYesNoFromPythonBoolean(foreignBankAccount.closedDuringYear)}
            </p>

          </div>
        </div>
      ))
    );
  }

  return (<>
    {formikContext.values.foreignBanks.length > 0 && (
      <div>
        <div className="row mt-5">
          {renderForeignBankAccounts()}
        </div>
        <div className="mb-5">
          <button type="button" className="btn btn-primary" onClick={() => {
            setShowAddForeignBankAccountModal(true);
          }}>Add another</button>
        </div>
      </div>
    )}

    {(formikContext.values.foreignBanks.length === 0)
      && <div className='text-align-center' style={{ margin: "200px" }}>
        <h3 className="text-secondary mb-4">Start by adding a new foreign bank account</h3>
        <button type="button" className="btn btn-primary" onClick={() => {
          setShowAddForeignBankAccountModal(true);
        }}>Add new</button>
        {needOneError && (
          <h3 className="text-danger mt-4 field-error text-center">
            You need to add at least one foreign bank account.<br />
            If you do not have any, please select No in the Company Info section for foreign bank accounts.
          </h3>
        )}
      </div>
    }

    <AddForeignBankAccountModal
      show={showAddForeignBankAccountModal}
      handleClose={handleCancel}
      handleSave={handleSave}
      foreignBankAccount={currentlySelectedBankAccountIndex !== undefined
        ? formikContext.values.foreignBanks[currentlySelectedBankAccountIndex] : undefined
      }
      header="Add new foreign bank account"
    />
  </>);
}
