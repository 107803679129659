import { useQuery } from "@tanstack/react-query";
import {
  Row, Col, Accordion, Spinner,
} from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { ActionItemsService } from "./actionItems.type";
import { getActionItemsFromService } from "./actionItems.api";
import ActionItemDetailView from "./ActionItemDetailView";
import CircleBadge from "../fondo-components/CircleProgress/CircleBadge";
import { calculatePercentageOfActionItemServiceCompletion, isActionItemCompleted } from "./utils";
import greenCheckCircle from "../../assets/img/icons/greenCheckCircle.png";

type ActionItemServiceViewProps = {
  actionItemComponentServiceId?: string;
}

const ActionItemServiceView = ({ actionItemComponentServiceId }: ActionItemServiceViewProps) => {
  let { actionItemServiceId } = useParams();
  actionItemServiceId = (actionItemComponentServiceId || actionItemServiceId);
  const {
    data: actionItemServiceData,
    isLoading: actionItemServiceDataIsLoading,
  } = useQuery<ActionItemsService, Error>([
    "actionItemServiceData",
    actionItemServiceId,
  ], () => getActionItemsFromService(actionItemServiceId || ""), {
    enabled: !!actionItemServiceId,
  });

  const getAmountOfItemsCompleted = () => {
    if (actionItemServiceData) {
      return actionItemServiceData.numberOfActionItems - actionItemServiceData.numberOfPendingActionItems;
    }
    return 0;
  };

  const getCurrentServiceIndex = () => {
    const currentActionItemServiceId = actionItemServiceData?.id;
    return actionItemServiceData?.actionItemsServicesIdList?.findIndex((serviceIdNumber: number) => (
      serviceIdNumber === currentActionItemServiceId
    ));
  };

  const getNextActionItemServiceId = () => {
    const currentActionItemServiceIdIndex = getCurrentServiceIndex();
    if (currentActionItemServiceIdIndex !== undefined) {
      return actionItemServiceData?.actionItemsServicesIdList[currentActionItemServiceIdIndex + 1];
    }
    return currentActionItemServiceIdIndex;
  };

  const getPreviousActionItemServiceId = () => {
    const currentActionItemServiceIdIndex = getCurrentServiceIndex();
    if (currentActionItemServiceIdIndex !== undefined) {
      return actionItemServiceData?.actionItemsServicesIdList[currentActionItemServiceIdIndex - 1];
    }
    return currentActionItemServiceIdIndex;
  };

  if (actionItemServiceDataIsLoading) {
    return (
      <div className="row justify-content-center mt-5">
        <Spinner variant="primary" animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const parentMargin = actionItemComponentServiceId ? "0" : "11%";

  return (
    <div style={{ marginLeft: parentMargin, marginRight: parentMargin }}>
      {!actionItemComponentServiceId
        && <>
          <div className="mt-5">
            <Link to="/dashboard/action-items">
              <FeatherIcon icon="arrow-left" size="17" className="me-2 mt-n1" />
              Back to Action Items
            </Link>
          </div>
          <Row className="justify-content-center pt-6 mb-5">
            <Col className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
              <h6 className="mb-3 text-uppercase text-muted">
                ONBOARDING
              </h6>
              <div className="mb-3" style={{
                width: "60px",
                height: "60px",
                margin: "auto",
              }}
              >
                {isActionItemCompleted(actionItemServiceData!) ? (
                  <img src={greenCheckCircle} style={{ width: "60px", height: "60px" }} />
                ) : <CircleBadge
                  text={`${(getCurrentServiceIndex() || 0) + 1}`}
                  value={calculatePercentageOfActionItemServiceCompletion(
                    actionItemServiceData!,
                  )}
                />
                }
              </div>
              <div className="d-flex justify-content-center">
                <FeatherIcon
                  onClick={() => {
                    window.location.href = `/dashboard/action-items/${getPreviousActionItemServiceId()}`;
                  }}
                  icon="arrow-left"
                  size="20"
                  className="mt-1 me-4 text-muted"
                  role="button"
                  style={{ visibility: getPreviousActionItemServiceId() ? "" : "hidden" }}
                />
                <h1 className="mb-3">
                  {actionItemServiceData?.name}
                </h1>
                <FeatherIcon
                  onClick={() => { window.location.href = `/dashboard/action-items/${getNextActionItemServiceId()}`; }}
                  icon="arrow-right"
                  size="20"
                  className="mt-1 ms-4 text-muted"
                  role="button"
                  style={{ visibility: getNextActionItemServiceId() ? "" : "hidden" }}
                />
              </div>
              <p className="mb-5 text-muted">
                {getAmountOfItemsCompleted()} of {actionItemServiceData?.numberOfActionItems} sections complete
              </p>
            </Col>
          </Row>
        </>
      }
      <hr />
      <Accordion>
        {actionItemServiceData?.actionItems && actionItemServiceData.actionItems.map((actionItem: any) => (
          <ActionItemDetailView actionItem={actionItem} key={actionItem.id} />
        ))}
      </Accordion>
    </div>
  );
};

export default ActionItemServiceView;
