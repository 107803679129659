import { useState } from "react";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import { ForeignBankAccountsFormFields, ForeignSubsidiaryFormFields } from "../foreignSubsidiary.type";
import DisplaySingleLineAddress from "../../forms/display-helpers/DisplaySingleLineAddress";
import AddForeignBankAccountsModal from "../modals/AddForeignBankAccountsModal";
import { getYesNoFromPythonBoolean } from "../../../utils/common.util";

type ForeignBankAccountsProps = {
  arrayHelpers: FieldArrayRenderProps;
}

const ForeignBankAccounts = ({ arrayHelpers }: ForeignBankAccountsProps) => {
  const formikContext = useFormikContext<ForeignSubsidiaryFormFields>();
  const [showAddForeignBankAccountsModal, setShowAddForeignBankAccountsModal] = useState(false);
  const [
    currentlySelectedForeignBankAccountsIndex,
    setCurrentlySelectedForeignBankAccountsIndex,
  ] = useState<number | undefined>();

  const handleCancel = () => {
    setShowAddForeignBankAccountsModal(false);
    setCurrentlySelectedForeignBankAccountsIndex(undefined);
  };

  const handleSave = (foreignBankAccountsData: ForeignBankAccountsFormFields) => {
    if (currentlySelectedForeignBankAccountsIndex !== undefined) {
      arrayHelpers.replace(currentlySelectedForeignBankAccountsIndex, foreignBankAccountsData);
    } else {
      arrayHelpers.push(foreignBankAccountsData);
    }
    setCurrentlySelectedForeignBankAccountsIndex(undefined);
    setShowAddForeignBankAccountsModal(false);
  };

  const handleDelete = (index: number, foreignBankAccounts: ForeignBankAccountsFormFields) => {
    if (foreignBankAccounts.uuid) {
      formikContext.setFieldValue(
        "oldForeignBankAccounts",
        [...formikContext.values.oldForeignBankAccounts, foreignBankAccounts.uuid],
      );
    }
    arrayHelpers.remove(index);
  };

  const fieldHasError = (index: number, fields: string[]): boolean => (
    fields.some((field) => !!(formikContext.errors?.foreignBankAccounts?.[index] as any)?.[field])
  );

  const renderForeignBankAccounts = () => (
    formikContext.values.foreignBankAccounts?.map((foreignBankAccounts, index: number) => (
      <div className="col-lg-6 col-xl-4 mb-5">
        <div className="d-flex justify-content-between">
          <h3 className={`${formikContext.errors?.foreignBankAccounts?.[index] ? "text-danger" : ""}`}>
            {foreignBankAccounts.name || ""}
            {formikContext.errors?.foreignBankAccounts?.[index] && (<i
              className="fe fe-alert-circle text-danger ms-2"
              style={{ marginBottom: "-0.1em" }}
            />)}
          </h3>
          <div>
            <span>
              <i
                className="fe fe-edit-2 mx-2 action-icon text-secondary"
                onClick={() => {
                  setShowAddForeignBankAccountsModal(true);
                  setCurrentlySelectedForeignBankAccountsIndex(index);
                }}
                style={{ cursor: "pointer" }}
                title="Edit"
              ></i>
            </span>
            <span>
              <i
                className="fe fe-trash mx-2 action-icon text-secondary"
                title="Delete"
                style={{ cursor: "pointer" }}
                onClick={() => handleDelete(index, foreignBankAccounts)}
              ></i>
            </span>
          </div>
        </div>
        <hr className='mt-0' />
        <div className='row'>
          <p
            className={`data-item ${fieldHasError(index, ["name"]) ? "text-danger" : ""}`}
          >
            Bank Name:{" "}
            {foreignBankAccounts.name}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["type"]) ? "text-danger" : ""}`}
          >
            Type:{" "}
            {foreignBankAccounts.type}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["accountNumber"]) ? "text-danger" : ""}`}
          >
            Account Number:{" "}
            {foreignBankAccounts.accountNumber}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["maxValueDuringYear"]) ? "text-danger" : ""}`}
          >
            Highest Balance:{" "}
            {foreignBankAccounts.maxValueDuringYear}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["dateOfMaxValue"]) ? "text-danger" : ""}`}
          >
            Date of highest balance:{" "}
            {foreignBankAccounts.dateOfMaxValue}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["openedDuringYear"]) ? "text-danger" : ""}`}
          >
            Opened during year:{" "}
            {getYesNoFromPythonBoolean(foreignBankAccounts.openedDuringYear)}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["closedDuringYear"]) ? "text-danger" : ""}`}
          >
            Closed during year:{" "}
            {getYesNoFromPythonBoolean(foreignBankAccounts.closedDuringYear)}
          </p>
          <p
            className={`data-item ${fieldHasError(index, [
              "country", "streetAddress", "city", "state", "postalCode",
            ]) ? "text-danger" : ""}`}
          >
            Address: <DisplaySingleLineAddress addressable={foreignBankAccounts} />
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["currency"]) ? "text-danger" : ""}`}
          >
            Currency:{" "}
            {foreignBankAccounts?.currency}
          </p>
        </div>
      </div>
    ))
  );

  return (<>
    {formikContext.values.foreignBankAccounts?.length > 0 && (
      <div>
        <div className="row mt-5">
          {renderForeignBankAccounts()}
        </div>
        <div className="mb-5">
          <button type="button" className="btn btn-primary" onClick={() => {
            setShowAddForeignBankAccountsModal(true);
          }}>Add another</button>
        </div>
      </div>
    )}

    {(formikContext.values.foreignBankAccounts?.length === 0)
      && <div className='text-align-center' style={{ margin: "200px" }}>
        <h3 className="text-secondary mb-4">Start by adding a new foreign bank account</h3>
        <button type="button" className="btn btn-primary" onClick={() => {
          setShowAddForeignBankAccountsModal(true);
        }}>Add new</button>
      </div>
    }

    <AddForeignBankAccountsModal
      show={showAddForeignBankAccountsModal}
      handleClose={handleCancel}
      handleSave={handleSave}
      foreignBankAccount={currentlySelectedForeignBankAccountsIndex !== undefined
        ? formikContext.values.foreignBankAccounts[currentlySelectedForeignBankAccountsIndex] : undefined}
      header="Add new foreign bank account"
    />
  </>);
};

export default ForeignBankAccounts;
