import { FieldTypes } from "../../forms/formik-components/formTypes";

export type CompanyDashboardOnboarding = {
  name?: string;
  legalName?: string;
  incorporationYear?: string | null;
  howHeard?: string;
  accelerator?: string;
  batch?: string;
  deCCorp?: boolean;
};

export const companyFieldToLabelMap = {
  name: "Company Name",
  legalName: "Legal Name",
  incorporationYear: "Year of Incorporation",
  howHeard: "How did you hear about us?",
  accelerator: "Accelerator Program",
  batch: "Batch",
  deCCorp: "Delaware C Corp?",
};

export const companyFieldToTypeMap = {
  name: FieldTypes.TEXT,
  legalName: FieldTypes.TEXT,
  incorporationYear: FieldTypes.YEAR_SELECTOR,
  howHeard: FieldTypes.BASIC_SELECTOR,
  accelerator: FieldTypes.BASIC_SELECTOR,
  batch: FieldTypes.BASIC_SELECTOR,
  deCCorp: FieldTypes.YESNO_FROM_BOOLEAN,
};
