import { Modal } from "react-bootstrap";

type StaticContentModalProps = {
    show: boolean;
    handleClose: () => any;
    content: string;
    header: string;
    className?: string;
};

export default function StaticContentModal(props: StaticContentModalProps) {
  return (
        <Modal className={props.className} show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.header}</Modal.Title>
            </Modal.Header>
            <Modal.Body dangerouslySetInnerHTML={{ __html: props.content }}>
            </Modal.Body>
        </Modal>
  );
}
