import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { getUserViewed } from "./fondo-components.api";
import VideoModal from "./VideoModal";

export enum HelpVideoType {
  FSUB = "FSUB",
  R_AND_D = "R_AND_D",
  CORP_TAX = "CORP_TAX"
}

export const mapHelpVideoTypeToUrl: {
  [key in HelpVideoType]: string
} = {
  FSUB: "https://upload-image-production.s3.us-east-2.amazonaws.com/videos/fsub_onboarding.mp4",
  R_AND_D: "https://upload-image-production.s3.us-east-2.amazonaws.com/videos/r_and_d_onboarding.mp4",
  CORP_TAX: "https://upload-image-production.s3.us-east-2.amazonaws.com/videos/corp_tax_onboarding.mp4",
};

export const mapHelpVideoTypeToKey: {
  [key in HelpVideoType]: string
} = {
  FSUB: "fsub_help_video",
  R_AND_D: "r_and_d_help_video",
  CORP_TAX: "corp_tax_help_video",
};

type VideoExplanationButtonParams = {
  videoType: HelpVideoType
}

const VideoExplanationButton = ({ videoType }: VideoExplanationButtonParams) => {
  const [showModal, setShowModal] = useState(false);

  useQuery(
    ["userViewed", mapHelpVideoTypeToKey[videoType]],
    () => getUserViewed(mapHelpVideoTypeToKey[videoType]),
    {
      onSuccess: (data) => {
        if (!data.viewed) {
          setShowModal(!data.viewed);
        }
      },
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      <Button size="sm" className="mb-2 ms-3" onClick={() => setShowModal(true)}>
        Learn More
      </Button>
      <VideoModal
        videoUrl={mapHelpVideoTypeToUrl[videoType]}
        handleClose={() => setShowModal(false)}
        show={showModal}
      />
    </>
  );
};

export default VideoExplanationButton;
