import { useState } from "react";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import {
  CapitalContributionsDistributionAndDividendsFormFields,
  ForeignSubsidiaryFormFields,
} from "../foreignSubsidiary.type";
import AddCapitalContributionsDistributionAndDividendsModal
  from "../modals/AddCapitalContributionsDistributionAndDividendsModal";

type CapitalContributionsPorps = {
  arrayHelpers: FieldArrayRenderProps;
}

const CapitalContributions = ({ arrayHelpers }: CapitalContributionsPorps) => {
  const formikContext = useFormikContext<ForeignSubsidiaryFormFields>();
  const [showAddCapitalContributionsModal, setShowAddCapitalContributionsModal] = useState(false);
  const [
    currentlySelectedCapitalContributionsIndex,
    setCurrentlySelectedCapitalContributionsIndex,
  ] = useState<number | undefined>();

  const handleClose = () => {
    setShowAddCapitalContributionsModal(false);
    setCurrentlySelectedCapitalContributionsIndex(undefined);
  };

  const handleSave = (capitalContributionsData: CapitalContributionsDistributionAndDividendsFormFields) => {
    if (currentlySelectedCapitalContributionsIndex !== undefined) {
      arrayHelpers.replace(currentlySelectedCapitalContributionsIndex, capitalContributionsData);
    } else {
      arrayHelpers.push(capitalContributionsData);
    }
    handleClose();
  };

  const handleDelete = (
    index: number,
    capitalContributions: CapitalContributionsDistributionAndDividendsFormFields,
  ) => {
    if (capitalContributions.uuid) {
      formikContext.setFieldValue(
        "oldCapitalContributions",
        [...formikContext.values.oldCapitalContributions, capitalContributions.uuid],
      );
    }
    arrayHelpers.remove(index);
  };

  const fieldHasError = (index: number, fields: string[]): boolean => (
    fields.some((field) => !!(formikContext.errors?.capitalContributions?.[index] as any)?.[field])
  );

  const renderCapitalContributions = () => (
    formikContext.values.capitalContributions.map((capitalContributions, index: number) => (
      <div className="col-lg-6 col-xl-4 mb-5">
        <div className="d-flex justify-content-between">
          <h3 className={`${formikContext.errors?.capitalContributions?.[index] ? "text-danger" : ""}`}>
            {capitalContributions.description || ""}
            {formikContext.errors?.capitalContributions?.[index] && (<i
              className="fe fe-alert-circle text-danger ms-2"
              style={{ marginBottom: "-0.1em" }}
            />)}
          </h3>
          <div>
            <span>
              <i
                className="fe fe-edit-2 mx-2 action-icon text-secondary"
                onClick={() => {
                  setCurrentlySelectedCapitalContributionsIndex(index);
                  setShowAddCapitalContributionsModal(true);
                }}
                style={{ cursor: "pointer" }}
                title="Edit"
              ></i>
            </span>
            <span>
              <i
                className="fe fe-trash mx-2 action-icon text-secondary"
                title="Delete"
                style={{ cursor: "pointer" }}
                onClick={() => handleDelete(index, capitalContributions)}
              ></i>
            </span>
          </div>
        </div>
        <hr className='mt-0' />
        <div className='row'>
          <p
            className={`data-item ${fieldHasError(index, ["date"]) ? "text-danger" : ""}`}
          >
            Date:{" "}
            {capitalContributions?.date}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["description"]) ? "text-danger" : ""}`}
          >
            Description:{" "}
            {capitalContributions?.description}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["value"]) ? "text-danger" : ""}`}
          >
            Value:{" "}
            {capitalContributions?.value}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["currency"]) ? "text-danger" : ""}`}
          >
            Currency:{" "}
            {capitalContributions?.currency}
          </p>
        </div>
      </div>
    ))
  );
  return (<>
    {formikContext.values.capitalContributions.length > 0 && (
      <div>
        <div className="row mt-5">
          {renderCapitalContributions()}
        </div>
        <div className="mb-5">
          <button type="button" className="btn btn-primary" onClick={() => {
            setShowAddCapitalContributionsModal(true);
          }}>Add another</button>
        </div>
      </div>
    )}

    {(formikContext.values.capitalContributions.length === 0)
      && <div className='text-align-center' style={{ margin: "200px" }}>
        <h3 className="text-secondary mb-4">Start by adding a new capital contribution</h3>
        <button type="button" className="btn btn-primary" onClick={() => {
          setShowAddCapitalContributionsModal(true);
        }}>Add new</button>
      </div>
    }

    <AddCapitalContributionsDistributionAndDividendsModal
      show={showAddCapitalContributionsModal}
      handleClose={handleClose}
      handleSave={handleSave}
      capitalContributionsDistributionAndDividends={currentlySelectedCapitalContributionsIndex !== undefined
        ? formikContext.values.capitalContributions[currentlySelectedCapitalContributionsIndex] : undefined}
      header="Add new capital contribution"
    />
  </>);
};

export default CapitalContributions;
