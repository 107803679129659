import { Alert, Col, Row } from "react-bootstrap";
import { convertNumberToCurrency } from "../../../utils/utils";
import {
  ANNUAL,
  PLATFORM_LINE_ITEM,
} from "./constants";
import OrderLineItemRow from "./OrderLineItemRow";
import { OrderLineItem } from "./products.type";

type PurchasedLineItemByFrequencyCardProps = {
  frequency: string;
  lineItems: OrderLineItem[];
  recurring?: boolean;
  totalFinalPrice: number;
  totalActualPrice: number;
  totalTextArr: string[];
};

const PurchasedLineItemByFrequencyCard = (
  {
    frequency,
    lineItems,
    recurring = false,
    totalFinalPrice,
    totalActualPrice,
    totalTextArr,
  } : PurchasedLineItemByFrequencyCardProps,
) => (
  <div className="mb-4">
    {recurring && (
      <span className="subtext-small text-grey text-uppercase">
        Recurring
      </span>
    )}
    <h4 className="py-1">{frequency}</h4>
    <Row className="justify-content-between mt-3">
      <Col className="d-flex justify-content-start">
        <span className="subtext-small text-grey text-uppercase">
          Product
        </span>
      </Col>
      <Col className="d-flex justify-content-end">
        <span className="subtext-small text-grey text-uppercase">
          Subtotal
        </span>
      </Col>
    </Row>
    <hr className="my-2"></hr>
    {frequency === ANNUAL && (
      <>
        <OrderLineItemRow lineItem={PLATFORM_LINE_ITEM}/>
        {!!lineItems.length && <hr style={ { borderBlockColor: "#EFF2F7" } }/>}
      </>
    )}
    {lineItems.map((lineItem: OrderLineItem, idx: number) => (
      <>
        <OrderLineItemRow lineItem={lineItem} />
        {idx < lineItems.length - 1
          && <hr style={ { borderBlockColor: "#EFF2F7" } }/>
        }
      </>
    ))}
    <Alert
      className="my-3 align-items-center m-0"
      style={{
        backgroundColor: "#F9FBFD",
        border: "none",
        color: "black",
      }}
    >
      <Row className="px-0">
        <Col className="d-flex justify-content-start">
          <small className="text-grey text-uppercase">
              TOTAL {frequency}
          </small>
        </Col>
        <Col className="d-flex justify-content-end">
          <small className="text-grey strikethrough">
            ${convertNumberToCurrency(totalActualPrice)}
          </small>
        </Col>
        <Col className="d-flex justify-content-end">
          <small className="text-grey text-bold">
            ${convertNumberToCurrency(totalFinalPrice)}
          </small>
        </Col>
      </Row>
    </Alert>
    <div className="d-flex justify-content-end">
      {totalTextArr.length > 0 && (
        <span className="subtext-small text-grey self-align-end">
          {totalTextArr.map((text: string) => (<>+ {text}<br /></>))}
        </span>
      )}
    </div>
  </div>
);

export default PurchasedLineItemByFrequencyCard;
