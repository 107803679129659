import { Badge, Button, Card, ListGroup } from "react-bootstrap";
import { useState } from "react";
import { Partner, SelectedDealStatusToColorMap, SelectedDealStatusToDisplayMap } from "../passport-partners.types";
import { FiveStars } from "../../fondo-components/FiveStars";
import { PartnerCardTopBar } from "../PartnerCardTopBar";
import { DealDetailsModal } from "../deals/DealDetailsModal";
import { UpsertReviewModal } from "../reviews/ReviewModal";

type PartnerCardProps = {
  partner: Partner;
};

export const PartnerCard = ({ partner }: PartnerCardProps) => {
  const [showDealDetailsModal, setShowDealDetailsModal] = useState(false);
  const [showCreateReviewModal, setShowCreateReviewModal] = useState(false);
  const product = partner.products.length ? partner.products[0] : undefined;

  const {
    logoUrl,
    url,
    name,
    description,
    canReviewPartner,
    hasOneTouchDeal,
    averageScore,
    numberUsingPartner,
    selectedDeal,
  } = partner;

  let badge;
  if (selectedDeal) {
    badge = (
      <Badge className="pt-2 user-select-none" bg={SelectedDealStatusToColorMap[selectedDeal.status]}>
        {SelectedDealStatusToDisplayMap[selectedDeal.status]}
      </Badge>
    );
  } else if (hasOneTouchDeal) {
    badge = (
      <Badge className="pt-2 user-select-none" bg="primary-soft">
        One Click Connect
      </Badge>
    );
  }

  const DealCardListGroupItem = ({ children, className }: { children: JSX.Element[]; className?: string }) => (
    <ListGroup.Item className={`d-flex px-0 border-start-0 border-end-0 ${className || ""}`}>{children}</ListGroup.Item>
  );

  const cardPadding = "1.2rem";

  return (
    <>
      <Card style={{ width: "32%", minWidth: "18rem" }} className="mx-2">
        <PartnerCardTopBar
          badge={badge}
          className=""
          style={{ paddingLeft: cardPadding, paddingRight: cardPadding, paddingTop: cardPadding }}
        />
        <Card.Body
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ padding: cardPadding }}
        >
          <div
            style={{ minHeight: "12rem" }}
            className="w-100 flex-grow-1 d-flex align-items-center justify-content-center"
          >
            <a className="d-block" style={{ maxWidth: "70%" }} href={url} target="_blank">
              <img src={logoUrl} className="w-100" />
            </a>
          </div>
          <div className="text-center">
            <Card.Title as="h3" className="mt-3">
              {name}
            </Card.Title>
            <Card.Subtitle as="h5" className="text-secondary mt-2">
              {description}
            </Card.Subtitle>
          </div>
          <ListGroup className="w-100 border-0 mt-3" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            <DealCardListGroupItem className="justify-content-between">
              <span>Using</span>
              <span className="number-using-deal">{numberUsingPartner}</span>
            </DealCardListGroupItem>
            <DealCardListGroupItem className="justify-content-between">
              <span>Rating</span>
              <span>
                <FiveStars stars={averageScore} />
              </span>
            </DealCardListGroupItem>
            <DealCardListGroupItem className="justify-content-end pb-0 border-bottom-0">
              <>
                {canReviewPartner && (
                  <Button variant="white" className="px-2" size="sm" onClick={() => setShowCreateReviewModal(true)}>
                    Review
                  </Button>
                )}
              </>
              <Button variant="primary" className="px-2 ms-2" size="sm" onClick={() => setShowDealDetailsModal(true)}>
                Details
              </Button>
            </DealCardListGroupItem>
          </ListGroup>
        </Card.Body>
      </Card>
      <DealDetailsModal
        partner={partner}
        product={product}
        show={showDealDetailsModal}
        onHide={() => setShowDealDetailsModal(false)}
      />
      <UpsertReviewModal
        show={showCreateReviewModal}
        onHide={() => setShowCreateReviewModal(false)}
        product={product}
        partner={partner}
      />
    </>
  );
};
