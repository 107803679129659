import { DELAWARE_FRANCHISE_TAX_CONTEXT, SIGNING_OFFICER_DUPLICATE_CHECK } from "../../constants/network-calls";
import { DelawareFranchiseTaxFormFields } from "./DelawareFranchiseTax.type";
import { getFondoApi, postFondoApi } from "../../utils/util.api";

export async function getDelawareFranchiseTaxContext(taxYear: string | number): Promise<any> {
  const result = await getFondoApi(
    DELAWARE_FRANCHISE_TAX_CONTEXT,
    { taxYear },
  );
  return result as DelawareFranchiseTaxFormFields;
}

export async function getSigningOfficerDuplicateCheck(data: object): Promise<any> {
  const result = await postFondoApi(SIGNING_OFFICER_DUPLICATE_CHECK, data);
  return result;
}

export async function submitDelawareFranchiseTaxForm(data: any, taxYear: string | number): Promise<any> {
  const result = await postFondoApi(
    DELAWARE_FRANCHISE_TAX_CONTEXT,
    data,
    { taxYear },
  );
  return result;
}
