import { useFormikContext } from "formik";
import {
  ForeignSubsidiaryFormFields,
  booksRecordsLocationToTypeMap,
  booksRecordsLocationFieldToLabelMap,
} from "../foreignSubsidiary.type";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import AddressFieldGroup from "../../forms/formik-components/AddressFieldGroup";

const BooksRecordsLocation = () => {
  const formikContext = useFormikContext<ForeignSubsidiaryFormFields>();

  return (
    <div>
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="booksRecordsLocation.name"
        labels={booksRecordsLocationFieldToLabelMap}
        types={booksRecordsLocationToTypeMap}
      />
      <AddressFieldGroup
        formikContext={formikContext}
        baseName="booksRecordsLocation"
      />
    </div>
  );
};

export default BooksRecordsLocation;
