import { useFormikContext } from "formik";
import {
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router";
import FondoLayout from "../fusion-kit/FondoLayout";
import FondoFormGroup from "../forms/formik-components/FondoFormGroup";
import {
  DelawareFranchiseTaxFormFields,
  delawareFranchiseTaxFieldToTypeMap,
  getDelawareFranchiseTaxFieldToLabelMap,
} from "./DelawareFranchiseTax.type";
import BoardOfDirectorsSection from "./form-sections/BoardOfDirectorsSection";
import { getSigningOfficerDuplicateCheck, submitDelawareFranchiseTaxForm } from "./DelawareFranchiseTax.api";
import SuccessfulSubmissionImg from "../imgs/rnd-submission-successful.png";
import DelawareFranchiseTaxDuplicateSigningOfficerModal from "./DelawareFranchiseTaxDuplicateSigningOfficerModal";
import DelawareFranchiseTaxInfoSection from "./form-sections/DelawareFranchiseTaxInfoSection";
import IncorporationInfoSection from "./form-sections/IncorporationInfoSection";
import PaymentInfoSection from "./form-sections/PaymentInfoSection";
import AddressInfoSection from "./form-sections/AddressInfoSection";
import SigningOfficerSection from "./form-sections/SigningOfficerSection";

type DelawareFranchiseTaxProps = {
  taxYear: string;
}

const DelawareFranchiseTax = ({ taxYear }: DelawareFranchiseTaxProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const formikContext = useFormikContext<DelawareFranchiseTaxFormFields>();

  const errorSubmittingText = <>
    Errors were found in the form.<br/>
    However, your progress was saved.<br />
    Please correct the errors and submit again.
  </>;

  const progressSavedText = <>Progress saved!</>;

  const [progressModalText, setProgressModalText] = useState(progressSavedText);
  const [showSavedProgressModal, setShowSavedProgressModal] = useState(false);
  const [showSubmittedModal, setShowSubmittedModal] = useState(false);
  const [isSubmittingWithNoErrors, setIsSubmittingWithNoErrors] = useState(false);
  const [showDuplicateSigningOfficerModal, setShowDuplicateSigningOfficerModal] = useState(false);

  const signingOfficerDuplicateCheckMutation = useMutation(
    (officerTaxId: string) => getSigningOfficerDuplicateCheck({
      taxYear,
      officerTaxId,
    }),
    {
      onSuccess: (data) => {
        if (data.taxIdEnc) {
          setShowDuplicateSigningOfficerModal(true);
        } else {
          delawareFranchiseTaxFormMutation.mutate({
            ...formikContext.values,
            submitted: isSubmittingWithNoErrors,
          });
        }
      },
    },
  );

  const handleDuplicateCheckModalAction = (markAsSigningOfficer: boolean) => {
    delawareFranchiseTaxFormMutation.mutate({
      ...formikContext.values,
      submitted: isSubmittingWithNoErrors,
      markAsSigningOfficer,
    });
  };

  const delawareFranchiseTaxFormMutation = useMutation(
    (values: DelawareFranchiseTaxFormFields) => submitDelawareFranchiseTaxForm(values, taxYear),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["delawareFranchiseTaxContext"],
        });
        setShowDuplicateSigningOfficerModal(false);
        if (isSubmittingWithNoErrors) {
          setShowSubmittedModal(true);
        } else {
          setShowSavedProgressModal(true);
        }
      },
    },
  );

  const handleSaveProgress = () => {
    const checkForSigningOfficerDuplicate = (
      formikContext.values.dftInfo.delawareAlreadyFiled === "False"
      && formikContext.values.officerInfo.taxIdEnc
    );
    setProgressModalText(progressSavedText);
    setIsSubmittingWithNoErrors(false);
    if (checkForSigningOfficerDuplicate) {
      signingOfficerDuplicateCheckMutation.mutate(formikContext.values.officerInfo.taxIdEnc);
    } else {
      delawareFranchiseTaxFormMutation.mutate({ ...formikContext.values });
    }
  };

  const handleSubmit = () => {
    formikContext.validateForm().then((errors) => {
      const checkForSigningOfficerDuplicate = (
        formikContext.values.dftInfo.delawareAlreadyFiled === "False"
        && formikContext.values.officerInfo.taxIdEnc
      );
      let submittingWithoutErrors;
      if (Object.keys(errors).length > 0) {
        // Not submitting, still have errors
        submittingWithoutErrors = false;
        setProgressModalText(errorSubmittingText);
      } else {
        // Submitting, no errors
        submittingWithoutErrors = true;
        setProgressModalText(progressSavedText);
      }

      setIsSubmittingWithNoErrors(submittingWithoutErrors);
      if (checkForSigningOfficerDuplicate) {
        signingOfficerDuplicateCheckMutation.mutate(formikContext.values.officerInfo.taxIdEnc);
      } else {
        delawareFranchiseTaxFormMutation.mutate({ ...formikContext.values, submitted: submittingWithoutErrors });
      }
    });
  };

  const handleModalClose = () => {
    setShowSubmittedModal(false);
    queryClient.invalidateQueries({
      queryKey: ["sidenavInfo"],
    });
    navigate("/dashboard");
  };

  if (!formikContext.initialValues) {
    return (
      <FondoLayout
        pretitle="Tax"
        title={`${taxYear} Delaware Franchise Tax`}
      >
        <div className="row justify-content-center mt-5">
          <Spinner variant="primary" animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </FondoLayout>
    );
  }

  return (
    <>
      <FondoLayout
        pretitle="Tax"
        title={`${taxYear} Delaware Franchise Tax`}
        rightFloatJsx={(
          <div className="mt-4">
            <Button
              onClick={() => handleSaveProgress()}
              className="mx-2"
              size="sm"
            >
              Save Progress
            </Button>
            <Button
              onClick={() => handleSubmit()}
              size="sm"
              variant="outline-primary"
            >
              Submit
            </Button>
          </div>
        )}
      >
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="dftInfo.delawareAlreadyFiled"
          types={delawareFranchiseTaxFieldToTypeMap}
          labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
        />

        {formikContext.values.dftInfo.delawareAlreadyFiled === "False" && (
          <>
            <div className="bg-light my-5 p-4 w-75 rounded">
              <p className="mb-4">
                Estimated Tax Due:{" "}
                <span className="h3">$450.00+</span>
              </p>
              <p className="text-secondary">
                Once you submit this form, your dedicated Tax Team will begin to prep your
                Delaware Franchise Tax Return and ensure that all is set to
                be filed by the March 1st deadline. <br /><br />
                Please keep an eye out for any (unlikely) requests that may pop up! Once filed,
                we will send you a copy of your {taxYear} Delaware Franchise Tax Annual Report
                for your records. <br /><br />
                To learn more about how this tax is calculated,
                see {" "}
                <a href="https://www.tryfondo.com/guides/founders-guide-delaware-franchise-tax" target="_blank">
                  here
                </a>.
                The amount above is just an estimate and your Fondo Accountant will
                make sure your tax calculated to be the lowest amount legally possible.
                If you have any questions please don't hesitate to email us at {" "}
                <a href="mailto:onboarding@tryfondo.com">
                  onboarding@tryfondo.com
                </a>.
              </p>
            </div>

            <DelawareFranchiseTaxInfoSection
              formikContext={formikContext}
              taxYear={taxYear}
            />
            <IncorporationInfoSection
              formikContext={formikContext}
              taxYear={taxYear}
            />
            <PaymentInfoSection
              formikContext={formikContext}
              taxYear={taxYear}
            />
            <AddressInfoSection
              formikContext={formikContext}
              taxYear={taxYear}
            />
            <SigningOfficerSection
              formikContext={formikContext}
              taxYear={taxYear}
            />

            <BoardOfDirectorsSection formikContext={formikContext} />
          </>
        )}
        <div className="mb-5 d-flex justify-content-end">
          <Button onClick={() => handleSubmit()}>Submit</Button>
        </div>

        <Modal
          show={showSavedProgressModal}
          onHide={() => setShowSavedProgressModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{progressModalText}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={() => setShowSavedProgressModal(false)}>Ok</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showSubmittedModal}
          onHide={() => setShowSubmittedModal(false)}
        >
          <Modal.Body style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <img
              src={SuccessfulSubmissionImg}
              className="img-fluid" alt="Submission Successful"
              style={{ width: "50%" }}
            />
            <h3 className="mt-4 mb-3 text-center">Thank you!</h3>
            <h5 className="text-center text-secondary">
              If we need anything else, we will reach out.
            </h5>
            <Button className="mt-3 mb-3" size="sm" onClick={handleModalClose}>Go to Dashboard</Button>
          </Modal.Body>
        </Modal>

      </FondoLayout>
      <DelawareFranchiseTaxDuplicateSigningOfficerModal
        show={showDuplicateSigningOfficerModal}
        taxYear={taxYear}
        handleClose={() => setShowDuplicateSigningOfficerModal(false)}
        handleCancel={() => handleDuplicateCheckModalAction(false)}
        handleConfirm={() => handleDuplicateCheckModalAction(true)}
      />
    </>
  );
};

export default DelawareFranchiseTax;
