import { Button, ButtonGroup } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

type ResetFilterButtonProps = {
  currentFilter: string;
  setFilterValue: (value?: undefined) => void;
  filterName?: string;
};

const ResetFilterButton = ({
  currentFilter, setFilterValue, filterName,
}: ResetFilterButtonProps) => {
  let buttonText = currentFilter;
  if (filterName) buttonText = `${filterName}: ${buttonText}`;

  return (
    <div className='my-3' style={{ marginLeft: "12px" }}>
      <ButtonGroup>
        <Button
          variant='secondary'
          size='sm'
          style={{ pointerEvents: "none" }}
        >
          {buttonText}
        </Button>
        <Button
          variant='secondary'
          size='sm'
          onClick={() => setFilterValue(undefined)}
        >
          <FeatherIcon icon='x' size='1em' />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ResetFilterButton;
