import { Card } from "react-bootstrap";
import { FundingData, FundingPeriod } from "../financeDashboard.type";
import FondoBarChart from "../../fondo-components/Charts/FondoBarChart";
import { convertNumberToCurrency } from "../../../utils/utils";

type FundingHistoryChartCardProps = {
  fundingData: FundingData;
}

const FundingHistoryChartCard = ({ fundingData }: FundingHistoryChartCardProps) => {
  const periods = fundingData.fundingHistory.map((item: FundingPeriod) => item.period);
  const fundingAmounts = fundingData.fundingHistory.map((item: FundingPeriod) => item.fundingAmount);

  const getFundingHistoryChartData = () => {
    const chartData = [];
    chartData.push({ data: fundingAmounts, color: "#2C7AE5" });
    return chartData;
  };

  return (
    <Card className="mb-0">
      <div className="ps-4 pt-4">
        <Card.Title className="h5 text-uppercase text-muted mb-2">
          Funding Over Date Range
        </Card.Title>
          <span className="h1 mb-0">
            ${convertNumberToCurrency(fundingData.totalFunding)}
          </span>
      </div>
      <Card.Body>
        {fundingData && (
          <FondoBarChart
            labels={periods}
            chartData={getFundingHistoryChartData()}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default FundingHistoryChartCard;
