export const PAYMENT_TERMS_MODAL_CONTENT = `<div class="c16">
<p class="h1"><span class="c3 c2 c13">Terms of Service</span></p>
<p class="text-muted"><span class="c1">Date updated: October 7, 2021</span></p>
<p class="c0">
  <span class="c2">This Fondo Agreement (these “</span><span class="c3 c2">terms</span>
  <span class="c2">” or this “</span><span class="c3 c2">agreement</span>
  <span class="c2">”) is made between you (if you are using Fondo on behalf of an entity, that entity)
  and BloomJoy, Inc. DBA Fondo. It governs your access to and use of Fondo’s services, products,
  software, and websites (the “</span><span class="c3 c2">Services</span><span class="c2">”). </span>
  <span class="c2">Fondo may perform any or all of its obligations hereunderthrough engagement of a third party
    accountant (a “</span><span class="c2 c3">Service Provider</span><span class="c2">”). In such event, Fondo will
    remain liable to you for any nonperformance of this Agreement by Fondo caused by an act or omission of the Service
    Provider.
  </span>
</p>
<p class="c0">
  <span class="c1">
    If your Order Form contains additional terms and conditions, then those terms and conditions govern to the
    extent they conflict with any of these terms. If you are agreeing to these terms on behalf of an entity,
    you represent that you have the authority to bind that organization, otherwise you must not sign up for
    the Services.
  </span>
</p>
<p class="h2"><span class="c9 c3 c2">1. Services</span></p>
<p class="h4"><span class="c5 c3 c2">1.1. Services</span></p>
<p class="c0">
  <span class="c2">
    Fondo will use reasonable efforts to perform Delaware Franchise Tax Filing Services, Software Services,
    and/or the Services on your Order Form in accordance with applicable professional standards. Client will
    provide Fondo with all resources (physical and human) reasonably requested by Fondo to enable Fondo to perform
    the Services.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">1.2. Quickbooks Online™</span></p>
<p class="c0">
  <span class="c2">
    We may use Intuit Inc.’s Quickbooks Online™ to provide Services. Your and our use of Quickbooks
    Online™ will be governed by Intuit’s
  </span>
  <span class="c12">
    <a
      class="c4"
      href="https://www.google.com/url?q=https://accounts.intuit.com/terms-of-service&amp;sa=D&amp;source=editors&amp;
      ust=1625860978758000&amp;usg=AOvVaw2jPb50wQ53C4ws6Ts_5az2">
      Terms of Service
    </a>
  </span>
  <span class="c2">&nbsp;and </span>
  <span class="c12">
    <a
      class="c4"
      href="https://www.google.com/url?q=https://security.intuit.com/index.php/privacy&amp;sa=D&amp;source=editors&amp;
      ust=1625860978759000&amp;usg=AOvVaw3fzlIORkLfq9vZzOrvCYqg">
      Privacy Policy
    </a>
  </span>
  <span class="c1">.</span>
</p>
<p class="h4"><span class="c5 c3 c2">1.3. Plaid</span></p>
<p class="c0">
  <span class="c2">
    We may use Plaid Inc. (“Plaid”) to gather End User’s data from financial institutions. By using Services, you grant
    Fondo and Plaid the right, power, and authority to act on your behalf to access and transmit your personal and
    financial information from the relevant financial institution. You agree to your personal and financial information
    being transferred, stored, and processed by Plaid in accordance with the
  </span>
  <span class="c12">
    <a
      class="c4"
      href="https://www.google.com/url?q=https://plaid.com/legal&amp;sa=D&amp;source=editors&amp;ust=1625860978760000&
      amp;usg=AOvVaw3TUrnIQFvR48mgRcsmMqxS">
      Plaid Privacy Policy
    </a>
  </span>
  <span class="c1">. </span>
</p>
<p class="h4"><span class="c5 c3 c2">1.4. Third-party services and Login Credentials</span></p>
<p class="c0">
  <span class="c2">
    Fondo will use a number of technology business partners and may have to disclose certain accounting related
    information to Intuit, HubDoc, Microsoft, Google, Formstack, Typeform, Operator, Visible, Plaid, and CanopyTax.
  </span>
  <span class="c2">
    Our Services can automatically transfer data from other websites and services, like your bank account. If you
    provide us with login credentials (for example, an account name or number, password, answers to security questions
    (collectively, the “</span><span class="c3 c2">Login Credentials</span><span class="c1">”)), you both (a) give us
    permission, and a limited power of attorney, to use them to login to these other websites and services and access,
    transfer, reformat, and manipulate your account on your behalf; and (b) represent to us that you have the authority
    to give us this permission. We will maintain Login Credentials in encrypted form, and we will only use them as
    described in this agreement.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">1.5. Unauthorized uses of the Services</span></p>
<p class="c0">
  <span class="c1">
    We may suspend or terminate provision of the Services, in whole or in part, where we believe it is being used in a
    manner that breaches this agreement or creates risk of personal injury, property damage, or legal liability for
    Fondo, you or any third party, or may cause Fondo to lose the services of one of our third-party service providers.
    You represent and warrant that any information you provide to us about your (or, if you are acting on behalf of
    another, that person’s) business, products, or services is accurate and complete.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">1.6. Modifications to the Services</span></p>
<p class="c0">
  <span class="c1">
    We are constantly changing and improving our Services. We may add or remove functionality or features, and we may
    suspend or stop part of the Services altogether.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">1.7. Facilities and data transfer</span></p>
<p class="c0">
  <span class="c1">
    All facilities used to store and process Customer Data will adhere to reasonable security standards no less
    protective than the security standards at facilities where Fondo stores and processes its own information of a
    similar type. As part of providing the Services, we may transfer, store and process Customer Data in the
    United States. By using the Services, you consent to this transfer, processing and storage of Customer Data.
  </span>
</p>
<p class="h2"><span class="c9 c3 c2">2. Tax Services</span></p>
<p class="c0">
  <span class="c2">
    If your Order Form shows that you’ve purchased Fondo’s tax preparation service or you signed up for Delaware
    Franchise Tax filing, your use of that service is governed by the 
  </span>
  <span class="c12">
    <a
      class="c4"
      href="https://www.google.com/url?q=https://tryfondo.com/fondo-tax-terms&amp;sa=D&amp;source=editors&amp;
      ust=1625860978764000&amp;usg=AOvVaw3xeuvB8ySDvQy4rbb6gslO">
      Fondo tax terms
    </a>
  </span>
  <span class="c1">.</span>
</p>
<p class="h2"><span class="c9 c3 c2">3. Non-Disclosure and Confidentiality</span></p>
<p class="h4"><span class="c5 c3 c2">3.1. Obligations</span></p>
<p class="c0">
  <span class="c1">
    Subject to Section 3.2 (Disclosure of Confidential Information) the recipient (that is, Fondo and you) will use the
    other party’s Confidential Information only to exercise rights and fulfill obligations under this agreement.
    Customer Data is your Confidential Information. The recipient will use reasonable care to protect against disclosure
    of the other party’s Confidential Information to other parties other than the recipient’s Delegates who need to know
    it and who have a legal obligation to keep it confidential. The recipient will ensure that its Delegates are also 
    subject to the same non-disclosure and use obligations.
  </span>
</p>
<p class="c0">
  <span class="c1">
    Upon request, Fondo will execute a separate agreement reflecting these non-disclosure and confidentiality
    obligations for your records.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">3.2. Disclosure of Confidential Information</span></p>
<p class="h5"><span class="c14 c3 c2">3.2.1. General</span></p>
<p class="c0">
  <span class="c1">
    Regardless of any other provision in this agreement, the recipient or its Affiliates may disclose the other party’s
    Confidential Information (a) in accordance with a Legal Process, subject to Section 3.2.2 (Legal Process
    notification); (b) with the other party’s written consent; or (c) in connection with enforcing our rights under our
    agreements with you.
  </span>
</p>
<p class="h5"><span class="c14 c3 c2">3.2.2. Legal Process notification</span></p>
<p class="c0">
  <span class="c1">
    The recipient will use commercially reasonable efforts to notify the other party before disclosing that party’s
    Confidential Information in accordance with Legal Process. Notice is not required before disclosure if the recipient
    is informed that (a) it is legally prohibited from giving notice or (b) the Legal Process relates to exceptional
    circumstances involving danger of death or serious physical injury.
  </span>
</p>
<p class="h5"><span class="c3 c2 c14">3.2.3. Opposition</span></p>
<p class="c0">
  <span class="c1">
    The recipient and its Affiliates will comply with the other party’s reasonable requests to oppose disclosure of its
    Confidential Information.
  </span>
</p>
<p class="h2"><span class="c9 c3 c2">4. Fees &amp; Payment</span></p>
<p class="h4"><span class="c3 c2 c5">4.1. Fees</span></p>
<p class="c0">
  <span class="c2">
    Fondo bases the Fees for all Products based on
      <a href="https://bit.ly/fondo-pricing-tables" target="_blank" >
        this pricing tables.
      </a>
    Client will pay Fondo the fee for Subscribed Products as specified during onboarding or after sign up. Fees are
    adjusted to account for the increase or decrease in expenses or hours as outlined
      <a href="https://bit.ly/fondo-pricing-tables" target="_blank">
        here.
      </a>
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">4.2. Payment </span></p>
<p class="c0">
  <span class="c1">
    Fondo will collect payment for the fees automatically via ACH. Fees are exclusive of taxes, which you’re
    responsible for if applicable. You hereby authorize Fondo or its payment processor to initiate entries to your
    business bank checking accounts on file with Fondo (using your business address on file) in order to pay amounts
    that you owe to Fondo (including for any Renewal Terms as those payments come due), and, if necessary, to initiate
    adjustments for any transactions credited or debited in error. Fondo may immediately suspend provision of the
    Services if your account is past due. Except to the extent expressly set forth in this Section 4 (Payment), all
    payments are non-refundable and non-creditable.
  </span>
</p>
<p class="h2"><span class="c3 c2 c9">5. Term and Termination</span></p>
<p class="h4"><span class="c5 c3 c2">5.1. Initial term</span></p>
<p class="c0">
  <span class="c2">
    This agreement is effective on the date you sign an Order Form or Scope of Work &amp; Agreement or you otherwise
    agree to these terms (for example, by clicking through an online agreement)
    (the “</span><span class="c3 c2">Effective Date</span><span class="c2">”). Your initial subscription term will
    begin at the subscription start date and continue, unless
    terminated earlier, for the term specified on your Order Form
    (the “</span><span class="c3 c2">Initial Term</span><span class="c1">”).</span>
</p>
<p class="h4"><span class="c5 c3 c2">5.2. Automatic renewal</span></p>
<p class="c0">
  <span class="c1">
    Upon the end of the Initial Term and any Renewal Term, your subscription will automatically renew for the same
    duration as the Initial Term unless you give us notice (via d@tryfondo.com) at least (a) seven (7) days for monthly
    or quarterly subscriptions; or (b) thirty (30) days for annual subscriptions, in each case, prior to the end of the
    then-current Initial Term or Renewal Term, as applicable.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">5.3. Termination by us</span></p>
<p class="c0">
  <span class="c1">
    We may terminate your subscription at any time by providing notice of termination to you via the email address we
    have on file. In the event we terminate your subscription for any reason other than your violation of Section 1.4
    (Unauthorized uses of the Services) or your material breach of this agreement, we will give you a refund of prepaid
    fees for unelapsed months of the Services.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">5.4. Effect of termination or expiration of subscription</span></p>
<p class="c0">
  <span class="c1">
    In the event your subscription ends, we will try to transfer to you the “master administrator” status for the Intuit
    Quickbooks Online account that was maintained for you by Fondo, so that you can elect to maintain that subscription
    with Intuit or export your data.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">5.5. Survival</span></p>
<p class="c0"><span class="c1">
  Sections 3, 5.4, 6, and 8 – 12 (inclusive) will survive the termination or expiration of
  this agreement.
</span></p>
<p class="h2"><span class="c9 c3 c2">6. Intellectual Property</span></p>
<p class="c0">
  <span class="c2">
    As between the parties, you retain all intellectual property rights in your data, and we retain all intellectual
    property rights in the Services. You grant us a limited license to use your data to provide, protect, and improve
    the Services. We may retain anonymized, de-identified, and aggregated data that is protected by organizational and
    technical safeguards in order to perform research and development. If you provide us with feedback or suggestions
    about the Services (“</span><span class="c3 c2">Feedback</span><span class="c1">”), then we may use that information
    without obligation to you, and you hereby irrevocably assign to us all right, title, and interest in that Feedback.
  </span>
</p>
<p class="h2"><span class="c9 c3 c2">7. Using Fondo Services on Behalf of Others</span></p>
<p class="c0">
  <span class="c1">
    If you are using the Services on behalf of another individual or entity, you represent and warrant that you have all
    the authorizations and rights necessary and sufficient to do so.
  </span>
</p>
<p class="h2"><span class="c9 c3 c2">8. Warranty Disclaimer</span></p>
<p class="c0">
  <span class="c2">
    TO THE FULLEST EXTENT PERMITTED BY LAW, FONDO, ITS AFFILIATES, AND ITS AND THEIR SUPPLIERS AND DISTRIBUTORS
    (THE “</span><span class="c3 c2">FONDO ENTITIES</span><span class="c1">”) MAKE NO WARRANTIES, EITHER EXPRESS OR
    IMPLIED, ABOUT THE SERVICES. THE SERVICES ARE PROVIDED “AS IS.” NO WARRANTY IS MADE THAT THE SERVICES OR RESULTS
    OF USE OF THE SERVICES WILL MEET YOUR NEEDS OR EXPECTATIONS, THAT THE SERVICES WILL BE TIMELY, SECURE, UNINTERRUPTED
    OR ERROR-FREE OR THAT THE RESULTS THAT MAY BE OBTAINED FROM THE SERVICES WILL BE ACCURATE OR RELIABLE. WE ALSO
    DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IF THE
    EXCLUSIONS FOR IMPLIED WARRANTIES DO NOT APPLY TO YOU UNDER APPLICABLE LAW, ANY IMPLIED WARRANTIES ARE LIMITED TO 60
    DAYS FROM THE DATE OF PURCHASE OR DELIVERY OF THE SERVICE, WHICHEVER IS SOONER. THE FONDO ENTITIES DISCLAIM ANY
    REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF THE SERVICES WILL SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL
    OBLIGATIONS OR LAWS OR REGULATIONS.
  </span>
</p>
<p class="h2"><span class="c9 c3 c2">9. Limitation of Liability</span></p>
<p class="c0">
  <span class="c1">
    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE LIABILITY OF THE FONDO ENTITIES FOR ALL CLAIMS
    RELATING TO THE SERVICES AND THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNT YOU PAID FOR THE SERVICES DURING THE
    TWELVE (12) MONTHS PRIOR TO SUCH CLAIM OR ONE HUNDRED US DOLLARS, WHICHEVER IS GREATER. SUBJECT TO APPLICABLE LAW,
    THE FONDO ENTITIES ARE NOT LIABLE FOR ANY OF THE FOLLOWING: (A) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR
    CONSEQUENTIAL DAMAGES; (B) DAMAGES RELATING TO LOSS OF USE, DATA, BUSINESS, REVENUES, OR PROFITS. THE ABOVE
    LIMITATIONS APPLY EVEN IF THE FONDO ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS AGREEMENT
    SETS FORTH THE ENTIRE LIABILITY OF THE FONDO ENTITIES AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE SERVICES AND
    THEIR USE.
  </span>
</p>
<p class="h2"><span class="c9 c3 c2">10. Indemnification</span></p>
<p class="c0">
  <span class="c1">
    You shall defend and indemnify the Fondo Entities from and against all damages, losses, liabilities, claims,
    demands, actions, suits, judgements, settlements, costs and expenses, including all attorneys’ fees, that arise from
    or relate to: (a) your use of and our provision of the Services (except to the extent arising directly from our
    willful misconduct or gross negligence), (b) your violation of this agreement, (c) any content, information or
    materials provided by you, or (d) infringement by you, or any third party using your account or identity in the
    services, of any intellectual property or other right of any person or entity. We reserve the right to assume the
    exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will
    assist and cooperate with us in asserting any available defenses.
  </span>
</p>
<p class="h2"><span class="c9 c3 c2">11. Disputes; Class-Action Waiver</span></p>
<p class="h4"><span class="c5 c3 c2">11.1. Judicial forum for disputes</span></p>
<p class="c0">
  <span class="c1">
    Except as set forth in Section 11.5 (Arbitration), you and Fondo agree that any and all claims relating to these
    terms or the Services may only be brought in the federal or state courts of San Francisco County, California,
    subject to the mandatory arbitration provisions below. Both you and Fondo consent to venue and personal jurisdiction
    in such courts.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">11.2. Notice of disputes</span></p>
<p class="c0">
  <span class="c1">
    If you have a dispute with Fondo, you will promptly send written notice to: BloomJoy, Inc. dba Fondo,
    45 Lansing St., San Francisco, California 94105.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">11.3. Governing law</span></p>
<p class="c0">
  <span class="c1">
    This agreement and all claims (including procedural issues) between the parties are
    governed by the laws of California, excluding California’s conflict of laws rules.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">11.4. Informal resolution</span></p>
<p class="c0">
  <span class="c1">
    Before filing a claim, you and we each agree to try to resolve the dispute by contacting the other party through
    the notice procedures in Section 11.2 (Notice of disputes). If a dispute is not resolved within thirty days of
    notice, you and we may bring a formal proceeding.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">11.5. Arbitration</span></p>
<p class="c0">
  <span class="c2">
    You and Fondo agree to resolve any and all claims relating to this agreement or the Services through final and
    binding arbitration, except as set forth below. The
  </span>
  <span class="c10">
    <a
      class="c4"
      href="https://www.google.com/url?q=https://www.adr.org/index.php&amp;sa=D&amp;source=editors&amp;
      ust=1625860978778000&amp;usg=AOvVaw0C1ubqk2NcYYAaJQUzNE7h">American Arbitration Association
    </a>
  </span>
  <span class="c1">
    &nbsp;(AAA) will administer the arbitration under its Commercial Arbitration Rules. The arbitration will be held
    in San Francisco (CA), or any other location both parties agree to in writing.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">11.6. Exception to arbitration</span></p>
<p class="c0">
  <span class="c1">
    Either party may bring a lawsuit in the federal or state courts of San Francisco County, California solely for
    injunctive relief to stop unauthorized use or abuse of the Services or infringement of Intellectual Property Rights
    without first engaging in the informal dispute notice process described above.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">11.7. NO CLASS ACTIONS</span></p>
<p class="c0">
  <span class="c1">
    You may only resolve disputes related to or arising from the Services with the Fondo Entities on an individual basis
    and will not bring a claim in a class, consolidated, or representative action.
  </span>
</p>
<p class="h2"><span class="c9 c3 c2">12. Miscellaneous</span></p>
<p class="h4"><span class="c5 c3 c2">12.1. Updates to this agreement</span></p>
<p class="c0">
  <span class="c1">
    Fondo may make nonmaterial changes to these terms at any time without notice, but we will provide advance notice of
    any material changes to them. Other than changes made under Section 11.9 (Future changes to this dispute resolution
    agreement), the changes to the terms will not apply retroactively and will become effective 30 days after we give
    you notice or the stated effective date of the new terms, whichever is later. If a new version of these terms has a
    material negative impact on you, then you may object to the change by notifying us (via d@tryfondo.com) within 30
    days after we provide you with notice of the changes. If you so notify us, then you will remain governed by the
    terms in effect immediately before the change until the end of your then-current Initial Term or Renewal Term, as
    applicable. After that, you will be governed by the changed terms. Notwithstanding anything to the contrary in the
    foregoing, any changes to the terms related to new features or made for legal reasons will become effective
    immediately upon notice.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">12.2. Severability</span></p>
<p class="c0">
  <span class="c1">
    If one or more of the provisions contained in this agreement is held invalid, illegal or unenforceable in any
    respect by any court of competent jurisdiction, such holding will not impair the validity, legality, or
    enforceability of the remaining provisions.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">12.3. Assignment</span></p>
<p class="c0">
  <span class="c1">
    You may not assign this agreement, or your rights or obligations under it, in whole or in part and any such
    assignment is void. Fondo may freely assign this agreement, or its rights and obligations under it, in whole
    or in part.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">12.4. Electronic notices</span></p>
<p class="c0">
  <span class="c1">
    We will communicate with you via email or the Services’ user interface. It is your responsibility to keep your
    Services account e-mail address up-to-date so that you are able to receive electronic communications from us.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">12.5. Entire agreement; amendments</span></p>
<p class="c0">
  <span class="c1">
    This agreement, together with your Order Form, constitutes the entire agreement between you and Fondo with respect
    to its subject matter, and supersedes any and all prior agreements, discussions, negotiations, and offers. Except as
    specifically stated otherwise in the agreement, any amendment to the agreement must be in writing, expressly state
    that it is amending the Agreement and signed by both parties.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">12.6. Order of precedence; headings</span></p>
<p class="c0">
  <span class="c1">
    In the event of a conflict between this agreement and any Order Form, the terms of the Order Form shall govern.
    Headings are for information purposes only.
  </span>
</p>
<p class="h4"><span class="c5 c3 c2">12.7. Third-party beneficiaries</span></p>
<p class="c0">
  <span class="c1">
    Fondo’s Affiliates and Fondo’s and its Affiliates’ suppliers and distributors are intended third party beneficiaries
    of Sections 8 – 10 (inclusive). Except as expressly set forth in the foregoing, there are no third party
    beneficiaries to these terms.
  </span>
</p>
<p class="h2"><span class="c9 c3 c2">13. Definitions</span></p>
<p class="c0">
  <span class="c2">
    “</span><span class="c3 c2">Affiliate</span><span class="c1">” means any entity that directly or 
    indirectly Controls, is Controlled by, or is under common Control with a party.
  </span>
</p>
<p class="c0">
  <span class="c2">“</span><span class="c3 c2">Confidential Information</span><span class="c1">” means information that
  one party (or Affiliate) discloses to the other party under the agreement, and that is marked as confidential or would
  normally be considered confidential information under the circumstances. Customer Data is your Confidential
  Information. Confidential Information does not include information that is independently developed by the recipient,
  is shared with the recipient by a third party without confidentiality obligations, or becomes public through no fault
  of the recipient.
  </span>
</p>
<p class="c0">
  <span class="c2">“</span><span class="c3 c2">Control</span><span class="c1">” means control of greater than 50% of the
  voting rights or equity interests of a party.</span>
</p>
<p class="c0">
  <span class="c2">“</span><span class="c3 c2">Customer Data</span><span class="c1">” means data submitted, stored, sent
  or received via the Services by you, at your direction, or as part of the Services for you.</span>
</p>
<p class="c0">
  <span class="c2">“</span><span class="c3 c2">Delegates</span><span class="c1">” means employees, Affiliates, agents,
  and professional advisors.</span>
</p>
<p class="c0">
  <span class="c2">“</span><span class="c3 c2">including</span><span class="c1">” means including, without
  limitation.</span>
</p>
<p class="c0">
  <span class="c2">“</span><span class="c3 c2">Legal Process</span><span class="c1">” means an information disclosure
  request made under law, governmental regulation, court order, subpoena, warrant, governmental regulatory or agency
  request, or other valid legal authority, legal procedure, or similar process.
  </span>
</p>
<p class="c0">
  <span class="c2">“</span><span class="c3 c2">Order Form</span><span class="c1">” means the ordering document or
  website page for the Services.</span>
</p>
<p class="c0">
  <span class="c2">
    “</span><span class="c3 c2">Fondo</span><span class="c2">”, “</span><span class="c3 c2">we</span><span class="c2">”,
    and “</span><span class="c3 c2">us</span><span class="c1">” means BloomJoy, Inc. DBA Fondo.</span>
</p>
<p class="c7"><span class="c11">&nbsp;</span></p>
<p class="c7 c8"><span class="c11"></span></p>
</div>`;

export const FOREIGN_SUBSIDIARY_INFO_MODAL = `<div>
        <h4>5471 Filing</h4>
        <ul class="mb-4">
                <li>Schedule A (Parent and International Corp reporting)</li>
                <li>Schedule B (US shareholder reporting)</li>
                <li>Schedule C (International Income Statement reporting)</li>
                <li>Schedule E (Foreign Tax Credit reporting)</li>
                <li>Schedule F (Foreign Balance Sheet reporting)</li>
                <li>Schedule H (International earnings and profits reporting - current)</li>
                <li>Schedule I (Shareholder income from International Subsidiary)</li>
                <li>Schedule I-1 (Global Intangible Low-Taxed Income - GILTI Calculations)</li>
                <li>Schedule J (International earnings and profits reporting - accumulated)</li>
                <li>Schedule O (US Officers, Shareholder, Directors, Stock, Organization Chart reporting)</li>
                <li>Schedule P (Previously taxed earinings and profits reporting)</li>
        </ul>
        <h4>926 Filing</h4>
        <ul class="mb-4">
                <li>Transferor of property to international corporation reporting</li>
        </ul>
        <h4>8992 Filing</h4>
        <ul class="mb-4">
                <li>Global Intangible Low-Taxed Income - GILTI Reporting</li>
        </ul>
</div>`;
