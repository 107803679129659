import FeatherIcon from "feather-icons-react";
import { useState } from "react";

type SortChevronButtonProps = {
    sortName: string;
    currentSort: string;
    handleSort: (sortBy: string) => void;
};

const SortChevronButton = ({
  sortName,
  currentSort,
  handleSort,
} : SortChevronButtonProps) => {
  const [sortBy, setSortBy] = useState(sortName);

  const handleSetSort = ((currentSortBy: string) => {
    setSortBy(currentSortBy);
    handleSort(currentSortBy);
  });

  return (
    <>
      {(sortName !== currentSort.replace("-", "") || currentSort[0] !== "-") ? (
        <FeatherIcon
          icon="chevron-up"
          size="17"
          onClick={() => handleSetSort(`-${sortBy.replaceAll("-", "")}`)}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <FeatherIcon
          icon="chevron-down"
          size="17"
          onClick={() => handleSetSort(sortBy.replace("-", ""))}
          style={{ cursor: "pointer" }}
        />
      )}
    </>
  );
};

export default SortChevronButton;
