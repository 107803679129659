import { useState } from "react";
import { Button, CardGroup } from "react-bootstrap";
import ReportingContentCard from "./ReportingContentCard";
import cashBalance from "../../../assets/img/newCashBalance.svg";
import runway from "../../../assets/img/runway.svg";
import burn from "../../../assets/img/burn-rate.svg";
import { getAmountColorClassName } from "../../finance-dashboard/utils";
import { formatCurrency } from "../../../utils/common.util";
import { RunwayOptions, defaultRunwayOptions, ReportingType  } from "../Reporting.type";
import { formatPeriodInHumanReadableText, formatTimeframeEndInHumanReadableText } from "../utils";
import ReportingOptionsModal from "./ReportingOptionsModal";

type ReportingRunwayProps = {
  report: ReportingType;
};

const ReportingRunway = ({ report }: ReportingRunwayProps) => {
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [options, setOptions] = useState<RunwayOptions>({ ...defaultRunwayOptions, ...report.runwayOptions });
  const { cashFlowStats } = report.reportingData;

  const selectedMonthWords = formatPeriodInHumanReadableText(report);
  const selectedDateWords = formatTimeframeEndInHumanReadableText(report);


  const cashBalanceTitle = `CASH BALANCE (AS OF ${selectedDateWords})`;
  const fundingTitle = `FUNDING (${selectedMonthWords})`;
  const burnRateTitle = `BURN RATE  (${selectedMonthWords})`;
  const averageBurnRateTitle = "AVERAGE BURN RATE (PRIOR THREE MONTHS)";
  const runwayTitle = `RUNWAY (AS OF ${selectedDateWords})`;
  const cashZeroDate = "CASH ZERO DATE";

  let descriptionContent = null;

  if (options?.description?.enabled) {
    if (options.description.text?.trim()) {
      descriptionContent = options.description.text;
    } else {
      descriptionContent = `Below is a breakdown of your runway for ${selectedMonthWords}`;
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>Runway</h2>
        <div>
          {<Button size="sm" className="px-4" onClick={() => setShowOptionsModal(true)}>Edit</Button>}
        </div>
      </div>
      <p className="text-secondary">{descriptionContent}</p>
      <CardGroup className="my-4">
        {options.cashBalance && (
          <ReportingContentCard
            title={cashBalanceTitle}
            roundedLeft={true}
            textBody={formatCurrency(cashFlowStats.currentBalance || "0")}
            icon={cashBalance}
          />
        )}
        {options.funding && (
          <ReportingContentCard
            title={fundingTitle}
            roundedRight={true}
            textBody={formatCurrency(cashFlowStats.funding || "0")}
            icon={cashBalance}
          />
        )}
      </CardGroup>
      <CardGroup className="my-4">
        {options.burnRate && (
          <ReportingContentCard
            title={burnRateTitle}
            roundedLeft={true}
            textBody={
              <span
                className={`text-${getAmountColorClassName(Number(cashFlowStats.currentBurn))}`}
              >
                {formatCurrency(cashFlowStats.currentBurn || "0")}
              </span>
            }
            icon={burn}
          />
        )}
        {options.averageBurnRate && (
          <ReportingContentCard
            title={averageBurnRateTitle}
            roundedRight={true}
            textBody={
              <span
                className={`text-${getAmountColorClassName(Number(cashFlowStats.averageBurn))}`}
              >
                {formatCurrency(cashFlowStats.averageBurn || "0")}
              </span>
            }
            icon={burn}
          />
        )}
      </CardGroup>
      <CardGroup className="my-4">
        {options.runway && (
          <ReportingContentCard
            roundedLeft={true}
            title={runwayTitle}
            textBody={cashFlowStats.runway || "N/A"}
            icon={runway}
          />
        )}
        {options.cashZeroDate && (
          <ReportingContentCard
            title={cashZeroDate}
            textBody={cashFlowStats.cashZeroDate || "N/A"}
            roundedRight={true}
            icon={runway}
          />
        )}
      </CardGroup>
      <hr className="my-5" />
      <ReportingOptionsModal
        sectionType = "runway"
        show={showOptionsModal}
        onHide={() => setShowOptionsModal(false)}
        initialOptions={options}
        reportUuid={report.uuid}
        onUpdate={(newOptions: RunwayOptions) => {
          setOptions(newOptions);
        }}
      />
      <div id="runway-data-loaded" />
    </>
  );
};

export default ReportingRunway;
