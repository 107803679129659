import { Button, Modal, ModalBody } from "react-bootstrap";
import RndIsEligibleImage from "../../../imgs/rnd-is-eligible.png";
import RndIsNotEligibleImage from "../../../imgs/rnd-is-not-eligible.png";

type RnDEligiblityModalProps = {
  show: boolean,
  onClose: () => void,
  eligiblity: boolean,
};

const RnDEligiblityModal = ({ show, onClose, eligiblity }: RnDEligiblityModalProps) => (
  <Modal show={show}>
    <ModalBody style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}>
      {eligiblity && (
        <>
          <img src={RndIsEligibleImage} className="img-fluid" alt="You are eligible" style={{ width: "50%" }} />
          <h3 className="mt-4 mb-3 text-center">You are eligible for an R&D Tax Credit</h3>
          <h5 className="text-center text-secondary">
            Continue filling out the form to get a more accurate estimate of your possible tax credit.
          </h5>
        </>
      )}
      {!eligiblity && (
        <>
          <img src={RndIsNotEligibleImage} className="img-fluid" alt="Not eligible" style={{ width: "50%" }} />
          <h3 className="mt-4 mb-3 text-center">You are not eligible for an R&D Tax Credit</h3>
          <h5 className="text-center text-secondary">
            Resubmit the form with different answers to be considered eligible.
          </h5>
        </>
      )}
      <Button className="mt-3 mb-3" size="sm" onClick={onClose}>Close</Button>
    </ModalBody>
  </Modal>
);

export default RnDEligiblityModal;
