import { COMPANY_DASHBOARD_ONBOARDING } from "../../../constants/network-calls";
import { CompanyDashboardOnboarding } from "./company.type";
import { getFondoApi, postFondoApi } from "../../../utils/util.api";

export async function getCompanyDashbaordOnboarding(): Promise<CompanyDashboardOnboarding> {
  const result = await getFondoApi(COMPANY_DASHBOARD_ONBOARDING);
  return result as CompanyDashboardOnboarding;
}

export async function upsertCompanyDashboardOnboarding(
  data: CompanyDashboardOnboarding,
): Promise<string> {
  const result: any = await postFondoApi(COMPANY_DASHBOARD_ONBOARDING, data);
  return result;
}
