import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DASHBOARD, DASHBOARD_ONBOARDING_COMPANY } from "../../../constants/network-calls";
import BankAccountsIntegrationCardV2 from "../../integrations/BankAccountsIntegrationCardV2";
import { amountOfConnectedAccounts } from "../../integrations/integrations.api";
import { getFlowTracking } from "../../onboarding/onboarding.api";
import SVGShieldIcon from "../../svg-components/SVGShieldIcon";
import { submitDashboardOnboardingConnections } from "./connections.api";

const Connections = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [flowTrackingSteps, setFlowTrackingSteps] = useState<string[]>([]);
  const [amountOfAccounts, setAmountOfConnectedAccounts] = useState<number>(0);

  useQuery<any, Error>(
    ["flowTrackingStep"],
    () => getFlowTracking("dashboard-onboarding"),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.length) {
          setFlowTrackingSteps(data);
        }
      },
    },
  );

  useQuery<any, Error>(["amountOfConnectedAccounts"], amountOfConnectedAccounts, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setAmountOfConnectedAccounts(data);
    },
  });

  // Submit only if the connections step  hasn't been generated yet.
  useQuery<any, Error>(["completeConnections"], submitDashboardOnboardingConnections, {
    refetchOnWindowFocus: false,
    enabled: (
      !!flowTrackingSteps.length
      && !flowTrackingSteps.includes("connections")
      && amountOfAccounts > 0
    ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["flowTrackingSteps"] });
    },
  });

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-10 col-xl-8 mx-3">
        <h2>Connect Bank Accounts</h2>
        <p className="text-muted">
          Connect your bank account(s) to populate your dashboard with your financial data.
        </p>
        <div>
          <BankAccountsIntegrationCardV2 amountOfAccounts={amountOfAccounts} />
          <Alert variant="secondary" className="d-flex align-items-center">
            <div className="me-3">
              <SVGShieldIcon />
            </div>
            <div>
              <b className="mb-1">Your account is safe with Fondo.</b>
              <p className="mb-1">We will never remove any money out of your account without prior authorization.</p>
            </div>
          </Alert>
          <hr className="row my-5"/>
          <div className="row mb-3 mt-2">
            <div className="d-flex flex-row col-12 col-md-12 justify-content-between ">
              <Button
                className="btn btn-lg btn-light"
                variant="white"
                onClick={() => navigate(DASHBOARD_ONBOARDING_COMPANY)}
              >
                Back
              </Button>
              <div className="justify-content-between">
                {!amountOfAccounts && (
                  <Button
                    className="btn btn-lg btn-light"
                    variant="white"
                    onClick={() => navigate(DASHBOARD)}
                  >
                    Later
                  </Button>
                )}
                <Button
                  className={`btn btn-lg btn-primary ms-4 ${!amountOfAccounts && "disabled"}`}
                  onClick={() => navigate(DASHBOARD)}
                >
                  {<div>Connect Now</div>}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connections;
