import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FeatherIcon from "feather-icons-react";
import { useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { AxiosError } from "axios";
import { getMergeDevLinkedAccounts, getMergeDevLinkToken, retrieveMergeDevAccountToken } from "./integrations.api";
import DeleteTrashButton from "../fusion-kit/DeleteTrashButton";
import { MERGE_DEV_LINKED_ACCOUNTS } from "../../constants/network-calls";
import { LinkedAccount, LinkToken } from "./integrations.type";

type MergeDevCardProps = {
  category: string;
  title: string;
  addButtonTitle?: string;
  icon?: string;
  queriesToRefresh?: string[];
}

const MergeDevCard = (
  {
    category,
    title,
    addButtonTitle = "Link Account",
    icon,
    queriesToRefresh = [],
  } : MergeDevCardProps,
) => {
  const linkedAccountDataQueryKey = `linkedAccountsData-${category}`;
  const queryClient = useQueryClient();
  const [linkToken, setLinkToken] = useState("");
  queriesToRefresh.push(linkedAccountDataQueryKey);

  const getAccountTokenMutation = useMutation<any, AxiosError<any>, any>(
    (publicToken: string) => retrieveMergeDevAccountToken(publicToken),
  );

  const onSuccess = useCallback((publicToken: string) => {
    getAccountTokenMutation.mutate(
      publicToken,
      {
        onSuccess: () => {
          for (let i = 0; i < queriesToRefresh.length; i += 1) {
            queryClient.invalidateQueries([queriesToRefresh[i]]);
          }
        },
      },
    );
  }, []);

  const { open, isReady } = useMergeLink({
    linkToken: linkToken || "",
    onSuccess,
  });

  const linkTokenDataQuery = useQuery<any, Error>(
    ["linkToken", category],
    () => getMergeDevLinkToken(category),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data: LinkToken) => {
        setLinkToken(data.linkToken);
      },
    },
  );

  const linkedAccountsQueryData = useQuery<any, Error>(
    [linkedAccountDataQueryKey, category],
    () => getMergeDevLinkedAccounts(category),
    { refetchOnWindowFocus: false },
  );

  if (linkTokenDataQuery.isFetching) {
    return (
            <div className="row justify-content-center">
                <Spinner className="text-primary my-4"/>
            </div>
    );
  }

  const hasLinkedAccountsForTheCategory = linkedAccountsQueryData?.data?.length > 0;

  return (
        <div className="card justify" style={{ position: "static" }}>
            <div className="card-body">
              {icon ? (
                <div className="text-center p-2">
                  <img src={icon} width="60px" />
                </div>
              ) : (
                <h1 className="text-uppercase text-center text-muted my-4">
                  <i className="fe fe-lock"></i>
                </h1>
              )}
              <div className="row no-gutters align-items-center justify-content-center">
                  <div className="col-auto">
                      <div className="h1 mb-0">{title}</div>
                  </div>
              </div>
                <div className="mb-3">
                    <ul className="list-group list-group-flush">
                        {
                            <li
                                className="list-group-item d-flex align-items-center justify-content-between"
                            >
                                <span>{hasLinkedAccountsForTheCategory ? "Connected" : "Not Connected"}</span>
                                <FeatherIcon
                                    icon={hasLinkedAccountsForTheCategory ? "check-circle" : "x"}
                                    className={hasLinkedAccountsForTheCategory ? "text-success" : "text-danger"}
                                    size="1.1em"
                                />
                            </li>
                        }
                    </ul>
                </div>
                {hasLinkedAccountsForTheCategory && linkedAccountsQueryData.data.map(
                  (linkedAccount: LinkedAccount, idx: number) => (
                        <div key={`linked-account${linkedAccount.integration}-${idx}`}>
                            <div
                                className="font-weight-bold mb-2 d-flex justify-content-between"
                                style={{ fontWeight: "bold" }}
                            >
                                <span
                                    className="font-weight-bold mb-1"
                                    style={{ fontWeight: "bold" }}
                                >
                                    {linkedAccount.integration}
                                </span>
                                <DeleteTrashButton
                                    id={linkedAccount.uuid}
                                    deleteURL={MERGE_DEV_LINKED_ACCOUNTS}
                                    queryKey={queriesToRefresh}
                                    askConfirmation={true}
                                />
                            </div>
                        </div>
                  ),
                )}
                <button
                  className={"btn btn-block btn-primary full-width"}
                  onClick={open}
                  disabled={!isReady || !!hasLinkedAccountsForTheCategory}
                >
                  {addButtonTitle}
                </button>
            </div>
        </div>
  );
};

export default MergeDevCard;
