import { Col, Row } from "react-bootstrap";
import { convertNumberToCurrency } from "../../../utils/utils";

type TotalSummaryOfChargesRowsProps = {
  name: string;
  totalFinalPrice: number;
  textBelowName?: string;
  textBelowFinalPrice: string[];
};

const TotalSummaryOfChargesRows = (
  {
    name,
    totalFinalPrice,
    textBelowName,
    textBelowFinalPrice,
  }: TotalSummaryOfChargesRowsProps,
) => (
  <>
    <Row className="justify-content-between mt-3">
      <Col className="justify-content-start col-9">
        <p className="p-0 m-0">
          <small>{name}</small>
        </p>
        {textBelowName && (
          <p className="fs-6 text-mutedp-0 m-0">
            <small className="text-muted">
              {textBelowName}
            </small>
          </p>
        )}
      </Col>
      <Col className="text-end">
        <p className="p-0 m-0">
          <small>${convertNumberToCurrency(totalFinalPrice)}</small>
        </p>
        {textBelowFinalPrice.length > 0 && (
          <p className="fs-6 text-mutedp-0 m-0">
            <small className="text-muted">
              {textBelowFinalPrice.map((text: string) => (<>+ {text}<br /></>))}
            </small>
          </p>
        )}
      </Col>
    </Row>
  </>
);

export default TotalSummaryOfChargesRows;
