import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import OrderCard from "./OrderCard";
import ProductsCard from "./ProductsCard";
import "../onboarding.css";

const Checkout = () => {
  const navigate = useNavigate();
  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-10 col-xl-8 card p-0">
        <Row className="gx-0">
          <Col className="container-bg">
            <ProductsCard />
            <div className="ms-5 mb-4">
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/onboarding/tax-plan");
                }}
              >
                <FeatherIcon className="mb-1" icon="chevron-left" size="15" />
                Review your information
              </a>
            </div>
          </Col>
          <Col>
            <OrderCard />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Checkout;
