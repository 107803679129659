import { Card } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { convertNumberToCurrency } from "../../../utils/utils";
import { Payroll } from "../financeDashboard.type";

type TaxPlanAssetsAndLiabilitiesBreakdownCardProps = {
  payroll: Payroll;
}

const TaxPlanAssetsAndLiabilitiesBreakdownCard = (
  { payroll }: TaxPlanAssetsAndLiabilitiesBreakdownCardProps,
) => (
  <Card>
    <Card.Body className="p-0">
      <div className="border-bottom p-4 pb-3">
        <Card.Title className="text-uppercase text-muted mb-2">
          <span className="h5 text-secondary">Balance Sheet</span>
        </Card.Title>
        <span className="h1 mb-0">
          ${convertNumberToCurrency(payroll?.assets || 0)}
        </span>
      </div>

      <div className="p-4">
        <div className="d-flex justify-content-between">
          <span>
            <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
            Assets
          </span>
          <span>${convertNumberToCurrency(payroll?.assets || 0)}</span>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <span>
            <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
            Equity & Liabilities
          </span>
          <span>
            ${convertNumberToCurrency(payroll?.equityAndLiabilities || payroll?.assets || 0)}
          </span>
        </div>

      </div>
    </Card.Body>
  </Card>
);

export default TaxPlanAssetsAndLiabilitiesBreakdownCard;
