import { QBO_AUTH_BASE_URL, QBO_CLIENT_ID, REACT_BASE_URL } from "../../constants/config";

import { BANKING_INTEGRATION_TOKEN_CALLBACK } from "../../constants/network-calls";
import { generateRandomString } from "./utils";

export default function QboAuthorize() {
  // step 1 - When user opens the web app create the code verifier and state and store them in the storage
  const clientId = QBO_CLIENT_ID || "";
  const redirectUri = `${REACT_BASE_URL}${BANKING_INTEGRATION_TOKEN_CALLBACK}`;
  const state = generateRandomString(16, clientId,  redirectUri);
  localStorage.setItem("x-provider-state", state);

  // step 2 - Once the code challenge is created, redirect to the authorization server with code challenge
  const params = new URLSearchParams();
  params.append("client_id", clientId);
  params.append("scope", "com.intuit.quickbooks.accounting");
  params.append("redirect_uri", redirectUri);
  params.append("response_type", "code");
  params.append("state", state);

  window.location.href = `${QBO_AUTH_BASE_URL}/connect/oauth2?${params.toString()}`;

  return null;
}
