import { useParams } from "react-router-dom";
import Post2023CorporateTax from "./post-2023/Post2023CorporateTax";

const CorporateTaxRouter = () => {
  const { taxYear } = useParams();

  if (!taxYear) {
    return <></>;
  }
  return <Post2023CorporateTax taxYear={taxYear} />;
};

export default CorporateTaxRouter;
