import sparks from "../../assets/img/icons/sparks.png"
import { BotTrack } from "../../utils/util.type";
import AIChatBotMessageEvaluateAndCopy from "./AIChatBotMessageEvaluateAndCopy";

type PurpleAIChatBotMessageProps = {
  message: string;
  isUser: boolean;
  botTrack?: BotTrack;
}

const PurpleAIChatBotMessage = ({ message, isUser, botTrack }: PurpleAIChatBotMessageProps) => {
  
  const userMessageStyleProps = {
    className: "bg-light p-3 mb-4 ms-auto",
    style: {
      borderRadius: "10px 10px 0 10px", 
      width: "95%",
    }
  };
  
  const botMessageStyleProps = {
    className: "text-white bg-gradient p-4 mb-4",
    style: {
      borderRadius: "10px 10px 10px 0",
      width: "95%",
      background: "#7431FA",
      borderColor: "#7431FA",
    },
  };

  const messageStyleProps = isUser ? userMessageStyleProps : botMessageStyleProps;

  return (
    <>
      <div {...messageStyleProps}>
        {!isUser && (<img src={sparks} width={20} className="me-2 mt-n1" />)}
        {message}
        {!isUser && botTrack && (
          <AIChatBotMessageEvaluateAndCopy botTrack={botTrack} contentToCopy={message} />
        )}
      </div>
      
    </>

  )
}

export default PurpleAIChatBotMessage
