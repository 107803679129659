import { useNavigate } from "react-router-dom";
import { ONBOARDING_COMPANY_2 } from "../../constants/network-calls";

const NonDeCCorpView = () => {
  const navigate = useNavigate();

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-10 col-xl-8 card">
        <div className="row">
          <div className='col-12 col-md-12 py-5 px-0 text-align-center'>
            <h1 className="mb-4">
              We’re sorry, we currently only support Delaware C Corps.
            </h1>
            <p className="mb-4 text-muted">
              We will contact you when we are able to help you with your taxes.
            </p>
            <button
              onClick={() => navigate(ONBOARDING_COMPANY_2)}
              className="btn btn-primary btn-md mb-4"
              style={{ width: "40%" }}
            >
                Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonDeCCorpView;
