import { useState } from "react";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import {
  CapitalContributionsDistributionAndDividendsFormFields,
  ForeignSubsidiaryFormFields,
} from "../foreignSubsidiary.type";
import AddCapitalContributionsDistributionAndDividendsModal
  from "../modals/AddCapitalContributionsDistributionAndDividendsModal";

type DistributionAndDividendsProps = {
  arrayHelpers: FieldArrayRenderProps;
}

const DistributionAndDividends = ({ arrayHelpers }: DistributionAndDividendsProps) => {
  const formikContext = useFormikContext<ForeignSubsidiaryFormFields>();
  const [showAddDistributionAndDividendsModal, setShowAddDistributionAndDividendsModal] = useState(false);
  const [
    currentlySelectedDistributionAndDividendsIndex,
    setCurrentlySelectedDistributionAndDividendsIndex,
  ] = useState<number | undefined>();

  const handleClose = () => {
    setShowAddDistributionAndDividendsModal(false);
    setCurrentlySelectedDistributionAndDividendsIndex(undefined);
  };

  const handleSave = (distributionAndDividendsData: CapitalContributionsDistributionAndDividendsFormFields) => {
    if (currentlySelectedDistributionAndDividendsIndex !== undefined) {
      arrayHelpers.replace(currentlySelectedDistributionAndDividendsIndex, distributionAndDividendsData);
    } else {
      arrayHelpers.push(distributionAndDividendsData);
    }
    handleClose();
  };

  const handleDelete = (
    index: number,
    distributionAndDividends: CapitalContributionsDistributionAndDividendsFormFields,
  ) => {
    if (distributionAndDividends.uuid) {
      formikContext.setFieldValue(
        "oldDistributionAndDividends",
        [...formikContext.values.oldDistributionAndDividends, distributionAndDividends.uuid],
      );
    }
    arrayHelpers.remove(index);
  };

  const fieldHasError = (index: number, fields: string[]): boolean => (
    fields.some((field) => !!(formikContext.errors?.distributionAndDividends?.[index] as any)?.[field])
  );

  const renderDistributionAndDividends = () => (
    formikContext.values.distributionAndDividends?.map((distributionAndDividends, index: number) => (
      <div className="col-lg-6 col-xl-4 mb-5">
        <div className="d-flex justify-content-between">
          <h3 className={`${formikContext.errors?.distributionAndDividends?.[index] ? "text-danger" : ""}`}>
            {distributionAndDividends.description || ""}
            {formikContext.errors?.distributionAndDividends?.[index] && (<i
              className="fe fe-alert-circle text-danger ms-2"
              style={{ marginBottom: "-0.1em" }}
            />)}
          </h3>
          <div>
            <span>
              <i
                className="fe fe-edit-2 mx-2 action-icon text-secondary"
                onClick={() => {
                  setCurrentlySelectedDistributionAndDividendsIndex(index);
                  setShowAddDistributionAndDividendsModal(true);
                }}
                style={{ cursor: "pointer" }}
                title="Edit"
              ></i>
            </span>
            <span>
              <i
                className="fe fe-trash mx-2 action-icon text-secondary"
                title="Delete"
                style={{ cursor: "pointer" }}
                onClick={() => handleDelete(index, distributionAndDividends)}
              ></i>
            </span>
          </div>
        </div>
        <hr className='mt-0' />
        <div className='row'>
          <p
            className={`data-item ${fieldHasError(index, ["date"]) ? "text-danger" : ""}`}
          >
            Date:{" "}
            {distributionAndDividends?.date}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["description"]) ? "text-danger" : ""}`}
          >
            Description:{" "}
            {distributionAndDividends?.description}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["value"]) ? "text-danger" : ""}`}
          >
            Value:{" "}
            {distributionAndDividends?.value}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["currency"]) ? "text-danger" : ""}`}
          >
            Currency:{" "}
            {distributionAndDividends?.currency}
          </p>
        </div>
      </div>
    ))
  );
  return (<>
    {formikContext.values.distributionAndDividends?.length > 0 && (
      <div>
        <div className="row mt-5">
          {renderDistributionAndDividends()}
        </div>
        <div className="mb-5">
          <button type="button" className="btn btn-primary" onClick={() => {
            setShowAddDistributionAndDividendsModal(true);
          }}>Add another</button>
        </div>
      </div>
    )}

    {(formikContext.values.distributionAndDividends?.length === 0)
      && <div className='text-align-center' style={{ margin: "200px" }}>
        <h3 className="text-secondary mb-4">Start by adding a new distribution and dividends</h3>
        <button type="button" className="btn btn-primary" onClick={() => {
          setShowAddDistributionAndDividendsModal(true);
        }}>Add new</button>
      </div>
    }

    <AddCapitalContributionsDistributionAndDividendsModal
      show={showAddDistributionAndDividendsModal}
      handleClose={handleClose}
      handleSave={handleSave}
      capitalContributionsDistributionAndDividends={currentlySelectedDistributionAndDividendsIndex !== undefined
        ? formikContext.values.distributionAndDividends[currentlySelectedDistributionAndDividendsIndex] : undefined}
      header="Add new Distribution and Dividend"
    />
  </>);
};

export default DistributionAndDividends;
