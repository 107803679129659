import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  Formik, Field, Form, ErrorMessage,
} from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import { FORM_ERROR_MSG } from "../../constants/general";
import { CompanyPaymentInfo } from "./settings.type";
import { getCompanyPaymentInfo, saveCompanyPaymentInfo } from "./settings.api";
import { Country, Subdivision } from "../../utils/util.type";
import { getCountries, getCountrySubdivisions } from "../../utils/util.api";

export default function CompanyPaymentInfoSettings() {
  const companyPaymentInfoQuery = useQuery(
    ["companyPaymentInfoSettings"],
    () => getCompanyPaymentInfo(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.state) {
          data.country = data.state.slice(0, 2);
        }
        setFormValues(data);
        setSelectedCountry(data.country);
      },
    },
  );

  const [formValues, setFormValues] = useState<CompanyPaymentInfo>({
    id: "",
    bankName: "",
    accountType: "",
    routingNumber: "",
    accountNumber: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });
  const companyPaymentInfoMutation = useMutation<any, AxiosError<any>, CompanyPaymentInfo>(
    (data) => saveCompanyPaymentInfo(data),
  );

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const countryQuery = useQuery<Country[], Error>(["country"], getCountries, { refetchOnWindowFocus: false });
  const countryStateQuery = useQuery<Subdivision[], Error>(
    ["countryState", selectedCountry],
    getCountrySubdivisions,
    { enabled: false },
  );

  useEffect(() => {
    countryStateQuery.refetch();
  }, [selectedCountry]);

  function renderCountryList() {
    return (
      countryQuery.data
            && countryQuery.data.map((country) => (
                <option key={country.alpha2} value={country.alpha2} selected={country.alpha2 === selectedCountry}>
                    {country.name}
                </option>
            ))
    );
  }

  function renderCountryStateList() {
    return (
      countryStateQuery.data
            && countryStateQuery.data.map((state) => (
                    <option key={state.code} value={state.code} selected={state.code === selectedCountry}>
                        {state.name}
                    </option>
            ))
    );
  }

  if (companyPaymentInfoQuery.isLoading) {
    return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
    );
  }

  return (
        <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
              bankName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
              accountType: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
              // routingNumber: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
              // accountNumber: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
              streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
              city: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
              state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
              country: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
              postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
            })}
            onSubmit={(values, { setErrors }) => {
              companyPaymentInfoMutation.mutate(values, {
                onError: (err) => {
                  const errors = err?.response?.data;
                  if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
                    setErrors(errors);
                  }
                },
                onSuccess: () => {
                  // console.log(res)
                },
              });
            }}>
            {(props) => (
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8 card">
                            <Form className="card-body my-5 mx-3">
                                <div className="row mb-3 mt-2">
                                    <div className="col-12 col-md-6">
                                        <label htmlFor="bankName">Bank name</label>
                                        <Field name="bankName" type="text" className={"form-control"} />
                                        <ErrorMessage component="div" className="text-danger" name="bankName" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label htmlFor="accountType">Account type</label>
                                        <Field
                                            name="accountType"
                                            as="select"
                                            value={props.values.accountType}
                                            className={"form-control form-select"}
                                            onChange={(e: any) => {
                                              props.setFieldValue("accountType", e.target.value);
                                            }}>
                                            <option value={""}>Select account type</option>
                                            <option value='business_checking'>Business Checking</option>
                                            <option value='savings'>Savings</option>
                                        </Field>
                                        <ErrorMessage component="div" className="text-danger mt-1" name="accountType" />
                                    </div>
                                </div>
                                <div className="row mb-3 mt-2">
                                    <div className="col-12 col-md-12">
                                        <label htmlFor="accountNumber">Account number</label>
                                        <Field name="accountNumber" type="text" className={"form-control"} />
                                        <ErrorMessage component="div" className="text-danger" name="accountNumber" />
                                    </div>
                                </div>
                                <div className="row mb-3 mt-2">
                                    <div className="col-12 col-md-12">
                                        <label htmlFor="routingNumber">Routing number</label>
                                        <Field name="routingNumber" type="text" className={"form-control"} />
                                        <ErrorMessage component="div" className="text-danger" name="routingNumber" />
                                    </div>
                                </div>
                                <div className="row mb-3 mt-2">
                                    <div className="col-12 col-md-12">
                                        <label htmlFor="streetAddress">Street address</label>
                                        <Field name="streetAddress" type="text" className={"form-control"} />
                                        <ErrorMessage component="div" className="text-danger" name="streetAddress" />
                                    </div>
                                </div>
                                {/* {Object.keys(props.errors).map
                                  (k => (<span>{k} - {(props.errors as any)[k]}</span>))} */}
                                <div className="row mb-3 mt-2">
                                    <div className="col-12 col-md-12">
                                        <label htmlFor="country">Country</label>
                                        <Field
                                            name="country"
                                            as="select"
                                            value={props.values.country}
                                            className={"form-control form-select"}
                                            onChange={(e: any) => {
                                              setSelectedCountry(e.target.value);
                                              props.setFieldValue("country", e.target.value);
                                            }}>
                                            <option value={""}>Select Country</option>
                                            {renderCountryList()}
                                        </Field>
                                        <ErrorMessage component="div" className="text-danger" name="country" />
                                    </div>
                                </div>
                                <div className="row mb-3 mt-2">
                                    <div className="col-12 col-md-12">
                                        <label htmlFor="city">City</label>
                                        <Field name="city" type="text" className={"form-control"} />
                                        <ErrorMessage component="div" className="text-danger" name="city" />
                                    </div>
                                </div>
                                <div className="row mb-3 mt-2">
                                    <div className="col-12 col-md-12">
                                        <label htmlFor="state">State</label>
                                        <Field
                                            name="state"
                                            as="select"
                                            className={"form-control form-select"}
                                            onChange={(e: any) => {
                                              props.setFieldValue("state", e.target.value);
                                            }}>
                                            <option value={""}>Select State</option>
                                            {renderCountryStateList()}
                                        </Field>
                                        <ErrorMessage component="div" className="text-danger" name="state" />
                                    </div>
                                </div>
                                <div className="row mb-3 mt-2">
                                    <div className="col-12 col-md-12">
                                        <label htmlFor="postalCode">Postal code</label>
                                        <Field name="postalCode" type="text" className={"form-control"} />
                                        <ErrorMessage component="div" className="text-danger" name="postalCode" />
                                    </div>
                                </div>
                                <div className="row mb-3 mt-2">
                                    <div className="d-flex flex-row col-12 col-md-12 justify-content-end">
                                        <button
                                          className="btn btn-lg btn-primary"
                                          type="submit"
                                          disabled={companyPaymentInfoMutation.isLoading}>
                                            {companyPaymentInfoMutation.isLoading
                                              ? <span><Spinner size="sm" />  Saving</span>
                                              : <div>Save billing information</div>}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
            )}
        </Formik>
  );
}
