import { useFormikContext } from "formik";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import { fieldToLabelMap, fieldToTypeMap, RnDValues } from "./baseFormData";

const RnDPage1 = () => {
  const formikContext = useFormikContext<RnDValues>();

  return (
    <>
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="paidToEmployees"
        labels={fieldToLabelMap}
        types={fieldToTypeMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="paidToContractors"
        labels={fieldToLabelMap}
        types={fieldToTypeMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="paidOnSupplies"
        labels={fieldToLabelMap}
        types={fieldToTypeMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="revenueMoreThanFiveMillion"
        labels={fieldToLabelMap}
        types={fieldToTypeMap}
      />

      <h5>Tell Us About You</h5>
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="companyWebsite"
        labels={fieldToLabelMap}
        types={fieldToTypeMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="companyDescription"
        labels={fieldToLabelMap}
        types={fieldToTypeMap}
      />

      <h5>1. Business Component Test</h5>
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="createImproveProduct"
        labels={fieldToLabelMap}
        types={fieldToTypeMap}
      />
      {formikContext.values.createImproveProduct === "True" && (
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="createdOrImprovedDescription"
          labels={fieldToLabelMap}
          types={fieldToTypeMap}
        />
      )}

      <h5>2. Technological in Nature Test</h5>
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="technologicalActivities"
        labels={fieldToLabelMap}
        types={fieldToTypeMap}
      />
      {formikContext.values.technologicalActivities === "True" && (
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="technologicalActivitiesDescription"
          labels={fieldToLabelMap}
          types={fieldToTypeMap}
        />
      )}

      <h5>3. Elimination of Uncertainty Test</h5>
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="facedUncertainty"
        labels={fieldToLabelMap}
        types={fieldToTypeMap}
      />
      {formikContext.values.facedUncertainty === "True" && (
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="uncertaintyDescription"
          labels={fieldToLabelMap}
          types={fieldToTypeMap}
        />
      )}

      <h5>4. Process of Experimentation Test</h5>
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="experimentalProcesses"
        labels={fieldToLabelMap}
        types={fieldToTypeMap}
      />
      {formikContext.values.experimentalProcesses === "True" && (
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="experimentalProcessesDescription"
          labels={fieldToLabelMap}
          types={fieldToTypeMap}
        />
      )}
    </>
  );
};

export default RnDPage1;
