import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_ONBOARDING_CONNECTIONS } from "../../../constants/network-calls";
import { amountOfConnectedAccounts } from "../../integrations/integrations.api";
import CashBalanceCard from "../../runway/CashBalanceCard";
import AdditionalFondoServicesCard from "../sales/AdditionalFondoServicesCard";
import TaxStatus from "../../tax-status/taxStatus";
import TaxDeadlines from "../../tax-deadlines/TaxDeadlines";
import LightCashFlowRow from "../../dashboard/LightCashFlowRow";
import { ConditionalWrapper, formatDateToStringYMDWithDash, formatDateToWords } from "../../../utils/common.util";
import TimeRangeFixedDate from "../../finance-dashboard/TimeRangeFixedDate";
import { getBalanceAvailableMonths, getTransactions } from "../../finance-dashboard/FinanceDashboard.api";
import { getCashFlowStats } from "../../dashboard/dashboard.api";
import { CashFlowStats } from "../../dashboard/dashboard.type";
import { TaxStatusType } from "../../tax-status/taxStatus.type";
import { getProductStatus } from "../../tax-status/taxStatus.api";
import { LoggedInUserContext } from "../../../context/LoggedInUserContext";
import { DateRangeIntervals, Transaction } from "../../finance-dashboard/financeDashboard.type";
import { getVerifyTransactionsOverlay } from "../../finance-dashboard/verify-transactions/VerifyTransactionsOverlay";
import BlurWrapper from "../../fondo-components/BlurWrapper";

const DashboardOnboardingHomeCard = () => {
  const navigate = useNavigate();
  const { currentDashboardOnboarding } = useContext(LoggedInUserContext);
  const [amountOfAccounts, setAmountOfConnectedAccounts] = useState<number>(0);
  useQuery<any, Error>(["amountOfConnectedAccounts"], amountOfConnectedAccounts, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setAmountOfConnectedAccounts(data);
    },
  });

  const [selectedMonth, setSelectedMonth] = useState<string>("");

  useQuery<DateRangeIntervals, Error>(
    ["balanceAvailableMonths"],
    () => getBalanceAvailableMonths(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (dateRange) => {
        if (dateRange.months.length) {
          setSelectedMonth(dateRange.months[0]);
        }
      },
    },
  );

  const { data: transactionsToVerify, isLoading: transactionsToVerifyIsLoading } = useQuery<Transaction[], Error>(
    ["transactionsToVerify"],
    getTransactions,
    { refetchOnWindowFocus: false },
  );

  const { data: cashFlowStatsData, isLoading: cashFlowStatsDataIsLoading } = useQuery<CashFlowStats, Error>(
    ["cashFlowStats"],
    getCashFlowStats,
    {
      refetchOnWindowFocus: false,
      enabled: !transactionsToVerifyIsLoading,
      // There is a race condition between verified transactions and cashFlowStats, where cashFlowStats can load first
      // This enabled allows us to load the transactions first, then the cashFlowStats
    },
  );

  const { data: taxStatusData, isLoading: taxStatusDataIsLoading } = useQuery<TaxStatusType[], Error>(
    ["productStatus"],
    getProductStatus,
    { refetchOnWindowFocus: false },
  );

  // Yoinked from the Finance Dashboard component
  const hasTransactionsToVerify = (transactionsToVerify && transactionsToVerify.length > 0) || false;

  let buttonRedirectUrl = "/dashboard/integrations";
  if (currentDashboardOnboarding) buttonRedirectUrl = DASHBOARD_ONBOARDING_CONNECTIONS;

  return (
    <>
      {!amountOfAccounts && (
        <Button
          className="btn btn-block btn-primary full-width py-3"
          onClick={() => navigate(buttonRedirectUrl)}
        >
          Connect a Bank to activate your Free Dashboard.
        </Button>
      )}
      {!!amountOfAccounts && (
        <h2>
          {formatDateToWords(formatDateToStringYMDWithDash())}
        </h2>
      )}
      <div className="my-3">
        <div className="my-3">
          <CashBalanceCard
            disabledState={!amountOfAccounts}
            showingCurrentMonthBalanceOnly={true}
            showRefreshBalanceSection={true}
          />
        </div>
      </div>
      <ConditionalWrapper
        condition={hasTransactionsToVerify}
        wrapper={(children: React.ReactNode) => (
          <BlurWrapper overlayNode={getVerifyTransactionsOverlay()}>
            {children}
          </BlurWrapper>
        )}
      >
        <div className="my-4">
          {selectedMonth && !!amountOfAccounts && (
            <TimeRangeFixedDate
              selectedMonth={selectedMonth}
            />
          )}
          {cashFlowStatsDataIsLoading && transactionsToVerifyIsLoading ? (
            <LightCashFlowRow
              loadingState={true}
              selectedMonth={selectedMonth}
            />
          ) : (
            <LightCashFlowRow
              cashFlowStatsData={cashFlowStatsData}
              selectedMonth={selectedMonth}
            />
          )}
        </div>
      </ConditionalWrapper>
      <Row className="my-4 mb-0">
        {taxStatusData && taxStatusData.length > 0 && (
          <Col xl={7} lg={12}>
            <TaxStatus
              showAsWidget={true}
              taxStatusData={taxStatusData}
              isLoading={taxStatusDataIsLoading}
            />
          </Col>
        )}
        <Col xl={taxStatusData && taxStatusData.length > 0 ? "5" : "12"} lg={12}>
          <TaxDeadlines showFutureDeadlinesOnly={true} showAsWidget={true} />
        </Col>
      </Row>
      <AdditionalFondoServicesCard />
    </>
  );
};

export default DashboardOnboardingHomeCard;
