import FeatherIcon from "feather-icons-react";
import { Alert } from "react-bootstrap";

const FeatureInBetaBanner = () => (
    <Alert className="p-4">
    <h4 className="mb-3">
      <FeatherIcon
        icon="alert-triangle"
        size="17"
        className="me-2 mt-n1"
      />
      Feature in Beta
    </h4>
    <p className="small m-0">
      Your Runway Dashboard currently shows preliminary data based on your
      connected accounts and verifications. Accounts like Treasury, Brokerage,
      and Payment Processors require manual syncing. Please contact your
      dedicated team if you need assistance: team@tryfondo.com
    </p>
  </Alert>
);

export default FeatureInBetaBanner;
