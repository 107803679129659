import { Button, Card, Dropdown, Form, Modal, Table } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ConfirmationModal from "../../fusion-kit/ConfirmationModal";
import { RecipientGroupType, RecipientGroupRouter } from "../Reporting.type";
import { removeRecipientGroup } from "../Reporting.api";

type RecipientGroupSelectedGroupType = {
  recipientGroupData: RecipientGroupType[];
  selectedGroups: { [key: number]: string };
  setSelectedGroups: React.Dispatch<React.SetStateAction<{ [key: number]: string }>>;
  setRecipientGroupEditId: React.Dispatch<React.SetStateAction<number>>;
  setCurrentRoute: React.Dispatch<React.SetStateAction<RecipientGroupRouter>>;
};

const RecipientGroupSelectedGroup = ({
  recipientGroupData,
  selectedGroups,
  setSelectedGroups,
  setRecipientGroupEditId,
  setCurrentRoute,
}: RecipientGroupSelectedGroupType) => {
  const queryClient = useQueryClient();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [recipientGroupToDelete, setRecipientGroupToDelete] = useState({ id: 0, name: "" });

  const deleteRecipientGroupMutation = useMutation(
    removeRecipientGroup,
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ["recipientGroupListData"] }),
    }
  );

  const openEditRecipientGroup = (recipientGroupId: number) => {
    setRecipientGroupEditId(recipientGroupId);
    setCurrentRoute(RecipientGroupRouter.EDIT_GROUP);
  }

  const openConfirmationModal = (recipientGroup: RecipientGroupType) => {
    setRecipientGroupToDelete({ id: recipientGroup.id, name: recipientGroup.name });
    setShowConfirmationModal(true);
  };

  const deleteSelectedId = () => {
    const currentGroups = { ...selectedGroups };
    if (currentGroups[recipientGroupToDelete.id]) {
      delete currentGroups[recipientGroupToDelete.id];
      setSelectedGroups(currentGroups);
    }
    deleteRecipientGroupMutation.mutate(recipientGroupToDelete.id);
    setRecipientGroupToDelete({ id: 0, name: "" });
    setShowConfirmationModal(false);
  }

  const updateGroupIds = (recipientGroup: RecipientGroupType) => {
    const currentGroups = { ...selectedGroups }
    if (currentGroups[recipientGroup.id]) {
      delete currentGroups[recipientGroup.id]
    } else {
      currentGroups[recipientGroup.id] = recipientGroup.name;
    }
    setSelectedGroups(currentGroups);
  }

  const returnToShare = () => {
    setCurrentRoute(RecipientGroupRouter.SHARE);
  };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title as="h3" className="mb-0">
          Select Group(s)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Header>
            <Card.Title className="mb-0" as="h4">
              My Groups
            </Card.Title>
            <Button
              size="sm"
              variant="white"
              onClick={() => setCurrentRoute(RecipientGroupRouter.CREATE_GROUP)}>
              Create New
            </Button>
          </Card.Header>
          <Table className='table mb-0' size="sm">
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {recipientGroupData.map((recipientGroup, idx) => (
                <tr key={idx}>
                  <td className="d-flex justify-content-between">
                    <Form.Check
                      checked={!!selectedGroups[recipientGroup.id]}
                      label={recipientGroup.name}
                      onChange={() => updateGroupIds(recipientGroup)}
                      id={`${recipientGroup.name}-${idx}`}
                    />
                    <Dropdown>
                      <Dropdown.Toggle as="span" className="dropdown-ellipses" role="button">
                        <FeatherIcon
                          icon='more-vertical'
                          size='17'
                          className='text-muted'
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => openEditRecipientGroup(recipientGroup.id)}>Edit</Dropdown.Item>
                        <Dropdown.Item onClick={() => openConfirmationModal(recipientGroup)}>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={returnToShare}>
          Select
        </Button>
      </Modal.Footer>
      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleConfirm={deleteSelectedId}
        modalOverModal={true}
        titleText="Confirm Deletion"
        bodyText={`Are you sure you want to delete the Recipient Group "${recipientGroupToDelete.name}"?`}
      />
    </>
  )
};

export default RecipientGroupSelectedGroup;
