import { FileRequestType } from "../documents/documents.types";
import { CompanyService } from "../onboarding/company/company.type";

export type ActionItemsData = {
    activated: boolean;
    depositStatus: boolean;
    numberOfActionItems: number;
    numberOfPendingActionItems: number;
    onboardingStatus: boolean;
    servicesWithPendingActionItems: number;
};

export type ActionItemsUserServicesData = {
    numberOfActionItems: number
    numberOfPendingActionItems: number;
    services: ActionItemsService[];
}

export type ActionItemsService = {
    numberOfActionItems: number;
    companyService: CompanyService;
    numberOfPendingActionItems: number;
    name: string;
    id: number;
    actionItems: [];
    actionItemsServicesIdList: number[];
    description: string;
    internalDescription: string;
}

export type ActionItem = {
    id: number;
    status: ActionItemStatus;
    missingInfo: MissingInfo;
    connectionType: ConnectionType;
}

export enum ActionItemStatus {
    PENDING = "PENDING",
    CANCELLED = "CANCELLED",
    COMPLETED = "COMPLETED",
    NOT_NEEDED = "NOT_NEEDED",
}

export type MissingInfo = {
    description: string;
    directions: string;
    missingForm: MissingForm;
    connectionDirections: string;
    name: string;
    status: MissingInfoStatus;
    uuid: string;
    fileRequests: FileRequestType[];
    videoName?: string;
    videoLink?: string;
}

export enum MissingInfoStatus {
    MISSING = "MISSING",
    REQUESTED = "REQUESTED",
    RECEIVED = "RECEIVED",
    VERIFIED = "VERIFIED",
    INCORRECT = "INCORRECT",
    CANCELLED = "CANCELLED",
}

export type MissingForm = {
    formType: string;
    formTypeTaxYear: number;
}

export type Image = {
    key: string;
    name: string;
    uploadedBy: number;
    url: string;
}

export type Institution = {
    connectionDirections: string;
    image?: Image;
    name: string;
    uuid: string;
}

export type ConnectionType = {
    id: number;
    name: string;
}

export type ActionItemSelectedInstitution = {
    id: number;
    selectedInstitution: Institution;
}
