import { Badge, Card } from "react-bootstrap";
import { formatDateToWords } from "../../../utils/common.util";
import { OrderAPIData } from "../../onboarding/product/product.type";
import OrderTable from "./OrderTable";

type ActiveOrdersProps = {
  order: OrderAPIData;
}

const ActiveOrders = ({ order }: ActiveOrdersProps) => (
  <>
    <Card>
      <Card.Header>
        <span>
          <span
            className={`fw-bold ${order.customerReviewed ? "" : "text-danger"}`}
          >
            Current Active Order {!order.customerReviewed && ("- Pending")}
          </span>
          <span className="ps-3 text-secondary">
            {formatDateToWords(order.createdAt)}
          </span>
        </span>
        <Badge bg="success-soft">
          In progress
        </Badge>
      </Card.Header>
      <Card.Body className="p-0">
        <OrderTable order={order} />
      </Card.Body>
    </Card>
  </>
);

export default ActiveOrders;
