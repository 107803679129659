import * as Yup from "yup";
import { FORM_ERROR_MSG } from "../../constants/general";

export const incorporationSchema = Yup.object({
  name: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  dateAcquired: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  taxIdentificationNumberEnc: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  fiscalYearEnd: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  dateIncorporated: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  functionalCurrency: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  city: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  countryOfIncorporationLaws: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  changesInOwnership: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  changeInOwnershipDescription: Yup.string().nullable().when("changesInOwnership", {
    is: "True",
    then: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  capitalContributionsThisYear: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  transferOfFundsToSubsidiary: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  distributionsDividendsThisYear: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();

export const registeredAgentAndBooksAndRecordsLocationSchema = Yup.object({
  name: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  city: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();

export const majorityShareholdersSchema = Yup.object({
  name: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  city: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  sharesAtYearStart: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  sharesAtYearEnd: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();

export const capitalStructureSchema = Yup.object({
  description: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  issuedCountAtPeriodEnd: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  issuedCountAtPeriodStart: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  outstandingCountAtPeriodEnd: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  outstandingCountAtPeriodStart: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();

export const capitalContributionsDistributionAndDividendsSchema = Yup.object({
  date: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  description: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  value: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  currency: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();

export const foreignBankAccountsSchema = Yup.object({
  name: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  type: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  accountNumber: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  city: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  currency: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  maxValueDuringYear: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  dateOfMaxValue: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  openedDuringYear: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  closedDuringYear: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();
