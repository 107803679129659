import { API_RESET_PASSWORD_CONFIRM } from "../../constants/network-calls";
import { placeParams } from "../../utils/common.util";
import { PasswordResetConfirmType } from "./passwordResetConfirm.type";
import { postFondoApi } from "../../utils/util.api";

export async function resetPasswordConfirm(
  uid: string,
  token: string,
  data: PasswordResetConfirmType,
): Promise<string> {
  data.uid = uid;
  data.token = token;
  const result: any = await postFondoApi(placeParams(API_RESET_PASSWORD_CONFIRM, { uid, token }), data);
  return result;
}
