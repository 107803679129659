import { CASH_FLOW_STATS } from "../../constants/network-calls";
import { CashFlowStats } from "./dashboard.type";
import { getFondoApi } from "../../utils/util.api";

export async function getCashFlowStats(): Promise<any> {
  const result = await getFondoApi(CASH_FLOW_STATS);
  return result as CashFlowStats;
}

export async function getCashFlowStatsUntilDate(yearAndMonth: string): Promise<any> {
  const result = await getFondoApi(CASH_FLOW_STATS, { untilDate: yearAndMonth });
  return result as CashFlowStats;
}
