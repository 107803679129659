import { useQuery } from "@tanstack/react-query";
import CashBalanceCard from "../runway/CashBalanceCard";
import CashBalanceOverTimeCard from "../runway/CashBalanceOverTimeCard";
import BurnRateCard from "../runway/BurnRateCard";
import LightCashFlowRow from "../dashboard/LightCashFlowRow";
import { CashFlowStats } from "../dashboard/dashboard.type";
import { getCashFlowStatsUntilDate } from "../dashboard/dashboard.api";
import BlurWrapper from "../fondo-components/BlurWrapper";
import { getVerifyTransactionsOverlay } from "./verify-transactions/VerifyTransactionsOverlay";
import { ConditionalWrapper } from "../../utils/common.util";

type RunwayProps = {
  selectedMonth: string;
  hasTransactionsToVerify: boolean;
  interval: string;
}

const Runway = ({ selectedMonth, hasTransactionsToVerify, interval }: RunwayProps) => {
  const { data: cashFlowStatsData, isLoading: cashFlowStatsDataIsLoading } = useQuery<CashFlowStats, Error>(
    ["cashFlowStats", selectedMonth],
    () => getCashFlowStatsUntilDate(selectedMonth),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedMonth,
    },
  );

  return (
    <>
      <div className="my-4">
        <CashBalanceCard
          month={selectedMonth}
          disabledState={!cashFlowStatsData?.amountOfAccounts}
          allowEditingBalances={true}
        />
      </div>

      <ConditionalWrapper
        condition={hasTransactionsToVerify}
        wrapper={(children: React.ReactNode) => (
          <BlurWrapper overlayNode={getVerifyTransactionsOverlay()}>
            {children}
          </BlurWrapper>
        )}
      >
        {cashFlowStatsDataIsLoading ? (
          <div className="my-4">
            <LightCashFlowRow
              loadingState={true}
              selectedMonth={selectedMonth}
            />
          </div>
        ) : (
          <>
            {cashFlowStatsData && (
              <div className="my-4">
                <LightCashFlowRow
                  cashFlowStatsData={cashFlowStatsData}
                  selectedMonth={selectedMonth}
                />
              </div>
            )}
          </>
        )}
      </ConditionalWrapper>

      <ConditionalWrapper
        condition={hasTransactionsToVerify}
        wrapper={(children: React.ReactNode) => (
          <BlurWrapper overlayNode={getVerifyTransactionsOverlay()}>
            {children}
          </BlurWrapper>
        )}
      >
        <>
          {cashFlowStatsData && (
            <div className="my-4">
              <CashBalanceOverTimeCard endMonth={selectedMonth} interval={interval} />
            </div>
          )}
        </>
      </ConditionalWrapper>

      <ConditionalWrapper
        condition={hasTransactionsToVerify}
        wrapper={(children: React.ReactNode) => (
          <BlurWrapper overlayNode={getVerifyTransactionsOverlay()}>
            {children}
          </BlurWrapper>
        )}
      >
        <>
          {cashFlowStatsData && (
            <div className="my-4">
              <BurnRateCard endMonth={selectedMonth} interval={interval} />
            </div>
          )}
        </>
      </ConditionalWrapper>

    </>
  );
};

export default Runway;
