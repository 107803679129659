import * as yup from "yup";

const fieldRequiredMessage = "This field is required.";

const manuallyAddedBankAccountSchema = yup.object().shape({
  institutionName: yup.string().trim().required(fieldRequiredMessage),
  accountName: yup.string().trim().required(fieldRequiredMessage),
  accountCreatedAt: yup.string().trim().required(fieldRequiredMessage),
});

export default manuallyAddedBankAccountSchema;
