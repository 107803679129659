import {
  AddonType, Addons, ProductCardType, AddonDetailType,
} from "./product.type";

export const needsProduct = (
  selectedProductCards: ProductCardType[],
  product: string,
) => selectedProductCards.some((selectedProduct) => {
  if (selectedProduct.product.productName === product) {
    return true;
  }
  return false;
});

export const getValidAddons = (addons: AddonType[], year: string) => {
  const foreignBankAccount: AddonType = addons.filter(
    (addon: AddonType) => addon.addon === Addons.foreignBankAccount && addon.year === year,
  )[0];
  const foreignSubsidiary: AddonType = addons.filter(
    (addon: AddonType) => addon.addon === Addons.foreignSubsidiary && addon.year === year,
  )[0];

  const foreignShareholder: AddonType = addons.filter(
    (addon: AddonType) => addon.addon === Addons.foreignShareholder && addon.year === year,
  )[0];
  if (foreignShareholder) foreignShareholder.quantity = Math.max(foreignShareholder.quantity - 1, 0);

  const additionalJurisdiction: AddonType = addons.filter(
    (addon: AddonType) => addon.addon === Addons.additionalJurisdiction && addon.year === year,
  )[0];
  if (additionalJurisdiction) additionalJurisdiction.quantity = Math.max(additionalJurisdiction.quantity - 1, 0);

  return {
    foreignBankAccount,
    foreignSubsidiary,
    foreignShareholder,
    additionalJurisdiction,
  };
};

const getAddonDetails = (addons: AddonType[]) => {
  const quantity = addons.reduce((a, p) => a + +(p?.quantity || 0), 0);
  const discountPrice = Number(addons[0]?.discountPrice);
  return {
    id: addons[0]?.id,
    addon: addons[0]?.addon,
    price: Number(addons[0]?.price),
    discountPrice,
    quantity,
    total: quantity * discountPrice,
  };
};

export const getFinalAddonsQuantityAndDetails = (
  addons: AddonType[],
  needsTaxPassCurrentYear: boolean,
  needsTaxPassPrevYear: boolean,
  needsTaxPassNextYear: boolean,
) => {
  let totalForeignBankAccounts: AddonType[] = [];
  let totalForeignShareholders: AddonType[] = [];
  let totalForeignSubsidiaries: AddonType[] = [];
  let totalAdditionalJurisdictions: AddonType[] = [];

  if (needsTaxPassCurrentYear) {
    const {
      foreignBankAccount,
      foreignSubsidiary,
      foreignShareholder,
      additionalJurisdiction,
    } = getValidAddons(addons, "current");
    if (foreignBankAccount) totalForeignBankAccounts = [...totalForeignBankAccounts, foreignBankAccount];
    if (foreignSubsidiary) totalForeignSubsidiaries = [...totalForeignSubsidiaries, foreignSubsidiary];
    if (foreignShareholder) totalForeignShareholders = [...totalForeignShareholders, foreignShareholder];
    if (additionalJurisdiction) {
      totalAdditionalJurisdictions = [...totalAdditionalJurisdictions, additionalJurisdiction];
    }
  }
  if (needsTaxPassPrevYear) {
    const {
      foreignBankAccount,
      foreignSubsidiary,
      foreignShareholder,
      additionalJurisdiction,
    } = getValidAddons(addons, "prev");
    if (foreignBankAccount) totalForeignBankAccounts = [...totalForeignBankAccounts, foreignBankAccount];
    if (foreignSubsidiary) totalForeignSubsidiaries = [...totalForeignSubsidiaries, foreignSubsidiary];
    if (foreignShareholder) totalForeignShareholders = [...totalForeignShareholders, foreignShareholder];
    if (additionalJurisdiction) {
      totalAdditionalJurisdictions = [...totalAdditionalJurisdictions, additionalJurisdiction];
    }
  }
  if (needsTaxPassNextYear) {
    const {
      foreignBankAccount,
      foreignSubsidiary,
      foreignShareholder,
      additionalJurisdiction,
    } = getValidAddons(addons, "next");
    if (foreignBankAccount) totalForeignBankAccounts = [...totalForeignBankAccounts, foreignBankAccount];
    if (foreignSubsidiary) totalForeignSubsidiaries = [...totalForeignSubsidiaries, foreignSubsidiary];
    if (foreignShareholder) totalForeignShareholders = [...totalForeignShareholders, foreignShareholder];
    if (additionalJurisdiction) {
      totalAdditionalJurisdictions = [...totalAdditionalJurisdictions, additionalJurisdiction];
    }
  }

  const totalAddonsDetails: AddonDetailType[] = [];
  if (totalAdditionalJurisdictions.length) {
    const addonDetail = getAddonDetails(totalAdditionalJurisdictions);
    if (addonDetail?.quantity) totalAddonsDetails.push(addonDetail);
  }
  if (totalForeignBankAccounts.length) {
    const addonDetail = getAddonDetails(totalForeignBankAccounts);
    if (addonDetail?.quantity) totalAddonsDetails.push(addonDetail);
  }
  if (totalForeignShareholders.length) {
    const addonDetail = getAddonDetails(totalForeignShareholders);
    if (addonDetail?.quantity) totalAddonsDetails.push(addonDetail);
  }
  if (totalForeignSubsidiaries.length) {
    const addonDetail = getAddonDetails(totalForeignSubsidiaries);
    if (addonDetail?.quantity) totalAddonsDetails.push(addonDetail);
  }
  return totalAddonsDetails;
};
