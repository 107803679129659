import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import SentReport from "../../../assets/img/mailbox-with-mail.svg";

type RecipientGroupSentType = {
  reportName: string;
  groupsToSendName: string;
  returnToShareRoute: () => void;
  handleClose: () => void;
}

const RecipientGroupSent = ({
  reportName,
  groupsToSendName,
  returnToShareRoute,
  handleClose,
}: RecipientGroupSentType) => (
  <>
    <Modal.Body>
      <Container>
        <Row>
          <Col>
            <div className="mx-auto my-6 text-center">
              <img src={SentReport} />
              <h3 className="fw-normal mt-4">
                <span className="fw-bold">"{reportName}"</span> has been shared
                with <span className="fw-bold">"{groupsToSendName}"</span>
              </h3>
            </div>
          </Col>
        </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer className="d-flex justify-content-between">
      <Button variant="white" onClick={returnToShareRoute}>
        Share With Another Group
      </Button>
      <Button onClick={handleClose}>
        Done
      </Button>
    </Modal.Footer>
  </>
);

export default RecipientGroupSent;
