import { Modal } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { CONTACT_ENGINEERING_ERROR_MESSAGE } from "../../constants/general";

type AlertModalProps = {
    title: string;
    alertText?: string | string[];
    show: boolean;
    handleClose: () => void;
    error: boolean;
}

const AlertModal = ({
  alertText, title, show, handleClose, error,
}: AlertModalProps) => {
  let alertTextTags = <p>{alertText || CONTACT_ENGINEERING_ERROR_MESSAGE}</p>;
  if (Array.isArray(alertText)) {
    if (alertText.length === 1) {
      alertTextTags = <p>{alertText[0]}</p>;
    } else {
      alertTextTags = (
                <>
                    {alertText.map((errorString: string) => (<li>{errorString}</li>))}
                </>
      );
    }
  }

  return (
        <Modal show={show} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <div className="me-2 mb-3">
                    {error && <FeatherIcon icon='alert-circle' size='20' style={{ color: "red" }}/>}
                </div>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {alertTextTags}
            </Modal.Body>
        </Modal>
  );
};

export default AlertModal;
