import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal } from "react-bootstrap";
import { Form, Formik, FormikProps } from "formik";
import { useState } from "react";
import {
  DataType,
  Deal,
  UnavailableRequiredDataInfo,
  UnavailableRequiredDataTypeToFormikFieldTypeMap,
  RequiredDataUpdate,
  UnavailableRequiredData,
} from "../passport-partners.types";
import { updateRequiredData } from "../passport-partners.api";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import { followPath, followPathAndSetValue } from "../../forms/formik-components/utils";
import { FieldTypes, HelperText } from "../../forms/formik-components/formTypes";

const DEFAULT_SELECTED_COUNTRY = "US";

const getFormFieldNames = (data: UnavailableRequiredDataInfo, baseNamesOnly = false, keyNamePrefix = ""): string[] => {
  const baseKeyName = `${keyNamePrefix}${data.keyName}`;
  if (data.dataType === DataType.OBJECT) {
    return (data.objectSchema as UnavailableRequiredDataInfo[])
      .map((objData) => getFormFieldNames(objData, baseNamesOnly, baseNamesOnly ? "" : `${baseKeyName}.`))
      .flat(5);
  }
  return [baseKeyName];
};

const getFormFieldTypes = (dataInfos: UnavailableRequiredDataInfo[]): Record<string, FieldTypes> =>
  dataInfos.reduce((acc, currDataInfo) => {
    if (currDataInfo.dataType === DataType.OBJECT) {
      return { ...acc, ...getFormFieldTypes(currDataInfo.objectSchema || []) };
      // this assumes there are no base key name collisions (FondoFormGroup assumes no collisions)
    }
    let type = UnavailableRequiredDataTypeToFormikFieldTypeMap[currDataInfo.dataType];
    if (currDataInfo.keyName === "region") {
      type = FieldTypes.STATE;
    } else if (currDataInfo.keyName === "country") {
      type = FieldTypes.COUNTRY;
    }
    acc[currDataInfo.keyName] = type;
    return acc;
  }, {} as Record<string, FieldTypes>);

const getFormFieldLabels = (dataInfos: UnavailableRequiredDataInfo[]): Record<string, string> =>
  dataInfos.reduce((acc, currDataInfo) => {
    if (currDataInfo.dataType === DataType.OBJECT) {
      return { ...acc, ...getFormFieldLabels(currDataInfo.objectSchema || []) };
      // this assumes there are no base key name collisions (FondoFormGroup assumes no collisions)
    }
    acc[currDataInfo.keyName] = currDataInfo.displayName;
    return acc;
  }, {} as Record<string, string>);

const cleanValue = (value: any, dataInfo: UnavailableRequiredDataInfo): any => {
  const { dataType } = dataInfo;
  if ((dataType === DataType.NUMBER || dataType === DataType.CURRENCY) && typeof value === "string") {
    return parseFloat(value.replace(",", ""));
  }
  if (dataType === DataType.OBJECT) {
    return dataInfo.objectSchema?.reduce((acc, currData) => {
      const origVal = value[currData.keyName];
      acc[currData.keyName] = cleanValue(origVal, currData);
      return acc;
    }, {} as Record<string, any>);
  }
  return value;
};

const convertValuesToRequiredDataUpdates = (
  values: Record<string, any>,
  missingRequiredDataInfos: UnavailableRequiredData[],
): RequiredDataUpdate[] =>
  missingRequiredDataInfos.map((dataInfo) =>
    // only send top-level values
    ({
      uuid: dataInfo.uuid || "",
      newData: cleanValue(values[dataInfo.keyName], dataInfo),
    }),
  );

const getInitialValues = (missingRequiredDataInfos: UnavailableRequiredDataInfo[]) =>
  missingRequiredDataInfos.reduce((acc, currData): Record<string, any> => {
    let value: Record<string, any> | string = "";
    if (currData.dataType === DataType.OBJECT) {
      value = getInitialValues(currData.objectSchema || []);
    } else if (currData.keyName === "country") {
      value = DEFAULT_SELECTED_COUNTRY;
    }
    return {
      ...acc,
      [currData.keyName]: value,
    };
  }, {} as Record<string, any>);

type MissingInfoModalProps = {
  show: boolean;
  onHide: () => void;
  deal: Deal;
  missingRequiredData: UnavailableRequiredData[];
};

export const MissingInfoModal = ({ show, onHide, deal, missingRequiredData }: MissingInfoModalProps) => {
  const queryClient = useQueryClient();
  const updateRequiredDataMutation = useMutation(
    (requiredDataUpdates: RequiredDataUpdate[]) => updateRequiredData(deal.uuid, requiredDataUpdates),
    { onSettled: () => queryClient.invalidateQueries(["passportPartnersUnavailableRequiredData", deal.uuid]) },
  );
  // support one selected country for now
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(DEFAULT_SELECTED_COUNTRY);

  const getEditField = (
    missingRequiredDataInfo: UnavailableRequiredDataInfo,
    formikContext: FormikProps<any>,
    keyNamePrefix = "",
  ) => {
    const baseKeyName = `${keyNamePrefix}${missingRequiredDataInfo.keyName}`;
    const helperText: HelperText = { type: "whatIsThis", body: missingRequiredDataInfo.description };
    if (missingRequiredDataInfo.dataType === DataType.OBJECT) {
      return (
        <>
          <h3>{missingRequiredDataInfo.displayName}</h3>
          {missingRequiredDataInfo.objectSchema?.map((dataInfo) => (
            <div key={dataInfo.keyName}>{getEditField(dataInfo, formikContext, `${baseKeyName}.`)}</div>
          ))}
        </>
      );
    }
    return (
      <FondoFormGroup
        formikContext={formikContext}
        fieldName={baseKeyName}
        labels={getFormFieldLabels(missingRequiredData)}
        types={getFormFieldTypes(missingRequiredData)}
        selectedCountry={selectedCountry}
        helperText={helperText}
        selectorOptions={missingRequiredDataInfo.enumChoices?.map(([value, label]) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
        includeSelectorEmptyValue={true}
      />
    );
  };

  const validateValues = (values: Record<string, any>) => {
    const errors = {} as Record<string, string>;
    missingRequiredData.forEach((dataInfo) =>
      getFormFieldNames(dataInfo).forEach((keyName) => {
        const pathArr = keyName.split(".");
        const currVal = followPath(values, pathArr);
        if (!currVal) {
          followPathAndSetValue(errors, pathArr, "Required", true);
        } else if (pathArr.at(-1) === "country") {
          // hardcoding 'country' for now
          setSelectedCountry(currVal);
        }
      }),
    );
    return errors;
  };

  const handleSubmit = (values: Record<string, any>) => {
    updateRequiredDataMutation.mutate(convertValuesToRequiredDataUpdates(values, missingRequiredData));
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} className="modal-over-modal">
      <Modal.Header closeButton>
        <Modal.Title>Add Missing Information</Modal.Title>
      </Modal.Header>
      <Formik
        onSubmit={handleSubmit}
        initialValues={getInitialValues(missingRequiredData)}
        validate={validateValues}
        isInitialValid={false}
      >
        {(formikContext) => (
          <Form>
            <Modal.Body>{missingRequiredData.map((data) => getEditField(data, formikContext))}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onHide}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={!formikContext.isValid}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
