import React, { useState, useRef, useEffect } from "react";
import { Card, Button, Table, Dropdown, ButtonGroup, Modal, Form, Col } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Typeahead } from "react-bootstrap-typeahead";
import { PresignedURLResponse } from "../../types/PreSignedUrl";
import NewFolderModal from "./Folder";
import "./Styles.css";
import { getFolders, getFiles } from "./documents.api";
import { DOCUMENT_CENTER_FILES, DOCUMENT_CENTER_FOLDERS } from "../../constants/network-calls";
import { formatDocumentUpdatedAt, getDownloadURL, getPresignedURL } from "./utils";
import { SelectedItemType, NestedFolderType, FondoFileType, FolderType } from "./documents.types";
import AlertModal from "../fusion-kit/AlertModal";
import { callFondoApi, patchFondoApi, postFondoApi, putFondoApi } from "../../utils/util.api";
import { FileRow } from "./FileRow";

const fetchFolders = async () => {
  try {
    const response = await getFolders();
    return response;
  } catch (error) {
    throw new Error(`Error fetching folders: ${error}`);
  }
};

const fetchFiles = async () => {
  try {
    const response = await getFiles();
    return response;
  } catch (error) {
    throw new Error(`Error fetching files: ${error}`);
  }
};

const NewDocumentCenterCard = () => {
  const foldersConst = "folders";
  const filesConst = "files";
  const actionMapping: Record<string, string> = {
    Move: "PUT",
    Delete: "DELETE",
    Rename: "PUT",
    Download: "GET",
  };
  const serverToPathMapping: Record<string, string> = {
    folders: DOCUMENT_CENTER_FOLDERS,
    files: DOCUMENT_CENTER_FILES,
  };
  const [sortColumn, setSortColumn] = useState("");
  const [sortAsc, setSortAsc] = useState(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const [isActionButtonEnabled, setIsActionButtonEnabled] = useState(false);
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [newName, setNewName] = useState("");
  const [selectedItems, setSelectedItems] = useState<SelectedItemType[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [nestedFoldersData, setNestedFoldersData] = useState<Record<number, NestedFolderType>>({});
  const [selectedFolder, setSelectedFolder] = useState<NestedFolderType | null>(null);
  const [breadcrumbs, setBreadcrumbs] = useState([{ id: 0, name: "Home" }]);
  const [renameItemId, setRenameItemId] = useState<number | null>(null);
  const [renameItemServiceType, setRenameItemServiceType] = useState<string | null>(null);
  const newNameRef = useRef<HTMLInputElement | null>(null);
  const [showMoveForm, setShowMoveForm] = useState(false);
  const [selectedMoveFolder, setSelectedMoveFolder] = useState<NestedFolderType | null>(null);
  const [availableMoveOptions, setAvailableMoveOptions] = useState<NestedFolderType[]>([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [allFiles, setAllFiles] = useState<FondoFileType[]>([]);
  const typeaheadRef = useRef<any>(null);
  const [allFolders, setAllFolders] = useState<FolderType[]>([]);

  const handleErrorMessage = () => {
    setShowAlertModal(true);
  };

  const handleFolderClick = (folder: NestedFolderType) => {
    if (selectedFolder && selectedFolder.id === folder.id) {
      setSelectedFolder(null);
    } else {
      const newBreadcrumbs = [...breadcrumbs, { id: folder.id, name: folder.name }];
      setBreadcrumbs(newBreadcrumbs);
      setSelectedFolder(folder);
    }
  };

  const refetchData = async () => {
    try {
      const foldersResponse = await fetchFolders();
      const filesResponse = await fetchFiles();
      setAllFiles(filesResponse);
      setAllFolders(foldersResponse);
      const nestedStructure = constructNestedStructure(foldersResponse, filesResponse);
      setNestedFoldersData(nestedStructure);
    } catch (error) {
      throw new Error(`Error refetching data: ${error}`);
    }
  };

  function constructNestedStructure(folders: FolderType[], files: FondoFileType[]): Record<number, FolderType> {
    const result: Record<number, FolderType> = {};

    folders.forEach((folder) => {
      folder.files = [];
      folder.folders = [];
      result[folder.id] = folder;
    });

    files.forEach((file) => {
      result[file.folder].files.push(file);
    });

    folders.forEach((folder) => {
      if (folder.parent !== null) {
        const nestedFolder = {
          ...folder,
          files: [],
          folders: [],
        };
        result[folder.parent].folders.push(nestedFolder);
      }
    });
    return result;
  }

  useEffect(() => {
    const fetchFoldersData = async () => {
      try {
        const foldersResponse = await fetchFolders();
        const filesResponse = await fetchFiles();
        setAllFiles(filesResponse);
        setAllFolders(foldersResponse);
        const nestedStructure = constructNestedStructure(foldersResponse, filesResponse);
        // Update state or perform actions with the nested structure data
        setNestedFoldersData(nestedStructure);

        // Select the home folder by default
        const homeFolder = Object.values(nestedStructure).find((folder) => folder.home);
        if (homeFolder) {
          setBreadcrumbs([{ id: homeFolder.id, name: "Home" }]);
          setSelectedFolder(homeFolder);
        }
      } catch (error) {
        throw new Error(`Error fetching folders: ${error}`);
      }
    };

    fetchFoldersData();
  }, [queryClient]);

  const handleItemsAction = async (event: any) => {
    const action = event.currentTarget.textContent.split(" ")[0];
    if (selectedItems.length === 0) {
      return;
    }
    try {
      const actions = selectedItems.map(async (item) => {
        let requestBody = {};
        const { id, service } = item;
        const path = `${serverToPathMapping[service]}${id}/`;
        if (action === "Move") {
          requestBody = {
            moveTo: selectedMoveFolder?.id,
          };
        }
        await callFondoApi(actionMapping[action], path, { data: requestBody });
      });

      await Promise.all(actions); // Wait for all actions to complete.
    } catch (error) {
      // Handle API error here
      console.error("Error in API request:", error);
    } finally {
      // Reset state regardless of success or error
      setSelectedItems([]);
      setSelectedMoveFolder(null);
      setShowMoveForm(false);
      setAvailableMoveOptions([]);
      await refetchData();
    }
  };

  const handleRenameModalCancel = () => {
    setNewName("");
    setRenameModalVisible(false);
    setRenameItemId(null);
    setRenameItemServiceType(null);
  };

  const handleItemNameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setNewName(e.target.value);
  };

  const handleRenameModalSave = async () => {
    if (renameItemId !== null && renameItemServiceType !== null) {
      const path = `${serverToPathMapping[renameItemServiceType]}${renameItemId}/`;
      await putFondoApi(path, { newName });
      setRenameModalVisible(false);
      setNewName("");
      setRenameItemId(null);
      setRenameItemServiceType(null);
      await refetchData();
    }
  };

  useEffect(() => {
    // This effect will run when selectedItems or selectedFolder changes
    if (selectedItems.length > 0) {
      calculateMoveOptions();
    }
  }, [selectedItems, selectedFolder]);

  const handleAction = async (event: any, item: SelectedItemType) => {
    const action = event.currentTarget.textContent;
    const path = `${serverToPathMapping[item.service]}${item.id}/`;
    if (action === "Rename") {
      setRenameItemId(item.id);
      setRenameItemServiceType(item.service);
      setRenameModalVisible(true);
    } else if (action === "Move") {
      setSelectedItems([item]);
      setShowMoveForm(true);
    } else {
      await callFondoApi(actionMapping[action], path, {});
    }

    await refetchData();
  };

  const handleFavorite = async (fileId: number) => {
    await patchFondoApi(`${DOCUMENT_CENTER_FILES}${fileId}/toggle_favorite/`, {});
  };

  const openNewFolderWindow = () => {
    setModalVisible(true);
  };

  const CloseNewFolderWindow = () => {
    setModalVisible(false);
  };

  const openFileSelection = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowProgressBar(true);
    const { files } = event.target;
    if (files) {
      for (let i = 0; i < files.length; i += 1) {
        UploadFileMutation.mutate([files[i]]);
      }
    }
    clearFileInput();
  };

  const UploadFileMutation = useMutation<void, unknown, [File]>(async ([file]) => {
    try {
      const presignedUrlResponse = await getPresignedURL(file);
      await uploadFileToPresignedURL(presignedUrlResponse, file);
      await postFondoApi(DOCUMENT_CENTER_FILES, {
        fileName: file.name,
        folderId: selectedFolder?.id,
        url: presignedUrlResponse.url,
      });
      await refetchData();
      setShowProgressBar(false);
    } catch (error) {
      setShowProgressBar(false);
      handleErrorMessage();
    }
    setShowProgressBar(false);
    setUploadProgress(0);
  });

  const uploadFileToPresignedURL = async (presignedUrlResponse: PresignedURLResponse, file: File) => {
    const form = new FormData();
    Object.keys(presignedUrlResponse.fields).forEach((key) => {
      form.append(key, (presignedUrlResponse.fields as any)[key]);
    });
    form.append("file", file);

    const xhr = new XMLHttpRequest();
    const parts = presignedUrlResponse.url.split("/");

    // Take the first part and join it back together
    const baseUrl = parts.slice(0, 3).join("/");
    xhr.open("POST", baseUrl);

    // Add an event listener to track upload progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        setUploadProgress(progress);
      }
    };

    xhr.send(form);

    // Return the XHR request so you can handle the response if needed
    return new Promise((resolve, reject) => {
      xhr.onload = () => {
        if (xhr.status === 204) {
          resolve(xhr.response);
        } else {
          reject(xhr.statusText);
        }
      };
    });
  };

  const handleCheckboxChange = (id: number, serviceName: string) => {
    setSelectedItems((prevSelected) => {
      const isSelected = prevSelected.some((item) => item.id === id);

      let updatedSelected: SelectedItemType[] = [];

      if (isSelected) {
        updatedSelected = prevSelected.filter((item) => item.id !== id);
      } else {
        updatedSelected = [...prevSelected, { id, service: serviceName }];
      }

      setIsActionButtonEnabled(updatedSelected.length > 0);
      return updatedSelected;
    });
  };

  const [downloadDialogVisible, setDownloadDialogVisible] = useState(false);
  const [downloadFileId, setDownloadFileId] = useState(0);

  const handleFileDownload = (file: FondoFileType) => {
    setDownloadFileId(file.id);
    setDownloadDialogVisible(true);
  };

  const closeDownloadDialog = () => {
    setDownloadDialogVisible(false);
  };

  const downloadFile = async () => {
    const url = await getDownloadURL(downloadFileId);
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.style.display = "none";

      // Trigger a click event on the link to initiate the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      closeDownloadDialog();
    }
  };

  const renderFoldersAndFiles = () => {
    let folderToRender: NestedFolderType | undefined;
    if (selectedFolder === null) {
      folderToRender = Object.values(nestedFoldersData).find(
        (folder: NestedFolderType | undefined) => folder && folder.home,
      );
    } else {
      folderToRender = (nestedFoldersData as Record<number, NestedFolderType>)[selectedFolder.id];
    }
    if (folderToRender) {
      return (
        <React.Fragment key={folderToRender.id}>
          {/* Render folder row */}
          {folderToRender.folders &&
            folderToRender.folders.length > 0 &&
            folderToRender.folders.map((folder: FolderType) => (
              <tr key={`folder-${folder.id}`}>
                <td>
                  <Form.Check inline onChange={() => handleCheckboxChange(folder.id, foldersConst)} className="m-0" />
                </td>
                <td>
                  <FeatherIcon icon="folder" size="16" />
                  &nbsp;&nbsp;
                  <span className="folder-file-name" onClick={() => handleFolderClick(folder)}>
                    {folder.name}
                  </span>
                </td>
                <td>Folder</td>
                <td>{folder.userName}</td>
                <td>{formatDocumentUpdatedAt(folder.updatedAt)}</td>
                <td>
                  <Dropdown as={ButtonGroup} style={{ position: "static" }}>
                    <Dropdown.Toggle
                      variant="primary"
                      style={{
                        marginRight: "10px",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      className="vertical-dots-toggle"
                      id={`${folder.id}`}
                    ></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(event) =>
                          handleAction(event, {
                            id: folder.id,
                            service: foldersConst,
                          })
                        }
                      >
                        Rename
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(event) =>
                          handleAction(event, {
                            id: folder.id,
                            service: foldersConst,
                          })
                        }
                      >
                        Move
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(event) =>
                          handleAction(event, {
                            id: folder.id,
                            service: foldersConst,
                          })
                        }
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}

          {/* Render files */}
          {folderToRender.files &&
            folderToRender.files.map((file: FondoFileType) => (
              <FileRow
                file={file}
                filesConst={filesConst}
                handleCheckboxChange={handleCheckboxChange}
                handleFileDownload={handleFileDownload}
                handleAction={handleAction}
                handleFavorite={handleFavorite}
              />
            ))}
        </React.Fragment>
      );
    }
    return <></>;
  };

  const handleBreadcrumbClick = (index: number) => {
    // Handle breadcrumb clicks to navigate back
    const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
    setBreadcrumbs(newBreadcrumbs);
    const { id: folderId } = newBreadcrumbs[index];
    const selected = Object.values(nestedFoldersData).find((folder) => folder.id === folderId);
    setSelectedFolder(selected || null);
  };

  const renderBreadcrumbs = () => (
    <div className="breadcrumbs">
      {breadcrumbs.map(({ id, name }, index) => (
        <span key={`${id}-${index}`}>
          {index !== 0 && " / "}
          {index === breadcrumbs.length - 1 ? (
            <strong>{name}</strong>
          ) : (
            <a href="#" onClick={() => handleBreadcrumbClick(index)}>
              {name}
            </a>
          )}
        </span>
      ))}
    </div>
  );

  function handleSort(id: number | undefined, column: string) {
    setSortAsc(column === sortColumn ? !sortAsc : false);
    setSortColumn(column);
    // Clone the selected folder and its contents to prevent direct state mutations.
    const folderToSort = id ? deepClone(nestedFoldersData[id]) : null;
    if (folderToSort) {
      sortFoldersAndFiles(folderToSort, column, sortAsc);
      // Update the nestedFoldersData state with the sorted folder.
      setNestedFoldersData((prevNestedData) => ({
        ...prevNestedData,
        [folderToSort.id]: folderToSort,
      }));
    }
  }

  // Deep clone function to create a copy of a folder and its contents.
  function deepClone(obj: any): any {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => deepClone(item));
    }

    if (typeof obj === "object") {
      const objCopy: Record<string, any> = {};
      Object.keys(obj).forEach((key) => {
        objCopy[key] = deepClone(obj[key]);
      });
      return objCopy;
    }

    return obj;
  }

  function sortFoldersAndFiles(folder: any, column: string, ascending: boolean) {
    // Concatenate folders and files into a single array.
    const allItems = [...folder.folders, ...folder.files];

    // Sort the combined array based on the selected column.
    allItems.sort((a: any, b: any) => {
      if (column === "name") {
        return a.name.localeCompare(b.name);
      }
      if (column === "people") {
        return (a.userName || "").localeCompare(b.userName || "");
      }
      if (column === "lastModified") {
        return a.updatedAt.localeCompare(b.updatedAt);
      }
      return 0; // Default return value, which means no change in order.
    });

    // Reverse the order if sorting in descending order.
    if (!ascending) {
      allItems.reverse();
    }

    // Separate the sorted items back into folders and files.
    folder.folders = allItems.filter((item: any) => item && "folders" in item);
    folder.files = allItems.filter((item: any) => item && "location" in item);
  }

  useEffect(() => {
    if (newNameRef.current) {
      newNameRef.current.focus();
    }
  });

  const calculateMoveOptions = () => {
    const optionsToExclude = [...selectedItems, ...(selectedFolder ? [selectedFolder] : [])];

    // If there is a selected folder, filter options based on its children
    const filteredOptions = selectedFolder
      ? [
          ...(selectedFolder.parent ? [nestedFoldersData[selectedFolder.parent]] : []),
          ...nestedFoldersData[selectedFolder.id].folders.filter(
            (folder) => !optionsToExclude.some((item) => item.id === folder.id),
          ),
        ]
      : [];
    setAvailableMoveOptions(filteredOptions);
  };

  const handleFileSelection = (selectedFile: any) => {
    const ancestorFolders = findFolderAncestorsOfFile(selectedFile);
    setBreadcrumbs(ancestorFolders);
    setSelectedFolder(ancestorFolders[ancestorFolders.length - 1]);
    if (typeaheadRef.current) {
      typeaheadRef.current.clear();
    }
  };

  const findAncestors = (folderId: number): FolderType[] => {
    const ancestors: FolderType[] = [];
    let currentFolder = allFolders.find((folder) => folder.id === folderId);

    while (currentFolder) {
      ancestors.unshift(currentFolder); // Add the current folder to the beginning of the array
      const parentFolderId = currentFolder.parent;
      currentFolder = parentFolderId ? allFolders.find((folder) => folder.id === parentFolderId) : undefined;
    }

    return ancestors;
  };

  const findFolderAncestorsOfFile = (file: FondoFileType): FolderType[] => findAncestors(file.folder);

  const renderMoveOptions = () =>
    showMoveForm && (
      <Modal show={showMoveForm} onHide={() => setShowMoveForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Specify New Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="newLocation">
            <Form.Label>Move To: </Form.Label>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {selectedMoveFolder !== null ? `${selectedMoveFolder.name}` : "Select Folder"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {availableMoveOptions.map((folder) => (
                  <Dropdown.Item
                    key={folder.id}
                    onClick={() => {
                      setSelectedMoveFolder(folder);
                    }}
                  >
                    {folder.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowMoveForm(false);
              setSelectedMoveFolder(null);
              setAvailableMoveOptions([]);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(event) => {
              setShowMoveForm(false);
              handleItemsAction(event);
            }}
          >
            Move
          </Button>
        </Modal.Footer>
      </Modal>
    );

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          {renderBreadcrumbs()}
          <Col lg={2} md={8} className="me-3">
            <div>
              <Typeahead
                id="file-search"
                labelKey="name"
                options={allFiles}
                placeholder="Search for a file..."
                onChange={(selectedOptions: any[]) => {
                  if (selectedOptions.length > 0) {
                    handleFileSelection(selectedOptions[0]);
                  }
                }}
                ref={typeaheadRef}
              />
            </div>
          </Col>
          <Button
            variant="primary"
            onClick={openFileSelection}
            style={{
              marginRight: "10px",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <FeatherIcon icon="arrow-up" size="16" /> Upload File
          </Button>
          <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} multiple />
          <Button
            variant="primary"
            onClick={openNewFolderWindow}
            style={{
              marginRight: "10px",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <FeatherIcon icon="folder-plus" size="16" /> New Folder
          </Button>
          <NewFolderModal
            showModal={modalVisible}
            handleClose={CloseNewFolderWindow}
            parentFolderId={selectedFolder?.id}
            refreshFolders={refetchData}
          />
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
              style={{
                marginRight: "10px",
                backgroundColor: isActionButtonEnabled ? "white" : "rgba(255, 255, 255, 0)",
                color: isActionButtonEnabled ? "black" : "rgba(128, 128, 128, 0.5)",
                // Adjust the alpha (last value) to control transparency
              }}
            >
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  calculateMoveOptions();
                  setShowMoveForm(true);
                }}
                disabled={!isActionButtonEnabled}
              >
                {`Move Selected (${selectedItems.length})`}
              </Dropdown.Item>
              <Dropdown.Item onClick={(event) => handleItemsAction(event)} disabled={!isActionButtonEnabled}>
                {`Delete Selected (${selectedItems.length})`}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Card.Header>
        <Card.Body className="p-0">
          {showProgressBar && (
            <div>
              <progress value={uploadProgress} max="100"></progress>
              <p>{Math.round(uploadProgress)}%</p>
            </div>
          )}
          <Table size="sm" className="table-nowrap mb-0" responsive hover>
            <thead>
              <tr>
                <th> </th> {/* Checkbox/star column */}
                <th onClick={() => handleSort(selectedFolder?.id, "name")} style={{ cursor: "pointer" }}>
                  NAME {sortColumn === "name" && (sortAsc ? "⇩" : "⇧")}
                  {sortColumn !== "name" && "⇩⇧"}
                </th>
                <th>TYPE</th>
                <th onClick={() => handleSort(selectedFolder?.id, "people")} style={{ cursor: "pointer" }}>
                  PEOPLE {sortColumn === "people" && (sortAsc ? "⇩" : "⇧")}
                  {sortColumn !== "people" && "⇩⇧"}{" "}
                </th>
                <th onClick={() => handleSort(selectedFolder?.id, "lastModified")} style={{ cursor: "pointer" }}>
                  LAST MODIFIED {sortColumn === "lastModified" && (sortAsc ? "⇩" : "⇧")}
                  {sortColumn !== "lastModified" && "⇩⇧"}{" "}
                </th>
                <th>ACTIONS</th>
                <th style={{ width: "15px" }}></th>
              </tr>
            </thead>
            <tbody>{renderFoldersAndFiles()}</tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal show={downloadDialogVisible} onHide={closeDownloadDialog} centered>
        <Modal.Header closeButton>
          <Modal.Title>Download File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to download the file?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDownloadDialog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={downloadFile}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={renameModalVisible} onHide={handleRenameModalCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Rename Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renameModalVisible && (
            <Form.Group controlId="newName">
              <Form.Label>New Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter new name"
                value={newName}
                onChange={handleItemNameChange}
                ref={newNameRef}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRenameModalCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRenameModalSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {renderMoveOptions()}
      <AlertModal show={showAlertModal} title={"Error"} handleClose={() => setShowAlertModal(false)} error={true} />
    </>
  );
};

export default NewDocumentCenterCard;
