// This is a list of HEX colors codes used to generate
// progress bars and lines in charts for Runway.
// Since each account should be represented by a color
// and the ones on dashkit/bootstrap are limited, we generated
// a few more that fits well in the design to have more options
// when having more than 5 accounts
// (don't know how often this can happen but just in case).

import {
  BalanceAccount,
  BalanceAccountChartData,
  CashBalanceChartData,
  CashBalanceOverTimeResponse,
  CashBalanceResponse,
} from "../finance-dashboard/financeDashboard.type";
import { parseMonthAndYearDateToWords, parseMonthAndYearToQuarters } from "../finance-dashboard/utils";

// If you need a lighter color for each of this ones,
// see function below `lightenHexColor`.
export const runwayReferencesColors = [
  "#2C7AE5",
  "#00D97D",
  "#F6C343",
  "#39AFD1",
  "#8F62E5",
  "#E54E7A",
  "#F66C43",
  "#D943F6",
  "#3C43F6",
];

export const grayHEXColor = "#6D83A3CC";

export const lightenHexColor = (hex: string, percent: number) => {
  // Function to programatically generate a lighter color to have
  // a darker color on the border of the char lines and a lighter one
  // on the fill. An easy way is to add 'CC' (for example) at the end
  // of the HEX to get some transparency, but since our lines on the chart
  // can overlap, the transparency ends generating a dirty color because it
  // mixes with the color that's behind.
  // Use:
  // `lightenHexColor(#3C43F6, 30)` -> gets a 30% lighter version of the original color.

  // Making sure the percent is between 0 and 100.
  percent = Math.min(100, Math.max(0, percent));

  if (hex) {
    // Remove the "#" symbol from the HEX color code.
    hex = hex.replace(/^#/, "");

    // Parse the HEX color to RGB.
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Calculate the new RGB values and parse them to HEX
    const newR = Math.round(r + (255 - r) * (percent / 100));
    const newG = Math.round(g + (255 - g) * (percent / 100));
    const newB = Math.round(b + (255 - b) * (percent / 100));
    const newHex = "#"
    + `${(newR < 16 ? "0" : "") + newR.toString(16)}`
    + `${(newG < 16 ? "0" : "") + newG.toString(16)}`
    + `${(newB < 16 ? "0" : "") + newB.toString(16)}`;

    return newHex;
  }
};

// Gets percentage of partial balance from a total balance.
// Used by ProgressBar component in Cash Balance Card.
export const getPercentageOfBalanceInTotalBalance = (partialBalance: number, totalBalance: number) => (
  (partialBalance / totalBalance) * 100
);

export const getAccountsDataWithReferenceColours = (accountsData: CashBalanceResponse) => {
  // When there are more than five accounts, we need to only represent
  // the five accounts with more balance in the progress bar. The rest of them
  // should be combined in a single balance to prevent having a progress bar
  // with a large number of accounts.
  // Sort the accounts to get first the ones with more balance.

  accountsData.balances.sort((
    a: BalanceAccountChartData,
    b: BalanceAccountChartData,
  ) => parseFloat(b.balance) - parseFloat(a.balance));

  // Get the five accounts with more balance.
  // Adds a boolean to each object to know if it belongs to 'Others'
  // and a reference color to each of them.
  const top5Accounts: BalanceAccountChartData[] = accountsData.balances.slice(0, 5).map(
    (account: BalanceAccountChartData, idx: number) => {
      account.others = false;
      account.referenceColor = runwayReferencesColors[idx];
      return account;
    },
  );

  // Get all the other accounts that are not the top five accounts with more balance.
  // Adds a boolean to each object to know if it belongs to 'Others'.
  // and a reference color to each of them (gray for others).
  const otherAccounts: BalanceAccountChartData[] = accountsData.balances.slice(5).map(
    (account: BalanceAccountChartData) => {
      account.others = true;
      account.referenceColor = grayHEXColor;
      return account;
    },
  );

  // Sum all the balances from the rest accounts.
  const otherAccountsTotalBalance = otherAccounts.reduce((
    cashBalance: number,
    account: BalanceAccountChartData,
  ) => cashBalance + parseFloat(account.balance), 0);

  // Create a new object with the data used to display by the progress bar widget (graphDisplayAccounts).
  // This has an account which is 'Other accounts'.
  // The `accounts` property has all the acounts to be displayed on the table from 'View All Balances'.
  const accounts = {
    cashBalance: accountsData.cashBalance,
    hasInvalidItems: accountsData.hasInvalidItems,
    balances: top5Accounts.concat(otherAccounts),
    graphDisplayAccounts: otherAccounts.length > 0
      ? [...top5Accounts, {
        account: {
          name: "Other Accounts",
        },
        displayBalance: otherAccountsTotalBalance,
        referenceColor: grayHEXColor,
      }] : top5Accounts,
  };

  return accounts as CashBalanceChartData;
};

export const getOneYearBeforeMonth = (monthAndYear: string) => {
  // Takes a month-year like "2023-06" and returns the year before,
  // ("2022-06" on this example)
  const [year, month] = monthAndYear.split("-");
  return `${Number(year) - 1}-${month}`;
};

export const getPast12Months = (untilMonthYear: string) => {
  // Takes a month-year like "2023-06" and returns an array with all the
  // months years labels lile "June 2023" from a year before until the provided monthYear.
  // For example: "2023-06" returns an array that starts with "June 2022" and ends with "June 2023".
  // Used for the labels of the LineChart for cash balance over time.
  const past12Months = [];
  const [year, month] = untilMonthYear.split("-");
  const currentDate = new Date(Number(year), Number(month), 1);

  for (let i = 0; i < 13; i += 1) {
    const dateYear = currentDate.getFullYear();
    const dateMonth = currentDate.getMonth();

    // Format month and year as "Month Year" and add it to the result array.
    // December is represented with 0, so if the month is 0 we save it as 12.
    if (dateMonth === 0) {
      past12Months.unshift(`${dateYear - 1}-12`);
    } else {
      past12Months.unshift(`${dateYear}-${dateMonth.toString().padStart(2, "0")}`);
    }

    // Move back one month for the next iteration
    currentDate.setMonth(currentDate.getMonth() - 1);
  }
  return past12Months;
};

/**
 * Gets the past 12 quarters from an end month string. So 2024-03 will return everything from 2021-03 up
 * to 2024-03. NOTE: The months are incremented by 3 steps, so the result would be [2021-03, 2021-06, 2021-09, etc.]
 * @param untilMonthYear String formatted as YYYY-MM: 2024-03
 * @returns A list of end month strings
 */
export const getPast12Quarters = (untilMonthYear: string) => {
  const [year, month] = untilMonthYear.split("-");
  let initialQuarter = `${Number(year) - 3}-${month}`;
  const past12Quarters = [initialQuarter];
  for (let i = 0; i < 12; i += 1) {
    const [currentYear, currentMonth] = initialQuarter.split("-");
    if (currentMonth === "12") {
      initialQuarter = `${Number(currentYear) + 1}-03`;
    } else {
      initialQuarter = `${Number(currentYear)}-${Number(currentMonth) + 3 === 12
        ? `${Number(currentMonth) + 3}` : `0${Number(currentMonth) + 3}`}`;
    }
    past12Quarters.push(initialQuarter);
  }
  return past12Quarters;
};

/**
 * Gets the past 12 years from an end month string.
 * So 2024-12 would get everything from 2012-12 to
 * 2024-12: [2012-12, 2013-12, 2014-12, etc.]
 * @param untilMonthYear String formatted as YYYY-MM, ex: 2024-12
 * @returns A list of dates as strings
 */
export const getPast12Years = (untilMonthYear: string) => {
  const [year, month] = untilMonthYear.split("-");
  let initialYear = `${Number(year) - 12}`;
  const past12Years = [`${initialYear}-${month}`];
  for (let i = 0; i < 12; i += 1) {
    initialYear = `${Number(initialYear) + 1}`;
    past12Years.push(`${initialYear}-${month}`);
  }
  return past12Years;
};

/**
 * Returns the label for the charts depending on the interval chosen
 * @param interval A string of "month", "quarter", or "year"
 * @param endMonth A string formatted as YYYY-MM, ex: 2024-01
 * @returns A list of strings such as: Q1 2022, January 2021, or 2020
 */
export const getRunwayLabels = (interval: string, endMonth: string) => {
  if (interval === "quarter") {
    const past12Quarters = getPast12Quarters(endMonth);
    return past12Quarters.map((quarter: string) => parseMonthAndYearToQuarters(quarter));
  }
  if (interval === "year") {
    const past12Years = getPast12Years(endMonth);
    return past12Years.map((year: string) => year.split("-")[0]);
  }
  const past12Months = getPast12Months(endMonth);
  return past12Months.map((month: string) => parseMonthAndYearDateToWords(month));
};

function sortAccountsInHistoricalBalanceByHighestBalance(dataObject: CashBalanceOverTimeResponse) {
  // Sort the arrays based on the highest balance found in descending order
  const sortedArrays = Object.entries(dataObject)
    .filter(([_, value]) => Array.isArray(value)) // eslint-disable-line @typescript-eslint/no-unused-vars
    .sort((a: any, b: any) => {
      const sumA = a[1].reduce((acc: any, item: any) => acc + item.displayBalance, 0);
      const sumB = b[1].reduce((acc: any, item: any) => acc + item.displayBalance, 0);
      return sumB - sumA;
    });

  // Create a new object with sorted arrays and non-array properties
  const sortedDataObject: any = {};
  sortedArrays.forEach(([key, value]) => {
    sortedDataObject[key] = value;
  });

  // Add non-array properties to the sorted object
  Object.entries(dataObject)
    .filter(([_, value]) => !Array.isArray(value)) // eslint-disable-line @typescript-eslint/no-unused-vars
    .forEach(([key, value]) => {
      sortedDataObject[key] = value;
    });

  return sortedDataObject as CashBalanceOverTimeResponse;
}

export const historicalBalanceToChartData = (historicalBalance: CashBalanceOverTimeResponse, months: string[]) => {
  // Takes the historical balance response from the API and orders it in a format
  // the line chart can read and render.
  const chartData = [];

  // Keep an index so we can assign a colour to each account.
  let index = 0;

  // Sort by accounts inside historical balance response
  // so the reference colors matches with the ones on the cash balance card.
  const sortedHistoricalBalance = sortAccountsInHistoricalBalanceByHighestBalance(historicalBalance);

  for (const prop in sortedHistoricalBalance) { // eslint-disable-line no-restricted-syntax
    // Check if the property is an array (there are other properties that are not accounts infos)
    if (Array.isArray(sortedHistoricalBalance[prop])) {
      // Adds to the chartData array an object like:
      // {
      //   data: [300, 500, 600, 700],
      //   color: "#2C7AE5"
      // }
      // for each account on the historical balance response.
      chartData.push({
        data: months.map((month) => {
          // Compares each account date with the past 12 months list.
          const acc = sortedHistoricalBalance[prop].find(
            (account: BalanceAccount) => account.date.split("-").slice(0, 2).join("-") === month,
          );
          // If there's a balance for a month date, add the balance to the array.
          // Otherwise, add null to show blank on the chart (meaning no data for that month)
          return acc ? parseFloat(acc.balance) : null;
        }),
        color: runwayReferencesColors[index], // Set the colour for the account
      });

      // Increment the index
      index += 1;
    }
  }
  return chartData;
};

export const getMaxValueForHistoricalBalancesChart = (historicalBalance: CashBalanceOverTimeResponse) => {
  // Finds the max balance on the historicalBalance response to determine
  // what should be the max number on the Y axis of the chart.
  // If we put a fixed max number on the chart max value, two things can happen:
  // 1. The max value number is too big and the balances to small, so the growth over
  // months is difficult to see.
  // 2. The max value number is too low and the balances are bigger, so you can't see
  // the top of the line in the chart.
  // The function gets the max number on the historical balances and adds 20%
  // to that number to ensure the chart displays correctly.

  // Initialize with negative infinity to handle negative balances
  let maxBalance = -Infinity;

  for (const prop in historicalBalance) { // eslint-disable-line no-restricted-syntax
    if (Array.isArray(historicalBalance[prop])) {
      for (const obj of historicalBalance[prop]) { // eslint-disable-line no-restricted-syntax
        const balance = parseFloat(obj.balance);
        if (balance > maxBalance) {
          maxBalance = balance;
        }
      }
    }
  }

  if (maxBalance === -Infinity) {
    // Return 1000 if the maximum balance is -Infinity
    // (which means there's no balance)
    return 1000;
  }

  // Adding 20%
  const maxValue = maxBalance + 0.2 * maxBalance;
  // Round value to nearest thousand
  const roundedMaxValue = Math.ceil(maxValue / 1000) * 1000;

  return roundedMaxValue;
};

export const burnRateResponseToChartData = (burnRate: any, months: string[]) => {
  const results: any = [];

  months.forEach((month: string) => {
    const matchingInstance = burnRate.find((obj: any) => obj.month === month);
    if (matchingInstance) {
      results.push(matchingInstance.burnRate);
    } else {
      results.push(null);
    }
  });

  return [{
    data: results,
    color: runwayReferencesColors[0],
  }];
};

/**
 * Get's the chart data based on the interval
 * @param runwayData The API response data
 * @param interval A string formatted as either "month", "quarter", or "year"
 * @param endMonth A string formatted as YYYY-MM, ex: 2024-01
 * @param isCashBalance Determines whether to return data for CashBalance or not
 * @returns The list of data used on the chart
 */
export const getRunwayChartData = (runwayData: any, interval: string, endMonth: string, isCashBalance?: boolean) => {
  if (runwayData) {
    const intervalToFunctionMap: {[key: string]: (untilMonthYear: string) => string[]} = {
      month: getPast12Months,
      quarter: getPast12Quarters,
      year: getPast12Years,
    };
    const periodFunction = intervalToFunctionMap[interval];
    if (isCashBalance) return historicalBalanceToChartData(runwayData, periodFunction(endMonth));
    return burnRateResponseToChartData(runwayData, periodFunction(endMonth));
  }
};
