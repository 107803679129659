import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getTaxDeadlines } from "./taxDeadlines.api";
import { TAX_DEADLINES } from "../../constants/network-calls";
import FondoLayout from "../fusion-kit/FondoLayout";
import { TaxDeadline } from "./taxDeadlines.type";
import TaxDeadlineCard from "./TaxDeadlineCard";
import "./taxDeadlines.css";

interface TaxDeadlineProps {
    showFutureDeadlinesOnly?: boolean;
    showAsWidget?: boolean;
}
export default function TaxDeadlines({ showFutureDeadlinesOnly, showAsWidget }: TaxDeadlineProps) {
  const taxDeadlinesQuery = useQuery<TaxDeadline[], Error>(
    ["taxDeadlines"],
    getTaxDeadlines,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const today = new Date();
        data.forEach((taxDeadline) => {
          const deadlineDate = new Date(taxDeadline.deadline);
          taxDeadline.isFutureDeadline = today < deadlineDate;
        });
      },
    },
  );
  const navigate = useNavigate();
  const amountOfDeadlinesToShow = 2;

  function getTaxDeadlinesList(): TaxDeadline[] {
    let taxDeadlines;
    if (taxDeadlinesQuery.data) {
      taxDeadlines = taxDeadlinesQuery.data.filter((taxDeadline) => (
        showFutureDeadlinesOnly ? taxDeadline.isFutureDeadline : true
      ));
      if (showAsWidget) {
        // Slice to return a specific amount of deadlines if shown as widget
        return taxDeadlines.slice(0, amountOfDeadlinesToShow);
      }
      return taxDeadlines;
    }
    return [];
  }

  function renderTaxDeadlines() {
    if (getTaxDeadlinesList().length === 0) {
      return (
        <p className="text-muted mb-0">No upcoming deadlines</p>
      );
    }

    return (
            <>
                {getTaxDeadlinesList().map((taxDeadline) => (
                    <TaxDeadlineCard key={taxDeadline.id} taxDeadline={taxDeadline}></TaxDeadlineCard>
                ))}
            </>
    );
  }

  function renderComponent() {
    return (<>
            <div className="card">
                <div className="card-header">
                    <h2 className={
                      `card-header-title ${getTaxDeadlinesList().length === 0
                        ? "text-muted" : ""}`
                    }>
                      Upcoming Tax Deadlines
                    </h2>
                    {showAsWidget
                        && <a className='align-right' href=''
                            onClick={(event) => {
                              event.preventDefault();
                              navigate(TAX_DEADLINES);
                            }}>
                            View all
                        </a>
                    }
                </div>
                <div className="card-body">
                    <div className="list-group list-group-flush list-group-activity my-n3">
                        {taxDeadlinesQuery.isFetching
                          ? <Spinner className="text-primary" style={{ alignSelf: "center" }} />
                          : renderTaxDeadlines()
                        }
                    </div>
                </div>
            </div>
        </>);
  }

  return (
        <>
            {showAsWidget ? (
              renderComponent()
            ) : (
                <FondoLayout title="Deadlines for Delaware C-Corporations">
                    {renderComponent()}
                </FondoLayout>
            )}
        </>
  );
}
