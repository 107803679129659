export type Country = {
    alpha2: string;
    alpha3: string;
    name: string;
};

export type Subdivision = {
    id: number;
    name: string;
    code: string;
    abbr: string;
    country: string;
};

export enum BotTrackStatus {
    IN_PROGRESS = "IN_PROGRESS",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}

export type BotTrack = {
    uuid: string;
    botName: string;
    startedAt: string;
    completedAt?: string;
    status: BotTrackStatus;
    user?: string;
    cost?: number;
    totalTokens?: number;
    isResultAccurate?: boolean;
    processedResults?: string | { [key: string]: string };
};
