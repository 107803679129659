const SVGShieldIcon = () => (
  <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1488 0.200952L24.3571 4.02908C24.9132 4.23752 25.3924 4.61074 25.7306 5.09885C26.0689 5.58695 26.2501 6.16668 26.25 6.76054V14.5816C26.2501 17.0191 25.5713 19.4084 24.2899 21.4819C23.0085 23.5554 21.1749 25.2311 18.9948 26.3212L14.1035 28.7668C13.7997 28.9187 13.4647 28.9979 13.125 28.9979C12.7853 28.9979 12.4503 28.9187 12.1465 28.7668L7.25521 26.3212C5.07506 25.2311 3.24154 23.5554 1.9601 21.4819C0.678666 19.4084 -5.25462e-05 17.0191 2.26301e-08 14.5816V6.76054C-7.39352e-05 6.16668 0.181131 5.58695 0.51939 5.09885C0.857649 4.61074 1.33684 4.23752 1.89292 4.02908L12.1013 0.200952C12.7613 -0.0464763 13.4887 -0.0464763 14.1488 0.200952ZM13.125 2.93241L2.91667 6.76054V14.5816C2.917 16.4772 3.44516 18.3353 4.44196 19.9477C5.43876 21.5601 6.86484 22.8631 8.56042 23.7107L13.125 25.996L17.6896 23.7137C19.3856 22.8658 20.812 21.5624 21.8088 19.9494C22.8056 18.3364 23.3335 16.4777 23.3333 14.5816V6.76054L13.125 2.93241ZM13.125 8.66658C13.7475 8.66632 14.3538 8.86524 14.8551 9.23425C15.3565 9.60326 15.7266 10.123 15.9114 10.7174C16.0962 11.3119 16.0859 11.9499 15.8821 12.5381C15.6783 13.1263 15.2917 13.6339 14.7788 13.9866L14.5833 14.1105V18.8749C14.5829 19.2466 14.4406 19.6041 14.1854 19.8744C13.9303 20.1447 13.5815 20.3073 13.2105 20.3291C12.8394 20.3509 12.474 20.2302 12.189 19.9916C11.904 19.753 11.7208 19.4146 11.6769 19.0455L11.6667 18.8749V14.1105C11.1099 13.7898 10.6747 13.2942 10.4285 12.7008C10.1823 12.1073 10.139 11.4492 10.3053 10.8285C10.4715 10.2079 10.838 9.65956 11.3479 9.26862C11.8578 8.87769 12.4825 8.66605 13.125 8.66658Z"  // eslint-disable-line
      fill="white"
    />
  </svg>
);

export default SVGShieldIcon;
