import {
  Badge,
  Card,
  Col,
  Row,
} from "react-bootstrap";
import FondoSparkLineChart from "../../fondo-components/Charts/FondoSparkLineChart";
import { getAmountColorClassName } from "../utils";

type GrowthRateSparkChartCardProps = {
  data: (number|null)[];
  title: string;
}

const GrowthRateSparkChartCard = ({ data, title }: GrowthRateSparkChartCardProps) => {
  const fondoBlueColor = "#2C7AE5";

  const chartData = [{
    data,
    color: fondoBlueColor,
  }];

  const currentPeriodValue = data[data.length - 1] || 0;
  const previousPeriodValue = data[data.length - 2] || 0;

  const getPercentageChangedComparedToPreviousPeriod = () => {
    const percentageChanged = (currentPeriodValue - previousPeriodValue) / (Math.abs(previousPeriodValue));
    return percentageChanged * 100;
  };

  return (
    <Card className="rounded">
      <Card.Body className="py-3">
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">
              {title}
            </h6>
            <span className={`h2 mb-0 text-${getAmountColorClassName(currentPeriodValue)}`}>
              {currentPeriodValue.toFixed(2)}%
            </span>
            {Number.isFinite(getPercentageChangedComparedToPreviousPeriod()) && (
              <Badge
                pill
                bg={`
                  ${getAmountColorClassName(
                  getPercentageChangedComparedToPreviousPeriod(),
                )}-soft
                `}
                className="ms-2 mt-n2"
                as='span'
              >
                {getPercentageChangedComparedToPreviousPeriod().toFixed(1)}%
              </Badge>
            )}
          </Col>
          <Col className="col-auto">
            <div>
              <FondoSparkLineChart chartData={chartData} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GrowthRateSparkChartCard;
