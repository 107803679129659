import { ErrorMessage, Field, Formik } from "formik";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Spinner } from "react-bootstrap";
import Cleave from "cleave.js/react";
import {
  OnboardingTaxPlanInfo,
  TaxPlanSection,
} from "./taxPlan.type";
import { TaxYearInfoType } from "../onboarding.type";
import { TAX_YEAR_INFO } from "../../../constants/network-calls";
import { getTaxYearInfo } from "../onboarding.api";
import { Subdivision } from "../../../utils/util.type";
import { getCountrySubdivisions } from "../../../utils/util.api";
import { getOnboardingTaxPlanInfo, saveTaxPlanOnboardingInformation } from "./taxPlan.api";
import { CompanyLevelSettingsContext } from "../../../context/FeatureFlagsContext";
import { taxPlanValidationSchema } from "./validationSchema";

const TaxPlan = () => {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState<TaxPlanSection>({
    wouldLikeAFreeTaxPlan: "true",
    totalEstimatedUsPayrollDecimal: "0",
    totalEstimatedInternationalPayrollDecimal: "0",
    totalEstimatedRevenueDecimal: "0",
    totalEstimatedExpensesDecimal: "0",
    totalEstimatedAssetsDecimal: "0",
    statesWithOffices: [],
  });

  const { showNewCheckoutPage } = useContext(CompanyLevelSettingsContext);

  const { data: taxYearInfoQuery } = useQuery<TaxYearInfoType, Error>([TAX_YEAR_INFO], getTaxYearInfo, {});

  useQuery<OnboardingTaxPlanInfo, Error>(
    ["taxPlanInformation"],
    getOnboardingTaxPlanInfo,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setFormValues({
            wouldLikeAFreeTaxPlan: data.wouldLikeAFreeTaxPlan ? "true" : "false",
            totalEstimatedUsPayrollDecimal: data.totalEstimatedUsPayrollDecimal,
            totalEstimatedInternationalPayrollDecimal: data.totalEstimatedInternationalPayrollDecimal,
            totalEstimatedRevenueDecimal: data.totalEstimatedRevenueDecimal,
            totalEstimatedExpensesDecimal: data.totalEstimatedExpensesDecimal,
            totalEstimatedAssetsDecimal: data.totalEstimatedAssetsDecimal,
            statesWithOffices: data.statesWithOffices,
          });
        }
      },
    },
  );

  const { data: countrySubdivisionQuery, isLoading: countrySubdivisionQueryIsLoading } = useQuery<Subdivision[], Error>(
    ["countrySubdivision", "US"],
    getCountrySubdivisions,
    {
      refetchOnWindowFocus: false,
    },
  );

  const taxPlanMutation = useMutation<any, AxiosError<any>, TaxPlanSection>(
    (data) => saveTaxPlanOnboardingInformation(data),
  );

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      validationSchema={taxPlanValidationSchema}
      onSubmit={(values, { setErrors }) => {
        taxPlanMutation.mutate(values, {
          onError: (err) => {
            const errors = err?.response?.data;
            if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
              setErrors(errors);
            }
          },
          onSuccess: () => {
            navigate(showNewCheckoutPage ? "/onboarding/checkout" : "/onboarding/product");
          },
        });
      }}
    >
      {(formik) => (
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8 card">
            <div className="card-body my-5 mx-3">

              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="wouldLikeAFreeTaxPlan">
                    Would you like a 2024 TaxPlan?
                  </label>
                  <br />
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="wouldLikeAFreeTaxPlan"
                      value={"true"}
                      checked={formik.values.wouldLikeAFreeTaxPlan === "true"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>Yes</label>
                  </div>
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="wouldLikeAFreeTaxPlan"
                      value={"false"}
                      checked={formik.values.wouldLikeAFreeTaxPlan === "false"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>No</label>
                  </div>
                  <ErrorMessage component="div" className="text-danger" name="wouldLikeAFreeTaxPlan" />
                </div>
              </div>

              {formik.values.wouldLikeAFreeTaxPlan === "true" && (
                <>
                  <div className="row mb-3 mt-4">
                    <div className="col-12 col-md-12">
                      <label
                        htmlFor="totalEstimatedUsPayrollDecimal"
                      >
                        Total Estimated {taxYearInfoQuery?.currentYear} US Payroll -
                        W2 & contractors - related to product development (Engineers, Designers, Product Managers, etc.)
                      </label>

                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text input-side-box">$</span>
                        </div>
                        <Cleave
                          {...formik.getFieldProps("totalEstimatedUsPayrollDecimal")}
                          options={{ numeral: true }}
                          className="form-control"
                        />
                      </div>
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="totalEstimatedUsPayrollDecimal"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 mt-4">
                    <div className="col-12 col-md-12">
                      <label
                        htmlFor="totalEstimatedInternationalPayrollDecimal"
                      >
                        Total Estimated {taxYearInfoQuery?.currentYear} International Payroll -
                        contractors - related to product development (Engineers, Designers, Product Managers, etc.)
                      </label>

                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text input-side-box">$</span>
                        </div>
                        <Cleave
                          {...formik.getFieldProps("totalEstimatedInternationalPayrollDecimal")}
                          options={{ numeral: true }}
                          className="form-control"
                        />
                      </div>
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="totalEstimatedInternationalPayrollDecimal"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 mt-4">
                    <div className="col-12 col-md-12">
                      <label
                        htmlFor="totalEstimatedRevenueDecimal"
                      >
                        Total Estimated {taxYearInfoQuery?.currentYear} Revenue
                      </label>

                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text input-side-box">$</span>
                        </div>
                        <Cleave
                          {...formik.getFieldProps("totalEstimatedRevenueDecimal")}
                          options={{ numeral: true }}
                          className="form-control"
                        />
                      </div>
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="totalEstimatedRevenueDecimal"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 mt-4">
                    <div className="col-12 col-md-12">
                      <label
                        htmlFor="totalEstimatedExpensesDecimal"
                      >
                        Total Estimated {taxYearInfoQuery?.currentYear} Expenses
                      </label>

                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text input-side-box">$</span>
                        </div>
                        <Cleave
                          {...formik.getFieldProps("totalEstimatedExpensesDecimal")}
                          options={{ numeral: true }}
                          className="form-control"
                        />
                      </div>
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="totalEstimatedExpensesDecimal"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 mt-4">
                    <div className="col-12 col-md-12">
                      <label
                        htmlFor="totalEstimatedAssetsDecimal"
                      >
                        Total Estimated {taxYearInfoQuery?.currentYear} Assets (How much funding have you raised?)
                      </label>

                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text input-side-box">$</span>
                        </div>
                        <Cleave
                          {...formik.getFieldProps("totalEstimatedAssetsDecimal")}
                          options={{ numeral: true }}
                          className="form-control"
                        />
                      </div>
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="totalEstimatedAssetsDecimal"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 mt-4">
                    <div className="col-12 col-md-12">
                      <label htmlFor="statesWithOffices">
                        Which states did the company have founders,
                        W2 employees, or an office in during {taxYearInfoQuery?.currentYear}?
                      </label>
                      <Typeahead
                        id="us-states-selector"
                        isLoading={countrySubdivisionQueryIsLoading}
                        options={countrySubdivisionQuery || []}
                        placeholder='Search for State'
                        selected={formik.values.statesWithOffices}
                        labelKey="name"
                        multiple={true}
                        onChange={(selected) => {
                          formik.setFieldValue("statesWithOffices", selected);
                        }}
                      />
                      <ErrorMessage component="div" className="text-danger mt-1" name="statesWithOffices" />
                    </div>
                  </div>
                </>
              )}

              <hr className="row my-5" />
              <div className="row mb-3 mt-2">
                <div className="d-flex flex-row col-12 col-md-12 justify-content-between ">
                  <button
                    onClick={() => navigate("/onboarding/activity2")}
                    className="btn btn-lg btn-secondary"
                  >
                    <div>Go Back</div>
                  </button>
                  <button onClick={() => formik.submitForm()} className="btn btn-lg btn-primary">
                    {taxPlanMutation.isLoading ? <Spinner /> : <div>Continue</div>}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>

      )}

    </Formik>
  );
};

export default TaxPlan;
