import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  Formik, Field, Form, ErrorMessage,
} from "formik";
import * as Yup from "yup";
import { Button, Spinner, Form as BootStrapForm } from "react-bootstrap";
import {
  getCustomerLogoImage,
  getUser, saveUser,
  uploadCustomerLogoImage,
} from "./settings.api";
import { LoggedInUserContext } from "../../context/LoggedInUserContext";
import { CustomerLogo, User } from "./settings.type";
import { FORM_ERROR_MSG } from "../../constants/general";
import FondoToast from "../fusion-kit/FondoToast";

export default function UserSettings() {
  const userQuery = useQuery(
    ["userSettings"],
    () => getUser(),
    {
      enabled: false,
      onSuccess: (data) => {
        setFormValues(data);
      },
    },
  );
  const customerLogo = useQuery<CustomerLogo, Error>(
    ["customerLogoImage"],
    getCustomerLogoImage,
    {
      refetchOnWindowFocus: false,
    }
  )
  const currentLoggedInUserContext = React.useContext(LoggedInUserContext);

  useEffect(() => {
    if (currentLoggedInUserContext) {
      userQuery.refetch();
    }
  }, []);

  const [formValues, setFormValues] = useState<User>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    phoneNumber: "",
  });
  const userMutation = useMutation<any, AxiosError<any>, User>((data) => saveUser(data));
  const [customerLogoImage, setCustomerLogoImage] = useState<File | undefined>(undefined);
  const [showFondoToast, setShowFondoToast] = useState(false);
  const [fondoToastErrorMessage, setFondoToastErrorMessage] = useState("");
  const uploadCustomerLogoMutation = useMutation<any, Error, any>(
    (file) => uploadCustomerLogoImage(file),
    {
      onSuccess: () => {
        customerLogo.refetch();
        setCustomerLogoImage(undefined);
        setFondoToastErrorMessage("");
        setShowFondoToast(true);
      },
      onError: (error: any) => {
        const errorMessage = error.response?.data.message;
        setFondoToastErrorMessage(errorMessage);
        setShowFondoToast(true);
      }
    },
  )
  const handleCustomerLogoUpload = () => {
    if (customerLogoImage) {
      uploadCustomerLogoMutation.mutate(customerLogoImage);
    }
  }
  const handleFileChange = (event: React.ChangeEvent<any>) => {
    const file = event.target.files?.[0];
    if (file) {
      setCustomerLogoImage(file);
    }
  }

  const renderCustomerLogo = () => {
    if (uploadCustomerLogoMutation.isLoading || customerLogo.isLoading) {
      return (<Spinner />);
    }
    return (
      customerLogo.data?.location
        ? <img
          style={{ width: 75 }}
          src={customerLogo.data.location}
        />
        : <p className="text-center">No image uploaded</p>
    );
  }

  return (
    <>
      <Formik
        initialValues={formValues}
        enableReinitialize={true}
        validationSchema={Yup.object({
          firstName: Yup.string().required(`${FORM_ERROR_MSG.REQUIRED_FIELD_CUSTOM}first name.`),
          lastName: Yup.string().required(`${FORM_ERROR_MSG.REQUIRED_FIELD_CUSTOM}last name.`),
          phoneNumber: Yup.string().required(FORM_ERROR_MSG.REQUIRED_PHONE),
          title: Yup.string().required(`${FORM_ERROR_MSG.REQUIRED_FIELD_CUSTOM}title.`),
        })}
        onSubmit={(values, { setErrors }) => {
          userMutation.mutate(values, {
            onError: (err) => {
              const errors = err?.response?.data;
              if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
                setErrors(errors);
              }
            },
          });
        }}>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8 card">
            <Form className="card-body mt-5 mx-3">
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-6">
                  <label htmlFor="firstName">First name</label>
                  <Field name="firstName" type="text" className={"form-control"} />
                  <ErrorMessage component="div" className="text-danger" name="firstName" />
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="lastName">Last name</label>
                  <Field name="lastName" type="text" className={"form-control"} />
                  <ErrorMessage component="div" className="text-danger mt-1" name="lastName" />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-6">
                  <label htmlFor="title">Title</label>
                  <Field
                    name="title"
                    type="text"
                    className={"form-control"}
                    placeholder={"CEO"}
                  />
                  <ErrorMessage component="div" className="text-danger" name="title" />
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="email">Email</label>
                  <Field name="email" type="text" className={"form-control"} disabled="true" />
                  <ErrorMessage component="div" className="text-danger" name="email" />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="phoneNumber">Phone number</label>
                  <Field name="phoneNumber" type="text" className={"form-control"} />
                  <ErrorMessage component="div" className="text-danger" name="phoneNumber" />
                </div>
              </div>
              <div className="row mt-2">
                <div className="d-flex flex-row col-12 col-md-12 justify-content-end">
                  <button
                    className="btn btn-lg btn-primary"
                    type="submit"
                    disabled={userMutation.isLoading}>
                    {userMutation.isLoading
                      ? <span><Spinner size="sm" />  Saving</span>
                      : <div>Save general information</div>}
                  </button>
                </div>
              </div>
            </Form>
            <div className="card-header my-0">
              Upload Company Logo
            </div>
            <div className="card-body">
              <div className="col-3">
                <BootStrapForm.Control
                  type="file"
                  onChange={(event) => handleFileChange(event)}
                />
                <div className="my-4 d-flex justify-content-center">
                  {renderCustomerLogo()}
                </div>
              </div>
              <Button
                className="col-3"
                onClick={handleCustomerLogoUpload}
                disabled={!customerLogoImage}
              >
                {customerLogoImage ? "Upload Image" : "Add an image"}
              </Button>
            </div>
          </div>
        </div>
      </Formik>
      <FondoToast
        show={showFondoToast}
        handleClose={() => setShowFondoToast(false)}
        title={`Image Upload ${fondoToastErrorMessage ? "Failed" : "Successful"}`}
        message={fondoToastErrorMessage || "Your company logo has been saved"}
        error={!!fondoToastErrorMessage}
      />
    </>
  );
}
