import { DJANGO_BASE_URL } from "../../constants/config";
import { RUNWAY } from "../../constants/network-calls";

export default function Runway() {
  // const runwayQuery = useQuery<string, Error>(['runway'], getRunway, { enabled: false });

  // useEffect(() => {
  //     runwayQuery.refetch();
  // }, []);

  // return <iframe srcDoc={runwayQuery.data}></iframe>;
  return <iframe src={DJANGO_BASE_URL + RUNWAY}></iframe>;
}
