import { RNDFORM } from "../../../constants/network-calls";
import { RnDValues } from "./baseFormData";
import { getFondoApi, postFondoApi } from "../../../utils/util.api";

export async function getRnDForm(taxYear: number): Promise<RnDValues> {
  const result = await getFondoApi(RNDFORM, { taxYear });
  return result as RnDValues;
}

export async function updateRnDForm(data: RnDValues): Promise<RnDValues> {
  const result = await postFondoApi(RNDFORM, data, { taxYear: data.taxYear });
  return result as RnDValues;
}
