import React from "react";

//
// Avatar
//

const Avatar: any = React.forwardRef(({
  as: Tag = "div", ratio, size, status, className, ...props
}: any, ref) => {
  const classes = `avatar${ratio ? ` avatar-${ratio}` : ""}${size ? ` avatar-${size}` : ""}`
    + `${status ? ` avatar-${status}` : ""} ${className}`;

  return <Tag className={classes} ref={ref} {...props} />;
});

//
// Avatar group
//

Avatar.Group = React.forwardRef(({ as: Tag = "div", className, ...props }: any, ref) => {
  const classes = `avatar-group ${className}`;

  return <Tag className={classes} ref={ref} {...props} />;
});

//
// Avatar image
//

Avatar.Image = React.forwardRef(({ as: Tag = "img", className, ...props }: any, ref) => {
  const classes = `avatar-img ${className}`;

  return <Tag className={classes} ref={ref} {...props} />;
});

//
// Avatar title
//

Avatar.Title = React.forwardRef(({ as: Tag = "div", className, ...props }: any, ref) => {
  const classes = `avatar-title ${className}`;

  return <Tag className={classes} ref={ref} {...props} />;
});

export default Avatar;
