import React from "react";
import { LoggedInUserContextType } from "./context.type";

export const LoggedInUserContext = React.createContext<LoggedInUserContextType>({
  currentLoggedInUserId: 0,
  currentLoggedInUserName: "",
  currentCompanyId: 0,
  currentCompanyName: "",
  currentDashboardOnboarding: false,
  currentCompanyDepositPaid: false,
  currentCompanyNonDeCCorp: false,
  currentCompanyShowNewRunway: false,
});
