import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import { getCompanyFilingsDeadlines } from "../FinanceDashboard.api";
import { CompanyFilingsDeadlinesPaginatedResponse } from "../financeDashboard.type";
import { formatDateToWords } from "../../../utils/common.util";
import { convertNumberToCurrency } from "../../../utils/utils";

const TaxDeadlineCardTable = () => {
  const amountOfDeadlinesToShowByDefault = 4;

  const { data: companyFilingsDeadlines } = useQuery<CompanyFilingsDeadlinesPaginatedResponse, Error>(
    ["companyFilingsDeadlines"],
    () => getCompanyFilingsDeadlines(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setDeadlinesOnDisplay({
            ...data,
            results: data?.results.slice(0, amountOfDeadlinesToShowByDefault),
          });
        }
      },
    },
  );

  const [showAllDeadlines, setShowAllDeadlines] = useState(false);
  const [deadlinesOnDisplay, setDeadlinesOnDisplay] = useState<CompanyFilingsDeadlinesPaginatedResponse>();

  const getEstimatedOwedColorClassName = (amount: string) => {
    if (Number(amount) > 0) {
      return "danger";
    }
  };

  const handleViewAllDeadlines = () => {
    setShowAllDeadlines(true);
    setDeadlinesOnDisplay(companyFilingsDeadlines);
  };

  const handleViewLessDeadlines = () => {
    setShowAllDeadlines(false);
    if (companyFilingsDeadlines?.results) {
      const updatedState = {
        ...companyFilingsDeadlines,
        results: companyFilingsDeadlines?.results.slice(0, amountOfDeadlinesToShowByDefault),
      };
      setDeadlinesOnDisplay(updatedState);
    }
  };

  return (
    <>
      {companyFilingsDeadlines && (
        <Card>
          <Card.Header>
            <h4 className="card-header-title">
              Corporate Tax Nexus
            </h4>
            {showAllDeadlines && (
                <Card.Link
                  onClick={() => handleViewLessDeadlines()}
                  className="small"
                  style={{ cursor: "pointer" }}
                >
                  View Less
                </Card.Link>
            )}
          </Card.Header>
          <Card.Body className="p-0">
            <Table className='table no-wrap mb-0' size="sm">
              <thead>
                <tr>
                  <th>Jurisdiction</th>
                  <th>Due Dates</th>
                  <th className="text-end">Estimated Tax Due</th>
                  <th className="text-center">Estimated Tax Credit</th>
                </tr>
              </thead>
              <tbody>
                {deadlinesOnDisplay?.results.map((deadline) => (
                  <tr key={deadline.uuid}>
                    <td>
                      <h4>{deadline.filingDeadline.filing.jurisdiction.name}</h4>
                      {deadline.filingDeadline.filing.filingTypes.map((filingType, idx) => (
                        <Badge key={idx} bg="light" className="me-2">{filingType.name}</Badge>
                      ))}
                    </td>
                    <td>
                    <h4>{formatDateToWords(deadline.filingDeadline.dueDate)}</h4>
                      <span className="text-muted">{deadline.filingDeadline.filing.name}</span>
                    </td>
                    <td className="text-end">
                      <h4
                        className={`text-${getEstimatedOwedColorClassName(deadline.amount)}`}
                      >
                        ${convertNumberToCurrency(deadline.amount)}
                      </h4>
                    </td>
                    <td className="text-center">
                      <h4>
                        ${convertNumberToCurrency(deadline.taxCreditAmount)}
                      </h4>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
          {!showAllDeadlines && companyFilingsDeadlines?.results.length > amountOfDeadlinesToShowByDefault && (
            <Card.Footer>
              <Card.Link
                onClick={() => handleViewAllDeadlines()}
                className="small"
                style={{ cursor: "pointer" }}
              >
                View All (
                  {(companyFilingsDeadlines?.results.length || 0) - (deadlinesOnDisplay?.results.length || 0)}
                more)
              </Card.Link>
            </Card.Footer>
          )}
        </Card>
      )}

    </>
  );
};

export default TaxDeadlineCardTable;
