import { InputGroup } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

import Flatpickr from "../flatpickr/Flatpickr";

interface DateSelectorFilterProps {
  dateValue: string|undefined;
  placeholder?: string;
  handleDateChange: (date: string|undefined) => void;
}

const DateSelectorFilter = ({
  dateValue,
  placeholder = "Date",
  handleDateChange,
}: DateSelectorFilterProps) => {
  const handleSelectedDateChange = (dates: Date[]) => {
    if (dates.length > 0) {
      const selectedDate = dates.map((date) => date.toISOString().split("T")[0]);
      handleDateChange(selectedDate[0]);
    } else {
      handleDateChange(undefined);
    }
  };

  return (
    <>
      <InputGroup className="input-group-merge">
        <Flatpickr
          onChange={(dates: Date[]) => handleSelectedDateChange(dates)}
          placeholder={placeholder}
          className="form-control"
          value={dateValue}
          options={{ mode: "single" }}
          style={{ width: "3em" }}
        />
        <InputGroup.Text>
          <FeatherIcon icon="chevron-down" size="1em" />
        </InputGroup.Text>
      </InputGroup>
    </>
  );
};

export default DateSelectorFilter;
