import { OverlayTrigger, Popover } from "react-bootstrap";
import { QBOReportingHeader, QBOReportingResult } from "../Reporting.type";
import DrillDownPopoverContent from "./DrillDownPopoverContent";

type DrillDownProfitAndLossRowProps = {
  item: QBOReportingResult;
  total?: boolean;
  padding?: number;
  previousAmount: string;
  currentAmount: string;
  periods: QBOReportingHeader[];
  handleRowClick: (drillDownShowing: boolean, item: QBOReportingResult) => void;
  active: boolean;
};

const DrillDownProfitAndLossRow = ({
  item,
  total,
  padding,
  previousAmount,
  currentAmount,
  periods,
  handleRowClick,
  active,
}: DrillDownProfitAndLossRowProps) => {
  const popoverStyle = {
    maxWidth: "450px",
    width: "100%",
    height: "365px",
    zIndex: 1000,
  };

  const getPopover = () => (
    <Popover id="popover-positioned-right" className="p-0 shadow" style={popoverStyle}>
      <DrillDownPopoverContent item={item} periods={periods} />
    </Popover>
  );

  return (
    <>
      <OverlayTrigger
        onToggle={(drillDownShowing) => handleRowClick(drillDownShowing, item)}
        trigger="click"
        placement="top"
        overlay={getPopover()}
        rootClose
      >
        <tr className={`cursor-pointer hoverable-row ${active && "active-table-row"}`}>
          {total ? (
            <>
              <th style={padding ? { paddingLeft: padding } : {}}>{item.name}</th>
              <th className="px-5">{previousAmount}</th>
              <th className="px-5">{currentAmount}</th>
            </>
          ) : (
            <>
              <td style={padding ? { paddingLeft: padding } : {}}>{item.name}</td>
              <td className="px-5">{previousAmount}</td>
              <td className="px-5">{currentAmount}</td>
            </>
          )}
        </tr>
      </OverlayTrigger>
    </>
  );
};

export default DrillDownProfitAndLossRow;
