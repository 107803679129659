import { Form, InputGroup, Modal } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { addSelectedInstitution } from "./actionItems.api";
import { ActionItemSelectedInstitution, ConnectionType, Institution } from "./actionItems.type";

type ActionItemInstitutionSelectorModalProps = {
    show: boolean;
    handleClose: () => any;
    institutions: Institution[];
    selectedInstitutions: ActionItemSelectedInstitution[];
    connectionType: ConnectionType;
    actionItemId: number;
};

const ActionItemInstitutionSelectorModal = ({
  show, handleClose, institutions, connectionType, selectedInstitutions, actionItemId,
}: ActionItemInstitutionSelectorModalProps) => {
  const [filteredInstitutions, setFilteredInstitutions] = useState<Institution[]>([]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (institutions) {
      setFilteredInstitutions(institutions.length > 0 ? institutions : []);
    }
  }, [institutions]);

  const filterInstitutionsBySearch = (search: string) => {
    const filteredInstitutionsSearch = institutions.filter((
      institution: Institution,
    ) => institution.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    setFilteredInstitutions(filteredInstitutionsSearch);
  };

  const addInstitution = (institutionUuid: string) => {
    addInstitutionToSelectedInstitutions.mutate(institutionUuid);
    handleClose();
  };

  const isInstitutionSelected = (
    institution: Institution,
  ) => selectedInstitutions.some((selectedInstitution) => institution.uuid
    === selectedInstitution.selectedInstitution.uuid);

  const addInstitutionToSelectedInstitutions = useMutation<string, AxiosError<any>, string>(
    (institutionUuid: string) => addSelectedInstitution(actionItemId, institutionUuid),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["actionItemSelectedInstitutions", actionItemId],
        });
      },
    },
  );

  return (
    <Modal show={show} onHide={handleClose} style={{ height: "600px" }}>
      <Modal.Body className="p-0">
        <div className="p-4">
            <h3 className="mb-2">Add {connectionType.name}</h3>
            <small>Select your {connectionType.name} from the list below</small>
        </div>
        <InputGroup className="input-group-merge input-group-reverse">
            <Form.Control
              onChange={(e) => filterInstitutionsBySearch(e.target.value)}
              placeholder="Search Institutions"
              className="rounded-0 border-end-0"
            />
            <InputGroup.Text className="rounded-0 border-start-0 ms-0">
                <FeatherIcon icon="search" size="1em" />
            </InputGroup.Text>
        </InputGroup>
        <div style={{ overflowY: "auto" }}>
          {filteredInstitutions?.map((institution: Institution) => (
            <div
              onClick={() => addInstitution(institution.uuid)}
              role="button"
              className="bg-white border border-gray border-top-0 p-3 ps-3 pe-3 d-flex justify-content-between">
              <div>
                <img src={institution.image?.url} width={"30px"} className="me-3 mt-n1" />
                {institution.name}
              </div>
              {selectedInstitutions?.length > 0 && isInstitutionSelected(institution) && (
                <div>
                  <FeatherIcon icon="check" className="text-muted" size="1em" />
                </div>
              )}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ActionItemInstitutionSelectorModal;
