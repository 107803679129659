import { Card, Badge } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { ProfitAndLossPeriod, TimeRange } from "../financeDashboard.type";
import { convertNumberToCurrency } from "../../../utils/utils";
import { getAmountColorClassName } from "../utils";

type NetIncomeBreakdownCardProps = {
  historicalProfitAndLoss: ProfitAndLossPeriod[];
  selectedTimeRange: TimeRange;
}

const NetIncomeBreakdownCard = ({ historicalProfitAndLoss, selectedTimeRange }: NetIncomeBreakdownCardProps) => {
  const currentMonth = historicalProfitAndLoss[historicalProfitAndLoss.length - 1];
  if (!currentMonth) {
    return <></>;
  }
  const currentRevenueAmount = currentMonth.revenueAmount;
  const currentExpensesAmount = currentMonth.expensesAmount;

  const priorMonth = historicalProfitAndLoss[historicalProfitAndLoss.length - 2];
  const previousRevenueAmount = priorMonth ? priorMonth.revenueAmount : 0;
  const previousExpensesAmount = priorMonth ? priorMonth.expensesAmount : 0;

  const getCurrentPeriodNetIncome = () => (
    currentRevenueAmount - currentExpensesAmount
  );

  const getPreviousPeriodNetIncome = () => (
    previousRevenueAmount - previousExpensesAmount
  );

  const getNetIncomePercentageChangeComparedToPreviousPeriod = () => {
    const percentageChanged = (
      getCurrentPeriodNetIncome() - getPreviousPeriodNetIncome()
    ) / (Math.abs(getPreviousPeriodNetIncome()));
    return percentageChanged * 100;
  };

  const getPeriodLabel = () => `${selectedTimeRange.timePeriod} ${selectedTimeRange.year}`;

  return (
    <Card>
      <Card.Body className="p-0">
        <div className="border-bottom p-4 pb-3">
          <Card.Title className="text-uppercase text-muted mb-2">
            <span className="h5 text-secondary">Net Income </span>
          </Card.Title>
          <div className="d-flex justify-content-between">
            <div>
              <span className={`h1 mb-0 text-${getAmountColorClassName(getCurrentPeriodNetIncome())}`}>
                ${convertNumberToCurrency(getCurrentPeriodNetIncome())}
              </span>
              {Number.isFinite(getNetIncomePercentageChangeComparedToPreviousPeriod()) && (
                <Badge
                  pill
                  bg={`${getAmountColorClassName(getNetIncomePercentageChangeComparedToPreviousPeriod())}-soft`}
                  className="ms-2 mt-n3"
                  as='span'
                >
                  {getNetIncomePercentageChangeComparedToPreviousPeriod().toFixed(1)}%
                </Badge>
              )}
            </div>
            <span className="h2 mb-0">{getPeriodLabel()}</span>
          </div>
        </div>

        <div className="p-4">
          <div className="d-flex justify-content-between">
            <span>
              <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
              Income
            </span>
            <span>${convertNumberToCurrency(currentRevenueAmount)}</span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <span>
              <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
              Expenses
            </span>
            <span>${convertNumberToCurrency(currentExpensesAmount)}</span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <strong>
              Net Income
            </strong>
            <span className={`text-${getAmountColorClassName(getCurrentPeriodNetIncome())}`}>
              ${convertNumberToCurrency(getCurrentPeriodNetIncome())}
            </span>
          </div>

        </div>
      </Card.Body>
    </Card>
  );
};

export default NetIncomeBreakdownCard;
