import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { ChartData } from "./types";
import { getAmountColorHEXCode } from "../../finance-dashboard/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Tooltip,
  Filler,
);

type FondoBarChartProps = {
  minYAxisValue?: number;
  maxYAxisValue?: number;
  labels: string[];
  chartData: ChartData[];
  noDataState?: boolean;
  reverse?: boolean;
  useAmountSemanticColors?: boolean;
}

const FondoBarChart = ({
  minYAxisValue,
  maxYAxisValue,
  labels,
  chartData,
  noDataState = false,
  reverse = false,
  useAmountSemanticColors = false,
}: FondoBarChartProps) => {
  const options = {
    maintainAspectRatio: false,
    barThickness: 8,
    borderRadius: 5,
    scales: {
      y: {
        min: minYAxisValue,
        max: maxYAxisValue,
        border: { dash: [4, 4] },
        beginAtZero: true,
        reverse,
      },
    },
  };

  const datasets = chartData.map((object: any) => {
    const newObject = { ...object };
    if (useAmountSemanticColors) {
      newObject.backgroundColor = object.data.map((value: number|null) => getAmountColorHEXCode(value));
    } else {
      newObject.backgroundColor = object.color;
    }
    delete newObject.color;
    return newObject;
  });

  const data = {
    labels,
    datasets,
  };

  return (
    <>
      {noDataState ? (
        <Bar options={options} data={ { datasets: [] } } height="270px" />
      ) : (
        <Bar options={options} data={data} height="270px" />
      )}
    </>
  );
};

export default FondoBarChart;
