import FeatherIcon from "feather-icons-react";
import SVGBankIcon from "../svg-components/SVGBankIcon";
import PlaidConnectionButton from "./PlaidConnectionButton";

// The new version is the one being used in the "connections" page,
// dashboard-onboarding flow.
// The only differences with the original version are for the design.

const BankAccountsIntegrationCardV2 = (
  { amountOfAccounts } : { amountOfAccounts: number },
) => (
  <div>
    <div className="card justify" style={{ position: "static" }}>
      <div className="card-body mx-4 mb-4">
        <h1 className="text-uppercase text-center text-muted my-4">
          <SVGBankIcon />
        </h1>
        <div className="row no-gutters align-items-center justify-content-center">
          <div className="col-auto">
              <div className="h1 mb-0">Bank Accounts</div>
          </div>
        </div>
        <div className="mb-3">
          <ul className="list-group list-group-flush">
            {
              <li
                className="list-group-item d-flex align-items-center justify-content-between"
              >
                <span>{amountOfAccounts ? "Connected" : "Not Connected"}</span>
                <FeatherIcon
                  icon={amountOfAccounts ? "check-circle" : "x"}
                  className={amountOfAccounts ? "text-success" : "text-danger"}
                  size="1.1em"
                />
              </li>
            }
            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
              <span>Accounts</span>
              <span>
                {amountOfAccounts}
                {amountOfAccounts === 1 ? " Account" : " Accounts"}
              </span>
            </li>
          </ul>
        </div>
        <div className="justify-content-center d-flex">
          <PlaidConnectionButton
            buttonContent={"Add Bank"}
            className={"btn btn-block px-6 btn-primary"}
          />
        </div>
      </div>
    </div>
  </div>
);

export default BankAccountsIntegrationCardV2;
