import { InputGroup, Form } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { ChangeEvent, useEffect, useState } from "react";

interface BasicSearchProps {
  handleSearchChange: (search: string) => void;
  currentSearch: string;
  placeholderText?: string;
}

const BasicSearch = ({
  handleSearchChange,
  currentSearch,
  placeholderText = "Search",
}: BasicSearchProps) => {
  const [search, setSearch] = useState(currentSearch);

  useEffect(() => {
    setSearch(currentSearch);
  }, [currentSearch]);

  const searchByQuery = (e: React.ChangeEvent) => {
    const searchQuery = (e.target as HTMLTextAreaElement).value;
    handleSearchChange(searchQuery);
  };

  const debounce = (func: (e: ChangeEvent) => void, delay = 500) => {
    let timer: NodeJS.Timeout;
    return (...args: [e: ChangeEvent<Element>]) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearchInputChange = (e: ChangeEvent) => {
    const searchQuery = (e.target as HTMLTextAreaElement).value;
    setSearch(searchQuery);
    debouncedSearchByQuery(e);
  };

  const debouncedSearchByQuery = debounce(searchByQuery);

  return (
    <div>
      <InputGroup className="input-group-merge" size="sm">
        <InputGroup.Text style={{ border: "none", background: "none" }}>
          <FeatherIcon icon="search" size="1.5em" />
        </InputGroup.Text>
        <Form.Control
          style={{ border: "none", boxShadow: "none" }}
          placeholder={placeholderText}
          onChange={(e) => handleSearchInputChange(e)}
          value={search}
        />
    </InputGroup>
    </div>
  );
};

export default BasicSearch;
