import * as Yup from "yup";
import { FORM_ERROR_MSG } from "../../constants/general";

export const onboardingActivity2Schema = Yup.object({
  bookkeepingManagerCurrentYear: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasForeignBankAccountsCurrentYear: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasForeignShareholdersCurrentYear: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  needsFondoBookkeepingCurrentYear: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  needsMonthlyBookkeepingCurrentYear: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasInternationalSubsidiariesCurrentYear: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  domesticSubsidiariesCurrentYear: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  needsAssistanceWithTaxPrevYear: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasForeignBankAccountsPrevYear: Yup.string().when("needsAssistanceWithTaxPrevYear", {
    is: (needsAssistanceWithPrevYear: any) => needsAssistanceWithPrevYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  hasForeignShareholdersPrevYear: Yup.string().when("needsAssistanceWithTaxPrevYear", {
    is: (needsAssistanceWithPrevYear: any) => needsAssistanceWithPrevYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  needsFondoBookkeepingPrevYear: Yup.string().when("needsAssistanceWithTaxPrevYear", {
    is: (needsAssistanceWithPrevYear: any) => needsAssistanceWithPrevYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  hasInternationalSubsidiariesPrevYear: Yup.string().when("needsAssistanceWithTaxPrevYear", {
    is: (needsAssistanceWithPrevYear: any) => needsAssistanceWithPrevYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  domesticSubsidiariesPrevYear: Yup.string().when("needsAssistanceWithTaxPrevYear", {
    is: (needsAssistanceWithPrevYear: any) => needsAssistanceWithPrevYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  totalExpensesPrevYear: Yup.string().when("needsAssistanceWithTaxPrevYear", {
    is: (needsAssistanceWithPrevYear: any) => needsAssistanceWithPrevYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  stateEmployeesPrevYear: Yup.string().when("needsAssistanceWithTaxPrevYear", {
    is: (needsAssistanceWithPrevYear: any) => needsAssistanceWithPrevYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD).max(50, FORM_ERROR_MSG.MAX_LENGTH(50)),
    otherwise: (schema) => schema.optional(),
  }),
  needsAssistanceWithTaxNextYear: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasForeignBankAccountsNextYear: Yup.string().when("needsAssistanceWithTaxNextYear", {
    is: (needsAssistanceWithTaxNextYear: any) => needsAssistanceWithTaxNextYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  hasForeignShareholdersNextYear: Yup.string().when("needsAssistanceWithTaxNextYear", {
    is: (needsAssistanceWithTaxNextYear: any) => needsAssistanceWithTaxNextYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  needsFondoBookkeepingNextYear: Yup.string().when("needsAssistanceWithTaxNextYear", {
    is: (needsAssistanceWithTaxNextYear: any) => needsAssistanceWithTaxNextYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  hasInternationalSubsidiariesNextYear: Yup.string().when("needsAssistanceWithTaxNextYear", {
    is: (needsAssistanceWithTaxNextYear: any) => needsAssistanceWithTaxNextYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  domesticSubsidiariesNextYear: Yup.string().when("needsAssistanceWithTaxNextYear", {
    is: (needsAssistanceWithTaxNextYear: any) => needsAssistanceWithTaxNextYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  totalExpensesNextYear: Yup.string().when("needsAssistanceWithTaxNextYear", {
    is: (needsAssistanceWithTaxNextYear: any) => needsAssistanceWithTaxNextYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    otherwise: (schema) => schema.optional(),
  }),
  stateEmployeesNextYear: Yup.string().when("needsAssistanceWithTaxNextYear", {
    is: (needsAssistanceWithTaxNextYear: any) => needsAssistanceWithTaxNextYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD).max(50, FORM_ERROR_MSG.MAX_LENGTH(50)),
    otherwise: (schema) => schema.optional(),
  }),
  numberOfForeignBankAccountsCurrentYear: Yup.number().when("hasForeignBankAccountsCurrentYear", {
    is: (hasForeignBankAccountsCurrentYear: any) => hasForeignBankAccountsCurrentYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD).min(1, FORM_ERROR_MSG.NUMBER_GT(1)),
    otherwise: (schema) => schema.optional(),
  }),
  numberOfForeignShareholdersCurrentYear: Yup.number().when("hasForeignShareholdersCurrentYear", {
    is: (hasForeignShareholdersCurrentYear: any) => hasForeignShareholdersCurrentYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD).min(1, FORM_ERROR_MSG.NUMBER_GT(1)),
    otherwise: (schema) => schema.optional(),
  }),
  numberOfInternationalSubsidiariesCurrentYear: Yup.number().when("hasInternationalSubsidiariesCurrentYear", {
    is: (hasInternationalSubsidiariesCurrentYear: any) => hasInternationalSubsidiariesCurrentYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD).min(1, FORM_ERROR_MSG.NUMBER_GT(1)),
    otherwise: (schema) => schema.optional(),
  }),
  numberOfForeignBankAccountsPrevYear: Yup.number().when("hasForeignBankAccountsPrevYear", {
    is: (hasForeignBankAccountsPrevYear: any) => hasForeignBankAccountsPrevYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD).min(1, FORM_ERROR_MSG.NUMBER_GT(1)),
    otherwise: (schema) => schema.optional(),
  }),
  numberOfForeignShareholdersPrevYear: Yup.number().when("hasForeignShareholdersPrevYear", {
    is: (hasForeignShareholdersPrevYear: any) => hasForeignShareholdersPrevYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD).min(1, FORM_ERROR_MSG.NUMBER_GT(1)),
    otherwise: (schema) => schema.optional(),
  }),
  numberOfInternationalSubsidiariesPrevYear: Yup.number().when("hasInternationalSubsidiariesPrevYear", {
    is: (hasInternationalSubsidiariesPrevYear: any) => hasInternationalSubsidiariesPrevYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD).min(1, FORM_ERROR_MSG.NUMBER_GT(1)),
    otherwise: (schema) => schema.optional(),
  }),
  numberOfForeignBankAccountsNextYear: Yup.number().when("hasForeignBankAccountsNextYear", {
    is: (hasForeignBankAccountsNextYear: any) => hasForeignBankAccountsNextYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD).min(1, FORM_ERROR_MSG.NUMBER_GT(1)),
    otherwise: (schema) => schema.optional(),
  }),
  numberOfForeignShareholdersNextYear: Yup.number().when("hasForeignShareholdersNextYear", {
    is: (hasForeignShareholdersNextYear: any) => hasForeignShareholdersNextYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD).min(1, FORM_ERROR_MSG.NUMBER_GT(1)),
    otherwise: (schema) => schema.optional(),
  }),
  numberOfInternationalSubsidiariesNextYear: Yup.number().when("hasInternationalSubsidiariesNextYear", {
    is: (hasInternationalSubsidiariesNextYear: any) => hasInternationalSubsidiariesNextYear === "Yes",
    then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD).min(1, FORM_ERROR_MSG.NUMBER_GT(1)),
    otherwise: (schema) => schema.optional(),
  }),
});
