import { Payroll } from "../financeDashboard.type";

export const getRDCreditEstimateAmount = (payrollData?: Payroll) => {
  if (payrollData) {
    const totalEstimatedUsPayrollDecimal = parseFloat(payrollData?.totalEstimatedUsPdRelatedPayroll || "0");
    return totalEstimatedUsPayrollDecimal * 0.06;
  }
  return 0;
};

export const getNetIncomeEstimate = (payrollData?: Payroll) => {
  if (payrollData) {
    const totalEstimatedRevenue = parseFloat(payrollData.income || "0");
    const totalEstimatedExpenses = parseFloat(payrollData.expenses || "0");
    return totalEstimatedRevenue - totalEstimatedExpenses;
  }
  return 0;
};

export const getSection174Estimate = (payrollData?: Payroll) => {
  if (payrollData) {
    const totalEstimatedUsPayrollDecimal = parseFloat(payrollData?.totalEstimatedUsPdRelatedPayroll || "0");
    const totalEstimatedInternationalPayroll = parseFloat(
      payrollData?.totalEstimatedInternationalPdRelatedPayroll || "0",
    );
    const totalEstimatedPayrolls = totalEstimatedUsPayrollDecimal + totalEstimatedInternationalPayroll;
    const section174Estimate = totalEstimatedPayrolls - (
      (totalEstimatedUsPayrollDecimal / 10) + (totalEstimatedInternationalPayroll / 30)
    );
    return section174Estimate;
  }
  return 0;
};

export const getTaxableIncomeEstimate = (payrollData?: Payroll) => {
  if (payrollData) {
    const section174Estimate = getSection174Estimate(payrollData);
    const netIncomeEstimate = getNetIncomeEstimate(payrollData);
    return section174Estimate + netIncomeEstimate;
  }
  return 0;
};
