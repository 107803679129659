import { Badge } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { TaxDeadline } from "./taxDeadlines.type";

export interface TaxDeadlineCardProps {
    taxDeadline: TaxDeadline;
}

export default function TaxDeadlineCard({ taxDeadline }: TaxDeadlineCardProps) {
  function getClass(deadline: TaxDeadline) {
    if (!deadline.isFutureDeadline) {
      return "avatar-transparent transparent text-muted";
    } if (deadline.isTaxDeadline) {
      return "bg-success-soft text-primary";
    }
    return "bg-primary-soft text-primary";
  }

  function getBadgeClass(badgeColor: string) {
    switch (badgeColor) {
      case "badge-soft-success":
        return "success-soft";
      case "badge-warning":
        return "warning";
      case "badge-soft-danger":
        return "danger-soft";
      case "badge-soft-info":
        return "info-soft";
      default:
        return "info-soft";
    }
  }

  function renderTags(deadline: TaxDeadline) {
    return deadline.tags.map((tag) => (
            <Badge bg={getBadgeClass(tag.tagColor)} className='ms-2' key={tag.text}>{tag.text}</Badge>
    ));
  }

  function renderTaxDeadline() {
    return (
      taxDeadline && (
                <div className="list-group-item">
                    <div className="row">
                        <div className="col-auto">
                            <div className={taxDeadline.isTaxDeadline
                              ? "avatar avatar-lg align-avatar"
                              : "avatar avatar-sm"
                              }>
                                <div className={`avatar-title font-size-lg rounded-circle ${getClass(taxDeadline)}`}>
                                    <FeatherIcon icon={taxDeadline.icon} size="1.1em" />
                                </div>
                            </div>
                        </div>
                        <div className={taxDeadline.isTaxDeadline ? "col ml-n2 mt-4" : "col ml-n2 align-deadlines"}>
                            <h2 className={`mb-3 ${taxDeadline.isFutureDeadline ? "" : "text-muted transparent"}`}>
                                {new Date(taxDeadline.deadline).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  timeZone: "UTC",
                                })}
                            </h2>
                            <p className={`mb-0 ${taxDeadline.isFutureDeadline
                              ? "text-gray-700"
                              : "text-muted transparent"
                            }`}>
                              {taxDeadline.description}
                            </p>
                            <span className="txt-sm text-muted">
                                {taxDeadline.info
                                  && <span><FeatherIcon icon="info" size="1.1em" /> &nbsp;{taxDeadline.info}</span>}
                                {taxDeadline.tags?.length && renderTags(taxDeadline)}
                            </span>
                        </div>
                    </div>
                </div>
      )
    );
  }

  return renderTaxDeadline();
}
