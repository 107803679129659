import {
  Col, Container, Form, Row,
} from "react-bootstrap";
import checkmark from "../../../assets/img/icons/greenCheckCircle.png";

type CreateNewReportRowProps = {
  reportTitle: string;
  reportBody: string;
  sectionIsSelected: boolean;
  reportingImage: string;
  handleChange: (change: React.ChangeEvent<HTMLInputElement>) => void;
}

const CreateNewReportRow = ({
  reportTitle, reportBody, sectionIsSelected, reportingImage, handleChange,
}: CreateNewReportRowProps) => (
  <Container>
    <Row>
      <Col sm={3}>
        <div className="position-relative d-inline-block">
          <div className="p-3 mx-auto">
            <div className="py-4 px-5 rounded bg-dark">
              <img src={reportingImage} width={30} />
            </div>
            {sectionIsSelected && <img src={checkmark} width={30} className="position-absolute bottom-0 end-0" />}
          </div>
        </div>
      </Col>
      <Col className="d-flex align-items-center">
        <div className="pt-3">
          <h4>{reportTitle}</h4>
          <p className="text-muted">{reportBody}</p>
        </div>
      </Col>
      <Col className="d-flex align-items-center" sm={2}>
        <Form.Check
          type="switch"
          checked={sectionIsSelected}
          onChange={(change) => handleChange(change)}
        />
      </Col>
    </Row>
  </Container>
);

export default CreateNewReportRow;
