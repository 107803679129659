import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { useState } from "react";
import {
  PassportField,
  PassportFieldTypes,
  PassportFieldTypesToFondoFormGroupTypes,
} from "./passport.type";
import { FieldTypes } from "../forms/formik-components/formTypes";
import { CreatePassportFieldForm, CreatePassportFieldFormValues } from "./CreatePassportFieldForm";

type CreatePassportFieldModalProps = {
    show: boolean;
    creationFailureMessage: string;
    handleClose: () => void;
    handleSave: (newPassportField: PassportField) => void;
};

export const CreatePassportFieldModal = ({
  show,
  creationFailureMessage,
  handleClose,
  handleSave,
}: CreatePassportFieldModalProps) => {
  const [passportFieldType, setPassportFieldType] = useState<PassportFieldTypes>(PassportFieldTypes.TEXT);
  const labels = {
    displayName: "Field Name",
    fieldType: "Field Type",
    fieldValue: "Field Value",
  };
  const types = {
    displayName: FieldTypes.TEXT,
    fieldType: FieldTypes.BASIC_SELECTOR,
    fieldValue: PassportFieldTypesToFondoFormGroupTypes[passportFieldType],
  };
  const placeholders = {
    displayName: "Enter field name",
    fieldType: "Select field type",
    fieldValue: "Enter field value",
  };

  const handleSubmit = (values: CreatePassportFieldFormValues) => {
    handleSave({
      fieldName: "",
      displayName: values.displayName,
      fieldType: values.fieldType,
      value: values.fieldValue,
      isAddressField: false,
      isCustomField: true,
    });
  };

  const validateForm = (values: CreatePassportFieldFormValues) => {
    const errors: { [key: string]: string } = {};
    if (!values.displayName) {
      errors.displayName = "Field name is required";
    }
    if (!values.fieldType) {
      errors.fieldType = "Field type is required";
    }
    return errors;
  };

  return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Create Custom Passport Field</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{ displayName: "", fieldType: PassportFieldTypes.TEXT, fieldValue: "" }}
                validate={validateForm}
                onSubmit={handleSubmit}
                isInitialValid={false}
            >
                <CreatePassportFieldForm
                    setPassportFieldType={setPassportFieldType}
                    labels={labels}
                    types={types}
                    placeholders={placeholders}
                    handleClose={handleClose}
                    creationFailureMessage={creationFailureMessage} />
            </Formik>
        </Modal>
  );
};
