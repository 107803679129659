import NewDocumentCenterCard from "./NewDocumentCenterCard";
import FondoLayout from "../fusion-kit/FondoLayout";

const DocumentCenterParent = () => (
    <FondoLayout title="Document Center">
      <NewDocumentCenterCard/>
    </FondoLayout>
  );

export default DocumentCenterParent;
