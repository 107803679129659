import { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { darkBlueText, gradientStyle } from "./utils";
import FondoServiceDetailModal from "./FondoServiceDetailModal";

export type SalesCardProps = {
  serviceName: string;
  serviceDescription: string;
  illustration: string;
  modalHeaderTitle: string;
  modalHeaderDescription: JSX.Element;
  modalServiceDetailTitle: string;
  modalServiceDetailDescription: JSX.Element;
  modalRedirectUrl: string;
  addMarginToSides?: boolean;
}

const SalesCard = ({
  serviceName,
  serviceDescription,
  illustration,
  modalHeaderTitle,
  modalHeaderDescription,
  modalServiceDetailTitle,
  modalServiceDetailDescription,
  modalRedirectUrl,
  addMarginToSides = true,
}: SalesCardProps) => {
  const [showServiceDetailModal, setShowDetailModal] = useState(false);

  return (
    <>
      <Card className={`m-0 rounded border ${addMarginToSides && "mx-2"}`} style={gradientStyle}>
        <Card.Body className="px-4 pt-4 pb-3">
          <div className="d-flex align-items-center">
            <div>
              <h2 className="mb-0" style={darkBlueText}>
                {serviceName}
              </h2>
              <p className="text-muted fs-5 fw-light mt-2 mb-3">
                {serviceDescription}
              </p>
              <Button
                onClick={() => setShowDetailModal(true)}
                variant="primary"
                size="sm"
              >
                Learn More
              </Button>
            </div>
            <div className="ms-auto">
              <img
                src={illustration}
                height="120px"
                className="d-none d-lg-block"
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      <FondoServiceDetailModal
        show={showServiceDetailModal}
        handleClose={() => setShowDetailModal(false)}
        headerTitle={modalHeaderTitle}
        headerDescription={modalHeaderDescription}
        serviceDetailTitle={modalServiceDetailTitle}
        serviceDetailDescription={modalServiceDetailDescription}
        illustration={illustration}
        modalRedirectUrl={modalRedirectUrl}
      />
    </>
  );
};

export default SalesCard;
