import {
  Card,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";

type LoadingProfitAndLossSparkChartsCardsProps = {
  title: string;
}

const LoadingProfitAndLossSparkChartsCards = ({ title }: LoadingProfitAndLossSparkChartsCardsProps) => (
  <Card className="rounded">
    <Card.Body className="py-3">
      <Row className="align-items-center">
        <Col>
          <h6 className="text-uppercase text-muted mb-2">
            {title}
          </h6>
          <span className="h2 mb-0">
            <Spinner variant="primary" animation="border" role="status" />
          </span>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

export default LoadingProfitAndLossSparkChartsCards;
