import { Navigate, useLocation } from "react-router-dom";
import { DJANGO_BASE_URL } from "../../constants/config";
import { YC_ONBOARDING } from "../../constants/network-calls";
import { removeLeadingAndTrailingSlashes } from "../../utils/common.util";

export default function IframeWrapper() {
  const location = useLocation();

  if (removeLeadingAndTrailingSlashes(location.pathname) === removeLeadingAndTrailingSlashes(YC_ONBOARDING)) {
    return <Navigate to="/onboarding" replace={true} />;
  }

  return <iframe key={encodeURIComponent(location.pathname)} src={DJANGO_BASE_URL + location.pathname}></iframe>;
}
