import { Stack } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

type FiveStarsProps = {
  stars?: number | null;
  setStars?: (stars: number) => void;
  className?: string;
  starSize?: number;
};

export const FiveStars = ({ stars, setStars, className, starSize }: FiveStarsProps) => (
  <Stack direction="horizontal" gap={1} className={className}>
    {Array.from({ length: 5 }).map((_, i) => (
      <FeatherIcon
        key={i}
        style={{ strokeWidth: "0" }}
        icon="star"
        size={starSize ?? 18}
        fill={i < Math.round(stars ?? 0) ? "#fce668" : "lightgray"}
        onClick={() => setStars && setStars(i + 1)}
        className={setStars ? "cursor-pointer" : ""}
      />
    ))}
  </Stack>
);
