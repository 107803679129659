import { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { Button, Card, Form } from "react-bootstrap";
import { DateRangeIntervals, TimeRange } from "./financeDashboard.type";
import { mapMonthDateToMonth } from "./constants";

type TimeRangeIntervalSelectorProps = {
  balanceAvailableIntervals?: DateRangeIntervals;
  handleApplyInterval: (data: TimeRange) => void;
  currentTimeRange: TimeRange;
  className?: string;
  openByDefault?: boolean;
}

const TimeRangeIntervalSelector = ({
  balanceAvailableIntervals,
  handleApplyInterval,
  currentTimeRange,
  className,
  openByDefault = false,
}: TimeRangeIntervalSelectorProps) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>({ interval: "month", year: "" });
  const [showSelector, setShowSelector] = useState<boolean>(openByDefault);
  const allowSubmission = () => {
    // Requires you to select a year
    if (selectedTimeRange.interval === "year" && !!selectedTimeRange.year) {
      return true;
    }
    // Requires you to select a year and month/quarter
    if (!!selectedTimeRange.year && !!selectedTimeRange.timePeriod) {
      return true;
    }
    return false;
  };

  const resetIntervalSelection = () => {
    setSelectedTimeRange({
      interval: currentTimeRange.interval,
      year: currentTimeRange.year,
      timePeriod: currentTimeRange.timePeriod,
    });
  };

  const clearTimeRange = (interval: string) => {
    setSelectedTimeRange({ interval, year: "", timePeriod: "" });
  };

  const handleSubmission = () => {
    handleApplyInterval(selectedTimeRange);
    setShowSelector(false);
    resetIntervalSelection();
  };

  const handleSelectedYear = (selectedYear: string, timePeriod: string[]) => {
    const timeFrame: string[] = [];
    timePeriod.forEach((period) => {
      const [currentYear, currentTimePeriod] = period.split("-");
      if (selectedYear === currentYear && !timeFrame.includes(currentTimePeriod)) {
        if (selectedTimeRange.interval === "month") {
          timeFrame.push(mapMonthDateToMonth[currentTimePeriod]);
        } else {
          timeFrame.push(currentTimePeriod);
        }
      }
    });
    setSelectedTimeRange({
      ...selectedTimeRange,
      cycles: timeFrame.reverse(),
      year: selectedYear,
      timePeriod: "",
    });
  };

  const getYearsFromTimePeriod = (timePeriod: string[]) => {
    const foundYears: string[] = [];
    timePeriod.forEach((period) => {
      const currentYear = period.split("-")[0];
      if (!foundYears.includes(currentYear)) {
        foundYears.push(currentYear);
        return currentYear;
      }
    });
    return foundYears;
  };

  return (
    <Card className={className}>
      <Card.Header style={{ cursor: "pointer" }} onClick={() => setShowSelector(!showSelector)}>
        <div>
          <span className="h4 pe-2 card-header-title">
            Time Range
          </span>
          <FeatherIcon
            icon="calendar"
            size="1.3em"
            className="mb-1 text-secondary"
          />
          <span className="h4 card-header-title ms-2">
            {currentTimeRange.timePeriod} {currentTimeRange.year}
          </span>
        </div>
        <FeatherIcon
          icon={`chevron-${showSelector ? "up" : "down"}`}
          size="22"
          className="mt-n1"
          style={{ cursor: "pointer" }}
        />
      </Card.Header>
      {showSelector && <Card.Body>
        <Form.Select
          value={selectedTimeRange.interval}
          onChange={(event) => { clearTimeRange(event.target.value); }}
          className="mb-2"
        >
          <option value="month">Month</option>
          <option value="quarter">Quarter</option>
          <option value="year">Year</option>
        </Form.Select>
        {selectedTimeRange.interval === "month" && (
          <>
            <hr />
            <p>Year</p>
            <Form.Select
              className="mb-3"
              onChange={(year) => handleSelectedYear(year.target.value, balanceAvailableIntervals?.months || [])}
            >
              <option value="">Select Year</option>
              {getYearsFromTimePeriod(balanceAvailableIntervals?.months || []).map((year, idx) => (
                <option
                  key={idx}
                  value={year}
                >
                  {year}
                </option>
              ))}
            </Form.Select>
            <p>Month</p>
            <Form.Select
              onChange={(month) => setSelectedTimeRange({ ...selectedTimeRange, timePeriod: month.target.value })}
              value={selectedTimeRange.timePeriod}
            >
              <option value="">Select Month</option>
              {selectedTimeRange.cycles?.map((month, idx) => (
                <option
                  key={idx}
                  value={month}
                >
                  {month}
                </option>
              ))}
            </Form.Select>
          </>
        )}
        {selectedTimeRange.interval === "quarter" && (
          <>
            <hr />
            <p>Year</p>
            <Form.Select
              className="mb-3"
              onChange={(year) => handleSelectedYear(year.target.value, balanceAvailableIntervals?.quarters || [])}
            >
              <option value="">Select Year</option>
              {getYearsFromTimePeriod(balanceAvailableIntervals?.quarters || []).map((year, idx) => (
                <option
                  key={idx}
                  value={year}
                >{year}</option>
              ))}
            </Form.Select>
            <p>Quarter</p>
            <Form.Select
              value={selectedTimeRange.timePeriod}
              onChange={(quarter) => setSelectedTimeRange({ ...selectedTimeRange, timePeriod: quarter.target.value })}
            >
              <option value="">Select Quarter</option>
              {selectedTimeRange.cycles?.map((month, idx) => (
                <option
                  key={idx}
                  value={month}
                >
                  {month}
                </option>
              ))}
            </Form.Select>
          </>
        )}
        {selectedTimeRange.interval === "year" && (
          <>
            <hr />
            <p>Year</p>
            <Form.Select
              onChange={(year) => setSelectedTimeRange({ ...selectedTimeRange, year: year.target.value })}
            >
              <option value="">Select Year</option>
              {balanceAvailableIntervals?.years.map((year, idx) => (
                <option
                  key={idx}
                  value={year}
                >{year}</option>
              ))}
            </Form.Select>
          </>
        )}
        <hr />
        <Button
          className={`${allowSubmission() ? "" : "disabled"}`}
          onClick={handleSubmission}
        >Apply</Button>
      </Card.Body>
      }
    </Card>
  );
};

export default TimeRangeIntervalSelector;
