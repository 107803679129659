import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { FOREIGN_SUBSIDIARY_INFO_MODAL } from "../../../constants/static-content";
import StaticContentModal from "../../modals/StaticContentModal";
import { AddonDetailType } from "./product.type";

export type AddonsTableProps = {
    addons: AddonDetailType[];
}

const AddonsTable = ({ addons }: AddonsTableProps) => {
  const [showForeignSubsidiaryInfoModal, setShowForeignSubsidiaryInfo] = useState(false);

  return (
        <>
            <table className="table my-3">
                <thead>
                    <tr>
                        <th className="px-0 bg-transparent border-top-0">
                            <span className="h6">Add-on</span>
                        </th>
                        <th className="px-0 bg-transparent border-top-0 text-center">
                            <span className="h6">Quantity</span>
                        </th>
                        <th className="px-0 bg-transparent border-top-0 text-end">
                            <span className="h6">Subtotal</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {addons.map((addon: AddonDetailType) => (
                        <tr>
                            <td className="px-0">
                                {addon.addon}
                                {addon.addon === "Foreign Subsidiary" && (
                                    <span
                                        className="ms-2"
                                        onClick={() => setShowForeignSubsidiaryInfo(true)}
                                    >
                                        <i
                                            className="fe fe-info ml-1"
                                            data-toggle="modal"
                                            data-target="#foreignSubsidiaryModal"
                                        >
                                        </i>
                                    </span>
                                )}
                            </td>
                            <td className="px-0 text-center">
                                {addon.quantity}
                            </td>
                            <td className="px-0 text-end">
                                <>
                                    {(addon.addon === "Additional Jurisdiction"
                                        || addon.addon === "Foreign Shareholder")
                                        && <p className="subtext subtext-bold text-grey mb-0">
                                            1 included per year
                                        </p>
                                    }
                                    {addon.price !== addon.discountPrice
                                        && <NumericFormat
                                            className="price-text-container subtext subtext-bold text-grey
                                            strikethrough"
                                            value={`${addon.price} (per)`}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            prefix={"$"}
                                        />
                                    }
                                <NumericFormat
                                    className="price-text-container price-text"
                                    value={`${addon.discountPrice} (per)`}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={"$"}
                                />
                            </>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <StaticContentModal
                show={showForeignSubsidiaryInfoModal}
                handleClose={() => setShowForeignSubsidiaryInfo(false)}
                content={FOREIGN_SUBSIDIARY_INFO_MODAL}
                header="Foreign Subsidiary Add-On includes:"
                className="modal-lg"
            />
        </>
  );
};

export default AddonsTable;
