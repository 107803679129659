import PayrollManualConnectionForm from "./PayrollManualConnectionForm";
import FondoBaseLayout from "../../fusion-kit/FondoBaseLayout";

const PayrollManualConnection = () => (
  <FondoBaseLayout >
    <div className="row justify-content-center">
      <div className="col-12 col-lg-10 col-xl-8 mx-3">
        <h2>TaxPlan - 2023</h2>
        <p className="text-muted">
          Please tell us about your company in order to activate your 2023 Fondo TaxPlan.
        </p>
        <PayrollManualConnectionForm />
      </div>
    </div>
  </FondoBaseLayout>
);

export default PayrollManualConnection;
