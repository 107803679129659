import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { NumericFormat } from "react-number-format";
import {
  AddonData, AddonDetailType, AddonType, ProductApiType, ProductCardType, ProductData,
} from "./product.type";
import { getPricing, saveProduct } from "./product.api";
import ProductCard, { renderPrice } from "./ProductCard";
import "../onboarding.css";
import { deepCopy } from "../../../utils/common.util";
import { PRODUCT_CATEGORIES } from "../../../constants/general";
import StaticContentModal from "../../modals/StaticContentModal";
import { PAYMENT_TERMS_MODAL_CONTENT } from "../../../constants/static-content";
import StripeWrapper from "../../stripe/StripeWrapper";
import AddonsTable from "./AddonsTable";
import { getFinalAddonsQuantityAndDetails, needsProduct } from "./utils";

export default function Product() {
  const navigate = useNavigate();
  const productMutation = useMutation<any, AxiosError<any>, ProductData, AddonData>((data) => saveProduct(data));
  const pricingQuery = useQuery<ProductApiType, Error>(
    ["pricing"],
    getPricing,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.context) {
          setContext(deepCopy(data.context));
        }
        const productCardList: ProductCardType[] = [];
        if (data.products) {
          data.products.map((p) => productCardList.push({
            product: p,
            checked: p.checked ? p.checked : false,
          }));
          const tempProductCards = deepCopy(productCardList);
          disableFreeQuarterlyBookkeepingIfNoBookkeepingSelected(tempProductCards);
          uncheckDelawareIfCorpTaxSelected(tempProductCards);
          handleFreeProducts(tempProductCards, data.context);
          removeOnboardingFeeIfAmountLessThan250(tempProductCards);
          setProductCards(tempProductCards);
        }
        let addonData: AddonData[] = [];
        if (data?.addons?.length) {
          const addonDetails: AddonDetailType[] = getAddonDetails(productCardList, data.addons);
          setAddons(addonDetails || []);
          addonData = addonDetails.map((addon: AddonDetailType) => ({
            id: addon.id,
            quantity: addon.quantity,
          }));
        }
        // Add products that were selected during the onboarding
        addProducts(productCardList, addonData);
      },
    },
  );
  const [productCards, setProductCards] = useState<ProductCardType[]>([]);
  const [context, setContext] = useState<{ [key: string]: any }>({});
  const [showPaymentTermsModal, setShowPaymentTermsModal] = useState(false);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const minAmountDue = 250.0;
  const [addons, setAddons] = useState<AddonDetailType[]>([]);

  const selectedProductCards = () => productCards.filter((p) => p.checked);

  const determineTotal = () => {
    const totalAmountProducts = productCards.filter((p) => p.checked)
      .reduce((total, productCard) => {
        let price = Number(productCard.product.discountPrice || productCard.product.price);
        if (!price) price = 0;
        return total + price;
      }, 0);
    const totalAmountAddons = addons.reduce((a, p) => a + +(p.total || 0), 0);
    return totalAmountProducts + totalAmountAddons;
  };

  const getAddonDetails = (products: ProductCardType[], addonsDetails: AddonType[]) => {
    const selectedProducts = products.filter((p) => p.checked);
    const needsTaxPassCurrentYear = needsProduct(selectedProducts, "tax_membership_current_year");
    const needsTaxPassPrevYear = needsProduct(selectedProducts, "tax_membership_previous_year");
    const needsTaxPassNextYear = needsProduct(selectedProducts, "tax_membership_next_year");
    return getFinalAddonsQuantityAndDetails(
      deepCopy(addonsDetails),
      needsTaxPassCurrentYear,
      needsTaxPassPrevYear,
      needsTaxPassNextYear,
    );
  };

  function addProducts(products: ProductCardType[], addonData: AddonData[]) {
    const checkedProducts = products.filter((p) => p.checked);
    const productData = checkedProducts.map((p) => p.product);
    productMutation.mutate({ productData, addonData }, {
      onError: (err) => {
        const errors = err?.response?.data;
        if (errors) {
          console.error(errors);
        }
      },
    });
  }

  function renderProductsList() {
    if (productCards) {
      return (
                <>
                    {productCards
                      .filter((p) => !p.product.category)
                      .map((p) => (
                            <div key={p.product.id}>
                                <ProductCard productCard={p} handleProductChecked={handleProductChecked}></ProductCard>
                            </div>
                      ))}
                    {PRODUCT_CATEGORIES.map((productCategory) => (
                        <div key={productCategory}>
                            {productCards.some((p) => p.product.category === productCategory) && (
                                <>
                                    <span className="price-text-container price-text mt-3">{productCategory}</span>
                                    {productCategory === "Tax"
                                      && context && context.isDelawareFranchiseTaxProductEnabled && (
                                        <span className="subtext text-green">
                                            Please Note:
                                            <strong>Delaware Franchise Tax - 2023 is FREE and included</strong>
                                            {" "}when you purchase{" "}
                                            <strong>Tax Pass (Annual) - 2023</strong>
                                        </span>
                                    )}
                                </>
                            )}
                            {productCards
                              .filter((p) => p.product.category === productCategory)
                              .map((p) => (
                                    <div key={p.product.id}>
                                        <ProductCard
                                          productCard={p}
                                          handleProductChecked={handleProductChecked}
                                        />
                                    </div>
                              ))}
                        </div>
                    ))}
                </>
      );
    }
  }

  function renderSelectedProductsHeader() {
    return (
            <>
                <div className="row border-bottom-grey align-items-center mx-2 py-2 my-2">
                    <div className="col-12 col-md-8 py-3 px-0">
                        <h3 className="mb-1">Estimate #478237</h3>
                        <span className="subtext subtext-bold text-grey">
                            {new Date().toLocaleString("en-US", { year: "numeric", month: "long", day: "numeric" })}
                        </span>
                    </div>
                </div>
                <div className="row align-items-center mx-2 my-2 pt-2 pb-3">
                    <div className="col-12 col-md-8 py-3 px-0">
                        <span className="subtext-small text-grey">TO</span>
                        <h3>{context.companyName}</h3>
                    </div>
                    <div className="col-12 col-md-4 py-2 px-0 price-container">
                        <span className="subtext-small text-grey">FROM</span>
                        <h3>Fondo</h3>
                    </div>
                </div>
                <div className="row border-bottom-grey align-items-center mx-2 py-2 my-2">
                    <div className="col-12 col-md-8 py-3 px-0">
                        <span className="subtext-small text-grey">DESCRIPTION</span>
                    </div>
                    <div className="col-12 col-md-4 py-3 px-0 price-container">
                        <span className="subtext-small text-grey">SUBTOTAL</span>
                    </div>
                </div>
            </>
    );
  }

  function renderSelectedProductsList() {
    if (selectedProductCards().length <= 1) {
      return (
                <div className="row border-bottom-grey align-items-center mx-2 py-4 my-2">
                    <i>Select products on the left to generate your quote</i>
                </div>
      );
    }
    return selectedProductCards().map((p) => (
                <div key={p.product.id} className="row border-bottom-grey align-items-center mx-2 py-2 my-2">
                    <div className="col-12 col-md-8 py-3 px-0">
                        <span className="">{p.product.relatedService.displayName}</span>
                    </div>
                    <div className="col-12 col-md-4 py-2 px-0 price-container">{renderPrice(p.product)}</div>
                </div>
    ));
  }

  function renderTotalAmount() {
    if (selectedProductCards().length <= 1) {
      return <></>;
    }
    return (
            <>
                <div className="row border-bottom-grey align-items-center mx-2 py-2 my-2">
                    <div className="col-12 col-md-8 py-3 px-0">
                        <span className="price-text">Total Estimate</span>
                    </div>
                    <div className="col-12 col-md-4 py-2 px-0 price-container">
                        <NumericFormat
                            className="price-text-container price-text"
                            value={determineTotal()}
                            displayType={"text"}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            prefix={"$"}
                        />
                    </div>
                </div>
                <div className="row align-items-center mx-2 py-2 my-2 text-green">
                    <div className="col-12 col-md-8 py-3 px-0">
                        <span className="price-text">Amount Due Today</span>
                        <span className="tooltip-container ms-2 text-success">
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="right"
                                overlay={
                                    <Tooltip>
                                        This is the amount that will be charged to your card today. Fondo will send you
                                        an invoice for your remaining balance due on or after your work begins.
                                    </Tooltip>
                                }>
                                <span>
                                    <FeatherIcon className="cursor-pointer" icon="info" size="1.1em" />
                                </span>
                            </OverlayTrigger>
                        </span>
                    </div>
                    <div className="col-12 col-md-4 py-2 px-0 price-container">
                        <NumericFormat
                            className="price-text-container price-text"
                            value={determineDepositAmount()}
                            displayType={"text"}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            prefix={"$"}
                        />
                    </div>
                </div>
            </>
    );
  }

  function handleTermsChecked(event: React.ChangeEvent<HTMLInputElement>) {
    setTermsChecked(event.target.checked);
  }

  // function handlePayWithCardClicked() {
  //   const products = selectedProductCards().map((p) => p.product);
  //   const addonData = addons.map((addon: AddonDetailType) => ({
  //     id: addon.id,
  //     quantity: addon.quantity,
  //   }));
  //   productMutation.mutate({ productData: products, addonData }, {
  //     onError: (err) => {
  //       const errors = err?.response?.data;
  //       if (errors) {
  //         console.error(errors);
  //       }
  //     },
  //     onSuccess: () => {
  //       navigate("/onboarding/onboarding-success");
  //     },
  //   });
  // }

  function renderModals() {
    return (
            <StaticContentModal
                show={showPaymentTermsModal}
                handleClose={() => setShowPaymentTermsModal(false)}
                content={PAYMENT_TERMS_MODAL_CONTENT}
                header="Fondo terms and conditions"
                className="modal-lg"
            />
    );
  }

  const determineDepositAmount = () => {
    let depositAmount = minAmountDue;
    // We want to still get a $250 deposit if they go for Tax Credits
    if (minAmountDue > determineTotal() && determineTotal() !== 0) {
      depositAmount = determineTotal();
    }
    const taxCredits = productCards.find((p) => p.product.productName === "tax_credits_current_year");
    if (taxCredits?.checked) {
      depositAmount = minAmountDue;
    }
    return depositAmount;
  };

  const determineStripeDepositAmount = () => determineDepositAmount() * 100;

  const anySelectedProducts = () => productCards.filter(
    (p) => p.checked
    && p.product.productName !== "onboarding_fee"
    && p.product.productName,
  ).length > 0;

  const TermsAndConditions = (
    <Form.Check
      type="checkbox"
      label={
          <span>
              I've read and accept{" "}
              <a
                  href=""
                  onClick={(event) => {
                    event.preventDefault();
                    setShowPaymentTermsModal(true);
                  }}>
                  Fondo terms and conditions.
              </a>
          </span>
      }
      disabled={!anySelectedProducts()}
      checked={termsChecked}
      onChange={(event) => handleTermsChecked(event)}
      id="termsandconditions"
    />
  );

  const possiblyOverlayedTermsAndConditions = () => {
    if (!anySelectedProducts()) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="termsandconditions">
              Please select a product!
            </Tooltip>
          }
        >
          <span>{TermsAndConditions}</span>
        </OverlayTrigger>
      );
    }
    return TermsAndConditions;
  };

  let addonPricingUrl = "https://docs.google.com/spreadsheets/u/1/d/e/2PACX-1vSmF6zvlvhXLvFcQxCzVCxrrWEzjfL";
  addonPricingUrl += "cQhmznZ4OyThM4jwjNHwUQz3vJdnIUj18nfFqYlCkb_UFZlqq/pubhtml?gid=1721167951&single=true";

  function renderSelectedProductsFooter() {
    return (
      <>
          <div className="row mx-2 pt-4 pb-2 my-3">
              <div className="col-12 col-md-12 py-3 px-0">
                  {possiblyOverlayedTermsAndConditions()}
              </div>
          </div>
          <div className="row mx-2 py-2 mb-2 mt-1 align-items-center">
              {/* <div className='col-12 col-md-12 py-3 px-0'>
                  <button
                    onClick={() => handlePayWithCardClicked()}
                    className="btn btn-light btn-md full-width"
                    disabled={!termsChecked}>
                      Pay with Card
                  </button>
              </div> */}
              <StripeWrapper
                  acceptedTermsAndConditions={termsChecked && anySelectedProducts()}
                  depositAmountStripe={determineStripeDepositAmount()}
                  productData={selectedProductCards().map((p) => p.product)}></StripeWrapper>
          </div>
          <div className="row text-align-center">
              <span className="">or</span>
          </div>
          <div className="row mx-2 py-2 mb-2 mt-1 align-items-center border-bottom-grey">
              <div className="col-12 col-md-12 py-3 px-0">
                  <a
                    href="https://www.tryfondo.com/overview-call"
                    target="_blank"
                    className="btn btn-light btn-md full-width">
                      {/* <FeatherIcon icon="calendar" className="me-2 mb-1" size="1.2em"></FeatherIcon> */}
                      Schedule a Call
                  </a>
              </div>
          </div>
          <div className="row mx-2 py-2 my-2">
              <div className="col-12 col-md-12 py-3 px-0">
                  <span>
                      Depending on your situation, your company may require additional add-ons -- please
                      find add-on pricing{" "}
                      <a
                        href={addonPricingUrl}
                        target="_blank">
                        here.
                      </a>
                  </span>
              </div>
          </div>
      </>
    );
  }

  function handleProductChecked(e: any, productName: string) {
    const tempProductCards = productCards.map((p) => {
      if (p.product.productName === productName) {
        p.checked = e.target.checked;
      }
      return p;
    });
    disableDelIfTaxPassCurrYearSelected(tempProductCards, productName);
    disableFreeQuarterlyBookkeepingIfNoBookkeepingSelected(tempProductCards);
    uncheckDelawareIfCorpTaxSelected(tempProductCards);
    handleFreeProducts(tempProductCards, context);
    removeOnboardingFeeIfAmountLessThan250(tempProductCards);
    setProductCards(tempProductCards);
    let addonData: AddonData[] = [];
    if (pricingQuery?.data?.addons.length) {
      const addonDetails: AddonDetailType[] = getAddonDetails(tempProductCards, pricingQuery.data.addons);
      addonData = addonDetails.map((addon: AddonDetailType) => ({
        id: addon.id,
        quantity: addon.quantity,
      }));
      setAddons(addonDetails || []);
    }
    addProducts(tempProductCards, addonData);
  }

  function disableDelIfTaxPassCurrYearSelected(updatedProductCards: ProductCardType[], productName: string) {
    if (productName === "tax_membership_current_year") {
      const taxPassCurrYear = updatedProductCards.find((
        p,
      ) => p.product.productName === productName);
      const delFranchiseTax = updatedProductCards.find((
        p,
      ) => p.product.productName === "delaware_franchise_tax_current_year");
      if (taxPassCurrYear?.checked && context.isDelawareFranchiseTaxProductEnabled) {
        if (delFranchiseTax) {
          delFranchiseTax.checked = false;
          delFranchiseTax.product.checked = false;
          delFranchiseTax.product.disabled = true;
        }
      } else if (delFranchiseTax) {
        delFranchiseTax.product.disabled = false;
      }
    }
  }

  function disableFreeQuarterlyBookkeepingIfNoBookkeepingSelected(updatedProductCards: ProductCardType[]) {
    const monthlyBK = updatedProductCards.find((p) => p.product.productName === "monthly_bookkeeping");
    const quarterlyBK = updatedProductCards.find((p) => p.product.productName === "quarterly_bookkeeping");
    const freeBK = updatedProductCards.find((p) => p.product.productName === "free_2023_q1_q2_bookkeeping");
    if (!monthlyBK?.checked && !quarterlyBK?.checked && freeBK) {
      freeBK.checked = false;
      freeBK.product.checked = false;
      freeBK.product.disabled = true;
    } else if (freeBK) {
      freeBK.product.disabled = false;
    }
  }

  function removeOnboardingFeeIfAmountLessThan250(updatedProductCards: ProductCardType[]) {
    const onboardingFee = updatedProductCards.find((p) => p.product.productName === "onboarding_fee");
    const totalAmountProducts = updatedProductCards.filter(
      (p) => p.checked && p.product.productName !== "onboarding_fee",
    ).reduce((a, p) => a + +(p.product.discountPrice || 0), 0);
    const totalAmountAddons = addons.reduce((a, p) => a + +(p.total || 0), 0);
    const totalAmount = totalAmountProducts + totalAmountAddons;
    if (totalAmount < 250 && onboardingFee?.checked) {
      onboardingFee.checked = false;
      onboardingFee.product.checked = false;
      onboardingFee.product.disabled = true;
    } else if (totalAmount >= 250 && onboardingFee) {
      onboardingFee.checked = true;
      onboardingFee.product.checked = true;
      onboardingFee.product.disabled = false;
    }
  }

  function uncheckDelawareIfCorpTaxSelected(updatedProductCards: ProductCardType[]) {
    const taxPass = updatedProductCards.find((p) => p.product.productName === "tax_membership_current_year");
    const deOneDollarDeal = updatedProductCards.find(
      (p) => p.product.productName === "delaware_franchise_tax_current_year",
    );
    if (taxPass?.checked && deOneDollarDeal) {
      deOneDollarDeal.checked = false;
      deOneDollarDeal.product.checked = false;
      deOneDollarDeal.product.disabled = true;
    }
  }

  const markAsFreeIfNeeded = (updatedProductCards: ProductCardType[], freeProductName: string): void => {
    const freeProduct = updatedProductCards.find((p) => p.product.productName === freeProductName);
    if (!freeProduct) return;
    const selectedCount = updatedProductCards.filter((p) => p.checked
      && p.product.productName !== freeProductName).length;
    if (selectedCount > 2) {
      freeProduct.product.discountPrice = "0.00";
    } else {
      freeProduct.product.discountPrice = freeProduct.product.originalPrice;
    }
  };

  const handleFreeProducts = (
    updatedProductCards: ProductCardType[],
    productsContext?: { [key: string]: any },
  ): void => {
    if (!productsContext) {
      productsContext = {};
    }
    if (productsContext.free2022YearlyBookkeepingEnabled) {
      // markAsFreeIfNeeded(updatedProductCards, "catchup_bookkeeping_previous_year");
    } else if (productsContext.free2023YearlyBookkeepingEnabled) {
      markAsFreeIfNeeded(updatedProductCards, "catchup_bookkeeping_current_year");
    }
  };

  return (
        <div>
            <div className="row justify-content-center">
                <div className="col-12 col-lg-10 col-xl-8 card">
                    <div className="row">
                        <div className="col-12 col-md-6 col-sm-12 container-bg px-5 py-4">
                            <div className="row text-align-center">
                                <span className="subtext subtext-bold text-grey py-2" style={{ fontSize: "11px" }}>
                                    PRODUCTS AND PAYMENT
                                </span>
                                <h2 className="my-3">Choose your products</h2>
                                {(selectedProductCards().length === 0) && (
                                    <span className="subtext py-2">Select products below to generate your quote</span>
                                )}
                                {selectedProductCards().length > 0 && minAmountDue < determineTotal() && (
                                    <span className="subtext text-green py-2">
                                        Pay just a ${minAmountDue} deposit now. We will invoice you for the balance.
                                    </span>
                                )}
                            </div>
                            <div className="row my-2">{renderProductsList()}</div>
                            <div>
                                <a
                                    href=""
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate("/onboarding/tax-plan");
                                    }}>
                                    <FeatherIcon className="mb-1" icon="chevron-left" size="15" />
                                    Review your information
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-sm-12 px-5 py-4 bg-white">
                            {renderSelectedProductsHeader()}
                            {renderSelectedProductsList()}
                            {addons?.length > 0 && <AddonsTable addons={addons}/>}
                            {renderTotalAmount()}
                            {renderSelectedProductsFooter()}
                        </div>
                    </div>
                </div>
            </div>
            {renderModals()}
        </div>
  );
}
