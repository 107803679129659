import {
  Button,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Field, FieldArray, FormikContextType } from "formik";
import { Director } from "../DelawareFranchiseTax.type";

type BoardOfDirectorsSectionProps = {
  formikContext: FormikContextType<any>;
}

const BoardOfDirectorsSection = ({ formikContext }: BoardOfDirectorsSectionProps) => (
  <>
    <FieldArray name="boardOfDirectors">
      {({ push, remove }) => (
        <>
          <div className="mt-5 d-flex justify-content-between">
            <h2 className="m-0">Board of Directors</h2>
            <Button
              onClick={() => push({ firstName: "", lastName: "" })}
              size="sm"
            >
              <FeatherIcon
                className="mt-n1 me-1"
                icon="plus"
                size="14"
              />
              Add Director
            </Button>
          </div>
          <hr />
          <p className="text-muted">
            <FeatherIcon
              className="mt-n1 me-1"
              icon="info"
              size="16"
            />
            If signing officer is also on Board of Directors, please add their name below.
          </p>

          {formikContext.values.boardOfDirectors.map((director: Director, idx: number) => (
            <>
              {!director.delete && (
              <Row className="mb-4">
                <Col className="mr-2">
                  <Form.Label>First name</Form.Label>
                  <Field
                    className="form-control"
                    name={`boardOfDirectors[${idx}].firstName`}
                  />
                </Col>
                <Col className="mr-3">
                  <Form.Label>Last name</Form.Label>
                  <Field
                    className="form-control"
                    name={`boardOfDirectors[${idx}].lastName`}
                  />
                </Col>
                <Col sm={"auto"} style={{ cursor: "pointer" }}>
                  <FeatherIcon
                    icon="trash"
                    size="16"
                    style={{ position: "relative", top: 40 }}
                    onClick={() => {
                      if (director.uuid) {
                        formikContext.setFieldValue(`boardOfDirectors[${idx}].delete`, true);
                      } else {
                        remove(idx);
                      }
                    }}
                  />
                </Col>
              </Row>
              )}
            </>
          ))}
        </>
      )}
    </FieldArray>
  </>
);

export default BoardOfDirectorsSection;
