// Mutations
import { LOGIN } from "../../constants/network-calls";
import { LoginInput } from "./login.type";
import { postFondoApi } from "../../utils/util.api";

export async function login(loginInput: LoginInput) {
  const formData = new FormData();
  formData.set("username", loginInput.email);
  formData.set("password", loginInput.password);
  /*
  FormData hides it's properties, so when humps attempts to decamelize the keys
  it doesn't find anything, which makes it override the formData with `undefined` and
  it sends that to our API, giving us an error. For that reason, we need to have the
  { ignoreDecamelize: true } so it doesn't convert the formData to undefined.
  */
  const result = await postFondoApi(LOGIN, formData, {});
  return result;
}
