import { Card, Spinner } from "react-bootstrap";
import CircleReferenceColor from "../../../fondo-components/CircleReferenceColor";

const incomeReferenceColor = "#2C7AE5";
const expensesReferenceColor = "#D5E5FA";

const LoadingProfitAndLossChartCard = () => (
  <Card>
    <Card.Body className="p-0">
      <div className="border-bottom p-4 pb-3 d-flex justify-content-between">
        <Card.Title className="text-uppercase text-muted">
          <span className="h5 text-secondary">Profit & Loss</span>
        </Card.Title>
        <div className="m-0 d-flex align-items-start">
          <CircleReferenceColor color={incomeReferenceColor} text="Income" />
          <CircleReferenceColor color={expensesReferenceColor} text="Expenses" />
        </div>
      </div>
      <div className="p-4">
        <Spinner variant="primary" animation="border" role="status" />
      </div>
    </Card.Body>
  </Card>
);

export default LoadingProfitAndLossChartCard;
