import React from "react";

interface ProgressTimeLineContextType {
    currentStep: string;
    setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
}

export const ProgressTimeLineContext = React.createContext<ProgressTimeLineContextType>({
  currentStep: "",
  setCurrentStep: () => undefined,
});
