import {
  ADD_PRODUCTS_TO_ORDER, GET_ALL_ORDERS, GET_AVAILABLE_PRODUCTS, REMOVE_LINE_ITEM_FROM_ORDER, VERIFY_ORDER,
} from "../../constants/network-calls";
import {
  AllOrdersAPIData, OrderAPIData, ProductPricingAPIData, ProductPricingCategory, SelectedProductProps,
} from "../onboarding/product/product.type";
import { ProductType, mapProductTypeToIndexOrder } from "../onboarding/checkout/products.type";
import { placeParams } from "../../utils/common.util";
import { getFondoApi, postFondoApi } from "../../utils/util.api";

export const getProductOrders = async () => {
  const result = await getFondoApi(GET_ALL_ORDERS);
  const data = result as OrderAPIData[];
  const openOrder = data.find((order) => !order.locked);
  const closedOrders = data.filter((order) => order.locked);
  const allOrders: AllOrdersAPIData = {
    openOrder,
    closedOrders,
  };
  return allOrders;
};

export const getAvailableProducts = async (checkout = false) => {
  const result = await getFondoApi(GET_AVAILABLE_PRODUCTS, { checkout });
  const data = result as ProductPricingAPIData[];
  const productsByCategory: ProductPricingCategory[] = [];
  data.forEach((product) => {
    // Find if a category already exists for this product
    const category = productsByCategory.find((productCategory) => productCategory.category === product.type);
    // Don't add the product if the price or discount price is -1, should not be displayed
    if (product.price === -1 || product.discountPrice === -1) {
      return;
    }
    // Either add the category or append it to an existing one
    if (category) {
      category.products.push(product);
    } else {
      productsByCategory.push({ category: product.type as ProductType, products: [product] });
    }
  });
  // Forces a specific order to be shown on the frontend based on the mapProductTypeToIndexOrder
  return productsByCategory.sort((a, b) => (
    mapProductTypeToIndexOrder[a.category] - mapProductTypeToIndexOrder[b.category]
  ));
};

export const addProductToOrder = async (data: SelectedProductProps[]) => {
  const result = await postFondoApi(ADD_PRODUCTS_TO_ORDER, { products: data });
  return result;
};

export const removeLineItemFromOrder = async (lineItemUuid: string) => {
  const result = await postFondoApi(REMOVE_LINE_ITEM_FROM_ORDER, {}, { lineItemUuid });
  return result;
};

export const markOrderAsVerified = async (orderUuid: string) => {
  const result = await postFondoApi(placeParams(VERIFY_ORDER, { uuid: orderUuid }), {});
  return result;
};
