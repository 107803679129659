import { useFormikContext } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button, Col, Modal, Nav,
} from "react-bootstrap";
import { useState } from "react";
import RndEligibilityCalculations from "./eligibility/RnDEligibility";
import ConfirmationModal from "../../fusion-kit/ConfirmationModal";
import { getRnDForm, updateRnDForm } from "./rndForm.api";
import {
  RnDFormValues,
  fieldsToCheck,
  relatedFieldsToCheck,
  wageFieldsToCheck,
  contractorFieldsToCheck,
  supplyFieldsToCheck,
  RnDValues,
} from "./baseFormData";
import SuccessfulSubmissionImg from "../../imgs/rnd-submission-successful.png";
import VideoExplanationButton, { HelpVideoType } from "../../fondo-components/VideoExplanationButton";

type RnDFormHeaderProps = {
  currentPage: number,
  setEligibilityStatus: (eligible: boolean) => void,
  setShown: React.Dispatch<React.SetStateAction<boolean>>,
  setShowErrorToast: React.Dispatch<React.SetStateAction<boolean>>,
}

const RnDFormHeader = ({
  currentPage,
  setEligibilityStatus,
  setShown,
  setShowErrorToast,
}: RnDFormHeaderProps) => {
  const formikContext = useFormikContext<RnDFormValues>();
  const formQuery = useQuery(["GetRnDForm", 2023], () => getRnDForm(2023), {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const formMutation = useMutation(
    (values: RnDFormValues) => updateRnDForm(values),
    {
      onSuccess: (data: RnDValues) => {
        if (data.submitted && !formikContext.values.submitted) {
          setShowSubmittedModal(true);
        } else {
          setShowSavedProgressModal(true);
        }
        formikContext.resetForm({
          values: {
            ...data,
            wagesToDelete: [],
            contractorsToDelete: [],
            suppliesToDelete: [],
          },
        });
        setSubmitting(false);
      },
      onError: () => {
        setShowErrorToast(true)
        setSubmitting(false);
      }
    },
  );

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSavedProgressModal, setShowSavedProgressModal] = useState(false);
  const [showSubmittedModal, setShowSubmittedModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  if (!formQuery.data) {
    return <></>;
  }

  const handleSaveProgress = () => {
    setSubmitting(true);
    formMutation.mutate(formikContext.values);
  };

  const handleConfirmedSubmit = () => {
    formMutation.mutate({ ...formikContext.values, submitted: true });
    setShowConfirmationModal(false);
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const hasEmptyField = emptyField(formikContext.values);
    if (hasEmptyField && !formikContext.values.submitted) {
      setShowConfirmationModal(true);
    } else {
      formMutation.mutate({ ...formikContext.values, submitted: true });
    }
  };

  const emptyField = (values: any): boolean => {
    if (fieldsToCheck.some((field) => (
      values[field] === undefined || values[field] === "" || values[field] === null
    ))) return true;
    if (Object.entries(relatedFieldsToCheck).some(([key, value]) => (
      values[key] === "True" && !values[value]
    ))) return true;
    if (emptyFieldInArray(values.rndRelatedEmployeeWages, wageFieldsToCheck)) return true;
    if (emptyFieldInArray(values.rndRelatedContractorWages, contractorFieldsToCheck)) return true;
    if (emptyFieldInArray(values.rndRelatedSupplies, supplyFieldsToCheck)) return true;
    return false;
  };

  const emptyFieldInArray = (
    arrayOfValues: any[],
    fields: string[],
  ): boolean => (
    arrayOfValues.some((value) => (
      fields.some((field) => (
        !value[field]
      ))
    ))
  );

  return (
    <Nav variant='tabs' className='mb-5 nav-tabs-sm justify-content-between'>
      <Col>
        <h6 className='header-pretitle'>R&D Form: Page {currentPage} of 3</h6>
        <h1>
          2023 Research and Development Eligibility
          <VideoExplanationButton videoType={HelpVideoType.R_AND_D} />
        </h1>
        <div className='mb-2 d-flex justify-content-end'>
          <Button
            variant="outline-primary"
            className="me-3"
            onClick={handleSubmit}
            disabled={submitting}
          >Submit</Button>
          <Button onClick={handleSaveProgress} disabled={submitting}>Save Progress</Button>
        </div>
        <h5 style={{ color: "#7283A0" }}>Keep going to claim your estimated R&D payroll credit for 2023</h5>
        <RndEligibilityCalculations setEligibilityStatus={setEligibilityStatus} setShown={setShown} />
        <h5 style={{ color: "#A1B0CA" }} className="mb-5">
          Try to give your best answers, but don't worry -- our team will review everything before anything is filed
        </h5>
      </Col>
      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={() => { setSubmitting(false); setShowConfirmationModal(false); }}
        handleConfirm={handleConfirmedSubmit}
      />
      <Modal
        show={showSavedProgressModal}
        onHide={() => setShowSavedProgressModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Successfully Saved</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button onClick={() => setShowSavedProgressModal(false)}>Ok</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showSubmittedModal}
        onHide={() => setShowSubmittedModal(false)}
      >
        <Modal.Body style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <img
            src={SuccessfulSubmissionImg}
            className="img-fluid"
            alt="Submission Successful"
            style={{ width: "50%" }}
          />
          <h3 className="mt-4 mb-3 text-center">Thank you!</h3>
          <h5 className="text-center text-secondary">
            If we need anything else, we will reach out.
          </h5>
          <Button className="mt-3 mb-3" size="sm" onClick={() => setShowSubmittedModal(false)}>Close</Button>
        </Modal.Body>
      </Modal>
    </Nav>
  );
};

export default RnDFormHeader;
