import {
  GET_ACTION_ITEMS,
  GET_ACTION_ITEMS_GENERAL_INFO,
  GET_ACTION_ITEMS_SERVICE,
  GET_ACTION_ITEMS_USER_SERVICES,
  GET_ACTION_ITEM_SELECTED_INSTITUTIONS,
  GET_EXTERNAL_CONNECTIONS_INSTITUTIONS,
} from "../../constants/network-calls";
import {
  ActionItem,
  ActionItemsData,
  ActionItemsUserServicesData,
} from "./actionItems.type";
import {
  deleteFondoApi, getFondoApi, patchFondoApi, postFondoApi,
} from "../../utils/util.api";

export async function getActionItemsGeneralInfo(): Promise<ActionItemsData> {
  const result = await getFondoApi(GET_ACTION_ITEMS_GENERAL_INFO);
  return result as ActionItemsData;
}

export async function getActionItems(): Promise<ActionItem[]> {
  const result = await getFondoApi(GET_ACTION_ITEMS);
  return result as ActionItem[];
}

export async function getActionItemsUserServices(): Promise<ActionItemsUserServicesData> {
  const result = await getFondoApi(GET_ACTION_ITEMS_USER_SERVICES);
  return result as ActionItemsUserServicesData;
}

export async function getActionItemsFromService(serviceId: string): Promise<any> {
  const result = await getFondoApi(GET_ACTION_ITEMS_SERVICE + serviceId);
  return result;
}

export async function markActionItemCompleted(actionItemId: string): Promise<any> {
  const result = await patchFondoApi(`${GET_ACTION_ITEMS_SERVICE + actionItemId}/mark_action_item_complete/`, {});
  return result;
}

export async function markActionItemIncomplete(actionItemId: string): Promise<any> {
  const result = await patchFondoApi(`${GET_ACTION_ITEMS_SERVICE + actionItemId}/mark_action_item_incomplete/`, {});
  return result;
}

export async function getActionItemSelectedInstitutions(actionItemId: number):Promise<any> {
  const result = await getFondoApi(GET_ACTION_ITEM_SELECTED_INSTITUTIONS + actionItemId);
  return result;
}

export async function addSelectedInstitution(actionItemId: number, selectedInstitutionUuid: string): Promise<any> {
  const result = await postFondoApi(
    `${GET_ACTION_ITEM_SELECTED_INSTITUTIONS + actionItemId}/add_selected_institution/`,
    { selectedInstitutionUuid },
  );
  return result;
}

export async function removeFromSelectedInstitutions(selectedInstitutionId: string): Promise<any> {
  const result = await deleteFondoApi(GET_ACTION_ITEM_SELECTED_INSTITUTIONS + selectedInstitutionId);
  return result;
}

export async function getInstitutions(connectionTypeId: number): Promise<any> {
  const result = await getFondoApi(GET_EXTERNAL_CONNECTIONS_INSTITUTIONS, { connectionTypeId });
  return result;
}
