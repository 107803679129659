import { Button } from "react-bootstrap";
import { TransactionCategory } from "../financeDashboard.type";
import CategoryButton from "./CategoryButton";

type MultipleTransactionCategorySelectorProps = {
  selectedCategory: TransactionCategory | undefined;
  handleCancel: () => void;
  setSelectedCategory: (categoty: TransactionCategory | undefined) => void;
  handleMassUpdateCategory: () => void;
  handleCategorizeUsingAI: () => void;
  allowUseOfAIToCategorizeTransactions: boolean;
};

const MultipleTransactionCategorySelector = ({
  handleCancel,
  setSelectedCategory,
  selectedCategory,
  handleMassUpdateCategory,
  handleCategorizeUsingAI,
  allowUseOfAIToCategorizeTransactions,
}: MultipleTransactionCategorySelectorProps) => (
  <>
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <b className="me-3">Set Type: </b>
        <CategoryButton
          category={TransactionCategory.INVESTMENT}
          setSelectedCategory={setSelectedCategory}
          selected={selectedCategory === TransactionCategory.INVESTMENT}
        />
        <CategoryButton
          category={TransactionCategory.REVENUE}
          setSelectedCategory={setSelectedCategory}
          selected={selectedCategory === TransactionCategory.REVENUE}
        />
        <CategoryButton
          category={TransactionCategory.TRANSFER}
          setSelectedCategory={setSelectedCategory}
          selected={selectedCategory === TransactionCategory.TRANSFER}
        />
        <CategoryButton
          category={TransactionCategory.OTHER}
          setSelectedCategory={setSelectedCategory}
          selected={selectedCategory === TransactionCategory.OTHER}
        />
        <CategoryButton
          category={TransactionCategory.VERIFY}
          setSelectedCategory={setSelectedCategory}
          selected={selectedCategory === TransactionCategory.VERIFY}
        />
      </div>
    </div>
    <div>
      <Button
        onClick={handleMassUpdateCategory}
        className="me-3 btn-sm"
        style={{ width: "11em" }}
        disabled={!selectedCategory}
      >
        Set selected Type
      </Button>
      {allowUseOfAIToCategorizeTransactions && (
        <Button onClick={handleCategorizeUsingAI} className="me-3 btn-sm" style={{ width: "11em" }} variant="success">
          Categorize using AI
        </Button>
      )}
      <Button variant="btn btn-outline-secondary btn-sm" onClick={handleCancel} style={{ width: "8em" }}>
        Cancel
      </Button>
    </div>
  </>
);

export default MultipleTransactionCategorySelector;
