import { useQuery } from "@tanstack/react-query";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import { FieldArray, Formik, FormikProps } from "formik";
import { getCorporateTaxContext } from "../corporateTax.api";
import fondoLogo from "../../../assets/img/fondo-icon.png";
import Address from "../Address";
import AdditionalInfo from "../AdditionalInfo";
import ResearchExpenses from "../ResearchExpenses";
import USSubsidiaries from "../USSubsidiaries";
import ForeignBankAccounts from "../ForeignBankAccounts";
import MajorityShareholders from "../MajorityShareholders";
import CorporateTaxHeader from "../CorporateTaxHeader";
import Post2023Incorporation from "./Post2023Incorporation";
import Post2023CompanyInfo from "./Post2023CompanyInfo";
import {
  Post2023CorpTaxFormFields,
  Post2023CorporateTaxStep,
  getPost2023CorporateTaxSchema,
} from "./post2023CorporateTax.type";
import Officers from "../Officers";

type Post2023CorporateTaxProps = {
  taxYear: string;
}

export default function Post2023CorporateTax({ taxYear }: Post2023CorporateTaxProps) {
  const corptaxContextQuery = useQuery(
    ["corptaxContext", taxYear],
    () => getCorporateTaxContext(taxYear),
    {
      refetchOnWindowFocus: false,
    },
  );

  const [isValidating, setIsValidating] = useState(false);

  const hasError = (formik: any, stepName: string): boolean => (
    formik.errors && formik.errors?.[stepName]
  );

  const formSteps: Post2023CorporateTaxStep[] = [
    {
      name: "incorporation",
      displayLabel: "Incorporation",
      icon: "",
      enabled: true,
    },
    {
      name: "address",
      displayLabel: "Address",
      icon: "",
      enabled: true,
    },
    {
      name: "officers",
      displayLabel: "Officers",
      icon: "",
      enabled: true,
    },
    {
      name: "companyInfo",
      displayLabel: "Company Info",
      icon: "",
      enabled: true,
    },
    {
      name: "researchExpenses",
      displayLabel: "Research and Experimental Expenses",
      icon: "",
      enabled: !!(taxYear && Number(taxYear) > 2021),
    },
    {
      name: "majorityShareholders",
      displayLabel: "Majority Shareholders",
      icon: "",
      enabled: true,
    },
    {
      name: "usSubs",
      displayLabel: "U.S. Subsidiaries",
      icon: "",
      enabled: (formik: FormikProps<Post2023CorpTaxFormFields>) => formik.values.companyInfo.hasSubsidiaries === "True",
    },
    {
      name: "foreignBanks",
      displayLabel: "Foreign Bank Accounts",
      icon: "",
      enabled: (formik: FormikProps<Post2023CorpTaxFormFields>) => (
        formik.values.companyInfo.hasForeignAccounts === "True"
      ),
    },
    {
      name: "additionalInfo",
      displayLabel: "Additional Info",
      icon: "fe-message-circle",
      enabled: true,
    },
  ];
  const [selectedStep, setSelectedStep] = useState<Post2023CorporateTaxStep>(formSteps[0]);

  function navigatePage(formik: FormikProps<Post2023CorpTaxFormFields>, direction: string) {
    let pageCounter = -1;
    let currentPageIndex = 0;
    const pages = formSteps.filter((step) => {
      if (typeof step.enabled === "function") {
        if (step.enabled(formik)) {
          pageCounter += 1;
          if (step.name === selectedStep.name) {
            currentPageIndex = pageCounter;
          }
          return step.enabled(formik);
        }
      } else if (step.enabled) {
        pageCounter += 1;
        if (step.name === selectedStep.name) {
          currentPageIndex = pageCounter;
        }
        return step.enabled;
      }
      return false;
    });

    if (currentPageIndex < pages.length - 1 && direction === "forward") {
      setSelectedStep(pages[currentPageIndex + 1]);
    } else if (currentPageIndex > 0 && direction === "back") {
      setSelectedStep(pages[currentPageIndex - 1]);
    }
  }

  function renderSidenavItems(formik: FormikProps<Post2023CorpTaxFormFields>) {
    return (<>
      <ul className="navbar-nav mx-auto mt-3" id="fondo_progress_sidebar">
        {formSteps.filter((step) => {
          if (typeof step.enabled === "function") {
            return step.enabled(formik);
          }
          return step.enabled;
        }).map((step) => (
          <li className="nav-item mb-4" key={step.name}>
            <a id={step.name} className={`nav-link ${step.name === selectedStep.name ? "active" : ""}`}
              data-toggle="tab" href="#{{ form_key }}"
              onClick={(event) => {
                event.preventDefault();
                step.taxYear = taxYear;
                setSelectedStep(step);
              }}>
              {hasError(formik, step.name) && (
                <i
                  className="fe fe-alert-circle text-danger ms-2"
                  style={{ marginBottom: "-0.1em" }}
                />
              )}
              {!hasError(formik, step.name) && isValidating && (
                <i
                  className="fe text-success ms-2 fe-check-circle"
                  style={{ marginBottom: "-0.1em" }}
                />
              )}
              {!hasError(formik, step.name) && !isValidating && (
                <i
                  className={`fe text-muted ms-2 ${step.icon || "fe-circle"}`}
                  style={{ marginBottom: "-0.1em" }}
                />
              )}
              {step.displayLabel}
            </a>
          </li>
        ))}
      </ul>
    </>);
  }

  function renderSidenav(formik: FormikProps<Post2023CorpTaxFormFields>) {
    return (
      <nav
        className="page-sidebar navbar navbar-vertical fixed-left navbar-expand-md navbar-light d-none d-md-block"
        id="sidebar_container"
      >
        <div className="container-fluid">
          <a
            className="navbar-brand"
            href="/dashboard"
          >
            <img src={fondoLogo} alt="Fondo" className="navbar-brand-img mx-auto" />
          </a>
          {corptaxContextQuery.isFetching ? <Spinner className="text-primary"></Spinner>
            : <>
              <div style={{ textAlign: "center" }} className="mb-3">
                {corptaxContextQuery?.data.context.companyLegalName || corptaxContextQuery?.data.context.companyName}
              </div>
              <div className="collapse navbar-collapse mt-5" id="sidebarCollapse">
                {renderSidenavItems(formik)}
              </div>
            </>
          }
        </div>
      </nav>
    );
  }

  function renderSelectedForm() {
    return (<>
      {selectedStep.name === "incorporation" && <Post2023Incorporation />}
      {selectedStep.name === "address" && <Address />}
      {selectedStep.name === "officers"
        && <FieldArray
          name="officers"
          render={(arrayHelpers) => (
            <Officers stepInfo={selectedStep} arrayHelpers={arrayHelpers} />
          )}
        />
      }
      {selectedStep.name === "companyInfo" && <Post2023CompanyInfo />}
      {selectedStep.name === "researchExpenses" && <ResearchExpenses stepInfo={selectedStep} />}
      {selectedStep.name === "majorityShareholders" && (
        <FieldArray
          name="majorityShareholders"
          render={(arrayHelpers) => (
            <MajorityShareholders stepInfo={selectedStep} arrayHelpers={arrayHelpers} />
          )}
        />
      )}
      {selectedStep.name === "usSubs" && <FieldArray name="usSubs" render={(arrayHelpers) => (
        <USSubsidiaries stepInfo={selectedStep} arrayHelpers={arrayHelpers} />
      )} />}
      {selectedStep.name === "foreignBanks" && <FieldArray name="foreignBanks" render={(arrayHelpers) => (
        <ForeignBankAccounts stepInfo={selectedStep} arrayHelpers={arrayHelpers} />
      )} />}
      {selectedStep.name === "additionalInfo" && <AdditionalInfo />}
    </>);
  }

  function renderFormComponents(formik: FormikProps<Post2023CorpTaxFormFields>) {
    return (<>
      <div className="main-content" style={{ marginLeft: "250px" }}>
        <div className="container-fluid">
          <CorporateTaxHeader taxYear={taxYear} setIsValidating={setIsValidating} isValidating={isValidating} />

          <form name="tax-onboarding" id="tax-onboarding" action="" method="post" >
            <input type="hidden" name='active_tab' value='{{ active }}'></input>
            <input type='hidden' name='final_submit' value=''></input>

            <div className="tab-content">
              {/* <div id="incorporation" className="tab-pane fade show" role="tabpanel"> */}
              <div id="incorporation" className="fade show" role="tabpanel">
                <div className="card">
                  <div className="card-body">
                    {renderSelectedForm()}
                    {selectedStep.name !== "incorporation"
                      && (<button
                            type="button"
                            className="btn btn-ghost-secondary text-muted navigation-btn"
                            data-toggle="xtab"
                            data-target="#incorporation-nav" role="tab" aria-controls="incorporation"
                          >
                        <i style={{ verticalAlign: "middle" }}
                          className="fe fe-chevron-left no-click"
                        ></i>
                        <span
                          className="no-click"
                          style={{ verticalAlign: "middle" }}
                          onClick={() => navigatePage(formik, "back")}
                        >
                          Previous
                        </span>
                      </button>)}
                    {selectedStep.name !== "additionalInfo"
                      && (<button type="button" className="btn btn-primary float-end navigation-btn" data-toggle="xtab"
                        data-target="#address-nav" role="tab" aria-controls="address">
                        <span
                          className="no-click"
                          style={{ verticalAlign: "middle" }}
                          onClick={() => navigatePage(formik, "forward")}
                        >
                          Next
                        </span>
                        <i style={{ verticalAlign: "middle" }}
                          className="fe fe-chevron-right no-click"
                        ></i>
                      </button>)}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div >
      </div >
    </>);
  }

  const initialValues: Post2023CorpTaxFormFields = corptaxContextQuery.data?.data;

  if (!initialValues) {
    return <></>;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={getPost2023CorporateTaxSchema(Number(taxYear))}
      validateOnChange={isValidating}
      validateOnBlur={isValidating}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {(formik) => (
        <>
          {renderSidenav(formik)}
          {renderFormComponents(formik)}
        </>
      )}
    </Formik>);
}
