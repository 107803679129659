import { useContext } from "react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { Row } from "react-bootstrap";
import FondoBGEllipseLayout from "../fusion-kit/FondoBGEllipseLayout";
import BankAccountsIntegrationCard from "./BankAccountsIntegrationCard";
import MergeDevCard from "./MergeDevCard";
import ThirdPartyIntegrationCard from "./ThirdPartyIntegrationCard";
import { CompanyLevelSettingsContext } from "../../context/FeatureFlagsContext";

export default function Integrations() {
  const isMergeDevAccountingEnabled = useFeatureFlagEnabled("merge-dev-accounting");
  const isMergeDevHRISEnabled = useFeatureFlagEnabled("merge-dev-hris");
  const companyLevelSettings = useContext(CompanyLevelSettingsContext);
  const { showQboIntegrationCard } = companyLevelSettings;

  let subtitle = "Connect Fondo to your bank accounts.";
  if (isMergeDevAccountingEnabled || isMergeDevHRISEnabled) {
    subtitle = "Connect Fondo to your bank accounts and other integrations.";
  }
  return (
        <div className="main-content">
            <FondoBGEllipseLayout
                title="Integrations"
                subtitle={subtitle}
            />
            <div className="mx-4">
                <Row className="row mt-n7">
                    <div className='col-sm-6'>
                        <BankAccountsIntegrationCard />
                    </div>
                    <div className='col-sm-6'>
                        {isMergeDevAccountingEnabled
                            && <MergeDevCard category='accounting' title='Accounting'/>
                        }
                        {isMergeDevHRISEnabled
                            && <MergeDevCard category='hris' title='HR'/>
                        }
                        {showQboIntegrationCard && (
                            <ThirdPartyIntegrationCard source="QBO" />
                        )}
                    </div>
                </Row>
            </div>
        </div>
  );
}
