import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

type CircleBadgeProps = {
  value: number;
  text: string;
}

const CircleBadge = ({ value, text }: CircleBadgeProps) => {
  const notStarted = !value;
  return (
    <CircularProgressbar
      value={value}
      text={text}
      background
      backgroundPadding={6}
      styles={buildStyles({
        backgroundColor: notStarted ? "#99A9C6" : "#2968ED",
        textColor: "#fff",
        pathColor: "#fff",
        trailColor: notStarted ? "#B8C3D7" : "#5b8af1",
        textSize: "36px",
      })}
    />
  );
};

export default CircleBadge;
