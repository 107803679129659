import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { requestFinancialHubInstitutionConnections } from "../finance-dashboard/FinanceDashboard.api";
import { FinancialHubInstitution } from "../finance-dashboard/financeDashboard.type";
import { AsyncSelectItem } from "./asyncSelectors.type";

type AsyncFinancialHubInstitutionConnectionSelectType = {
  handleSelection: (selected: AsyncSelectItem[]) => any;
  multiple: boolean;
  selected: AsyncSelectItem[];
  widgetClassName?: string;
  widgetStyle?: object;
  widgetSize?: "sm" | "lg";
  isInvalid?: boolean
  allowNew?: boolean
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  excludeManuallyAddedAccounts?: boolean;
};

const AsyncFinancialHubInstitutionConnectionSelect = ({
  selected,
  multiple,
  handleSelection,
  widgetClassName,
  widgetStyle,
  widgetSize,
  isInvalid,
  allowNew = false,
  onBlur,
  excludeManuallyAddedAccounts = false,
}: AsyncFinancialHubInstitutionConnectionSelectType) => {
  const [currentSearch, setCurrentSearch] = useState("");
  const financialHubInstitutionConnectionSearch = useQuery(
    ["financialHubInstitutionConnectionSearch", currentSearch],
    async (): Promise<AsyncSelectItem[]> => {
      const response = await requestFinancialHubInstitutionConnections({
        search: currentSearch,
        excludeManuallyAdded: excludeManuallyAddedAccounts,
      });
      return response.map((institutionConnection: FinancialHubInstitution) => ({
        id: institutionConnection.uuid,
        label: institutionConnection.name,
      }));
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  return (
    <AsyncTypeahead
      id='financial-hub-institution-connection-select'
      style={widgetStyle}
      size={widgetSize}
      className={widgetClassName}
      isLoading={financialHubInstitutionConnectionSearch.isLoading}
      minLength={0}
      onSearch={setCurrentSearch}
      options={financialHubInstitutionConnectionSearch.data?.length ? financialHubInstitutionConnectionSearch.data : []}
      placeholder='Search for Institution by name'
      selected={selected}
      onChange={(newSelected: any[]) => handleSelection(newSelected)}
      multiple={multiple}
      isInvalid={isInvalid}
      allowNew={allowNew}
      onBlur={onBlur}
    />
  );
};

export default AsyncFinancialHubInstitutionConnectionSelect;
