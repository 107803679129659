import { useState } from "react";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import {
  CorpTaxFormFields,
  CorporateTaxStep,
  USSubsidiaryFormFields,
} from "./corporateTax.type";
import AddSubsidiaryModal from "./modals/AddSubsidiaryModal";
import { Post2023CorporateTaxStep } from "./post-2023/post2023CorporateTax.type";
import DisplaySingleLineAddress from "../forms/display-helpers/DisplaySingleLineAddress";
import DataVisibilityToggle from "../fusion-kit/DataVisibilityToggle";

type USSubsidiariesProps = {
  arrayHelpers: FieldArrayRenderProps;
  stepInfo: CorporateTaxStep | Post2023CorporateTaxStep;
}

export default function USSubsidiaries({ arrayHelpers }: USSubsidiariesProps) {
  const formikContext = useFormikContext<CorpTaxFormFields>();

  const needOneError = typeof formikContext.errors?.usSubs === "string";

  const [showAddSubsidiaryModal, setShowAddSubsidiaryModal] = useState<boolean>(false);
  const [currentlySelectedSubsidiaryIndex, setCurrentlySelectedSubsidiaryIndex] = useState<number | undefined>();

  const handleCancel = () => {
    setShowAddSubsidiaryModal(false);
    setCurrentlySelectedSubsidiaryIndex(undefined);
  };

  const handleSave = (officerData: USSubsidiaryFormFields) => {
    if (currentlySelectedSubsidiaryIndex !== undefined) {
      arrayHelpers.replace(currentlySelectedSubsidiaryIndex, officerData);
    } else {
      arrayHelpers.push(officerData);
    }

    setCurrentlySelectedSubsidiaryIndex(undefined);
    setShowAddSubsidiaryModal(false);
  };

  const handleDelete = (index: number, subsidiary: USSubsidiaryFormFields) => {
    if (subsidiary.uuid) {
      formikContext.setFieldValue("oldUsSubs", [...formikContext.values.oldUsSubs, subsidiary.uuid]);
    }
    arrayHelpers.remove(index);
  };

  function renderSubsidiaries() {
    return (
      formikContext.values.usSubs.map((subsidiary, index) => (

        <div className="col-lg-6 col-xl-4 mb-5">
          <div className="d-flex justify-content-between">
            <h3>{subsidiary?.name}</h3>
            <div>
              <span>
                <i
                  className="fe fe-edit-2 mx-2 action-icon text-secondary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCurrentlySelectedSubsidiaryIndex(index);
                    setShowAddSubsidiaryModal(true);
                  }}
                  title="Edit"
                ></i>
              </span>
              <span>
                <i
                  style={{ cursor: "pointer" }}
                  className="fe fe-trash mx-2 action-icon text-secondary"
                  title="Delete"
                  onClick={() => handleDelete(index, subsidiary)}
                ></i>
              </span>
            </div>
          </div>
          <hr className='mt-0' />
          <div className='row'>
            <p className={`data-item ${fieldHasError(index, ["name"]) ? "text-danger" : ""}`}>
              Name: {subsidiary?.name}
            </p>
            <p className={`data-item ${fieldHasError(index, ["dateAcquired"]) ? "text-danger" : ""}`}>
              Date acquired: {subsidiary?.dateAcquired?.toString()?.split("T")?.[0]}
            </p>
            <p className={`data-item ${fieldHasError(index, ["taxIdentificationNumberEnc"]) ? "text-danger" : ""}`}>
              Tax ID: <DataVisibilityToggle
                  data={subsidiary?.taxIdentificationNumberEnc}
                />
            </p>
            <p
              className={`data-item ${fieldHasError(index, [
                "streetAddress", "city", "state", "postalCode",
              ]) ? "text-danger" : ""}`}
            >
              Address: <DisplaySingleLineAddress addressable={subsidiary} />
            </p>
            <p className={`data-item ${fieldHasError(index, ["phone"]) ? "text-danger" : ""}`}>
              Phone: {subsidiary?.phone}
            </p>
            <p className={`data-item ${fieldHasError(index, ["owners"]) ? "text-danger" : ""}`}>
              Owners: {subsidiary?.owners}
            </p>
            <p className={`data-item ${fieldHasError(index, ["fiscalYearEnd"]) ? "text-danger" : ""}`}>
              Fiscal Year End:
              {subsidiary?.fiscalYearEnd?.length === 4
                ? ` ${subsidiary?.fiscalYearEnd.slice(0, 2)}/${subsidiary?.fiscalYearEnd.slice(2, 4)}` : ""}
            </p>
          </div>
        </div>
      ))
    );
  }

  const fieldHasError = (index: number, fields: string[]): boolean => (
    fields.some((field) => !!(formikContext.errors?.usSubs?.[index] as any)?.[field])
  );

  return (<>
    {formikContext.values.usSubs.length > 0 && (
      <div>
        <div className="row mt-5">
          {renderSubsidiaries()}
        </div>
        <div className="mb-5">
          <button type="button" className="btn btn-primary" onClick={() => {
            setShowAddSubsidiaryModal(true);
          }}>Add another</button>
        </div>
      </div>
    )}

    {(formikContext.values.usSubs.length === 0) && (
      <div className='text-align-center' style={{ margin: "200px" }}>
        <h3 className="text-secondary mb-4">Start by adding a new subsidiary</h3>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setShowAddSubsidiaryModal(true);
          }}
        >Add new</button>
        {needOneError
          && <h3 className="text-danger mt-4 field-error text-center">You need to add at least one subsidiary.<br />
          If you do not have any, please select No in the Company Info section for subsidiaries.</h3>
        }
      </div>
    )}

    <AddSubsidiaryModal
      show={showAddSubsidiaryModal}
      handleClose={handleCancel}
      subsidiary={currentlySelectedSubsidiaryIndex !== undefined
        ? formikContext.values.usSubs[currentlySelectedSubsidiaryIndex] : {}}
      header={`${currentlySelectedSubsidiaryIndex !== undefined ? "Update" : "Add new"} subsidiary`}
      handleSave={handleSave}
    />
  </>);
}
