import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { deleteFondoApi } from "../../utils/util.api";
import ConfirmationModal from "./ConfirmationModal";
import AlertModal from "./AlertModal";

type DeleteTrashButtonProps = {
    id: number | string;
    deleteURL: string;
    queryKey?: string[];
    confirmationModalBodyText?: string;
    askConfirmation?: boolean;
};

const DeleteTrashButton = (
  {
    id,
    deleteURL,
    queryKey,
    confirmationModalBodyText,
    askConfirmation = false,
  }:DeleteTrashButtonProps,
) => {
  const queryClient = useQueryClient();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const deleteItemMutation = useMutation<any, AxiosError<any>, any>(
    (url: string) => deleteFondoApi(url),
  );
  const [showAlertModal, setShowAlertModal] = useState(false);

  const deleteElement = () => {
    deleteItemMutation.mutate(
      deleteURL + id,
      {
        onSuccess: () => {
          if (queryKey?.length) {
            for (let i = 0; i < queryKey.length; i += 1) {
              queryClient.invalidateQueries([queryKey[i]]);
            }
          }
        },
        onError: () => {
          setShowAlertModal(true);
        },
      },
    );
    setShowConfirmationModal(false);
  };

  const handleOnClick = () => {
    // askConfirmation ? setShowConfirmationModal(true) : deleteElement();
    if (askConfirmation) {
      setShowConfirmationModal(true);
    } else deleteElement();
  };

  return (
        <>
            <FeatherIcon
                onClick={handleOnClick}
                icon="trash-2"
                size="1.1em"
                className="text-danger"
                style={{ cursor: "pointer", color: "red" }}
            />
            <ConfirmationModal
                show={showConfirmationModal}
                handleClose={() => setShowConfirmationModal(false)}
                handleConfirm={deleteElement}
                bodyText={confirmationModalBodyText}
            />
            <AlertModal
                show={showAlertModal}
                title={"Error"}
                handleClose={() => setShowAlertModal(false)}
                error={true}
            />
        </>
  );
};

export default DeleteTrashButton;
