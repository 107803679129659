const overlayStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "2",
} as React.CSSProperties;
// Adding typing above otherwise 'position' doesn't work

type BlurWrapperProps = {
  children: React.ReactNode,
  overlayNode?: React.ReactNode,
}

// Applies a blur effect to the child component to signify "disabled"
// and has the abbility to pass a ReactNode to show on top (like an alert)
const BlurWrapper = ({ children, overlayNode }: BlurWrapperProps) => (
  <div className="position-relative">
    <div style={ { filter: "blur(3px)" } }>
      {children}
    </div>
    <div style={overlayStyle}>
      <div>
        {overlayNode}
      </div>
    </div>
  </div>
);

export default BlurWrapper;
