import {
  Accordion, Badge, Button, Form,
} from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router";
import { ActionItemStatus, ActionItem, MissingInfoStatus } from "./actionItems.type";
import greenCheckCircle from "../../assets/img/icons/greenCheckCircle.png";
import ConnectionTypeActionItemSelector from "./ConnectionTypeActionItemSelector";
import { getUrlForFormActionItem } from "./utils";
import { markActionItemCompleted, markActionItemIncomplete } from "./actionItems.api";
import FileActionItemSelector from "./FileActionItemSelector";
import playButton from "../../assets/img/icons/playButton.svg";

type ActionItemDetailViewProps = {
  actionItem: ActionItem;
}

const ActionItemDetailView = ({ actionItem }: ActionItemDetailViewProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const showMarkAsCompleteButton = () => (
    actionItem.status === ActionItemStatus.PENDING && !actionItem.missingInfo.missingForm?.formType
  );

  const showMarkAsIncompleteButton = () => (
    actionItem.status !== ActionItemStatus.PENDING && actionItem.missingInfo.status !== MissingInfoStatus.VERIFIED
  );

  const markActionItemCompleteMutation = useMutation<any, AxiosError<any>, string>(
    (actionItemId) => markActionItemCompleted(actionItemId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["actionItemServiceData"],
        });
        queryClient.invalidateQueries({
          queryKey: ["actionItemsData"],
        });
        queryClient.invalidateQueries({
          queryKey: ["actionItems"],
        });
        queryClient.invalidateQueries({
          queryKey: ["actionItemsUserServices"],
        });
      },
    },
  );

  const markActionItemIncompleteMutation = useMutation<any, AxiosError<any>, string>(
    (actionItemId) => markActionItemIncomplete(actionItemId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["actionItemServiceData"],
        });
        queryClient.invalidateQueries({
          queryKey: ["actionItemsData"],
        });
        queryClient.invalidateQueries({
          queryKey: ["actionItems"],
        });
        queryClient.invalidateQueries({
          queryKey: ["actionItemsUserServices"],
        });
      },
    },
  );

  const handleActionItemCompletion = (item: ActionItem) => {
    if (showMarkAsCompleteButton()) {
      markActionItemCompleteMutation.mutate(item.id.toString());
    } else {
      markActionItemIncompleteMutation.mutate(item.id.toString());
    }
  };

  const queryLoading = () => markActionItemCompleteMutation.isLoading
    || markActionItemIncompleteMutation.isLoading;

  const getBadgeColor = (): string => {
    if (queryLoading()) return "secondary-soft";
    return showMarkAsIncompleteButton() ? "success-soft" : "primary-soft";
  };

  return (
    <Accordion>
      <Accordion.Item eventKey={actionItem.id.toString()} key={actionItem.id} className="border-0">
        <Accordion.Header>
          {actionItem.status === ActionItemStatus.PENDING && (
            <input
              type="radio"
              style={{
                width: "30px",
                height: "30px",
                marginTop: "-1px",
                marginRight: "3px",
              }}
              disabled
            />
          )}
          {actionItem.status === ActionItemStatus.COMPLETED && (
            <img src={greenCheckCircle} className="me-1" style={{ width: "30px", height: "30px" }} />
          )}
          <h2
            className={`m-0 ms-2 ${actionItem.status === ActionItemStatus.COMPLETED
              && "text-decoration-line-through"}`}>
            {actionItem.missingInfo.name}
          </h2>
        </Accordion.Header>
        <Accordion.Body>
          <div dangerouslySetInnerHTML={{ __html: actionItem?.missingInfo.directions }} className="pt-3"></div>
          {actionItem.missingInfo.videoLink && (
            <div>
              <h3>
                <a className="pe-3" href={actionItem.missingInfo.videoLink} target="blank" rel="noopener noreferrer">
                  <img src={playButton} />
                </a>
                {actionItem.missingInfo.videoName || "Related Video"}
              </h3>
            </div>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: actionItem?.missingInfo.connectionDirections }}
            className="pt-3"></div>
          {actionItem.connectionType && (
            <div className="mt-5">
              <ConnectionTypeActionItemSelector
                connectionType={actionItem.connectionType}
                actionItem={actionItem}
              />
            </div>
          )}
          <FileActionItemSelector actionItem={actionItem} />
          {actionItem.missingInfo.missingForm?.formType && (
            <Button
              onClick={() => navigate(getUrlForFormActionItem(
                actionItem.missingInfo.missingForm.formType,
                actionItem.missingInfo.missingForm.formTypeTaxYear,
              ))}
              variant="outline-primary"
              size="sm">
              Go to Form
            </Button>
          )}
          {(actionItem.status !== ActionItemStatus.PENDING
            || !actionItem.missingInfo.missingForm?.formType) && <Badge
              className="d-flex justify-content-between py-4 mt-3"
              bg={`${getBadgeColor()}`}>
              <div>
                <h3 className="fw-normal ps-4 my-auto">
                  {`${showMarkAsIncompleteButton() ? "You have marked this step complete"
                    : "Please select this option after completion"}`}
                </h3>
              </div>
              <Form>
                <Form.Check
                  type="switch"
                  id="actionItemCompletionSwitch"
                  className="pe-4"
                  checked={showMarkAsIncompleteButton()}
                  disabled={queryLoading()}
                  onChange={() => handleActionItemCompletion(actionItem)}
                />
              </Form>
            </Badge>}

        </Accordion.Body>
      </Accordion.Item>
      <hr />
    </Accordion>
  );
};

export default ActionItemDetailView;
