import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

type VideoModalParams = {
  show: boolean;
  handleClose: () => void;
  videoUrl: string;
}

const VideoModal = ({
  show, handleClose, videoUrl,
}: VideoModalParams) => (
  <Modal
    show={show}
    size="lg"
    onHide={handleClose}
    >
    <div className="d-flex justify-content-center bg-dark mx-0">
      <ReactPlayer
        url={videoUrl}
        controls={true}
        // ReactPlayer seems to work best with a 16:9 aspect ratio`
        // Changes to the width and height should keep this ratio
        width={800}
        height={450}
        />
    </div>
  </Modal>
);
export default VideoModal;
