import actionItemsEmptyStateIcon from "../../assets/img/illustrations/victory-hand.svg";
import FondoLayout from "../fusion-kit/FondoLayout";

const ActionItemsCompletedView = () => (
    <FondoLayout
      title="Action Items"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div
              className="px-6 mx-auto text-center col-12 col-sm-8 col-lg-10 col-xl-8"
              style={{ marginTop: "150px" }}
            >
              <div>
                <img src={actionItemsEmptyStateIcon} className="mx-auto" alt="..." />
                <h1 className="my-3">
                  All done!
                </h1>
                <p className="mb-2 text-muted">
                  Woo hoo, you've completed all your tasks.
                </p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </FondoLayout>
);

export default ActionItemsCompletedView;
