import { useFormikContext } from "formik";
import {
  convertCurrencyToNumber,
  convertNumberToRoundedCurrency,
  convertPercentageToDecimal,
} from "../../../../utils/utils";
import { RnDValues } from "../baseFormData";

type RndEligibilityCalculationsProps = {
  setEligibilityStatus: (eligible: boolean) => void,
  setShown: React.Dispatch<React.SetStateAction<boolean>>,
}

const RndEligibilityCalculations = ({
  setEligibilityStatus,
}: RndEligibilityCalculationsProps) => {
  const formikContext = useFormikContext<RnDValues>();

  const isEligibile = (rndValues: RnDValues): boolean => {
    if (rndValues.revenueMoreThanFiveMillion === "True"
      || rndValues.createImproveProduct === "False"
      || rndValues.facedUncertainty === "False"
      || rndValues.experimentalProcesses === "False"
      || rndValues.technologicalActivities === "False") return false;
    return true;
  };

  const anyValidWage = formikContext.values.rndRelatedEmployeeWages.some(
    (wage) => wage.grossEarnings && wage.timeSpentOnRnd,
  );

  const anyValidContractor = formikContext.values.rndRelatedContractorWages.some(
    (contractor) => contractor.grossEarnings && contractor.timeSpentOnRnd,
  );

  const anyValidSupply = formikContext.values.rndRelatedSupplies.some(
    (supply) => supply.cost,
  );

  const useComplexBase = anyValidWage || anyValidContractor || anyValidSupply;

  const calculateBaseAmount = (rndValues: RnDValues): number => {
    if (useComplexBase) {
      return calculateComplexBase(rndValues);
    }
    if (rndValues.paidToEmployees || rndValues.paidToContractors || rndValues.paidOnSupplies) {
      return calculateSimplifiedBase(rndValues);
    }
    return 0;
  };

  const calculateComplexBase = (rndValues: RnDValues): number => {
    const wagesSum = rndValues.rndRelatedEmployeeWages.reduce((total, currentWage) => {
      if (currentWage.grossEarnings && currentWage.timeSpentOnRnd) {
        total += convertCurrencyToNumber(currentWage.grossEarnings)
        * convertPercentageToDecimal(currentWage.timeSpentOnRnd);
      }
      return total;
    }, 0);
    const contractorsSum = rndValues.rndRelatedContractorWages.reduce((total, currentContractor) => {
      if (currentContractor.grossEarnings && currentContractor.timeSpentOnRnd) {
        total += convertCurrencyToNumber(currentContractor.grossEarnings)
        * convertPercentageToDecimal(currentContractor.timeSpentOnRnd) * 0.65;
      }
      return total;
    }, 0);
    const suppliesSum = rndValues.rndRelatedSupplies.reduce((total, currentSupply) => {
      if (currentSupply.cost) {
        total += convertCurrencyToNumber(currentSupply.cost);
      }
      return total;
    }, 0);
    return wagesSum + contractorsSum + suppliesSum;
  };

  const calculateSimplifiedBase = (rndValues: RnDValues): number => {
    let base = 0;
    if (rndValues.paidToEmployees) {
      base += (0.5 * convertCurrencyToNumber(rndValues.paidToEmployees));
    }
    if (rndValues.paidToContractors) {
      base += (0.65 * convertCurrencyToNumber(rndValues.paidToContractors));
    }
    if (rndValues.paidOnSupplies) {
      base += convertCurrencyToNumber(rndValues.paidOnSupplies);
    }
    return base;
  };

  const renderEligibilityComponent = () => {
    const eligible = isEligibile(formikContext.values);
    setEligibilityStatus(eligible);
    if (maxEstimate <= 10) return (<></>);

    let textColor = "#A31D2B";
    let eligibilityText = "Ineligible";
    if (eligible) {
      if (maxEstimate > 0) {
        textColor = "#2968ED";
        eligibilityText = `$${convertNumberToRoundedCurrency(minEstimate)}
        - $${convertNumberToRoundedCurrency(maxEstimate)}`;
      }
    }
    return (
      <h1 style={{ color: textColor }} className="mb-3">{eligibilityText}</h1>
    );
  };

  const minEstimate: number = 0.06 * calculateBaseAmount(formikContext.values);
  const maxEstimate: number = 0.1 * calculateBaseAmount(formikContext.values);

  return (
    <>
      {renderEligibilityComponent()}
      {useComplexBase && (
        <h5 className="pb-2">
          This estimate is based on the Employees, Contractors, and Supplies on Page 2.
          Please add in all records here to get the most accurate estimate possible.
        </h5>
      )}
    </>
  );
};

export default RndEligibilityCalculations;
