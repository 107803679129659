import { FormikContextType } from "formik";
import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

type EncryptedTextFieldProps = {
  formikContext: FormikContextType<any>;
  fieldName: string;
  invalid?: boolean;
  enablePasswordType?: boolean;
  placeholder?: string;
}

const EncryptedTextField = (
  {
    formikContext,
    fieldName,
    invalid,
    enablePasswordType = true,
    placeholder,
  }: EncryptedTextFieldProps,
) => {
  const [visible, setVisible] = useState(false);
  return (
    <InputGroup className='input-group-merge mb-3'>
      <Form.Control
        {...formikContext.getFieldProps(fieldName)}
        placeholder={placeholder || "Enter confidential text"}
        className={`form-control ${invalid ? "is-invalid" : ""}`}
        type={!visible && enablePasswordType ? "password" : "text"}
      />
      <InputGroup.Text>
        {enablePasswordType
          && <FeatherIcon
            icon={visible ? "eye-off" : "eye"}
            size='1em'
            onClick={() => setVisible(!visible)}
            style={{ cursor: "pointer" }}
          />
        }
      </InputGroup.Text>
    </InputGroup>
  );
};

export default EncryptedTextField;
