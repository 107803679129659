import { Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { mapTransactionCategoryToHexColor, TransactionCategory } from "../financeDashboard.type";

type CategoryButtonProps = {
  category: TransactionCategory;
  selected: boolean;
  setSelectedCategory: (value: TransactionCategory|undefined) => void;
}

const CategoryButton = (
  {
    category,
    selected,
    setSelectedCategory,
  }: CategoryButtonProps,
) => (
  <Button
    onClick={() => setSelectedCategory(category)}
    variant={`btn btn-sm ${selected ? "btn-outline-primary" : "btn-outline-secondary"}`}
    className="mx-1"
  >
    <span className="px-1">
      <FeatherIcon
        className="mb-1"
        icon="circle"
        size="10"
        fill={mapTransactionCategoryToHexColor[category]}
        strokeWidth={0}
      />
    </span>
    <span>
      {category}
    </span>
  </Button>
);

export default CategoryButton;
