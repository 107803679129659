import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import FondoLayout from "../../fusion-kit/FondoLayout";
import { ACTIVE_PRODUCTS, CLOSED_PRODUCTS } from "../../../constants/network-calls";
import { LoggedInUserContext } from "../../../context/LoggedInUserContext";
import ActiveOrders from "./ActiveOrders";
import ClosedOrders from "./ClosedOrders";
import AddProductModal from "./AddProductModal";
import { getProductOrders } from "../products.api";

const ProductsPage = () => {
  const location = useLocation();
  const { currentCompanyName } = useContext(LoggedInUserContext);
  const [showAddProductModal, setShowAddProductModal] = useState(false);

  const navigate = useNavigate();

  const orders = useQuery(
    ["productOpenOrder"],
    getProductOrders,
    {
      refetchOnWindowFocus: false,
    },
  );

  // THIS FORCES ANYONE THAT HITS THE PRODUCT PAGE, BUT NOT ACTIVE OR CLOSED, TO REDIRECT TO ACTIVE
  if (location.pathname !== CLOSED_PRODUCTS && location.pathname !== ACTIVE_PRODUCTS) {
    navigate(ACTIVE_PRODUCTS);
  }

  const productTabs = [
    {
      name: "Active",
      url: ACTIVE_PRODUCTS,
    },
    {
      name: "Closed",
      url: CLOSED_PRODUCTS,
    },
  ];

  const orderHeader = (): JSX.Element => {
    if (location.pathname === CLOSED_PRODUCTS && orders.data?.closedOrders?.length === 0) {
      return (
        <p className="text-secondary mt-3 mb-5">No closed orders.</p>
      );
    }
    if (location.pathname === ACTIVE_PRODUCTS && !orders.data?.openOrder) {
      return (
        <p className="text-secondary mt-3 mb-5">No open orders.</p>
      );
    }
    return (
      <p className="text-secondary mt-3 mb-5">
        During sign up, {currentCompanyName} selected the following products.
        If you need help with anything else, please let us know.
      </p>
    );
  };

  const orderFooter = () => {
    if ((location.pathname === CLOSED_PRODUCTS
      && orders.data?.closedOrders?.length === 0)
      || (location.pathname === ACTIVE_PRODUCTS
        && !orders.data?.openOrder)) {
      return;
    }
    return (
      <p className="text-secondary mb-5">
        Please note that your subscription pricing may increase or decrease based on the company's expenses
        (<Link to="https://www.tryfondo.com/add-on-pricing" target="_blank">see here</Link>).
        &nbsp;Any changes/prices discussed with Fondo during onboarding or scoping will be reflected
        on your invoice.
      </p>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-end">
      </div>
      <FondoLayout
        title="Products"
        tabs={productTabs}
      >
        {(location.pathname === CLOSED_PRODUCTS || location.pathname === ACTIVE_PRODUCTS)
          && <>
            <div className="d-flex justify-content-between">
              {orderHeader()}
              <div>
                <Button onClick={() => setShowAddProductModal(true)}>
                  Add Product
                </Button>
              </div>
            </div>
            {location.pathname === ACTIVE_PRODUCTS
              && orders.data?.openOrder && <ActiveOrders order={orders.data.openOrder} />}
            {location.pathname === CLOSED_PRODUCTS
              && orders.data?.closedOrders && <ClosedOrders orders={orders.data.closedOrders} />}
            {orderFooter()}
          </>
        }
      </FondoLayout>
      <AddProductModal show={showAddProductModal} handleClose={() => setShowAddProductModal(false)} />
    </>
  );
};

export default ProductsPage;
