import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import FondoLayout from "../../fusion-kit/FondoLayout";
import { getPartners } from "../passport-partners.api";
import Spinner from "../../spinner/Spinner";
import { PartnerCard } from "./PartnerCard";
import { PASSPORT_PARTNERS } from "../../../constants/network-calls";
import { AsyncSelectItem } from "../../asyncSelectors/asyncSelectors.type";
import AsyncExternalConnectionTypeSelect from "../../asyncSelectors/AsyncExternalConnectionTypeSelect";
import { Partner } from "../passport-partners.types";
import { FeaturedPartnerBanner } from "./FeaturedPartnerBanner";

const ALL_SUFFIX = "partners";
const ONE_CLICK_SUFFIX = "one-click";
const OTHER_SUFFIX = "other";

const tabs = [
  { name: "All", url: PASSPORT_PARTNERS },
  { name: "One Click Partners", url: `${PASSPORT_PARTNERS}/${ONE_CLICK_SUFFIX}` },
  { name: "Other Partners", url: `${PASSPORT_PARTNERS}/${OTHER_SUFFIX}` },
];

const PartnersLayout = ({ children }: { children: JSX.Element }) => (
  <FondoLayout title="Marketplace" pretitle="Welcome" tabs={tabs}>
    {children}
  </FondoLayout>
);

export const PassportPartners = () => {
  const location = useLocation();
  const [selectedConnectionTypes, setSelectedConnectionTypes] = useState<AsyncSelectItem[]>([]);
  const selectedConnectionTypeIds = selectedConnectionTypes.map((item) => item.id as number);
  const partnersQuery = useQuery(
    // avoid refetches when connection types change order
    ["passportPartnersPartners", ...selectedConnectionTypeIds.sort()],
    () => getPartners(selectedConnectionTypeIds),
    {
      refetchOnWindowFocus: false,
      // connection types are unlikely to change during the session
      staleTime: Infinity,
    },
  );

  const getFeaturedPartnerBanner = (partnersToDisplay: Partner[]) => {
    const featuredPartner = partnersToDisplay.find((partner) => partner.isFeatured);

    if (featuredPartner) {
      return <FeaturedPartnerBanner partner={featuredPartner} />;
    }

    return null;
  };

  let partnersContent = <></>;

  if (partnersQuery.isLoading || !partnersQuery.data) {
    partnersContent = <Spinner />;
  } else if (partnersQuery.data) {
    const partnersToDisplay = partnersQuery.data.filter((partner) => {
      if (location.pathname.endsWith(ALL_SUFFIX)) {
        return true;
      }
      return location.pathname.endsWith(ONE_CLICK_SUFFIX)
        ? partner.hasOneTouchDeal
        : !partner.hasOneTouchDeal && partner.products.length;
    });

    if (!partnersToDisplay.length) {
      partnersContent = <div>No partners available at the moment.</div>;
    } else {
      partnersContent = (
        <>
          {getFeaturedPartnerBanner(partnersToDisplay)}
          <div className="flex-add-after d-flex justify-content-between flex-wrap">
            {partnersToDisplay.map((partner) => (
              <PartnerCard key={partner.uuid} partner={partner} />
            ))}
          </div>
        </>
      );
    }
  }

  return (
    <PartnersLayout>
      <div className="d-flex flex-column align-items-center">
        <Alert variant="white" className="w-100">
          <AsyncExternalConnectionTypeSelect
            selected={selectedConnectionTypes}
            handleSelection={setSelectedConnectionTypes}
            multiple={true}
          />
        </Alert>
        {partnersContent}
      </div>
    </PartnersLayout>
  );
};
