import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  OverlayTrigger,
  ProgressBar,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import CashBalancePerAccount from "./CashBalancePerAccount";
import CircleReferenceColor from "../fondo-components/CircleReferenceColor";
import { convertNumberToCurrency } from "../../utils/utils";
import { getPercentageOfBalanceInTotalBalance, getAccountsDataWithReferenceColours } from "./utils";
import { getCurrentBalance, getMonthlyBalance } from "../finance-dashboard/FinanceDashboard.api";
import { BalanceAccountChartData, CashBalanceResponse } from "../finance-dashboard/financeDashboard.type";
import { formatDateToWords } from "../../utils/common.util";
import BlurWrapper from "../fondo-components/BlurWrapper";
import RefreshBalancesCard from "../finance-dashboard/RefreshBalanceCard";

type CashBalanceCardProps = {
  disabledState?: boolean;
  month?: string;
  showingCurrentMonthBalanceOnly?: boolean;
  showRefreshBalanceSection?: boolean;
  allowEditingBalances?: boolean;
}

const CashBalanceCard = ({
  disabledState = false,
  month = "",
  showingCurrentMonthBalanceOnly = false,
  showRefreshBalanceSection = false,
  allowEditingBalances = false,
}: CashBalanceCardProps) => {
  const [showAllBalancesTable, setShowAllBalancesTable] = useState(false);
  const [balanceResponse, setBalanceResponse] = useState<CashBalanceResponse>();

  const { data: monthlyBalance, isLoading: monthlyBalanceIsLoading } = useQuery<CashBalanceResponse, Error>(
    ["monthlyBalance", month],
    () => getMonthlyBalance(month),
    {
      refetchOnWindowFocus: false,
      enabled: !!month && !showingCurrentMonthBalanceOnly,
      onSuccess: (data) => {
        setBalanceResponse(data);
      },
    },
  );

  const { data: currentBalance, isLoading: currentBalanceIsLoading } = useQuery<CashBalanceResponse, Error>(
    ["currentBalance", month],
    () => getCurrentBalance(),
    {
      refetchOnWindowFocus: false,
      enabled: showingCurrentMonthBalanceOnly,
      onSuccess: (data) => {
        setBalanceResponse(data);
      },
    },
  );

  const renderBalanceTooltip = (balance: number) => (
    <Tooltip id="button-tooltip">
      {`$${convertNumberToCurrency(balance)}`}
    </Tooltip>
  );
  
  const getAccountName = (account: BalanceAccountChartData) => {
    if (account?.others) {
      return "Others";
    }
  
    const accountName = account.account.name;
    const accountMask = account.account.mask ? `- ${account.account.mask}` : "";
  
    return `${accountName}${accountMask}`;
  };

  if (disabledState) {
    return (
      <Card className="mb-0">
        <Card.Body>
          <Card.Title className="text-muted mb-2">
          <div className="d-flex justify-content-between">
            <div>
              <p className="h5 text-uppercase">
                Cash Balance
              </p>
              <span className="h1">
                No accounts connected
              </span>
            </div>
            <Link to={"/dashboard/integrations"}>
              <Button size="sm">
                Connect Account
              </Button>
            </Link>
          </div>
          </Card.Title>
          <ProgressBar className="mt-3 mb-4" style={ { height: "1em" } } />
            <CircleReferenceColor
              color={"#D7E5FA"}
              text={"No data"}
              textMuted
            />
        </Card.Body>
      </Card>
    );
  }

  if ((month && monthlyBalanceIsLoading) || (showingCurrentMonthBalanceOnly && currentBalanceIsLoading)) {
    return (
      <Card className="mb-0">
        <Card.Body>
          <Card.Title className="h5 text-uppercase text-muted mb-2">
            Cash Balance
          </Card.Title>
          <span className="h1 mb-0">
            <Spinner variant="primary" animation="border" role="status" />
          </span>
          <ProgressBar className="mt-4" style={ { height: "1em" } } />
        </Card.Body>
      </Card>
    );
  }

  if (
    (month && monthlyBalance?.hasInvalidItems)
    || (showingCurrentMonthBalanceOnly && currentBalance?.hasInvalidItems)
  ) {
    return (
      <BlurWrapper
        overlayNode={
          <Link to="/dashboard/integrations">
            <Alert variant="danger" className="fs-5">
              <FeatherIcon
                className="me-2 mt-n1"
                icon="alert-triangle"
                size="1.2em"
              />
              We're having issues connecting to one or more of your accounts.
              This may lead to outdated or inaccurate information.
              Click here to repair your connections.
            </Alert>
          </Link>
        }
      >
        <Card className="mb-0">
          <Card.Body>
            <Card.Title className="h5 text-uppercase text-muted mb-2">
              Cash Balance
            </Card.Title>
            <span className="h1 mb-0">
              $0
            </span>
            <ProgressBar className="mt-4" style={ { height: "1em" } } />
          </Card.Body>
        </Card>
      </BlurWrapper>
    );
  }

  return (
    <>
      <Card className={`${showAllBalancesTable ? "rounded-0 rounded-top" : "rounded"} mb-0`}>
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <Card.Title className="text-uppercase text-muted mb-2">
                <span className="h5 text-secondary">Cash Balance </span>
                {showingCurrentMonthBalanceOnly && balanceResponse
                && `(as of ${formatDateToWords(balanceResponse?.latestCashBalanceDate)})`
                }
              </Card.Title>
              <span className="h1 mb-0">
                ${convertNumberToCurrency(balanceResponse?.cashBalance || 0)}
              </span>
              {
                showRefreshBalanceSection && (
                  <RefreshBalancesCard />
                )
              }
            </Col>
            <Col sm="auto">
              <Link to="/dashboard/integrations">
                <Button className="mx-2 mt-n4" variant='white' size='sm'>
                  Manage Accounts
                </Button>
              </Link>
              <Button
                onClick={() => setShowAllBalancesTable(!showAllBalancesTable)}
                className="mx-2 mt-n4"
                variant='white'
                size='sm'
              >
                { showAllBalancesTable ? "Hide" : "Show" } all Balances
                <FeatherIcon
                  className="ms-2"
                  icon={showAllBalancesTable ? "eye-off" : "eye"}
                  size="1em"
                />
              </Button>
            </Col>
          </Row>
          <ProgressBar className="mt-4" style={ { height: "1em" } }>
            {balanceResponse
            && getAccountsDataWithReferenceColours(balanceResponse).graphDisplayAccounts.map(
              (account: any, idx: number) => {
                const balance = account.displayBalance ? account.displayBalance : parseFloat(account.balance);
                return (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 100 }}
                    overlay={renderBalanceTooltip(balance)}
                  >
                    <ProgressBar
                      isChild={true}
                      key={idx}
                      now={getPercentageOfBalanceInTotalBalance(
                        balance,
                        (balanceResponse?.cashBalance || 0),
                      )}
                      style={{ backgroundColor: account.referenceColor, cursor: "pointer" }}
                    />
                  </OverlayTrigger>
                );
              },
            )}
          </ProgressBar>
          <div className="d-flex mt-4">
          {balanceResponse
          && getAccountsDataWithReferenceColours(balanceResponse).graphDisplayAccounts.map(
            (account: BalanceAccountChartData, idx: number) => (
              <CircleReferenceColor
                key={idx}
                color={account.referenceColor}
                text={getAccountName(account)}
                uppercaseText
                textMuted
              />
            ),
          )}
          </div>
        </Card.Body>
      </Card>
      {showAllBalancesTable && balanceResponse && (
        <CashBalancePerAccount
          accountsData={getAccountsDataWithReferenceColours(balanceResponse)}
          allowEditingBalances={allowEditingBalances}
          month={month}
        />
      )}
    </>
  );
};

export default CashBalanceCard;
