import { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "./passport.css";
import {
  Offcanvas,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { AxiosError } from "axios";
import { DragEndEvent } from "@dnd-kit/core";
import {
  arrayMove,
} from "@dnd-kit/sortable";
import { PassportContext } from "../../context/PassportContext";
import PassportCard from "./PassportCard";
import {
  deletePassportField, getPassport, patchPassportField, postPassportField,
  reorderPassportFields,
} from "./passport.api";
import { PassportField } from "./passport.type";
import { LoggedInUserContext } from "../../context/LoggedInUserContext";
import { CreatePassportFieldModal } from "./CreatePassportFieldModal";
import { PassportDragSortContext } from "./PassportDragSortContext";
import { PassportSortableItem } from "./PassportSortableItem";

type IdentifiedFields = {
  addressFields: PassportField[];
  nonAddressFields: PassportField[];
  firstAddressFieldIdx?: number;
};

export default function Passport() {
  const queryClient = useQueryClient();
  const { currentLoggedInUserId, currentCompanyId } = useContext(LoggedInUserContext);

  const passportQuery = useQuery<PassportField[], Error>(["passport"], getPassport, {
    refetchOnWindowFocus: false,
    enabled: !!currentLoggedInUserId && !!currentCompanyId,
  });
  const [fieldsHaveBeenEdited, setFieldsHaveBeenEdited] = useState(false);
  const [showCreatePassportFieldModal, setShowCreatePassportFieldModal] = useState(false);
  const [creationFailureMessage, setCreationFailureMessage] = useState("");

  const calculateSortableIdsAndIdentifiedFields = (passportFields: PassportField[]): [string[], IdentifiedFields] => {
    const identifiedFields: IdentifiedFields = { addressFields: [], nonAddressFields: [] };
    passportFields.reduce((acc, field, idx) => {
      if (field.isAddressField) {
        if (acc.firstAddressFieldIdx === undefined) {
          acc.firstAddressFieldIdx = idx;
        }
        acc.addressFields.push(field);
      } else {
        acc.nonAddressFields.push(field);
      }
      return acc;
    }, identifiedFields);
    const sortableIds = identifiedFields.nonAddressFields.map((passportField) => passportField.fieldName);
    if (identifiedFields.firstAddressFieldIdx !== undefined) {
      sortableIds.splice(identifiedFields.firstAddressFieldIdx, 0, "address_block");
    }
    return [sortableIds, identifiedFields];
  };

  const { data: passportFields } = passportQuery;

  const [sortableIds, identifiedFields] = calculateSortableIdsAndIdentifiedFields(passportFields || []);

  const patchPassportFieldMutation = useMutation(
    patchPassportField,
    {
      onSuccess: () => {
        setFieldsHaveBeenEdited(true);
      },
    },
  );
  const postPassportFieldMutation = useMutation(
    postPassportField,
    {
      onSuccess: () => {
        setShowCreatePassportFieldModal(false);
        setCreationFailureMessage("");
        passportQuery.refetch();
      },
      onError: (error: AxiosError<any>) => {
        setShowCreatePassportFieldModal(true);
        const errString = Object.values(error.response?.data || {}).flat().join(" ").trim() || "";
        setCreationFailureMessage(
          `Failed to create custom field${errString ? `: ${errString}` : "."} Please try again.`,
        );
      },
    },
  );

  const deletePassportFieldMutation = useMutation(
    deletePassportField,
    {
      onMutate: async (passportFieldToDelete: PassportField) => {
        await queryClient.cancelQueries(["passport"]);
        const previousPassportFields = queryClient.getQueryData<PassportField[]>(["passport"]);
        queryClient.setQueryData<PassportField[] | undefined>(["passport"], (oldPassportFields) => (
          [...(oldPassportFields || [])].filter((field) => field.fieldName !== passportFieldToDelete.fieldName)
        ));
        return { previousPassportFields };
      },
      onError: (_error: AxiosError<any>, _variables, context) => {
        if (context?.previousPassportFields) {
          queryClient.setQueryData<PassportField[] | undefined>(["passport"], context.previousPassportFields);
        }
      },
      onSettled: () => {
        passportQuery.refetch();
      },
    },
  );

  type ReorderFieldsMutationVariables = { passportFieldName: string, newIndex: number, oldIndex: number };
  const reorderFieldsMutation = useMutation(
    ({ passportFieldName, newIndex }: ReorderFieldsMutationVariables) => (
      reorderPassportFields(passportFieldName, newIndex)
    ),
    {
      onMutate: async ({ newIndex, oldIndex }: ReorderFieldsMutationVariables) => {
        await queryClient.cancelQueries(["passport"]);
        const previousPassportFields = queryClient.getQueryData<PassportField[]>(["passport"]);
        queryClient.setQueryData<PassportField[] | undefined>(["passport"], (oldPassportFields) => {
          const newSortableIds = arrayMove(sortableIds, oldIndex, newIndex);
          const fieldNameToIdx = newSortableIds.reduce((acc, fieldName, idx) => {
            acc[fieldName] = idx;
            return acc;
          }, {} as { [key: string]: number });
          const getFieldName = (passportField: PassportField) => (
            passportField.isAddressField ? "address_block" : passportField.fieldName
          );
          return [...(oldPassportFields || [])].sort(
            (a, b) => (
              fieldNameToIdx[getFieldName(a)] - fieldNameToIdx[getFieldName(b)]
            ),
          );
        });
        return { previousPassportFields };
      },
      onError: (_error: AxiosError<any>, _variables, context) => {
        if (context?.previousPassportFields) {
          queryClient.setQueryData<PassportField[] | undefined>(["passport"], context.previousPassportFields);
        }
      },
      onSettled: () => {
        passportQuery.refetch();
      },
    },
  );

  const { passportOpen, setPassportOpen } = useContext(PassportContext);

  const onHide = () => {
    setPassportOpen(false);
    if (fieldsHaveBeenEdited) {
      setFieldsHaveBeenEdited(false);
      passportQuery.refetch();
      // in case company names were changed
      queryClient.invalidateQueries({ queryKey: ["userCompanies"] });
    }
  };

  const handlePassportCardEdit = (passportItem: PassportField, onSuccess: () => void, onError: () => void) => {
    patchPassportFieldMutation.mutate(passportItem, { onSuccess, onError });
  };

  const handlePassportCardCreate = (newPassportField: PassportField) => (
    postPassportFieldMutation.mutate(newPassportField)
  );

  const handleModalClose = () => {
    setShowCreatePassportFieldModal(false);
    setCreationFailureMessage("");
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over === null || active.id === over.id || passportFields === undefined) {
      return;
    }
    reorderFieldsMutation.mutate(
      {
        passportFieldName: active.id as string,
        oldIndex: sortableIds.indexOf(active.id as string),
        newIndex: sortableIds.indexOf(over.id as string),
      },
    );
  };

  const getDisplayItems = () => {
    if (!passportFields) return null;
    const fieldNameToPassportFieldsIdx = passportFields.reduce((acc, field, idx) => {
      acc[field.fieldName] = idx;
      return acc;
    }, {} as { [key: string]: number });
    return sortableIds.map((id) => (
      <PassportSortableItem
        key={`si-${id}`}
        id={id}>
        {id === "address_block"
          ? (<div className="border rounded p-1">
            {identifiedFields.addressFields.map((passportField) => (
              <PassportCard
                passportField={passportField}
                handleEdit={handlePassportCardEdit}
                handleDelete={deletePassportFieldMutation.mutate}
                key={passportField.fieldName}
              />))}
          </div>)
          : <PassportCard
            passportField={passportFields[fieldNameToPassportFieldsIdx[id]] as PassportField}
            handleEdit={handlePassportCardEdit}
            handleDelete={deletePassportFieldMutation.mutate}
            key={id}
          />
        }
      </PassportSortableItem >
    ));
  };

  return (
    <>
      <Offcanvas
        show={passportOpen}
        onHide={onHide}
        scroll={true}
        backdrop={false}
        placement={"start"}
        className={"passport-layout"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="passport-title">Fondo Passport</Offcanvas.Title>
          <a className="text-grey ms-auto" onClick={() => setShowCreatePassportFieldModal(true)}>
            <FeatherIcon icon="plus-circle" size="1.2em" strokeWidth="1.5px" className="cursor-pointer" />
          </a>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-2">
          {passportQuery.isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <PassportDragSortContext
              sortableIds={sortableIds}
              handleDragEnd={handleDragEnd}>
              {getDisplayItems()}
            </PassportDragSortContext>
          )}
        </Offcanvas.Body>
      </Offcanvas >
      <CreatePassportFieldModal
        show={showCreatePassportFieldModal}
        creationFailureMessage={creationFailureMessage}
        handleClose={handleModalClose}
        handleSave={handlePassportCardCreate}
      />
    </>
  );
}
