// eslint-disable-next-line import/no-unresolved
import { BentoSettings } from "@bentoapp/types";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getBentoPayload } from "./bento.api";
import loadBentoScript from "../../bento";
import bentoActionItemsEmptyState from "../../assets/img/illustrations/bentoActionItemsEmptyState.png";
import { useBentoNoGuideToShowContext } from "../../context/BentoNoGuideToShowContext";

declare global {
  // Doing this to use the import above, otherwise just importing
  // the type but not using it somehow works and if we delete it
  // thinking is not being used the script will crash.
  interface Window {
    propName: BentoSettings;
  }
}

const Bento = () => {
  const { noGuideToShow, setNoGuideToShow } = useBentoNoGuideToShowContext();
  const { data: bentoPayload, isLoading: bentoPayloadIsLoading } = useQuery(["bentoPayload"], () => getBentoPayload(), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      loadBentoScript();
    },
    enabled: true,
  });

  if (bentoPayload && !bentoPayloadIsLoading) {
    window.bentoSettings = bentoPayload;
    window.Bento?.initialize();
  }

  document.addEventListener("bento-onGuideLoad", (e: any) => {
    // Listening for Bento event guide load to check if the
    // guide is already completed to show the 'All done!' UI
    if (e?.detail?.isComplete) {
      setNoGuideToShow(true);
    }
  });

  document.addEventListener("bento-noGuideFound", () => {
    // Listening for Bento event no guide found to check if there's
    // no existing guide for this user to show the 'All done!' UI
    setNoGuideToShow(true);
  });

  const bentoTag = React.createElement("bento-embed");

  return (
    <>
      {noGuideToShow && (
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div
                className="px-6 mx-auto text-center col-12 col-sm-8 col-lg-10 col-xl-8"
                style={{ marginTop: "150px" }}
                id="action_items-empty-state">
                <div id="action_items-empty-state-content">
                  <img src={bentoActionItemsEmptyState} className="mx-auto" alt="..." />
                  <h1 className="mb-3">All done!</h1>
                  <p className="mb-2 text-muted">Looks like you've completed all your tasks, check back soon!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {bentoTag}
    </>
  );
};

export default Bento;
