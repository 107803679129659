import { useEffect, useState } from "react";
import {
  Formik, Field, Form, ErrorMessage,
} from "formik";
import * as Yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import posthog from "posthog-js";
import {
  ACCELERATOR_CHOICES, BATCH_CHOICES, FORM_ERROR_MSG, HOW_HEARD_CHOICES, fondoEnv as FONDO_ENV,
} from "../../../constants/general";
import { getCompanySection2, saveCompany } from "./company.api";
import { CompanyApiType, CompanySection2, DatePickerProps } from "./company.type";
import { getCountrySubdivisions } from "../../../utils/util.api";
import { Subdivision } from "../../../utils/util.type";
import Flatpickr from "../../flatpickr/Flatpickr";
import { formatDateToStringYMDWithDash } from "../../../utils/common.util";
import { getUser, getUserCompany } from "../../settings/settings.api";

export default function Company2() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = useState<CompanySection2>({
    id: "",
    businessDescription: "",
    batch: "",
    accelerator: "",
    teamSize: 0,
    howHeard: "",
    howHeardOther: "",
    incorporationDate: undefined,
    deCCorp: "Yes",
    interestedInRnDCredit: "Yes",
  });
  const companyMutation = useMutation<any, AxiosError<any>, CompanyApiType>((data) => saveCompany(data));
  const [selectedCountry] = useState<string>("US");
  const countrySubdivisionQuery = useQuery<Subdivision[], Error>([
    "countrySubdivision",
    selectedCountry,
  ], getCountrySubdivisions, {
    enabled: false,
  });

  const userDetails = useQuery(["userDetails"], () => getUser());

  // Identify company on PostHog
  useQuery(["userCompany"], () => getUserCompany(), {
    enabled: !!userDetails,
    onSuccess: (data) => {
      const userId = FONDO_ENV === "production" ? userDetails?.data?.id : `${userDetails?.data?.id}_${FONDO_ENV}`;
      const companyId = FONDO_ENV === "production" ? data.id : `${data.id}_${FONDO_ENV}`;
      posthog.identify(userId, {
        companyId,
        companyName: data.name,
      });
    },
  });

  useQuery<CompanySection2, Error>(["getCompanyQuery2"], getCompanySection2, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (Object.values(data).length !== 0) {
        setFormValues(data);
      }
    },
    onError: (e) => {
      console.error(e);
    },
  });

  useEffect(() => {
    countrySubdivisionQuery.refetch();
  }, [selectedCountry]);

  function renderBatchList() {
    return BATCH_CHOICES.map((batch) => (
      <option key={batch} value={batch}>
        {batch}
      </option>
    ));
  }

  function renderAcceleratorProgramList() {
    return ACCELERATOR_CHOICES.map((batch) => (
      <option key={batch} value={batch}>
        {batch}
      </option>
    ));
  }

  function renderHowHeardList() {
    return HOW_HEARD_CHOICES.map((choice) => (
      <option key={choice} value={choice}>
        {choice}
      </option>
    ));
  }

  const DatePicker = ({ field, form }: DatePickerProps) => (
    <Flatpickr
      value={field.value}
      onChange={(date: any) => {
        const d = formatDateToStringYMDWithDash(date[0]);
        form.setFieldValue(field.name, d);
      }}
      name={field.name}
      placeholder="MM/DD/YYYY"
      className="form-control col-12"
      options={{
        mode: "single",
        altInput: true,
        static: true,
        allowInput: true,
        dateFormat: "Y-m-d",
        altFormat: "m/d/Y",
        maxDate: new Date(),
      }}
    />
  );

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      validationSchema={Yup.object({
        businessDescription: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
        batch: Yup.string(),
        accelerator: Yup.string(),
        teamSize: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD).min(1, FORM_ERROR_MSG.NUMBER_GT(1)),
        howHeard: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
        howHeardOther: Yup.string().when("howHeard", {
          is: (howHeard: any) => howHeard === "Other",
          then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
          otherwise: (schema) => schema.optional().nullable(),
        }),
        incorporationDate: Yup.string()
          .required(FORM_ERROR_MSG.REQUIRED_FIELD)
          .typeError(FORM_ERROR_MSG.REQUIRED_FIELD),
        deCCorp: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
        interestedInRnDCredit: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
      })}
      onSubmit={(values, { setErrors }) => {
        const company: CompanyApiType = {
          section: 2,
          companySection2: values,
        };
        try {
          if (company.companySection2?.incorporationDate
            && typeof company.companySection2?.incorporationDate !== "string"
            && company.companySection2?.incorporationDate?.toISOString().includes("T")) {
            company.companySection2.incorporationDate = formatDateToStringYMDWithDash(
              company.companySection2?.incorporationDate as unknown as string,
            );
          }
        } catch (e) {
          console.error(e);
        }
        companyMutation.mutate(company, {
          onError: (err) => {
            const errors = err?.response?.data;
            if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
              setErrors(errors);
            }
          },
          onSuccess: () => {
            // Refetch the loggedInUser context to get the updated "De C Corp" answer there.
            queryClient.invalidateQueries({ queryKey: ["loggedInUser"] });
            navigate("/onboarding/activity1");
          },
        });
      }}>
      {(props) => (
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8 card">
            <Form className="card-body my-5 mx-3">
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="firstName">Brief description of your business</label>
                  <Field name="businessDescription" as="textarea" className={"form-control"} />
                  <ErrorMessage
                    component="div"
                    className="text-danger"
                    name="businessDescription"
                  />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-6">
                  <label htmlFor="accelerator">Accelerator Program</label>
                  <Field
                    name="accelerator"
                    as="select"
                    className={"form-control form-select"}
                    onChange={(e: any) => {
                      props.setFieldValue("accelerator", e.target.value);
                    }}>
                    <option key="emptyValue" value={""}>
                      ----
                    </option>
                    {renderAcceleratorProgramList()}
                  </Field>
                  <ErrorMessage component="div" className="text-danger mt-1" name="batch" />
                </div>
                {props.values.accelerator === "Y Combinator" && (
                  <div className="col-12 col-md-6">
                    <label htmlFor="batch">Batch</label>
                    <Field
                      name="batch"
                      as="select"
                      className={"form-control form-select"}
                      onChange={(e: any) => {
                        props.setFieldValue("batch", e.target.value);
                      }}>
                      {renderBatchList()}
                    </Field>
                    <ErrorMessage component="div" className="text-danger mt-1" name="batch" />
                  </div>
                )}
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="teamSize">Team Size</label>
                  <Field name="teamSize" type="number" className={"form-control"} />
                  <ErrorMessage component="div" className="text-danger mt-1" name="teamSize" />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div
                  className={props.values.howHeard === "Other"
                    ? "col-12 col-md-6" : "col-12 col-md-12"}>
                  <label htmlFor="howHeard">How did you hear about us?</label>
                  <Field
                    name="howHeard"
                    as="select"
                    className={"form-control form-select"}
                    onChange={(e: any) => {
                      props.setFieldValue("howHeard", e.target.value);
                    }}>
                    <option key="emptyValue" value={""}>
                      ----
                    </option>
                    {renderHowHeardList()}
                  </Field>
                  <ErrorMessage component="div" className="text-danger" name="howHeard" />
                </div>
                {props.values.howHeard === "Other" && (
                  <div className="col-12 col-md-6">
                    <label htmlFor="howHeardOther">Other</label>
                    <Field name="howHeardOther" type="text" className={"form-control"} />
                    <ErrorMessage
                      component="div"
                      className="text-danger"
                      name="howHeardOther"
                    />
                  </div>
                )}
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="incorporationDate">Date of Incorporation</label>
                  <br />
                  <Field
                    name="incorporationDate"
                    type="text"
                    component={DatePicker}
                    className={"form-control"}
                  />
                  <ErrorMessage
                    component="div"
                    className="text-danger"
                    name="incorporationDate"
                  />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="deCCorp">Delaware C-Corp</label>
                  <br />
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="deCCorp"
                      value="Yes"
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>Yes</label>
                  </div>
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="deCCorp"
                      value="No"
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>No</label>
                  </div>
                  <ErrorMessage component="div" className="text-danger" name="deCCorp" />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="interestedInRnDCredit">Interested in R&D Credit for 2024</label>
                  <br />
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="interestedInRnDCredit"
                      value="Yes"
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>Yes</label>
                  </div>
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="interestedInRnDCredit"
                      value="No"
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>No</label>
                  </div>
                  <ErrorMessage
                    component="div"
                    className="text-danger"
                    name="interestedInRnDCredit"
                  />
                </div>
              </div>
              {/* {Object.keys(props.errors).map(k
                                   => (<span>{k} - {(props.errors as any)[k]}</span>))} */}
              <hr className="row my-5" />
              <div className="row mb-3 mt-2">
                <div className="d-flex flex-row col-12 col-md-12 justify-content-between ">
                  <button
                    onClick={() => navigate("/onboarding/company1")}
                    className="btn btn-lg btn-secondary">
                    <div>Go Back</div>
                  </button>
                  <button className="btn btn-lg btn-primary" type="submit">
                    {companyMutation.isLoading ? <Spinner /> : <div>Continue</div>}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
}
