import { FieldTypes } from "../forms/formik-components/formTypes";

export enum PassportFieldTypes {
    TEXT = "TEXT",
    DATE = "DATE",
    CURRENCY_AMOUNT = "CURR",
    NUMBER = "NUMB",
    URL = "URL",
    ENC_TEXT = "ENCT",
}

export const PassportFieldTypesDisplayMap: { [key in PassportFieldTypes]: string } = {
  [PassportFieldTypes.TEXT]: "Text",
  [PassportFieldTypes.DATE]: "Date",
  [PassportFieldTypes.CURRENCY_AMOUNT]: "Currency Amount",
  [PassportFieldTypes.NUMBER]: "Number",
  [PassportFieldTypes.URL]: "URL",
  [PassportFieldTypes.ENC_TEXT]: "Encrypted Text",
};

export const PassportFieldTypesToFondoFormGroupTypes: { [key in PassportFieldTypes]: FieldTypes } = {
  [PassportFieldTypes.TEXT]: FieldTypes.TEXT,
  [PassportFieldTypes.DATE]: FieldTypes.DATE,
  [PassportFieldTypes.CURRENCY_AMOUNT]: FieldTypes.CURRENCY,
  [PassportFieldTypes.NUMBER]: FieldTypes.INTEGER,
  [PassportFieldTypes.URL]: FieldTypes.TEXT,
  [PassportFieldTypes.ENC_TEXT]: FieldTypes.ENCRYPTED_TEXT,
};

export type PassportField = {
    fieldName: string;
    displayName: string;
    value: string;
    fieldType: PassportFieldTypes;
    isAddressField: boolean;
    isCustomField: boolean;
};
