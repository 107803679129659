import { Card } from "react-bootstrap";
import CashBalancePerAccountTable from "./CashBalancePerAccountTable";
import { CashBalanceChartData } from "../finance-dashboard/financeDashboard.type";
import RunwayAuditToolTable from "./RunwayAuditToolTable";

type CashBalancePerAccountProps = {
  accountsData: CashBalanceChartData;
  allowEditingBalances?: boolean;
  month?: string;
}

const CashBalancePerAccount = (
  {
    accountsData,
    allowEditingBalances = false,
    month = "",
  }: CashBalancePerAccountProps,
) => (
  <Card className="rounded-0 rounded-bottom border-top-0">
    <Card.Body className="p-0">
      <div className="p-4">
        <p className="text-secondary">
          {`This amount includes your current (as of date stated next to Cash Balance)
          bank balances across all of your bank accounts connected to your finance dashboard.`}
        </p>
        <p className="text-secondary mb-0">
          <strong>Please note:</strong> {`these amounts may appear different from your 
          bank statements due to data integration issues, timing differences, 
          transfers between accounts that are currently in-flight, or manual changes. 
          If something does not look right to you, please contact us at team@tryfondo.com 
          so we can address your concerns.`}
        </p>
      </div>
      {allowEditingBalances && month
        ? (
          <RunwayAuditToolTable accountsData={accountsData} month={month}/>
        ) : (
          <CashBalancePerAccountTable accountsData={accountsData} />
        )
      }
    </Card.Body>
  </Card>
);

export default CashBalancePerAccount;
