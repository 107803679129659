import { Form, Table } from "react-bootstrap";
import { Transaction, TransactionOrdering } from "../financeDashboard.type";
import SortChevronButton from "../SortChevronButton";
import TransactionRow from "./TransactionRow";

type TransactionTableProps = {
  transactions: Transaction[];
  currentOrdering: string;
  setOrdering: (ordering: TransactionOrdering) => void;
  transactionsToCategorize: string[];
  setTransactionUuids: (uuids: string[]) => void;
  selectAllRows: boolean;
  handleSelectAllTransactionsInPage: (checked: boolean) => void;
  allowUseOfAIToCategorizeTransactions: boolean;
}

const TransactionsTable = (
  {
    transactions,
    currentOrdering,
    setOrdering,
    transactionsToCategorize,
    setTransactionUuids,
    selectAllRows,
    handleSelectAllTransactionsInPage,
    allowUseOfAIToCategorizeTransactions = false,
  }: TransactionTableProps,
) => (
  <Table className='table no-wrap mb-0' size="sm">
    <thead>
      <tr>
        <th className="pe-0 text-center">
        <Form.Check
          id="select-all-transactions-check"
          type="checkbox"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleSelectAllTransactionsInPage(event.target.checked);
          }}
          checked={selectAllRows}
        />
        </th>
        <th>
          <span className='d-flex justify-content-between'>
            Date
            <SortChevronButton
              sortName={TransactionOrdering.DATE}
              currentSort={currentOrdering}
              handleSort={(newSortBy: string) => setOrdering(newSortBy as TransactionOrdering)}
            />
          </span>
        </th>
        <th>
          <span className='d-flex justify-content-between'>
            Amount
            <SortChevronButton
              sortName={TransactionOrdering.AMOUNT}
              currentSort={currentOrdering}
              handleSort={(newSortBy: string) => setOrdering(newSortBy as TransactionOrdering)}
            />
          </span>
        </th>
        <th>
          <span className='d-flex justify-content-between'>
            Source
            <SortChevronButton
              sortName={TransactionOrdering.SOURCE}
              currentSort={currentOrdering}
              handleSort={(newSortBy: string) => setOrdering(newSortBy as TransactionOrdering)}
            />
          </span>
        </th>
        <th>Description</th>
        <th>
          <span className='d-flex justify-content-between'>
            Type
            <SortChevronButton
              sortName={TransactionOrdering.CATEGORY}
              currentSort={currentOrdering}
              handleSort={(newSortBy: string) => setOrdering(newSortBy as TransactionOrdering)}
            />
          </span>
        </th>
        {allowUseOfAIToCategorizeTransactions && (
          <th>
            <span className='d-flex justify-content-between'>
              AI Verification Status
              <SortChevronButton
                sortName={TransactionOrdering.AI_VERIFICATION_STATUS}
                currentSort={currentOrdering}
                handleSort={(newSortBy: string) => setOrdering(newSortBy as TransactionOrdering)}
              />
            </span>
          </th>
        )}
      </tr>
    </thead>
    <tbody>
      {transactions?.map((transaction: Transaction) => (
        <TransactionRow
          transaction={transaction}
          transactionsToCategorize={transactionsToCategorize}
          setTransactionUuids={setTransactionUuids}
          allowUseOfAIToCategorizeTransactions={allowUseOfAIToCategorizeTransactions}
        />
      ))}
    </tbody>
  </Table>
);

export default TransactionsTable;
