import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { formatDateToWords } from "../../utils/common.util";
import { TaxStatusType } from "./taxStatus.type";
import ProgressTracker from "../fusion-kit/ProgressTracker";
import { isProjectComplete, organizeTaxStatusSteps } from "./utils";

type TaxStatusCardProps = {
  taxStatus?: TaxStatusType;
}

const TaxStatusCard = ({ taxStatus }: TaxStatusCardProps) => (
  <Container>
    <h2>
      {taxStatus?.name}
      {taxStatus?.anyPendingActionItems && !isProjectComplete(taxStatus) && (
        <Link to={"/dashboard/action-items"}>
          <Button
            variant="danger"
            className="float-end"
            href="/dashboard/action-items"
          >
            Action Required
          </Button>
        </Link>
      )}
    </h2>

    {taxStatus?.deadline && (
      <div className="pb-2">
        <FeatherIcon icon="flag" className="pe-2" />
        <span>
          Filing deadline {formatDateToWords(taxStatus?.deadline || "")}
        </span>
      </div>
    )}
    {taxStatus?.estimatedCompletionDate && (
      <div>
        <FeatherIcon icon="clock" className="pe-2" />
        <span>
          Estimated date of completion {formatDateToWords(taxStatus?.estimatedCompletionDate || "")}
        </span>
      </div>
    )}
    <div>
      <ProgressTracker
        steps={taxStatus ? organizeTaxStatusSteps(taxStatus) : []}
        className="mt-5"
      />
    </div>
  </Container>
);

export default TaxStatusCard;
