import {
  Badge,
  Card,
  Col,
  Row,
} from "react-bootstrap";
import FondoSparkLineChart from "../../fondo-components/Charts/FondoSparkLineChart";
import { convertNumberToCurrency } from "../../../utils/utils";
import { getAmountColorClassName, getExpensesColorClassName } from "../utils";

type ProfitAndLossSparkChartsCardProps = {
  data: (number | null)[];
  title: string;
  isExpenses?: boolean;
  textOverride?: string;
}

const ProfitAndLossSparkChartsCard = ({
  data,
  title,
  isExpenses = false,
  textOverride,
}: ProfitAndLossSparkChartsCardProps) => {
  const fondoBlueColor = "#2C7AE5";

  const chartData = [{
    data,
    color: fondoBlueColor,
  }];

  const currentPeriodValue = data[data.length - 1] || 0;
  const previousPeriodValue = data[data.length - 2] || 0;

  const getPercentageChangedComparedToPreviousPeriod = () => {
    const percentageChanged = (currentPeriodValue - previousPeriodValue) / (Math.abs(previousPeriodValue));
    return percentageChanged * 100;
  };

  const getColorClassNameForAmount = (amount: number, options: { isExpenses: boolean }) => {
    if (options.isExpenses) {
      return getExpensesColorClassName(amount);
    }
    return getAmountColorClassName(amount);
  };

  return (
    <Card className="rounded">
      <Card.Body className="py-3">
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">
              {title}
            </h6>
            {textOverride ?
              <span className="h2 mb-0">
                {textOverride}
                {!data.length && <br />}
                {!data.length && <br />}
              </span> :
              <span className={`h2 mb-0 text-${getColorClassNameForAmount(currentPeriodValue, { isExpenses })}`}>
                ${convertNumberToCurrency(currentPeriodValue)}
              </span>
            }
            {Number.isFinite(getPercentageChangedComparedToPreviousPeriod()) && (
              <Badge
                pill
                bg={`
                  ${getColorClassNameForAmount(
                  getPercentageChangedComparedToPreviousPeriod(),
                  { isExpenses },
                )}-soft
                `}
                className="ms-2 mt-n2"
                as='span'
              >
                {getPercentageChangedComparedToPreviousPeriod().toFixed(1)}%
              </Badge>
            )}
          </Col>
          <Col className="col-auto">
            <div>
              <FondoSparkLineChart chartData={chartData} customWidth={!data.length ? "1px" : ""} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProfitAndLossSparkChartsCard;
