import { Button } from "react-bootstrap";
import { useState } from "react";
import { defaultBalanceSheetOptions, BalanceSheetOptions, ReportingType } from "../Reporting.type";
import QBOTable from "../QBOTable/QBOTable";
import LiabilitiesAndEquityCard from "../../finance-dashboard/balance-sheet/LiabilitiesAndEquityCard";
import { formatCurrency } from "../../../utils/common.util";
import ReportingOptionsModal from "./ReportingOptionsModal";
import { formatPeriodInHumanReadableText } from "../utils";

type ReportingBalanceSheetProps = {
  report: ReportingType;
}


const ReportingBalanceSheet = ({ report }: ReportingBalanceSheetProps) => {
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [options, setOptions] = useState<BalanceSheetOptions>(
    { ...defaultBalanceSheetOptions, ...report.balanceSheetOptions }
  );
  const qboReportingData = report.reportingData.balanceSheet;
  
  const selectedMonthWords = formatPeriodInHumanReadableText(report);

  const assets = qboReportingData.results.find((item) => item.baseName?.toLocaleLowerCase() === "assets")?.amount?.[0];
  const liabilitiesAndEquity = qboReportingData.results.find(
    (item) => item.baseName?.toLocaleLowerCase() === "liabilities and equity")?.amount?.[0];


  let descriptionContent = null;


  if (options?.description?.enabled) {
    if (options.description.text?.trim()) {
      descriptionContent = options.description.text;
    } else {
      descriptionContent = `Below is a breakdown of your balance sheet for ${selectedMonthWords}`;
    }
  }
    return (
    <>
      <div className="d-flex justify-content-between">
        <h2>Balance Sheet</h2>
        <div>
          {<Button size="sm" className="px-4" onClick={() => setShowOptionsModal(true)}>Edit</Button>}
        </div>
      </div> 
      <p className="text-secondary">{descriptionContent}</p>
      {options.liabilitiesAndEquity && (
        <LiabilitiesAndEquityCard
          assets={formatCurrency(assets || "0")}
          liabilitiesAndEquity={formatCurrency(liabilitiesAndEquity || "0")}
        />
      )}
      {options.table && (
        <QBOTable tableName={"Balance Sheet"} qboReportingData={qboReportingData} isBalanceSheet={true}/>
      )}
      <ReportingOptionsModal
        sectionType = "balanceSheet"
        show={showOptionsModal}
        onHide={() => setShowOptionsModal(false)}
        initialOptions={options}
        reportUuid={report.uuid}
        onUpdate={(newOptions) => {
          setOptions(newOptions as BalanceSheetOptions);
        }}
      />
      <div id="balance-sheet-data-loaded" />
    </>
  )
}

export default ReportingBalanceSheet;
