import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import {
  ForeignBankAccountFormFields,
  foreignBankAccountsFieldToLabelMap,
  foreignBankAccountsToTypeMap,
} from "../corporateTax.type";
import AddressFieldGroup from "../../forms/formik-components/AddressFieldGroup";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import { currencySelectorChoices } from "../../foreign-subsidiary/utils";

type AddForeignBankAccountModalProps = {
  show: boolean;
  handleClose: () => void;
  foreignBankAccount?: ForeignBankAccountFormFields;
  header: string;
  handleSave: (officer: ForeignBankAccountFormFields) => void;
};

export default function AddForeignBankAccountModal(props: AddForeignBankAccountModalProps) {
  const initialValues = props.foreignBankAccount || {
    bankName: "",
    type: "",
    accountNumber: "",
    country: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    currency: "",
    maxValueDuringYear: 0,
    dateOfMaxValue: "",
    openedDuringYear: "",
    closedDuringYear: "",
  };

  return (
    <Modal show={props.show} onHide={() => props.handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
            })}
            onSubmit={(values) => {
              props.handleSave(values);
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="bankName"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="type"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="accountNumber"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <AddressFieldGroup
                  formikContext={formik}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="currency"
                  selectorOptions={currencySelectorChoices}
                  includeSelectorEmptyValue={true}
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="maxValueDuringYear"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="dateOfMaxValue"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="openedDuringYear"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="closedDuringYear"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <div className="row mb-3 mt-2">
                  <div className="d-flex flex-row col-12 col-md-12 justify-content-end">
                    <button className="btn btn-lg btn-primary" type="submit"><div>Save</div></button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
