import {
  Nav, Col, Button, Badge,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link, useLocation } from "react-router-dom";
// import HowToLink from "./FondoComponents/HowToLink";
// import { HowToLinkProps } from "../utils/howToPagesList";
// import CustomerDrawer from "./General/CustomerDrawer";

type ITab = {
    name: string;
    url: string;
    badgeInfo?: number | string;
};

export interface FondoLayoutProps {
  title: string | React.ReactNode;
  pretitle?: string | React.ReactNode;
  pretitleHTML?: React.ReactNode;
  children: React.ReactNode;
  actionButton?: boolean;
  actionButtonText?: string;
  actionButtonIcon?: string;
  actionButtonColor?:
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | "danger"
  | "info"
  | "light"
  | "dark"
  | "link";
  badge?: boolean;
  badgeText?: string|number;
  badgeColor?:
  | "primary"
  | "primary-soft"
  | "secondary"
  | "secondary-soft"
  | "success"
  | "success-soft"
  | "warning"
  | "warning-soft"
  | "danger"
  | "danger-soft"
  | "info"
  | "info-soft"
  | "light"
  | "dark"
  | "link";
  handleActionButtonClick?: () => any;
  tabs?: ITab[];
  actionTabItems?: any[];
  rightFloatJsx?: React.ReactNode;
  documentTitle?: string;
  centerContent?: boolean;
  maxWidth?: string;
}

const FondoLayout = ({
  title,
  pretitle,
  pretitleHTML,
  children,
  actionButton,
  actionButtonText,
  actionButtonIcon,
  actionButtonColor = "primary",
  badge,
  badgeText,
  badgeColor = "primary",
  handleActionButtonClick,
  tabs,
  actionTabItems = [],
  rightFloatJsx,
  documentTitle = "",
  centerContent,
  maxWidth,
}: FondoLayoutProps) => {
  if (typeof title === "string" && !documentTitle) documentTitle = title;
  document.title = `Fondo · ${documentTitle}`;
  const { pathname } = useLocation();

  const customStyle: { [key: string]: string } = {};
  if (maxWidth) customStyle.maxWidth = maxWidth;
  if (centerContent) customStyle.margin = "auto";

  return (
    <div className='px-3 py-5 px-md-5 py-md-4' style={customStyle}>
      <Nav variant='tabs' className='mb-5 nav-tabs-sm justify-content-between'>
        <Col>
          {pretitle && !pretitleHTML && (
            <h6 className='header-pretitle'>{pretitle}</h6>
          )}
          {pretitleHTML && pretitleHTML}
          <h1>
            {title}
            {badge && (
              <Badge pill bg={badgeColor} className="ms-2 fs-4" as='span'>
                {badgeText}
              </Badge>
            )}
          </h1>
        </Col>
        {rightFloatJsx}
        {actionButton && actionButtonText ? (
          <Button
            variant={actionButtonColor}
            size='sm'
            onClick={handleActionButtonClick}
            style={{ height: "2.5em" }}
          >
            {actionButtonIcon && (
              <FeatherIcon icon={actionButtonIcon} size='17' className='me-3' />
            )}
            {actionButtonText}
          </Button>
        ) : (
          tabs?.length && (
            <div className='d-flex'>
              {tabs.map((tab: ITab, idx: number) => (
                  <Nav.Item key={`${tab.name}-${idx}`}>
                    <Nav.Link
                      as={Link}
                      to={tab.url}
                      active={pathname.endsWith(tab.url)}
                    >
                      <span>{tab.name}</span>
                      {(tab.badgeInfo || Number.isInteger(tab.badgeInfo)) && (
                        <Badge bg='secondary-soft' className='ms-2'>
                          {tab.badgeInfo}
                        </Badge>
                      )}
                    </Nav.Link>
                  </Nav.Item>
              ))}
              <div className="border-start ps-2 h-75">
                {actionTabItems.length > 0 && actionTabItems.map((actionTab: ITab, idx: number) => (
                  <Nav.Item key={`${actionTab.name}-${idx}`}>
                    <Nav.Link
                      as={Link}
                      to={actionTab.url}
                      active={pathname.endsWith(actionTab.url)}
                    >
                      <span>{actionTab.name}</span>
                      {(actionTab.badgeInfo || Number.isInteger(actionTab.badgeInfo)) && (
                        <Badge bg='secondary-soft' className='ms-2'>
                          {actionTab.badgeInfo}
                        </Badge>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </div>
            </div>
          )
        )}
        {/* <CustomerDrawer /> */}
      </Nav>
      <div>{children}</div>
    </div>
  );
};

export default FondoLayout;
