import { Card, Spinner } from "react-bootstrap";

const LoadingFundingHistoryChartCard = () => (
  <Card className="mb-0">
    <div className="ps-4 pt-4">
      <Card.Title className="h5 text-uppercase text-muted mb-2">
        Funding Over Date Range
      </Card.Title>
    </div>
    <Card.Body>
      <Spinner variant="primary" animation="border" role="status" />
    </Card.Body>
  </Card>
);

export default LoadingFundingHistoryChartCard;
