const SVGBankIcon = () => (
  <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.8802 11.7771C28.9625 11.7771 29.4144 10.4098 28.5352 9.77951L15.1584 0.211811C14.9674 0.0741919 14.7367 0 14.4999 0C14.2631 0 14.0324 0.0741919 13.8414 0.211811L0.464616 9.77951C-0.41456 10.4063 0.0372874 11.7771 1.12312 11.7771H3.29129V24.658H0.76935C0.615232 24.658 0.489135 24.782 0.489135 24.9335V26.7245C0.489135 26.876 0.615232 27 0.76935 27H28.2305C28.3846 27 28.5107 26.876 28.5107 26.7245V24.9335C28.5107 24.782 28.3846 24.658 28.2305 24.658H25.7085V11.7771H27.8802ZM14.4999 2.6399L23.9957 9.43166H5.00411L14.4999 2.6399ZM5.81323 11.7771H9.91138V24.658H5.81323V11.7771ZM12.4333 11.7771H16.5315V24.658H12.4333V11.7771ZM23.1866 24.658H19.0534V11.7771H23.1866V24.658Z" // eslint-disable-line
      fill="#7C91B6"
    />
  </svg>
);

export default SVGBankIcon;
