import { ASK_REPORTING_AI_ASSISTANT, REPORTINGS } from "../../../../constants/network-calls";
import { postFondoApi } from "../../../../utils/util.api";
import { AskReportingAIChatBotDataJobResponse, ReportingAIChatBotData } from "./reportingAIChatBot.type";

export const askReportingAIAssistant = async (requestData: ReportingAIChatBotData) => {
  const result = await postFondoApi(
    `${REPORTINGS}${requestData.reportingUuid}${ASK_REPORTING_AI_ASSISTANT}`,
    {
      question: requestData.question,
      conversationHistory: requestData.conversationHistory,
    },
  );
  return result as AskReportingAIChatBotDataJobResponse;
};
