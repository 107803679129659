import { useContext } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

import {
  DASHBOARD_ONBOARDING_STEPS,
  DASHBOARD_ONBOARDING_TIMELINE_ITEMS,
  SESSION_COOKIE_NAME,
} from "../../constants/general";

import { cookies } from "../App";
import { ProgressTimeLineContext } from "../../context/ProgressTimelineContext";
import ProgressTimeline from "../fusion-kit/ProgressTimeline";
import {
  DASHBOARD,
  DASHBOARD_ONBOARDING,
  DASHBOARD_ONBOARDING_COMPANY,
  DASHBOARD_ONBOARDING_USER,
} from "../../constants/network-calls";
import "./dashboardOnboarding.css";
import { getFlowTracking } from "../onboarding/onboarding.api";
import { getNext } from "../../utils/common.util";

const DashboardOnboarding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentStep } = useContext(ProgressTimeLineContext);

  if (location.pathname === DASHBOARD_ONBOARDING) {
    const sessionCookie = cookies.get(SESSION_COOKIE_NAME);
    if (sessionCookie) {
      getFlowTracking("dashboard-onboarding")
        .then((res) => {
          if (res?.length) {
            const next = getNext(DASHBOARD_ONBOARDING_STEPS, res[res.length - 1]);
            if (next) navigate(next);
            else navigate(DASHBOARD);
          } else navigate(DASHBOARD_ONBOARDING_COMPANY);
        });
    } else navigate(DASHBOARD_ONBOARDING_USER);
  }

  return (
    <div
      className="dashboard-onboarding-gradient containter-fluid"
      style={{ height: "100vh", overflow: "auto" }}
    >
      <ProgressTimeline
        currentStep={currentStep}
        hideTimeline={false}
        timeLineItems={DASHBOARD_ONBOARDING_TIMELINE_ITEMS}
        inlineStep={true}
        formatCompletedSteps={true}
      />
      <Outlet />
    </div>
  );
};

export default DashboardOnboarding;
