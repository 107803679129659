import { Card, Spinner } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

const LoadingNetIncomeBreakdownCard = () => (
  <Card>
    <Card.Body className="p-0">
      <div className="border-bottom p-4 pb-3">
        <Card.Title className="text-uppercase text-muted mb-2">
          <span className="h5 text-secondary">Net Income </span>
        </Card.Title>
        <Spinner variant="primary" animation="border" role="status" />
      </div>

      <div className="p-4">
        <div className="d-flex justify-content-between">
          <span>
            <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
            Income
          </span>
          <span>
            <Spinner variant="primary" animation="border" role="status" />
          </span>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <span>
            <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
            Expenses
          </span>
          <span>
            <Spinner variant="primary" animation="border" role="status" />
          </span>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <strong>
            Net Income
          </strong>
          <span>
            <Spinner variant="primary" animation="border" role="status" />
          </span>
        </div>

      </div>
    </Card.Body>
  </Card>
);

export default LoadingNetIncomeBreakdownCard;
