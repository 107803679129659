import { Camelized } from "humps";
import { useEffect, useState } from "react";
import {
  Col,
  ListGroup,
  Nav,
  Row,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

interface PaginationProps {
  currentPageNumber: number;
  currentSearch?: string;
  currentDateRange?: string[];
  numberOfPages: number;
  isLoading: boolean;
  numberOfPagesBeforeEllipsis: number;
  currentData: Camelized<any> | undefined;
  handlePageChange: (pageNumber: number) => void;
  manageUrl?: boolean;
}

const Pagination = ({
  currentPageNumber,
  currentSearch,
  currentDateRange,
  numberOfPages,
  isLoading,
  numberOfPagesBeforeEllipsis,
  currentData,
  handlePageChange,
  manageUrl = true,
}: PaginationProps) => {
  const pageNumber = currentPageNumber || 1;
  const [totalPages, setTotalPages] = useState(0);
  const [allPagesArray, setAllPagesArray] = useState([0]);
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [currentPaginationRange, setCurrentPaginationRange] = useState(
    Math.ceil(pageNumber / numberOfPagesBeforeEllipsis),
  );
  const [currentPaginationRangeArray, setCurrentPaginationRangeArray] = useState([0]);
  const [data, setData] = useState(currentData);

  useEffect(() => {
    if (!isLoading) {
      setData(currentData);
      setTotalPages(numberOfPages);
      setAllPagesArray(Array.from(Array(numberOfPages).keys()));
      setCurrentPaginationRangeArray(
        paginate(
          allPagesArray,
          Math.ceil(currentPageNumber / numberOfPagesBeforeEllipsis),
        ),
      );
      pageChange(currentPageNumber);
    }
  }, [currentSearch, isLoading, totalPages, numberOfPages, currentDateRange]);

  const paginate = (array: number[], range: number) => array.slice(
    (range - 1) * numberOfPagesBeforeEllipsis,
    range * numberOfPagesBeforeEllipsis,
  );

  const changeUrlPaginationQueryParam = (newPageNumber: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", `${newPageNumber}`);
    window.history.replaceState(null, document.title, url);
  };

  const pageChange = (newPageNumber: number) => {
    handlePageChange(newPageNumber);
    setCurrentPage(newPageNumber);
    if (manageUrl) {
      changeUrlPaginationQueryParam(newPageNumber);
    }
    setCurrentPaginationRangeArray(
      paginate(
        allPagesArray,
        Math.ceil(newPageNumber / numberOfPagesBeforeEllipsis),
      ),
    );
    setCurrentPaginationRange(
      Math.ceil(newPageNumber / numberOfPagesBeforeEllipsis),
    );
  };

  const onPageClick = (newPageNumber: number) => {
    pageChange(newPageNumber);
  };

  const onPreviousClick = () => {
    const newCurrentPage = Math.max(currentPage - 1, 1);
    pageChange(newCurrentPage);
  };

  const onNextClick = () => {
    if (data?.next) {
      const newCurrentPage = currentPage + 1;
      pageChange(newCurrentPage);
    }
  };

  const showNextRange = () => {
    const newRange = currentPaginationRange + 1;
    const nextPageRangeStart = (newRange - 1) * numberOfPagesBeforeEllipsis + 1;
    setCurrentPaginationRange(newRange);
    setCurrentPaginationRangeArray(paginate(allPagesArray, newRange));
    pageChange(nextPageRangeStart);
  };

  const showPreviousRange = () => {
    const newRange = currentPaginationRange - 1;
    const previousPageRangeStart = Math.max((newRange - 1) * numberOfPagesBeforeEllipsis + 1, 1);
    setCurrentPaginationRange(newRange);
    setCurrentPaginationRangeArray(paginate(allPagesArray, newRange));
    pageChange(previousPageRangeStart);
  };

  return (
    <>
      {totalPages > 1 && (
        <ListGroup className="p-0">
          <Row className="px-3">
            <Col
              role="button"
              onClick={onPreviousClick}
              className="border-end d-flex justify-content-center align-items-center"
            >
              <span className={`${currentPage === 1 && "text-muted"} align-middle`}>
                <FeatherIcon icon="arrow-left" size="17" className={`me-2 ${currentPage === 1 && "text-muted"}`} />
                Prev
              </span>
            </Col>
            <Col className="col-xl-9 col-lg-8 col-6">
              <Nav variant="tabs" className="nav-tabs justify-content-center border-bottom-0">
                {currentPaginationRangeArray[0] !== 0 && (
                  <Nav.Item className="mx-0">
                    <Nav.Link
                      onClick={showPreviousRange}
                    >
                      <div className="text-center" style={{
                        width: "25px", height: "25px", flexGrow: "0", flexShrink: "0",
                      }}>
                        ...
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                )}
                {currentPaginationRangeArray.map((paginationPage) => (
                  <>
                    <Nav.Item key={paginationPage + 1} className="mx-0">
                      <Nav.Link
                        active={paginationPage + 1 === currentPage}
                        onClick={() => onPageClick(paginationPage + 1)}
                        className="px-2"
                      >
                        <div className="text-center" style={{
                          width: "25px", height: "25px", flexGrow: "0", flexShrink: "0",
                        }}>
                          {paginationPage + 1}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </>
                ))}
                {currentPaginationRangeArray[currentPaginationRangeArray.length - 1]
                  + 1 !== totalPages
                  && (
                    <Nav.Item className="mx-0">
                      <Nav.Link
                        onClick={showNextRange}
                      >
                        <div className="text-center" style={{
                          width: "25px", height: "25px", flexGrow: "0", flexShrink: "0",
                        }}>
                          ...
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  )
                }
              </Nav>
            </Col>
            <Col
              role="button"
              onClick={onNextClick}
              className="border-start d-flex justify-content-center align-items-center"
            >
              <span className={`${!currentData?.next && "text-muted"} align-middle`}>
                Next
              </span>
              <FeatherIcon
                icon="arrow-right"
                size="17"
                className={`ms-2 ${!currentData?.next && "text-muted"}`}
              />
            </Col>
          </Row>
        </ListGroup>
      )}
    </>
  );
};

export default Pagination;
