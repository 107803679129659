import { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { AxiosError } from "axios";
import { refreshBalances } from "./FinanceDashboard.api";
import RealTimeProgressManagerStatusCard from "../fusion-kit/RealTimeProgressManagerStatus";
import { ProgressManagerStatus } from "../../common/type";
import { REACTIFY_API, REFRESH_BALANCES } from "../../constants/network-calls";

const RefreshBalancesCard = () => {
  const [itemsToRefresh, setItemsToRefresh] = useState<number | null>();
  const [verifyRefreshBalanceProgress, setVerifyRefreshBalanceProgress] = useState(true);
  const [enableRefreshButton, setEnableRefreshButton] = useState(true);

  useEffect(() => {
    if (verifyRefreshBalanceProgress) setEnableRefreshButton(false);
    else setEnableRefreshButton(true);
  }, [verifyRefreshBalanceProgress]);

  const refreshBalancesMutation = useMutation<any, AxiosError<any>, any>(refreshBalances);

  const handleRefreshBalances = () => {
    setEnableRefreshButton(false);
    refreshBalancesMutation.mutate(
      null,
      {
        onSuccess: (data: ProgressManagerStatus) => {
          setVerifyRefreshBalanceProgress(true);
          setItemsToRefresh(data?.elementsToProcess);
        },
      },
    );
  };

  if (verifyRefreshBalanceProgress) {
    return (
      <RealTimeProgressManagerStatusCard
        processingTitle={"Refreshing Balances..."}
        elementsToProcessText={"Banks to refresh balance"}
        numberOfElementsToProcess={itemsToRefresh}
        elementsProcessedText={"Banks with balances refreshed"}
        verifyProgress={verifyRefreshBalanceProgress}
        setVerifyProgress={setVerifyRefreshBalanceProgress}
        progressStatusURL={`${REACTIFY_API}${REFRESH_BALANCES}`}
        queriesToRefresh={["currentBalance"]}
      />
    );
  }

  return (
    <Button
      onClick={handleRefreshBalances}
      className="mx-2"
      size='sm'
      disabled={!!verifyRefreshBalanceProgress || !enableRefreshButton}
      variant ='white'
    >
      <span>
        {enableRefreshButton ? "Refresh Balance" : "Refreshing balances..."}
      </span>
      <FeatherIcon
        className="ms-2 mt-n1"
        icon="refresh-ccw"
        size="1.2em"
      />
    </Button>
  );
};

export default RefreshBalancesCard;
