import { useQuery } from "@tanstack/react-query";
import { Country } from "../../../utils/util.type";
import { getCountries } from "../../../utils/util.api";

type DisplayCountryNameProps = {
  countryCode: string;
}

const DisplayCountryName = ({ countryCode }: DisplayCountryNameProps) => {
  const countryQuery = useQuery<Country[], Error>(["country"], getCountries, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (!countryQuery.data) {
    return <></>;
  }

  return (
    <>
      {countryQuery.data.find((country) => country.alpha2 === countryCode)?.name}
    </>
  );
};

export default DisplayCountryName;
