import { useQuery } from "@tanstack/react-query";
import { getHistoricalFunding } from "../FinanceDashboard.api";
import { FundingData } from "../financeDashboard.type";
import FundingHistoryChartCard from "./FundingHistoryChartCard";
import CashBalanceCard from "../../runway/CashBalanceCard";
import AssetsAndLiabilitiesBreakdownCard from "./AssetsAndLiabilitiesBreakdownCard";
import BlurWrapper from "../../fondo-components/BlurWrapper";
import { getVerifyTransactionsOverlay } from "../verify-transactions/VerifyTransactionsOverlay";
import { ConditionalWrapper } from "../../../utils/common.util";
import LoadingFundingHistoryChartCard from "../profit-and-loss/LoadingFundingHistoryChartCard";

type BalanceSheetProps = {
    selectedMonth: string;
    interval: string;
    hasTransactionsToVerify: boolean;
}

const BalanceSheet = ({ selectedMonth, hasTransactionsToVerify, interval }: BalanceSheetProps) => {
  const { data: fundingData, isLoading: fundingDataIsLoading } = useQuery<FundingData, Error>(
    ["fundingData", selectedMonth, interval],
    () => getHistoricalFunding(selectedMonth, interval),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedMonth,
    },
  );

  return (
    <div className="mt-4">
      <div className="my-4">
        <CashBalanceCard
          month={selectedMonth}
          showingCurrentMonthBalanceOnly={false}
          disabledState={!fundingData?.amountOfAccounts}
        />
      </div>

      <ConditionalWrapper
        condition={hasTransactionsToVerify}
        wrapper={(children: React.ReactNode) => (
          <BlurWrapper overlayNode={getVerifyTransactionsOverlay()}>
            {children}
          </BlurWrapper>
        )}
      >
        <AssetsAndLiabilitiesBreakdownCard selectedMonth={selectedMonth} />
      </ConditionalWrapper>

      <ConditionalWrapper
        condition={hasTransactionsToVerify}
        wrapper={(children: React.ReactNode) => (
          <BlurWrapper overlayNode={getVerifyTransactionsOverlay()}>
            {children}
          </BlurWrapper>
        )}
      >
        {fundingDataIsLoading ? (
          <LoadingFundingHistoryChartCard />
        ) : (
          <>
            {fundingData && (
              <FundingHistoryChartCard fundingData={fundingData} />
            )}
          </>
        )}
      </ConditionalWrapper>

    </div>
  );
};

export default BalanceSheet;
