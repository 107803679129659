import {
  Col,
  Row,
  Button,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { parseMonthAndYearDateToWords } from "./utils";

type TimeRangeFixedDateProps = {
  selectedMonth: string;
};

const TimeRangeFixedDate = ({
  selectedMonth,
}: TimeRangeFixedDateProps) => (
  <Row className="my-3">
    <Col>
      <div className="d-flex align-items-center">
        <Button variant='white' size='sm' style={{ cursor: "default" }}>
          <FeatherIcon
            icon="calendar"
            size="1.3em"
            className="text-secondary"
            style={{ marginBottom: "3px" }}
          />
          <span className="ms-2 fw-bold">
            {parseMonthAndYearDateToWords(selectedMonth)}
          </span>
        </Button>
      </div>
    </Col>
  </Row>
);

export default TimeRangeFixedDate;
