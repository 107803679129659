import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ActionItemsService } from "./actionItems.type";
import {
  calculatePercentageOfActionItemServiceCompletion, isActionItemCompleted, isActionItemNotStarted,
} from "./utils";
import CircleProgress from "../fondo-components/CircleProgress/CircleProgress";
import greenCheckCircle from "../../assets/img/icons/greenCheckCircle.png";

export type ActionItemListViewCardProps = {
  actionItemsService: ActionItemsService;
  index: number;
}

const ActionItemListViewCard = ({ actionItemsService, index }: ActionItemListViewCardProps) => {
  const renderCardIcon = () => {
    if (isActionItemNotStarted(actionItemsService)) {
      return <CircleProgress centerText={(index + 1).toString()} value={0} notStarted={true} />;
    }
    if (isActionItemCompleted(actionItemsService)) {
      return <img src={greenCheckCircle} style={{ width: "60px", height: "60px" }} />;
    }
    return (
      <CircleProgress
        centerText={(index + 1).toString()}
        value={calculatePercentageOfActionItemServiceCompletion(actionItemsService)}
      />
    );
  };

  const renderActionButton = () => {
    if (isActionItemNotStarted(actionItemsService)) {
      return (
        <Button variant="outline-primary" className="float-end" style={{ width: "6rem" }}>
          Start
        </Button>
      );
    }
    if (isActionItemCompleted(actionItemsService)) {
      return (
        <Button
          variant="outline-secondary"
          className="float-end"
          style={{ width: "6rem", borderColor: "#09AD3D", color: "#09AD3D" }}>
          Revisit
        </Button>
      );
    }
    return (
      <Button className="float-end" style={{ width: "6rem" }}>
        Continue
      </Button>
    );
  };

  const getActionItemStatusTextColor = () => {
    const color = isActionItemCompleted(actionItemsService) ? "#09AD3D" : "";
    return color;
  };

  const getActionItemStatusBackgroundColor = () => {
    const color = isActionItemCompleted(actionItemsService) ? "#B5E4C7" : "";
    return color;
  };

  return (
    <Card
      className="p-0 my-2"
      border={`${!isActionItemCompleted(actionItemsService)
        && !isActionItemNotStarted(actionItemsService) ? "primary" : ""}`}
      style={{
        backgroundColor: getActionItemStatusBackgroundColor(),
        borderWidth: !isActionItemCompleted(actionItemsService)
          && !isActionItemNotStarted(actionItemsService) ? "4px" : "",
      }}>
      <Card.Body className="py-3 px-4">
        <div className="d-flex align-items-center">
          <div className="me-4" style={{
            width: "60px", height: "60px", flexGrow: "0", flexShrink: "0",
          }}>
            {renderCardIcon()}
          </div>
          <div>
            <h2 className="mb-0 mt-2 mb-2" style={{ color: getActionItemStatusTextColor() }}>
              {actionItemsService?.name}
            </h2>
            <p
              className={`${!isActionItemCompleted(actionItemsService) && "text-secondary"} mb-0 pe-5`}
              style={{ color: getActionItemStatusTextColor() }}
            >
              {actionItemsService?.description}
            </p>
          </div>
          <div className="ms-auto">
            <Link to={`${actionItemsService.id}`}>
              {renderActionButton()}
            </Link>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ActionItemListViewCard;
