export const LOGOUT = "/accounts/logout/";

export const DASHBOARD = "/dashboard/";
export const DASHBOARD_ONBOARDING = "/dashboard-onboarding";

export const ACTION_ITEMS = `${DASHBOARD}action-items/`;
export const DOCUMENTS = `${DASHBOARD}documents/`;
export const REPORTING = `${DASHBOARD}reporting/`;
export const REPORTING_DETAIL = `${DASHBOARD}reporting/:uuid`;
export const INTEGRATIONS = `${DASHBOARD}integrations/`;
export const RUNWAY = `${DASHBOARD}runway/`;
export const TAX_DEADLINES = `${DASHBOARD}tax-deadlines/`;
export const CUSTOMER_ORDER = `${DASHBOARD}customer-order/`;
export const SETTINGS = `${DASHBOARD}settings/`;
export const ACH_SETTINGS = `${DASHBOARD}settings/ach/`;

// ONBOARDING
export const ONBOARDING = "/onboarding/";
export const ONBOARDING_COMPANY_2 = `${ONBOARDING}company2`;
export const ONBOARDING_NON_DE_C_CORP = `${ONBOARDING}non-delaware-c-corp`;

// PRODUCTS
export const PRODUCTS = `${DASHBOARD}products/`;
export const ACTIVE_PRODUCTS = `${PRODUCTS}active/`;
export const CLOSED_PRODUCTS = `${PRODUCTS}closed/`;

// FINANCE DASHBOARD
export const FINANCE_DASHBOARD = `${DASHBOARD}finance/`;
export const FINANCE_DASHBOARD_RUNWAY = `${FINANCE_DASHBOARD}runway/`;
export const FINANCE_DASHBOARD_P_AND_L = `${FINANCE_DASHBOARD}profit-and-loss/`;
export const FINANCE_DASHBOARD_BALANCE_SHEET = `${FINANCE_DASHBOARD}balance-sheet/`;
export const FINANCE_DASHBOARD_TAX_PLAN = `${FINANCE_DASHBOARD}tax-plan/`;
export const FINANCE_DASHBOARD_TRANSACTIONS = `${FINANCE_DASHBOARD}transactions/`;

export const DASHBOARD_ONBOARDING_USER = `${DASHBOARD_ONBOARDING}/user`;
export const DASHBOARD_ONBOARDING_COMPANY = `${DASHBOARD_ONBOARDING}/company`;
export const DASHBOARD_ONBOARDING_CONNECTIONS = `${DASHBOARD_ONBOARDING}/connections`;

export const RND = "/questionnaire/f/";
export const CORP_TAX_ONBOARDING = "/corp_tax_onboarding/";
export const CORP_TAX_ONBOARDING_2020 = "/corp_tax_onboarding/2020/";
export const DEL_FRANCHISE_TAX = "/delaware_franchise_tax/";
export const FILINGS_1099 = "/form_1099/";
export const FOREIGN_SUBSIDIARY = "/foreign_subsidiary/";

export const YC_ONBOARDING = "/yc_onboarding/";
export const YC_ONBOARDING_PRODUCTS = `${YC_ONBOARDING}products/`;

export const FRANCHISE_TAX = "/franchise_tax/";
export const FRANCHISE_TAX_FILING_INFO = `${FRANCHISE_TAX}dft/<int:file_number>/fy/<int:tax_year>/filing_info/`;
export const FRANCHISE_TAX_ONBOARDING_STATUS = `${FRANCHISE_TAX}dft/<int:file_number>/onboarding_status/`;
export const FRANCHISE_TAX_COMPANIES = `${FRANCHISE_TAX}companies/`;

export const PAYMENT = "/payment/";
export const CHARGE = `${PAYMENT}charge/`;

export const ACCOUNT = "/accounts/";
export const RESET_PASSWORD = `${ACCOUNT}password/reset/`;

export const FONDO_API = "api/";
export const USER_DETAILS = `${FONDO_API}user/`;
export const HEALTH_CHECK = `${FONDO_API}health/`;

export const CONNECT_PAYROLL = "payroll_connection/";
export const CONNECT_PAYROLL_MANUALLY = "payroll_manual_connection/";
// Same as CONNECT_PAYROLL_MANUALLY but to have a less misleading url.
export const TAX_PLAN_DATA_FORM = "/taxplan-data/";

// DROPBOX SIGN
export const DROPBOX_SIGN = `${DASHBOARD}signatures-required/`;

// PASSPORT PARTNERS
export const PASSPORT_PARTNERS = "/marketplace/partners";

// -------------------------------------- REACTIFY API --------------------------------------//

export const REACTIFY_API = "api/reactify/";
export const LOGIN = "accounts/login/";
export const SIDENAV_INFO = "sidenav-info/";
export const USER_COMPANIES = "user-companies/";
export const SIGN_UP = "onboarding/signup/";
export const TAX_YEAR_INFO = "tax-year-info/";
export const CURRENT_USER_COMPANY = "user/current-company/";
export const ONBOARDING_ACTIVITY = "onboarding/activity/";
export const ONBOARDING_TAX_PLAN = "onboarding/tax-plan/";
export const ONBOARDING_COMPANY = "onboarding/company/";
export const ONBOARDING_PRODUCT = "onboarding/product/";
export const CUSTOMER_SERVICES_REQUIRED = "company/services_required/";
export const UNPURCHASED_ACCOUNTING_SERVICES = "accounting_services/unpurchased_accounting_services/";
export const PURCHASED_ACCOUNTING_SERVICES = "accounting_services/purchased_accounting_services/";
export const GET_FLOW_TRACKING = "flow-tracking/";
export const SAVE_PAYMENT = "payment/charge/";
export const HAS_PAID_DEPOSIT = "payment/has_paid_deposit/";
export const API_RESET_PASSWORD = "rest-auth/password/reset/";
export const API_RESET_PASSWORD_CONFIRM = "rest-auth/password/reset/confirm/:uid/:token/";
export const USER = "user/";
export const LOGGED_IN_USER = "logged-in-user/";
export const COMPANY_PAYMENT_INFO = "company-payment-info/";
export const COMPANY_LOGO_IMAGE = "company_logo_image/";
export const GET_TAX_DEADLINES = "tax-deadlines/";
export const GET_FOREIGN_SUBSIDIARIES = "foreign_subsidiary/";
export const GET_FOREIGN_SUBSIDIARY_FORMS = "foreign_subsidiary_forms/";
export const API_INTEGRATION_ITEMS = "integrations/items/";
export const API_INTEGRATION_ACCOUNTS = "integrations/bank-accounts/";
export const API_INTEGRATIONS_SOURCES_LINKED_ACCOUNTS = "integrations/source_accounts/"
export const AMOUNT_OF_CONNECTED_ACCOUNTS = `${API_INTEGRATION_ACCOUNTS}amount_of_connected_accounts/`;
export const CREATE_MERGE_LINK_TOKEN = "merge-dev/link-token/";
export const RETRIEVE_MERGE_ACCOUNT_TOKEN = "merge-dev/account-token/";
export const MERGE_DEV_LINKED_ACCOUNTS = "merge-dev/linked-accounts/";
export const SYNC_LINKED_ACCOUNT = "merge-dev/sync_linked_account/";
export const FILINGS_1099_API = "filings-1099/";
export const FILINGS_1099_VENDOR = "filings-1099-vendor/";
export const RNDFORM = "rnd-form/";
export const COMPANY_LEVEL_SETTINGS = "company_level_settings/";

export const GET_TAX_STATUS = "tax-status/";
export const CASH_FLOW_STATS = "cash-flow-stats/";
export const CORP_TAX = "corp-tax/";
export const CORP_TAX_CONTEXT = `${CORP_TAX}context/`;
export const DELAWARE_FRANCHISE_TAX_CONTEXT = "delaware_franchise_tax/";
export const SIGNING_OFFICER_DUPLICATE_CHECK = `${DELAWARE_FRANCHISE_TAX_CONTEXT}signing_officer_duplicate_check/`;

export const GET_ACTION_ITEMS = "action-items/";
export const GET_ACTION_ITEMS_GENERAL_INFO = "action-items/general_info/";
export const GET_ACTION_ITEMS_USER_SERVICES = "action-items/user_services/";
export const GET_ACTION_ITEMS_SERVICE = "action-items/service/";
export const GET_ACTION_ITEM_SELECTED_INSTITUTIONS = "action-items/selected_institutions/";

export const GET_EXTERNAL_CONNECTIONS_INSTITUTIONS = "external_connections/institutions/";


export const GET_BENTO_PAYLOAD = "bento/";

export const PASSPORT = "passport/";

export const COMPANY_DASHBOARD_ONBOARDING = "dashboard-onboarding/company/";
export const CONNECTIONS_DASHBOARD_ONBOARDING = "dashboard-onboarding/connections/";
export const GET_SIGNATURE_REQUESTS = "signature_requests/";

export const API_BANKING_INTEGRATION_TOKEN_CALLBACK = "banking_integration/token/";
export const API_CREATE_FINANCIAL_HUB_INSTITUTION = "banking_integration/institution/";
export const API_RUN_QBO_OBJECT_SYNC = "qbo_api/sync/"
export const BANKING_INTEGRATION_TOKEN_CALLBACK = `${INTEGRATIONS}${API_BANKING_INTEGRATION_TOKEN_CALLBACK}`;

// -------------------------------------- FINANCIAL HUB  -------------------------------------- //
export const GET_BALANCE_AVAILABLE_MONTHS = "financial_hub/available_months/";
export const GET_BALANCE_MONTHS_FROM_FOUNDING_DATE = `${GET_BALANCE_AVAILABLE_MONTHS}incorporation_date/`;
export const GET_MONTHLY_BALANCES = "financial_hub/monthly_balances/";
export const GET_CURRENT_BALANCE = "financial_hub/balances/";
export const GET_BURN_RATE = "financial_hub/burn_rate/";
export const GET_TRANSACTIONS = "financial_hub/internal_transactions/";
export const GET_TRANSACTIONS_PAGINATED = "financial_hub/internal_transactions_paginated/";
export const GET_HISTORICAL_PROFIT_AND_LOSS = "financial_hub/profit_and_loss/";
export const GET_HISTORICAL_GROWTH_RATE = "financial_hub/growth_rate_history";
export const GET_HISTORICAL_COMPOUND_GROWTH_RATE = "financial_hub/compound_growth_rate_history";
export const GET_HISTORICAL_FUNDING = "financial_hub/funding_history/";
export const GET_QBO_BALANCE_SHEET = "financial_hub/qbo_balance_sheet_report/";
export const GET_QBO_PNL_REPORT = "financial_hub/qbo_pnl_report/";
export const GET_ENDING_BALANCES_AUDIT = "financial_hub/ending_balances_audit";
export const GET_INTERNATIONAL_ACTIVITY = "onboarding/general_data_intake/";
export const REFRESH_BALANCES = "financial_hub/refresh_balances/";
export const GET_INSTITUTIONS = "financial_hub/institutions/";
export const GET_INSTITUTION_CONNECTIONS = "financial_hub/institution_connections/";
export const FINANCIAL_HUB_ACCOUNTS = "financial_hub/accounts";
export const COMPANY_FILINGS_DEADLINES = "tax_plan/company_filing_deadlines/";
export const PAYROLL = "tax_plan/payroll/";
export const AI_TRANSACTION_CATEGORIZATION = "financial_hub/transaction_categorization/";

// -------------------------------------- UTILS --------------------------------------//
export const GET_ALL_COUNTRIES = `${FONDO_API}address/countries/`;
export const GET_ALL_COUNTRY_SUBDIVISIONS = `${FONDO_API}address/subdivisions/`;

// -------------------------------------- DOCUMENT CENTER --------------------------------------//
export const DOCUMENT_CENTER_FILES = "document-center/files/";
export const DOCUMENT_CENTER_FOLDERS = "document-center/folders/";
export const DOCUMENT_CENTER_PRE_SIGNED_URL = "document-center/pre-signed-url/";
export const DOCUMENT_CENTER_DOWNLOAD_URL = "document-center/download-url/";
export const DOCUMENT_CENTER_FILE_REQUEST = "document-center/file-request/";

// -------------------------------------- PRODUCTS --------------------------------------//
export const GET_ALL_ORDERS = "products/order/";
export const GET_AVAILABLE_PRODUCTS = "products/product/show_available_products/";
export const ADD_PRODUCTS_TO_ORDER = "products/order/add_products_to_order/";
export const REMOVE_LINE_ITEM_FROM_ORDER = "products/order/remove_line_item_from_order/";
export const VERIFY_ORDER = "products/order/:uuid/verify_order/";

// -------------------------------------- REPORTING --------------------------------------//
export const REPORTINGS = "reporting/";
export const SEND_REPORT_TO_GROUPS = "/send-report-to-groups/";
export const RECIPIENT_GROUP = "recipient_group/";
export const REPORT_PDF = "company/:companyUuid/reporting/:uuid";
export const DOWNLOAD_REPORT = "/download";
export const REPORTING_REFRESH = "/refresh-reporting-data/";
export const ASK_REPORTING_AI_ASSISTANT = "/ask_ai_assistant/"

// -------------------------------------- GENERATIVE AI --------------------------------------//
export const BOT_TRACK = "generative_ai/bot_tracking/";