import { ProductType } from "../checkout/products.type";

export type ProductCardType = {
    product: Product;
    checked: boolean;
    // index: number;
};

export type OnboardingProductApiType = {
    products: ProductApiType[];
    section: number;
};

export type Product = {
    id: string;
    fromRange: number;
    toRange: number;
    price?: string;
    discountPrice?: string;
    originalPrice?: string;

    checked?: boolean;
    disabled?: boolean;
    tooltip?: string;
    specialPrice?: string;
    specialDiscountPrice?: string;
    priceTooltip?: string;
    discountDuration?: string;
    category?: string;
    productName?: string;
    displayName?: string;

    relatedService: Service;
};

export type ProductApiType = {
    products: Product[];
    context?: { [key: string]: any };
    addons: AddonType[];
};

export type ProductData = {
    productData: Product[];
    addonData: AddonData[];
};

export type Service = {
    id: string;
    service: string;
    displayName: string;
};

export type AddonType = {
    id: number;
    addon: string;
    price: string;
    discountPrice: string;
    quantity: number;
    year: string;
};

export enum Addons {
    foreignBankAccount = "Foreign Bank Account",
    foreignSubsidiary = "Foreign Subsidiary",
    foreignShareholder = "Foreign Shareholder",
    additionalJurisdiction = "Additional Jurisdiction",
}

export type AddonDetailType = {
    id: number;
    addon: string;
    price: number;
    discountPrice: number;
    quantity: number;
    total: number;
};

export type AddonData = {
    id: number;
    quantity: number;
};

export type RelatedProduct = {
    parentProductUuid: string,
    addonProductName: string,
    addonProductUuid: string,
};

// Product Page Types (May change in the future)
export type ProductAPIData = {
    displayName: string;
    uuid: string;
    addons: RelatedProduct[];
    parentProducts: RelatedProduct[];
    billingFrequency: string;
    requiresFullDeposit: boolean;
    estimateHelperText?: string;
};

export type LineItemAPIData = {
    actualUnitPrice: string;
    displayedUnitPrice: string;
    price: string;
    priceCalculatedBasedOn: string;
    product: ProductAPIData;
    units: number;
    uuid: string;
    actualUnitPriceTextOverride?: string;
    displayedUnitPriceTextOverride?: string;
};

export type OrderAPIData = {
    createdAt: string;
    lineItems: LineItemAPIData[];
    locked: boolean;
    status: string;
    uuid: string;
    customerReviewed: string;
    customerReviewedBy: string;
    fondoApprovedOnUserBehalf: string;
    fondoReviewed: string;
    fondoReviewedBy: string;
};

export type AllOrdersAPIData = {
    openOrder?: OrderAPIData;
    closedOrders?: OrderAPIData[];
};

export type ProductPricingAPIData = {
    uuid: string;
    internalName: string;
    displayName: string;
    description?: string;
    billingFrequency: string;
    sku: string;
    allowMultiple: boolean;
    taxYear?: number;
    nextYearVersion?: number;
    termLength: number;
    price: number;
    discountPrice: number;
    type?: string;
    originalPriceOverrideText?: string;
    priceTextOverride?: string;
    discountPriceTextOverride?: string;
    helperText?: string;
    discountTermText?: string;
    excludedProducts: ExcludedProduct[];
    primaryProducts: ExcludedProduct[];
    dependents: DependentProduct[];
    prerequisites: DependentProduct[];
    addons: AddonProduct[];
    parentProducts: AddonProduct[];
    enabled: boolean;
};

export type DependentProduct = {
    dependentProductName: string;
    dependentProductUuid: string;
    prerequisiteProductName: string;
    prerequisiteProductUuid: string;
};

export type ExcludedProduct = {
    primaryProductName: string;
    primaryProductUuid: string;
    excludedProductName: string;
    excludedProductUuid: string;
};

export type AddonProduct = {
    parentProductName: string;
    parentProductUuid: string;
    addonProductName: string;
    addonProductUuid: string;
};

export type ProductPricingCategory = {
    category: ProductType;
    products: ProductPricingAPIData[];
};

export type ProductPricing = {
    productName: string;
    price: number;
    discount?: number;
    discountLength?: number;
};

export type SelectedProductProps = {
    productId: string;
    units: number;
};
