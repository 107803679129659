import { Card, Badge } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Payroll } from "../financeDashboard.type";
import { convertNumberToCurrency } from "../../../utils/utils";
import { getAmountColorClassName } from "../utils";
import { getTooltipPopover, section174TooltipContent } from "./utils";
import { getNetIncomeEstimate, getSection174Estimate, getTaxableIncomeEstimate } from "./formulas";

type TaxPlanNetIncomeBreakdownCardProps = {
  payroll: Payroll;
}

const TaxPlanNetIncomeBreakdownCard = ({ payroll }: TaxPlanNetIncomeBreakdownCardProps) => {
  const currentPeriodNetIncome = getNetIncomeEstimate(payroll);

  const netIncomePercentageChangeComparedToPreviousPeriod = (
    payroll?.netIncomeDetail?.percentageChangeFromLastPeriod || 0
  );

  return (
    <Card>
      <Card.Body className="p-0">
        <div className="border-bottom p-4 pb-3">
          <Card.Title className="text-uppercase text-muted mb-2">
            <span className="h5 text-secondary">Profit &amp; Loss</span>
          </Card.Title>
            <span className={`h1 mb-0 text-${getAmountColorClassName(currentPeriodNetIncome)}`}>
              ${convertNumberToCurrency(currentPeriodNetIncome)}
            </span>
            {Number.isFinite(netIncomePercentageChangeComparedToPreviousPeriod)
            && (
              <Badge
                pill
                bg={`${getAmountColorClassName(netIncomePercentageChangeComparedToPreviousPeriod)}-soft`}
                className="ms-2 mt-n2"
                as='span'
              >
                {netIncomePercentageChangeComparedToPreviousPeriod.toFixed(1)}%
              </Badge>
            )}
        </div>

        <div className="p-4">
          <div className="d-flex justify-content-between">
            <span>
              <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
              Income
            </span>
            <span>
              ${convertNumberToCurrency(payroll?.income || 0)}
            </span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <span>
              <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
              Expenses
            </span>
            <span>
              ${convertNumberToCurrency(payroll?.expenses || 0)}
            </span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <strong>
              Net Income
            </strong>
            <span className={`text-${getAmountColorClassName(currentPeriodNetIncome)}`}>
              ${convertNumberToCurrency(currentPeriodNetIncome)}
            </span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <span>
              <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
              Section 174 Add-Back {getTooltipPopover(section174TooltipContent)}
            </span>
            <span>
              ${convertNumberToCurrency(getSection174Estimate(payroll))}
            </span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <strong>
              Taxable Income
            </strong>
            <span>
              ${convertNumberToCurrency(getTaxableIncomeEstimate(payroll))}
            </span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TaxPlanNetIncomeBreakdownCard;
