import { ForeignSubsidiaryFormStep } from "./foreignSubsidiary.type";

const formSteps: ForeignSubsidiaryFormStep[] = [
  {
    name: "incorporation",
    displayLabel: "Incorporation",
    icon: "",
    enabled: true,
  },
  {
    name: "registeredAgent",
    displayLabel: "Registered Agent",
    icon: "",
    enabled: true,
  },
  {
    name: "booksRecordsLocation",
    displayLabel: "Books/Records Location",
    icon: "",
    enabled: true,
  },
  {
    name: "majorityShareholders",
    displayLabel: "Majority Shareholders",
    icon: "",
    enabled: true,
  },
  {
    name: "capitalStructure",
    displayLabel: "Capital Structure",
    icon: "",
    enabled: true,
  },
  {
    name: "capitalContributions",
    displayLabel: "Capital Contributions",
    icon: "",
    enabled: true,
  },
  {
    name: "distributionAndDividends",
    displayLabel: "Distribution & Dividends",
    icon: "",
    enabled: true,
  },
  {
    name: "foreignBankAccounts",
    displayLabel: "Foreign Bank Accounts",
    icon: "",
    enabled: true,
  },
];

export default formSteps;
