import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { Formik } from "formik";
import DelawareFranchiseTax from "./DelawareFranchiseTax";
import { getDelawareFranchiseTaxContext } from "./DelawareFranchiseTax.api";
import { DelawareFranchiseTaxFormFields, delawareFranchiseTaxFormSchema } from "./DelawareFranchiseTax.type";

const DelawareFranchiseTaxRouter = () => {
  const { taxYear } = useParams();

  if (!taxYear) {
    return <></>;
  }

  const { data: delawareFranchiseTaxContextQuery } = useQuery(
    ["delawareFranchiseTaxContext"],
    () => getDelawareFranchiseTaxContext(taxYear),
    {
      refetchOnWindowFocus: false,
      enabled: !!taxYear,
    },
  );

  const initialValues = delawareFranchiseTaxContextQuery as DelawareFranchiseTaxFormFields;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={delawareFranchiseTaxFormSchema}
      enableReinitialize={true}
      onSubmit={(values) => console.log(values)}
    >
      <DelawareFranchiseTax taxYear={taxYear} />
    </Formik>
  );
};

export default DelawareFranchiseTaxRouter;
