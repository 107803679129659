import { useContext } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "react-bootstrap";
import {
  getOnboardingSteps,
  getSignupTimelineItems,
  SESSION_COOKIE_NAME,
  STEPS_TO_HIDE_TIMELINE,
} from "../../constants/general";
import * as utils from "../../utils/common.util";

import { getFlowTracking } from "./onboarding.api";

import { cookies } from "../App";
import { ProgressTimeLineContext } from "../../context/ProgressTimelineContext";
import { getDepositPaid } from "../payment/payment.api";
import ProgressTimeline from "../fusion-kit/ProgressTimeline";
import { DepositInformation } from "../payment/payment.type";
import { ONBOARDING_NON_DE_C_CORP } from "../../constants/network-calls";
import { LoggedInUserContext } from "../../context/LoggedInUserContext";
import { CompanyLevelSettingsContext } from "../../context/FeatureFlagsContext";
import { growSurfSnippet } from "../../utils/common.util";

export default function Onboarding() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentStep } = useContext(ProgressTimeLineContext);
  const { currentCompanyNonDeCCorp } = useContext(LoggedInUserContext);
  const { showNewCheckoutPage } = useContext(CompanyLevelSettingsContext);

  const paymentQuery: any = useQuery<DepositInformation, Error>(["depositInfo"], getDepositPaid, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const hasPaidDeposit = paymentQuery?.data && paymentQuery?.data.hasPaidDeposit;

  // Regex to remove trailing slash at the end so
  // '/onboarding' and '/onboarding/' will both redirect to where its needed.
  const pathName = location.pathname.replace(/\/+$/, "");

  const onboardingCheckoutUrl = showNewCheckoutPage ? "/onboarding/checkout" : "/onboarding/product";

  if (!paymentQuery.isLoading && pathName === "/onboarding") {
    const sessionCookie = cookies.get(SESSION_COOKIE_NAME);
    if (sessionCookie) {
      getFlowTracking("onboarding")
        .then((res) => {
          if (currentCompanyNonDeCCorp) {
            navigate(ONBOARDING_NON_DE_C_CORP);
          } else if (res && res.length) {
            let nextStep = res[res.length - 1];
            if (nextStep === "product" && showNewCheckoutPage) nextStep = "checkout";
            const next = utils.getNext(getOnboardingSteps(showNewCheckoutPage), nextStep);
            if (next) {
              if ((next !== "onboarding-success")) {
                navigate(next);
              } else if (next === "onboarding-success" && hasPaidDeposit) {
                navigate(next);
              } else {
                // If we hit this, it means that we have next === onboarding-success
                // and no paid deposit. If that happens, we want to go back to the product
                // page. This happens if someone signs up but doesn't pay the deposit right away
                navigate(onboardingCheckoutUrl);
              }
            } else {
              navigate("/dashboard");
            }
          } else {
            navigate("/onboarding/company1");
          }
        })
        .catch((err) => {
          console.error("err");
          console.error(err);
        });
    } else {
      navigate("signup");
    }
  }

  return (
        <div>
            {growSurfSnippet}
            <ProgressTimeline
              currentStep={currentStep}
              hideTimeline={STEPS_TO_HIDE_TIMELINE.includes(currentStep)}
              timeLineItems={getSignupTimelineItems(showNewCheckoutPage)}
            />
            {paymentQuery.isLoading && (
            <div className="row justify-content-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>)}
            {!paymentQuery.isLoading && <Outlet />}
        </div>
  );
}
