import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { darkBlueText, gradientStyle } from "./utils";

type FondoServiceDetailModalProps = {
  show: boolean;
  handleClose: () => any;
  headerTitle: string;
  headerDescription: JSX.Element;
  serviceDetailTitle: string;
  serviceDetailDescription: JSX.Element;
  illustration: string;
  modalRedirectUrl: string;
};

const FondoServiceDetailModal = ({
  show,
  handleClose,
  headerTitle,
  headerDescription,
  serviceDetailTitle,
  serviceDetailDescription,
  illustration,
  modalRedirectUrl,
}: FondoServiceDetailModalProps) => (
  <Modal
    show={show}
    onHide={handleClose}
    size="lg"
  >
    <Modal.Body className="p-0 rounded">
      <div className="p-6" style={gradientStyle}>

        <div className="d-flex align-items-center justify-content-between" style={{ height: "130px" }}>
          <div className="px-2">
            <h1 className="mb-0" style={darkBlueText}>
              {headerTitle}
            </h1>
            <p className="fw-light my-3" style={darkBlueText}>
              {headerDescription}
            </p>
          </div>
          <div>
            <img
              src={illustration}
              height="230px"
            />
          </div>
        </div>

      </div>
      <div className="px-6 pt-5 pb-6">
        <h3 style={darkBlueText}>{serviceDetailTitle}</h3>
        {serviceDetailDescription}
      </div>
    </Modal.Body>
    <Modal.Footer className="py-3">
      <Button onClick={handleClose} variant="white" size="sm">
        Close
      </Button>
      <Link to={modalRedirectUrl}>
        <Button variant="primary" size="sm">
          Get Started
        </Button>
      </Link>
    </Modal.Footer>
  </Modal>
);

export default FondoServiceDetailModal;
