type CircleReferenceColorProps = {
  color: string;
  text?: string;
  uppercaseText?: boolean;
  textMuted?: boolean;
  size?: number;
}

const CircleReferenceColor = ({
  color,
  text = "",
  uppercaseText = false,
  textMuted = false,
  size = 15,
}: CircleReferenceColorProps) => (
    <div className="d-flex align-items-center me-4">
      <div
        className="rounded-circle me-2"
        style={ { width: `${size}px`, height: `${size}px`, backgroundColor: color } }
      />
      {text && (
        <span className={`${textMuted && "text-muted"} small ${uppercaseText && "text-uppercase"}`}>{text}</span>
      )}
    </div>
);

export default CircleReferenceColor;
