import { Product, OrderLineItem, ProductType } from "./products.type";

export const DEPOSIT_AMOUNT = 250;
export const ONE_TIME = "One Time";
export const ANNUAL = "Annual";
export const ANNUALLY = "Annually";
export const YEARLY = "Yearly";
export const MONTHLY = "Monthly";
export const QUARTERLY = "Quarterly";
export const CANCELLED_WITH_NO_PENALTY_TEXT = "Can be cancelled anytime with no penalty.";

export const PLATFORM_PRODUCT: Product = {
  displayName: "Platform",
  type: ProductType.PLATFORM,
  helperText: "Runway, Monthly, Cash Metrics, Tax Deadlines",
  price: 0,
  discountPrice: 0,
  billingFrequency: ONE_TIME,
};

export const PLATFORM_LINE_ITEM: OrderLineItem = {
  name: "Platform",
  finalPrice: "FREE",
  originalPrice: "",
};
