import {
  Formik,
} from "formik";
import { Modal } from "react-bootstrap";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import {
  CapitalStructureFormFields,
  capitalStructureFieldToLabelMap,
  capitalStructureToTypeMap,
} from "../foreignSubsidiary.type";
import { capitalStructureSchema } from "../validationSchemas";

type AddCapitalStructureModalProps = {
  show: boolean;
  handleClose: () => any;
  capitalStructure?: CapitalStructureFormFields;
  header: string;
  handleSave: (capitalStructure: CapitalStructureFormFields) => void;
  taxYear?: string;
};

const AddCapitalStructureModal = (props: AddCapitalStructureModalProps) => {
  const initialValues = props.capitalStructure || {
    description: "",
    issuedCountAtPeriodEnd: 0,
    issuedCountAtPeriodStart: 0,
    outstandingCountAtPeriodEnd: 0,
    outstandingCountAtPeriodStart: 0,
  };

  return (
    <Modal show={props.show} onHide={() => props.handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={capitalStructureSchema}
            onSubmit={(values) => {
              props.handleSave(values);
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="description"
                  labels={capitalStructureFieldToLabelMap}
                  types={capitalStructureToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="issuedCountAtPeriodStart"
                  labels={capitalStructureFieldToLabelMap}
                  types={capitalStructureToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="outstandingCountAtPeriodStart"
                  labels={capitalStructureFieldToLabelMap}
                  types={capitalStructureToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="issuedCountAtPeriodEnd"
                  labels={capitalStructureFieldToLabelMap}
                  types={capitalStructureToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="outstandingCountAtPeriodEnd"
                  labels={capitalStructureFieldToLabelMap}
                  types={capitalStructureToTypeMap}
                />
                <div className="row mb-3 mt-2">
                  <div className="d-flex flex-row col-12 col-md-12 justify-content-end">
                    <button className="btn btn-lg btn-primary" type="submit"><div>Save</div></button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCapitalStructureModal;
