import { useState } from "react";
import {
  Button, Card, Modal, Form, ListGroup,
} from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addProductToOrder, getAvailableProducts } from "../products.api";
import { ProductPricingAPIData, SelectedProductProps } from "../../onboarding/product/product.type";
import ProductModalField from "./ProductModalField";

type AddProductModalProps = {
  show: boolean;
  handleClose: () => any;
}

const AddProductModal = ({ show, handleClose }: AddProductModalProps) => {
  const [selectProducts, setSelectProducts] = useState<SelectedProductProps[]>([]);
  const [acknowledgedDisclaimer, setAcknowledgedDisclaimer] = useState(false);
  const [saveWarningText, setSaveWarningText] = useState("");
  const queryClient = useQueryClient();

  const availableProducts = useQuery(
    ["availableProducts"],
    () => getAvailableProducts(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const addProduct = useMutation({
    mutationFn: (data: SelectedProductProps[]) => addProductToOrder(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["productOpenOrder"] });
      queryClient.invalidateQueries({ queryKey: ["availableProducts"] });
    },
  });

  const handleSelectProducts = (product: ProductPricingAPIData) => {
    const foundProduct = selectProducts.find((selectProduct) => selectProduct.productId === product.uuid);
    if (foundProduct) {
      let newProducts = selectProducts.filter((selectProduct) => selectProduct.productId !== product.uuid);
      if (product.dependents) {
        newProducts = newProducts.filter((selectedProduct) => !product.dependents?.some(
          (dependent) => dependent.dependentProductUuid === selectedProduct.productId,
        ));
      }
      setSelectProducts(newProducts);
    } else {
      const newProducts = [...selectProducts, { productId: product.uuid, units: 1 }];
      setSelectProducts(newProducts);
    }
  };

  const checkIfProductIsSelected = (
    product: ProductPricingAPIData,
    selectedProducts: SelectedProductProps[],
  ) => (selectedProducts.some((selectProduct) => selectProduct.productId === product.uuid));

  const checkProductDisabled = (product: ProductPricingAPIData, selectedProducts: SelectedProductProps[]) => {
    if (product.prerequisites?.length !== 0) {
      const prereqFound = product.prerequisites?.some((prereq) => availableProducts.data?.some(
        (availble) => availble.products.some((singleProduct) => singleProduct.uuid === prereq.prerequisiteProductUuid),
      ));
      if (prereqFound) {
        const hasSelectedParent = selectedProducts.some(
          (selectedProduct) => product.prerequisites?.some(
            (prereqs) => prereqs.prerequisiteProductUuid === selectedProduct.productId,
          ),
        );
        if (!hasSelectedParent) {
          return true;
        }
      }
    }
    if (product.excludedProducts?.length !== 0) {
      const hasSelectedParent = selectedProducts.some(
        (selectedProduct) => product.excludedProducts?.some(
          (excludedProduct) => excludedProduct.excludedProductUuid === selectedProduct.productId,
        ),
      );
      if (hasSelectedParent) {
        return true;
      }
    }
    if (product.primaryProducts?.length !== 0) {
      const hasSelectedParent = selectedProducts.some(
        (selectedProduct) => product.primaryProducts?.some(
          (primaryProduct) => primaryProduct.primaryProductUuid === selectedProduct.productId,
        ),
      );
      if (hasSelectedParent) {
        return true;
      }
    }
    return false;
  };

  const closeModal = () => {
    setSelectProducts([]);
    setAcknowledgedDisclaimer(false);
    setSaveWarningText("");
    handleClose();
  };

  const handleSaveProducts = () => {
    if (!acknowledgedDisclaimer) {
      setSaveWarningText("You must acknowledge this agreement!");
      return;
    }
    if (selectProducts.length === 0) {
      setSaveWarningText("You must select products to add to your order!");
      return;
    }
    addProduct.mutate(selectProducts);
    closeModal();
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <span className="fw-bold">Choose your products</span>
      </Modal.Header>
      <Modal.Body>
        Which products would you like Fondo to assist you with?
        {availableProducts.data?.map((productCategory) => (
          <Card className="mt-3" key={productCategory.category}>
            <Card.Header>
              <span className="fw-bold">{productCategory.category}</span>
            </Card.Header>
            <ListGroup>
              {productCategory.products.map((product) => (
                <ListGroup.Item key={product.uuid}>
                  <ProductModalField
                    product={product}
                    selectedProducts={selectProducts}
                    handleSelectProducts={handleSelectProducts}
                    checkProductDisabled={checkProductDisabled}
                    checkIfProductIsSelected={checkIfProductIsSelected}
                  />
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Form.Check
          label="I agree to have these products added to my order and
          understand they will be reflected on future invoices"
          checked={acknowledgedDisclaimer}
          onChange={(value) => setAcknowledgedDisclaimer(value.target.checked)}
        />
        {saveWarningText && <p className="text-danger">{saveWarningText}</p>}
        <Button
          variant="primary"
          onClick={handleSaveProducts}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProductModal;
