import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { loadingStatePhrases } from "./utils";

const AIAssistantLoadingState = () => {
  const [phraseOnDisplay, setPhraseOnDisplay] = useState("Looking into it...");

  const shufflePhrases = useCallback(() => {
    const index = Math.floor(Math.random() * loadingStatePhrases.length);
    setPhraseOnDisplay(loadingStatePhrases[index]);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shufflePhrases, 4000);
    return () => clearInterval(intervalID);
  }, [shufflePhrases]);

  return (
    <div className="row justify-content-center text-center mt-5">
      <Spinner as="span" variant="primary" className="mb-3" animation="border" role="status" />
      <p className="text-muted fs-4 align-middle mb-0">{phraseOnDisplay}</p>
    </div>
  );
};

export default AIAssistantLoadingState;
