import { useQuery } from "@tanstack/react-query";
import { Country, Subdivision } from "../../../utils/util.type";
import { getCountries, getCountrySubdivisions } from "../../../utils/util.api";

type Addressable = {
  streetAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

type DisplaySingleLineAddressProps = {
  addressable: Addressable;
}

const DisplaySingleLineAddress = ({ addressable }: DisplaySingleLineAddressProps) => {
  const countryCode = addressable.state?.split("-")?.[0];
  const stateCode = addressable.state?.includes("-") ? addressable.state.split("-")?.[1] : "";

  const countryQuery = useQuery<Country[], Error>(["country"], getCountries, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const countrySubdivisionQuery = useQuery<Subdivision[], Error>(
    ["countrySubdivision", countryCode],
    getCountrySubdivisions,
    {
      enabled: !!countryCode,
      refetchOnMount: false,
    },
  );

  let stateCountryText = "";

  if (countrySubdivisionQuery.data && countryQuery.data) {
    stateCountryText = `${countrySubdivisionQuery.data.find((state) => state.abbr === stateCode)?.name},
      ${countryQuery.data.find((country) => country.alpha2 === countryCode)?.name}`;
  }

  const formatSingleLineAddress = (): string => (
    `${addressable.streetAddress || ""}${addressable.streetAddress && ", "}${formatCityStatePostal()}`
  );

  const formatCityStatePostal = (): string => {
    let str = addressable.city || "";
    if (stateCountryText) str = `${str}, ${stateCountryText}`;
    if (addressable.postalCode) str = `${str}, ${addressable.postalCode}`;
    return str;
  };

  return <>{formatSingleLineAddress()}</>;
};

export default DisplaySingleLineAddress;
