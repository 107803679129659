import { useState } from "react";
import { Badge, Button, Card } from "react-bootstrap";
import { Partner } from "../passport-partners.types";
import { DealDetailsModal } from "../deals/DealDetailsModal";

type SimpleFeaturedPartnerBannerProps = {
  partner: Partner;
};

const SimpleFeaturedPartnerBanner = ({ partner }: SimpleFeaturedPartnerBannerProps) => {
  const [showDealDetailsModal, setShowDealDetailsModal] = useState(false);
  const { products } = partner;
  const product = products.length ? products[0] : undefined;

  return (
    <>
      <Card
        className="p-4 d-flex flex-row justify-content-between"
        style={{ backgroundColor: "#EDF2F9" }}
      >
        <div className="d-flex flex-row justify-content-between">
          <Card
            className="m-0 p-3 d-flex justify-content-center"
            style={{ height: "8rem", width: "8rem" }}
          >
            <a href={partner.url} target="_blank">
              <img src={partner.logoUrl} alt={partner.name} className="w-100" />
            </a>
          </Card>
          <div className="ms-5 d-flex justify-content-between flex-column">
            <Badge bg="primary-soft" className="px-4 fs-4" style={{ width: "fit-content" }}>
              Featured {partner.hasOneTouchDeal ? "One Click Connect " : ""}Partner
            </Badge>
            <h1 className="mb-0 mt-1" style={{ fontSize: "2.25em" }}>{partner.name}</h1>
            <p className="text-secondary fs-2 m-0">{partner.description}</p>
          </div>
        </div>
        <div>
          <Button
            className="px-4 py-2"
            size="lg"
            onClick={() => setShowDealDetailsModal(true)}
          >
            See Details
          </Button>
        </div>
      </Card>
      <DealDetailsModal
        partner={partner}
        product={product}
        show={showDealDetailsModal}
        onHide={() => setShowDealDetailsModal(false)}
      />
    </>
  )
};

export default SimpleFeaturedPartnerBanner;
