import { mapMonthDateToQuarter } from "./constants";

export const parseMonthAndYearDateToWords = (monthAndYear: string) => {
  const date = parseMonthAndYearDate(monthAndYear);
  const year = Number(monthAndYear.split("-")[0]);

  const monthName = date.toLocaleString("en-US", {
    month: "long",
  });
  return `${monthName} ${year}`;
};

export const parseMonthAndYearToQuarters = (monthAndYear: string) => {
  const [year, month] = monthAndYear.split("-");
  return `${mapMonthDateToQuarter[month]} ${year}`;
};

export const parseMonthAndYearDateToLastDayFullText = (monthAndYear: string): string => {
  const date = parseMonthAndYearDate(monthAndYear);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const parseMonthAndYearDate = (monthAndYear: string): Date => {
  const year = Number(monthAndYear.split("-")[0]);
  const monthNumber = Number(monthAndYear.split("-")[1]);
  const date = new Date(year, monthNumber, 1);
  date.setDate(date.getDate() - 1);
  return date;
};

export const getAmountColorClassName = (amount: number) => {
  if (amount === 0) {
    return;
  }
  if (amount > 0) {
    return "success";
  }
  return "danger";
};

export const getExpensesColorClassName = (amount: number) => {
  if (amount === 0) {
    return;
  }
  if (amount > 0) {
    return "danger";
  }
  return "success";
};

export const getAmountColorHEXCode = (amount: number | null) => {
  // Same logic as getAmountColorClassName, but returns a HEX code.
  // Green if the number is bigger than zero, red if it's less.
  // If number is null or 0, returns transparent.
  if (!amount) {
    return "#FFFFFF00"; // Transparent
  }
  if (amount > 0) {
    return "#00D97D"; // Green
  }
  return "#E63756"; // Red
};

export const getChartColorsWithLastOneActive = (amounts: (number | null)[]) =>
  amounts.map((amount: number | null, idx: number) => {
    if (!amount) {
      return "#FFFFFF00"; // Transparent
    }
    if (idx === amounts.length - 1) {
      return "#92E6DC";
    }
    return "#D0D6E0";
  });
