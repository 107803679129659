import { useContext } from "react";
import { CardGroup } from "react-bootstrap";
import { useLocation } from "react-router";
import cashBalance from "../../assets/img/newCashBalance.svg";
import runway from "../../assets/img/runway.svg";
import burn from "../../assets/img/burn-rate.svg";
import { CashFlowStats } from "./dashboard.type";
import DoubleContentCard from "../fondo-components/Cards/DoubleContentCard";
import { LoggedInUserContext } from "../../context/LoggedInUserContext";
import {
  getAmountColorClassName,
  parseMonthAndYearDateToLastDayFullText,
  parseMonthAndYearDateToWords,
} from "../finance-dashboard/utils";

type LightCashFlowRowProps = {
  cashFlowStatsData?: CashFlowStats;
  loadingState?: boolean;
  selectedMonth: string;
}

export default function LightCashFlowRow({
  cashFlowStatsData,
  loadingState = false,
  selectedMonth,
}: LightCashFlowRowProps) {
  const { pathname: currentPath } = useLocation();
  const pathIsDashboardHome = currentPath.replace(/\/+$/, "") === "/dashboard";
  const { currentDashboardOnboarding, currentCompanyShowNewRunway } = useContext(LoggedInUserContext);

  function formatCurrency(input: string) {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(parseFloat(input));
  }

  const selectedMonthWords = parseMonthAndYearDateToWords(selectedMonth);
  const selectedDateWords = parseMonthAndYearDateToLastDayFullText(selectedMonth);

  const parseCashZeroDate = (cashZeroDate: string) => {
    if (cashZeroDate === "N/A" || !cashZeroDate) {
      return "N/A";
    }
    if (cashZeroDate === "More than 10 years") {
      return "More than 10 years";
    }
    const [year, month] = cashZeroDate.split("-");
    return parseMonthAndYearDateToWords(`${year}-${month}`);
  };

  if (loadingState) {
    return (
      <CardGroup style={ { gap: "1rem" } }>
        <DoubleContentCard
          primaryTitle={"CASH BALANCE"}
          icon={cashBalance}
          loading={true}
        />
        <DoubleContentCard
          primaryTitle={"RUNWAY"}
          icon={runway}
          loading={true}
        />
        <DoubleContentCard
          primaryTitle={"BURN RATE"}
          icon={burn}
          loading={true}
        />
      </CardGroup>
    );
  }

  if (!cashFlowStatsData || cashFlowStatsData?.accountBalancesCount === 0) {
    return (
      <CardGroup style={ { gap: "1rem" } }>
        <DoubleContentCard
          primaryTitle={"CASH BALANCE"}
          secondaryText={"FUNDING"}
          icon={cashBalance}
          inactive={true}
          buttonText="Activate"
          buttonUrl="/dashboard/integrations"
        />
        <DoubleContentCard
          primaryTitle={"RUNWAY"}
          secondaryText={"AVERAGE BURN RATE"}
          icon={runway}
          inactive={true}
          buttonText="Activate"
          buttonUrl="/dashboard/integrations"
        />
        <DoubleContentCard
          primaryTitle={"BURN RATE"}
          secondaryText={"CASH ZERO DATE"}
          icon={burn}
          inactive={true}
          buttonText="Activate"
          buttonUrl="/dashboard/integrations"
        />
      </CardGroup>
    );
  }

  const cashBalanceTitle = `CASH BALANCE (AS OF ${selectedDateWords})`;
  const fundingTitle = `FUNDING (${selectedMonthWords})`;
  const burnRateTitle = `BURN RATE  (${selectedMonthWords})`;
  const averageBurnRateTitle = "AVERAGE BURN RATE (PRIOR THREE MONTHS)";
  const runwayTitle = `RUNWAY (AS OF ${selectedDateWords})`;
  const cashZeroDate = "CASH ZERO DATE";

  const linkToNewRunway = (currentDashboardOnboarding || currentCompanyShowNewRunway);

  return (
    <CardGroup style={ { gap: "1rem" } }>
      <DoubleContentCard
        primaryTitle={cashBalanceTitle}
        icon={cashBalance}
        primaryText={formatCurrency(cashFlowStatsData.currentBalance)}
        secondaryTitle={fundingTitle}
        secondaryText={formatCurrency(`${cashFlowStatsData.funding}`)}
        showButton={false}
      />
      <DoubleContentCard
        primaryTitle={burnRateTitle}
        icon={burn}
        primaryText={
          <span
            className={`text-${getAmountColorClassName(Number(cashFlowStatsData.currentBurn))}`}
          >
            {formatCurrency(cashFlowStatsData.currentBurn)}
          </span>
        }
        secondaryTitle={averageBurnRateTitle}
        secondaryText={
          <span
            className={`text-${getAmountColorClassName(Number(cashFlowStatsData.averageBurn || 0))}`}
          >
            {formatCurrency(cashFlowStatsData.averageBurn || "0")}
          </span>
        }
        showButton={false}
      />
      <DoubleContentCard
        primaryTitle={runwayTitle}
        icon={runway}
        primaryText={cashFlowStatsData.runway || "N/A"}
        secondaryTitle={cashZeroDate}
        secondaryText={parseCashZeroDate(cashFlowStatsData.cashZeroDate)}
        buttonText="View Details"
        showButton={pathIsDashboardHome}
        buttonUrl={linkToNewRunway ? "/dashboard/finance/runway/" : "/dashboard/runway"}
      />
    </CardGroup>
  );
}
