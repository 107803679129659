import { Form, InputGroup } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

type FondoCurrencyFieldProps = {
  id?: string;
  name?: string;
  value: number | string | undefined;
  placeholder?: string;
  size?: "lg" | "sm" | undefined;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isInvalid?: boolean;
  errorMessage?: string;
  allowNegative?: boolean;
};

const FondoCurrencyField = ({
  id,
  name,
  value,
  placeholder,
  size = "lg",
  className = "mb-3",
  onChange,
  onBlur,
  isInvalid,
  errorMessage,
  allowNegative,
}: FondoCurrencyFieldProps) => {
  const defaultMaskOptions = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 15, // limit length of integer numbers
    allowNegative: allowNegative || false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask(defaultMaskOptions);

  return (
    <div>
      <InputGroup className={className} size={size} hasValidation>
        <InputGroup.Text>$</InputGroup.Text>
        <Form.Control isInvalid={isInvalid} className='d-none'/>
        <MaskedInput
          mask={currencyMask}
          id={id}
          value={value}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          className='form-control'
        />
        <Form.Control.Feedback type='invalid'>
          {errorMessage || ""}
        </Form.Control.Feedback>
      </InputGroup>
    </div>
  );
};

export default FondoCurrencyField;
