import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Row,
} from "react-bootstrap";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HelloSign from "hellosign-embedded";
import FeatherIcon from "feather-icons-react";
import FondoLayout from "../fusion-kit/FondoLayout";
import {
  SetStatusParams,
  SignatureRequestStatus,
  getDropboxSignerURL,
  getSignatureRequests,
  setSignatureRequestStatus,
} from "./documents.api";
import greenCheckCircle from "../../assets/img/icons/greenCheckCircle.png";

const DropboxSign = () => {
  const client = new HelloSign();
  const dropboxSignClientId = process.env.REACT_APP_DROPBOX_SIGN_CLIENT_ID;
  
  const queryClient = useQueryClient();
  const [currentSignatureId, setCurrentSignatureId] = useState("");
  const { data: signatureRequests } = useQuery(["fondoDropboxFiles"], getSignatureRequests);

  const skipDomainVerification = process.env.REACT_APP_FONDO_ENVIRONMENT === "development"
  || process.env.REACT_APP_FONDO_ENVIRONMENT === "stage";

  const requestSignatureMutator = useMutation(
    {
      mutationFn: (requestId: string) => getDropboxSignerURL(requestId),
      onSuccess: (data) => {
        client.open(data, {
          clientId: dropboxSignClientId,
          skipDomainVerification,
        });
      },
    },
  );

  const setSignatureRequestMutator = useMutation(
    {
      mutationFn: (requestData: SetStatusParams) => setSignatureRequestStatus(requestData),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["fondoDropboxFiles"] });
      },
    },
  );

  client.on("finish", () => {
    const requestSignatureData: SetStatusParams = {
      requestId: currentSignatureId,
      params: {
        status: SignatureRequestStatus.SIGNED,
      },
    };
    setSignatureRequestMutator.mutate(requestSignatureData);
  });

  const handleReviewAndSign = (signatureId: string) => {
    setCurrentSignatureId(signatureId);
    requestSignatureMutator.mutate(signatureId);
  };

  const allSigned = () => signatureRequests?.signatureRequests.every(
    (request) => request.status === SignatureRequestStatus.SIGNED
  );

  return (
    <FondoLayout title="Signatures Required" pretitle="documents">
      <Alert
        variant={`${allSigned() ? "success" : "light"}`}
        className={`${allSigned() ? "text-white" : "text-secondary"}`}
      >
        <FeatherIcon icon="info" size="18" className="me-2 mt-n1" />
        <span className="pt-1 align-middle">
          {allSigned()
            ? "All documents signed, thank you!"
            : "The following documents need your signature before we can proceed."}
        </span>
      </Alert>
      {signatureRequests?.signatureRequests?.map((request) => (
        <Card>
          <Card.Body>
            <Row className="pt-1">
              <Col>
                <div className="d-flex">
                  <div>
                    <div className="avatar avatar-md">
                      <div className="avatar-title bg-primary-soft rounded-circle text-primary">
                        <FeatherIcon icon="file-text" size="23" strokeWidth={1.5} />
                      </div>
                    </div>
                  </div>
                  <div className="ms-3">
                    <span>
                      {request.fileName}
                      {request.status === SignatureRequestStatus.WAITING_SIGNATURE && (
                        <Badge className="ms-3" bg="danger-soft">
                          Signature Required
                        </Badge>
                      )}
                    </span>
                    <h5 className="text-secondary">{request.uploader}</h5>
                  </div>
                </div>
              </Col>
              <Col xs={2} className="my-auto">
                <div className="float-end">
                  {request.status === SignatureRequestStatus.WAITING_SIGNATURE && (
                    <Button size="sm" onClick={() => handleReviewAndSign(`${request.id}`)}>
                      Review and Sign
                    </Button>
                  )}
                  {request.status === SignatureRequestStatus.SIGNED && (
                    <img src={greenCheckCircle} style={{ width: "30px", height: "30px" }} />
                  )}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
    </FondoLayout>
  );
};

export default DropboxSign;
