import { FormikProps } from "formik";
import * as Yup from "yup";
import { FieldTypes } from "../forms/formik-components/formTypes";
import {
  capitalContributionsDistributionAndDividendsSchema,
  capitalStructureSchema,
  foreignBankAccountsSchema,
  incorporationSchema,
  majorityShareholdersSchema,
  registeredAgentAndBooksAndRecordsLocationSchema,
} from "./validationSchemas";

export type ForeignSubsidiaryInfo = {
  name: string;
  uuid: string;
  complete: boolean;
};

export type ForeignSubsidiaryFormFields = {
  submitted?: boolean;
  incorporation: IncorporationFormFields;
  registeredAgent: RegisteredAgentFormFields;
  booksRecordsLocation: BooksRecordsLocationFormFields;
  capitalStructure: CapitalStructureFormFields[];
  oldCapitalStructure: CapitalStructureFormFields[];
  majorityShareholders: MajorityShareholdersFormFields[];
  oldMajorityShareholders: MajorityShareholdersFormFields[];
  capitalContributions: CapitalContributionsDistributionAndDividendsFormFields[];
  oldCapitalContributions: CapitalContributionsDistributionAndDividendsFormFields[];
  distributionAndDividends: CapitalContributionsDistributionAndDividendsFormFields[];
  oldDistributionAndDividends: CapitalContributionsDistributionAndDividendsFormFields[];
  foreignBankAccounts: ForeignBankAccountsFormFields[];
  oldForeignBankAccounts: ForeignBankAccountsFormFields[];
}

export type ForeignSubsidiaryFormStep = {
  name: string;
  displayLabel: string;
  icon: string;
  enabled: boolean | ((formik: FormikProps<ForeignSubsidiaryFormFields>) => boolean);
  taxYear?: string;
};

export type IncorporationFormFields = {
  name: string;
  dateAcquired: string;
  taxIdentificationNumberEnc: string;
  fiscalYearEndMonth?: string;
  fiscalYearEndDay?: string;
  dateIncorporated: string;
  functionalCurrency: string;
  country: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  countryOfIncorporationLaws: string;
  changesInOwnership: string;
  changeInOwnershipDescription: string;
  capitalContributionsThisYear: string;
  transferOfFundsToSubsidiary: string;
  distributionsDividendsThisYear: string;
};

export type RegisteredAgentFormFields = {
  name: string;
  country: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
};

export type BooksRecordsLocationFormFields = {
  name: string;
  country: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
};

export type MajorityShareholdersFormFields = {
  uuid?: string;
  name: string;
  country: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  sharesAtYearStart: number;
  sharesAtYearEnd: number;
};

export type CapitalStructureFormFields = {
  uuid?: string;
  description: string;
  issuedCountAtPeriodStart: number;
  outstandingCountAtPeriodStart: number;
  issuedCountAtPeriodEnd: number;
  outstandingCountAtPeriodEnd: number;
};

export type CapitalContributionsDistributionAndDividendsFormFields = {
  uuid?: string;
  date: string;
  description: string;
  value: number;
  currency: string;
};

export type ForeignBankAccountsFormFields = {
  uuid?: string;
  name: string;
  type: string;
  accountNumber: string;
  country: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  currency: string;
  maxValueDuringYear: number;
  dateOfMaxValue: string;
  openedDuringYear: string;
  closedDuringYear: string;
};

export const incorporationFieldToLabelMap = {
  name: "Name of Foreign Subsidiary:",
  dateAcquired: "Acquisition Date (If Applicable):",
  taxIdentificationNumberEnc: "Tax identification number:",
  fiscalYearEnd: "Fiscal Year End:",
  dateIncorporated: "Date Incorporated:",
  functionalCurrency: "Functional Currency:",
  country: "Country:",
  streetAddress: "Street Address:",
  city: "City:",
  state: "State:",
  postalCode: "Postal Code:",
  countryOfIncorporationLaws: "Country under whose laws incorporated:",
  changesInOwnership: "Changes in ownership:",
  changeInOwnershipDescription: "Changes in ownership description:",
  capitalContributionsThisYear: "Capital contributions this year:",
  transferOfFundsToSubsidiary: "Transfer of funds to subsidiary:",
  distributionsDividendsThisYear: "Distributions dividends this year:",
};

export const incorporationFieldToTypeMap = {
  name: FieldTypes.TEXT,
  dateAcquired: FieldTypes.DATE,
  taxIdentificationNumberEnc: FieldTypes.TEXT,
  fiscalYearEnd: FieldTypes.DATE_OF_YEAR,
  dateIncorporated: FieldTypes.DATE,
  functionalCurrency: FieldTypes.BASIC_SELECTOR,
  countryOfIncorporationLaws: FieldTypes.COUNTRY,
  changesInOwnership: FieldTypes.YESNO,
  changeInOwnershipDescription: FieldTypes.TEXT,
  capitalContributionsThisYear: FieldTypes.YESNO,
  transferOfFundsToSubsidiary: FieldTypes.YESNO,
  distributionsDividendsThisYear: FieldTypes.YESNO,
};

export const registeredAgentFieldToLabelMap = {
  name: "Name:",
  country: "Country:",
  streetAddress: "Street Address:",
  city: "City:",
  state: "State:",
  postalCode: "Postal Code:",
};

export const registeredAgentFieldToTypeMap = {
  name: FieldTypes.TEXT,
};

export const booksRecordsLocationFieldToLabelMap = {
  name: "Name:",
  country: "Country:",
  streetAddress: "Street Address:",
  city: "City:",
  state: "State:",
  postalCode: "Postal Code:",
};

export const booksRecordsLocationToTypeMap = {
  name: FieldTypes.TEXT,
};

export const majorityShareholdersFieldToLabelMap = {
  name: "Name:",
  country: "Country:",
  streetAddress: "Street Address:",
  city: "City:",
  state: "State:",
  postalCode: "Postal Code:",
  sharesAtYearStart: "Shares at year start:",
  sharesAtYearEnd: "Shares at year end:",
};

export const majorityShareholdersToTypeMap = {
  name: FieldTypes.TEXT,
  country: FieldTypes.TEXT,
  streetAddress: FieldTypes.TEXT,
  city: FieldTypes.TEXT,
  state: FieldTypes.TEXT,
  postalCode: FieldTypes.TEXT,
  sharesAtYearStart: FieldTypes.INTEGER,
  sharesAtYearEnd: FieldTypes.INTEGER,
};

export const capitalStructureFieldToLabelMap = {
  description: "Description:",
  issuedCountAtPeriodStart: "Issued at start of period:",
  outstandingCountAtPeriodStart: "Outstanding at start of period:",
  issuedCountAtPeriodEnd: "Issued at end of period:",
  outstandingCountAtPeriodEnd: "Outstanding at end of period:",
};

export const capitalStructureToTypeMap = {
  description: FieldTypes.TEXT,
  issuedCountAtPeriodStart: FieldTypes.INTEGER,
  outstandingCountAtPeriodStart: FieldTypes.INTEGER,
  issuedCountAtPeriodEnd: FieldTypes.INTEGER,
  outstandingCountAtPeriodEnd: FieldTypes.INTEGER,
};

export const capitalContributionsDistributionAndDividendsFieldToLabelMap = {
  date: "Date:",
  description: "Description:",
  value: "Value:",
  currency: "Currency:",
};

export const capitalContributionsDistributionAndDividendsToTypeMap = {
  date: FieldTypes.DATE,
  description: FieldTypes.TEXT,
  value: FieldTypes.INTEGER,
  currency: FieldTypes.BASIC_SELECTOR,
};

export const foreignBankAccountsFieldToLabelMap = {
  name: "Name:",
  type: "Type:",
  accountNumber: "Account number:",
  country: "Country:",
  streetAddress: "Street Address:",
  city: "City:",
  state: "State:",
  postalCode: "Postal Code:",
  currency: "Currency:",
  maxValueDuringYear: "Max value during year:",
  dateOfMaxValue: "Date of max value:",
  openedDuringYear: "Opened during year:",
  closedDuringYear: "Closed during year:",
};

export const foreignBankAccountsToTypeMap = {
  name: FieldTypes.TEXT,
  type: FieldTypes.TEXT,
  accountNumber: FieldTypes.TEXT,
  country: FieldTypes.TEXT,
  streetAddress: FieldTypes.TEXT,
  city: FieldTypes.TEXT,
  state: FieldTypes.TEXT,
  postalCode: FieldTypes.TEXT,
  currency: FieldTypes.BASIC_SELECTOR,
  maxValueDuringYear: FieldTypes.INTEGER,
  dateOfMaxValue: FieldTypes.DATE,
  openedDuringYear: FieldTypes.YESNO,
  closedDuringYear: FieldTypes.YESNO,
};

export type ForeignSubsidiaryForms = {
  incorporationInfo: string;
  uuid: string;
  complete: boolean;
};

export const foreignSubsidiaryValidationSchema = Yup.object({
  incorporation: incorporationSchema,
  registeredAgent: registeredAgentAndBooksAndRecordsLocationSchema,
  booksRecordsLocation: registeredAgentAndBooksAndRecordsLocationSchema,
  majorityShareholders: Yup.array(majorityShareholdersSchema),
  capitalStructure: Yup.array(capitalStructureSchema),
  capitalContributions: Yup.array(capitalContributionsDistributionAndDividendsSchema),
  distributionAndDividends: Yup.array(capitalContributionsDistributionAndDividendsSchema),
  foreignBankAccounts: Yup.array(foreignBankAccountsSchema),
});
