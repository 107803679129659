import {
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Product, mapProductTypeToString } from "./products.type";
import { convertNumberToCurrency } from "../../../utils/utils";

type AlwaysSelectedProductsCard = {
  product: Product,
}

const AlwaysSelectedProductsCard = ({
  product,
}: AlwaysSelectedProductsCard) => {
  const getproductFinalPrice = (productDetail: Product) => {
    if (productDetail.originalPriceOverrideText) {
      return (
        <p className="p-0 m-0  text-bold">{productDetail.originalPriceOverrideText}</p>
      );
    }
    if (productDetail.discountPrice) {
      return (
        <p className="p-0 m-0  text-bold">
          ${convertNumberToCurrency(productDetail.discountPrice)}
        </p>
      );
    }
    if (productDetail.discountPrice === 0) {
      return (
        <p className="text-bold text-green p-0 m-0" >Free</p>
      );
    }
    return productDetail.price ? (
      <p className="p-0 m-0">
        ${convertNumberToCurrency(productDetail.price)}
      </p>
    ) : (<></>);
  };

  const getproductOriginalPrice = (productDetail: Product) => {
    if (productDetail.discountPrice && productDetail.discountPrice !== productDetail.price) {
      return (
        <p className="fs-6 text-muted strikethrough p-0 m-0">
          ${convertNumberToCurrency(productDetail.discountPrice)}
        </p>
      );
    }
    return productDetail.price ? (
      <p className="p-0 m-0">
        ${convertNumberToCurrency(productDetail.price)}
      </p>
    ) : (<></>);
  };

  const getDiscountTermText = (productDetail: Product) => {
    if (productDetail.discountTermText) {
      return (
        <p className="p-0 m-0 fs-6 text-muted text-uppercase">
          {productDetail.discountTermText}
        </p>
      );
    }
    return (<></>);
  };

  return (
    <Card>
      <Card.Header className="p-0 px-3">
        <span className="text-start fw-bold">
          {mapProductTypeToString[product.type]}
        </span>
      </Card.Header>
      <Card.Body>
        <Row className="justify-content-between">
          <Col className="d-flex justify-content-start">
            <Form.Group className="mb-3 d-flex justify-content-start" controlId="formNonStandardRequest">
              <Form.Switch
                type="checkbox"
                checked={true}
              />
              <span className="ps-2">{product.displayName}</span>
              {product.helperText && (
                <span className="tooltip-container ms-2">
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="right"
                    overlay={
                      <Tooltip>
                        {product.helperText}
                      </Tooltip>
                    }
                  >
                    <span>
                      <FeatherIcon className="cursor-pointer" icon="info" size="1.1em" />
                    </span>
                  </OverlayTrigger>
                </span>
              )}
            </Form.Group>
          </Col>
          <Col className="d-flex justify-content-end">
            <div>
              {getproductOriginalPrice(product)}
              {getproductFinalPrice(product)}
              {getDiscountTermText(product)}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AlwaysSelectedProductsCard;
