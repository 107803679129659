import {
  Row, Col, Form, Tooltip, OverlayTrigger,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { ProductPricingAPIData, SelectedProductProps } from "../../onboarding/product/product.type";

type ProductModalFieldProps = {
  product: ProductPricingAPIData;
  selectedProducts: SelectedProductProps[];
  handleSelectProducts: (product: ProductPricingAPIData) => any;
  checkProductDisabled: (product: ProductPricingAPIData, selectedProducts: SelectedProductProps[]) => boolean;
  checkIfProductIsSelected: (product: ProductPricingAPIData, selectedProducts: SelectedProductProps[]) => boolean;
};

const ProductModalField = ({
  product,
  handleSelectProducts,
  checkProductDisabled,
  selectedProducts,
  checkIfProductIsSelected,
}: ProductModalFieldProps) => {
  const getToolTipText = (productToolTip: ProductPricingAPIData): string => {
    let toolTipText = `${productToolTip.helperText} `;
    if (productToolTip.prerequisites?.length !== 0) {
      toolTipText += "To purchase this product, you must have one of the following: ";
      productToolTip.prerequisites?.forEach((prereq) => {
        toolTipText += `"${prereq.prerequisiteProductName}" `;
      });
    }
    return toolTipText;
  };

  return (
    <Form>
      <Row>
        <Col sm={9} className="my-3">
          <div className="d-flex">
            <Form.Check
              label={product.displayName}
              type="switch"
              checked={checkIfProductIsSelected(product, selectedProducts)}
              onChange={() => handleSelectProducts(product)}
              disabled={checkProductDisabled(product, selectedProducts)}
            />
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="right"
              overlay={
                <Tooltip>
                  {getToolTipText(product)}
                </Tooltip>
              }>
              <span><FeatherIcon className="ms-2" icon="info" size="1em" /></span>
            </OverlayTrigger>
          </div>
        </Col>
        <Col className="text-end">
          {product.price !== product.discountPrice
            && <p className="h5 mb-0 text-muted strikethrough">
              ${Number(product.price).toFixed(2)}
            </p>
          }
          <p className={`h2 mb-0 ${product.price !== product.discountPrice ? "mt-1" : "mt-3"}`}>
            {product.discountPrice > 0 ? `$${Number(product.discountPrice).toFixed(2)}` : "FREE"}
          </p>
          {product.termLength
            && <p className="h5 text-secondary fw-light">
              1 Year Discount
            </p>
          }
        </Col>
      </Row>
    </Form>
  );
};

export default ProductModalField;
