import {
  Formik, Field, Form, ErrorMessage,
} from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { signupPersonalDetails } from "./signup.api";
import { PersonalDetailError, PersonalDetailInput } from "./signup.type";

export default function Signup() {
  const navigate = useNavigate();
  const signupMutation = useMutation<any, AxiosError<PersonalDetailError>, PersonalDetailInput>((
    data,
  ) => signupPersonalDetails(data));

  return (
        <div>
            <Formik
                initialValues={{
                  firstName: "", lastName: "", email: "", title: "", password: "",
                }}
                validationSchema={Yup.object({
                  firstName: Yup.string().required("This field is required"),
                  lastName: Yup.string().required("This field is required"),
                  email: Yup.string().email("Invalid email address").required("This field is required"),
                  title: Yup.string().required("This field is required"),
                  password: Yup.string().required("This field is required"),
                })}
                onSubmit={(values, { setErrors }) => {
                  signupMutation.mutate(values, {
                    onError: (err) => {
                      const errors = err?.response?.data;
                      if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
                        setErrors(errors);
                      }
                    },
                    onSuccess: () => {
                      navigate("/onboarding/company1");
                    },
                  });
                }}>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-8 card">
                        <Form className="card-body my-5 mx-3">
                            <div className="row mb-3 mt-2">
                                <div className="col-12 col-md-6">
                                    <label htmlFor="firstName">First Name</label>
                                    <Field
                                      name="firstName"
                                      type="text"
                                      className={"form-control"}
                                      placeholder={"First Name"}
                                    />
                                    <ErrorMessage component="div" className="text-danger" name="firstName" />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label htmlFor="lastName">Last Name</label>
                                    <Field
                                      name="lastName"
                                      type="text"
                                      className={"form-control"}
                                      placeholder={"Last Name"}
                                    />
                                    <ErrorMessage component="div" className="text-danger mt-1" name="lastName" />
                                </div>
                            </div>
                            <div className="row mb-3 mt-2">
                                <div className="col-12 col-md-12">
                                    <label htmlFor="title">Title</label>
                                    <Field name="title" type="text" className={"form-control"} placeholder={"CEO"} />
                                    <ErrorMessage component="div" className="text-danger" name="title" />
                                </div>
                            </div>
                            <div className="row mb-3 mt-2">
                                <div className="col-12 col-md-12">
                                    <label htmlFor="email">Email Address</label>
                                    <Field
                                      name="email"
                                      type="text"
                                      className={"form-control"}
                                      placeholder={"name@address.com"}
                                    />
                                    <ErrorMessage component="div" className="text-danger" name="email" />
                                </div>
                            </div>
                            <div className="row mb-3 mt-2">
                                <div className="col-12 col-md-12">
                                    <label htmlFor="password">Password</label>
                                    <Field name="password" type="password" className={"form-control"} />
                                    <ErrorMessage component="div" className="text-danger" name="password" />
                                </div>
                            </div>
                            <hr className="row my-5" />
                            <div className="row mb-3 mt-2">
                                <div className="d-flex flex-row col-12 col-md-12 justify-content-end ">
                                    <button className="btn btn-lg btn-primary" type="submit">
                                        {signupMutation.isLoading ? <Spinner /> : <div>Continue</div>}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Formik>
        </div>
  );
}
