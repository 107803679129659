import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { convertNumberToCurrency } from "../../../utils/utils";
import { OrderAPIData, ProductPricingAPIData } from "../product/product.type";

type ProductDetail = {
    product: ProductPricingAPIData,
    openOrder?: OrderAPIData,
    handleSelectProduct: (productUuid: string) => void;
    handleRemoveProduct: (lineItemUuid: string) => void;
}

const ProductDetailRow = ({
  product,
  openOrder,
  handleSelectProduct,
  handleRemoveProduct,
}: ProductDetail) => {
  const orderLineItem = () => openOrder?.lineItems.find((lineItem) => lineItem.product.uuid === product.uuid);

  const handleSwitchClick = () => {
    const lineItem = orderLineItem();
    if (lineItem) {
      handleRemoveProduct(lineItem.uuid);
    } else {
      handleSelectProduct(product.uuid);
    }
  };

  const getproductFinalPrice = (productDetail: ProductPricingAPIData) => {
    if (productDetail.discountPriceTextOverride) {
      return (
        <p className="p-0 m-0 text-bold text-end">
          {productDetail.discountPriceTextOverride}
        </p>
      );
    }
    if (productDetail.discountPrice) {
      return (
        <p className="p-0 m-0 text-bold text-end">
          ${convertNumberToCurrency(productDetail.discountPrice)}
        </p>
      );
    }
    if (productDetail.discountPrice === 0) {
      return (
        <p className="text-bold text-green p-0 m-0 text-end" >Free</p>
      );
    }
    return productDetail.price ? (
      <p className="p-0 m-0 text-end">
        ${convertNumberToCurrency(productDetail.price)}
      </p>
    ) : (<></>);
  };

  const getproductOriginalPrice = (productDetail: ProductPricingAPIData) => {
    if (productDetail.originalPriceOverrideText) {
      return (
        <p className="fs-6 text-muted p-0 m-0 text-end">
          {productDetail.originalPriceOverrideText}
        </p>
      );
    }

    if (productDetail.priceTextOverride) {
      return (
        <p className="fs-6 text-muted strikethrough p-0 m-0 text-end">
          {productDetail.priceTextOverride}
        </p>
      );
    }

    if (productDetail.price && productDetail.discountPrice !== productDetail.price) {
      return (
        <p className="fs-6 text-muted strikethrough p-0 m-0 text-end">
          ${convertNumberToCurrency(productDetail.price)}
        </p>
      );
    }
    if (productDetail.price === productDetail.discountPrice) {
      return (<></>);
    }
    return productDetail.price ? (
      <p className="p-0 m-0">
        ${convertNumberToCurrency(productDetail.price)}
      </p>
    ) : (<></>);
  };

  const getDiscountTermText = (productDetail: ProductPricingAPIData) => {
    if (productDetail.discountTermText) {
      return (
        <p className="p-0 m-0 fs-6 text-muted text-uppercase text-end">
          {productDetail.discountTermText}
       </p>
      );
    }
    return (<></>);
  };

  const productDisabled = (): boolean => {
    if (product.prerequisites.length === 0) return false;
    const prereqUuids = product.prerequisites.map((prereq) => prereq.prerequisiteProductUuid);
    const selectedUuids = openOrder?.lineItems?.map((lineItem) => lineItem.product.uuid);
    if (!selectedUuids) return true;
    const filteredSelectedUuids = selectedUuids.filter(
      (prodUuid) => prereqUuids.findIndex((prereqUuid) => prereqUuid === prodUuid) !== -1,
    );
    return prereqUuids.length > filteredSelectedUuids.length;
  };

  let formSwitch = (
    <>
      <Form.Switch
        type="checkbox"
        checked={!!orderLineItem()}
        onChange={handleSwitchClick}
      />
      <span className="ps-2">{product.displayName}</span>
    </>
  );

  if (productDisabled()) {
    formSwitch = (
      <>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={
            <Tooltip>
              <span>
                You must purchase&nbsp;
                {product.prerequisites.map((prereq) => prereq.prerequisiteProductName).join(", ")}
                &nbsp;to purchase this product!
              </span>
            </Tooltip>
          }
        >
          <div className="d-flex justify-content-start align-items-center">
            <Form.Switch
              disabled={true}
              type="checkbox"
              checked={!!orderLineItem()}
              onChange={handleSwitchClick}
            />
            <span className="ps-2 text-muted">{product.displayName}</span>
          </div>
        </OverlayTrigger>
      </>
    );
  }

  return (
    <Row className="justify-content-between">
      <Col className="d-flex justify-content-start align-items-center" xs={7}>
        <Form.Group className="mb-3 d-flex justify-content-start align-items-center">
          {formSwitch}
          {product.helperText && (
            <span className="tooltip-container ms-2">
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="right"
                overlay={
                <Tooltip>
                    {product.helperText}
                </Tooltip>
                }
                >
                <span>
                    <FeatherIcon className="cursor-pointer" icon="info" size="1.1em" />
                </span>
              </OverlayTrigger>
            </span>
          )}
        </Form.Group>
      </Col>
      <Col className="d-flex justify-content-end">
      <div>
        {getproductOriginalPrice(product)}
        {getproductFinalPrice(product)}
        {getDiscountTermText(product)}
      </div>
      </Col>
    </Row>
  );
};

export default ProductDetailRow;
