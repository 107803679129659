import { useNavigate, useParams } from "react-router";
import { AxiosError } from "axios";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { ForeignSubsidiaryInfo } from "./foreignSubsidiary.type";
import ConfirmationModal from "../fusion-kit/ConfirmationModal";
import { deleteForeignSubsidiary } from "./foreignSubsidiary.api";

type ForeignSubsidiaryInfoCardProps = {
  foreignSubsidiaryInfo: ForeignSubsidiaryInfo;
}

const ForeignSubsidiaryInfoCard = ({ foreignSubsidiaryInfo }: ForeignSubsidiaryInfoCardProps) => {
  const { taxYear } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [foreignSubsidiaryToDelete, setForeignSubsidiaryToDelete] = useState<ForeignSubsidiaryInfo>();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

  const renderStatusIcon = () => {
    if (foreignSubsidiaryInfo.complete) {
      return (
        <FeatherIcon
          icon="check-circle"
          size="15"
          className="text-success ms-2"
        />
      );
    }
    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="right"
        overlay={
          <Tooltip>
            Please complete and submit.
          </Tooltip>
      }>
        <span>
          <FeatherIcon
            icon="help-circle"
            size="15"
            className="text-warning ms-2"
            style={{ cursor: "pointer" }}
          />
        </span>
      </OverlayTrigger>
    );
  };

  const handleDeleteForeignSubsidiary = (foreignSubsidiaryInfoToDelete: ForeignSubsidiaryInfo) => {
    setShowDeleteConfirmationModal(true);
    setForeignSubsidiaryToDelete(foreignSubsidiaryInfoToDelete);
  };

  const deleteForeignSubsidiaryMutation = useMutation<any, AxiosError<any>>(
    () => deleteForeignSubsidiary(foreignSubsidiaryToDelete ? foreignSubsidiaryToDelete?.uuid : ""),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["foreignSubsidiariesInfo"],
        });
        setShowDeleteConfirmationModal(false);
      },
    },
  );

  return (
    <>
      <div className="d-flex justify-content-between">
        <p className="h4">
          {foreignSubsidiaryInfo.name}
          {renderStatusIcon()}
        </p>
        <div className="d-flex">
          <FeatherIcon
            onClick={() => navigate(`/foreign_subsidiary/${taxYear}/${foreignSubsidiaryInfo.uuid}`)}
            icon="edit-2"
            size="15"
            className="text-secondary"
            style={{ cursor: "pointer" }}
          />
          <FeatherIcon
            onClick={() => handleDeleteForeignSubsidiary(foreignSubsidiaryInfo)}
            icon="trash"
            size="15"
            className="text-secondary ms-3"
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <hr className="mt-0" />
      <p>Name: {foreignSubsidiaryInfo.name}</p>
      <ConfirmationModal
        show={showDeleteConfirmationModal}
        handleClose={() => setShowDeleteConfirmationModal(false)}
        handleConfirm={() => deleteForeignSubsidiaryMutation.mutate()}
        titleText="Delete Foreign Subsidiary"
        bodyText="Are you sure you want to delete this Foreign Subsidiary?"
      />
    </>
  );
};

export default ForeignSubsidiaryInfoCard;
