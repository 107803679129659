import { TokenInput, TokenOutput } from "./bankingIntegration.type";
import { postFondoApi } from "../../utils/util.api";
import {
  API_BANKING_INTEGRATION_TOKEN_CALLBACK,
  API_CREATE_FINANCIAL_HUB_INSTITUTION,
  API_RUN_QBO_OBJECT_SYNC,
} from "../../constants/network-calls";

export async function getToken(tokenInput: TokenInput): Promise<TokenOutput> {
  const result = await postFondoApi(API_BANKING_INTEGRATION_TOKEN_CALLBACK, tokenInput);
  return result as TokenOutput;
}

export async function createInstitution(clientId: string): Promise<any> {
  const result = await postFondoApi(API_CREATE_FINANCIAL_HUB_INSTITUTION, { clientId });
  return result;
}

export async function runQboObjectSync(): Promise<any> {
  const result = await postFondoApi(API_RUN_QBO_OBJECT_SYNC, {});
  return result;
}
