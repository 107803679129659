import { Col, Row } from "react-bootstrap";
import { ForeignSubsidiaryInfo } from "./foreignSubsidiary.type";
import ForeignSubsidiaryInfoCard from "./ForeignSubsidiaryInfoCard";

type ForeignSubsidiariesInfoGridProps = {
  foreignSubsidiariesInfo: ForeignSubsidiaryInfo[];
}

const ForeignSubsidiariesInfoGrid = ({ foreignSubsidiariesInfo }: ForeignSubsidiariesInfoGridProps) => (
    <Row className="mt-5">
      {foreignSubsidiariesInfo.map((foreignSubsidiaryInfo: ForeignSubsidiaryInfo) => (
        <Col className="col-lg-4 col-md-6 mb-5">
          <ForeignSubsidiaryInfoCard foreignSubsidiaryInfo={foreignSubsidiaryInfo}/>
        </Col>
      ))}
    </Row>
);

export default ForeignSubsidiariesInfoGrid;
