import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import {
  CapitalContributionsDistributionAndDividendsFormFields,
  capitalContributionsDistributionAndDividendsFieldToLabelMap,
  capitalContributionsDistributionAndDividendsToTypeMap,
} from "../foreignSubsidiary.type";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import { currencySelectorChoices } from "../utils";
import { capitalContributionsDistributionAndDividendsSchema } from "../validationSchemas";

type AddCapitalContributionsDistributionAndDividendsModalProps = {
  show: boolean;
  header: string;
  capitalContributionsDistributionAndDividends?: CapitalContributionsDistributionAndDividendsFormFields;
  handleClose: () => any;
  handleSave: (
    capitalContributionsDistributionAndDividends:
    CapitalContributionsDistributionAndDividendsFormFields
  ) => void;
};

const AddCapitalContributionsDistributionAndDividendsModal = (
  props: AddCapitalContributionsDistributionAndDividendsModalProps,
) => {
  const initialValues = props.capitalContributionsDistributionAndDividends || {
    date: "",
    description: "",
    value: 0,
    currency: "",
  };

  return (
    <Modal show={props.show} onHide={() => props.handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={capitalContributionsDistributionAndDividendsSchema}
            onSubmit={(values) => {
              props.handleSave(values);
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="date"
                  labels={capitalContributionsDistributionAndDividendsFieldToLabelMap}
                  types={capitalContributionsDistributionAndDividendsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="description"
                  labels={capitalContributionsDistributionAndDividendsFieldToLabelMap}
                  types={capitalContributionsDistributionAndDividendsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="value"
                  labels={capitalContributionsDistributionAndDividendsFieldToLabelMap}
                  types={capitalContributionsDistributionAndDividendsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="currency"
                  labels={capitalContributionsDistributionAndDividendsFieldToLabelMap}
                  types={capitalContributionsDistributionAndDividendsToTypeMap}
                  selectorOptions={currencySelectorChoices}
                  includeSelectorEmptyValue={true}
                />
                <div className="row mb-3 mt-2">
                  <div className="d-flex flex-row col-12 col-md-12 justify-content-end">
                    <button className="btn btn-lg btn-primary" type="submit"><div>Save</div></button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCapitalContributionsDistributionAndDividendsModal;
