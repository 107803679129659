import { useState } from "react";
import { Table } from "react-bootstrap";
import ReportingTableRow from "./ReportTableRow";
import { OpenedReport, ReportingType } from "../Reporting.type";
import { formatDateToWords } from "../../../utils/common.util";
import { mapMonthDateToMonth, mapMonthDateToQuarter } from "../../finance-dashboard/constants";

type ReportingTableProps = {
  reportingData: ReportingType[];
  setShowRecipientGroupModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedReport: React.Dispatch<React.SetStateAction<OpenedReport>>;
}

const ReportingTable = ({ reportingData, setShowRecipientGroupModal, setSelectedReport }: ReportingTableProps) => {
  const [donwloadLoading, setDownloadLoading] = useState(false)

  const getTimeframeDate = (reportInstance: ReportingType): string => {
    const [year, month] = reportInstance.timeframeEnd.split("-");
    if (reportInstance.groupedBy === "MONTH") {
      return `${mapMonthDateToMonth[month]} ${year}`;
    }
    if (reportInstance.groupedBy === "QUARTER") {
      return `${mapMonthDateToQuarter[month]} ${year}`;
    }
    return `Year of ${year}`;
  };

  const determineFondoVerified = (report: ReportingType): boolean => {
    if (report.booksValidUntilDate === null || report.timeframeEnd > report.booksValidUntilDate) {
      return false;
    }
    return true;
  };

  return (
    <Table className='no-wrap mb-0' size="sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Created</th>
          <th>Timeframe</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {reportingData.map((report) => (
          <ReportingTableRow
            report={report}
            fondoVerified={determineFondoVerified(report)}
            createDate={formatDateToWords(report.createdAt)}
            timeframe={getTimeframeDate(report)}
            setShowRecipientGroupModal={setShowRecipientGroupModal}
            setSelectedReport={setSelectedReport}
            key={`${report.name}-${report.uuid}`}
            setDownloadLoading={setDownloadLoading}
            downloadLoading={donwloadLoading}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default ReportingTable;
