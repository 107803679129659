import {
  CURRENT_USER_COMPANY, ONBOARDING_ACTIVITY,
} from "../../../constants/network-calls";
import { ActivitySection1, ActivitySection2 } from "./activity.type";
import { getFondoApi, postFondoApi } from "../../../utils/util.api";

export async function saveActivity(data: any): Promise<string> {
  const result: any = await postFondoApi(ONBOARDING_ACTIVITY, data);
  return result;
}

export async function getCurrentUserCompany(): Promise<any> {
  const result = await getFondoApi(CURRENT_USER_COMPANY);
  return result;
}

export async function getActivity1(): Promise<ActivitySection1> {
  const result = await getFondoApi(ONBOARDING_ACTIVITY, { section: 1 });
  return result as ActivitySection1;
}

export async function getActivity2(): Promise<ActivitySection2> {
  const result = await getFondoApi(ONBOARDING_ACTIVITY, { section: 2 });
  return result as ActivitySection2;
}
