import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Spinner } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { AxiosError } from "axios";
import {
  Formik, Field, Form, ErrorMessage,
} from "formik";
import * as Yup from "yup";
import Cleave from "cleave.js/react";
import {
  FEDERAL_ID_TYPE_CHOICES, FEDERAL_TAX_CLASSIFICATION_CHOICES, FORM_ERROR_MSG,
  PAYMENT_DESCRIPTION_CHOICES, PAYMENT_METHOD_CHOICES, REQUIRED_1099_PAYMENT_METHODS,
} from "../../constants/general";
import { Subdivision } from "../../utils/util.type";
import { getCountrySubdivisions } from "../../utils/util.api";
import { Vendor } from "./filings1099.type";
import { saveVendor } from "./filings1099.api";

type Filings1099VendorFormProps = {
  editVendor: boolean;
  vendor?: Vendor;
  taxYear: string;
  successCallback: () => any;
};

export default function Filings1099VendorForm(props: Filings1099VendorFormProps) {
  const defaultFormValues: Vendor = {
    id: "",
    recipientName: "",
    paymentMethod: "",
    paymentMethodOther: "",
    paymentDescription: "",
    paymentDescriptionOther: "",
    email: "",
    totalAmountPaidPrevYear: 0.00,
    hasW9: "",
    federalIdNumber: "",
    federalIdType: "",
    federalTaxClassification: "",
    federalTaxClassificationOther: "",
    streetAddress: "",
    city: "",
    subdivision: "",
    postalCode: "",
  };

  const [formValues, setFormValues] = useState<Vendor>(defaultFormValues);

  const countrySubdivisionQuery = useQuery<Subdivision[], Error>(["countrySubdivision", "US"], getCountrySubdivisions, {
    refetchOnWindowFocus: false,
  });
  const vendorMutation = useMutation<any, AxiosError<any>, Vendor>(
    (data) => saveVendor({ vendor: data, taxYear: props.taxYear }),
    {
      onSuccess: () => {
        props.successCallback();
      },
    },
  );

  useEffect(() => {
    if (props.editVendor && props.vendor) {
      setFormValues(props.vendor);
    } else {
      setFormValues(defaultFormValues);
    }
  }, [props]);

  function renderCountrySubdivisionList() {
    return (
      countrySubdivisionQuery.data?.map((subdivision) => (
        <option key={subdivision.id} value={subdivision.code}>
          {subdivision.name}
        </option>
      ))
    );
  }

  function renderList(list: any[]) {
    return list.map((element) => (
      <option key={element.key} value={element.key}>
        {element.value}
      </option>
    ));
  }

  function renderVendorForm() {
    return (<>
      <Formik
        initialValues={formValues}
        enableReinitialize={true}
        validationSchema={Yup.object({
          recipientName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
          paymentMethod: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
          email: Yup.string().email("Enter a valid email address."),
        })}
        onSubmit={(values, { setErrors }) => {
          const vendor: Vendor = values;
          vendorMutation.mutate(vendor, {
            onError: (err) => {
              const errors = err?.response?.data;
              if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
                setErrors(errors);
              }
            },
          });
        }}>
        {({ values, setFieldValue, getFieldProps }) => (
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Form>
                <div id="form_first_part">
                  <div className="row mb-3 pb-3">
                    <div className="col-md-12">
                      <label htmlFor="recipientName">Recipient name</label>
                      <Field name="recipientName" type="text" className={"form-control"} />
                      <ErrorMessage component="div" className="text-danger" name="recipientName" />
                    </div>
                  </div>
                  <div className="row mb-3 pb-3">
                    <div className="col-md-12">
                      <label htmlFor="paymentMethod">Payment method</label>
                      <Field
                        name="paymentMethod"
                        as="select"
                        className={"form-control form-select"}
                        onChange={(e: any) => { setFieldValue("paymentMethod", e.target.value); }}>
                        <option key="emptyValue" value={""}>--------</option>
                        {renderList(PAYMENT_METHOD_CHOICES)}
                      </Field>
                      <ErrorMessage component="div" className="text-danger mt-1" name="paymentMethod" />
                    </div>
                  </div>
                  {values.paymentMethod && values.paymentMethod === "Other"
                    && <div className="row mb-3 pb-3">
                      <div className="col-md-12">
                        <label htmlFor="paymentMethodOther">Payment method - Other</label>
                        <Field name="paymentMethodOther" type="text" className={"form-control"} />
                        <ErrorMessage component="div" className="text-danger" name="paymentMethodOther" />
                      </div>
                    </div>
                  }
                </div>
                {!REQUIRED_1099_PAYMENT_METHODS.includes(values.paymentMethod)
                  && <div id="form_1099_not_needed_message_container">
                    <p className="text-gray-700">
                      Based on your selected payment method, a 1099 is not required for this vendor.
                    </p>
                  </div>
                }
                {REQUIRED_1099_PAYMENT_METHODS.includes(values.paymentMethod)
                  && <>
                    <div id="form_second_part">
                      <div className="row mb-3 pb-3">
                        <div className="col-md-12">
                          <label htmlFor="paymentDescription">Payment description</label>
                          <Field
                            name="paymentDescription"
                            as="select"
                            className={"form-control form-select"}
                            onChange={(e: any) => { setFieldValue("paymentDescription", e.target.value); }}>
                            <option key="emptyValue" value={""}>--------</option>
                            {renderList(PAYMENT_DESCRIPTION_CHOICES)}
                          </Field>
                          <ErrorMessage component="div" className="text-danger mt-1" name="paymentDescription" />
                        </div>
                      </div>
                      {values.paymentDescription && values.paymentDescription === "Other"
                        && <div className="row mb-3 pb-3">
                          <div className="col-md-12">
                            <label htmlFor="paymentDescriptionOther">Payment desciption - Other</label>
                            <Field name="paymentDescriptionOther" type="text" className={"form-control"} />
                            <ErrorMessage component="div" className="text-danger" name="paymentDescriptionOther" />
                          </div>
                        </div>
                      }
                      <div className="row mb-3 pb-3">
                        <div className="col-md-12">
                          <label htmlFor="email">Email</label>
                          <Field name="email" type="text" className={"form-control"} />
                          <ErrorMessage component="div" className="text-danger" name="email" />
                        </div>
                      </div>
                      <div className="row mb-3 pb-3">
                        <div className="col-md-12">
                          <label htmlFor="totalAmountPaidPrevYear">
                            Total amount paid{props.taxYear ? (` in ${props.taxYear}`) : ""}
                          </label>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text input-side-box">$</span>
                            </div>
                            <Cleave
                              {...getFieldProps("totalAmountPaidPrevYear")}
                              options={{
                                numeral: true,
                                numeralThousandsGroupStyle: "thousand",
                              }}
                              placeholder=""
                              className="form-control"
                            />
                            {/* <Field name="totalAmountPaidPrevYear" type="text" className={'form-control'} /> */}
                          </div>
                          <ErrorMessage component="div" className="text-danger" name="totalAmountPaidPrevYear" />
                        </div>
                      </div>
                      <div className="row mb-3 pb-3">
                        <div className="col-md-12">
                          <label htmlFor="hasW9">
                            Do you have a W-9 or the following information for this vendor:
                            Name, Address, Tax ID, & Entity Type?
                          </label>
                          <Field
                            name="hasW9"
                            as="select"
                            className={"form-control form-select"}
                            onChange={(e: any) => { setFieldValue("hasW9", e.target.value); }}>
                            <option key="emptyValue" value={""}>--------</option>
                            <option key="Yes" value={"Yes"}>Yes</option>
                            <option key="No" value={"No"}>No</option>
                          </Field>
                          <ErrorMessage component="div" className="text-danger" name="hasW9" />
                        </div>
                      </div>
                    </div>
                    {values.hasW9 && values.hasW9 === "No"
                      && <div id="form_missing_w9_message_container" className="my-5">
                        <div>
                          <h3>
                            <FeatherIcon
                              className="me-1 mb-1 text-warning"
                              icon="alert-triangle"
                              size="1.3em"
                            />Important!
                          </h3>
                          <p className="mb-4 text-gray-700">
                            Most contractors will send you a W-9 when you start working
                            with them - this contains their address, tax ID, business type,
                            and other important info to file a 1099. In order to file a 1099,
                            a vendor must submit a completed Form W-9 to you.
                            There are penalties associated with unfiled 1099s your company may
                            face as well as potentially being required to withhold and submit
                            taxes that are by the vendor.
                          </p>
                        </div>
                        <div className="text-gray-700 mb-2">
                          <h3 className="text-decoration-underline">Next Steps</h3>
                          <p className="font-weight-bold">
                            1. Request W-9 from Vendor
                          </p>
                          <p>
                            Send an email to your vendor with the following message (or something similar):
                          </p>
                          <p>
                            “Hello [insert vendor name] - in order to issue you a Form 1099 for tax year
                            {` ${props.taxYear}`}, we need to collect a completed and signed Form W-9 from you. Here is
                            a blank template (link: https://www.irs.gov/pub/irs-pdf/fw9.pdf) of Form W-9 you can use to
                            complete, download, sign and email back to us.“
                          </p>
                          <p className="font-weight-bold">
                            2. Come back here and update this vendor information as soon as possible (by December 31).
                          </p>
                        </div>
                      </div>
                    }
                    {values.hasW9 && values.hasW9 === "Yes"
                      && <div id="form_third_part">
                        <div className="row mb-3 pb-3">
                          <div className="col-md-12">
                            <label htmlFor="federalIdType">Federal ID Type (EIN, SSN, ITIN or ATIN)</label>
                            <Field
                              name="federalIdType"
                              as="select"
                              className={"form-control form-select"}
                              onChange={(e: any) => { setFieldValue("federalIdType", e.target.value); }}>
                              <option key="emptyValue" value={""}>--------</option>
                              {renderList(FEDERAL_ID_TYPE_CHOICES)}
                            </Field>
                            <ErrorMessage component="div" className="text-danger" name="federalIdType" />
                          </div>
                        </div>
                        <div className="row mb-3 pb-3">
                          <div className="col-md-12">
                            <label htmlFor="federalIdNumber">Federal ID Number</label>
                            <Field name="federalIdNumber" type="text" className={"form-control"} />
                            <ErrorMessage component="div" className="text-danger" name="federalIdNumber" />
                          </div>
                        </div>
                        <div className="row mb-3 pb-3">
                          <div className="col-md-12">
                            <label htmlFor="federalTaxClassification">Federal Tax Classification</label>
                            <Field
                              name="federalTaxClassification"
                              as="select"
                              className={"form-control form-select"}
                              onChange={(e: any) => { setFieldValue("federalTaxClassification", e.target.value); }}>
                              <option key="emptyValue" value={""}>--------</option>
                              {renderList(FEDERAL_TAX_CLASSIFICATION_CHOICES)}
                            </Field>
                            <ErrorMessage component="div" className="text-danger" name="federalTaxClassification" />
                          </div>
                        </div>
                        {values.federalTaxClassification && values.federalTaxClassification === "Other"
                          && <div className="row mb-3 pb-3">
                            <div className="col-md-12">
                              <label htmlFor="federalTaxClassificationOther">Federal Tax Classification - Other</label>
                              <Field name="federalTaxClassificationOther" type="text" className={"form-control"} />
                              <ErrorMessage
                                component="div"
                                className="text-danger"
                                name="federalTaxClassificationOther"
                              />
                            </div>
                          </div>
                        }
                        <div className="row mb-3 pb-3">
                          <div className="col-md-12">
                            <label htmlFor="streetAddress">Street Address</label>
                            <Field name="streetAddress" type="text" className={"form-control"} />
                            <ErrorMessage component="div" className="text-danger" name="streetAddress" />
                          </div>
                        </div>
                        <div className="row mb-3 pb-3">
                          <div className="col-md-12">
                            <label htmlFor="city">City</label>
                            <Field name="city" type="text" className={"form-control"} />
                            <ErrorMessage component="div" className="text-danger" name="city" />
                          </div>
                        </div>
                        <div className="row mb-3 pb-3">
                          <div className="col-md-12">
                            <label htmlFor="subdivision">State</label>
                            <Field
                              name="subdivision"
                              as="select"
                              className={"form-control form-select"}
                              onChange={(e: any) => { setFieldValue("subdivision", e.target.value); }}>
                              <option key="emptyValue" value={""}>--------</option>
                              {renderCountrySubdivisionList()}
                            </Field>
                            <ErrorMessage component="div" className="text-danger" name="subdivision" />
                          </div>
                        </div>
                        <div className="row mb-3 pb-3">
                          <div className="col-md-12">
                            <label htmlFor="postalCode">Postal Code</label>
                            <Field name="postalCode" type="text" className={"form-control"} />
                            <ErrorMessage component="div" className="text-danger" name="postalCode" />
                          </div>
                        </div>
                      </div>
                    }
                  </>
                }
                <hr className="row my-4" />
                <div className="row">
                  <div className="d-flex flex-row col-md-12 justify-content-end">
                    <button className="btn btn-md btn-primary" type="submit">
                      {vendorMutation.isLoading ? <Spinner /> : <div>{props.editVendor ? "Edit" : "Add"} Vendor</div>}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>);
  }

  return (<>
    {renderVendorForm()}
  </>);
}
