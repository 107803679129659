import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

type CurrencyFieldProps = {
  value: string | number | string[] | undefined;
  placeholder?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inline?: boolean;
}

const CurrencyField = (
  {
    value, placeholder, name, onChange, onBlur, inline,
  }: CurrencyFieldProps,
) => {
  const defaultMaskOptions = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 15, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask(defaultMaskOptions);

  return (
    <>
      <div className="input-group-prepend">
        <span
          className={`input-group-text input-side-box ${inline ? "p-1" : ""}`}
          style={{ borderRight: "0" }}
        >$</span>
      </div>
      <MaskedInput
        mask={currencyMask}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        className='form-control m-0 p-1'
      />
    </>
  );
};

export default CurrencyField;
