import { useFormikContext } from "formik";
import {
  CorpTaxFormFields,
  addressFieldToTypeMap,
  addressFieldToLabelMap,
} from "./corporateTax.type";
import AddressFieldGroup from "../forms/formik-components/AddressFieldGroup";
import FondoFormGroup from "../forms/formik-components/FondoFormGroup";

export default function Address() {
  const formikContext = useFormikContext<CorpTaxFormFields>();

  return (
    <div>
      <AddressFieldGroup
        formikContext={formikContext}
        baseName="address"
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName={"address.phone"}
        types={addressFieldToTypeMap}
        labels={addressFieldToLabelMap}
      />
      <FondoFormGroup
        fieldName="address.description"
        formikContext={formikContext}
        types={addressFieldToTypeMap}
        labels={addressFieldToLabelMap}
      />
      <FondoFormGroup
        fieldName="address.stateIdNumber"
        formikContext={formikContext}
        types={addressFieldToTypeMap}
        labels={addressFieldToLabelMap}
        helperText={{
          type: "whereToFindThis",
          body:
          <>
           You can find your Business Corporate ID
          <a href="https://opencorporates.com" target="_blank" rel="noopener noreferrer">
            here.
          </a>
          </>,
        }}
      />
    </div >
  );
}
