import { Badge, Card } from "react-bootstrap";
import { OrderAPIData } from "../../onboarding/product/product.type";
import { formatDateToWords } from "../../../utils/common.util";
import OrderTable from "./OrderTable";

type ClosedOrdersProps = {
  orders: OrderAPIData[];
};

const ClosedOrders = ({ orders }: ClosedOrdersProps) => (
  <>
    {orders.map((order, idx) => (
      <Card>
        <Card.Header>
          <span>
            <span className="fw-bold">Order #{orders.length - idx}</span>
            <span className="ps-3 text-secondary">
              {formatDateToWords(order.createdAt)}
            </span>
          </span>
          <Badge bg="danger">
            Order Closed
          </Badge>
        </Card.Header>
        <Card.Body className="p-0">
          <OrderTable order={order} />
        </Card.Body>
      </Card>
    ))}
  </>
);

export default ClosedOrders;
