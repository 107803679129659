import { CashFlowStats } from "../dashboard/dashboard.type";
import { BurnRateResponse, CashBalanceOverTimeResponse } from "../finance-dashboard/financeDashboard.type";

export type ReportingType = {
  uuid: string,
  name: string,
  fondoVerified: boolean,
  executiveSummary: boolean,
  executiveSummaryOptions: ExecutiveSummaryOptions,
  runway: boolean,
  runwayOptions: RunwayOptions,
  profitAndLoss: boolean,
  profitAndLossOptions: ProfitAndLossOptions,
  balanceSheet: boolean,
  balanceSheetOptions: BalanceSheetOptions,
  timeframeStart: string,
  timeframeEnd: string,
  groupedBy: string,
  createdAt: string,
  booksValidUntilDate: string | null,
  reportingData: ReportingData,
}

export type ReportingData = {
  cashFlowStats: CashFlowStats,
  burnRateList: BurnRateResponse,
  monthlyBalances: CashBalanceOverTimeResponse,  
  profitAndLoss: QBOReportingData,
  balanceSheet: QBOReportingData,
}

export type ReportingSubmission = {
  name: string,
  executiveSummary: boolean,
  runway: boolean,
  profitAndLoss: boolean,
  balanceSheet: boolean,
  timeframeStart: string,
  timeframeEnd: string,
  groupedBy: string,
  createdBy: number,
}

export type ReportPDFResponse = {
  pdfData: string;
}

export type QBOReportingHeader = {
  name: string,
}

export type QBOReportingResult = {
  amount: string[],
  name: string,
  baseName?: string,
  children?: QBOReportingResult[],
}

export type QBOReportingData = {
  headers: QBOReportingHeader[],
  results: QBOReportingResult[],
}

export type OpenedReport = {
  uuid: string,
  name: string,
}

export type RecipientEmail = {
  firstName: string,
  lastName: string,
  email: string,
}

export type CreateRecipientGroupType = {
  name: string,
  emailList: RecipientEmail[],
}

export type RecipientGroupType = CreateRecipientGroupType & {
  id: number,
}

export enum RecipientGroupRouter {
  SHARE = "SHARE",
  SELECT_GROUPS = "SELECT_GROUPS",
  CREATE_GROUP = "CREATE_GROUP",
  EDIT_GROUP = "EDIT_GROUP",
  SENT_GROUP = "SENT_GROUP",
}

export type SendingRecipientGroup = {
  name: string,
  recipientGroupIds: number[],
}


export type DescriptionType = {
  enabled?: boolean,
  text?: string,
}
export type RunwayOptions = {
  description?: DescriptionType
  cashBalance?: boolean;
  funding?: boolean;
  burnRate?: boolean;
  averageBurnRate?: boolean;
  runway?: boolean;
  cashZeroDate?: boolean;
}

export const defaultRunwayOptions: RunwayOptions = {
  description: {enabled: true, text: ""},
  cashBalance: true,
  funding: true,
  burnRate: true,
  averageBurnRate: true,
  runway: true,
  cashZeroDate: true,
}

export type ExecutiveSummaryOptions = {
  description?: DescriptionType
  totalCash?: boolean;
  netBurn?: boolean;
  runway?: boolean;
}

export const defaultExecutiveSummaryOptions: ExecutiveSummaryOptions = {
  description: {enabled: true, text: ""},
  totalCash: true,
  netBurn: true,
  runway: true,
}

export type ProfitAndLossOptions = {
  description?: DescriptionType
  breakdown: boolean;
  revenue: boolean;
  expenses: boolean;
  netIncome: boolean;
  chart: boolean;
  table: boolean;
}

export const defaultProfitAndLossOptions: ProfitAndLossOptions = {
  description: {enabled: true, text: ""},
  breakdown: true,
  revenue: true,
  expenses: true,
  netIncome: true,
  chart: true,
  table: true
}

export type BalanceSheetOptions = {
  description?: DescriptionType
  liabilitiesAndEquity: boolean;
  table: boolean;
}

export const defaultBalanceSheetOptions: BalanceSheetOptions = {
  description: {enabled: true, text: ""},
  liabilitiesAndEquity: true,
  table: true
}

type ReportInfo = {
  subject: string,
  message?: string,
  recipientGroupIds: number[],
}

export type GroupsToSendType = {
  reportingUuid: string,
  reportInfo: ReportInfo,
}

export const sectionTypeKeys = {
  executiveSummary: "executiveSummaryOptions",
  profitAndLoss: "profitAndLossOptions",
  balanceSheet: "balanceSheetOptions",
  runway: "runwayOptions",
};

export const sectionTypeOptions = {}
export type SectionType = keyof typeof sectionTypeKeys;