import { useState } from "react";
import { useParams } from "react-router";
import { FieldArray, Formik, FormikProps } from "formik";
import { useQuery } from "@tanstack/react-query";
import fondoLogo from "../../assets/img/fondo-icon.png";
import {
  ForeignSubsidiaryFormFields,
  ForeignSubsidiaryFormStep,
  foreignSubsidiaryValidationSchema,
} from "./foreignSubsidiary.type";
import { UserCompaniesResponse } from "../side-nav/sidenav.type";
import { getUserCompanies } from "../side-nav/sidenav.api";
import ForeignSubsidiaryFormHeader from "./ForeignSubsidiaryFormHeader";
import RegisteredAgent from "./sections/RegisteredAgent";
import BooksRecordsLocation from "./sections/BooksRecordsLocation";
import CapitalStructure from "./sections/CapitalStructure";
import Incorporation from "./sections/Incorporation";
import MajorityShareholders from "./sections/MajorityShareholders";
import CapitalContributions from "./sections/CapitalContributions";
import formSteps from "./constants";
import DistributionAndDividends from "./sections/DistributionAndDividends";
import ForeignBankAccounts from "./sections/ForeignBankAccounts";
import { getForeignSubsidiaryFormsData } from "./foreignSubsidiary.api";

const ForeignSubsidiaryForm = () => {
  const { taxYear, uuid } = useParams();

  const { data: userCompaniesQuery } = useQuery<UserCompaniesResponse, Error>(["userCompanies"], getUserCompanies, {
    refetchOnWindowFocus: false,
  });

  const { data: foreignSubsidiaryFormsData } = useQuery(
    ["foreignSubsidiaryFormsData", uuid],
    () => getForeignSubsidiaryFormsData(uuid || ""),
    {
      refetchOnWindowFocus: false,
      enabled: !!uuid,
    },
  );

  const [selectedStep, setSelectedStep] = useState<ForeignSubsidiaryFormStep>(formSteps[0]);
  const [isValidating, setIsValidating] = useState(false);

  const navigatePage = (formik: FormikProps<ForeignSubsidiaryFormFields>, direction: string) => {
    let pageCounter = -1;
    let currentPageIndex = 0;
    const pages = formSteps.filter((step) => {
      if (typeof step.enabled === "function") {
        if (step.enabled(formik)) {
          pageCounter += 1;
          if (step.name === selectedStep.name) {
            currentPageIndex = pageCounter;
          }
          return step.enabled(formik);
        }
      } else if (step.enabled) {
        pageCounter += 1;
        if (step.name === selectedStep.name) {
          currentPageIndex = pageCounter;
        }
        return step.enabled;
      }
      return false;
    });

    if (currentPageIndex < pages.length - 1 && direction === "forward") {
      setSelectedStep(pages[currentPageIndex + 1]);
    } else if (currentPageIndex > 0 && direction === "back") {
      setSelectedStep(pages[currentPageIndex - 1]);
    }
  };

  const hasError = (formik: any, stepName: string): boolean => (
    formik.errors && formik.errors?.[stepName]
  );

  const renderSidenavItems = (formik: FormikProps<ForeignSubsidiaryFormFields>) => (
    <>
      <ul className="navbar-nav mx-auto mt-3" id="fondo_progress_sidebar">
        {formSteps.filter((step) => {
          if (typeof step.enabled === "function") {
            return step.enabled(formik);
          }
          return step.enabled;
        }).map((step) => (
          <li className="nav-item mb-4">
            <a id={step.name} className={`nav-link${step.name === selectedStep.name ? " active" : ""}`}
              data-toggle="tab" href='#{{ form_key }}'
              onClick={(event) => {
                event.preventDefault();
                step.taxYear = taxYear;
                setSelectedStep(step);
              }}>
              {hasError(formik, step.name)
                && <i
                  className="fe fe-alert-circle text-danger ms-2"
                  style={{ marginBottom: "-0.1em" }}>
                  </i>
              }
              {!hasError(formik, step.name)
                && <i
                  className={`fe text-muted ms-2 ${step.icon || "fe-circle"}`}
                  style={{ marginBottom: "-0.1em" }}>
                  </i>
              }
              {step.displayLabel}
            </a>
          </li>
        ))}
      </ul>
    </>
  );

  const renderSidenav = (formik: FormikProps<ForeignSubsidiaryFormFields>) => (
    <nav
      className="page-sidebar navbar navbar-vertical fixed-left navbar-expand-md navbar-light d-none d-md-block"
      id="sidebar_container"
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="/dashboard">
          <img src={fondoLogo} alt="Fondo" className="navbar-brand-img mx-auto" />
        </a>
            <div className="text-center mb-3">
              {userCompaniesQuery?.selectedCompany.legalName || userCompaniesQuery?.selectedCompany.name}
            </div>
            <div className="collapse navbar-collapse mt-5" id="sidebarCollapse">
              {renderSidenavItems(formik)}
            </div>
      </div>
    </nav>
  );

  const renderSelectedForm = () => (
    <>
      {selectedStep.name === "incorporation" && <Incorporation />}
      {selectedStep.name === "registeredAgent" && <RegisteredAgent />}
      {selectedStep.name === "booksRecordsLocation" && <BooksRecordsLocation />}
      {selectedStep.name === "majorityShareholders" && (
        <FieldArray
          name="majorityShareholders"
          render={(arrayHelpers) => (
            <MajorityShareholders arrayHelpers={arrayHelpers} />
          )}
        />
      )}
      {selectedStep.name === "capitalStructure" && (
        <FieldArray
          name="capitalStructure"
          render={(arrayHelpers) => (
            <CapitalStructure stepInfo={selectedStep} arrayHelpers={arrayHelpers} />
          )}
        />
      )}
      {selectedStep.name === "capitalContributions" && (
        <FieldArray
          name="capitalContributions"
          render={(arrayHelpers) => (
            <CapitalContributions arrayHelpers={arrayHelpers}/>
          )}
        />
      )}
      {selectedStep.name === "distributionAndDividends" && (
        <FieldArray
          name="distributionAndDividends"
          render={(arrayHelpers) => (
            <DistributionAndDividends arrayHelpers={arrayHelpers}/>
          )}
        />
      )}
      {selectedStep.name === "foreignBankAccounts" && (
        <FieldArray
          name="foreignBankAccounts"
          render={(arrayHelpers) => (
            <ForeignBankAccounts arrayHelpers={arrayHelpers}/>
          )}
        />
      )}
    </>
  );

  const renderFormComponents = (formik: FormikProps<ForeignSubsidiaryFormFields>) => (
    <>
      <div className="main-content" style={{ marginLeft: "250px" }}>
        <div className="container-fluid">
          {uuid && (
            <ForeignSubsidiaryFormHeader
              taxYear={taxYear || ""}
              foreignSubsidiaryUuid={uuid}
              setIsValidating={setIsValidating}
              isValidating={isValidating}
            />
          )}

          <form name="tax-onboarding" id="tax-onboarding" action="" method="post" >
            <input type='hidden' name='active_tab' value='{{ active }}'></input>
            <input type='hidden' name='final_submit' value=''></input>

            <div className="tab-content">
              <div id="incorporation" className="fade show" role="tabpanel">
                <div className="card">
                  <div className="card-body">
                    {renderSelectedForm()}
                    {selectedStep.name !== formSteps[0].name
                      && (<button
                            type="button"
                            className="btn btn-ghost-secondary text-muted navigation-btn"
                            data-toggle="xtab"
                            data-target="#incorporation-nav" role="tab" aria-controls="incorporation"
                          >
                        <i style={{ verticalAlign: "middle" }}
                          className="fe fe-chevron-left no-click"
                        ></i>
                        <span
                          className="no-click"
                          style={{ verticalAlign: "middle" }}
                          onClick={() => navigatePage(formik, "back")}
                        >
                          Previous
                        </span>
                      </button>)}
                      {selectedStep.name !== formSteps[formSteps.length - 1].name
                      && (<button type="button" className="btn btn-primary float-end navigation-btn" data-toggle="xtab"
                        data-target="#address-nav" role="tab" aria-controls="address">
                        <span
                          className="no-click"
                          style={{ verticalAlign: "middle" }}
                          onClick={() => navigatePage(formik, "forward")}
                        >
                          Next
                        </span>
                        <i style={{ verticalAlign: "middle" }}
                          className="fe fe-chevron-right no-click"
                        ></i>
                      </button>)}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div >
      </div >
    </>
  );

  const initialValues: ForeignSubsidiaryFormFields = foreignSubsidiaryFormsData;
  if (!initialValues) {
    return <></>;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(value) => console.log(value)}
      validationSchema={foreignSubsidiaryValidationSchema}
      validateOnChange={isValidating}
      validateOnBlur={isValidating}
    >
      {(formik) => (
        <>
          {renderSidenav(formik)}
          {renderFormComponents(formik)}
        </>
      )}
    </Formik>);
};

export default ForeignSubsidiaryForm;
