import { Card } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

type LiabilitiesAndEquityCardProps = {
  assets: string;
  liabilitiesAndEquity: string;
}

const LiabilitiesAndEquityCard = ({ assets, liabilitiesAndEquity }: LiabilitiesAndEquityCardProps) => (
  <Card>
    <Card.Body className="p-0">
      <div className="border-bottom p-4 pb-3">
        <Card.Title className="text-uppercase text-muted mb-2">
          <span className="h5 text-secondary">Liabilities & Equity</span>
        </Card.Title>
        <span className="h1 mb-0">
          {liabilitiesAndEquity}
        </span>
      </div>
      <div className="p-4">
        <div className="d-flex justify-content-between">
          <span>
            <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
            Assets
          </span>
          <span>{assets}</span>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <span>
            <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
            Equity & Liabilities
          </span>
          <span>{liabilitiesAndEquity}</span>
        </div>
      </div>
    </Card.Body>
  </Card>
)

export default LiabilitiesAndEquityCard;
