import * as Yup from "yup";
import { FORM_ERROR_MSG } from "../../../constants/general";

export const taxPlanValidationSchema = Yup.object({
  wouldLikeAFreeTaxPlan: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  totalEstimatedUsPayrollDecimal: Yup.string().nullable().when("wouldLikeAFreeTaxPlan", {
    is: "true",
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  totalEstimatedInternationalPayrollDecimal: Yup.string().nullable().when("wouldLikeAFreeTaxPlan", {
    is: "true",
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  totalEstimatedRevenueDecimal: Yup.string().nullable().when("wouldLikeAFreeTaxPlan", {
    is: "true",
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  totalEstimatedExpensesDecimal: Yup.string().nullable().when("wouldLikeAFreeTaxPlan", {
    is: "true",
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  totalEstimatedAssetsDecimal: Yup.string().nullable().when("wouldLikeAFreeTaxPlan", {
    is: "true",
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
});
