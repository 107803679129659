import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import {
  MajorityShareholdersFormFields, majorityShareholdersFieldToLabelMap, majorityShareholdersToTypeMap,
} from "../foreignSubsidiary.type";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import AddressFieldGroup from "../../forms/formik-components/AddressFieldGroup";
import { majorityShareholdersSchema } from "../validationSchemas";

type AddMajorityShareholdersModalProps = {
  show: boolean;
  header: string;
  majorityShareholders?: MajorityShareholdersFormFields;
  handleClose: () => any;
  handleSave: (majorityShareholders: MajorityShareholdersFormFields) => void;
};

const AddMajorityShareholdersModal = (props: AddMajorityShareholdersModalProps) => {
  const initialValues = props.majorityShareholders || {
    name: "",
    country: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    sharesAtYearStart: 0,
    sharesAtYearEnd: 0,
  };

  return (
    <Modal show={props.show} onHide={() => props.handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={majorityShareholdersSchema}
            onSubmit={(values) => {
              props.handleSave(values);
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="name"
                  labels={majorityShareholdersFieldToLabelMap}
                  types={majorityShareholdersToTypeMap}
                />
                <AddressFieldGroup
                  formikContext={formik}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="sharesAtYearStart"
                  labels={majorityShareholdersFieldToLabelMap}
                  types={majorityShareholdersToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="sharesAtYearEnd"
                  labels={majorityShareholdersFieldToLabelMap}
                  types={majorityShareholdersToTypeMap}
                />
                <div className="row mb-3 mt-2">
                  <div className="d-flex flex-row col-12 col-md-12 justify-content-end">
                    <button
                      className="btn btn-lg btn-primary"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddMajorityShareholdersModal;
