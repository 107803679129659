import Cleave from "cleave.js/react";
import { FormikContextType } from "formik";
import { useState } from "react";
import { InputGroup } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

type EinFieldProps = {
  formikContext: FormikContextType<any>;
  fieldName: string;
  invalid?: boolean;
  enablePasswordType?: boolean;
}

const EinField = (
  {
    formikContext,
    fieldName,
    invalid,
    enablePasswordType = false,
  }: EinFieldProps,
) => {
  const [visible, setVisible] = useState(false);
  return (
    <InputGroup className='input-group-merge mb-3'>
      <Cleave
        {...formikContext.getFieldProps(fieldName)}
        options={{
          delimiter: "-",
          blocks: [2, 7],
          numericOnly: true,
        }}
        placeholder="01-2345678"
        className={`form-control ${invalid ? "is-invalid" : ""}`}
        type={!visible && enablePasswordType ? "password" : "text"}
      />
      <InputGroup.Text>
        { enablePasswordType
          && <FeatherIcon
            icon={visible ? "eye-off" : "eye"}
            size='1em'
            onClick={() => setVisible(!visible)}
            style={{ cursor: "pointer" }}
          />
        }
      </InputGroup.Text>
    </InputGroup>
  );
};

export default EinField;
