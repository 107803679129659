import { useContext } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { LoggedInUserContext } from "../../../context/LoggedInUserContext";
import OrderProductsSection from "./OrderProductsSection";

const OrderCard = () => {
  const { currentCompanyName } = useContext(LoggedInUserContext);
  return (
    <div className="px-6 py-5">
      <Row>
        <h3 className="mb-1">Estimate</h3>
        <span className="subtext subtext-bold text-grey">
          {new Date().toLocaleString("en-US", { year: "numeric", month: "long", day: "numeric" })}
        </span>
      </Row>
      <Row className="justify-content-between mt-3">
        <Col className="d-flex justify-content-start">
            <span className="subtext-small text-grey">TO</span>
        </Col>
        <Col className="d-flex justify-content-end">
            <span className="subtext-small text-grey">FROM</span>
        </Col>
      </Row>
      <hr className="my-2" ></hr>
      <Row className="justify-content-between mt-3">
        <Col className="d-flex justify-content-start">
          <h5>{currentCompanyName}</h5>
        </Col>
        <Col className="d-flex justify-content-end">
          <h5>Fondo</h5>
        </Col>
      </Row>
      <Alert
        className="my-3"
        style={{
          backgroundColor: "#F9FBFD",
          border: "none",
          color: "black",
        }}
      >
        <small className="text-grey">
          <strong>TYPE OF CHARGES</strong>
        </small>
        <li className="text-grey text-sm">
          <small>
            <strong>
              One-Time:
            </strong> Initial pricing for services that occur at the start of our partnership.
          </small>
        </li>
        <li className="text-grey">
        <small>
            <strong>
              Recurring:
            </strong> Regular pricing for ongoing services as part of the Fondo subscription
            , billed annually, quarterly, or monthly.
          </small>
        </li>
      </Alert>
      <OrderProductsSection />
    </div>
  );
};

export default OrderCard;
