import {
  DOCUMENT_CENTER_FILES, DOCUMENT_CENTER_FOLDERS, GET_SIGNATURE_REQUESTS,
} from "../../constants/network-calls";
import { getFondoApi, putFondoApi } from "../../utils/util.api";
import { FolderType, FondoFileType } from "./documents.types";


type DropboxSignSignatureRequest = {
  id: number;
  signatureRequestId: string;
  testMode: boolean;
  requesterEmailAddress: string;
  requestCreatedAt: string;
  expiresAt?: string;
  isComplete: boolean;
  isDeclined: boolean;
  hasError: boolean;
  filesUrl: string;
  detailsUrl: string;
  signingRedirectUrl?: string;
  claimUrl?: string;
}

type SignatureRequestsResponse = {
  numberOfPendingSignatureRequests: number;
  signatureRequests: SignatureRequests[];
}

type SignatureRequests = {
  id: number;
  email?: string;
  status: string;
  fileAccountingType?: string;
  declinedReason?: string;
  fileName: string;
  uploader: string;
  dropboxSignSignatureRequest: DropboxSignSignatureRequest;
}

type StatusRequestParams = {
  status: SignatureRequestStatus;
};

export type SetStatusParams = {
  requestId: string;
  params: StatusRequestParams;
}

export enum SignatureRequestStatus {
  AI_PROCESSING = "AI_PROCESSING",
  REQUEST_SIGNATURE = "REQUEST_SIGNATURE",
  SEND_EMAIL = "SEND_EMAIL",
  WAITING_SIGNATURE = "WAITING_SIGNATURE",
  SIGNED = "SIGNED",
  DECLINED = "DECLINED",
}


export async function getFolders(): Promise<FolderType[]> {
  const result = await getFondoApi(DOCUMENT_CENTER_FOLDERS);
  return result as FolderType[];
}

export async function getFiles(): Promise<FondoFileType[]> {
  const result = await getFondoApi(DOCUMENT_CENTER_FILES);
  return result as FondoFileType[];
}

export const getSignatureRequests = async () => {
  const result = await getFondoApi(GET_SIGNATURE_REQUESTS);
  return result as SignatureRequestsResponse;
};

export const getDropboxSignerURL = async (requestId: string) => {
  const result = await getFondoApi(`${GET_SIGNATURE_REQUESTS}${requestId}/get_dropbox_sign/`);
  return result;
};

export const setSignatureRequestStatus = async (requestData: SetStatusParams) => {
  const { requestId, params } = requestData;
  const result = await putFondoApi(
    `${GET_SIGNATURE_REQUESTS}${requestId}/`,
    params,
  );
  return result as SignatureRequests[];
};
