import { useEffect, useState } from "react";
import {
  Formik, Field, Form, ErrorMessage,
} from "formik";
import * as Yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import posthog from "posthog-js";
import { getCompanySection1, saveCompany } from "./company.api";
import { CompanyApiType, CompanySection1 } from "./company.type";
import { getCountries, getCountrySubdivisions } from "../../../utils/util.api";
import { Country, Subdivision } from "../../../utils/util.type";
import { getUser } from "../../settings/settings.api";
import { FORM_ERROR_MSG, fondoEnv as FONDO_ENV } from "../../../constants/general";

export default function Company1() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<CompanySection1>({
    name: "",
    legalName: "",
    url: "",
    phone: "",
    streetAddress: "",
    city: "",
    country: "US",
    subdivision: "",
    postalCode: "",
  });
  const companyMutation = useMutation<any, AxiosError<any>, CompanyApiType>((data) => saveCompany(data));
  const [selectedCountry, setSelectedCountry] = useState<string>("US");
  const countryQuery = useQuery<Country[], Error>(["country"], getCountries, {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setFormValues({ ...formValues });
    },
  });
  const countrySubdivisionQuery = useQuery<Subdivision[], Error>([
    "countrySubdivision",
    selectedCountry,
  ], getCountrySubdivisions, {
    enabled: false,
  });

  useQuery<CompanySection1, Error>(["getCompanyQuery"], getCompanySection1, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (Object.values(data).length > 0) {
        setFormValues(data);
      }
    },
    onError: (e) => {
      console.error(e);
    },
  });

  // Identify user on PostHog
  useQuery(["userDetails"], () => getUser(), {
    onSuccess: (data) => {
      const userId = FONDO_ENV === "production" ? data.id : `${data.id}_${FONDO_ENV}`;
      posthog.identify(userId, {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      });
    },
  });

  useEffect(() => {
    countrySubdivisionQuery.refetch();
  }, [selectedCountry]);

  function renderCountryList() {
    return (
      countryQuery.data?.map((country) => (
        <option key={country.alpha2} value={country.alpha2}>
          {country.name}
        </option>
      ))
    );
  }

  // Regex are unalready unreadable as is, so changing the length by putting it into a regex isn't worth it
  // Not sure if removing the "useless escape character" will affect the code. Not willing to risk blowing it up
  // eslint-disable-next-line no-useless-escape, max-len
  const urlRegMatch = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

  function renderCountrySubdivisionList() {
    return (
      countrySubdivisionQuery.data?.map((subdivision) => (
        <option key={subdivision.id} value={subdivision.id}>
          {subdivision.name}
        </option>
      ))
    );
  }

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      validationSchema={Yup.object({
        name: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
        legalName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
        url: Yup.string().matches(urlRegMatch, "Website should be a valid URL").required(FORM_ERROR_MSG.REQUIRED_FIELD),
        phone: Yup.string().required(FORM_ERROR_MSG.REQUIRED_PHONE),
        streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
        city: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
        country: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
        subdivision: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
        postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD).max(10, FORM_ERROR_MSG.MAX_LENGTH(10)),
      })}
      onSubmit={(values, { setErrors }) => {
        const company: CompanyApiType = {
          section: 1,
          companySection1: values,
        };
        companyMutation.mutate(company, {
          onError: (err) => {
            const errors = err?.response?.data;
            if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
              setErrors(errors);
            }
          },
          onSuccess: () => {
            queryClient.refetchQueries(["companyLevelSettings"]);
            navigate("/onboarding/company2");
          },
        });
      }}>
      {({ setFieldValue }) => (
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8 card">
            <Form className="card-body my-5 mx-3">
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="firstName">Company Name</label>
                  <Field name="name" type="text" className={"form-control"} placeholder={"Fondo"} />
                  <ErrorMessage component="div" className="text-danger" name="name" />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="lastName">Legal Name</label>
                  <Field name="legalName" type="text" className={"form-control"} placeholder={"Bloomjoy, Inc."} />
                  <ErrorMessage component="div" className="text-danger mt-1" name="legalName" />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-6">
                  <label htmlFor="url">URL</label>
                  <Field name="url" type="text" className={"form-control"} placeholder={"https://tryfondo.com"} />
                  <ErrorMessage component="div" className="text-danger" name="url" />
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="phone">Phone Number</label>
                  <Field name="phone" type="text" className={"form-control"} />
                  <ErrorMessage component="div" className="text-danger" name="phone" />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="streetAddress">Street Address</label>
                  <Field name="streetAddress" type="text" className={"form-control"} />
                  <ErrorMessage component="div" className="text-danger" name="streetAddress" />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-6">
                  <label htmlFor="city">City</label>
                  <Field name="city" type="text" className={"form-control"} />
                  <ErrorMessage component="div" className="text-danger" name="city" />
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="country">Country</label>

                  <Field
                    name="country"
                    as="select"
                    className={"form-control form-select"}
                    onChange={(e: any) => {
                      setSelectedCountry(e.target.value);
                      setFieldValue("country", e.target.value);
                    }}>
                    <option value={""}>Select Country</option>
                    {renderCountryList()}
                  </Field>
                  <ErrorMessage component="div" className="text-danger" name="country" />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-6">
                  <label htmlFor="subdivision">State</label>

                  <Field
                    name="subdivision"
                    as="select"
                    className={"form-control form-select"}
                    onChange={(e: any) => {
                      setFieldValue("subdivision", e.target.value);
                    }}>
                    <option value={""}>Select State</option>
                    {renderCountrySubdivisionList()}
                  </Field>

                  <ErrorMessage component="div" className="text-danger" name="subdivision" />
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="postalCode">Postal Code</label>
                  <Field name="postalCode" type="text" className={"form-control"} />
                  <ErrorMessage component="div" className="text-danger" name="postalCode" />
                </div>
              </div>
              <hr className="row my-5" />
              <div className="row mb-3 mt-2">
                <div className="d-flex flex-row col-12 col-md-12 justify-content-end">
                  <button className="btn btn-lg btn-primary" type="submit">
                    {companyMutation.isLoading ? <Spinner /> : <div>Continue</div>}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
}
