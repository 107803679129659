import { Card, Col, Row } from "react-bootstrap"

type ReportingContentCardProps = {
  title: string;
  textBody: string|React.ReactNode;
  icon: string;
  roundedRight?: boolean;
  roundedLeft?: boolean;
}

const ReportingContentCard = ({ title, textBody, icon, roundedLeft, roundedRight }: ReportingContentCardProps) => {
  let cardClassName = "rounded";
  if (roundedRight) {
    cardClassName = "rounded-right";
  } else if (roundedLeft) {
    cardClassName = "rounded-left";
  }
  return (
    <Card className={cardClassName}>
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted mb-2">
              {title}
            </h6>
            <span className="h2 mb-0">
              {textBody}
            </span>
          </Col>
          <Col className="col-auto">
            <img src={icon} style={ { filter: "invert(1)" } } width="25px"/>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ReportingContentCard;
