import React, { useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Button, Modal } from "react-bootstrap";
import { ActionItem } from "./actionItems.type";
import { getPresignedURL, getDownloadURL } from "../documents/utils";
import { PresignedURLResponse } from "../../types/PreSignedUrl";
import { DOCUMENT_CENTER_FILE_REQUEST, DOCUMENT_CENTER_FILES } from "../../constants/network-calls";
import { FondoFileType, FileRequestType } from "../documents/documents.types";
import { deleteFondoApi, postFondoApi } from "../../utils/util.api";

type FileActionItemSelectorProps = {
    actionItem: ActionItem;
};

const FileActionItemSelector: React.FC<FileActionItemSelectorProps> = ({ actionItem }) => {
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState(0);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const queryClient = useQueryClient();
  const [downloadDialogVisible, setDownloadDialogVisible] = useState(false);
  const [downloadFileId, setDownloadFileId] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const invalidateActionItemQueries = () => {
    queryClient.invalidateQueries({
      queryKey: ["actionItemServiceData"],
    });
    queryClient.invalidateQueries({
      queryKey: ["actionItemsData"],
    });
    queryClient.invalidateQueries({
      queryKey: ["actionItems"],
    });
    queryClient.invalidateQueries({
      queryKey: ["actionItemsUserServices"],
    });
  };

  const showAttachFilesButton = () => actionItem.missingInfo?.fileRequests?.length > 0;

  const removeFileMutation = useMutation<string, AxiosError<any>, string>(
    async () => {
      await deleteFondoApi(`${DOCUMENT_CENTER_FILES}${deleteFileId}/`);
      return "";
    },
    {
      onSuccess: () => invalidateActionItemQueries(),
    },
  );

  const closeDownloadDialog = () => {
    setDownloadFileId(0);
    setDownloadDialogVisible(false);
  };

  const handleFileDownload = (fileId: number) => {
    setDownloadFileId(fileId);
    setDownloadDialogVisible(true);
  };

  const uploadFileToPresignedURL = async (presignedUrlResponse: PresignedURLResponse, file: File) => {
    const maxSize = 500 * 1024 * 1024; // 500 MB in bytes

    if (file.size > maxSize) {
      throw new Error("File size exceeds 500 MB");
    }
    const form = new FormData();
    Object.keys(presignedUrlResponse.fields).forEach((key) => {
      form.append(key, (presignedUrlResponse.fields as any)[key]);
    });
    form.append("file", file);

    const xhr = new XMLHttpRequest();
    const parts = presignedUrlResponse.url.split("/");

    // Take the first part and join it back together
    const baseUrl = parts.slice(0, 3).join("/");
    xhr.open("POST", baseUrl);

    // Add an event listener to track upload progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        setUploadProgress(progress);
      }
    };

    xhr.send(form);

    // Return the XHR request so you can handle the response if needed
    return new Promise((resolve, reject) => {
      xhr.onload = () => {
        if (xhr.status === 204) {
          resolve(xhr.response);
        } else {
          reject(xhr.statusText);
        }
      };
    });
  };

  const downloadFile = async () => {
    const url = await getDownloadURL(downloadFileId);
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.style.display = "none";

      // Trigger a click event on the link to initiate the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      closeDownloadDialog();
    }
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationVisible(false);
  };

  const handleFileDelete = (fileId: number) => {
    setDeleteFileId(fileId);
    setDeleteConfirmationVisible(true);
  };

  const confirmFileDelete = async () => {
    removeFileMutation.mutate(deleteFileId.toString());
    closeDeleteConfirmation();
  };

  const openFileSelection = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowProgressBar(true);
    const { files } = event.target;
    if (files) {
      for (let i = 0; i < files.length; i += 1) {
        UploadFileMutation.mutate([files[i]]);
      }
    }
    clearFileInput();
  };

  const UploadFileMutation = useMutation<void, unknown, [File]>(
    async ([file]) => {
      try {
        const presignedUrlResponse = await getPresignedURL(file);
        await uploadFileToPresignedURL(presignedUrlResponse, file);
        await postFondoApi(
          DOCUMENT_CENTER_FILE_REQUEST,
          {
            id: actionItem.missingInfo.fileRequests[0].id,
            fileName: file.name,
            originalFileName: file.name,
            url: presignedUrlResponse.url,
          },
        );
        setShowProgressBar(false);
      } catch (error) {
        setShowProgressBar(false);
        throw new Error(`Failed to upload document: ${error}`);
      }
      setShowProgressBar(false);
      setUploadProgress(0);
    },
    {
      onSuccess: () => invalidateActionItemQueries(),
    },
  );

  return (
        <>
            {actionItem.missingInfo.fileRequests?.length > 0 && (
                <>
                    <div className="mb-4">
                        {actionItem.missingInfo.fileRequests.map((fileRequest: FileRequestType) => (
                            <div key={fileRequest.fileName}>
                                <p className="m-0">{fileRequest.fileName}</p>
                                {fileRequest?.files?.length > 0 && (
                                    <div>
                                        {fileRequest.files.map((file: FondoFileType) => (
                                            <div key={file.id}
                                            className="bg-white border border-gray p-2
                                            ps-2 pe-3 d-flex justify-content-between">
                                                <div>
                                                    <a
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          handleFileDownload(file.id);
                                                        }}
                                                        href="#"
                                                        rel="noopener noreferrer"
                                                        download={file.originalName}>
                                                        {file.originalName || "Unknown File Name"}
                                                    </a>
                                                </div>
                                                <div>
                                                    <FeatherIcon
                                                        onClick={() => handleFileDelete(file.id)}
                                                        role="button"
                                                        icon="x"
                                                        size="1em"
                                                        className="ms-1 mt-n1 text-muted"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}
            {showAttachFilesButton() && (
                <div className="mt-3 d-flex justify-content-start">
                    <p className="text-primary" onClick={openFileSelection} style={{ cursor: "pointer" }}>
                        <FeatherIcon icon="plus" size="1em" className="mt-n1 me-1" />
                        Attach Files
                    </p>
                    <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange} multiple />
                </div>
            )}

            <Modal show={downloadDialogVisible} onHide={closeDownloadDialog} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Download File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to download the file?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDownloadDialog}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={downloadFile}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={deleteConfirmationVisible} onHide={closeDeleteConfirmation} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the file?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmFileDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {showProgressBar && (
                <div>
                    <progress value={uploadProgress} max="100"></progress>
                    <p>{Math.round(uploadProgress)}%</p>
                </div>
            )}

            {/* Download confirmation modal */}
            <Modal show={downloadDialogVisible} onHide={closeDownloadDialog} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Download File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to download the file?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDownloadDialog}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={downloadFile}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
  );
};

export default FileActionItemSelector;
