import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { Badge, Card, Nav } from "react-bootstrap";
import { CompanyLevelSettingsContext } from "../../../context/FeatureFlagsContext";
import { getTransactionsStats } from "../FinanceDashboard.api";
import { mapTransactionCategoryToDropdownLabel, TransactionsStats } from "../financeDashboard.type";
import AITransactionCategorizationButton from "./AITransactionCategorizationButton";
import TransactionsCard from "./TransactionsCard";

const Transactions = () => {
  const [currentTab, setCurrentTab] = useState<string | null>(null);
  const [transactionsStats, setTransactionsStats] = useState<{ [key: string]: number }>({});
  const [minAmount, setMinAmount] = useState<number | undefined>();
  const [maxAmount, setMaxAmount] = useState<number | undefined>();
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();
  const [last30Days, setLast30Days] = useState<boolean | undefined>();
  const [last6Months, setLast6Months] = useState<boolean | undefined>();
  const [lastYear, setLastYear] = useState<boolean | undefined>();
  const companyLevelSettings = useContext(CompanyLevelSettingsContext);

  useQuery<any, Error>(
    ["transactionsStats", minAmount, maxAmount, startDate, endDate, last30Days, last6Months, lastYear],
    () =>
      getTransactionsStats({
        minAmount,
        maxAmount,
        startDate,
        endDate,
        last30Days,
        last6Months,
        lastYear,
      }),
    {
      onSuccess: (data: TransactionsStats) => {
        setTransactionsStats({
          ALL: data.allCount,
          REVENUE: data.revenueCount,
          INVESTMENT: data.investmentCount,
          TRANSFER: data.transferCount,
          OTHER: data.otherCount,
          VERIFY: data.verifyCount,
        });
      },
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Card.Body>
      <Nav variant="tabs" className="mb-5 nav-tabs-sm justify-content-start">
        <div className="d-flex">
          <Nav.Item key="all-transactions" onClick={() => setCurrentTab(null)} role="button">
            <Nav.Link active={!currentTab} className={`${!currentTab} ? "text-dark" : "text-muted"}`}>
              <span>All</span>
              {(transactionsStats.ALL || transactionsStats.ALL === 0) && (
                <Badge bg="secondary-soft" className="ms-2">
                  {transactionsStats.ALL}
                </Badge>
              )}
            </Nav.Link>
          </Nav.Item>
          {Object.entries(mapTransactionCategoryToDropdownLabel).map(([key, value], idx: number) => (
            <Nav.Item key={`${value}-${idx}`} onClick={() => setCurrentTab(key)} role="button">
              <Nav.Link active={key === currentTab} className={`${key === currentTab} ? "text-dark" : "text-muted"}`}>
                <span>{value}</span>
                {(transactionsStats[key] || transactionsStats[key] === 0) && (
                  <Badge bg="secondary-soft" className="ms-2">
                    {transactionsStats[key]}
                  </Badge>
                )}
              </Nav.Link>
            </Nav.Item>
          ))}
        </div>
      </Nav>
      {companyLevelSettings.useAiToCategorizeTransactions && (
        <div className="text-end mb-2">
          <AITransactionCategorizationButton />
        </div>
      )}
      <TransactionsCard
        category={currentTab}
        minAmount={minAmount}
        setMinAmount={setMinAmount}
        maxAmount={maxAmount}
        setMaxAmount={setMaxAmount}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        last30Days={last30Days}
        setLast30Days={setLast30Days}
        last6Months={last6Months}
        setLast6Months={setLast6Months}
        lastYear={lastYear}
        setLastYear={setLastYear}
        allowUseOfAIToCategorizeTransactions={companyLevelSettings.useAiToCategorizeTransactions}
      />
    </Card.Body>
  );
};

export default Transactions;
