import { Button, Navbar, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import sparks from "../../../assets/img/icons/sparks.png";
import FondoLayout from "../../fusion-kit/FondoLayout";
import ReportingExecutiveSummary from "./ReportingExecutiveSummary";
import ReportingRunway from "./ReportingRunway";
import ReportingProfitAndLoss from "./ReportingProfitAndLoss";
import ReportingBalanceSheet from "./ReportingBalanceSheet";
import { getReportQuery, refreshReportingData } from "../Reporting.api";
import { ReportingType } from "../Reporting.type";
import RecipientGroupModal from "../RecipientGroupModal/RecipientGroupModal";
import UpdateReportModal from "../CreateNewReportModal/UpdateReportModal";
import { downloadReport , missingBankSections, missingQBOSections } from "../utils";
import CenterSpinner from "../../spinner/Spinner";
import { amountOfConnectedAccounts, getMergeDevLinkedAccounts } from "../../integrations/integrations.api";
import ReportingAIChatBot from "./ReportingAIChatBot/ReportingAIChatBot";
import { getCompanyLevelSettings } from "../../account/account.api";


const ReportingDetailAndEdit = () => {
  const { companyUuid, uuid } = useParams<{ companyUuid?: string, uuid: string }>();
  const [showRecipientGroupModal, setShowRecipientGroupModal] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [showReportEditModal, setShowReportEditModal] = useState(false);
  const [showAIChatBot, setShowAIChatBot] = useState(false);

  const navigate = useNavigate();

  const { data: report, refetch: reportRefetch, isLoading, isError } = useQuery<ReportingType, Error>(
    ["reportings", uuid],
    () => getReportQuery(uuid || ""),
    {
      refetchOnWindowFocus: false,
      enabled: !!uuid,
    },
  );

  const { data: companyLevelSettings } = useQuery(["companyLevelSettings"], getCompanyLevelSettings);
  
  const { data: numBankAccounts } = useQuery<any, Error>(["amountOfConnectedAccounts"], amountOfConnectedAccounts, {
    refetchOnWindowFocus: false
  });

  const missingBankConnection = numBankAccounts === 0 && (report?.executiveSummary || report?.runway);
  
  const category = "accounting";

  const { data: linkedAccountsQueryData } = useQuery<any, Error>(
    ["linkedAccountData", category],
    () => getMergeDevLinkedAccounts(category),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const missingQBOConnection = () => {
    if (report?.profitAndLoss || report?.balanceSheet) {
        const linkedAccounts = linkedAccountsQueryData || [];
        const hasQBOIntegration = linkedAccounts.some((entry: any) => entry.integration === "QuickBooks Online");
        return !hasQBOIntegration;
    } 
    return false; 
  };

  const updateReportMutation = useMutation(
    refreshReportingData,
    {
      onSuccess: () => reportRefetch(),
    },
  );

  const handleToggleModal = () => {
    setShowReportEditModal(!showReportEditModal);
  };

  const refreshReport = () => updateReportMutation.mutate(report?.uuid || "");

  const downloadFile = async () => {
    setDownloadLoading(true)
    await downloadReport(report as ReportingType)
    setDownloadLoading(false)
  };

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    )
  }

  if (isError) {
    return <div>Error loading report data</div>;
  }

  const buttonRedirectUrl = "/dashboard/integrations";
  return (
    <>
      {
        <Navbar className="d-flex justify-content-between position-sticky" variant="dark" sticky="top">
          <Navbar.Brand>
            <Link to={"/dashboard/reporting/"} style={{ textDecoration: "none", color: "inherit" }}>
              <FeatherIcon icon="arrow-left" size="35" className="p-2" />
              <span>Back to Reporting</span>
            </Link>
          </Navbar.Brand>
          <Navbar.Brand className="text-secondary">
            <div className="position-absolute top-50 start-50 translate-middle">
              EDIT AND PREVIEW
            </div>
          </Navbar.Brand>
          <Navbar.Brand>
            {
              <>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Refresh Balances and QBO</Tooltip>}
              >
                  <Button size="sm" variant="outline-white" className="me-2 px-2 py-1" onClick={refreshReport}>
                    {updateReportMutation.isLoading ?
                      <Spinner as="span" animation="border" size="sm" /> :
                      <FeatherIcon icon="refresh-cw" size="16" className="mb-1" />
                    }
                  </Button>
                </OverlayTrigger>
                <Button size="sm" variant="outline-white" className="px-2 py-1" onClick={handleToggleModal}>
                  <FeatherIcon icon="sliders" size="16" className="mb-1" />
                </Button>
                <span className="mx-3 border-secondary border" />
                <Button
                  disabled={downloadLoading}
                  size="sm"
                  variant="outline-white"
                  className="me-2 px-2 py-1"
                  onClick={downloadFile}
                >
                  {downloadLoading ?
                    <Spinner as="span" animation="border" size="sm" /> :
                    <FeatherIcon icon="download" size="16" className="mb-1" />
                  }
                </Button>
                <Button
                  size="sm"
                  variant="outline-white"
                  className="px-2 py-1"
                  onClick={() => setShowRecipientGroupModal(true)}
                >
                  <FeatherIcon icon="send" size="16" className="mb-1" />
                </Button>
                {companyLevelSettings?.showReportingAiAssistant && (
                  <>
                    <span className="mx-3 border-secondary border" />
                    <Button
                      onClick={() => setShowAIChatBot(true)}
                      style={{background: "#7431FA", borderColor: "#7431FA"}}
                      className="bg-gradient py-2"
                    >
                      <div className="d-flex align-items-center">
                        <img src={sparks} width={17} />
                        <span className="ms-2">Ask AI</span>
                      </div>
                    </Button>
                  </>
                )}

              </>
            }
          </Navbar.Brand>
        </Navbar>
      }
      <FondoLayout
        centerContent={!showAIChatBot}
        maxWidth="1000px"
        pretitle={"Report"}
        title={report.name}
      >
        {missingBankConnection && (
        <Button
          className="btn btn-block btn-primary full-width py-3 mb-5"
          onClick={() => navigate(buttonRedirectUrl)}
        >
          Connect a bank in order to view {missingBankSections(report)}
        </Button>
        )}
        {missingQBOConnection() && (
        <Button
          className="btn btn-block btn-primary full-width py-3 mb-5"
          onClick={() => navigate(buttonRedirectUrl)}
        >
          Connect QBO in order to view {missingQBOSections(report)}
        </Button>
        )}
        {report?.executiveSummary && (
          <ReportingExecutiveSummary
            report={report}
          />
        )}
        {report?.runway && (
          <ReportingRunway
            report={report}
          />
        )}
        {report?.profitAndLoss && (
          <ReportingProfitAndLoss
            report={report}
          />
        )}
        {report?.balanceSheet && (
          <ReportingBalanceSheet
            report={report}
          />
        )}
      </FondoLayout>
      <RecipientGroupModal
        show={showRecipientGroupModal}
        selectedReport={{ uuid: report.uuid, name: report.name }}
        handleClose={() => setShowRecipientGroupModal(false)} />
      <UpdateReportModal
        show={showReportEditModal}
        handleClose={handleToggleModal}
        report={report} />
      <style>
        {`
        @media print {
          .ReactQueryDevtools {
            display: none !important;
          }
          [href="mailto:team@tryfondo.com"] {
              display: none !important;
          }
          .spinner-border{
            display: none !important;
          }
          .navbar-vertical{
            display: none !important;
          }
          * {
            box-shadow: none !important;
           }
          .navbar {
            display: none !important;
          }
          .btn {
            display: none !important;
          }
        }
      `}
      </style>
      {uuid && companyLevelSettings?.showReportingAiAssistant && (
        <ReportingAIChatBot
        show={showAIChatBot}
        handleClose={() => setShowAIChatBot(false)}
        reportingUuid={uuid}
        />
      )}
    </>
  );
}

export default ReportingDetailAndEdit;
