import FeatherIcon from "feather-icons-react";
import {
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { LineItemAPIData } from "../product/product.type";
import { OrderLineItem } from "./products.type";
import { getProductFinalPrice } from "./utils";

type OrderLineItemRowProps = {
  lineItem: OrderLineItem;
};

const OrderLineItemRow = ({ lineItem }: OrderLineItemRowProps) => (
  <>
    <Row className="justify-content-between mt-3">
      <Col className="text-start col-6">
        <small>{lineItem.name}</small>
        {lineItem.helperText && (
          <span className="tooltip-container ms-2">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="right"
              overlay={
              <Tooltip>
                  {lineItem.helperText}
              </Tooltip>
              }
              >
              <span>
                  <FeatherIcon className="cursor-pointer" icon="info" size="1.1em" />
              </span>
            </OverlayTrigger>
          </span>
        )}
      </Col>
      <Col className="text-end">
      {lineItem.originalPrice !== lineItem.finalPrice && (<small className="text-muted strikethrough">
          {lineItem.originalPrice}
        </small>)}
      </Col>
      <Col className="text-end">
        <small>
          {lineItem.finalPrice}
        </small>
      </Col>
    </Row>
    {!!lineItem.addons?.length && lineItem.addons.map((addon: LineItemAPIData) => (
      <Row className="py-1">
        <Col className="text-start ps-5">
          <small className="text-grey">{addon.product.displayName}</small>
        </Col>
        <Col className="text-end">
          <small className="text-grey">
            {addon.units}
          </small>
        </Col>
        <Col className="text-end">
          <small className="text-muted">
            {addon.displayedUnitPrice} (per)
          </small>
        </Col>
        <Col className="text-end">
          <small className="text-grey">
            {getProductFinalPrice(addon)}
          </small>
        </Col>
      </Row>
    ))}
  </>
);

export default OrderLineItemRow;
