import { useState } from "react";
import { Button, CardGroup } from "react-bootstrap";
import ProfitAndLossSparkChartsCard from "../../finance-dashboard/profit-and-loss/ProfitAndLossSparkChartsCards";
import { ReportingType, ExecutiveSummaryOptions, defaultExecutiveSummaryOptions } from "../Reporting.type";
import ReportingOptionsModal from "./ReportingOptionsModal";

type ReportingExecutiveSummaryProps = {
  report: ReportingType;
};

const ReportingExecutiveSummary = ({ report }: ReportingExecutiveSummaryProps) => {
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const initialOptionsState = { ...defaultExecutiveSummaryOptions, ...report.executiveSummaryOptions };
  const [options, setOptions] = useState<ExecutiveSummaryOptions>(initialOptionsState);
  const { cashFlowStats, burnRateList, monthlyBalances } = report.reportingData;

  const getTotalBalancesArray = (): number[] => {
    const accountIds = Object.keys(monthlyBalances).filter((key) => key.startsWith("account"));
    const totalBalances: { [key: string]: number } = {};
    accountIds.forEach((accountId) => {
      monthlyBalances[accountId].forEach((balanceAccount) => {
        if (!totalBalances[balanceAccount.date]) {
          totalBalances[balanceAccount.date] = 0;
        }
        totalBalances[balanceAccount.date] += Number(balanceAccount.balance);
      });
    });
    const datesArr = Object.keys(totalBalances).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    return datesArr.map((date) => totalBalances[date]);
  }


  let descriptionContent = null;
  if (options?.description?.enabled && options.description.text?.trim()) {
    descriptionContent = options.description.text;
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>Executive Summary</h2>
        <div>
          {<Button size="sm" className="px-4" onClick={() => setShowOptionsModal(true)}>Edit</Button>}
        </div>
      </div>
      <p className="text-secondary">{descriptionContent}</p>
      <CardGroup style={{ gap: "1rem" }} className="mb-4">
        {options.totalCash && (
          <ProfitAndLossSparkChartsCard data={getTotalBalancesArray()} title="Total Cash" />
        )}
        {options.netBurn && (
          <ProfitAndLossSparkChartsCard data={burnRateList.map((rate) => rate.burnRate)} title="Net Burn" />
        )}
        {options.runway && (
          <ProfitAndLossSparkChartsCard data={[]} textOverride={cashFlowStats.runway} title="Runway" />
        )}
      </CardGroup>
      <hr className="my-5" />
      <ReportingOptionsModal
        sectionType = "executiveSummary"
        show={showOptionsModal}
        onHide={() => setShowOptionsModal(false)}
        initialOptions={options}
        reportUuid={report.uuid}
        onUpdate={(newOptions: ExecutiveSummaryOptions) => {
          setOptions(newOptions);
        }}
      />
      <div id="exec-summary-data-loaded" />
    </>
  );
};

export default ReportingExecutiveSummary;
