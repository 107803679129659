import { Button } from "react-bootstrap";
import { convertNumberToCurrency } from "../../utils/utils";

type RunwayAuditToolTableTotalsRowProps = {
  handleAddBankAccount: () => any;
  totalBalances: number;
  handleSaveAndUpdate: () => void;
  disableSubmitButton: boolean;
}

const RunwayAuditToolTableTotalsRow = ({
  handleAddBankAccount,
  totalBalances,
  handleSaveAndUpdate,
  disableSubmitButton,
}: RunwayAuditToolTableTotalsRowProps) => (
  <>
    <tr>
      <td colSpan={4}>
        <h5 className="text-uppercase text-secondary">
          Total
        </h5>
      </td>
      <td className="text-end">${convertNumberToCurrency(totalBalances)}</td>
    </tr>
    <tr>
      <td></td>
      <td colSpan={4} className="text-end">
        <Button
            onClick={handleAddBankAccount}
            size="sm"
            variant="outline-primary"
            className="me-3"
        >
            Add Bank Account Manually
        </Button>
        <Button
            onClick={handleSaveAndUpdate}
            size="sm"
            variant="primary"
            disabled={disableSubmitButton}
        >
            Save and Update
        </Button>
      </td>
    </tr>
  </>
);

export default RunwayAuditToolTableTotalsRow;
