import { Field, FormikContextType } from "formik";
import { useQuery } from "@tanstack/react-query";
import { getCountrySubdivisions } from "../../../utils/util.api";
import { Subdivision } from "../../../utils/util.type";

type StateFieldProps = {
  selectedCountry?: string;
  formikContext: FormikContextType<any>;
  fieldName: string;
  value: string;
  invalid: boolean;
  useNameAsValue?: boolean;
}

export default function Address({
  formikContext, fieldName, selectedCountry, value, invalid, useNameAsValue,
}: StateFieldProps) {
  const country = selectedCountry
    || (useNameAsValue === true ? "US" : value?.split("-")?.[0]);

  const countrySubdivisionQuery = useQuery<Subdivision[], Error>(
    ["countrySubdivision", country],
    getCountrySubdivisions,
    {
      enabled: !!country,
      refetchOnMount: false,
    },
  );

  function renderCountrySubdivisionList() {
    return (
      countrySubdivisionQuery.data?.map((subdivision) => (
        <option key={subdivision.abbr} value={useNameAsValue === true ? subdivision.name : subdivision.abbr}>
          {subdivision.name}
        </option>
      ))
    );
  }

  const handleChange = (subdivisionValue: string) => {
    formikContext.setFieldValue(
      fieldName,
      useNameAsValue === true ? subdivisionValue : `${country}-${subdivisionValue}`
    );
  };

  const getFieldValue = () => {
    if (useNameAsValue === true) {
      return value;
    }
    return value?.includes("-") ? value.split("-")[1] : value;
  };

  return (
    <Field
      name={fieldName}
      as="select"
      className={`form-control form-select ${invalid ? "is-invalid" : ""}`}
      onChange={(e: any) => handleChange(e.target.value)}
      value={getFieldValue()}
      isInvalid={invalid}
    >
      <option key="emptyValue" value={""}>{country ? "Select State" : "Please select Country"}</option>
      {renderCountrySubdivisionList()}
    </Field>
  );
}
