import { Toast } from "react-bootstrap";
import ToastContainer from "react-bootstrap/ToastContainer";
import FeatherIcon from "feather-icons-react";

export type ToastProps = {
  show: boolean;
  handleClose: () => void;
  title?: string;
  message?: string;
  error?: boolean;
};

function FondoToast({
  show,
  handleClose,
  error = false,
  title = error ? "An error has occurred" : "Success",
  message = error ? "Our dev team has been notified and is working hard to fix it!" : "",
}: ToastProps) {
  return (
    <ToastContainer style={{ position: "fixed", top: "1.5em", right: "2.3em" }}>
      <Toast
        onClose={() => handleClose()}
        show={show}
        delay={5000}
        autohide
        className={error ? "border-danger" : "border-success"}
      >
        <Toast.Header>
          <FeatherIcon
            icon={error ? "x" : "check-circle"}
            size='15'
            className={error ? "me-2 text-danger" : "me-2 text-success"}
          />
          <strong className='me-auto'>{title}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default FondoToast;
