import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { AxiosError } from "axios";
import {
  Alert,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import { formatDateToWords, removeCommas } from "../../utils/common.util";
import Flatpickr from "../flatpickr/Flatpickr";
import FondoToast from "../fusion-kit/FondoToast";
import FondoCurrencyField from "../forms/formik-components/FondoCurrencyField";
import { EndingBalancePayload } from "../finance-dashboard/financeDashboard.type";
import { generateEndingBalances } from "../finance-dashboard/FinanceDashboard.api";

type EndOfTheMonthBalanceModalFormProps = {
  accountUuid: string;
  show: boolean;
  handleClose: () => void;
}

const EndOfTheMonthBalanceModalForm = (
  {
    accountUuid,
    show,
    handleClose,
  }: EndOfTheMonthBalanceModalFormProps,
) => {
  const queryClient = useQueryClient();
  const [accountOpenedOn, setAccountOpenedOn] = useState<string>("");
  const [endingBalances, setEndingBalances] = useState<{ [key: string]: string }>({});
  const [showSubmitResultToast, setShowSubmitResultToast] = useState(false);
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);

  const currentDate = new Date();
  const handleDateChange = (dates: Date[]) => {
    if (dates.length > 0) {
      const selectedDate = dates.map((date) => date.toISOString())[0];
      const [newDate] = selectedDate.split("T");
      setAccountOpenedOn(newDate);
      // Generate ending balances from the date the account was opened,
      // excluding the current month.
      const startDate = new Date(selectedDate);
      const monthDiff = (
        currentDate.getFullYear() - startDate.getFullYear()
      ) * 12 + currentDate.getMonth() - startDate.getMonth();
      const newEndingBalances: {[key: string]: string} = {};
      for (let i = monthDiff - 1; i >= 0; i -= 1) {
        const date = new Date(startDate.getFullYear(), startDate.getMonth() + i, 1);
        newEndingBalances[date.toISOString().slice(0, 7)] = "0";
      }
      setEndingBalances(newEndingBalances);
    } else {
      setAccountOpenedOn("");
      setEndingBalances({});
    }
  };

  const handleBalanceChange = (month: string, value: string) => {
    if (value === undefined || value === "") value = "0";
    setEndingBalances({ ...endingBalances, [month]: value });
  };

  const saveEndingBalancesMutation = useMutation<any, AxiosError<any>, any>(
    (data: EndingBalancePayload) => generateEndingBalances(data),
  );

  const handleSubmitResult = (error: boolean) => {
    handleClose();
    if (!error) {
      queryClient.invalidateQueries({
        queryKey: ["currentBalance"],
      });
    }
    setErrorOnSubmit(error);
    setShowSubmitResultToast(true);
    setEndingBalances({});
    setAccountOpenedOn("");
  };

  const saveEndingBalances = () => {
    saveEndingBalancesMutation.mutate(
      {
        accountUuid,
        accountOpenedOn,
        endingBalances,
      },
      {
        onSuccess: () => {
          handleSubmitResult(false);
        },
        onError: () => {
          handleSubmitResult(true);
        },
      },
    );
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Action Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="light">
            Currently, there are no historical ending balances for this account.
            Please manually enter balances for previous months to accurately track
            your financial history.
          </Alert>
          <Form>
            <Form.Group className="mb-3" controlId="openedAt" >
              <Form.Label>Account opened on:</Form.Label>
              <Flatpickr
                value={accountOpenedOn}
                onChange={(dates: any) => handleDateChange(dates)}
                placeholder="MM/DD/YYYY"
                options={{
                  mode: "single",
                  altInput: true,
                  static: true,
                  allowInput: true,
                  dateFormat: "Y-m-d",
                  altFormat: "m/d/Y",
                  maxDate: currentDate,
                }}
              />
            </Form.Group>
            {Object.entries(endingBalances).map(([month, balance]) => (
              <Form.Group className="mb-3" key={month}>
                <Form.Label>{formatDateToWords(month, true)}</Form.Label>
                <FondoCurrencyField
                  name={month}
                  value={balance}
                  onChange={(e) => handleBalanceChange(month, removeCommas(e.target.value))}
                  allowNegative={true}
                  isInvalid={!endingBalances[month]}
                />
            </Form.Group>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={saveEndingBalances}
            disabled={!accountOpenedOn}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <FondoToast
        show={showSubmitResultToast}
        handleClose={() => setShowSubmitResultToast(false)}
        message={errorOnSubmit ? "" : "Successfully generated ending balances!"}
        error={errorOnSubmit}
      />
    </>
  );
};

export default EndOfTheMonthBalanceModalForm;
