import { Card } from "react-bootstrap";
import { ProfitAndLossPeriod } from "../financeDashboard.type";
import CircleReferenceColor from "../../fondo-components/CircleReferenceColor";
import FondoBarChart from "../../fondo-components/Charts/FondoBarChart";

type ProfitAndLossChartCardProps = {
  historicalProfitAndLoss: ProfitAndLossPeriod[];
}

const incomeReferenceColor = "#2C7AE5";
const expensesReferenceColor = "#D5E5FA";

const ProfitAndLossChartCard = ({ historicalProfitAndLoss }: ProfitAndLossChartCardProps) => {
  const periods = historicalProfitAndLoss.map((item) => item.period);
  const incomeAmounts = historicalProfitAndLoss.map((item) => item.revenueAmount);
  const expensesAmounts = historicalProfitAndLoss.map((item) => item.expensesAmount);

  const getProfitAndLossChartData = () => {
    const chartData = [];
    chartData.push({ data: incomeAmounts, color: incomeReferenceColor });
    chartData.push({ data: expensesAmounts, color: expensesReferenceColor });
    return chartData;
  };

  return (
    <Card>
      <Card.Body className="p-0">
        <div className="border-bottom p-4 pb-3 d-flex justify-content-between">
          <Card.Title className="text-uppercase text-muted">
            <span className="h5 text-secondary">Profit & Loss</span>
          </Card.Title>
          <div className="m-0 d-flex align-items-start">
            <CircleReferenceColor color={incomeReferenceColor} text="Income" />
            <CircleReferenceColor color={expensesReferenceColor} text="Expenses" />
          </div>
        </div>
        <div className="p-4">
          <FondoBarChart
            chartData={getProfitAndLossChartData()}
            labels={periods}
            minYAxisValue={0}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProfitAndLossChartCard;
