import { Modal } from "react-bootstrap";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import RecipientGroupShare from "./RecipientGroupShare";
import { RecipientGroupType, RecipientGroupRouter, OpenedReport } from "../Reporting.type";
import RecipientGroupSelectedGroup from "./RecipientGroupSelectedGroups";
import { getRecipientGroups } from "../Reporting.api";
import RecipientGroupUpsert from "./RecipientGroupUpsert";
import RecipientGroupSent from "./RecipientGroupSent";
import FondoToast from "../../fusion-kit/FondoToast";

type RecipientGroupModalType = {
  show: boolean;
  selectedReport: OpenedReport;
  handleClose: () => void;
};

const RecipientGroupModal = ({ show, handleClose, selectedReport }: RecipientGroupModalType) => {
  const [selectedGroups, setSelectedGroups] = useState<{ [key: number]: string }>({});
  const [emailSubject, setEmailSubject] = useState("");
  const [optionalMessage, setOptionalMessage] = useState("");
  const [currentRoute, setCurrentRoute] = useState(RecipientGroupRouter.SHARE);
  const [recipientGroupEditId, setRecipientGroupEditId] = useState(0);
  const [showFondoToast, setShowFondoToast] = useState(false);
  
  const groupsToSendName = () => {
    let groupName = "";
    if (selectedGroups && Object.values(selectedGroups).length > 0) {
      groupName = Object.values(selectedGroups).reduce((name, currentName) => `${name}, ${currentName}`);
    }
    return groupName;
  };

  const { data: recipientGroupListData } = useQuery<RecipientGroupType[], Error>(
    ["recipientGroupListData"],
    getRecipientGroups,
    {
      refetchOnWindowFocus: false,
    }
  );

  const resetData = () => {
    setSelectedGroups({});
    setEmailSubject("");
    setOptionalMessage("");
  }

  const returnToShareRoute = () => {
    resetData();
    setCurrentRoute(RecipientGroupRouter.SHARE);
  }

  const closeModal = () => {
    handleClose();
    returnToShareRoute();
  }

  return (
    <Modal size="lg" show={show} onHide={closeModal} backdrop="static">
      {currentRoute === RecipientGroupRouter.SHARE &&
        <RecipientGroupShare
          report={selectedReport}
          groupsToSendName={groupsToSendName()}
          optionalMessage={optionalMessage}
          selectedGroups={selectedGroups}
          emailSubject={emailSubject}
          setEmailSubject={setEmailSubject}
          setOptionalMessage={setOptionalMessage}
          setShowFondoToast={setShowFondoToast}
          setCurrentRoute={setCurrentRoute}
          handleClose={closeModal}
        />
      }
      {currentRoute === RecipientGroupRouter.SELECT_GROUPS &&
        <RecipientGroupSelectedGroup
          recipientGroupData={recipientGroupListData || []}
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          setCurrentRoute={setCurrentRoute}
          setRecipientGroupEditId={setRecipientGroupEditId}
        />
      }
      {currentRoute === RecipientGroupRouter.CREATE_GROUP &&
        <RecipientGroupUpsert
          setCurrentRoute={setCurrentRoute}
          editing={false}
        />
      }
      {currentRoute === RecipientGroupRouter.EDIT_GROUP &&
        <RecipientGroupUpsert
          setCurrentRoute={setCurrentRoute}
          recipientGroupItemId={recipientGroupEditId}
          editing={true}
        />
      }
      {currentRoute === RecipientGroupRouter.SENT_GROUP &&
        <RecipientGroupSent
          reportName={selectedReport.name}
          groupsToSendName={groupsToSendName()}
          returnToShareRoute={returnToShareRoute}
          handleClose={closeModal}
        />
      }
      <FondoToast
        show={showFondoToast}
        handleClose={() => setShowFondoToast(false)}
        title="Report not sent"
        message="There was an issue with sending the report. Please try again."
        error={true}
      />
    </Modal>
  )

}

export default RecipientGroupModal;
