import { ONBOARDING_COMPANY } from "../../../constants/network-calls";
import { CompanyApiType, CompanySection1, CompanySection2 } from "./company.type";
import { getFondoApi, postFondoApi } from "../../../utils/util.api";

export async function saveCompany(data: CompanyApiType): Promise<string> {
  const result: any = await postFondoApi(ONBOARDING_COMPANY, data);
  return result;
}

export async function getCompanySection1(): Promise<CompanySection1> {
  const result = await getFondoApi(ONBOARDING_COMPANY, { section: 1 });
  return result as CompanySection1;
}

export async function getCompanySection2(): Promise<CompanySection2> {
  const result = await getFondoApi(ONBOARDING_COMPANY, { section: 2 });
  return result as CompanySection2;
}
