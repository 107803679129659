import { Table } from "react-bootstrap";
import CircleReferenceColor from "../fondo-components/CircleReferenceColor";
import { convertNumberToCurrency } from "../../utils/utils";
import { BalanceAccountChartData, CashBalanceChartData } from "../finance-dashboard/financeDashboard.type";
import { formatDateToWords } from "../../utils/common.util";
import AddEndingBalanceButton from "./AddEndingBalanceButton";

const CashBalancePerAccountTable = ({ accountsData }: {accountsData: CashBalanceChartData}) => (
  <>
    <Table className='table mb-0' size="sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Bank Name</th>
          <th>Account</th>
          <th>Date</th>
          <th>Balance</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {accountsData.balances.map((balance: BalanceAccountChartData) => (
          <tr>
            <td>
              <CircleReferenceColor
                color={balance.referenceColor}
                text={balance.account.name + (balance.account.mask ? ` - ${balance.account.mask}` : "")} 
              />
            </td>
            <td>{balance.account.bankName}</td>
            <td>{balance.account.type}</td>
            <td>{formatDateToWords(balance.date)}</td>
            <td>${convertNumberToCurrency(balance.balance)}</td>
            <td>
              {balance.account.uuid
                && balance.account.needsHistoricalEndingBalances
                && balance.account.isInvestmentType && (
                <AddEndingBalanceButton accountUuid={balance.account.uuid}/>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </>
);

export default CashBalancePerAccountTable;
