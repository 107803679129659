import { createContext, useContext, useEffect } from "react";

export const DirtyFormContext = createContext({
  dirty: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDirty: (dirty: boolean) => {
    "no op";
  },
});

// can render this in a Formik wrapped by the context provider
export const DirtyChecker = ({ dirty }: { dirty: boolean }) => {
  const { setDirty } = useContext(DirtyFormContext);
  useEffect(() => {
    setDirty(dirty);
  }, [dirty, setDirty]);
  return <></>;
};
