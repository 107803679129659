import { PresignedURLResponse } from "../../types/PreSignedUrl";
import { DOCUMENT_CENTER_PRE_SIGNED_URL, DOCUMENT_CENTER_DOWNLOAD_URL } from "../../constants/network-calls";
import { getFondoApi, postFondoApi } from "../../utils/util.api";

export const getPresignedURL = async (file: File): Promise<PresignedURLResponse> => {
  const fileSizeMB = file.size / (1024 ** 2);
  const requestBody = {
    appName: "document_center",
    fileName: file.name,
    fileSize: fileSizeMB,
  };
  const res = await postFondoApi(
    DOCUMENT_CENTER_PRE_SIGNED_URL,
    requestBody,
    {},
  );
  return res as PresignedURLResponse;
};

export const getDownloadURL = async (fileId: number): Promise<string> => {
  const res: any = await getFondoApi(
    `${DOCUMENT_CENTER_DOWNLOAD_URL}${fileId}/`,
    {},
  );
  return res;
};

export const formatDocumentUpdatedAt = (updatedAt: string) => {
  const date = new Date(updatedAt);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${month}/${day}/${year}`;
};
