import React from "react";
import { CompanyLevelSettingsContextType } from "./context.type";

export const CompanyLevelSettingsContext = React.createContext<CompanyLevelSettingsContextType>({
  company: 0,
  actionItemsFeatureFlag: true,
  showActionItemOnboardingHeaderFeatureFlag: false,
  showFinanceDashboardRunwayPage: true,
  showFinanceDashboardPNLPage: true,
  showFinanceDashboardBalanceSheetPage: true,
  showFinanceDashboardTaxPlanPage: true,
  showNewCheckoutPage: true,
  showNewProductPage: true,
  showFinanceDashboardTransactionsPage: true,
  showGrowthRateCardOnProfitAndLossPage: true,
  showCompoundGrowthRateCardOnProfitAndLossPage: true,
  showPassportPartners: false,
  showReportingFeatureFlag: false,
  useAiToCategorizeTransactions: false,
  showActionItemsRhoBanner: true,
  showQboIntegrationCard: false,
  showReportingAiAssistant: false,
});
