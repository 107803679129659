import { ONBOARDING_PRODUCT } from "../../../constants/network-calls";
import { ProductData } from "./product.type";
import { getFondoApi, postFondoApi } from "../../../utils/util.api";

export async function saveProduct(data: ProductData): Promise<string> {
  const result: any = await postFondoApi(ONBOARDING_PRODUCT, data);
  return result;
}

export async function getPricing(): Promise<any> {
  const result = await getFondoApi(ONBOARDING_PRODUCT);
  return result;
}
