import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { cookies } from "../App";
import { ENV } from "../../constants/config";
import { logoutApi } from "./logout.api";
import { CSRF_COOKIE_NAME, SESSION_COOKIE_NAME } from "../../constants/general";

const LogoutDropdownItem = () => {
  const navigate = useNavigate();

  async function logout() {
    logoutApi()
      .then(() => {
        cookies.remove(SESSION_COOKIE_NAME, {
          path: "/",
          domain: ENV === "development" ? "localhost" : ".tryfondo.com",
        });
        cookies.remove(CSRF_COOKIE_NAME, {
          path: "/",
          domain: ENV === "development" ? "localhost" : ".tryfondo.com",
        });
        navigate("/login");
      })
      .catch((err) => {
        console.error("error while logging out");
        console.error(err);
      });
  }

  return (
        <Dropdown.Item onClick={logout}>
            <span>Logout</span>
        </Dropdown.Item>
  );
};

export default LogoutDropdownItem;
