import { useQuery } from "@tanstack/react-query";
import { mapMonthDateToMonth, mapMonthDateToQuarter } from "../finance-dashboard/constants";
import { getReportPDF } from "./Reporting.api";
import { QBOReportingData, ReportingType } from "./Reporting.type";
import { amountOfConnectedAccounts, getMergeDevLinkedAccounts } from "../integrations/integrations.api";
import { ProfitAndLossPeriod } from "../finance-dashboard/financeDashboard.type";

export const formatPeriodInHumanReadableText = (report: ReportingType) => {
  const [year, month] = report.timeframeEnd.split("-");
  if (report.groupedBy === "MONTH") {
    return `${mapMonthDateToMonth[month]} ${year}`;
  }
  if (report.groupedBy === "QUARTER") {
    return `${mapMonthDateToQuarter[month]} ${year}`;
  }
  return `Year of ${year}`;
}

export const formatTimeframeEndInHumanReadableText = (report: ReportingType) => {
  const [year, month, day] = report.timeframeEnd.split("-");
  const date = new Date();
  date.setFullYear(Number(year));
  date.setMonth(Number(month) - 1);
  date.setDate(Number(day));
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export const monthStringForReport = (report: ReportingType) => {
  const [year, month] = report.timeframeEnd.split("-");
  return `${year}-${month}`;
}

export const downloadReport = async (report: ReportingType) => {
  try {
    const result = await getReportPDF(report); 
    const blob = new Blob([result as BlobPart], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${report.name  }.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    alert("An error occurred while downloading the report");
  }
}

export const sectionNames = {
  runway: "Runway",
  profitAndLoss: "Profit & Loss",
  balanceSheet: "Balance Sheet",
  executiveSummary: "Executive Summary",
}

export const sectionOptionNames = {
  description: "Description",
  breakdown: "Breakdown",
  revenue: "Revenue",
  expenses: "Expenses",
  netIncome: "Net Income",
  chart: "Chart",
  table: "Table",
  totalCash: "Total Cash",
  netBurn: "Net Burn",
  runway: "Runway",
  liabilitiesAndEquity: "Liabilities And Equity",
  cashBalance: "Cash Balance",
  funding: "Funding",
  burnRate: "Burn Rate",
  averageBurnRate: "Average Burn Rate",
  cashZeroDate: "Cash Zero Date",
}

export const sectionOptionDescriptions = {
  description: "A brief overview of the section's purpose and key points.",
  breakdown: "A detailed breakdown of the section's components.",
  revenue: "The income generated by the company during the reporting period.",
  expenses: "The costs incurred by the company during the reporting period.",
  netIncome: "The profit remaining after subtracting expenses from revenue.",
  chart: "A visual representation of the data presented in the section.",
  table: "A tabular representation of the data presented in the section.",
  totalCash: "The total amount of cash readily available to the company.",
  netBurn: "The net difference between cash inflows and outflows during the period.",
  runway: "The estimated time the company can operate with its current cash flow.",
  liabilitiesAndEquity: "The company's debts and its ownership stake (net assets).",
  cashBalance: "The balance of cash and cash equivalents readily available.",
  funding: "The additional capital raised by the company during the period.",
  burnRate: "The rate at which the company is spending its cash reserves.",
  averageBurnRate: "The average rate of cash expenditure over a specific period.",
  cashZeroDate: "The estimated date when the company's cash reserves will be depleted.",
};


export const missingQBOSections = (selectedReport: Partial<ReportingType>): string => {
  const sections = [];

  if (selectedReport.profitAndLoss) {
      sections.push("Profit and Loss");
  }

  if (selectedReport.balanceSheet) {
      sections.push("Balance Sheet");
  }

  return sections.join(sections.length > 1 ? " and " : "");
};


export const missingBankSections = (selectedReport: Partial<ReportingType>): string => {
  const sections = [];

  if (selectedReport.executiveSummary) {
      sections.push("Executive Summary");
  }

  if (selectedReport.runway) {
      sections.push("Runway");
  }

  return sections.join(sections.length > 1 ? " and " : "");
}

export const createHistoricalProfitAndLossFromQboData = (
  qboReportingData: QBOReportingData
): ProfitAndLossPeriod[] => {
  const qboHistoricalProfitAndLoss: ProfitAndLossPeriod[] = [];
  qboReportingData.headers.slice(0, -1).forEach((header, index) => {
    const incomeAmount = qboReportingData.results.find(
      (item) => item.baseName?.toLocaleLowerCase() === "income")?.amount[index];
    const expensesAmount = qboReportingData.results.find(
      (item) => item.baseName?.toLocaleLowerCase() === "expenses")?.amount[index];
    const revenue = Number(incomeAmount || 0);
    const expenses = Number(expensesAmount || 0);
    const qboPNL = {
      period: header.name,
      revenueAmount: revenue,
      expensesAmount: expenses,
    }
    qboHistoricalProfitAndLoss.push(qboPNL);
  })
  return qboHistoricalProfitAndLoss;
}

export const getNetIncomeFromQboData = (
  qboReportingData: QBOReportingData
): number[] => {
  const netIncome: number[] = [];
  const netIncomeRow = qboReportingData.results.find(
    (item) => item.name?.toLocaleLowerCase() === "net income"
    )
  qboReportingData.headers.slice(0, -1).forEach((header, index) => {
    if (netIncomeRow?.amount[index]) {
      netIncome.push(Number(netIncomeRow?.amount[index]));
    }
  });
  return netIncome;
};
