import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Tooltip,
    Filler,
  } from "chart.js";
  
  import { Bar } from "react-chartjs-2";
  import { ChartData } from "./types";
  import { getChartColorsWithLastOneActive } from "../../finance-dashboard/utils";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Tooltip,
    Filler,
  );
  
  type FondoBarChartSquareProps = {
    minYAxisValue?: number;
    maxYAxisValue?: number;
    labels: string[];
    chartData: ChartData[];
    noDataState?: boolean;
    reverse?: boolean;
    getLastBarColorActive?: boolean;
    showBorders?: boolean;
  }
  
  const FondoBarChartSquare = ({
    minYAxisValue,
    maxYAxisValue,
    labels,
    chartData,
    noDataState = false,
    reverse = false,
    showBorders = true,
    getLastBarColorActive = false,
  }: FondoBarChartSquareProps) => {
    const options = {
      maintainAspectRatio: false,
      barThickness: 15,
      borderRadius: 3,
      scales: {
        y: {
          min: minYAxisValue,
          max: maxYAxisValue,
          border: { dash: [showBorders ? 4 : 0, 4] },
          beginAtZero: true,
          reverse,
        },
        x: {
            border: {
                dash: [showBorders ? 4 : 0, 4],
            }
        }
      },
    };
  
    const datasets = chartData.map((object: any) => {
      const newObject = { ...object };
      if (getLastBarColorActive) {
        newObject.backgroundColor = getChartColorsWithLastOneActive(object.data);
      } else {
        newObject.backgroundColor = object.color;
      }
      delete newObject.color;
      return newObject;
    });

    const data = {
      labels,
      datasets,
    };
  
    return (
      <>
        {noDataState ? (
          <Bar options={options} data={ { datasets: [] } } height="270px" />
        ) : (
          <Bar options={options} data={data} height="270px" />
        )}
      </>
    );
  };
  
  export default FondoBarChartSquare;
  