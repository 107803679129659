import { useQuery } from "@tanstack/react-query";
import FondoLayout from "../fusion-kit/FondoLayout";
import ActionItemDetailView from "./ActionItemDetailView";
import { getActionItems } from "./actionItems.api";
import { ActionItem } from "./actionItems.type";

const FondoActionItemsPostOnboarding = () => {
  const { data: actionItems } = useQuery<ActionItem[], Error>(["actionItems"], () => getActionItems(), {});

  return <FondoLayout title="Action Items">
        {actionItems?.map((actionItem: ActionItem) => (
            <div key={actionItem.id} className="mb-3">
                <ActionItemDetailView actionItem={actionItem} />
            </div>
        ))}
    </FondoLayout>;
};

export default FondoActionItemsPostOnboarding;
