import Cleave from "cleave.js/react";
import { Field, FormikContextType } from "formik";
import { useState } from "react";
import { InputGroup } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

type SsnFieldProps = {
  formikContext: FormikContextType<any>;
  fieldName: string;
  invalid?: boolean;
  selectedCountry?: string;
  enablePasswordType?: boolean;
}

const SsnField = ({
  formikContext,
  fieldName,
  invalid,
  selectedCountry,
  enablePasswordType = false,
}: SsnFieldProps) => {
  const [visible, setVisible] = useState(false);
  const className = `form-control ${invalid ? "is-invalid" : ""}`;
  const fieldType = !visible && enablePasswordType ? "password" : "text";

  return (
    <InputGroup className='input-group-merge mb-3'>
      { `${selectedCountry}-${fieldName}` === `US-${fieldName}`
        ? <Cleave
          {...formikContext.getFieldProps(fieldName)}
          options={{
            delimiter: "-",
            blocks: [3, 2, 4],
            numericOnly: true,
          }}
          placeholder="555-55-5555"
          className={className}
          type={fieldType}
          autoComplete="off"
        />
        : <Field
          {...formikContext.getFieldProps(fieldName)}
          className={className}
          type={fieldType}
          autoComplete="off"
        />
      }
      <InputGroup.Text>
      { enablePasswordType
        && <FeatherIcon
          icon={visible ? "eye-off" : "eye"}
          size='1em'
          onClick={() => setVisible(!visible)}
          style={{ cursor: "pointer" }}
        />

      }
      </InputGroup.Text>
    </InputGroup>
  );
};

export default SsnField;
