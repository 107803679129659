import { Button, Card, Modal } from "react-bootstrap";
import { FormikProps } from "formik";
import FeatherIcon from "feather-icons-react";
import { useMutation } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { Partner, Review, ReviewData, ReviewStatus } from "../passport-partners.types";
import { PartnerCardTopBar } from "../PartnerCardTopBar";
import { UpsertReviewForm } from "./UpsertReviewForm";
import { RoundedImage } from "../../fondo-components/RoundedImage";
import { createReview } from "../passport-partners.api";
import { DirtyFormContext } from "../../../context/DirtyFormContext";

type ReviewCardProps = {
  review: Review;
  partner: Partner;
  onUpsertSuccess?: () => void;
  onDismiss?: () => void;
};

export const ReviewCard = ({
  review,
  partner,
  onUpsertSuccess: parentOnUpsertSuccess,
  onDismiss: parentOnDismiss,
}: ReviewCardProps) => {
  const dismissReviewMutation = useMutation(() => createReview({ ...review, reviewStatus: ReviewStatus.DISMISSED }));
  const originalReviewStatus = review.reviewStatus;
  const [userSelectedReviewStatus, setUserSelectedReviewStatus] = useState<ReviewStatus | undefined>(
    originalReviewStatus === ReviewStatus.COMPLETED ? ReviewStatus.COMPLETED : undefined,
  );
  const [upsertErrorOccurred, setUpsertErrorOccurred] = useState(false);
  const [showDismissModal, setShowDismissModal] = useState(false);
  const dirtyRef = useRef(false); // use ref to prevent rerender

  if (userSelectedReviewStatus === ReviewStatus.DISMISSED) {
    return <></>;
  }

  const onUpsertSuccess = () => {
    setUserSelectedReviewStatus(ReviewStatus.COMPLETED);
    setUpsertErrorOccurred(false);
    parentOnUpsertSuccess?.();
  };

  const onUpsertError = () => {
    setUserSelectedReviewStatus(undefined);
    setUpsertErrorOccurred(true);
  };

  const getCardFooter = (formikContext: FormikProps<ReviewData>) => {
    let cardContent = <></>;
    if (userSelectedReviewStatus === ReviewStatus.COMPLETED) {
      cardContent = (
        <Card bg="success-soft" className="w-100">
          <Card.Body className="d-flex justify-content-between align-items-center p-2">
            <span className="text-success text-center user-select-none px-2">Review submitted. Thank you!</span>
            <Button
              variant="link"
              className="text-decoration-underline text-success"
              onClick={() => setUserSelectedReviewStatus(undefined)}
            >
              Edit Review
            </Button>
          </Card.Body>
        </Card>
      );
    } else {
      const submitReviewText = `Submit Review ${originalReviewStatus === ReviewStatus.COMPLETED ? " Changes" : ""}`;
      const variant = upsertErrorOccurred ? "danger" : "primary";
      const buttonText = upsertErrorOccurred ? "Try Again" : submitReviewText;
      cardContent = (
        <Button className="w-100" variant={variant} type="submit" disabled={!formikContext.isValid}>
          {buttonText}
        </Button>
      );
    }
    return <Card.Footer className="border-top-0 pt-0">{cardContent}</Card.Footer>;
  };

  const onDismiss = () => {
    parentOnDismiss?.();
    if (!originalReviewStatus) {
      dismissReviewMutation.mutate();
    }
    setUserSelectedReviewStatus(ReviewStatus.DISMISSED);
  };

  const getDismissButton = () => {
    if (userSelectedReviewStatus === ReviewStatus.COMPLETED) {
      return <></>;
    }
    return (
      <Button
        variant=""
        className="text-secondary"
        onClick={() => {
          if (dirtyRef.current) {
            setShowDismissModal(true);
          } else {
            onDismiss();
          }
        }}
      >
        <FeatherIcon icon="x" />
      </Button>
    );
  };

  return (
    <>
      <Card className="w-100">
        <PartnerCardTopBar dropdownItems={getDismissButton()} />
        <Card.Header style={{ height: "fit-content" }}>
          <div className="d-flex">
            {partner.logoUrl && <RoundedImage logoUrl={partner.logoUrl} className="border me-1" imageDiameter="4rem" />}
            <div className="d-flex flex-column justify-content-center ms-3">
              <Card.Title as="span" className="fs-2 fw-bold m-0">
                {partner.name}
              </Card.Title>
              <span className="text-secondary fs-4">{partner.description}</span>
            </div>
          </div>
        </Card.Header>
        <DirtyFormContext.Provider
          value={{
            dirty: false,
            setDirty: (dirty) => {
              dirtyRef.current = dirty;
            },
          }}
        >
          <UpsertReviewForm
            existingReview={review}
            renderSubmitButtons={getCardFooter}
            FormWrapper={({ children }) => <Card.Body className="pb-0">{children}</Card.Body>}
            onUpsertSuccess={onUpsertSuccess}
            onUpsertError={onUpsertError}
            onSubmit={onUpsertSuccess}
          />
        </DirtyFormContext.Provider>
      </Card>
      <Modal size="sm" centered show={showDismissModal} onHide={() => setShowDismissModal(false)}>
        <Modal.Body className="d-flex flex-column align-items-center">
          <p className="text-center">
            Are you sure you want to dismiss this partner? You'll be able to review them again if you revisit this page.
          </p>
          <div className="d-flex justify-content-between w-100">
            <Button style={{ width: "49%" }} variant="white" onClick={() => setShowDismissModal(false)}>
              Cancel
            </Button>
            <Button style={{ width: "49%" }} variant="secondary" onClick={onDismiss}>
              Dismiss
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
