import { Image } from "react-bootstrap";

type RoundedImageProps = {
  logoUrl: string;
  imageDiameter?: string;
  objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down";
  className?: string;
};

export const RoundedImage = ({ logoUrl, imageDiameter, objectFit, className }: RoundedImageProps) => {
  const imageStyle = {
    width: imageDiameter || "6rem",
    height: imageDiameter || "6rem",
    objectFit: objectFit || "contain",
  } as React.CSSProperties;
  return <Image src={logoUrl} className={className || "border"} style={imageStyle} roundedCircle />;
};
