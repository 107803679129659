import { useState } from "react";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import { CorpTaxFormFields, CorporateTaxStep, MajorityShareholderFormFields } from "./corporateTax.type";
import AddMajorityShareholderModal from "./modals/AddMajorityShareholderModal";
import { getYesNoFromPythonBoolean, post2023Form } from "../../utils/common.util";
import { Post2023CorporateTaxStep } from "./post-2023/post2023CorporateTax.type";
import DisplaySingleLineAddress from "../forms/display-helpers/DisplaySingleLineAddress";
import DisplayCountryName from "../forms/display-helpers/DisplayCountryName";
import DataVisibilityToggle from "../fusion-kit/DataVisibilityToggle";

type MajorityShareholdersProps = {
  arrayHelpers: FieldArrayRenderProps;
  stepInfo: CorporateTaxStep | Post2023CorporateTaxStep;
}

export default function MajorityShareholders({ arrayHelpers, stepInfo }: MajorityShareholdersProps) {
  const formikContext = useFormikContext<CorpTaxFormFields>();

  const error = "";

  const [showAddMajorityShareholderModal, setShowAddMajorityShareholderModal] = useState<boolean>(false);
  const [currentlySelectedShareholderIndex, setCurrentlySelectedShareholderIndex] = useState<number | undefined>();

  const handleCancel = () => {
    setShowAddMajorityShareholderModal(false);
    setCurrentlySelectedShareholderIndex(undefined);
  };

  const handleSave = (officerData: MajorityShareholderFormFields) => {
    if (currentlySelectedShareholderIndex !== undefined) {
      arrayHelpers.replace(currentlySelectedShareholderIndex, officerData);
    } else {
      arrayHelpers.push(officerData);
    }

    setCurrentlySelectedShareholderIndex(undefined);
    setShowAddMajorityShareholderModal(false);
  };

  const handleDelete = (index: number, majorityShareholder: MajorityShareholderFormFields) => {
    if (majorityShareholder.uuid) {
      formikContext.setFieldValue(
        "oldMajorityShareholders",
        [...formikContext.values.oldMajorityShareholders, majorityShareholder.uuid],
      );
    }
    arrayHelpers.remove(index);
  };

  const fieldHasError = (index: number, fields: string[]): boolean => (
    fields.some((field) => !!(formikContext.errors?.majorityShareholders?.[index] as any)?.[field])
  );

  function renderMajorityShareholders() {
    return (
      formikContext.values.majorityShareholders.map((majorityShareholder, index) => (

        <div className="col-lg-6 col-xl-4 mb-5">
          <div className="d-flex justify-content-between">
            <h3 className={`${formikContext.errors?.majorityShareholders?.[index] ? "text-danger" : ""}`}>
              {`${majorityShareholder.firstName || ""} ${majorityShareholder.lastName || ""}`}
              {formikContext.errors?.majorityShareholders?.[index] && (<i
                className="fe fe-alert-circle text-danger ms-2"
                style={{ marginBottom: "-0.1em" }}
              />)}
            </h3>
            <div>
              <span>
                <i
                  className="fe fe-edit-2 mx-2 action-icon text-secondary"
                  onClick={() => {
                    setCurrentlySelectedShareholderIndex(index);
                    setShowAddMajorityShareholderModal(true);
                  }}
                  style={{ cursor: "pointer" }}
                  title="Edit"
                ></i>
              </span>
              <span>
                <i
                  className="fe fe-trash mx-2 action-icon text-secondary"
                  title="Delete"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDelete(index, majorityShareholder)}
                ></i>
              </span>
            </div>
          </div>
          <hr className='mt-0' />
          <div className='row'>
            <p
              className={`data-item ${fieldHasError(index, ["firstName", "lastName"]) ? "text-danger" : ""}`}
            >
              Name:
              {` ${majorityShareholder.firstName || ""} ${majorityShareholder.lastName || ""}`}
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["title"]) ? "text-danger" : ""}`}
            >
              Title: {majorityShareholder?.title}
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["percentageOwnership"]) ? "text-danger" : ""}`}
            >
              Percentage Ownership: {majorityShareholder?.percentageOwnership || 0}%
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["phone"]) ? "text-danger" : ""}`}
            >
              Phone: {majorityShareholder?.phone}
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["email"]) ? "text-danger" : ""}`}
            >
              Email: {majorityShareholder?.email}
            </p>
            <p
              className={`data-item ${fieldHasError(index, [
                "streetAddress", "city", "state", "postalCode",
              ]) ? "text-danger" : ""}`}
            >
              Address: <DisplaySingleLineAddress addressable={majorityShareholder} />
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["countryOfTaxResidence"]) ? "text-danger" : ""}`}
            >
              Country of tax residence: {majorityShareholder.countryOfTaxResidence && (
                <DisplayCountryName countryCode={majorityShareholder.countryOfTaxResidence}/>
            )}
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["countryOfTaxResidenceTaxIdEnc"]) ? "text-danger" : ""}`}
            >
              Country of tax residence - Tax ID: <DataVisibilityToggle
                data={majorityShareholder?.countryOfTaxResidenceTaxIdEnc}
              />
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["countryOfCitizenship"]) ? "text-danger" : ""}`}
            >
              Country of citizenship: {majorityShareholder.countryOfCitizenship && (
                <DisplayCountryName countryCode={majorityShareholder.countryOfCitizenship}/>
            )}
            </p>
            {majorityShareholder.countryOfCitizenship !== majorityShareholder.countryOfTaxResidence && (
              <p
                className={`data-item ${fieldHasError(index, ["countryOfCitizenshipTaxIdEnc"]) ? "text-danger" : ""}`}
              >
                Country of citizenship - Tax ID: <DataVisibilityToggle
                  data={majorityShareholder?.countryOfCitizenshipTaxIdEnc}
                />
              </p>
            )}
            {post2023Form(stepInfo.taxYear) && (
              <>
                <p
                  className={`data-item ${fieldHasError(index, ["officer"]) ? "text-danger" : ""}`}
                >
                  Officer: {getYesNoFromPythonBoolean(majorityShareholder?.officer)}
                </p>
                <p
                  className={`data-item ${fieldHasError(index, ["signingOfficer"]) ? "text-danger" : ""}`}
                >
                  Signing Officer: {getYesNoFromPythonBoolean(majorityShareholder?.signingOfficer)}
                </p>
              </>
            )}
          </div>
        </div>
      ))
    );
  }

  return (<>
    {formikContext.values.majorityShareholders.length > 0 && (
      <div>
        <div className="row mt-5">
          {renderMajorityShareholders()}
        </div>
        <div className="mb-5">
          <button type="button" className="btn btn-primary" onClick={() => {
            setShowAddMajorityShareholderModal(true);
          }}>Add another</button>
        </div>
      </div>
    )}

    {(formikContext.values.majorityShareholders.length === 0)
      && <div className='text-align-center' style={{ margin: "200px" }}>
        <h3 className="text-secondary mb-4">Start by adding a new shareholder</h3>
        <button type="button" className="btn btn-primary" onClick={() => {
          setShowAddMajorityShareholderModal(true);
        }}>Add new</button>
        {error
          && <h3 className="text-danger mt-4 field-error text-center">You need to add at least one shareholder.</h3>}
      </div>
    }

    <p className="text-center text-muted mt-n5">
      <i className="fe fe-info mr-1" />
        Please provide the following information for each shareholder who owned more than 20% in {stepInfo.taxYear}.
    </p>

    <AddMajorityShareholderModal
      show={showAddMajorityShareholderModal}
      handleClose={handleCancel}
      handleSave={handleSave}
      majorityShareholder={currentlySelectedShareholderIndex !== undefined
        ? formikContext.values.majorityShareholders[currentlySelectedShareholderIndex] : undefined}
      header="Add new shareholder"
      taxYear={stepInfo.taxYear}
    />
  </>);
}
