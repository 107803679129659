import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import {
  foreignBankAccountsFieldToLabelMap,
  ForeignBankAccountsFormFields,
  foreignBankAccountsToTypeMap,
} from "../foreignSubsidiary.type";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import AddressFieldGroup from "../../forms/formik-components/AddressFieldGroup";
import { currencySelectorChoices } from "../utils";
import { foreignBankAccountsSchema } from "../validationSchemas";

type AddForeignBankAccountsModalProps = {
  show: boolean;
  header: string;
  foreignBankAccount?: ForeignBankAccountsFormFields;
  handleClose: () => any;
  handleSave: (foreignBankAccounts: ForeignBankAccountsFormFields) => void;
};

const AddForeignBankAccountsModal = (props: AddForeignBankAccountsModalProps) => {
  const initialValues = props.foreignBankAccount || {
    name: "",
    type: "",
    accountNumber: "",
    country: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    currency: "",
    maxValueDuringYear: 0,
    dateOfMaxValue: "",
    openedDuringYear: "",
    closedDuringYear: "",
  };

  return (
    <Modal show={props.show} onHide={() => props.handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={foreignBankAccountsSchema}
            onSubmit={(values) => {
              props.handleSave(values);
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="name"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="type"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="accountNumber"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <AddressFieldGroup
                  formikContext={formik}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="currency"
                  selectorOptions={currencySelectorChoices}
                  includeSelectorEmptyValue={true}
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="maxValueDuringYear"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="dateOfMaxValue"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="openedDuringYear"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="closedDuringYear"
                  labels={foreignBankAccountsFieldToLabelMap}
                  types={foreignBankAccountsToTypeMap}
                />
                <div className="row mb-3 mt-2">
                  <div className="d-flex flex-row col-12 col-md-12 justify-content-end">
                    <button className="btn btn-lg btn-primary" type="submit"><div>Save</div></button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddForeignBankAccountsModal;
