const broadcastChannel = new BroadcastChannel(`fondo_app_${process.env.REACT_APP_FONDO_ENVIRONMENT}`);

export enum BroadcastMessageTypes {
  FORCE_RELOAD = "Force Reload"
}

export const broadcast = (message: string): void => {
  broadcastChannel.postMessage(message);
};

broadcastChannel.onmessage = (event: MessageEvent): void => {
  if (event.data === BroadcastMessageTypes.FORCE_RELOAD) {
    window.location.reload();
  }
};
