import React from "react";

export type FinanceDashboardContextType = {
    useQboSource: boolean;
    setUseQboSource: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FinanceDashboardContext = React.createContext<FinanceDashboardContextType>({
  useQboSource: true,
  setUseQboSource: () => undefined,
});
