// tax-year-info/

import { GET_FLOW_TRACKING, TAX_YEAR_INFO } from "../../constants/network-calls";
import { TaxYearInfoType } from "./onboarding.type";
import { getFondoApi } from "../../utils/util.api";

export async function getTaxYearInfo(): Promise<TaxYearInfoType> {
  const result = await getFondoApi(TAX_YEAR_INFO);
  return result as TaxYearInfoType;
}

export async function getFlowTracking(flowType: string): Promise<string> {
  const result: any = await getFondoApi(GET_FLOW_TRACKING, { flowType });
  return result;
}
