import { useFormik } from "formik";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { removeCommas } from "../../utils/common.util";
import { AsyncSelectItem } from "../asyncSelectors/asyncSelectors.type";
import { NewBankAccount } from "../finance-dashboard/financeDashboard.type";
import { parseMonthAndYearDateToWords } from "../finance-dashboard/utils";
import FormikDatePickerField from "../flatpickr/FormikFlatpickrField";
import FondoCurrencyField from "../forms/formik-components/FondoCurrencyField";
import manuallyAddedBankAccountSchema from "../formSchemas/auditManuallyAddedBankAccounts";
import AsyncFinancialHubInstitutionConnectionSelect from
  "../asyncSelectors/AsyncFinancialHubInstitutionConnectionSelect";

type RunwayAuditToolManuallyAddFormProps = {
  handleCloseModal: () => any;
  selectedMonth: string;
  handleAddBankAccount: (newBankAccount: NewBankAccount) => void;
}

const RunwayAuditToolManuallyAddForm = ({
  handleCloseModal,
  selectedMonth,
  handleAddBankAccount,
}: RunwayAuditToolManuallyAddFormProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: {
      institutionName: "",
      institutionUuid: "",
      accountName: "",
      selectedMonthEndingBalance: "",
      selectedYearAndMonth: selectedMonth,
      accountCreatedAt: "",
    },
    validationSchema: manuallyAddedBankAccountSchema,
    onSubmit: () => {
      handleAddBankAccount(
        {
          ...values,
          selectedMonthEndingBalance: removeCommas(values.selectedMonthEndingBalance),
          accountCreatedAt: values.accountCreatedAt.split("T")[0],
        },
      );
      handleCloseModal();
    },
  });

  const [selectedInstitution, setSelectedInstitution] = useState<AsyncSelectItem>(
    { label: "", id: "" },
  );

  const handleInstitutionSelect = (selectedItem: AsyncSelectItem[]) => {
    const institution = selectedItem[0];
    if (institution) {
      setSelectedInstitution(institution);
      setFieldValue("institutionName", institution.label);
      const id = institution.customOption ? "" : institution.id;
      setFieldValue("institutionUuid", id);
    } else {
      setSelectedInstitution({ label: "", id: "" });
      setFieldValue("institutionName", undefined);
      setFieldValue("institutionUuid", undefined);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className='mb-4'>
        <Form.Label>Bank name</Form.Label>
        <AsyncFinancialHubInstitutionConnectionSelect
          handleSelection={handleInstitutionSelect}
          multiple={false}
          selected={selectedInstitution.label ? [selectedInstitution] : []}
          widgetSize="lg"
          isInvalid={!!errors.institutionName}
          allowNew={true}
          onBlur={handleBlur}
          excludeManuallyAddedAccounts
        />
        <Form.Control.Feedback
          type='invalid'
          style={{ display: errors.institutionName ? "block" : "none" }}
        >
          {errors.institutionName}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-4'>
        <Form.Label>Account name</Form.Label>
        <Form.Control
            value={values.accountName}
            onChange={handleChange}
            onBlur={handleBlur}
            name='accountName'
            isInvalid={!!errors.accountName && touched.accountName}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.accountName}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-4' >
        <Form.Label>Account created at</Form.Label>
        <FormikDatePickerField
            fieldName='accountCreatedAt'
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            date={values.accountCreatedAt}
            maxDate="today"
        />
        <Form.Control.Feedback
            type='invalid'
            style={{ display: errors.accountCreatedAt ? "block" : "none" }}
        >
            {errors.accountCreatedAt}
        </Form.Control.Feedback>
    </Form.Group>
      <Form.Group className='mb-4'>
        <Form.Label>Ending balance</Form.Label>
        <FondoCurrencyField
          value={values.selectedMonthEndingBalance}
          onChange={handleChange}
          onBlur={handleBlur}
          name="selectedMonthEndingBalance"
          isInvalid={!!errors.selectedMonthEndingBalance && touched.selectedMonthEndingBalance}
          allowNegative={true}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.selectedMonthEndingBalance}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-4'>
        <Form.Label>As of</Form.Label>
        <Form.Control
            value={parseMonthAndYearDateToWords(values.selectedYearAndMonth)}
            onChange={handleChange}
            onBlur={handleBlur}
            name='selectedYearAndMonth'
            isInvalid={!!errors.selectedYearAndMonth && touched.selectedYearAndMonth}
            disabled
            readOnly
            style={{ backgroundColor: "lightgray" }}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.selectedYearAndMonth}
        </Form.Control.Feedback>
      </Form.Group>
      <div className='d-flex justify-content-between mt-5'>
        <Button variant='light' onClick={handleCloseModal}>
            Cancel
        </Button>
        <Button type='submit' disabled={!isValid} >
            Save
        </Button>
      </div>
    </Form>
  );
};

export default RunwayAuditToolManuallyAddForm;
