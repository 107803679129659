import { Button } from "react-bootstrap";
import partyingFace from "../../assets/img/partying-face.svg";

export type Step = {
  name: string;
  complete: boolean;
}

type ProgressTrackerProps = {
  steps: Step[];
  className?: string;
  competeText?: string;
};

const ProgressTracker = ({ steps, className, competeText }: ProgressTrackerProps) => {
  const isStepActive = (idx: number): boolean => {
    const currentStep = steps[idx];
    if (currentStep.complete) return false;
    if (idx !== steps.length && idx !== 0) {
      const previousStep = steps[idx - 1];
      return previousStep.complete;
    }
    return true;
  };

  const allStepsComplete = () => steps.every((step) => step.complete);

  const getTextColor = (step: Step) => {
    if (allStepsComplete()) {
      return "text-success";
    }
    return step.complete ? "text-primary" : "text-muted";
  };

  const isLastStep = (idx: number): boolean => idx === (steps.length - 1);

  return (
    <div className={className}>
      {allStepsComplete() && <h3 className="text-success text-center">{competeText}</h3>}
      <ul
        className=
        {`my-5 progress-tracker progress-tracker--center progress-tracker--text
        progress-tracker--theme-fondo${allStepsComplete() ? "-success" : ""}`}
      >
        {steps.map((step, idx) => (
          <>
          <li className={`progress-step ${isStepActive(idx) && "is-active"}  ${step.complete && "is-complete"}`}>
            {isLastStep(idx) && allStepsComplete() && (
              <div
                style={{ marginTop: "-44px", paddingBottom: "7px" }}
              >
                <img src={partyingFace} />
              </div>
            )}
            <div className="progress-marker" style={{ zIndex: 10 }}>
              <div className="progress-text">
                {isStepActive(idx)
                  ? (<Button size="sm" style={{ cursor: "default" }}>{step.name}</Button>)
                  : (<h4 className={`${getTextColor(step)}`}>{step.name}</h4>)
                }
              </div>
            </div>
          </li>
          </>
        ))}
      </ul>
    </div>
  );
};

export default ProgressTracker;
