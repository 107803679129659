import { useState } from "react";
import { Button } from "react-bootstrap";
import EndOfTheMonthBalanceModalForm from "./EndOfTheMonthBalanceModalForm";

type AddEndingBalanceButtonProps = {
  accountUuid: string;
}

const AddEndingBalanceButton = ({ accountUuid }: AddEndingBalanceButtonProps) => {
  const [showEndOfTheMonthBalanceModal, setShowEndOfTheMonthBalanceModal] = useState(false);
  return (
    <>
      <Button
        variant="warning btn-sm"
        onClick={() => setShowEndOfTheMonthBalanceModal(true)}
      >
        Action required
      </Button>
      <EndOfTheMonthBalanceModalForm
        accountUuid={accountUuid}
        show={showEndOfTheMonthBalanceModal}
        handleClose={() => setShowEndOfTheMonthBalanceModal(false)}
      />
    </>
  );
};

export default AddEndingBalanceButton;
