import { useFormikContext } from "formik";
import { useState } from "react";
import RnDContractorsSection from "./RnDContractorsSection";
import RnDWagesSection from "./RnDWagesSection";
import RnDSuppliesSection from "./RnDSuppliesSection";
import ConfirmationModal from "../../fusion-kit/ConfirmationModal";
import { RndContractor, RndEmployee, RndSupplies } from "./baseFormData";

type RnDRow = {
  arrayHelperFunc: (index: number) => void;
  index: number;
  rndEmployee?: RndEmployee;
  rndContractor?: RndContractor
  rndSupply?: RndSupplies;
};

export type RnDSectionProps = {
  setCurrentRowData: React.Dispatch<React.SetStateAction<RnDRow | undefined>>;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const RnDPage2 = () => {
  const formikContext = useFormikContext<any>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRowData, setCurrentRowData] = useState<RnDRow>();

  const handleDelete = () => {
    if (currentRowData?.rndEmployee) {
      removeEmployee(currentRowData.arrayHelperFunc, currentRowData.index, currentRowData.rndEmployee);
    } else if (currentRowData?.rndContractor) {
      removeContractor(currentRowData.arrayHelperFunc, currentRowData.index, currentRowData.rndContractor);
    } else if (currentRowData?.rndSupply) {
      removeSupply(currentRowData.arrayHelperFunc, currentRowData.index, currentRowData.rndSupply);
    }
    setCurrentRowData(undefined);
    setShowDeleteModal(false);
  };

  const removeEmployee = (removeFunc: (index: number) => void, index: number, wage: RndEmployee) => {
    if (wage.uuid) {
      formikContext.setFieldValue("wagesToDelete", [...formikContext.values.wagesToDelete, wage.uuid]);
    }
    removeFunc(index);
  };

  const removeContractor = (removeFunc: (index: number) => void, index: number, contractor: RndContractor) => {
    if (contractor.uuid) {
      formikContext.setFieldValue(
        "contractorsToDelete",
        [...formikContext.values.contractorsToDelete, contractor.uuid],
      );
    }
    removeFunc(index);
  };

  const removeSupply = (removeFunc: (index: number) => void, index: number, supply: RndSupplies) => {
    if (supply.id) {
      formikContext.setFieldValue("suppliesToDelete", [...formikContext.values.suppliesToDelete, supply.id]);
    }
    removeFunc(index);
  };

  return (
    <>
      <h4 className=" mb-4">Qualifying Research Expenses</h4>
      <RnDWagesSection setCurrentRowData={setCurrentRowData} setShowDeleteModal={setShowDeleteModal} />
      <RnDContractorsSection setCurrentRowData={setCurrentRowData} setShowDeleteModal={setShowDeleteModal} />
      <RnDSuppliesSection setCurrentRowData={setCurrentRowData} setShowDeleteModal={setShowDeleteModal} />
      <ConfirmationModal
        show={showDeleteModal}
        titleText="Are you sure you want to delete this row?"
        confirmButtonText="Delete"
        handleClose={() => setShowDeleteModal(false)}
        handleConfirm={() => handleDelete()}
      />
    </>
  );
};

export default RnDPage2;
