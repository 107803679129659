import { Nav } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import FondoBarChartSquare from "../../fondo-components/Charts/FondoBarChartSquare";
import { QBOReportingHeader, QBOReportingResult } from "../Reporting.type";
import { formatCurrency } from "../../../utils/common.util";

type DrillDownPopoverContentProps = {
  item: QBOReportingResult;
  periods: QBOReportingHeader[];
};

const DrillDownPopoverContent = ({ item, periods }: DrillDownPopoverContentProps) => {
  const popoverHeaderStyle = {
    color: "#2A4171",
    backgroundColor: "red",
    borderRadius: "0.5em 0.5em 0 0",
  };

  const amounts = item.amount;
  const currentAmount = amounts.at(-2);

  const renderCurrency = (amount?: string): string => {
    // If the amount is undefined it means it doesn't exist in the list of amounts
    if (amount === undefined) {
      return "";
    }
    return amount ? formatCurrency(amount) : "$0.00"
  };
  
  const chartHeaders = periods.map((period) => period.name.toUpperCase());
  const chartData = amounts.slice(0, -1).map((amount) => parseFloat(amount || "0.00"));

  return (
    <>
      <div style={popoverHeaderStyle} className="p-3 pb-0 bg-light bg-gradient">
        <h3 className="m-0 text-truncate mt-1">
          <FeatherIcon icon="tag" size="1em" className="mt-n1 me-2" style={{ transform: "scaleX(-1)" }} />
          {item.name}
        </h3>
        <Nav variant="tabs" defaultActiveKey="overview" className="mt-1">
          <Nav.Item>
            <Nav.Link eventKey="overview" className="text-uppercase fw-bold pb-1">Overview</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div className="px-3 pt-3 pb-0">
        <div className="d-flex justify-content-between">
          <p className="fw-light text-muted fs-3">{periods.at(-1)?.name}</p>
          <p className="fw-bold fs-3" style={{color: "#2A4171"}}>{renderCurrency(currentAmount)}</p>
        </div>
      </div>
      <div className="p-3 pt-0" style={{height: "230px"}}>
        <FondoBarChartSquare
          labels={chartHeaders}
          chartData={[{data: chartData, color: "#D0D6E0"}]}
          showBorders={false}
          getLastBarColorActive
        />
      </div>
    </>
  );
};

export default DrillDownPopoverContent;
