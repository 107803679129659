import {
  SIDENAV_INFO, USER_COMPANIES,
} from "../../constants/network-calls";
import { SidenavInfoResponse, UserCompaniesResponse } from "./sidenav.type";
import { getFondoApi, postFondoApi } from "../../utils/util.api";

export async function getSidenavInfo(): Promise<SidenavInfoResponse> {
  const res = await getFondoApi(SIDENAV_INFO, {});
  return res as SidenavInfoResponse;
}

export async function getUserCompanies(): Promise<UserCompaniesResponse> {
  const result: any = await getFondoApi(USER_COMPANIES, {});
  return result as UserCompaniesResponse;
}

export async function saveSelectedCompany(selectedCompanyId: string): Promise<any> {
  const result = await postFondoApi(USER_COMPANIES, { selectedCompanyId }, {});
  return result;
}
