import { Card, CardGroup, Spinner } from "react-bootstrap";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import fondoLogo from "../../../assets/img/fondo-icon.png";
import bookkeepingServiceIllustration from "./illustrations/bookkeepingServiceImg.png";
import taxPassServiceIllustration from "./illustrations/taxPassServiceImg.png";
import taxCreditsServiceIllustration from "./illustrations/taxCreditsServiceImg.png";
import SalesCard from "./SalesCard";
import { darkBlueText } from "./utils";
import { LoggedInUserContext } from "../../../context/LoggedInUserContext";
import { getCustomerServicesRequired, getPurchasedAccountingServices } from "../../customer-order/customerOrder.api";
import { getDepositPaid } from "../../payment/payment.api";
import { DepositInformation } from "../../payment/payment.type";
import { CompanyLevelSettingsContext } from "../../../context/FeatureFlagsContext";
import { AccountingService } from "../../../common/type";

const AdditionalFondoServicesCard = () => {
  const { currentCompanyId, currentDashboardOnboarding } = useContext(LoggedInUserContext);
  const { showNewCheckoutPage } = useContext(CompanyLevelSettingsContext);

  // uses the old Order API
  const { data: customerServicesRequired, isLoading: customerServicesRequiredIsLoading } = useQuery<any, Error>(
    ["customerServicesRequired", currentCompanyId],
    () => getCustomerServicesRequired(currentCompanyId || ""),
    { enabled: !!currentCompanyId },
  );

  const {
    data: purchasedAccountingServices,
    isLoading: purchasedAccountingServicesIsLoading,
  } = useQuery<AccountingService[], Error>(
    ["purchasedAccountingServices"],
    getPurchasedAccountingServices,
    {
      enabled: !!currentCompanyId,
    },
  );

  const { data: depositInfo } = useQuery<DepositInformation, Error>(["depositInfo"], getDepositPaid, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const onboardingCheckoutUrl = showNewCheckoutPage ? "/onboarding/checkout" : "/onboarding/product";

  const getModalRedirectUrl = () => {
    let redirectUrl = "";
    if (depositInfo && depositInfo.hasPaidDeposit) {
      redirectUrl = "/dashboard/products/";
    } else {
      redirectUrl = currentDashboardOnboarding ? "/onboarding/company1" : onboardingCheckoutUrl;
    }
    return redirectUrl;
  };

  // old Order-based system
  const hasBookkeepingFromCustomerServicesRequired = customerServicesRequired
    && customerServicesRequired.length > 0
    && customerServicesRequired[0].servicesRequired.some(
      (service: string) => service.includes("Bookkeeping"),
    );
  const hasTaxPassFromCustomerServicesRequired = customerServicesRequired
    && customerServicesRequired.length > 0
    && customerServicesRequired[0].servicesRequired.some(
      (service: string) => service.includes("Tax Pass"),
    );
  const hasTaxCreditsFromCustomerServicesRequired = customerServicesRequired
    && customerServicesRequired.length > 0
    && customerServicesRequired[0].servicesRequired.some(
      (service: string) => service.includes("Tax Credits"),
    );

  const hasAllServicesFromCustomerServicesRequired = hasBookkeepingFromCustomerServicesRequired
    && hasTaxPassFromCustomerServicesRequired
    && hasTaxCreditsFromCustomerServicesRequired;

  // new ProductOrder/AccountingService-based system
  const hasBookkeepingFromPurchasedAccountingServices = !!purchasedAccountingServices?.some(
    (accService) => !accService.isTaxService
      && accService.name.toLowerCase().includes("bookkeeping"),
  );
  const hasTaxPassFromPurchasedAccountingServices = !!purchasedAccountingServices?.some(
    (accService) => accService.isTaxService
      && !accService.name.toLowerCase().includes("tax credits"),
  );
  const hasTaxCreditsFromPurchasedAccountingServices = !!purchasedAccountingServices?.some(
    (accService) => accService.isTaxService
      && accService.name.toLowerCase().includes("tax credits"),
  );

  const hasAllServicesFromPurchasedAccountingServices = hasBookkeepingFromPurchasedAccountingServices
    && hasTaxPassFromPurchasedAccountingServices
    && hasTaxCreditsFromPurchasedAccountingServices;

  if (hasAllServicesFromCustomerServicesRequired || hasAllServicesFromPurchasedAccountingServices) {
    return <></>;
  }

  return (
    <Card>
      <Card.Header>
        <h4 className="card-header-title">
          <img
            src={fondoLogo}
            width="20px"
            className="text-secondary me-2"
          />
          Additional Fondo Services
        </h4>
      </Card.Header>
      <Card.Body className="px-3">
        {customerServicesRequiredIsLoading || purchasedAccountingServicesIsLoading ? (
          <div className="row justify-content-center mt-5">
            <Spinner variant="primary" animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <CardGroup>
            {!(hasBookkeepingFromCustomerServicesRequired || hasBookkeepingFromPurchasedAccountingServices) && (
              <SalesCard
                serviceName="Bookkeeping"
                serviceDescription={`Receive accurate financial reporting
                monthly, quarterly, or annually.`}
                illustration={bookkeepingServiceIllustration}
                modalHeaderTitle="Bookkeeping"
                modalHeaderDescription={
                  <span>
                    Fondo can help you with your bookkeeping - we offer <br />
                    monthly, quarterly, or annual options.
                  </span>}
                modalServiceDetailTitle="Bookkeeping"
                modalServiceDetailDescription={
                  <p>
                    We understand that keeping your books up to date can be a tedious task.
                    However, by prioritizing this essential aspect, you
                    <span className="fw-bold" style={darkBlueText}> gain clear visibility
                      into your business and set yourself up for success,
                      especially when it comes to the upcoming tax season.
                    </span>
                  </p>
                }
                modalRedirectUrl={getModalRedirectUrl()}
              />
            )}
            {!(hasTaxPassFromCustomerServicesRequired || hasTaxPassFromPurchasedAccountingServices) && (
              <SalesCard
                serviceName="TaxPass"
                serviceDescription={`Get your Delaware Franchise Tax, 
                Federal & State Corporate Income Taxes filed.`}
                illustration={taxPassServiceIllustration}
                modalHeaderTitle="TaxPass"
                modalHeaderDescription={
                  <span>
                    TaxPass is an annual subscription that covers your filings
                    for the Delaware Franchise Tax, State Corporate Income Tax,
                    and Federal Corporate Income Tax deadlines.
                  </span>
                }
                modalServiceDetailTitle="TaxPass"
                modalServiceDetailDescription={
                  <p>
                    Simplify the tax filing process with Fondo TaxPass.
                    Our seamless app allows you to conveniently handle your tax filings,
                    including DE Franchise taxes, all in one place.
                    <span className="fw-bold" style={darkBlueText}>
                      Say goodbye to endless email chains and experience a
                      hassle-free tax preparation journey.
                    </span>
                  </p>
                }
                modalRedirectUrl={getModalRedirectUrl()}
              />
            )}
            {!(hasTaxCreditsFromCustomerServicesRequired || hasTaxCreditsFromPurchasedAccountingServices) && (
              <SalesCard
                serviceName="Tax Credits"
                serviceDescription={`Get up to $500,000 in Tax Credits 
                (R&D Tax Credit, ERTC, and more) for your startup.`}
                illustration={taxCreditsServiceIllustration}
                modalHeaderTitle="Automatically get cash back from the IRS"
                modalHeaderDescription={
                  <span>
                    Fondo is the easy, automated way for your startup to get cash back from the IRS.
                    We’ll optimize and file your taxes, and you’ll get cash
                    back — the average startup gets $25,000 per year.
                  </span>
                }
                modalServiceDetailTitle="Tax Credits"
                modalServiceDetailDescription={
                  <p>
                    Did you know that Fondo Tax Credits can put money back in your pocket?
                    Our dedicated team works diligently to identify and secure
                    government-issued tax credits for your business. <span className="fw-bold" style={darkBlueText}>
                      On average, our startup clients receive over $25,000 from the US government,
                      making it an incredible way to offset your bookkeeping and tax expenses.
                    </span>
                  </p>
                }
                modalRedirectUrl={getModalRedirectUrl()}
              />
            )}
          </CardGroup>
        )}
      </Card.Body>
    </Card>
  );
};

export default AdditionalFondoServicesCard;
