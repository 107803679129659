import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import FeatherIcon from "feather-icons-react";
import QboAuthorize from "../banking-integration/QuickbookonlineAuthorize";
import { getSourceLinkedAccounts } from "./integrations.api";
import { LinkedAccount } from "./integrations.type";
import DeleteTrashButton from "../fusion-kit/DeleteTrashButton";
import { API_INTEGRATIONS_SOURCES_LINKED_ACCOUNTS } from "../../constants/network-calls";

const componentMapping: { [key: string]: React.ComponentType } = {
  QBO: QboAuthorize,
};


const ThirdPartyIntegrationCard = ({ source }: { source: string }) => {
  const Component = componentMapping[source];
  const [showComponent, setShowComponent] = useState(false);
  const handleClick = () => {
    setShowComponent(!showComponent);
  };

  const linkedAccountsQueryData = useQuery<any, Error>(
    ["linkedAccountsQueryData"],
    () => getSourceLinkedAccounts(source),
    { refetchOnWindowFocus: false },
  );

  const hasLinkedAccountsForTheCategory = linkedAccountsQueryData?.data?.length > 0;

  return (
       <div className="card justify" style={{ position: "static" }}>
      <div className="card-body">
        <h1 className="text-uppercase text-center text-muted my-4">
          <i className="fe fe-lock"></i>
        </h1>
        <div className="row no-gutters align-items-center justify-content-center">
          <div className="col-auto">
            <div className="h1 mb-0">QBO</div>
          </div>
        </div>
        <div className="mb-3">
          <ul className="list-group list-group-flush">
            {
              <li className="list-group-item d-flex align-items-center justify-content-between">
                <span>{hasLinkedAccountsForTheCategory ? "Connected" : "Not Connected"}</span>
                <FeatherIcon
                  icon={hasLinkedAccountsForTheCategory ? "check-circle" : "x"}
                  className={hasLinkedAccountsForTheCategory ? "text-success" : "text-danger"}
                  size="1.1em"
                />
              </li>
            }
          </ul>
        </div>
        {hasLinkedAccountsForTheCategory &&
          linkedAccountsQueryData.data.map((linkedAccount: LinkedAccount, idx: number) => (
            <div key={`linked-account${linkedAccount.integration}-${idx}`}>
              <div className="font-weight-bold mb-2 d-flex justify-content-between" style={{ fontWeight: "bold" }}>
                <span className="font-weight-bold mb-1" style={{ fontWeight: "bold" }}>
                  {linkedAccount.integration}
                </span>
                <DeleteTrashButton
                  id={linkedAccount.uuid}
                  deleteURL={API_INTEGRATIONS_SOURCES_LINKED_ACCOUNTS}
                  queryKey={["linkedAccountsQueryData"]}
                  askConfirmation={true}
                />
              </div>
            </div>
          ))}
        <button
          className={"btn btn-block btn-primary full-width"}
          onClick={handleClick}
          disabled={!!hasLinkedAccountsForTheCategory}
        >
          Link account
        </button>
        {showComponent && <Component />}
      </div>
    </div>
  );
};

export default ThirdPartyIntegrationCard;
