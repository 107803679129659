import { useState } from "react";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Alert,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import Flatpickr from "../flatpickr/Flatpickr";
import { markBankAccountAsClosed } from "./integrations.api";
import FondoToast from "../fusion-kit/FondoToast";

type CloseAccountModalProps = {
  accountUuid: string;
  show: boolean;
  handleClose: () => void;
}

const CloseAccountModal = (
  {
    accountUuid,
    show,
    handleClose,
  }: CloseAccountModalProps,
) => {
  const queryClient = useQueryClient();
  const [accountClosedOn, setAccountClosedOn] = useState<string>("");
  const [showErrorToast, setShowErrorToast] = useState(false);

  const handleDateChange = (dates: Date[]) => {
    if (dates.length > 0) {
      let newDate = dates.map((selectedDate) => selectedDate.toISOString())[0];
      [newDate] = newDate.split("T");
      setAccountClosedOn(newDate);
    } else {
      setAccountClosedOn("");
    }
  };

  const closeAccountMutation = useMutation<any, AxiosError<any>>(
    () => markBankAccountAsClosed(accountUuid, accountClosedOn),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["itemAccounts"]);
        handleClose();
      },
      onError: () => {
        handleClose();
        setShowErrorToast(true);
      },
    },
  );

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Account Closure Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="light">
            Please enter the date when the account was officially closed.
            By marking the account as closed, all data synchronization will cease, but
            historical data associated with the account will remain accessible within
            the application for monitoring and analysis purposes.
          </Alert>
          <Form>
            <Form.Group className="mb-3" controlId="closedAt">
              <Form.Label>Account closed on:</Form.Label>
              <Flatpickr
                value={accountClosedOn}
                onChange={(dates: any) => handleDateChange(dates)}
                placeholder="MM/DD/YYYY"
                options={{
                  mode: "single",
                  altInput: true,
                  static: true,
                  allowInput: true,
                  dateFormat: "Y-m-d",
                  altFormat: "m/d/Y",
                  maxDate: new Date(),
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => closeAccountMutation.mutate()}
            disabled={!accountClosedOn}
          >
            Mark as closed
          </Button>
        </Modal.Footer>
      </Modal>
      <FondoToast
        show={showErrorToast}
        handleClose={() => setShowErrorToast(false)}
        message={"Error marking account as closed"}
        error={true}
      />
    </>
  );
};

export default CloseAccountModal;
