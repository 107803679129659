import {
  Form,
  InputGroup,
} from "react-bootstrap";
import { ErrorMessage, FormikContextType } from "formik";
import {
  delawareFranchiseTaxFieldToTypeMap,
  getDelawareFranchiseTaxFieldToLabelMap,
} from "../DelawareFranchiseTax.type";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import CurrencyField from "../../forms/formik-components/CurrencyField";
import MaskedAmountField from "../../forms/formik-components/MaskedAmountField";

type DelawareFranchiseTaxInfoSectionProps = {
  formikContext: FormikContextType<any>;
  taxYear: string|number;
}

const DelawareFranchiseTaxInfoSection = ({ formikContext, taxYear }: DelawareFranchiseTaxInfoSectionProps) => (
  <>
    <FondoFormGroup
      formikContext={formikContext}
      fieldName="dftInfo.fondoHasAccessToAccountingSystem"
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
    />
    <FondoFormGroup
      formikContext={formikContext}
      fieldName="dftInfo.fondoHasAccessToCapTable"
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
    />
    <FondoFormGroup
      formikContext={formikContext}
      fieldName="dftInfo.delawareFileNumber"
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
      maxLength={7}
      helperText={{
        type: "whereToFindThis",
        body:
        <>
          Your Delaware File Number can be found{" "}
          <a
            href="https://icis.corp.delaware.gov/ecorp/EntitySearch/NameSearch.aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            here.
          </a>
        </>,
      }}
    />
    {formikContext.values.dftInfo.fondoHasAccessToAccountingSystem === "False" && (

      <div className="mb-4">
          <Form.Label>Gross Assets (rounded to nearest dollar) as of December 31, {taxYear}</Form.Label>
          <InputGroup>
            <CurrencyField
              value={formikContext.values.dftInfo.grossAssets}
              onChange={(e: any) => {
                formikContext.setFieldValue("dftInfo.grossAssets", e.target.value.replaceAll(",", "") || 0);
              }}
              onBlur={formikContext.handleBlur}
              name="dftInfo.grossAssets"
            />
          </InputGroup>
          <ErrorMessage component="div" className="text-danger" name="dftInfo.grossAssets" />
        </div>
    )}

    {formikContext.values.dftInfo.fondoHasAccessToCapTable === "False" && (
      <>
        <div className="mb-4">
          <Form.Label className="m-0">Authorized Common Shares as of December 31, {taxYear}</Form.Label>
          <p className="text-muted small">
          You can find this stock information on your cap table or request it from your lawyer.
          It is common for startups to have 10M Authorized Common Shares and 8M Issued Common Shares
          (if you have an option pool, that does not count towards Issued Common Shares).
          Startups generally don’t have any Authorized Preferred Shares or
          Issued Preferred Shares until Series A or beyond.
          </p>
          <MaskedAmountField
            value={formikContext.values.dftInfo.commonSharesApproved}
            onChange={(e: any) => {
              formikContext.setFieldValue(
                "dftInfo.commonSharesApproved",
                e.target.value.replaceAll(",", "") || 0,
              );
            }}
            onBlur={formikContext.handleBlur}
            name="dftInfo.commonSharesApproved"
          />
          <ErrorMessage component="div" className="text-danger" name="dftInfo.commonSharesApproved" />
        </div>

        <div className="mb-4">
          <Form.Label className="m-0">Authorized Preferred Shares as of December 31, {taxYear}</Form.Label>
          <MaskedAmountField
            value={formikContext.values.dftInfo.preferredSharesApproved}
            onChange={(e: any) => {
              formikContext.setFieldValue(
                "dftInfo.preferredSharesApproved",
                e.target.value.replaceAll(",", "") || 0,
              );
            }}
            onBlur={formikContext.handleBlur}
            name="dftInfo.preferredSharesApproved"
          />
          <ErrorMessage component="div" className="text-danger" name="dftInfo.preferredSharesApproved" />
        </div>

        <div className="mb-4">
          <Form.Label className="m-0">Issued Common Shares as of December 31, {taxYear}</Form.Label>
          <MaskedAmountField
            value={formikContext.values.dftInfo.commonSharesIssued}
            onChange={(e: any) => {
              formikContext.setFieldValue(
                "dftInfo.commonSharesIssued",
                e.target.value.replaceAll(",", "") || 0,
              );
            }}
            onBlur={formikContext.handleBlur}
            name="dftInfo.commonSharesIssued"
          />
          <ErrorMessage component="div" className="text-danger" name="dftInfo.commonSharesIssued" />
        </div>
        <div className="mb-4">
          <Form.Label className="m-0">Issued Preferred Shares as of December 31, {taxYear}</Form.Label>
          <MaskedAmountField
            value={formikContext.values.dftInfo.preferredSharesIssued}
            onChange={(e: any) => {
              formikContext.setFieldValue(
                "dftInfo.preferredSharesIssued",
                e.target.value.replaceAll(",", "") || 0,
              );
            }}
            onBlur={formikContext.handleBlur}
            name="dftInfo.preferredSharesIssued"
          />
          <ErrorMessage component="div" className="text-danger" name="dftInfo.preferredSharesIssued" />
        </div>
      </>
    )}
  </>
);

export default DelawareFranchiseTaxInfoSection;
