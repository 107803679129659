import { ErrorMessage, Field, FormikContextType } from "formik";
import { Form } from "react-bootstrap";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import {
  delawareFranchiseTaxFieldToTypeMap,
  getDelawareFranchiseTaxFieldToLabelMap,
} from "../DelawareFranchiseTax.type";

type PaymentInfoSectionProps = {
  formikContext: FormikContextType<any>;
  taxYear: string|number;
}

const PaymentInfoSection = ({ formikContext, taxYear }: PaymentInfoSectionProps) => (
  <>
   <div className="mt-5">
      <h2 className="m-0">Payment Information</h2>
    </div>
    <hr />
    <FondoFormGroup
      formikContext={formikContext}
      fieldName="paymentInfo.bankName"
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
    />
    <div className="mb-4">
      <Form.Label>Account Type</Form.Label>
      <Field
        name="paymentInfo.accountType"
        as="select"
        className={"form-control form-select"}
        onChange={(e: any) => { formikContext.setFieldValue("paymentInfo.accountType", e.target.value); }}
      >
        <option key="emptyValue" value={""}>--------</option>
        <option value="business_checking">Business Checking</option>
        <option value="savings">Savings</option>
      </Field>
      <ErrorMessage component="div" className="text-danger" name="paymentInfo.accountType" />
    </div>

    <FondoFormGroup
      formikContext={formikContext}
      fieldName="paymentInfo.routingNumberEnc"
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
      enablePasswordType
    />
    <FondoFormGroup
      formikContext={formikContext}
      fieldName="paymentInfo.accountNumberEnc"
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
      enablePasswordType
    />
  </>
);

export default PaymentInfoSection;
