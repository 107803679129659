import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  Badge,
  Button,
  Form,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { convertNumberToCurrency } from "../../../utils/utils";
import { updateTransactionAmount } from "../FinanceDashboard.api";
import {
  mapTransactionAIVerificationStatusToColor,
  mapTransactionAIVerificationStatusToLabel,
  Transaction,
  TransactionCategory,
} from "../financeDashboard.type";
import { formatDateToWords } from "../../../utils/common.util";
import AlertModal from "../../fusion-kit/AlertModal";
import CategoryDropdown from "./CategoryDropdown";

type TransactionRowProps = {
  transaction: Transaction;
  transactionsToCategorize: string[];
  setTransactionUuids: (uuids: string[]) => void;
  allowUseOfAIToCategorizeTransactions: boolean;
}

const TransactionRow = ({
  transaction,
  transactionsToCategorize,
  setTransactionUuids,
  allowUseOfAIToCategorizeTransactions,
}: TransactionRowProps) => {
  const queryClient = useQueryClient();
  const [enableEdit, setEnableEdit] = useState(false);
  const [amount, setAmount] = useState<number|string>(transaction.displayAmount);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const updateTransactionAmountMutation = useMutation<any, AxiosError<any>>(
    () => updateTransactionAmount(transaction.uuid, Number(amount)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["transactionsList"],
        });
        queryClient.invalidateQueries({
          queryKey: ["transactionsStats"],
        });
      },
      onError: () => {
        setShowAlertModal(true);
      },
    },
  );

  const handleUpdateAmount = () => {
    setEnableEdit(false);
    updateTransactionAmountMutation.mutate();
  };

  return (
    <>
      <tr key={transaction.uuid}>
        <td className="pe-0 text-center">
        <Form.Check
          id={`${transaction.uuid}-check`}
          type="checkbox"
          checked={transactionsToCategorize.some((uuid: string) => uuid === transaction.uuid)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
              setTransactionUuids([...transactionsToCategorize, transaction.uuid]);
            } else {
              setTransactionUuids(
                transactionsToCategorize.filter(
                  (uuid: string) => uuid !== transaction.uuid,
                ),
              );
            }
          }}
        />
        </td>
        <td>{formatDateToWords(transaction.date)}</td>
        <td className="fw-bold">
          <div className="d-flex align-items-center">
            {enableEdit ? (
              <>
                <Form.Control
                  onChange={(e) => setAmount(e.target.value)}
                  size="sm"
                  value={amount}
                  htmlSize={1}
                  type="number"
                  style={{ width: "auto" }}
                />
                <Button
                  onClick={() => handleUpdateAmount()}
                  size="sm"
                  className="ms-2"
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <span>
                  ${convertNumberToCurrency(transaction.displayAmount)}
                </span>
                <FeatherIcon
                  onClick={() => setEnableEdit(true)}
                  icon={"edit-2"}
                  size="1em"
                  className="ms-2 mt-n1"
                  style={ { cursor: "pointer" } }
                />
              </>
            )}
          </div>
        </td>
        <td>{transaction.source}</td>
        <td className="text-secondary">{transaction.description}</td>
        <td>
          <CategoryDropdown
            transactionUuid={transaction.uuid}
            category={transaction.manuallyEnteredCategory as TransactionCategory}
          />
        </td>
        {allowUseOfAIToCategorizeTransactions && (
          <td>
            <Badge
              bg={
                mapTransactionAIVerificationStatusToColor[transaction.aiVerificationStatus]
              }
              className="p-2"
            >
              {
                mapTransactionAIVerificationStatusToLabel[transaction.aiVerificationStatus]
              }
            </Badge>
          </td>
        )}
      </tr>
      <AlertModal
        show={showAlertModal}
        title={"Error"}
        handleClose={() => setShowAlertModal(false)}
        error={true}
      />
    </>
  );
};

export default TransactionRow;
