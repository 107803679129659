import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Table } from "react-bootstrap";
import {
  BalanceAccount,
  BalanceAccountChartData,
  BalancesPayloadToSubmit,
  CashBalanceChartData,
  EndingBalanceUpdated,
  NewBankAccount,
} from "../finance-dashboard/financeDashboard.type";
import RunwayAuditToolTableTotalsRow from "./RunwayAuditToolTableTotalsRow";
import { updateEndingBalancesAudit } from "../finance-dashboard/FinanceDashboard.api";
import FondoToast from "../fusion-kit/FondoToast";
import RunwayAuditToolTableRow from "./RunwayAuditToolTableRow";
import { InstitutionSource } from "../integrations/integrations.type";
import RunwayAuditToolManuallyAddFormModal from "./RunwayAuditToolManuallyAddFormModal";

type RunwayAuditToolProps = {
  accountsData: CashBalanceChartData;
  month: string;
}

const RunwayAuditToolTable = (
  {
    accountsData,
    month,
  }: RunwayAuditToolProps,
) => {
  const queryClient = useQueryClient();
  const [balancesToRender, setBalancesToRender] = useState<BalanceAccountChartData[]>(accountsData.balances);
  const [balancesPayloadToSubmit, setBalancesPayloadToSubmit] = useState<BalancesPayloadToSubmit>({
    endingBalancesUpdated: [],
    bankAccountsToAdd: [],
  });
  const [showAddBankAccountModal, setShowAddBankAccountModal] = useState(false);

  useEffect(() => {
    if (accountsData.balances) {
      setBalancesToRender(accountsData.balances.length > 0 ? accountsData.balances : []);
    }
  }, [accountsData.balances]);

  const [showSavedAndUpdatedToast, setShowSavedAndUpdatedToast] = useState(false);
  const [errorSubmitingChanges, setErrorSubmitingChanges] = useState(false);
  const saveAndUpdateMutation = useMutation<any, AxiosError<any>, any>(
    (data: BalancesPayloadToSubmit) => updateEndingBalancesAudit(data),
  );

  const saveAndUpdateEndingBalancesAudit = () => {
    saveAndUpdateMutation.mutate(
      balancesPayloadToSubmit,
      {
        onSuccess: () => {
          setErrorSubmitingChanges(false);
          setShowSavedAndUpdatedToast(true);
          queryClient.invalidateQueries({
            queryKey: ["monthlyBalance"],
          });
          queryClient.invalidateQueries({
            queryKey: ["historicalMonthlyBalances"],
          });
          queryClient.invalidateQueries({
            queryKey: ["burnRate"],
          });
          setBalancesPayloadToSubmit({
            endingBalancesUpdated: [],
            bankAccountsToAdd: [],
          });
        },
        onError: () => {
          setErrorSubmitingChanges(true);
          setShowSavedAndUpdatedToast(true);
          setBalancesPayloadToSubmit({
            endingBalancesUpdated: [],
            bankAccountsToAdd: [],
          });
        },
      },
    );
  };

  const balanceRowUpdate = (updatedRowData: EndingBalanceUpdated) => {
    const updatedBalancesToRender = balancesToRender.map(
      (balance) => ((balance.uuid === updatedRowData.uuid)
        ? {
          ...balance,
          balance: updatedRowData.manuallyEditedBalance,
        } : balance
      ),
    );
    setBalancesToRender(updatedBalancesToRender);

    // Find the balance value in the original request, to determine
    // if it is a record to update or not, if the value manually entered is the same as
    // the one it has originally in the request, then there is nothing to update.
    const balanceToUpdate = accountsData.balances.find((originalBalance: BalanceAccount) => (
      originalBalance.uuid === updatedRowData.uuid
      && originalBalance.balance !== updatedRowData.manuallyEditedBalance
    ));

    let endingBalancesToUpdate = balancesPayloadToSubmit.endingBalancesUpdated;
    if (balanceToUpdate) {
      endingBalancesToUpdate.push(updatedRowData);
    } else {
      endingBalancesToUpdate = endingBalancesToUpdate.filter(
        (endingBalanceToUpdate: EndingBalanceUpdated) => (
          endingBalanceToUpdate.uuid !== updatedRowData.uuid
        ),
      );
    }
    setBalancesPayloadToSubmit(
      {
        ...balancesPayloadToSubmit,
        endingBalancesUpdated: endingBalancesToUpdate,
      },
    );
  };

  const newAccountRowUpdate = (updatedRowData: EndingBalanceUpdated) => {
    const newUpdatedBalancesToRender = balancesToRender.map(
      (balance) => (
        (
          balance.account.bankName === updatedRowData.institutionName
          && balance.account.name === updatedRowData.accountName
        ) ? {
            ...balance,
            balance: updatedRowData.manuallyEditedBalance,
          } : balance
      ),
    );
    setBalancesToRender(newUpdatedBalancesToRender);

    const updatedNewAccounts: NewBankAccount[] = balancesPayloadToSubmit.bankAccountsToAdd.map(
      (account: NewBankAccount) => (
        (
          updatedRowData.accountName === account.accountName
          && updatedRowData.institutionName === account.institutionName
        ) ? {
            ...account,
            selectedMonthEndingBalance: updatedRowData.manuallyEditedBalance,
          } : account
      ),
    );

    setBalancesPayloadToSubmit(
      {
        ...balancesPayloadToSubmit,
        bankAccountsToAdd: updatedNewAccounts,
      },
    );
  };

  const handleRowUpdate = (updatedRowData: EndingBalanceUpdated) => {
    if (updatedRowData.uuid) balanceRowUpdate(updatedRowData);
    else newAccountRowUpdate(updatedRowData);
  };

  const totalBalances = balancesToRender.reduce((accumulator: number, balance: BalanceAccount) => (
    accumulator + parseFloat(balance.balance)
  ), 0) || 0;

  const enableSubmitButton = (
    !!balancesPayloadToSubmit.endingBalancesUpdated.length
    || !!balancesPayloadToSubmit.bankAccountsToAdd.length
  );

  const handleAddBankAccount = (newBankAccount: NewBankAccount) => {
    setBalancesToRender([...balancesToRender, {
      account: {
        name: newBankAccount.accountName,
        bankName: newBankAccount.institutionName,
        validItem: true,
        source: InstitutionSource.MANUAL,
      },
      balance: newBankAccount.selectedMonthEndingBalance,
      others: true,
      referenceColor: "#999999",
      date: month,
    }]);
    setBalancesPayloadToSubmit((currentPayloadToSubmit) => ({
      ...currentPayloadToSubmit,
      bankAccountsToAdd: [
        ...currentPayloadToSubmit.bankAccountsToAdd, newBankAccount,
      ],
    }));
  };

  const handleRemoveNewBankAccount = (bankAccountToRemove: BalanceAccount) => {
    const filteredBalances = balancesToRender.filter(
      (balance) => (
        balance.account.name !== bankAccountToRemove.account.name
        && balance.account.bankName !== bankAccountToRemove.account.bankName
      ),
    );
    setBalancesToRender(filteredBalances);
    const updatedNewAccounts: NewBankAccount[] = balancesPayloadToSubmit.bankAccountsToAdd.filter(
      (account: NewBankAccount) => (
        (
          bankAccountToRemove.account.name !== account.accountName
          && bankAccountToRemove.account.bankName !== account.institutionName
        )
      ),
    );
    setBalancesPayloadToSubmit((currentPayloadToSubmit) => ({
      ...currentPayloadToSubmit,
      bankAccountsToAdd: updatedNewAccounts,
    }));
  };

  return (
    <>
      <Table className='table mb-0' size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Bank Name</th>
            <th>Account</th>
            <th>Date</th>
            <th className="text-end">Balance</th>
          </tr>
        </thead>
        <tbody>
          {balancesToRender.map((balance: BalanceAccountChartData) => (
            <RunwayAuditToolTableRow
              balance={balance}
              handleRowUpdate={handleRowUpdate}
              handleRemoveNewBankAccount={handleRemoveNewBankAccount}
            />
          ))}
          <RunwayAuditToolTableTotalsRow
            handleAddBankAccount={() => setShowAddBankAccountModal(true)}
            totalBalances={totalBalances}
            handleSaveAndUpdate={() => saveAndUpdateEndingBalancesAudit()}
            disableSubmitButton={!enableSubmitButton}
          />
        </tbody>
      </Table>
      <RunwayAuditToolManuallyAddFormModal
        show={showAddBankAccountModal}
        handleClose={() => setShowAddBankAccountModal(false)}
        selectedMonth={month}
        handleAddBankAccount={handleAddBankAccount}
      />
      <FondoToast
        show={showSavedAndUpdatedToast}
        handleClose={() => setShowSavedAndUpdatedToast(false)}
        message={errorSubmitingChanges ? "" : "Successfully updated monthly balances!"}
        error={errorSubmitingChanges}
      />
    </>
  );
};

export default RunwayAuditToolTable;
