import { Button, Form, Modal, Spinner } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useMutation } from "@tanstack/react-query";
import { GroupsToSendType, OpenedReport, RecipientGroupRouter } from "../Reporting.type";
import { sendReportToGroups } from "../Reporting.api";

type RecipientGroupShareType = {
  report: OpenedReport;
  optionalMessage: string;
  groupsToSendName: string;
  selectedGroups: { [key: number]: string };
  emailSubject: string;
  setEmailSubject: React.Dispatch<React.SetStateAction<string>>;
  setOptionalMessage: React.Dispatch<React.SetStateAction<string>>;
  setShowFondoToast: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentRoute: React.Dispatch<React.SetStateAction<RecipientGroupRouter>>;
  handleClose: () => void;
}

const RecipientGroupShare = ({
  report,
  optionalMessage,
  groupsToSendName,
  selectedGroups,
  emailSubject,
  setEmailSubject,
  setOptionalMessage,
  setShowFondoToast,
  setCurrentRoute,
  handleClose,
}: RecipientGroupShareType) => {
  const sendReportMutation = useMutation(
    sendReportToGroups,
    {
      onSuccess: () => setCurrentRoute(RecipientGroupRouter.SENT_GROUP),
      onError: () => setShowFondoToast(true),
    }
  );

  const sendReportsToRecipientGroups = () => {
    const groupIds = Object.keys(selectedGroups).map((id) => parseInt(id, 10));
    const reportingInfo: GroupsToSendType = {
      reportingUuid: report.uuid,
      reportInfo: {
        subject: emailSubject,
        message: optionalMessage,
        recipientGroupIds: groupIds,
      }
    }
    sendReportMutation.mutate(reportingInfo);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title as="h3" className="mb-0">
          Share "{report.name}"
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Text className="text-secondary">Please add teams to send it to.</Form.Text>
            <Form.Control
              placeholder="Select Group(s)"
              value={groupsToSendName}
              style={{ cursor: "pointer" }}
              onClick={() => setCurrentRoute(RecipientGroupRouter.SELECT_GROUPS)}
              className="mb-4"
              readOnly />
          </Form.Group>
          <Form.Group>
            <Form.Text className="text-secondary">Message contents</Form.Text>
            <Form.Control
              placeholder="Subject"
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.target.value)}
              className="mb-3"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={7}
              placeholder="Body"
              value={optionalMessage}
              onChange={(e) => setOptionalMessage(e.target.value)}
              className="mb-3" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" onClick={handleClose}>
          Cancel
        </Button>
        <div>
          <Button
            disabled={
              Object.keys(selectedGroups).length === 0
              || emailSubject.trim() === "" || sendReportMutation.isLoading
            }
            onClick={sendReportsToRecipientGroups}
          >
            {sendReportMutation.isLoading ?
              <Spinner animation="border" size="sm" /> :
              <>
                <FeatherIcon className="me-3" icon="send" size="17" style={{ cursor: "pointer" }} />
                <span>
                  Send
                </span>
              </>
            }
          </Button>
        </div>
      </Modal.Footer>
    </>
  )
};

export default RecipientGroupShare;
