import { Step } from "../fusion-kit/ProgressTracker";
import { TaxStatusType } from "./taxStatus.type";

/**
 * Converts a TaxStatusType object to a Step object
 * @param statusSteps The phaseTemplates must have uuids and parentUuids linking each other
 * @returns This will be in the format of a Step object, which looks like: {name, complete}
 */
export const organizeTaxStatusSteps = (statusSteps: TaxStatusType): Step[] => {
  const templates = statusSteps.phaseTemplates;
  const orderedSteps = statusSteps.phaseTemplates.filter((phaseTemplate) => phaseTemplate.parentPhaseTemplate === null);
  let currentStep = orderedSteps[0];

  // Order the steps based on uuids and parent uuids
  for (let count = 0; count < templates.length - 1; count += 1) {
    for (let index = 0; index < templates.length; index += 1) {
      if (templates[index].parentPhaseTemplate === currentStep.uuid) {
        currentStep = templates[index];
        break;
      }
    }
    orderedSteps.push(currentStep);
  }
  // Remove any steps that don't have a customer status name (we don't want to show them)
  const displayedSteps = orderedSteps.filter((phaseTemplate) => !!phaseTemplate.customerStatusName);
  // Create the `Step` data type and return it
  const completedSteps = displayedSteps.map((phaseTemplate) => {
    const name = phaseTemplate.customerStatusName;
    const complete = statusSteps.phases.some(
      (phase) => phase.status === "COMPLETED" && phase.customerStatusName === phaseTemplate.customerStatusName,
    );
    return { name, complete };
  });
  return completedSteps as Step[];
};

export const isProjectComplete = (taxStatus: TaxStatusType): boolean => {
  const steps = organizeTaxStatusSteps(taxStatus);
  return steps.every((step) => step.complete);
};
