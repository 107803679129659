import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Spinner from "react-bootstrap/Spinner";
import CircleReferenceColor from "../../fondo-components/CircleReferenceColor";
import ConfirmationModal from "../../fusion-kit/ConfirmationModal";
import { deleteReportQuery } from "../Reporting.api";
import { ReportingType, OpenedReport } from "../Reporting.type";
import { downloadReport } from "../utils";

type ReportingTableRowProps = {
  report: ReportingType,
  fondoVerified: boolean,
  createDate: string,
  timeframe: string,
  setShowRecipientGroupModal: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedReport: React.Dispatch<React.SetStateAction<OpenedReport>>,
  setDownloadLoading: React.Dispatch<React.SetStateAction<boolean>>,
  downloadLoading: boolean
}

const ReportingTableRow = ({
  fondoVerified,
  createDate, 
  timeframe, 
  setShowRecipientGroupModal, 
  setSelectedReport, 
  report,
  setDownloadLoading,
  downloadLoading
}: ReportingTableRowProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const queryClient = useQueryClient();

  const closeConfirmationModal = () => setShowConfirmationModal(false)
  const deleteReportMutation = useMutation(
    deleteReportQuery,
    {
      onSuccess: () => queryClient.invalidateQueries({queryKey: ["reportings"]}),
    }
  );
  const deleteReport = () => {
    deleteReportMutation.mutate(report.uuid);
    closeConfirmationModal();
  }


  const openRecipientGroupModal = () => {
    setSelectedReport({uuid: report.uuid, name: report.name});
    setShowRecipientGroupModal(true);
  }

  const downloadFile = async () =>{
    setDownloadLoading(true)
    await downloadReport(report)
    setDownloadLoading(false)
  }

  return (
    <tr>
      <td className="fs-4">
        <Link to={`/dashboard/reporting/${report.uuid}`} style={{ textDecoration: "none", color: "inherit" }}>
          {report.name}
        </Link>
      </td>
      <td>
        <CircleReferenceColor
          text={`${fondoVerified ? "Fondo Verified" : "User Generated"}`}
          color={`${fondoVerified ? "#00D97E" : "#007BFF"}`}
          size={6}
        />
      </td>
      <td className="text-muted">{createDate}</td>
      <td className="text-muted">{timeframe}</td>
      <td className="text-muted">
        <Link to={`/dashboard/reporting/${report.uuid}`} style={{ textDecoration: "none", color: "inherit" }}>
          <FeatherIcon icon="edit" size="17" className="me-2" style={{ cursor: "pointer" }} />
        </Link>
        <FeatherIcon
          icon="trash-2"
          size="17"
          className="me-2"
          style={{ cursor: "pointer" }}
          onClick={() => setShowConfirmationModal(true)}
        />
        {downloadLoading ? (
          <Spinner size="sm"/>
        ) : (
          <FeatherIcon
            icon="download"
            size="17"
            className="me-2"
            style={{ cursor: "pointer" }}
            onClick={downloadFile}
          />
        )}
        <FeatherIcon icon="send" size="17" style={{ cursor: "pointer" }} onClick={openRecipientGroupModal} />
      </td>
      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={closeConfirmationModal}
        titleText="Delete Report"
        bodyText={`Are you sure you want to delete the report "${report.name}"?`}
        handleConfirm={deleteReport}
      />
    </tr>
  )
};

export default ReportingTableRow;
