import {
  Button, ListGroup, Modal,
} from "react-bootstrap";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CreateNewReportRow from "./CreateNewReportRow";
import { editReportingQuery } from "../Reporting.api";
import { ReportingType } from "../Reporting.type";
import reportingExecutiveSummary from "../../../assets/img/icons/reportingExecutiveSummary.svg";
import reportingRunway from "../../../assets/img/icons/reportingRunway.svg";
import reportingProfitAndLoss from "../../../assets/img/icons/reportingProfitAndLoss.svg";
import reportingBalanceSheet from "../../../assets/img/icons/reportingBalanceSheet.svg";

type UpdateReportModalProps = {
  show: boolean;
  handleClose: () => void;
  report: ReportingType;
}

const UpdateReportModal = ({
  show,
  handleClose,
  report,
}: UpdateReportModalProps) => {
  // I wanted to use the same modal for creating and updating a report, but the TimeRange prop made it too complex.
  // So I decided to create a new modal for updating an existing report.
  const [selectedReport, setSelectedReport] = useState({
    executiveSummary: report.executiveSummary,
    runway: report.runway,
    profitAndLoss: report.profitAndLoss,
    balanceSheet: report.balanceSheet,
  });
  const queryClient = useQueryClient();

  const updateReportSectionsMutation = useMutation(
    editReportingQuery,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ["reportings", report.uuid]});
    },
    },
  );

  const updateCurrentReport = () => {
    const submissionData: Partial<ReportingType> = {
      uuid: report.uuid,
      executiveSummary: selectedReport.executiveSummary,
      runway: selectedReport.runway,
      profitAndLoss: selectedReport.profitAndLoss,
      balanceSheet: selectedReport.balanceSheet,
    };
    updateReportSectionsMutation.mutate(submissionData);
    handleClose();
  };

  const closeModal = () => {
    // reset fields
    setSelectedReport({
      executiveSummary: report.executiveSummary,
      runway: report.runway,
      profitAndLoss: report.profitAndLoss,
      balanceSheet: report.balanceSheet,
    });
    handleClose();
  };

  const enableSubmit = () => Object.values(selectedReport).some((value) => value);

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title className="fs-3 my-0">
          Edit Report Sections
        </Modal.Title>
      </Modal.Header>
      <ListGroup className="ps-0">
        <ListGroup.Item>
          <CreateNewReportRow
            reportTitle="Executive Summary"
            reportBody="Summary given from Fondo on the data you are viewing."
            sectionIsSelected={selectedReport.executiveSummary}
            reportingImage={reportingExecutiveSummary}
            handleChange={(change) => setSelectedReport({ ...selectedReport, executiveSummary: change.target.checked })}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <CreateNewReportRow
            reportTitle="Runway"
            reportBody="Viewing your companies runway broken down in charts easy to understand."
            sectionIsSelected={selectedReport.runway}
            reportingImage={reportingRunway}
            handleChange={(change) => setSelectedReport({ ...selectedReport, runway: change.target.checked })}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <CreateNewReportRow
            reportTitle="Profit and Loss"
            reportBody="Breakdown of revenue, cost, and expenses incurred."
            sectionIsSelected={selectedReport.profitAndLoss}
            reportingImage={reportingProfitAndLoss}
            handleChange={(change) => setSelectedReport({ ...selectedReport, profitAndLoss: change.target.checked })}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <CreateNewReportRow
            reportTitle="Balance Sheet"
            reportBody="Breakdown of assets, liabilities, and shareholder equity."
            sectionIsSelected={selectedReport.balanceSheet}
            reportingImage={reportingBalanceSheet}
            handleChange={(change) => setSelectedReport({ ...selectedReport, balanceSheet: change.target.checked })}
          />
        </ListGroup.Item>
      </ListGroup>
      <Modal.Footer>
        <Button variant="white" onClick={closeModal}>
          Cancel
        </Button>
        <Button disabled={!enableSubmit()} variant="primary" onClick={updateCurrentReport}>
          Update Report
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateReportModal;
