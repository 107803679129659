import { CardGroup } from "react-bootstrap";
import CorporateTaxSummaryCard from "./CorporateTaxSummaryCard";
import confettiEmoji from "../../../assets/img/confetti-emoji.svg";
import confettiEmojiDisabled from "../../../assets/img/confetti-emoji-disabled.svg";
import taxableIncome from "../../../assets/img/taxable-income.svg";
import taxesOwed from "../../../assets/img/taxes-owed.svg";
import { convertNumberToCurrency } from "../../../utils/utils";
import { Payroll } from "../financeDashboard.type";
import {
  rDCreditEstimateTooltipContent,
  taxableIncomeEstimateTooltipContent,
  taxesOwedEstimateTooltipContent,
} from "./utils";
import { getRDCreditEstimateAmount, getTaxableIncomeEstimate } from "./formulas";

type CorporateTaxSummaryCardsRowProps = {
  payrollData?: Payroll;
  payrollDataIsLoading: boolean;
}

const CorporateTaxSummaryCardsRow = ({
  payrollData,
  payrollDataIsLoading,
}: CorporateTaxSummaryCardsRowProps) => (
  <CardGroup style={ { gap: "1rem" } } className="mb-4">
    <CorporateTaxSummaryCard
      title="R&D Credit (estimate)"
      value={convertNumberToCurrency(payrollData?.rDCreditEstimateAmount || getRDCreditEstimateAmount(payrollData))}
      valueColorClassName="success"
      icon={confettiEmoji}
      disabledIcon={confettiEmojiDisabled}
      isLoading={payrollDataIsLoading}
      disabled={!payrollData}
      showHelpTooltip
      helpTooltipHTML={rDCreditEstimateTooltipContent}
    />
    <CorporateTaxSummaryCard
      title="Taxable Income (estimate)"
      value={convertNumberToCurrency(getTaxableIncomeEstimate(payrollData))}
      valueColorClassName="primary"
      icon={taxableIncome}
      isLoading={payrollDataIsLoading}
      disabled={!payrollData}
      showHelpTooltip
      helpTooltipHTML={taxableIncomeEstimateTooltipContent}
    />
    <CorporateTaxSummaryCard
      title="Taxes Owed (estimate)"
      value={convertNumberToCurrency(payrollData?.taxesOwedEstimateAmount || 0)}
      valueColorClassName="danger"
      icon={taxesOwed}
      isLoading={payrollDataIsLoading}
      disabled={!payrollData}
      showHelpTooltip
      helpTooltipHTML={taxesOwedEstimateTooltipContent}
    />
  </CardGroup>
);

export default CorporateTaxSummaryCardsRow;
