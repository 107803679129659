import { Button, Modal } from "react-bootstrap";

type DelawareFranchiseTaxDuplicateSigningOfficerModalProps = {
  show: boolean;
  handleClose: () => any;
  handleConfirm: () => any;
  handleCancel: () => any;
  taxYear: string|number;
};

const DelawareFranchiseTaxDuplicateSigningOfficerModal = ({
  show,
  handleClose,
  handleConfirm,
  handleCancel,
  taxYear,
}: DelawareFranchiseTaxDuplicateSigningOfficerModalProps) => (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Signing Officer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You already added an officer with this Tax ID on your Corporate Tax Form {taxYear}.
        <p className="fw-bold">Do you want to mark it as a Signing Officer?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          No
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Mark as Signing Officer
        </Button>
      </Modal.Footer>
    </Modal>
);

export default DelawareFranchiseTaxDuplicateSigningOfficerModal;
