import { Field, FormikContextType } from "formik";
import { useQuery } from "@tanstack/react-query";
import { Country } from "../../../utils/util.type";
import { getCountries } from "../../../utils/util.api";

type CountryFieldProps = {
  formikContext: FormikContextType<any>;
  fieldName: string;
  value: string;
  invalid: boolean;
}

export default function CountryField({
  formikContext, fieldName, value, invalid,
}: CountryFieldProps) {
  const countryQuery = useQuery<Country[], Error>(["country"], getCountries, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  function renderCountryList() {
    return (
      countryQuery.data?.map((country) => (
        <option key={country.alpha2} value={country.alpha2}>
          {country.name}
        </option>
      ))
    );
  }

  return (
    <Field
      name={fieldName}
      as="select"
      className={`form-control form-select ${invalid ? "is-invalid" : ""}`}
      onChange={(e: any) => {
        formikContext.setFieldValue(fieldName, e.target.value);
      }}
      value={value?.includes("-") ? value.split("-")[0] : value}
      isInvalid={invalid}
    >
      <option value={""}>Select Country</option>
      {renderCountryList()}
    </Field>
  );
}
