import { Dropdown } from "react-bootstrap";

type ResultsPerPageSelectorProps = {
  resultsPerPage: number;
  handleResultsPerPageChange: (value: number) => void;
  pageSizeStart?: number;
  pageSizeEnd?: number;
  pageSizeInterval?: number;
  pageSizesList?: number[];
}

const ResultsPerPageSelector = (
  {
    resultsPerPage,
    handleResultsPerPageChange,
    pageSizeStart = 10,
    pageSizeEnd = 55,
    pageSizeInterval = 5,
    pageSizesList,
  }: ResultsPerPageSelectorProps,
) => {
  const amountsPerPageOptions = Array.from(
    { length: (pageSizeEnd - pageSizeStart) / pageSizeInterval + 1 },
    (_, index) => pageSizeInterval + index * pageSizeInterval,
  );

  return (
    <Dropdown>
      <Dropdown.Toggle variant="none">
        {resultsPerPage} per page
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {pageSizesList && pageSizesList.length > 0 ? (
          <>
            {pageSizesList.map((option: number) => (
              <Dropdown.Item
                onClick={() => handleResultsPerPageChange(option)}
              >
                {option} per page
              </Dropdown.Item>
            ))}
          </>
        ) : (
          <>
            {amountsPerPageOptions.map((option: number) => (
              <Dropdown.Item
                onClick={() => handleResultsPerPageChange(option)}
              >
                {option} per page
              </Dropdown.Item>
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ResultsPerPageSelector;
