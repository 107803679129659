import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { convertNumberToCurrency } from "../../../utils/utils";
import AlertModal from "../../fusion-kit/AlertModal";
import BasicSearch from "../../fusion-kit/BasicSearch";
import Pagination from "../../fusion-kit/Pagination";
import ResetFilterButton from "../../fusion-kit/ResetFilterButton";
import {
  categorizeTransactionsUsingAI,
  massUpdateTransactionCategory,
  requestPaginatedTransactionsList,
} from "../FinanceDashboard.api";
import { DateFilterTypes, Transaction, TransactionCategory, TransactionOrdering } from "../financeDashboard.type";
import MultipleTransactionCategorySelector from "./MultipleTransactionCategorySelector";
import ResultsPerPageSelector from "./PageSizeSelector";
import TransactionsFilterButton from "./TransactionsFiltersButton";
import TransactionsTable from "./TransactionsTable";

type TransactionsCardProps = {
  category?: string | null;
  minAmount: number | undefined;
  setMinAmount: (value: number | undefined) => void;
  maxAmount: number | undefined;
  setMaxAmount: (value: number | undefined) => void;
  startDate: string | undefined;
  setStartDate: (value: string | undefined) => void;
  endDate: string | undefined;
  setEndDate: (value: string | undefined) => void;
  last30Days: boolean | undefined;
  setLast30Days: (value: boolean | undefined) => void;
  last6Months: boolean | undefined;
  setLast6Months: (value: boolean | undefined) => void;
  lastYear: boolean | undefined;
  setLastYear: (value: boolean | undefined) => void;
  allowUseOfAIToCategorizeTransactions: boolean;
};

const TransactionsCard = ({
  category,
  minAmount,
  setMinAmount,
  maxAmount,
  setMaxAmount,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  last30Days,
  setLast30Days,
  last6Months,
  setLast6Months,
  lastYear,
  setLastYear,
  allowUseOfAIToCategorizeTransactions
}: TransactionsCardProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearch, setCurrentSearch] = useState("");
  const [ordering, setOrdering] = useState(TransactionOrdering.REVERSED_DATE);
  const [pageSize, setPageSize] = useState(10);
  const queryClient = useQueryClient();

  useEffect(() => {
    setCurrentPage(1);
  }, [category]);

  const transactionsList = useQuery<any, Error>(
    [
      "transactionsList",
      category,
      currentPage,
      currentSearch,
      ordering,
      pageSize,
      minAmount,
      maxAmount,
      startDate,
      endDate,
      last30Days,
      last6Months,
      lastYear,
    ],
    () =>
      requestPaginatedTransactionsList(currentPage, {
        category,
        ordering,
        currentSearch,
        pageSize,
        minAmount,
        maxAmount,
        startDate,
        endDate,
        last30Days,
        last6Months,
        lastYear,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const handlePageChange = useCallback((newPage: number) => {
    setCurrentPage(newPage);
  }, []);

  const handleSearchChange = useCallback((search: string) => {
    setCurrentPage(1);
    setCurrentSearch(search);
  }, []);

  const handlePageSizeChange = useCallback((selectetPageSize: number) => {
    setCurrentPage(1);
    setPageSize(selectetPageSize);
  }, []);

  const handleAmountFilters = useCallback((minAmountValue: number | undefined, maxAmountValue: number | undefined) => {
    setMinAmount(minAmountValue);
    setMaxAmount(maxAmountValue);
    setCurrentPage(1);
  }, []);

  const handleDateFilters = useCallback(
    ({ startDateValue, endDateValue, last30DaysValue, last6MonthsValue, lastYearValue }: DateFilterTypes) => {
      setStartDate(startDateValue);
      setEndDate(endDateValue);
      setLast30Days(last30DaysValue);
      setLast6Months(last6MonthsValue);
      setLastYear(lastYearValue);
      setCurrentPage(1);
    },
    [],
  );

  const enableClearFiltersButton =
    minAmount || maxAmount || startDate || endDate || last30Days || last6Months || lastYear;

  const clearAllFilters = () => {
    setCurrentPage(1);
    setMinAmount(undefined);
    setMaxAmount(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    setLast30Days(undefined);
    setLast6Months(undefined);
    setLastYear(undefined);
  };

  // Update transactions type
  const [transactionsUuids, setTransactionUuids] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<TransactionCategory | undefined>();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [selectAllRows, setSelectAllRows] = useState<boolean>(false);

  const handleResetSelectedTransactions = () => {
    setTransactionUuids([]);
    setSelectAllRows(false);
  };

  const handleResetCategorizeTransactions = () => {
    handleResetSelectedTransactions();
    setSelectedCategory(undefined);
  };

  const handleSelectAllTransactionsInPage = (checked: boolean) => {
    // Handles select all checkbox change
    if (checked) {
      setSelectAllRows(true);
      setTransactionUuids(transactionsList.data?.results.map((transaction: Transaction) => transaction.uuid));
    } else {
      handleResetCategorizeTransactions();
    }
  };

  const isEveryTransactionSelected = () =>
    // Returns true if every transaction on the page has been selected
    transactionsList.data?.results.every((transaction: Transaction) => transactionsUuids.includes(transaction.uuid));

  useEffect(() => {
    // If you manually select all transactions in the page
    // we check the Select All checkbox. This will do the job
    // whenever a transaction is selected or unselected.
    setSelectAllRows(isEveryTransactionSelected());
  }, [transactionsUuids]);

  const massUpdateCategoryMutation = useMutation<any, AxiosError<any>>(
    () => massUpdateTransactionCategory(transactionsUuids, selectedCategory),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["transactionsList"],
        });
        queryClient.invalidateQueries({
          queryKey: ["transactionsStats"],
        });
        handleResetCategorizeTransactions();
      },
      onError: () => {
        setShowAlertModal(true);
      },
    },
  );

  const handleMassUpdateCategory = () => {
    massUpdateCategoryMutation.mutate();
  };

  const categorizeUsingAIMutation = useMutation<any, AxiosError<any>>(
    () => categorizeTransactionsUsingAI(transactionsUuids),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["transactionsList"],
        });
        queryClient.invalidateQueries({
          queryKey: ["transactionsStats"],
        });
        handleResetSelectedTransactions();
      },
      onError: () => {
        setShowAlertModal(true);
      },
    },
  );

  const handleCategorizeUsingAI = () => {
    categorizeUsingAIMutation.mutate();
  };

  return (
    <Card>
      <Card.Header>
        {!(transactionsUuids.length > 0) ? (
          <>
            <BasicSearch handleSearchChange={handleSearchChange} currentSearch={currentSearch} />
            <ResultsPerPageSelector
              resultsPerPage={pageSize}
              handleResultsPerPageChange={handlePageSizeChange}
              pageSizesList={[10, 50, 100, 200, 300, 400, 500]}
            />
            <TransactionsFilterButton
              minAmountValue={minAmount}
              maxAmountValue={maxAmount}
              handleAmountFilters={handleAmountFilters}
              startDateValue={startDate}
              endDateValue={endDate}
              handleDateFilters={handleDateFilters}
            />
          </>
        ) : (
          <MultipleTransactionCategorySelector
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            handleCancel={handleResetCategorizeTransactions}
            handleMassUpdateCategory={handleMassUpdateCategory}
            handleCategorizeUsingAI={handleCategorizeUsingAI}
            allowUseOfAIToCategorizeTransactions={allowUseOfAIToCategorizeTransactions}
          />
        )}
      </Card.Header>
      <Card.Body className="p-0">
        <div className="d-flex">
          {(!!minAmount || minAmount === 0) && (
            <ResetFilterButton
              currentFilter={`$${convertNumberToCurrency(minAmount)}`}
              filterName="Min Amount"
              setFilterValue={setMinAmount}
            />
          )}
          {(!!maxAmount || maxAmount === 0) && (
            <ResetFilterButton
              currentFilter={`$${convertNumberToCurrency(maxAmount)}`}
              filterName="Max Amount"
              setFilterValue={setMaxAmount}
            />
          )}
          {last30Days && <ResetFilterButton currentFilter="Last 30 days" setFilterValue={setLast30Days} />}
          {last6Months && <ResetFilterButton currentFilter="Last 6 Months" setFilterValue={setLast6Months} />}
          {lastYear && <ResetFilterButton currentFilter="Last year" setFilterValue={setLastYear} />}
          {startDate && (
            <ResetFilterButton
              filterName="Starting Date"
              setFilterValue={setStartDate}
              currentFilter={startDate.replaceAll("-", "/")}
            />
          )}
          {endDate && (
            <ResetFilterButton
              filterName="Ending Date"
              setFilterValue={setEndDate}
              currentFilter={endDate.replaceAll("-", "/")}
            />
          )}
          {enableClearFiltersButton && (
            <div className="my-3" style={{ marginLeft: "12px" }}>
              <Button variant="primary" size="sm" onClick={() => clearAllFilters()}>
                Clear All Filters
              </Button>
            </div>
          )}
        </div>
        <TransactionsTable
          transactions={transactionsList.data?.results as Transaction[]}
          currentOrdering={ordering}
          setOrdering={setOrdering}
          transactionsToCategorize={transactionsUuids}
          setTransactionUuids={setTransactionUuids}
          selectAllRows={selectAllRows}
          handleSelectAllTransactionsInPage={handleSelectAllTransactionsInPage}
          allowUseOfAIToCategorizeTransactions={allowUseOfAIToCategorizeTransactions}
        />
      </Card.Body>
      <Card.Footer>
        <Pagination
          currentPageNumber={currentPage}
          numberOfPages={transactionsList.data?.totalPages || 1}
          isLoading={transactionsList.isLoading}
          handlePageChange={handlePageChange}
          numberOfPagesBeforeEllipsis={10}
          currentData={transactionsList.data}
        />
        <AlertModal show={showAlertModal} title={"Error"} handleClose={() => setShowAlertModal(false)} error={true} />
      </Card.Footer>
    </Card>
  );
};

export default TransactionsCard;
