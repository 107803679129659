import { useState } from "react";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import {
  CorpTaxFormFields,
  CorporateTaxStep,
  OfficerFormFields,
} from "./corporateTax.type";
import AddOfficerModal from "./modals/AddOfficerModal";
import { Post2023CorporateTaxStep } from "./post-2023/post2023CorporateTax.type";
import DisplaySingleLineAddress from "../forms/display-helpers/DisplaySingleLineAddress";

type OfficersProps = {
  arrayHelpers: FieldArrayRenderProps;
  stepInfo: CorporateTaxStep | Post2023CorporateTaxStep;
}

export default function Officers({ arrayHelpers }: OfficersProps) {
  const formikContext = useFormikContext<CorpTaxFormFields>();

  const needOneError = typeof formikContext.errors?.officers === "string";

  const [showAddOfficerModal, setShowAddOfficerModal] = useState<boolean>(false);
  const [currentlySelectedOfficerIndex, setCurrentlySelectedOfficerIndex] = useState<number | undefined>();

  const handleCancel = () => {
    setShowAddOfficerModal(false);
    setCurrentlySelectedOfficerIndex(undefined);
  };

  const handleSave = (officerData: OfficerFormFields) => {
    if (currentlySelectedOfficerIndex !== undefined) {
      arrayHelpers.replace(currentlySelectedOfficerIndex, officerData);
    } else {
      arrayHelpers.push(officerData);
    }

    setCurrentlySelectedOfficerIndex(undefined);
    setShowAddOfficerModal(false);
  };

  const handleDelete = (index: number, officer: OfficerFormFields) => {
    if (officer.uuid) {
      formikContext.setFieldValue("oldOfficers", [...formikContext.values.oldOfficers, officer.uuid]);
    }
    arrayHelpers.remove(index);
  };

  const fieldHasError = (index: number, fields: string[]): boolean => (
    fields.some((field) => !!(formikContext.errors?.officers?.[index] as any)?.[field])
  );

  function renderOfficers() {
    return (
      formikContext.values.officers.map((officer, index) => (
        <div className="col-lg-6 col-xl-4 mb-5">
          <div className="d-flex justify-content-between">
            <h3>{`${officer?.firstName} ${officer?.lastName}`}</h3>
            <div>
              <span>
                <i
                  className="fe fe-edit-2 mx-2 action-icon text-secondary"
                  onClick={() => {
                    setCurrentlySelectedOfficerIndex(index);
                    setShowAddOfficerModal(true);
                  }}
                  style={{ cursor: "pointer" }}
                  title="Edit"
                ></i>
              </span>
              <span>
                <i
                  className="fe fe-trash mx-2 action-icon text-secondary"
                  title="Delete"
                  onClick={() => handleDelete(index, officer)}
                  style={{ cursor: "pointer" }}
                ></i>
              </span>
            </div>
          </div>
          <hr className='mt-0' />
          <div className='row'>
            <p
              className={`data-item ${fieldHasError(index, ["firstName", "lastName"]) ? "text-danger" : ""}`}
            >
              Name: {officer?.firstName} {officer?.lastName}
            </p>
            <p
              className={`data-item ${fieldHasError(index, ["title"]) ? "text-danger" : ""}`}
            >
              Title: {officer?.title}
            </p>
            <p
              className={`data-item
              ${fieldHasError(index, ["signingOfficer"]) ? "text-danger" : ""}
              ${formikContext.errors.officers === "Need one signing officer" ? "text-danger" : ""}
              `}
            >
              Signing Officer: {officer?.signingOfficer ? "Yes" : "No"}
            </p>
            <p
              className={`data-item ${fieldHasError(index, [
                "streetAddress", "city", "state", "postalCode",
              ]) ? "text-danger" : ""}`}
            >
              Address: <DisplaySingleLineAddress addressable={officer}
              /></p>
            <p
              className={`data-item ${fieldHasError(index, ["taxIdEnc"]) ? "text-danger" : ""}`}
            >
              Tax ID: {officer?.taxIdEnc}
            </p>
          </div>
        </div>
      ))
    );
  }

  return (
    <>
      {formikContext.values.officers.length > 0 && (
        <div>
          <div className="row mt-5" id="officers-container">
            {renderOfficers()}
          </div>
          {needOneError && <h3 className="text-danger mt-4 field-error text-center">
            You need to have at least one signing officer.
          </h3>}
          <div id="officers-add-another-btn mb-5">
            <button type="button" className="btn btn-primary" onClick={() => setShowAddOfficerModal(true)}>
              Add another
            </button>
          </div>
        </div>
      )}

      {(formikContext.values.officers.length === 0) && (
        <div id="officers-no-data" className='text-align-center' style={{ margin: "200px" }}>
          <h3 className="text-secondary mb-4">Start by adding a new officer</h3>
          <button type="button" className="btn btn-primary" onClick={() => setShowAddOfficerModal(true)}>
            Add new
          </button>
          {needOneError && <h3 className="text-danger mt-4 field-error text-center">
            You need to add at least one officer.
          </h3>}
        </div>
      )}

      <p className="text-center text-muted mt-3">
        <i className="fe fe-info mr-1" />
        One of the officers entered above must be the signing officer responsible for signing the tax return.
      </p>

      <AddOfficerModal
        show={showAddOfficerModal}
        handleClose={handleCancel}
        officer={
          currentlySelectedOfficerIndex !== undefined
            ? formikContext.values.officers[currentlySelectedOfficerIndex] : {}
        }
        header="Add new officer"
        handleSave={handleSave}
      />
    </>
  );
}
