import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import ActionItemInstitutionSelectorModal from "./ActionItemInstitutionSelectorModal";
import { getActionItemSelectedInstitutions, getInstitutions, removeFromSelectedInstitutions } from "./actionItems.api";
import {
  ActionItem, ActionItemSelectedInstitution, ActionItemStatus, ConnectionType,
} from "./actionItems.type";

type ConnectionTypeActionItemSelectorProps = {
  connectionType: ConnectionType;
  actionItem: ActionItem;
}

const ConnectionTypeActionItemSelector = ({ connectionType, actionItem }: ConnectionTypeActionItemSelectorProps) => {
  const queryClient = useQueryClient();

  const [showInstitutionSelectorModal, setShowInstitutionSelectorModal] = useState(false);

  const { data: institutions } = useQuery(
    ["institutions", connectionType.id],
    () => getInstitutions(connectionType.id),
  );

  const { data: actionItemSelectedInstitutions } = useQuery<any, Error>(
    ["actionItemSelectedInstitutions", actionItem?.id],
    () => getActionItemSelectedInstitutions(actionItem.id),
    { enabled: !!actionItem },
  );

  const removeFromSelectedInstitutionsMutation = useMutation<string, AxiosError<any>, string>(
    (selectedInstitutionId: string) => removeFromSelectedInstitutions(selectedInstitutionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["actionItemSelectedInstitutions", actionItem?.id],
        });
      },
    },
  );

  return (
    <>
      {actionItemSelectedInstitutions?.length > 0 && (
        <>
          <div className="mb-4">
            {actionItemSelectedInstitutions.map((selectedInstitution: ActionItemSelectedInstitution, idx: number) => (
              <div
                key={selectedInstitution.id}
                className={"bg-white border border-gray p-2 ps-2 pe-3 d-flex justify-content-between "
                  + `${actionItemSelectedInstitutions.length !== idx + 1 && "border-bottom-0"}`}>
                <div>
                  <img src={selectedInstitution.selectedInstitution.image?.url} width={"24px"} className="me-3 mt-n1" />
                  {selectedInstitution.selectedInstitution.name}
                </div>
                {actionItem.status !== ActionItemStatus.COMPLETED && (
                  <div>
                    <FeatherIcon
                      onClick={() => removeFromSelectedInstitutionsMutation.mutate(`${selectedInstitution.id}`)}
                      role="button"
                      icon='x'
                      size='1em'
                      className='ms-1 mt-n1 text-muted'
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}

      {actionItem.status !== ActionItemStatus.COMPLETED && (
        <div className="d-flex justify-content-between">
          <p
            className="text-primary"
            onClick={() => setShowInstitutionSelectorModal(true)}
            style={{ cursor: "pointer" }}
          >
            <FeatherIcon
              icon='plus'
              size='1em'
              className='mt-n1 me-1'
            />
            Add another {connectionType.name}
          </p>
        </div>
      )}

      <ActionItemInstitutionSelectorModal
        show={showInstitutionSelectorModal}
        handleClose={() => setShowInstitutionSelectorModal(false)}
        institutions={institutions}
        selectedInstitutions={actionItemSelectedInstitutions}
        connectionType={connectionType}
        actionItemId={actionItem.id}
      />
    </>
  );
};

export default ConnectionTypeActionItemSelector;
