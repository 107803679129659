import { CORP_TAX_CONTEXT, CORP_TAX_ONBOARDING } from "../../constants/network-calls";
import { CorpTaxFormFields } from "./corporateTax.type";
import { Post2023CorpTaxFormFields } from "./post-2023/post2023CorporateTax.type";
import { getFondoApi, postFondoApi } from "../../utils/util.api";

export async function getCorporateTax(params: any): Promise<string> {
  const [, taxYear] = params.queryKey;
  const result = await getFondoApi(CORP_TAX_ONBOARDING + taxYear);
  return result as Promise<any>;
}

export async function getCorporateTaxContext(taxYear: string | number): Promise<any> {
  const result: any = await getFondoApi(CORP_TAX_CONTEXT, { taxYear });
  result.data.oldOfficers = [];
  result.data.oldMajorityShareholders = [];
  result.data.oldForeignBanks = [];
  result.data.oldUsSubs = [];
  return result as CorpTaxFormFields;
}

export async function updateCorporateTax(
  data: CorpTaxFormFields | Post2023CorpTaxFormFields,
  taxYear: string | number,
): Promise<CorpTaxFormFields> {
  const result = (await postFondoApi(CORP_TAX_CONTEXT, data, { taxYear })) as CorpTaxFormFields;
  result.oldOfficers = [];
  result.oldMajorityShareholders = [];
  result.oldForeignBanks = [];
  result.oldUsSubs = [];
  return result;
}
