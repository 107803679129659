import { Card, Table } from "react-bootstrap";
import { useState } from "react";
import { formatCurrency } from "../../../utils/common.util";
import { QBOReportingData, QBOReportingHeader, QBOReportingResult } from "../Reporting.type";
import DrillDownProfitAndLossRow from "./DrillDownProfitAndLossRow";

type QBOTableProps = {
  tableName: string;
  qboReportingData: QBOReportingData;
  isBalanceSheet?: boolean;
}

const QBOTable = ({ tableName, qboReportingData, isBalanceSheet }: QBOTableProps) => {
  const qboHeaderData = qboReportingData.headers;

  const [activeDrillDownRowName, setActiveDrillDownRowName] = useState("");

  if (qboHeaderData.length === 0) {
    // If there is no header data, there is no QBO data, so we'll render a blank card
    return (
      <Card>
        <Card.Header className="mt-4">
          <Card.Title as={"h1"}>
            {tableName}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Text className="text-center">
            No data available
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  const renderTableHeader = () => {
    // Balance Sheet has only one period (the current period) whereas P&L has two periods (current and previous)
    if (isBalanceSheet) {
      return (
        <th className="px-5">
          {qboHeaderData.at(-1)?.name /* Current period */}
        </th>
      )
    }
    return (
      <>
        <th className="px-5">
          {qboHeaderData.at(-3)?.name /* Previous period */}
        </th>
        <th className="px-5">
          {qboHeaderData.at(-2)?.name /* Current period */}
        </th>
      </>
    )
  }

  const renderBasenameRowHeader = (name: string, padding?: number) => (
    <tr>
      <th style={padding ? { paddingLeft: padding } : {}}>{name}</th>
      {/* The rows below are filler for the names */}
      <th />
      {!isBalanceSheet && <th />}
    </tr>
  );

  const renderCurrency = (amount?: string): string => {
    // If the amount is undefined it means it doesn't exist in the list of amounts
    if (amount === undefined) {
      return "";
    }
    return amount ? formatCurrency(amount) : "$0.00"
  };

  const handleDrillDownRowClick = (drillDownShowing: boolean, item: QBOReportingResult) => {
    if (drillDownShowing) {
      setActiveDrillDownRowName(item.name)
    } else {
      setActiveDrillDownRowName("");
    }
  }


  const renderRowTotal = (item: QBOReportingResult, { total, padding }: { total?: boolean, padding?: number }) => {
    const amounts = item.amount;
    const balanceAmount = amounts.at(-1);
    const currentAmount = amounts.at(-2);
    const previousAmount = amounts.at(-3);
    // Similar reasoning as above, conditionally render the items based on whether it's Balance Sheet or P&L
    if (isBalanceSheet) {
      return (
        <tr>
          {total ?
            <>
              <th style={padding ? { paddingLeft: padding } : {}}>{item.name}</th>
              <th className="px-5">{renderCurrency(balanceAmount)}</th>
            </> :
            <>
              <td style={padding ? { paddingLeft: padding } : {}}>{item.name}</td>
              <td className="px-5">{renderCurrency(balanceAmount)}</td>
            </>
          }
        </tr>
      )
    }
    return (
      <DrillDownProfitAndLossRow
        item={item}
        padding={padding}
        total={total}
        previousAmount={renderCurrency(previousAmount)}
        currentAmount={renderCurrency(currentAmount)}
        periods={qboHeaderData.slice(0, -1)}
        handleRowClick={handleDrillDownRowClick}
        active={item.name === activeDrillDownRowName}
      />
    )
  };

  const renderBasenameRow = (
    item: QBOReportingResult,
    headers: QBOReportingHeader[],
    depth?: number,
  ): React.ReactNode => {
    if (depth && depth > 0) {
      const pad = 25 + (depth * 15);
      return (
        <>
          {item.children?.map((child) => {
            if (child.baseName) {
              return (
                <>
                  {renderBasenameRowHeader(child.baseName, pad)}
                  {renderBasenameRow(child, headers, depth + 1)}
                  {renderRowTotal(child, { total: true, padding: pad })}
                </>
              )
            }
            return (
              renderRowTotal(child, { padding: pad })
            )
          })}
        </>
      )
    }
    return (
      <>
        {renderBasenameRowHeader(item.baseName || "")}
        {item.children?.map((child) => {
          if (child.baseName) {
            return (
              <>
                {renderBasenameRowHeader(child.baseName, 25)}
                {renderBasenameRow(child, headers, 1)}
                {renderRowTotal(child, { total: true, padding: 25 })}
              </>
            )
          }
          return renderRowTotal(child, { padding: 25 })
        })}
        {renderRowTotal(item, { total: true })}
      </>
    )
  };

  return (
    <Card>
      <Card.Header className="mt-4">
        <Card.Title as={"h1"}>
          {tableName}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {qboReportingData.results.map((item, index) => (
          <div key={index}>
            <h3>
              {item.baseName || item.name}
            </h3>
            <hr />
            <Table className="text-nowrap fs-5 mb-0" responsive>
              <thead>
                <tr>
                  <th style={{ width: 1000 }} />
                  {renderTableHeader()}
                </tr>
              </thead>
              <tbody>
                {item.baseName ? renderBasenameRow(item, qboReportingData.headers)
                  : renderRowTotal(item, { total: true })}
              </tbody>
            </Table>
            <hr className="mt-0 mb-5" />
          </div>
        ))}
      </Card.Body>
    </Card>
  )

}

export default QBOTable;