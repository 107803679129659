import { ErrorMessage, Field, FormikContextType } from "formik";
import { Form, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import CurrencyField from "./CurrencyField";
import { FieldTypes, HelperText } from "./formTypes";
import FormikDatePickerField from "../../flatpickr/FormikFlatpickrField";
import { PERCENTAGE_MULTIPLE_OF_5 } from "../../../constants/general";
import CountryField from "./CountryField";
import StateField from "./StateField";
import { followPath } from "./utils";
import SsnField from "./SsnField";
import DateInYearField from "./DateInYearField";
import EinField from "./EinField";
import { helperTextTypeToLabelMap } from "../../corporate-tax/post-2023/post2023CorporateTax.type";
import { getListOfYears } from "../../../utils/common.util";
import EncryptedTextField from "./EncryptedTextField";
import { FiveStars } from "../../fondo-components/FiveStars";

type FondoFormGroupProps = {
  formikContext: FormikContextType<any>;
  fieldName: string;
  labels?: { [key: string]: string };
  types: { [key: string]: FieldTypes };
  placeholders?: { [key: string]: string };
  selectedCountry?: string;
  helperText?: HelperText;
  enablePasswordType?: boolean;
  selectorOptions?: string[] | JSX.Element[];
  includeSelectorEmptyValue?: boolean;
  mutedDescription?: string;
  maxLength?: number;
  staticDateDisplay?: boolean;
  hideLabel?: boolean;
  checkboxType?: "switch" | "checkbox";
  className?: string;
};

const FondoFormGroup = ({
  formikContext,
  fieldName,
  labels,
  types,
  placeholders,
  selectedCountry,
  helperText,
  enablePasswordType,
  selectorOptions,
  includeSelectorEmptyValue,
  mutedDescription,
  maxLength,
  staticDateDisplay,
  checkboxType,
  hideLabel,
  className,
}: FondoFormGroupProps) => {
  const fieldPath = fieldName.split(".");
  const baseFieldName = fieldPath[fieldPath.length - 1];

  const type = types[baseFieldName];

  const invalidField = !formikContext.isValid && followPath(formikContext.errors, fieldPath);

  let field = (
    <Form.Control
      value={followPath(formikContext.values, fieldPath)}
      onChange={formikContext.handleChange}
      onBlur={formikContext.handleBlur}
      name={fieldName}
      isInvalid={invalidField}
      placeholder={placeholders?.[baseFieldName]}
      as={type === FieldTypes.TEXTAREA ? "textarea" : "input"}
      type={type === FieldTypes.INTEGER ? "number" : "text"}
      maxLength={maxLength}
    />
  );
  if (type === FieldTypes.CURRENCY) {
    field = (
      <CurrencyField
        value={followPath(formikContext.values, fieldPath)}
        onChange={formikContext.handleChange}
        onBlur={formikContext.handleBlur}
        name={fieldName}
      />
    );
  } else if (type === FieldTypes.YESNO) {
    field = (
      <div className={`form-radio form-radio-inline p-0 ${invalidField ? "is-invalid" : ""}`}>
        <Form.Check
          inline
          label="Yes"
          value="True"
          name={fieldName}
          type="radio"
          onChange={formikContext.handleChange}
          onBlur={formikContext.handleBlur}
          checked={followPath(formikContext.values, fieldPath) === "True"}
        />
        <Form.Check
          inline
          label="No"
          value="False"
          name={fieldName}
          type="radio"
          onChange={formikContext.handleChange}
          onBlur={formikContext.handleBlur}
          checked={followPath(formikContext.values, fieldPath) === "False"}
        />
      </div>
    );
  } else if (type === FieldTypes.EIN) {
    field = (
      <EinField
        formikContext={formikContext}
        fieldName={fieldName}
        invalid={invalidField}
        enablePasswordType={enablePasswordType}
      />
    );
  } else if (type === FieldTypes.DATE) {
    field = (
      <FormikDatePickerField
        fieldName={fieldName}
        handleBlur={formikContext.handleBlur}
        setFieldValue={formikContext.setFieldValue}
        date={followPath(formikContext.values, fieldPath)}
        {...formikContext.getFieldProps(fieldName)}
        invalid={invalidField}
        staticDateDisplay={staticDateDisplay}
      />
    );
  } else if (type === FieldTypes.SELECT_PERCENTAGE) {
    field = (
      <>
        <div className="input-group-prepend">
          <span className="input-group-text input-side-box">%</span>
        </div>
        <Field
          name={fieldName}
          as="select"
          className={`form-control form-select ${invalidField ? "is-invalid" : ""}`}
          onChange={(e: any) => {
            formikContext.setFieldValue(fieldName, e.target.value);
          }}
          isInvalid={invalidField}
          invalid={invalidField}
        >
          <option key="emptyValue" value={""}>
            --------
          </option>
          {PERCENTAGE_MULTIPLE_OF_5.map((element) => (
            <option key={element} value={element}>
              {element}
            </option>
          ))}
        </Field>
      </>
    );
  } else if (type === FieldTypes.COUNTRY) {
    field = (
      <CountryField
        invalid={invalidField}
        formikContext={formikContext}
        fieldName={fieldName}
        value={followPath(formikContext.values, fieldPath)}
      />
    );
  } else if (type === FieldTypes.STATE) {
    field = (
      <StateField
        invalid={invalidField}
        formikContext={formikContext}
        fieldName={fieldName}
        selectedCountry={selectedCountry}
        value={followPath(formikContext.values, fieldPath)}
      />
    );
  } else if (type === FieldTypes.SSN) {
    field = (
      <SsnField
        formikContext={formikContext}
        fieldName={fieldName}
        invalid={invalidField}
        selectedCountry={selectedCountry}
        enablePasswordType={enablePasswordType}
      />
    );
  } else if (type === FieldTypes.DATE_OF_YEAR) {
    field = (
      <DateInYearField
        formikContext={formikContext}
        fieldName={fieldName}
        value={followPath(formikContext.values, fieldPath)}
        invalid={invalidField}
      />
    );
  } else if (type === FieldTypes.CHECKBOX) {
    field = (
      <Form.Check
        id={fieldName}
        type={checkboxType || "checkbox"}
        label={labels?.[baseFieldName]}
        checked={followPath(formikContext.values, fieldPath)}
        onChange={(e: any) => {
          formikContext.setFieldValue(fieldName, e.target.checked);
        }}
      ></Form.Check>
    );
  } else if (type === FieldTypes.BASIC_SELECTOR) {
    const getSelectorOptions = (selOptions: string[] | JSX.Element[]) => {
      if (typeof selOptions[0] === "string") {
        selOptions = selOptions as string[];
        return selOptions.map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ));
      }
      return selOptions;
    };
    field = (
      <Field
        name={fieldName}
        as="select"
        className={`form-control form-select ${invalidField ? "is-invalid" : ""}`}
        onChange={(e: any) => {
          formikContext.setFieldValue(fieldName, e.target.value);
        }}
        isInvalid={invalidField}
        invalid={invalidField}
      >
        {includeSelectorEmptyValue && <option key="emptyValue" value={""}></option>}
        {selectorOptions !== undefined && selectorOptions.length > 0 && getSelectorOptions(selectorOptions)}
      </Field>
    );
  } else if (type === FieldTypes.YESNO_FROM_BOOLEAN) {
    field = (
      <Field
        name={fieldName}
        as="select"
        className={`form-control form-select ${invalidField ? "is-invalid" : ""}`}
        onChange={(e: any) => {
          formikContext.setFieldValue(fieldName, e.target.value);
        }}
        isInvalid={invalidField}
        invalid={invalidField}
      >
        {includeSelectorEmptyValue && <option key="emptyValue" value={""}></option>}
        <option value="true" key={"true"}>
          Yes
        </option>
        <option value="false" key={"false"}>
          No
        </option>
      </Field>
    );
  } else if (type === FieldTypes.YEAR_SELECTOR) {
    field = (
      <Field
        name={fieldName}
        as="select"
        className={`form-control form-select ${invalidField ? "is-invalid" : ""}`}
        onChange={(e: any) => {
          formikContext.setFieldValue(fieldName, e.target.value);
        }}
        isInvalid={invalidField}
        invalid={invalidField}
      >
        {includeSelectorEmptyValue && <option key="emptyValue" value={""}></option>}
        {getListOfYears().map((choice) => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </Field>
    );
  } else if (type === FieldTypes.ENCRYPTED_TEXT) {
    field = (
      <EncryptedTextField
        formikContext={formikContext}
        fieldName={fieldName}
        invalid={invalidField}
        enablePasswordType={enablePasswordType}
        placeholder={placeholders?.[baseFieldName]}
      />
    );
  } else if (type === FieldTypes.STAR_RATING) {
    field = (
      <FiveStars
        stars={followPath(formikContext.values, fieldPath)}
        setStars={(stars) => formikContext.setFieldValue(fieldName, stars)}
        starSize={24}
      />
    );
  }

  const renderHelpTooltip = () => {
    if (helperText) {
      const tooltipPopover = (
        <Popover>
          <Popover.Body>
            <h5>{helperTextTypeToLabelMap[helperText.type]}</h5>
            <hr className="mt-n2" />
            <p className="mt-n3 mb-0">{helperText.body}</p>
          </Popover.Body>
        </Popover>
      );
      return (
        <OverlayTrigger trigger="click" placement="right" overlay={tooltipPopover}>
          <a role="button" tabIndex={0}>
            <FeatherIcon icon="help-circle" className="text-warning ms-1" size="15" />
          </a>
        </OverlayTrigger>
      );
    }
  };

  return (
    <Form.Group className={className || "mb-4"}>
      {labels && !hideLabel && <Form.Label>{labels[baseFieldName]}</Form.Label>}
      {mutedDescription && <p className="text-muted mb-2">{mutedDescription}</p>}
      {renderHelpTooltip()}
      <InputGroup aria-invalid={invalidField}>{field}</InputGroup>
      <span className="text-danger">
        <ErrorMessage name={fieldName} />
      </span>
    </Form.Group>
  );
};

export default FondoFormGroup;
