import FeatherIcon from "feather-icons-react";
import fondoLogoSquare from "../../assets/img/fondo-icon-square.png";
import { RoundedImage } from "../fondo-components/RoundedImage";

const PARTNER_ROUNDED_IMAGE_DIAMETER = "6rem";

type FondoPartnerLogosProps = {
  partnerLogoUrl: string;
  width?: string;
  icon?: string;
  iconClassName?: string;
  imageDiameter?: string;
};

export const FondoPartnerLogos = ({
  partnerLogoUrl,
  width,
  icon,
  iconClassName,
  imageDiameter,
}: FondoPartnerLogosProps) => (
    <div style={{ width: width || "60%" }} className="d-flex justify-content-evenly align-items-center">
      <RoundedImage logoUrl={fondoLogoSquare} imageDiameter={imageDiameter || PARTNER_ROUNDED_IMAGE_DIAMETER} />
      <FeatherIcon icon={icon || "arrow-right"} size={36} className={iconClassName || "text-secondary"} />
      <RoundedImage logoUrl={partnerLogoUrl} imageDiameter={imageDiameter || PARTNER_ROUNDED_IMAGE_DIAMETER} />
    </div>
  );
