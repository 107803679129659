import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  Modal, OverlayTrigger, Spinner, Tooltip,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { AxiosError, AxiosResponse } from "axios";
import { NumericFormat } from "react-number-format";
import Filings1099VendorForm from "./Filings1099Form";
import { Filing1099, Vendor } from "./filings1099.type";
import { DOCUMENTS } from "../../constants/network-calls";
import { deleteVendor, getFilings1099, saveFilings1099 } from "./filings1099.api";
import { REQUIRED_1099_PAYMENT_METHODS } from "../../constants/general";
import ConfirmationModal from "../fusion-kit/ConfirmationModal";
import FondoToast from "../fusion-kit/FondoToast";
import FondoLayout from "../fusion-kit/FondoLayout";

export default function Filings1099() {
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [, setShowVendorDeleteConfirmationModal] = useState(false);
  const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] = useState(false);
  const [showSubmitSuccessToast, setShowSubmitSuccessToast] = useState(false);
  const [editVendor, setEditVendor] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<Vendor>();
  const navigate = useNavigate();

  const { taxYear } = useParams();

  const get1099FilingsQuery = useQuery<Filing1099, Error>(
    ["filings1099"],
    () => getFilings1099(taxYear || ""),
    { refetchOnWindowFocus: false, enabled: !!taxYear },
  );

  const deleteVendorMutation = useMutation<any, AxiosError<any>, string>(
    (vendorId) => deleteVendor(vendorId),
    {
      onSuccess: () => {
        get1099FilingsQuery.refetch();
      },
    },
  );
  const finalSubmitMutation = useMutation<any, AxiosError<any>, Filing1099>(
    (filing) => saveFilings1099({ filing, taxYear: taxYear || "" }),
    {
      onSuccess: () => {
        setShowSubmitSuccessToast(true);
      },
    },
  );

  function onEditVendorClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, vendor: Vendor) {
    event.preventDefault();
    setEditVendor(true);
    setSelectedVendor(vendor);
    setShowVendorModal(true);
  }

  function onDeleteVendorClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, vendorId: string) {
    event.preventDefault();
    deleteVendorClicked(vendorId);
  }

  function onAddVendorClick() {
    setEditVendor(false);
    setShowVendorModal(true);
  }

  function renderVendors() {
    return (<>
      <table className="table table-sm">
        <thead>
          <tr>
            <th scope="col">Recipient name</th>
            <th scope="col">Payment method</th>
            <th scope="col">Payment description</th>
            <th scope="col">Email</th>
            <th scope="col">Total amount paid in {get1099FilingsQuery.data?.currentYear}</th>
            <th scope="col">Do you have a W-9?</th>
            <th scope="col">Federal Tax Classification</th>
            <th scope="col">Actions</th>
            <th scope="col">
              <button
                className="btn btn-sm btn-primary float-end text-nowrap"
                onClick={() => { onAddVendorClick(); }}
              >
                  Add vendor
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            get1099FilingsQuery.data?.vendors?.map((v) => <tr>
              <td>
                {v.recipientName}
                {v.status === "Ignore"
                  && <span style={{ fontSize: "0.6rem" }}><br />No 1099 needed for Vendor</span>
                }
                {v.status !== "Ignore" && REQUIRED_1099_PAYMENT_METHODS.includes(v.paymentMethod) && v.missingW9
                  && <span style={{ verticalAlign: "text-bottom" }}>
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="right"
                      overlay={
                        <Tooltip>
                          Upload W9s in Document Center or enter info here. Ignore this message if already done. Fondo
                          will verify your info soon!
                        </Tooltip>
                      }>
                      <span><FeatherIcon className="ms-1 text-danger" icon="info" size="1.1em" /></span>
                    </OverlayTrigger>
                  </span>
                }
              </td>
              <td>{v.paymentMethod}</td>
              <td>{v.paymentDescription || "-"}</td>
              <td>{v.email || "-"}</td>
              <td>
                <NumericFormat
                  value={v.totalAmountPaidPrevYear ? v.totalAmountPaidPrevYear : "-"}
                  displayType={"text"}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={"$"}
                />
              </td>
              <td>{v.hasW9 || "-"}</td>
              <td>{v.federalTaxClassification || "-"}</td>
              <td>
                <div className='d-flex'>
                  <a href="" onClick={(event) => { onEditVendorClick(event, v); }}>
                    <i
                      id="edit_vendor-action"
                      className="fe fe-edit-2 mx-2 action-icon text-secondary"
                      title="Edit"
                    />
                  </a>
                  <a href="" onClick={(event) => { onDeleteVendorClick(event, v.id); }}>
                    <i
                      id="delete_vendor-action"
                      className="fe fe-trash mx-2 action-icon text-secondary"
                      title="Delete"
                    />
                  </a>
                </div>
              </td>
              <td></td>
            </tr>)
          }

        </tbody>
      </table>
    </>);
  }

  function onModalSuccessCallback() {
    setShowVendorModal(false);
    get1099FilingsQuery.refetch();
  }

  function deleteVendorClicked(vendorId: string) {
    deleteVendorMutation.mutate(vendorId, {
      onError: (err) => {
        console.error(err.message);
      },
      onSuccess: (res: AxiosResponse) => {
        console.log(res.data);
      },
    });
    setShowVendorDeleteConfirmationModal(false);
  }

  function onSubmit() {
    if (get1099FilingsQuery.data) {
      finalSubmitMutation.mutate(get1099FilingsQuery.data);
    }
    setShowSubmitConfirmationModal(false);
  }

  if (!get1099FilingsQuery.data) {
    return <></>;
  }

  return (
    <FondoLayout title='1099 Onboarding' pretitle='Tax'>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h2 className="card-header-title">
              Instructions
            </h2>
          </div>
          <div className="card-body">

            <div className="row">
              <div className="col-auto">
                <div className="avatar avatar-sm">
                  <div className="avatar-title font-size-lg rounded-circle bg-primary-soft text-primary">
                    <i className="fe fe-calendar"></i>
                  </div>
                </div>
              </div>
              <div className="col ml-n2 align-deadlines">
                <h2 className="mb-3">
                  1099 Onboarding Deadline: January 15, {Number(taxYear) + 1} <br />
                  1099 Filing Deadline: January 31, {Number(taxYear) + 1}
                </h2>
                <p className="mb-2 text-gray-700">
                  Please provide the required information below on each vendor or contractor that your company paid more
                  than $600 out of a regular bank account during {Number(taxYear)} for services.
                  The company will need to file a 1099-NEC with the IRS for each one as well as send each vendor their
                  1099-NEC or 1099-MISC:
                  <ul className="text-gray-700">
                    <li>U.S. based Contractor</li>
                    <li>U.S. based Consultant</li>
                    <li>U.S. based Agency</li>
                    <li>U.S. based Law Firm</li>
                    <li>U.S. based Landlord</li>
                  </ul>
                  <span className="text-gray-700">1099s are not required for the following:</span>
                  <ul className="text-gray-700">
                    <li>Vendors that are structured as a Corporation</li>
                    <li>Foreign contractors who are not based in the U.S.</li>
                    <li>
                      Contractors paid through your payroll provider (like Gusto & Rippling will likely take care of
                      these filings for you)
                    </li>
                    <li>Vendors paid by credit card</li>
                  </ul>
                  <span className="text-gray-700">
                    Please upload W-9s for each vendor below. If you are unsure if your vendor requires a 1099, add
                    the vendor below and Fondo will determine for you.<br /><b>Upon completion, please click the submit
                    button below.</b>
                  </span>
                </p>
                <small className="text-muted">
                  <i className="fe fe-info"></i>
                  Record Keeping Tip: If you haven't done so already, you should have each vendor and contractor
                  you paid more than $600 over the year (excluding employees) send you a completed form W-9.
                  This will allow you to collect and keep on file all necessary information you could need for filings
                  like the 1099. It is a good idea to keep updated W-9s for all your vendors and contractors. In the
                  event of an audit, tax authorities, like the IRS, may examine these type of supporting records.
                  You can find a blank W-9 form here:
                  <a
                    href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
                    target="_blank"
                    rel="noopener">https://www.irs.gov/pub/irs-pdf/fw9.pdf
                  </a>
                </small>

              </div>
            </div>

          </div>
        </div>

        <div className="text-center">
          <a className="btn btn-sm btn-primary mb-3" target="_blank" onClick={(event) => {
            event.preventDefault();
            navigate(DOCUMENTS);
          }}>
            Upload W9s
            <i className="fe fe-external-link"></i>
          </a>
        </div>
        {get1099FilingsQuery.isFetching
          ? <div className='text-center'><Spinner className='text-primary' /></div> : renderVendors()}

        <hr />
        <div className="mb-5 pb-5">
          <button className="btn btn-primary float-end" onClick={() => { setShowSubmitConfirmationModal(true); }}>
            Submit
          </button>
        </div>

        <Modal className="modal-md" show={showVendorModal} onHide={() => setShowVendorModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title className='h5 mb-0'>{editVendor ? "Edit" : "Add"} Vendor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Filings1099VendorForm
              editVendor={editVendor}
              vendor={selectedVendor}
              taxYear={taxYear || ""}
              successCallback={() => onModalSuccessCallback()}
            />
          </Modal.Body>
        </Modal>

        <ConfirmationModal
          show={showSubmitConfirmationModal}
          handleClose={() => setShowSubmitConfirmationModal(false)}
          handleConfirm={onSubmit}
          titleText="Submit 1099 Filings"
          bodyText="Are you sure you want submit your 1099 Filings?"
          cancelButtonText="Close"
        />

        <FondoToast
          show={showSubmitSuccessToast}
          handleClose={() => setShowSubmitSuccessToast(false)}
          title='Congratulations!'
          message='Your 1099 Filing Form has been submitted successfully.'
        />
      </div>
    </FondoLayout>
  );
}
