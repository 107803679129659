import { Alert, Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { DepositInformation } from "../payment/payment.type";
import { getDepositPaid } from "../payment/payment.api";
import { LoggedInUserContext } from "../../context/LoggedInUserContext";
import { CompanyLevelSettingsContext } from "../../context/FeatureFlagsContext";

type PreliminaryDataBannerProps = {
  showUpgradeYourAccountText: boolean;
  collapseBannerContent: boolean;
  setCollapseBannerContent: (value: boolean) => void;
};

const PreliminaryDataBanner = ({
  showUpgradeYourAccountText,
  collapseBannerContent,
  setCollapseBannerContent,
}: PreliminaryDataBannerProps) => {
  const { currentDashboardOnboarding } = useContext(LoggedInUserContext);
  const { showNewCheckoutPage } = useContext(CompanyLevelSettingsContext);

  const { data: depositInfo } = useQuery<DepositInformation, Error>(["depositInfo"], getDepositPaid, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const onboardingCheckoutUrl = showNewCheckoutPage ? "/onboarding/checkout" : "/onboarding/product";

  const getProductsRedirectUrl = () => {
    let redirectUrl = "";
    if (depositInfo && depositInfo.hasPaidDeposit) {
      redirectUrl = "/dashboard/products/";
    } else {
      redirectUrl = currentDashboardOnboarding ? "/onboarding/company1" : onboardingCheckoutUrl;
    }
    return redirectUrl;
  };

  return (
    <Alert className="bg-primary-soft text-primary p-4">
      <div className="d-flex justify-content-between">
        <h4 className="mb-0">
          <FeatherIcon
            icon="alert-triangle"
            size="17"
            className="me-2 mt-n1"
          />
          Preliminary Data
        </h4>
        {collapseBannerContent && (
          <>
            <FeatherIcon
              onClick={() => setCollapseBannerContent(false)}
              icon="chevron-down"
              size="22"
              className="mt-n1"
              style={{ cursor: "pointer" }}
            />
          </>
        )}

      </div>
      {!collapseBannerContent && (
        <>
          <p className="small mt-3">
            Your P&L and Balance Sheet Dashboard currently shows preliminary data based
            on your connected accounts and verifications. Accounts like Treasury, Brokerage,
            and Payment Processors require manual syncing. Please note these figures are
            estimates to provide directional finance insights.
          </p>
          {showUpgradeYourAccountText && (
            <>
              <hr className="text-primary" />
              <p className="small m-0">
                <strong>
                  Upgrade your account to Fondo Bookkeeping.{" "}
                </strong>
                To get accurate and verified
                 financials, you may upgrade your account to Fondo Bookkeeping where your dedicated
                 team will provide you with monthly, quarterly or annual financial statements.
                To ensure accuracy and get your financial data verified by your Fondo accountant,{" "}
                <Alert.Link href={getProductsRedirectUrl()} className="text-primary">
                  click here.
                </Alert.Link>
              </p>
            </>
          )}
          <div className="text-end mt-3">
            <Button
              onClick={() => setCollapseBannerContent(true)}
              size="sm"
            >
              I understand
            </Button>
          </div>
        </>
      )}

    </Alert>
  );
};

export default PreliminaryDataBanner;
