import {
  RECIPIENT_GROUP,
  REPORTINGS,
  SEND_REPORT_TO_GROUPS,
  DOWNLOAD_REPORT,
  REPORTING_REFRESH,
} from "../../constants/network-calls";
import { deleteFondoApi, getFondoApi, patchFondoApi, postFondoApi } from "../../utils/util.api";
import {
  CreateRecipientGroupType,
  GroupsToSendType,
  RecipientGroupType,
  ReportingSubmission,
  ReportingType,
} from "./Reporting.type";

export const getAllReporting = async (): Promise<ReportingType[]> => {
  const result = await getFondoApi(REPORTINGS);
  return result as ReportingType[];
};

export const getReportPDF = async (report: ReportingType) => {
  const result = await getFondoApi(
    `${REPORTINGS}${report.uuid}${DOWNLOAD_REPORT}`,
    {},
    {
      responseType: "arraybuffer"
    }
  );
  return result;
}
export const getReportQuery = async (uuid: string): Promise<ReportingType> => {
  const result = await getFondoApi(`${REPORTINGS}${uuid}`);
  return result as ReportingType;
};

export const refreshReportingData = async (uuid: string): Promise<ReportingType> => {
  const result = await patchFondoApi(`${REPORTINGS}${uuid}${REPORTING_REFRESH}`, {});
  return result as ReportingType;
}

export const deleteReportQuery = async (uuid: string) => {
  const result = await deleteFondoApi(`${REPORTINGS}${uuid}`);
  return result;
}

export const editReportingQuery = async (data: Partial<ReportingType>) => {
  const result = await patchFondoApi(`${REPORTINGS}${data.uuid}/`, data);
  return result;
};

export const createNewReportingInfo = async (data: ReportingSubmission): Promise<ReportingType> => {
  const result = await postFondoApi(REPORTINGS, data);
  return result as ReportingType;
};

export const getRecipientGroups = async (): Promise<RecipientGroupType[]> => {
  const result = await getFondoApi(RECIPIENT_GROUP);
  return result as RecipientGroupType[];
};

export const getRecipientGroupItem = async (id: number): Promise<RecipientGroupType> => {
  const result = await getFondoApi(`${RECIPIENT_GROUP}${id}`);
  return result as RecipientGroupType;
};

export const removeRecipientGroup = async (recipientId: number) => {
  const result = await deleteFondoApi(`${RECIPIENT_GROUP}${recipientId}`);
  return result;
};

export const createRecipientGroup = async (recipientGroup: CreateRecipientGroupType) => {
  const result = await postFondoApi(RECIPIENT_GROUP, recipientGroup);
  return result;
};

export const editRecipientGroup = async (recipientGroup: RecipientGroupType) => {
  const result = await patchFondoApi(`${RECIPIENT_GROUP}${recipientGroup.id}/`, recipientGroup);
  return result;
};

export const sendReportToGroups = async ({ reportingUuid, reportInfo }:  GroupsToSendType) => {
  const result = await postFondoApi(`${REPORTINGS}${reportingUuid}${SEND_REPORT_TO_GROUPS}`, reportInfo);
  return result;
};
