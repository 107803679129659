import { Button } from "react-bootstrap";
import { ActionItemsService, ActionItemsUserServicesData } from "./actionItems.type";
import ActionItemListViewCard from "./ActionItemListViewCard";

type ActionItemsOnboardingListViewProps = {
    actionItemsUserServicesData: ActionItemsUserServicesData;
    switchToGuidedView: () => void;
};

const ActionItemsOnboardingListView = ({
  actionItemsUserServicesData, switchToGuidedView,
}: ActionItemsOnboardingListViewProps) => (
  <>
    <div style={{ display: "flex", flexDirection: "row-reverse" }} >
      <Button variant="white" size="sm" onClick={switchToGuidedView}>
        View Guided
      </Button>
    </div>
    {actionItemsUserServicesData?.services?.map((actionItemsService: ActionItemsService, index: number) => (
      <ActionItemListViewCard key={actionItemsService.id} index={index} actionItemsService={actionItemsService} />
    ))}
  </>
);

export default ActionItemsOnboardingListView;
