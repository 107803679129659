import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Button, Col, Row } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { CONNECT_PAYROLL_MANUALLY, REACTIFY_API, SYNC_LINKED_ACCOUNT } from "../../../constants/network-calls";
import SalesCard from "../../dashboard-onboarding/sales/SalesCard";
import TaxDeadlineCardTable from "./TaxDeadlineTable";
import taxPassServiceIllustration from "../../dashboard-onboarding/sales/illustrations/taxPassServiceImg.png";
import { LoggedInUserContext } from "../../../context/LoggedInUserContext";
import { getCustomerServicesRequired } from "../../customer-order/customerOrder.api";
import { DepositInformation } from "../../payment/payment.type";
import { getDepositPaid } from "../../payment/payment.api";
import { darkBlueText } from "../../dashboard-onboarding/sales/utils";
import { Payroll } from "../financeDashboard.type";
import { getPayrollData } from "../FinanceDashboard.api";
import InternationalActivityTable from "./InternationalActivityTable";
import AddPayrollDataAlert from "./AddPayrollDataAlert";
import TaxPlanNetIncomeBreakdownCard from "./TaxPlanNetIncomeBreakdownCard";
import LoadingTaxPlanNetIncomeBreakdownCard
  from "../profit-and-loss/loading-state-components/LoadingTaxPlanNetIncomeBreakdownCard";
import RealTimeProgressManagerStatusCard from "../../fusion-kit/RealTimeProgressManagerStatus";
import LoadingAssetsAndLiabilitiesBreakdownCard
  from "../profit-and-loss/loading-state-components/LoadingAssetsAndLiabilitiesBreakdownCard";
import TaxPlanAssetsAndLiabilitiesBreakdownCard from "./TaxPlanAssetsAndLiabilitiesBreakDownCard";
import { CompanyLevelSettingsContext } from "../../../context/FeatureFlagsContext";
import CorporateTaxSummaryCardsRow from "./CorporateTaxSummaryCardsRow";

const TaxPlan = () => {
  const { currentCompanyId, currentDashboardOnboarding } = useContext(LoggedInUserContext);
  const { showNewCheckoutPage } = useContext(CompanyLevelSettingsContext);
  const [verifySyncProgress, setVerifySyncProgress] = useState(true);
  const { data: customerServicesRequired } = useQuery<any, Error>(
    ["customerServicesRequired", currentCompanyId],
    () => getCustomerServicesRequired(currentCompanyId || ""),
    { enabled: !!currentCompanyId },
  );

  const { data: depositInfo } = useQuery<DepositInformation, Error>(["depositInfo"], getDepositPaid, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const hasTaxPass = customerServicesRequired
  && customerServicesRequired.length > 0
  && customerServicesRequired[0].servicesRequired.some(
    (service: string) => service.includes("Tax Pass"),
  );

  const onboardingCheckoutUrl = showNewCheckoutPage ? "/onboarding/checkout" : "/onboarding/product";

  const getModalRedirectUrl = () => {
    let redirectUrl = "";
    if (depositInfo && depositInfo.hasPaidDeposit) {
      redirectUrl = "/dashboard/products/";
    } else {
      redirectUrl = currentDashboardOnboarding ? "/onboarding/company1" : onboardingCheckoutUrl;
    }
    return redirectUrl;
  };

  const {
    data: payrollData,
    isLoading: payrollDataIsLoading,
    isFetching: payrollDataIsFetching,
  } = useQuery<Payroll, Error>(
    ["payrollData"],
    () => getPayrollData(2023),
    {
    refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      {!payrollData && !payrollDataIsLoading && !verifySyncProgress && (
        <div className="my-4">
          <AddPayrollDataAlert />
        </div>
      )}
      {verifySyncProgress && (
        <RealTimeProgressManagerStatusCard
          processingTitle={"Processing Payroll data..."}
          elementsToProcessText={"Total tasks"}
          elementsProcessedText={"Tasks completed"}
          verifyProgress={verifySyncProgress}
          setVerifyProgress={setVerifySyncProgress}
          progressStatusURL={`${REACTIFY_API}${SYNC_LINKED_ACCOUNT}?category=hris`}
          queriesToRefresh={["payrollData", "companyFilingsDeadlines"]}
        />
      )}

      <div className="my-4">
        <p className="fs-2 my-4">Corporate Tax Summary</p>
        <CorporateTaxSummaryCardsRow
          payrollData={payrollData}
          payrollDataIsLoading={payrollDataIsLoading}
        />
        <hr />

      </div>

      <div className="my-4">
        <div className="d-flex align-items-center justify-content-between mb-4">

          <p className="fs-2 mb-0">Financials</p>
          <Link to={`/${CONNECT_PAYROLL_MANUALLY}`}>
            <Button size="sm" variant="light" className="border ms-3">
              <FeatherIcon icon="database" size="17" className="me-2" />
              Edit TaxPlan Data
            </Button>
          </Link>
        </div>
        <Row>
          <Col>
            {(payrollDataIsLoading) ? (
              <LoadingTaxPlanNetIncomeBreakdownCard />
            ) : (
              <TaxPlanNetIncomeBreakdownCard payroll={payrollData as Payroll} />
            )}
          </Col>
          <Col>
            {(payrollDataIsLoading) ? (
              <LoadingAssetsAndLiabilitiesBreakdownCard
                nameOverride="Balance Sheet"
              />
            ) : (
              <TaxPlanAssetsAndLiabilitiesBreakdownCard
                payroll={payrollData as Payroll}
              />
            )}
          </Col>
        </Row>
        <hr />
      </div>
      {!hasTaxPass && customerServicesRequired && (
        <div className="my-4">
          <p className="fs-2">Jurisdictions</p>
          <SalesCard
            serviceName="TaxPass"
            serviceDescription={`Get your Delaware Franchise Tax, 
            Federal & State Corporate Income Taxes filed.`}
            illustration={taxPassServiceIllustration}
            modalHeaderTitle="TaxPass"
            modalHeaderDescription={
              <span>
                TaxPass is an annual subscription that covers your filings
                for the Delaware Franchise Tax, State Corporate Income Tax,
                and Federal Corporate Income Tax deadlines.
              </span>
            }
            modalServiceDetailTitle="TaxPass"
            modalServiceDetailDescription={
              <p>
                Simplify the tax filing process with Fondo TaxPass.
                Our seamless app allows you to conveniently handle your tax filings,
                including DE Franchise taxes, all in one place.
                <span className="fw-bold" style={darkBlueText}>
                  Say goodbye to endless email chains and experience a
                  hassle-free tax preparation journey.
                </span>
              </p>
            }
            modalRedirectUrl={getModalRedirectUrl()}
            addMarginToSides={false}
          />
          <hr />
        </div>
      )}
      <div className="my-4">
        <TaxDeadlineCardTable />
      </div>
      <div className="my-4">
        <InternationalActivityTable
          payrollData={payrollData}
          payrollDataIsFetching={payrollDataIsFetching}
        />
      </div>
    </>
  );
};

export default TaxPlan;
