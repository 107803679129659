import { Col, Row } from "react-bootstrap";

type FondoBGEllipseLayoutProps = {
    title: string;
    subtitle?: string;
};

const FondoBGEllipseLayout = (
  { title, subtitle }: FondoBGEllipseLayoutProps,
) => (
        <div className="pt-7 pb-8 bg-dark bg-ellipses">
            <div className="container-fluid">
                <Row className="justify-content-center">
                    <Col className="col-md-10 col-lg-8 col-xl-6">
                        <h1 className="display-3 text-center text-white">{title}</h1>
                        {subtitle
                            && <p className="lead text-center text-muted">
                                {subtitle}
                            </p>
                        }
                    </Col>
                </Row>
            </div>
        </div>
);

export default FondoBGEllipseLayout;
