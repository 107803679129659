import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { TokenInput } from "./bankingIntegration.type";
import { getToken } from "./bankingIntegration.api";
import OnSuccessIntegration from "./OnSuccessIntegration";
import FondoToast from "../fusion-kit/FondoToast";
import Integrations from "../integrations/Integrations";
import { INTEGRATIONS } from "../../constants/network-calls";

export default function BankingIntegrationCallback() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [client, setClient] = useState<string>("");
  const [hasError, setHasError] = useState(false);
  const effectExecuted = useRef(false);  

  /* 
      step 3 - Once the user is redirected back to the web app,
      extract the code from the URL and exchange it for the access token
  */
  useEffect(() => {
    if (effectExecuted.current) return;
    effectExecuted.current = true;

    const fetchToken = async (code: string) => {
      const stateFromStorage = localStorage.getItem("x-provider-state");
      const realmId = searchParams.get("realmId");
      const stateFromUrl = searchParams.get("state");

      if (!stateFromStorage || stateFromStorage !== stateFromUrl) {
        setHasError(true);
      } else {
        const [clientId, , redirectUri] = stateFromStorage.split(";");

        const tokenInput: TokenInput = {
          grantType: "authorization_code",
          code,
          clientId,
          redirectUri,
          realmId,
        };

        try {
          await getToken(tokenInput);
          localStorage.removeItem("x-provider-state");
          setClient(clientId);
        } catch {
          setHasError(true);
        }
      }
    };

    const code = searchParams.get("code");
    if (code) {
      fetchToken(code);
    } else {
      setHasError(true);
    }
    window.history.replaceState(null, document.title, INTEGRATIONS);
  }, [searchParams]);

  return client && !hasError ? (
    <OnSuccessIntegration clientId={client} />
  ) : (
    <>
      <Integrations />
      <FondoToast
        show={hasError}
        handleClose={() => setHasError(false)}
        error={hasError}
        message={hasError ? "Connecting account failed! Please try again later." : "Successfully connected account!"}
      />
    </>
  );
}
