import React from "react";

interface PassportContextType {
    passportOpen: boolean;
    setPassportOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PassportContext = React.createContext<PassportContextType>({
  passportOpen: false,
  setPassportOpen: () => undefined,
});
