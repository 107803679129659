import { FormikContextType } from "formik";
import FondoFormGroup from "./FondoFormGroup";
import { FieldTypes } from "./formTypes";
import { followPath } from "./utils";

type AddressFieldGroupProps = {
  formikContext: FormikContextType<any>;
  baseName?: string;
  labels?: {[key: string]: string;}
  types?: {[key: string]: FieldTypes;}
  defaultCountryCode?: string;
  names?: {[key: string]: string;}
}

const AddressFieldGroup = ({
  formikContext, baseName, labels, types, defaultCountryCode,
}: AddressFieldGroupProps) => {
  const defaultFieldTypes = {
    country: FieldTypes.COUNTRY,
    streetAddress: FieldTypes.TEXT,
    city: FieldTypes.TEXT,
    state: FieldTypes.STATE,
    postalCode: FieldTypes.TEXT,
  };

  const defaultFieldLabels = {
    country: "Country",
    streetAddress: "Street Address",
    city: "City",
    state: "State/Province",
    postalCode: "Postal Code",
  };

  const getSelectedCountry = (): string | undefined => {
    if (!baseName) {
      return formikContext.values.country;
    }
    const path = baseName.split(".");
    path.push("country");
    return followPath(formikContext.values, path);
  };

  return (
    <>
      {!defaultCountryCode && <FondoFormGroup
        fieldName={`${baseName || ""}${baseName ? "." : ""}state`}
        types={types || { state: FieldTypes.COUNTRY }}
        labels={labels || { state: "Country" }}
        formikContext={formikContext}
      />}
      <FondoFormGroup
        fieldName={`${baseName || ""}${baseName ? "." : ""}streetAddress`}
        types={types || defaultFieldTypes}
        labels={labels || defaultFieldLabels}
        formikContext={formikContext}
      />
      <FondoFormGroup
        fieldName={`${baseName || ""}${baseName ? "." : ""}city`}
        types={types || defaultFieldTypes}
        labels={labels || defaultFieldLabels}
        formikContext={formikContext}
      />
      <FondoFormGroup
        fieldName={`${baseName || ""}${baseName ? "." : ""}state`}
        types={types || defaultFieldTypes}
        labels={labels || defaultFieldLabels}
        formikContext={formikContext}
        selectedCountry={defaultCountryCode || getSelectedCountry()}
      />
      <FondoFormGroup
        fieldName={`${baseName || ""}${baseName ? "." : ""}postalCode`}
        types={types || defaultFieldTypes}
        labels={labels || defaultFieldLabels}
        formikContext={formikContext}
      />
    </>
  );
};

export default AddressFieldGroup;
