import { CardGroup } from "react-bootstrap";
import { CompoundGrowthRatePeriod, GrowthRatePeriod, ProfitAndLossPeriod } from "../financeDashboard.type";
import ProfitAndLossSparkChartsCard from "./ProfitAndLossSparkChartsCards";
import GrowthRateSparkChartCard from "./GrowthRateSparkChartCard";

type ProfitAndLossSparkChartsCardRowProps = {
  historicalProfitAndLoss: ProfitAndLossPeriod[];
  historicalGrowthRate?: GrowthRatePeriod[];
  historicalCompoundGrowthRate?: CompoundGrowthRatePeriod[];
  qboNetIncome?: number[];
  showGrowthRate?: boolean;
  showCompoundGrowthRate?: boolean;
}

const ProfitAndLossSparkChartsCardRow = ({
  historicalProfitAndLoss,
  historicalGrowthRate,
  historicalCompoundGrowthRate,
  qboNetIncome,
  showGrowthRate,
  showCompoundGrowthRate,
}: ProfitAndLossSparkChartsCardRowProps) => {
  const incomeAmounts = historicalProfitAndLoss.map((item) => item.revenueAmount);
  const expensesAmounts = historicalProfitAndLoss.map((item) => item.expensesAmount);
  const netIncomeAmounts = qboNetIncome || historicalProfitAndLoss.map(
    (item) => item.revenueAmount - item.expensesAmount
  );
  const growthRates = historicalGrowthRate?.map((period) => period.growthRate);
  const compoundGrowthRates = historicalCompoundGrowthRate?.map((period) => period.compoundGrowthRate);

  return (
    <CardGroup style={ { gap: "1rem" } } className="mb-4">
      <ProfitAndLossSparkChartsCard data={incomeAmounts} title="Revenue" />
      <ProfitAndLossSparkChartsCard data={expensesAmounts} title="Expenses" isExpenses={true} />
      <ProfitAndLossSparkChartsCard data={netIncomeAmounts} title="Net Income" />
      {showGrowthRate && growthRates && (
        <GrowthRateSparkChartCard data={growthRates} title="Growth Rate" />
      )}
      {showCompoundGrowthRate && compoundGrowthRates && (
        <GrowthRateSparkChartCard data={compoundGrowthRates} title="Compound Growth Rate" />
      )}
    </CardGroup>
  );
};

export default ProfitAndLossSparkChartsCardRow;
