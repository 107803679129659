import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Modal, Button } from "react-bootstrap";
import { 
  DescriptionType, 
  SectionType, 
  sectionTypeKeys, 
  ProfitAndLossOptions, 
  RunwayOptions, 
  ExecutiveSummaryOptions, 
  BalanceSheetOptions 
} from "../Reporting.type";

import { editReportingQuery } from "../Reporting.api";
import ReportSectionForm from "./ReportSectionForm";
import { sectionNames } from "../utils";


type ReportingOptionsModalProps = {
  sectionType: SectionType;
  show: boolean;
  onHide: () => void;
  initialOptions: RunwayOptions | ExecutiveSummaryOptions | BalanceSheetOptions | ProfitAndLossOptions;
  reportUuid: string;
  onUpdate: (options: RunwayOptions | ExecutiveSummaryOptions | BalanceSheetOptions | ProfitAndLossOptions) => void;
};

const ReportingOptionsModal = ({
  sectionType,
  show,
  onHide,
  initialOptions,
  reportUuid,
  onUpdate,
}: ReportingOptionsModalProps) => {

  const [options, setOptions] = useState<
    RunwayOptions | 
    ExecutiveSummaryOptions | 
    BalanceSheetOptions | 
    ProfitAndLossOptions
  >(initialOptions);
  
  const handleChange = (key: any) => {
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      [key]: !prevOptions[key],
    }));
  };

  const updateOptionsMutation = useMutation(editReportingQuery, {
    onSuccess: () => {
      onUpdate(options);
      onHide();
    },
  });

  const handleSubmit = async () => {
    await updateOptionsMutation.mutateAsync({ uuid: reportUuid, [sectionTypeKeys[sectionType]]: options });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit {sectionNames[sectionType as keyof typeof sectionNames]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReportSectionForm
          options={options as { [key: string]: DescriptionType | boolean}}
          handleChange={handleChange}
          setOptions={setOptions}
        />
        {updateOptionsMutation.isError && (
          <p className="text-danger">Failed to update options. Please try again.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={updateOptionsMutation.isLoading}>
          {updateOptionsMutation.isLoading ? "Saving..." : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportingOptionsModal;
