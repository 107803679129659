import { FormikContextType } from "formik";
import {
  delawareFranchiseTaxFieldToTypeMap,
  getDelawareFranchiseTaxFieldToLabelMap,
} from "../DelawareFranchiseTax.type";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";

type IncorporationInfoSectionProps = {
  formikContext: FormikContextType<any>;
  taxYear: string|number;
}

const IncorporationInfoSection = ({ formikContext, taxYear }: IncorporationInfoSectionProps) => (
  <>
    <FondoFormGroup
      formikContext={formikContext}
      fieldName="incorporationInfo.einEnc"
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
    />
  </>
);

export default IncorporationInfoSection;
