import { useQuery } from "@tanstack/react-query";
import { getCompanyLevelSettings } from "../account/account.api";
import IframeWrapper from "../iframe-wrapper/IframeWrapper";
import ProductsPage from "./Post2023Products/ProductsPage";

const ProductParentPage = () => {
  const { data } = useQuery(["companyLevelSettings"], getCompanyLevelSettings);
  return (
    <>
      {data?.showNewProductPage && <ProductsPage />}
      {!data?.showNewProductPage && <IframeWrapper />}
    </>
  );
};

export default ProductParentPage;
