import {
  API_INTEGRATION_ITEMS,
  CREATE_MERGE_LINK_TOKEN,
  RETRIEVE_MERGE_ACCOUNT_TOKEN,
  MERGE_DEV_LINKED_ACCOUNTS,
  AMOUNT_OF_CONNECTED_ACCOUNTS,
  SYNC_LINKED_ACCOUNT,
  API_INTEGRATION_ACCOUNTS,
  API_INTEGRATIONS_SOURCES_LINKED_ACCOUNTS,
} from "../../constants/network-calls";
import {
  IntegrationContext,
  Institution,
  LinkedAccount,
  LinkToken,
  CompanyExternalConnections,
  ExchangePlaidPublicToken,
} from "./integrations.type";
import { ProgressManagerStatus } from "../../common/type";
import {
  deleteFondoApi, getApiClient, getFondoApi, patchFondoApi, postFondoApi,
} from "../../utils/util.api";

export async function getInstitutions(): Promise<Institution[]> {
  const result = await getFondoApi(API_INTEGRATION_ITEMS);
  return result as Institution[];
}

export async function amountOfConnectedAccounts(): Promise<number> {
  const result: any = await getFondoApi(AMOUNT_OF_CONNECTED_ACCOUNTS);
  return result;
}

export async function getMergeDevLinkToken(category: string): Promise<LinkToken> {
  const result = await getFondoApi(CREATE_MERGE_LINK_TOKEN, { category });
  return result as LinkToken;
}

export async function retrieveMergeDevAccountToken(publicToken: string): Promise<IntegrationContext> {
  const result = await postFondoApi(RETRIEVE_MERGE_ACCOUNT_TOKEN, { publicToken });
  return result as IntegrationContext;
}

export async function getMergeDevLinkedAccounts(category: string): Promise<LinkedAccount[]> {
  const result = await getFondoApi(MERGE_DEV_LINKED_ACCOUNTS, { category });
  return result as LinkedAccount[];
}

export async function syncLinkedAccount(category: string): Promise<ProgressManagerStatus> {
  const result = await postFondoApi(SYNC_LINKED_ACCOUNT, { category });
  return result as ProgressManagerStatus;
}

export async function markBankAccountAsClosed(
  accountUuid: string,
  accountClosedOn: string,
): Promise<any> {
  const result = await patchFondoApi(
    `${API_INTEGRATION_ACCOUNTS}${accountUuid}/close_account/`,
    { closedAt: accountClosedOn },
  );
  return result;
}

export async function deleteBankAccount(
  accountUuid: string,
): Promise<any> {
  const result = deleteFondoApi(`${API_INTEGRATION_ACCOUNTS}${accountUuid}/`);
  return result;
}

export async function getCompanyExternalConnections(
): Promise<CompanyExternalConnections> {
  const apiClient = await getApiClient();
  const result = await apiClient.retrieveReactifyCompanyExternalConnections();
  return result.data;
}

export async function getSourceLinkedAccounts(source: string): Promise<LinkedAccount[]> {
  const result = await getFondoApi(API_INTEGRATIONS_SOURCES_LINKED_ACCOUNTS, { source });
  return result as LinkedAccount[];
}

export async function createPlaidLinkToken(accessToken?: string): Promise<string> {
  const apiClient = await getApiClient();
  const result = await apiClient.createReactifyPlaidCreateLinkToken(null, {accessToken})
  return result.data;
}

export async function exchangePlaidPublicToken(payload: ExchangePlaidPublicToken): Promise<any> {
  const apiClient = await getApiClient();
  const result = await apiClient.createReactifyPlaidExchangePublicToken(null, payload)
  return result
}
