import BankAccountList from "./BankAccountList";
import PlaidConnectionButton from "./PlaidConnectionButton";

const BankAccountsIntegrationCard = () => (
        <div>
            <div className="card" style={{ position: "static" }}>
                <div className="card-body">
                    <h1 className="text-uppercase text-center text-muted my-4">
                        <i className="fe fe-lock"></i>
                    </h1>
                    <div className="row no-gutters align-items-center justify-content-center">
                        <div className="col-auto">
                            <div className="h1 mb-0">Bank Accounts</div>
                        </div>
                    </div>
                    <BankAccountList />
                    <PlaidConnectionButton buttonContent={"Add Bank Accounts"}/>
                </div>
            </div>
            <div className="mx-2">
                <p>
                    The confidentiality and security of your information remains a top priority for Fondo. Please note
                    that our {" "}
                    <a href="https://tryfondo.com/fondo-terms" target="_blank">
                        terms of service (section 3)
                    </a>{" "}
                    includes a Non-Disclosure and Confidentiality Agreement between Fondo and you.
                </p>
            </div>
        </div>
);

export default BankAccountsIntegrationCard;
