import { useState } from "react";
import { Button, CardGroup } from "react-bootstrap";
import NetIncomeBreakdownCard from "../../finance-dashboard/profit-and-loss/NetIncomeBreakdownCard";
import ProfitAndLossSparkChartsCard from "../../finance-dashboard/profit-and-loss/ProfitAndLossSparkChartsCards";
import ProfitAndLossChartCard from "../../finance-dashboard/profit-and-loss/ProfitAndLossChartCard";
import { ProfitAndLossPeriod, TimeRange } from "../../finance-dashboard/financeDashboard.type";
import { defaultProfitAndLossOptions, ProfitAndLossOptions, ReportingType } from "../Reporting.type";
import QBOTable from "../QBOTable/QBOTable";
import ReportingOptionsModal from "./ReportingOptionsModal";
import { mapMonthDateToMonth, mapMonthDateToQuarter } from "../../finance-dashboard/constants";
import { formatPeriodInHumanReadableText, getNetIncomeFromQboData } from "../utils";
import QboNetIncomeBreakdownCard from "../../finance-dashboard/profit-and-loss/QboNetIncomeBreakdownCard";

type ReportingProfitAndLossProps = {
  report: ReportingType;
};


const ReportingProfitAndLoss = ({ report }: ReportingProfitAndLossProps) => {
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [options, setOptions] = useState<ProfitAndLossOptions>(
    { ...defaultProfitAndLossOptions, ...report.profitAndLossOptions }
  );
  const { groupedBy, timeframeEnd } = report;
  const qboReportingData = report.reportingData.profitAndLoss;
  
  const selectedMonthWords = formatPeriodInHumanReadableText(report);

  const createTimeRangeFromDate = (interval: string, dateString: string): TimeRange => {
    // dateString is formatted as "YYYY-MM-DD"
    const [year, month] = dateString.split("-");
    const timeRange: TimeRange = {interval, year};
    if (interval === "MONTH") {
      timeRange.timePeriod = mapMonthDateToMonth[month];
    } else if (interval === "QUARTER") {
      timeRange.timePeriod = mapMonthDateToQuarter[month];
    } else {
      timeRange.timePeriod = "Year of";
    }
    return timeRange;
  }

  const createHistoricalProfitAndLoss = (): ProfitAndLossPeriod[] => {
    const qboHistoricalProfitAndLoss: ProfitAndLossPeriod[] = [];
    qboReportingData.headers.slice(0, -1).forEach((header, index) => {
      const incomeAmount = qboReportingData.results.find(
        (item) => item.baseName?.toLocaleLowerCase() === "income")?.amount[index];
      const expensesAmount = qboReportingData.results.find(
        (item) => item.baseName?.toLocaleLowerCase() === "expenses")?.amount[index];
      const revenue = Number(incomeAmount || 0);
      const expenses = Number(expensesAmount || 0);
      const qboPNL = {
        period: header.name,
        revenueAmount: revenue,
        expensesAmount: expenses,
      }
      qboHistoricalProfitAndLoss.push(qboPNL);
    })
    return qboHistoricalProfitAndLoss;
  }

  const qboHistoricalProfitAndLoss = createHistoricalProfitAndLoss();
  const incomeAmounts = qboHistoricalProfitAndLoss.map((item) => item.revenueAmount);
  const expensesAmounts = qboHistoricalProfitAndLoss.map((item) => item.expensesAmount);

  let descriptionContent = null;
  if (options?.description?.enabled) {
    if (options.description.text?.trim()) {
      descriptionContent = options.description.text;
    } else {
      descriptionContent = `Below is a breakdown of your profit and loss for ${selectedMonthWords}`;
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>Profit and Loss</h2>
        <div>
          {<Button size="sm" className="px-4" onClick={() => setShowOptionsModal(true)}>Edit</Button>}
        </div>
      </div>
      <p className="text-secondary">{descriptionContent}</p>
      {options.breakdown && (
        <QboNetIncomeBreakdownCard
          historicalProfitAndLoss={report.reportingData.profitAndLoss}
        />
      )}

      <CardGroup style={{ gap: "1rem" }} className="mb-4">
        {options.revenue && (
          <ProfitAndLossSparkChartsCard data={incomeAmounts} title="Revenue" />
        )}
        {options.expenses && (
          <ProfitAndLossSparkChartsCard data={expensesAmounts} title="Expenses" isExpenses={true} />
        )}
        {options.netIncome && (
          <ProfitAndLossSparkChartsCard
            data={getNetIncomeFromQboData(report.reportingData.profitAndLoss)}
            title="Net Income"
          />
        )}
      </CardGroup>

      {options.chart && (
        <>
        <ProfitAndLossChartCard historicalProfitAndLoss={qboHistoricalProfitAndLoss} />
        </>
      )}
      {options.table && (
        <QBOTable tableName={"Profit and Loss"} qboReportingData={qboReportingData} />
      )}
      <ReportingOptionsModal
        sectionType = "profitAndLoss"
        show={showOptionsModal}
        onHide={() => setShowOptionsModal(false)}
        initialOptions={options}
        reportUuid={report.uuid}
        onUpdate={(newOptions) => {
          setOptions(newOptions as ProfitAndLossOptions);
        }}
      />
      <div id="pandl-data-loaded" />
    </>
  );
}

export default ReportingProfitAndLoss;
