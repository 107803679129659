import { COMPANY_LEVEL_SETTINGS, LOGGED_IN_USER } from "../../constants/network-calls";
import { CompanyLevelSettingsContextType, LoggedInUserContextType } from "../../context/context.type";
import { getFondoApi } from "../../utils/util.api";

export async function getLoggedInUser(): Promise<LoggedInUserContextType> {
  const result = await getFondoApi(LOGGED_IN_USER);
  return result as LoggedInUserContextType;
}

export async function getCompanyLevelSettings(): Promise<CompanyLevelSettingsContextType> {
  const result = await getFondoApi(COMPANY_LEVEL_SETTINGS);
  return result as CompanyLevelSettingsContextType;
}
