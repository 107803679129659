import { convertNumberToCurrency } from "../../../utils/utils";
import { LineItemAPIData, RelatedProduct } from "../product/product.type";
import { DEPOSIT_AMOUNT } from "./constants";
import { lineItemsGroupedByFrequencyType, OrderLineItem } from "./products.type";

export const getProductFinalPrice = (product: LineItemAPIData) => {
  if (product.actualUnitPriceTextOverride) return product.actualUnitPriceTextOverride;
  if (product.price) return `$${convertNumberToCurrency(product.price)}`;
  if (product.price === "0") return "Free";
  return "";
};

export const getproductOriginalPrice = (product: LineItemAPIData) => {
  if (product.displayedUnitPriceTextOverride) return product.displayedUnitPriceTextOverride;
  if (
    product.displayedUnitPrice
  ) return `$${convertNumberToCurrency(product.displayedUnitPrice)}`;
  return "";
};

export const getTermsOfServiceUrl = (): string => {
  let url = "https://docs.google.com/document/d/e/2PACX-1vRqHqph2Jp5mp6xdjRLLtYdquj1TTyF4";
  url += "zZeH5qzW8B1qXrRqm2fxh9SFGHKMiwj_E2UZutqysQGuX1p/pub";
  return url;
};

export const getRelatedProducts = (
  addons: RelatedProduct[],
  lineItems: LineItemAPIData[],
) => addons.map(
  (addon: RelatedProduct) => lineItems.find(
    (lineItem: LineItemAPIData) => lineItem.product.uuid === addon.addonProductUuid,
  ),
).filter(Boolean) as LineItemAPIData[];

export const getLineItemsFormatedForTheOrder = (lineItems: LineItemAPIData[]) => {
  const lineItemsGroupedByFrequency: lineItemsGroupedByFrequencyType = {
    annuallyLineItems: [],
    monthlyLineItems: [],
    quarterlyLineItems: [],
    oneTimeLineItems: [],
  };

  // Build objects for each line Item with only the data that will be
  // displayed on the order, and assign them to the corresponding frequency.
  lineItems.forEach((lineItem: LineItemAPIData) => {
    // If the product has parentProducts, it means it is an addon.
    // We ignore them, since they are going to be added as children products
    // for another parent product.
    if (!lineItem.product.parentProducts?.length) {
      const formatedLineItem: OrderLineItem = {
        name: lineItem.product.displayName,
        finalPrice: getProductFinalPrice(lineItem),
        originalPrice: getproductOriginalPrice(lineItem),
        price: parseFloat(lineItem.price) || 0,
        displayedUnitPrice: parseFloat(lineItem.displayedUnitPrice) || 0,
        addons: [],
        textPrice: lineItem.actualUnitPriceTextOverride,
        helperText: lineItem.product.estimateHelperText,
      };

      if (lineItem.product.addons) {
        formatedLineItem.addons = getRelatedProducts(lineItem.product.addons, lineItems);
      }

      switch (lineItem.product.billingFrequency) {
        case "YEARLY":
          lineItemsGroupedByFrequency.annuallyLineItems.push(formatedLineItem);
          break;
        case "MONTHLY":
          lineItemsGroupedByFrequency.monthlyLineItems.push(formatedLineItem);
          break;
        case "QUARTERLY":
          lineItemsGroupedByFrequency.quarterlyLineItems.push(formatedLineItem);
          break;
        case "ONE_TIME":
          lineItemsGroupedByFrequency.oneTimeLineItems.push(formatedLineItem);
          break;
        default:
          break;
      }
    }
  });
  return lineItemsGroupedByFrequency;
};

export const getGroupedByFrequencyTotalPrices = (lineItems: OrderLineItem[]) => {
  const totalFinalPrice: number = lineItems.reduce((sum, lineItem) => {
    const itemPrice = lineItem.price || 0;
    const addonsPrice = lineItem.addons
      ? lineItem.addons.reduce((addonSum, addon) => addonSum + parseFloat(addon.price), 0)
      : 0;
    return sum + itemPrice + addonsPrice;
  }, 0);

  const totalActualPrice: number = lineItems.reduce((sum, lineItem) => {
    const itemActualPrice = lineItem.displayedUnitPrice || 0;
    const addonsActualPrice = lineItem.addons
      ? lineItem.addons.reduce((addonSum, addon) => addonSum + (
        parseFloat(addon.displayedUnitPrice) * addon.units
      ), 0) : 0;
    return sum + itemActualPrice + addonsActualPrice;
  }, 0);

  return {
    totalFinalPrice,
    totalActualPrice,
  };
};

export const getGroupedByFrequencyTextPrices = (lineItems: OrderLineItem[]): string[] => {
  const textAdditions: string[] = [];
  lineItems.forEach((lineItem) => {
    if (lineItem.textPrice) textAdditions.push(lineItem.textPrice);
  });
  return textAdditions;
};

export const getDepositAmount = (lineItems: LineItemAPIData[], orderTotalPrice: number) => {
  const lineItemWithFullDeposit = lineItems.find(
    (lineItem: LineItemAPIData) => lineItem.product.requiresFullDeposit === true,
  );
  if (lineItemWithFullDeposit) return DEPOSIT_AMOUNT;
  return Math.min(DEPOSIT_AMOUNT, orderTotalPrice);
};
