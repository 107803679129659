<script async type="module"></script>;

const loadBentoScript = () => {
  const script = document.createElement("script");
  script.async = true;
  script.type = "module";
  script.src = "https://assets.trybento.co/bento-embed.js";
  document.body.appendChild(script);
};

export default loadBentoScript;
