import { AccountingService } from "../../common/type";
import {
  CUSTOMER_SERVICES_REQUIRED, PURCHASED_ACCOUNTING_SERVICES, UNPURCHASED_ACCOUNTING_SERVICES,
} from "../../constants/network-calls";
import { getFondoApi } from "../../utils/util.api";

export async function getCustomerServicesRequired(companyId: string|number): Promise<string> {
  const result: any = await getFondoApi(CUSTOMER_SERVICES_REQUIRED, { companyId });
  return result;
}

export async function getUnpurchasedAccountingServices(): Promise<AccountingService[]> {
  const result: any = await getFondoApi(UNPURCHASED_ACCOUNTING_SERVICES);
  return result as AccountingService[];
}

export async function getPurchasedAccountingServices(): Promise<AccountingService[]> {
  const result: any = getFondoApi(PURCHASED_ACCOUNTING_SERVICES);
  return result as AccountingService[];
}
