import FeatherIcon from "feather-icons-react";
import { convertNumberToCurrency } from "../../../utils/utils";

type QboNetIncomeRowParams = {
  name: string;
  amount?: string;
  depth: number;
  bold?: boolean;
}

const QboNetIncomeRow = ({ name, amount, depth, bold }: QboNetIncomeRowParams) => {
  const padding = `${depth * 10}px`
  return (
    <div className={`d-flex justify-content-between ${bold ? "fw-bold" : ""}`}>
      <span style={{paddingLeft: padding}}>
        <FeatherIcon icon="chevron-right" size="1em" className="mt-n1 me-1" />
        {name}
      </span>
      <span>{amount !== undefined && `$${convertNumberToCurrency(amount)}`}</span>
    </div>
  )
}

export default QboNetIncomeRow;
