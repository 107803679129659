import { ActionItemsService } from "./actionItems.type";

export const calculatePercentageOfActionItemServiceCompletion = (actionItemService: ActionItemsService) => {
  const percentage = (1 - actionItemService.numberOfPendingActionItems / actionItemService.numberOfActionItems)
    * 100 || 0;
  return Math.round(percentage);
};

export const isActionItemNotStarted = (
  actionItemService: ActionItemsService,
) => actionItemService?.numberOfPendingActionItems === actionItemService?.numberOfActionItems;

export const isActionItemCompleted = (
  actionItemService: ActionItemsService,
) => actionItemService?.numberOfPendingActionItems === 0;

export const mapFormTypeToUrl: { [key: string]: any } = {
  "Foreign Subsidiary Tax Year": "foreign_subsidiary",
  "Corp Tax Onboarding": "corp_tax_onboarding",
  "DE Franchise Tax Onboarding": "delaware_franchise_tax",
  "Tax Credit Onboarding": "questionnaire/f/r_and_d",
  1099: "form_1099",
};

export const getUrlForFormActionItem = (formType: string, formTypeTaxYear: number) => {
  // Returns the url for the different forms.
  // Ideally, we will have all the forms following the same name + year
  // format but right now we don't. For example, R&D for 2022 is 'r_and_d_2022'
  // while 2021 is 'r_and_d'.
  // The other ones, like corp tax onboarding have a format like <form>/<year>
  let url = mapFormTypeToUrl[formType];
  if (url === "questionnaire/f/r_and_d") {
    if (formTypeTaxYear === 2021) {
      return `/${url}`;
    }
    url += `_${formTypeTaxYear}`;
    return `/${url}`;
  }
  url += `/${formTypeTaxYear}`;
  return `/${url}/`;
};
