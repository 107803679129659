import { useState } from "react";
import {
  Button,
  Card,
  Form,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import DateSelectorFilter from "../../../fusion-kit/DateRangeFilter";
import { DateFilterTypes } from "../../financeDashboard.type";

type DateFilterParams = {
  startDateValue?: string|undefined;
  endDateValue?: string|undefined;
  handleDateFilters: (filters: DateFilterTypes) => void;
  setSelectedOption: (value: string|null) => void;
}

const DateFilter = (
  {
    startDateValue,
    endDateValue,
    handleDateFilters,
    setSelectedOption,
  } : DateFilterParams,
) => {
  const [startDate, setStartDate] = useState<string|undefined>(startDateValue);
  const [endDate, setEndDate] = useState<string|undefined>(endDateValue);

  const applyFilters = () => {
    handleDateFilters({
      startDateValue: startDate,
      endDateValue: endDate,
      last30DaysValue: undefined,
      last6MonthsValue: undefined,
      lastYearValue: undefined,
    });
    setSelectedOption(null);
  };

  const disableApplyButton = (!!startDate
    && !!endDate
    && startDate > endDate
  );

  const handleLast30DaysSelected = () => {
    handleDateFilters({
      startDateValue: undefined,
      endDateValue: undefined,
      last30DaysValue: true,
      last6MonthsValue: undefined,
      lastYearValue: undefined,
    });
    setSelectedOption(null);
  };

  const handleLast6MonthsSelected = () => {
    handleDateFilters({
      startDateValue: undefined,
      endDateValue: undefined,
      last30DaysValue: undefined,
      last6MonthsValue: true,
      lastYearValue: undefined,
    });
    setSelectedOption(null);
  };

  const handleLastYearSelected = () => {
    handleDateFilters({
      startDateValue: undefined,
      endDateValue: undefined,
      last30DaysValue: undefined,
      last6MonthsValue: undefined,
      lastYearValue: true,
    });
    setSelectedOption(null);
  };

  const handleAllTimeSelected = () => {
    handleDateFilters({
      startDateValue: undefined,
      endDateValue: undefined,
      last30DaysValue: undefined,
      last6MonthsValue: undefined,
      lastYearValue: undefined,
    });
    setSelectedOption(null);
  };

  return (
    <Card.Body>
      <Row>
        <Link
          to="#"
          onClick={handleLast30DaysSelected}
          className="mb-3"
        >
          30 Days
        </Link>
    </Row>
    <Row>
      <Link
        to="#"
        onClick={handleLast6MonthsSelected}
        className="mb-3"
      >
        6 Months
      </Link>
    </Row>
    <Row>
      <Link
        to="#"
        onClick={handleLastYearSelected}
        className="mb-3"
      >
        1 Year
      </Link>

    </Row>
    <Row>
      <Link
        to="#"
        onClick={handleAllTimeSelected}
        className="mb-3"
      >
        All Time
      </Link>
    </Row>
    <Row>
      <hr />
      <b className="mb-3">CUSTOM DATE RANGE</b>
    </Row>
    <Row>
      <Form.Group controlId="startingDate" className="mb-3">
        <Form.Label>Starting Date</Form.Label>
        <DateSelectorFilter
          dateValue={startDate}
          placeholder="YYYY/MM/DD"
          handleDateChange={setStartDate}
        />
        </Form.Group>
        <Form.Group controlId="startingDate" className="mb-3">
          <Form.Label>Ending Date</Form.Label>
          <DateSelectorFilter
            dateValue={endDateValue}
            placeholder="YYYY/MM/DD"
            handleDateChange={setEndDate}
          />
        </Form.Group>
      </Row>
      <Row className="my-2 px-3">
        {disableApplyButton && (
          <span style={{ color: "red" } }>
            Starting date must be before the ending date.
          </span>
        )}
        <Button
          onClick={() => applyFilters()}
          disabled={disableApplyButton}
        >
          Apply
        </Button>
      </Row>
      <Row className="my-2 px-3">
        <Button
          variant="btn btn-outline-secondary"
          onClick={() => setSelectedOption(null)}
        >
          Cancel
        </Button>
      </Row>
    </Card.Body>
  );
};

export default DateFilter;
