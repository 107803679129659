import FeatherIcon from "feather-icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import posthog from "posthog-js";
import { Collapse, Navbar, Nav, Dropdown, Badge, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import fondoLogo from "../../assets/img/fondo-icon.png";
import LogoutDropdownItem from "../logout/LogoutDropdownItem";
import Avatar from "../avatar/Avatar";
import avatarGeneric from "../../assets/img/avatar-generic.png";
import { BasicCompany, SidenavData, SidenavInfoResponse, SidenavItem, UserCompaniesResponse } from "./sidenav.type";
import { getSidenavInfo, getUserCompanies, saveSelectedCompany } from "./sidenav.api";
import { SelectOptionType } from "../../common/type";
import Spinner from "../spinner/Spinner";
import { SidenavCollapsedContext } from "../../context/SidenavCollapsedContext";
import "./sidenav.css";
import {
  BENTO_PLUS_1,
  BETA,
  NEW,
  HELP_WARNING,
  isSidenavCollapsedPath,
  MARK_DONE,
  fondoEnv as FONDO_ENV,
  ACTION_ITEMS_PLUS_1,
} from "../../constants/general";
import { BroadcastMessageTypes, broadcast } from "../../utils/broadcaster";
import { getUser } from "../settings/settings.api";
import { getDepositPaid } from "../payment/payment.api";
import { DepositInformation } from "../payment/payment.type";
import { ActionItemsData } from "../action-items/actionItems.type";
import { getActionItemsGeneralInfo } from "../action-items/actionItems.api";
import { CompanyLevelSettingsContext } from "../../context/FeatureFlagsContext";
import { getSignatureRequests } from "../documents/documents.api";
import { DROPBOX_SIGN } from "../../constants/network-calls";

export default function Sidenav() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCompany, setSelectedCompany] = useState<SelectOptionType | undefined>();
  const [expandedItemId, setExpandedItemId] = useState<string>("financeDashboard");
  const [nestedExpandedItemId, setNestedExpandedItemId] = useState<string>("");

  const paymentQuery: any = useQuery<DepositInformation, Error>(["depositInfo"], getDepositPaid, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  // Identify user on PostHog
  const userDetails = useQuery(["userDetails"], () => getUser(), {
    enabled: !!paymentQuery.data,
    onSuccess: (data) => {
      const depositChargeId = paymentQuery?.data?.depositChargeId;
      const enableActionItems = paymentQuery?.data?.actionItemsEnabled;
      const userId = FONDO_ENV === "production" ? data.id : `${data.id}_${FONDO_ENV}`;
      posthog.identify(userId, {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        depositChargeId,
        enableActionItems: enableActionItems ? "ENABLED" : "DISABLED",
      });
    },
  });

  const userCompaniesQuery = useQuery<UserCompaniesResponse, Error>(["userCompanies"], getUserCompanies, {
    refetchOnWindowFocus: false,
    enabled: !!userDetails,
    onSuccess: (data) => {
      const selectedSuccessCompany = data.companies.find((company) => company.id === data.selectedCompanyId);
      if (selectedSuccessCompany) setSelectedCompany(buildCompanyOption(selectedSuccessCompany));
      const userId = FONDO_ENV === "production" ? userDetails?.data?.id : `${userDetails?.data?.id}_${FONDO_ENV}`;
      const companyId =
        FONDO_ENV === "production" ? data.selectedCompanyId : `${data.selectedCompanyId}_${FONDO_ENV}`;
      posthog.identify(userId, {
        companyId,
        companyName: data.selectedCompany.name,
      });
    },
  });

  const buildCompanyOptions = (companies: BasicCompany[]): SelectOptionType[] =>
    companies.map((company) => buildCompanyOption(company));

  const buildCompanyOption = (company: BasicCompany): SelectOptionType => ({
    value: company.id.toString(),
    label: `${company.name} (${company.legalName})`,
  });

  const { data: sidenavInfoQuery } = useQuery<SidenavInfoResponse, Error>(["sidenavInfo"], getSidenavInfo, {
    refetchOnWindowFocus: false,
  });

  const [isSidenavCollapsed, setIsSidenavCollapsed] = useContext(SidenavCollapsedContext);
  const companyLevelSettings = useContext(CompanyLevelSettingsContext);

  const isActionItemOnboardingEnabled = companyLevelSettings.actionItemsFeatureFlag;

  const { data: actionItemsData } = useQuery<ActionItemsData, Error>(
    ["actionItemsData"],
    () => getActionItemsGeneralInfo(),
    { enabled: isActionItemOnboardingEnabled },
  );

  const { data: signatureRequests } = useQuery(["fondoDropboxFiles"], getSignatureRequests);

  function handleClick(itemId: string) {
    setExpandedItemId(expandedItemId === itemId ? "" : itemId);
  }

  function handleNestedClick(itemId: string) {
    setNestedExpandedItemId(nestedExpandedItemId === itemId ? "" : itemId);
  }

  function renderItems(sideNavItems: SidenavData[]) {
    return sideNavItems.map((section) => (
      <>
        {section.items && section.items.length > 0 && (
          <>
            <hr className="navbar-divider my-3" />
            <h6 className="navbar-heading">{section.title}</h6>
          </>
        )}
        <Nav>
          <>
            {section.items &&
              section.items.length > 0 &&
              section.items.map((item) => (
                <>
                  {item.children && item.children.length > 0 ? (
                    <Nav.Item key={item.key}>
                      <Nav.Link onClick={() => handleClick(item.key)} role="button">
                        {item.icon && <FeatherIcon icon={item.icon} size="17" />}
                        {item.title}
                        <FeatherIcon
                          icon="chevron-down"
                          size="1em"
                          className={`ms-auto nav-chevron ${expandedItemId === item.key && "active"}`}
                        />
                      </Nav.Link>
                      <Collapse in={expandedItemId === item.key}>
                        <div>
                          <div className="ps-4 nav-sm flex-column">{renderChildren(item.children)}</div>
                        </div>
                      </Collapse>
                    </Nav.Item>
                  ) : (
                    renderItem(item.key, item)
                  )}
                </>
              ))}
          </>
        </Nav>
      </>
    ));
  }

  function renderChildren(childNavItems: SidenavItem[]) {
    return childNavItems.map((childSidenavItem) => {
      if (childSidenavItem.children) {
        return (
          <>
            <Nav.Item key={childSidenavItem.key}>
              <Nav.Link onClick={() => handleNestedClick(childSidenavItem.key)} role="button">
                {childSidenavItem.icon && <FeatherIcon icon={childSidenavItem.icon} size="17" />}
                {childSidenavItem.title}
                <FeatherIcon
                  icon="chevron-down"
                  size="1em"
                  className={`ms-auto nav-chevron ${nestedExpandedItemId === childSidenavItem.key && "active"}`}
                />
              </Nav.Link>
              <Collapse in={nestedExpandedItemId === childSidenavItem.key}>
                <div>
                  <div className="ps-4 nav-sm flex-column">{renderChildren(childSidenavItem.children)}</div>
                </div>
              </Collapse>
            </Nav.Item>
          </>
        );
      }
      return renderItem(childSidenavItem.key, childSidenavItem);
    });
  }

  function renderItem(id: string, item: SidenavItem) {
    const classNames = [];
    if (item.disabled) classNames.push("disabled");
    if (item.new) classNames.push("fw-bold");
    return (
      <Nav.Item key={id}>
        <Nav.Link
          active={location.pathname === item.url}
          onClick={(event) => (item.disabled ? event.preventDefault : navigate(item.url))}
          className={item.disabled ? "cursor-disabled" : ""}
        >
          {item.icon && <FeatherIcon icon={item.icon} size="17" />}
          <span className={classNames.join(" ")}>{item.title}</span>
          {item.badge && renderIcon(item.badge, true)}
          {item.new && renderIcon(NEW, true)}
          {item.secondaryIcon && renderIcon(item.secondaryIcon, false, item.secondaryIconHoverText, item.disabled)}
        </Nav.Link>
      </Nav.Item>
    );
  }

  function getIcon(icon: string, className: string, tooltipText?: string, disabled?: boolean) {
    if (tooltipText) {
      return (
        <span>
          <OverlayTrigger placement="top" overlay={<Tooltip>{tooltipText}</Tooltip>}>
            <span className={disabled ? "disabled" : ""}>
              <FeatherIcon icon={icon} className={className} size="1.1em" />
            </span>
          </OverlayTrigger>
        </span>
      );
    }
    return <FeatherIcon icon={icon} className={className} size="1.1em" />;
  }

  function renderIcon(key: string, isBadge: boolean, hoverText?: string, disabled?: boolean) {
    let element;
    if (isBadge) {
      if (key === BETA) {
        element = (
          <Badge bg="primary-soft" className="ms-2">
            Beta
          </Badge>
        );
      } else if (key === BENTO_PLUS_1 || key === ACTION_ITEMS_PLUS_1) {
        element = (
          <Badge bg="danger-soft" className="ms-2">
            1+
          </Badge>
        );
      } else if (key === NEW) {
        element = (
          <Badge bg="success-soft" className="ms-2">
            NEW!
          </Badge>
        )
      }
    } else if (key === MARK_DONE) element = getIcon("check-circle", "ms-2 text-success", hoverText, disabled);
    else if (key === HELP_WARNING) element = getIcon("help-circle", "ms-2 text-warning", hoverText, disabled);
    return element;
  }

  function renderBrand() {
    return (
      <Nav.Link href="/">
        <Navbar.Brand href="">
          <img src={fondoLogo} className="navbar-brand-img mx-auto" alt="fondoIso" />
        </Navbar.Brand>
      </Nav.Link>
    );
  }

  function renderUserAvatar() {
    return (
      <div className="navbar-user d-none d-md-flex">
        <Dropdown drop="up">
          <Dropdown.Toggle as={Avatar} size="sm" role="button">
            <img className="rounded-circle navbar-brand-img mx-auto" src={avatarGeneric} alt="avatar" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <LogoutDropdownItem />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  function handleCompanyChange(e: SelectOptionType) {
    setSelectedCompany(e);
    saveSelectedCompany(e.value).then(() => {
      broadcast(BroadcastMessageTypes.FORCE_RELOAD);
      window.location.reload();
    });
  }

  function renderCompanies() {
    if (!userCompaniesQuery.data?.isAccountant) {
      return <div className="text-center mb-3">{userCompaniesQuery.data?.selectedCompany.legalName}</div>;
    }

    if (userCompaniesQuery.data?.companies?.length) {
      if (userCompaniesQuery.data.companies.length > 1) {
        return (
          <div className="my-3">
            <Select
              className="hover-pointer"
              openMenuOnFocus={true}
              isLoading={userCompaniesQuery.isLoading}
              isSearchable={true}
              value={selectedCompany}
              onChange={(e) =>
                handleCompanyChange(
                  e || {
                    value: "",
                    label: "",
                  },
                )
              }
              options={buildCompanyOptions(userCompaniesQuery.data.companies)}
            />
          </div>
        );
      }
      return <div className="text-center">{userCompaniesQuery?.data?.selectedCompany.legalName}</div>;
    }
  }

  function redirectToTaskflow() {
    const url = `${process.env.REACT_APP_TASKFLOW_BASE_URL}/customers/${selectedCompany?.value}`;
    window.open(url, "_blank");
  }

  function renderPlaybookButton() {
    return (
      <div className="d-grid">
        <button onClick={() => redirectToTaskflow()} className="btn btn-outline-primary mb-3 btn-sm">
          Go to playbook
        </button>
      </div>
    );
  }

  function renderNavItems() {
    if (sidenavInfoQuery?.sidenavInfo) {
      return renderItems(sidenavInfoQuery.sidenavInfo);
    }
    return <Spinner />;
  }

  function renderMessage() {
    return (
      <div className="alert alert-warning mb-3 pb-1" role="alert">
        <p>
          This company does not have any information about the user who created it, we represent this with the "owner"
          field and it is currently not set. It is advised to operate with caution since changes might lead to
          unexpected app behavior.
        </p>
      </div>
    );
  }

  const renderSignaturesRequiredNavItem = () => {
    const signaturesRequiredPath = DROPBOX_SIGN;
    if (sidenavInfoQuery?.sidenavInfo) {
      // checking for sidenavInfo query to render
      // signatures required item when the whole sidebar is loaded.
      return (
        <Nav.Item key="signatures-required">
          <Nav.Link
            role="button"
            active={location.pathname === signaturesRequiredPath}
            onClick={() => navigate(signaturesRequiredPath)}
          >
            <FeatherIcon icon="pen-tool" size="17" />
            Signatures
            {signatureRequests && signatureRequests.numberOfPendingSignatureRequests > 0 && (
              <Badge bg="danger" className="rounded-pill ms-auto">
                {signatureRequests?.numberOfPendingSignatureRequests}
              </Badge>
            )}
          </Nav.Link>
        </Nav.Item>
      );
    }
  };

  const renderActionItemNavItem = () => {
    const actionItemsPath = "/dashboard/action-items";
    if (sidenavInfoQuery?.sidenavInfo && isActionItemOnboardingEnabled) {
      // checking for sidenavInfo query to render
      // action items item when the whole sidebar is loaded.
      return (
        <Nav.Item key="action-items">
          <Nav.Link
            role="button"
            active={location.pathname === actionItemsPath}
            onClick={() => navigate(actionItemsPath)}
          >
            <FeatherIcon icon="bell" size="17" />
            Action Items
            {actionItemsData && actionItemsData.numberOfPendingActionItems > 0 && (
              <Badge bg="danger" className="rounded-pill ms-auto">
                {actionItemsData?.numberOfPendingActionItems}
              </Badge>
            )}
          </Nav.Link>
        </Nav.Item>
      );
    }
  };

  const collapseSidenavTrigger = (
    <Button
      variant={"outline-secondary"}
      className={isSidenavCollapsed ? "display-none" : "sidebar-toggle-button"}
      onClick={() => toggleSidenav(!isSidenavCollapsed)}
      size="sm"
    >
      <FeatherIcon icon={isSidenavCollapsed ? "chevron-right" : "chevron-left"} size="17" />
    </Button>
  );

  function toggleSidenav(setState: boolean) {
    setIsSidenavCollapsed(setState);
  }

  return (
    <div className={`${isSidenavCollapsed ? "sidebar" : "sidebar open"}`}>
      <Navbar
        expand="md"
        className="navbar-vertical"
        collapseOnSelect={true}
        style={{ borderRight: "1px solid #EDF2F9" }}
      >
        <Navbar.Toggle />
        <>
          {renderBrand()}
          <Navbar.Collapse>
            <>
              {!isSidenavCollapsed && isSidenavCollapsedPath(location.pathname) && collapseSidenavTrigger}
              {!userCompaniesQuery.data?.isAccountant && (
                <div className="text-center mb-3">{userCompaniesQuery.data?.selectedCompany.legalName}</div>
              )}
              {userCompaniesQuery.data?.isAccountant && (
                <>
                  {renderCompanies()}
                  {renderPlaybookButton()}
                  {!userCompaniesQuery.data?.ownerId && renderMessage()}
                </>
              )}
              <Nav className="mt-3">
                {renderActionItemNavItem()}
                {renderSignaturesRequiredNavItem()}
              </Nav>
              {renderNavItems()}
              {renderUserAvatar()}
            </>
          </Navbar.Collapse>
        </>
      </Navbar>
    </div>
  );
}
