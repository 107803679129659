import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import FondoLayout from "../../fusion-kit/FondoLayout";
import { getCompanyReviews } from "../passport-partners.api";
import Spinner from "../../spinner/Spinner";
import { ReviewCard } from "./ReviewCard";
import { CompanyReview, Partner, ReviewStatus } from "../passport-partners.types";
import notoHeartHands from "../../../assets/img/icons/notoHeartHands.svg";
import notoWarning from "../../../assets/img/icons/notoWarning.svg";

const ReviewsLayout = ({ children, onDoneClick }: { children: JSX.Element; onDoneClick: () => void }) => (
  <FondoLayout
    title="Rate your Connected Services"
    pretitle="Fondo Partners"
    actionButton
    actionButtonText="Done"
    handleActionButtonClick={onDoneClick}
    maxWidth="60%"
    centerContent
  >
    {children}
  </FondoLayout>
);

export const PassportPartnersCompanyReviews = () => {
  const navigate = useNavigate();
  const companyReviewsQuery = useQuery(["passportPartnersCompanyReviews"], getCompanyReviews, {
    refetchOnWindowFocus: false,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [reviewCompleted, setReviewCompleted] = useState(false);
  const [numDismissedReviews, setNumDismissedReviews] = useState(0);

  useEffect(() => {
    if (companyReviewsQuery.isSuccess && companyReviewsQuery.data) {
      const anyReviewComplete = companyReviewsQuery.data.some(
        (companyReview) => companyReview.reviewStatus === ReviewStatus.COMPLETED,
      );
      setReviewCompleted(anyReviewComplete);
    }
  }, [companyReviewsQuery.isSuccess, companyReviewsQuery.data, setReviewCompleted]);

  let layoutContent = <></>;

  if (companyReviewsQuery.isLoading || !companyReviewsQuery.data) {
    layoutContent = <Spinner />;
  } else if (!companyReviewsQuery.data.length || numDismissedReviews === companyReviewsQuery.data.length) {
    layoutContent = <div className="w-100 text-center">No reviews available at the moment.</div>;
  } else {
    const onDismiss = () => {
      setNumDismissedReviews((prev) => prev + 1);
    };

    const getReviewCard = (companyReview: CompanyReview) => {
      const onUpsertSuccess = () => {
        if (companyReview.reviewStatus !== ReviewStatus.COMPLETED) {
          setReviewCompleted(true);
        }
      };
      const { partner } = companyReview;
      return (
        <ReviewCard
          key={`${partner.uuid}-${companyReview.uuid}`}
          review={companyReview}
          onUpsertSuccess={onUpsertSuccess}
          onDismiss={onDismiss}
          partner={partner as Partner}
        />
      );
    };

    layoutContent = <div className="d-flex flex-column">{companyReviewsQuery.data.map(getReviewCard)}</div>;
  }

  const goBack = () => {
    setShowConfirmationModal(false);
    navigate(-1);
  };

  const getConfirmationModal = () => {
    let emojiToShow = notoWarning;
    let mainText = "No Reviews Created";
    let subText = "That's okay, you can always come back and review these later.";
    let buttons = (
      <>
        <Button variant="white" style={{ width: "49%" }} onClick={() => setShowConfirmationModal(false)}>
          Back to Reviews
        </Button>
        <Button variant="primary" style={{ width: "49%" }} onClick={goBack}>
          Done
        </Button>
      </>
    );
    let buttonJustification = "justify-content-between";
    if (reviewCompleted) {
      emojiToShow = notoHeartHands;
      mainText = "Thank you!";
      subText = "Your review(s) have been submitted";
      buttons = (
        <Button className="px-6" variant="primary" onClick={goBack}>
          Close
        </Button>
      );
      buttonJustification = "justify-content-center";
    }
    return (
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size="sm"
        contentClassName="p-3 bg-lighter"
        dialogClassName="review-modal"
      >
        <Modal.Body className="d-flex flex-column align-items-center pb-3">
          <Image src={emojiToShow} style={{ height: "6rem", width: "6rem" }} className="mb-4" />
          <span className="fs-3">{mainText}</span>
          <p className="text-center">{subText}</p>
        </Modal.Body>
        <div className={`d-flex flex-no-wrap pb-3 mb-2 px-3 ${buttonJustification}`}>{buttons}</div>
      </Modal>
    );
  };

  return (
    <>
      <ReviewsLayout onDoneClick={() => setShowConfirmationModal(true)}>{layoutContent}</ReviewsLayout>
      {getConfirmationModal()}
    </>
  );
};
