import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  ErrorMessage, Field, Form, Formik,
} from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import fondoLogo from "../../assets/img/fondo-icon.png";
import { FORM_ERROR_MSG } from "../../constants/general";
import { resetPassword } from "./passwordReset.api";

export default function PasswordReset() {
  const [emailSent, setEmailSent] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const passwordMutation = useMutation<any, AxiosError<any>, { email: string }>((data) => resetPassword(data));

  return (
        <div className="d-flex row vh-100 justify-content-center">
            <div className="d-flex flex-column col-12 col-md-6 col-xl-6 justify-content-center">
                <div className="row mb-5">
                    <div className="d-flex col justify-content-center">
                        <img className="logo-img" src={fondoLogo} alt="Fondo Logo"/>
                    </div>
                </div>

                {emailSent ? (
                    <div className="row justify-content-center">
                        <div className="col-3" />
                        <div className="d-flex col-6 justify-content-center">
                            <h1 className="display-4 mb-3 text-center">Check your email to reset your password</h1>
                        </div>
                        <div className="col-3" />
                    </div>
                ) : (
                    <div className="row justify-content-center">
                        <div className="d-flex col justify-content-center">
                            <h1 className="display-4 mb-3">Password Reset</h1>
                        </div>
                    </div>
                )}

                {emailSent ? (
                    <div className="row mt-5">
                        <div className="col-3" />
                        <div className="d-flex col-6 justify-content-center">
                            <p className="text-muted mb-5 text-center">
                                If there's a Fondo account associated with the address that you entered,
                                you'll get a link to reset your password.
                            </p>
                        </div>
                        <div className="col-3" />
                    </div>
                ) : (
                    <div className="row">
                        <div className="d-flex col justify-content-center">
                            <p className="text-muted mb-5">Enter your email to get a password reset link.</p>
                        </div>
                    </div>
                )}
                {emailSent ? (
                    <>
                        <div className="row">
                            <div className="d-flex col justify-content-center">
                                <p className="text-muted mb-0">If you don't get the link, check your spam folder or</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="d-flex col justify-content-center">
                                <small className="text-muted text-center">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setEmailSent(false);
                                        }}>
                                        re-enter your email address
                                    </a>
                                </small>
                            </div>
                        </div>
                    </>
                ) : (
                    <Formik
                        initialValues={{
                          email: "",
                        }}
                        validationSchema={Yup.object({
                          email: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
                        })}
                        onSubmit={(values, { setErrors }) => {
                          setSubmitting(true);
                          passwordMutation.mutate(values, {
                            onSuccess: () => {
                              setEmailSent(true);
                              setSubmitting(false);
                            },
                            onError: (err) => {
                              setSubmitting(false);
                              const errors = err?.response?.data;
                              if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
                                setErrors(errors);
                              }
                            },
                          });
                        }}>
                        {() => (
                          <div className="row justify-content-center">
                              <div className="col-12 col-lg-10 col-xl-8">
                                  <Form className="my-1 mx-3">
                                      <div className="row mb-3 mt-2">
                                          <div className="col-12 col-md-12">
                                              <label htmlFor="email">Email Address</label>
                                              <Field
                                                placeholder={"name@example.com"}
                                                name="email"
                                                className={"form-control"}
                                              />
                                              <ErrorMessage
                                                component="div"
                                                className="text-danger mt-1"
                                                name="email"
                                              />
                                          </div>
                                      </div>

                                      <div className="row my-4">
                                          <div className="col d-grid">
                                              <button
                                                  type="submit"
                                                  className="btn btn-primary btn-lg"
                                                  disabled={submitting}
                                              >
                                                {submitting ? "Sending Email" : "Reset Password"}
                                              </button>
                                          </div>
                                      </div>
                                      <div className="row">
                                          <div className="d-flex flex-row col justify-content-center">
                                              <small className="text-muted">
                                                  Remember your password?{" "}
                                                  <Link to="/login">
                                                      Sign in
                                                  </Link>
                                              </small>
                                          </div>
                                      </div>
                                  </Form>
                              </div>
                          </div>
                        )}
                    </Formik>
                )}
            </div>
        </div>
  );
}
