import { getApiClient } from "../../utils/util.api";
import { serializeQueryParamsToRepeated } from "../../utils/utils";
import {
  Partner,
  RequiredDataUpdate,
  Review,
  ReviewPaginatedResponse,
  UnavailableRequiredData,
} from "./passport-partners.types";

export const getPartners = async (connectionTypeIds?: number[]): Promise<Partner[]> => {
  const apiClient = await getApiClient();
  const response = await apiClient.listReactifyPassportPartnersPartners({ connectionTypeIds }, null, {
    paramsSerializer: { serialize: serializeQueryParamsToRepeated },
  });
  return response.data;
};

export const getFeaturedPartner = async (): Promise<Partner> => {
  const apiClient = await getApiClient();
  const response = await apiClient.listReactifyPassportPartnersPartners({ isFeatured: true });
  return response.data[0];
};

export const getReviews = async (partnerId: string): Promise<ReviewPaginatedResponse> => {
  const apiClient = await getApiClient();
  const response = await apiClient.listReactifyPassportPartnersReviews({ partnerId });
  return response.data;
};

export const getCompanyReviews = async () => {
  const apiClient = await getApiClient();
  const response = await apiClient.listReactifyPassportPartnersReviewsCompanyReviews();
  return response.data;
};

export const createReview = async (review: Review): Promise<Review> => {
  const apiClient = await getApiClient();
  const response = await apiClient.createReactifyPassportPartnersReviews(null, review);
  return response.data;
};

export const patchReview = async (reviewUuid: string, review: Partial<Review>): Promise<Review> => {
  const apiClient = await getApiClient();
  const response = await apiClient.partialUpdateReactifyPassportPartnersReviews(reviewUuid, review);
  return response.data;
};

export const getUnavailableRequiredData = async (dealUuid: string): Promise<UnavailableRequiredData[]> => {
  const apiClient = await getApiClient();
  const response = await apiClient.listReactifyPassportPartnersUnavailableRequiredData(dealUuid);
  return response.data;
};

export const updateRequiredData = async (dealUuid: string, requiredDataUpdates: RequiredDataUpdate[]) => {
  const apiClient = await getApiClient();
  const response = await apiClient.partialUpdateReactifyPassportPartnersUnavailableRequiredData(
    dealUuid,
    requiredDataUpdates,
  );
  return response.data;
};
