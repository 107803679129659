import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { CashBalanceResponse } from "../financeDashboard.type";
import { convertNumberToCurrency } from "../../../utils/utils";
import { getMonthlyBalance, getQboBalanceSheetReport } from "../FinanceDashboard.api";
import LoadingAssetsAndLiabilitiesBreakdownCard
  from "../profit-and-loss/loading-state-components/LoadingAssetsAndLiabilitiesBreakdownCard";
import LiabilitiesAndEquityCard from "./LiabilitiesAndEquityCard";
import { FinanceDashboardContext } from "../FinanceDashboardContext";
import { QBOReportingData } from "../../reporting/Reporting.type";

const AssetsAndLiabilitiesBreakdownCard = ({ selectedMonth }: {selectedMonth: string}) => {
  const { useQboSource } = useContext(FinanceDashboardContext);

  const qboBalanceSheetQuery = useQuery<QBOReportingData, Error>(
    ["qboBalanceSheet", selectedMonth],
    () => getQboBalanceSheetReport(selectedMonth),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedMonth && useQboSource,
    },
  );

  const { data: monthlyBalance, isLoading: monthlyBalanceIsLoading } = useQuery<CashBalanceResponse, Error>(
    ["monthlyBalance", selectedMonth],
    () => getMonthlyBalance(selectedMonth),
    {
      refetchOnWindowFocus: false,
      enabled: !useQboSource,
    },
  );

  const isLoading = (): boolean => {
    if (useQboSource) {
      return qboBalanceSheetQuery.isLoading;
    }
    return monthlyBalanceIsLoading
  }

  // const Liabilities
  const liabilitiesAndEquity = (): string => {
    if (useQboSource) {
      const liabilitesAndEquityResult = qboBalanceSheetQuery?.data?.results?.find((result) => (
        result.baseName === "LIABILITIES AND EQUITY"
      ));
      if (liabilitesAndEquityResult) {
        return `$${convertNumberToCurrency(liabilitesAndEquityResult.amount[0])}`;
      }
      return "$0.00";
    }
    return `$${convertNumberToCurrency(monthlyBalance?.cashBalance || 0)}`;
  }

  if (isLoading()) {
    return (
        <LoadingAssetsAndLiabilitiesBreakdownCard />
    );
  }

  return (
    <LiabilitiesAndEquityCard
      liabilitiesAndEquity={liabilitiesAndEquity()}
      assets={liabilitiesAndEquity()}/>
  );
};

export default AssetsAndLiabilitiesBreakdownCard;
