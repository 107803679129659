import { Alert, Row } from "react-bootstrap";
import ProductsListCard from "./ProductsListCard";

const ProductsCard = () => (
  <div className="text-align-center">
    <Row className="py-5">
      <span className="subtext subtext-bold text-grey" style={{ fontSize: "10px" }}>
        PRODUCTS AND PAYMENT
      </span>
      <h2 className="my-2">Choose your products</h2>
    </Row>
    <div className="py-0 px-5 m-0">
      <Alert variant="secondary" className="py-2 px-4 m-0 align-items-center m-0">
        <span>
          Pay just a $250.00 deposit now. We will invoice you for the balance.
        </span>
      </Alert>
    </div>
    <div className="py-4 px-5 m-0">
      <ProductsListCard />
    </div>
  </div>
);

export default ProductsCard;
