import { useContext } from "react";
import { Nav } from "react-bootstrap";
import "../dashboard-onboarding/dashboardOnboarding.css";
import fondoLogo from "../../assets/img/fondo-icon.png";
import { LoggedInUserContext } from "../../context/LoggedInUserContext";

type FondoBaseLayoutProps = {
  children: React.ReactNode;
}
const FondoBaseLayout = (
  { children }: FondoBaseLayoutProps,
) => {
  const { currentCompanyId } = useContext(LoggedInUserContext);
  const navbarLogoProps: { [key:string]: string } = {
    className: "navbar-brand mr-auto",
  };
  if (currentCompanyId) navbarLogoProps.href = "/";
  return (
    <div
      className="dashboard-onboarding-gradient containter-fluid"
      style={{ height: "100vh", overflow: "auto" }}
    >
      <Nav className="navbar navbar-expand-lg navbar-light mb-5">
        <div className="container justify-content-center">
          <a {...navbarLogoProps}>
            <img src={fondoLogo} alt="Fondo" className="navbar-brand-img" />
          </a>
        </div>
      </Nav>
      <div>
        {children}
      </div>
    </div>
  );
};

export default FondoBaseLayout;
