import FondoBaseLayout from "../../fusion-kit/FondoBaseLayout";
import PayrollConnectionCard from "./PayrollConnectionCard";

const PayrollConnection = () => (
  <FondoBaseLayout >
    <PayrollConnectionCard />
  </FondoBaseLayout>
);

export default PayrollConnection;
