export const ENV = process.env.NODE_ENV;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const REACT_BASE_URL = process.env.REACT_APP_REACT_BASE_URL;
export const DJANGO_BASE_URL = process.env.REACT_APP_DJANGO_APP_BASE_URL;
export const STRIPE_PUBLISH_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
export const MERCURY_BASE_URL = process.env.REACT_APP_MERCURY_BASE_URL;
export const MERCURY_CLIENT_ID = process.env.REACT_APP_MERCURY_CLIENT_ID;
export const BREX_BASE_URL = process.env.REACT_APP_BREX_BASE_URL;
export const BREX_CLIENT_ID = process.env.REACT_APP_BREX_CLIENT_ID;
export const QBO_AUTH_BASE_URL = process.env.REACT_APP_QBO_AUTH_BASE_URL;
export const QBO_CLIENT_ID = process.env.REACT_APP_QBO_CLIENT_ID;
