import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/theme.scss";
import "./styles/_user.scss";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { PostHogProvider } from "posthog-js/react";
// eslint-disable-next-line import/extensions
import loadBentoScript from "./bento.js";
import App from "./components/App";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

loadBentoScript();

root.render(
    <React.StrictMode>
        <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
            <App />
        </PostHogProvider>
    </React.StrictMode>,
);
