import FeatherIcon from "feather-icons-react";
import { useState } from "react";

export type DataVisibilityToggleProps = {
  data?: string;
  additionalIconClasses?: string;
};

const DataVisibilityToggle = ({ data, additionalIconClasses }: DataVisibilityToggleProps) => {
  const [visible, setVisible] = useState(false);

  if (!data) {
    return <></>;
  }
  return (
    <>
      <span>{visible ? data : data?.replace(/./g, "•")}</span>
      <FeatherIcon
        onClick={() => setVisible(!visible)}
        style={{ cursor: "pointer" }}
        className={`align-middle ms-2 ${additionalIconClasses || ""}`}
        icon={visible ? "eye-off" : "eye"}
        size='1em'
      />
    </>
  );
};

export default DataVisibilityToggle;
