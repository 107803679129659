import { useFormikContext } from "formik";
import {
  ForeignSubsidiaryFormFields,
  incorporationFieldToLabelMap,
  incorporationFieldToTypeMap,
} from "../foreignSubsidiary.type";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import AddressFieldGroup from "../../forms/formik-components/AddressFieldGroup";
import { currencySelectorChoices } from "../utils";

const Incorporation = () => {
  const formikContext = useFormikContext<ForeignSubsidiaryFormFields>();

  return (
    <div>
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="incorporation.name"
        labels={incorporationFieldToLabelMap}
        types={incorporationFieldToTypeMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="incorporation.dateAcquired"
        labels={incorporationFieldToLabelMap}
        types={incorporationFieldToTypeMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="incorporation.taxIdentificationNumberEnc"
        labels={incorporationFieldToLabelMap}
        types={incorporationFieldToTypeMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="incorporation.fiscalYearEnd"
        labels={incorporationFieldToLabelMap}
        types={incorporationFieldToTypeMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="incorporation.dateIncorporated"
        labels={incorporationFieldToLabelMap}
        types={incorporationFieldToTypeMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="incorporation.functionalCurrency"
        types={incorporationFieldToTypeMap}
        labels={incorporationFieldToLabelMap}
        selectorOptions={currencySelectorChoices}
        includeSelectorEmptyValue={true}
      />
      <AddressFieldGroup
        formikContext={formikContext}
        baseName="incorporation"
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="incorporation.countryOfIncorporationLaws"
        types={incorporationFieldToTypeMap}
        labels={incorporationFieldToLabelMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="incorporation.changesInOwnership"
        types={incorporationFieldToTypeMap}
        labels={incorporationFieldToLabelMap}
      />
      {formikContext.values.incorporation?.changesInOwnership === "True" && (
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="incorporation.changeInOwnershipDescription"
          types={incorporationFieldToTypeMap}
          labels={incorporationFieldToLabelMap}
        />
      )}
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="incorporation.capitalContributionsThisYear"
        types={incorporationFieldToTypeMap}
        labels={incorporationFieldToLabelMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="incorporation.transferOfFundsToSubsidiary"
        types={incorporationFieldToTypeMap}
        labels={incorporationFieldToLabelMap}
      />
      <FondoFormGroup
        formikContext={formikContext}
        fieldName="incorporation.distributionsDividendsThisYear"
        types={incorporationFieldToTypeMap}
        labels={incorporationFieldToLabelMap}
      />
    </div>
  );
};

export default Incorporation;
