import { FILINGS_1099_VENDOR, FILINGS_1099_API } from "../../constants/network-calls";
import { Filing1099, Vendor } from "./filings1099.type";
import { deleteFondoApi, getFondoApi, postFondoApi } from "../../utils/util.api";

export async function getFilings1099(taxYear: string): Promise<Filing1099> {
  const result = await getFondoApi(FILINGS_1099_API, { taxYear });
  return result as Filing1099;
}

export async function saveFilings1099(filingsData: {filing: Filing1099, taxYear: string}): Promise<Filing1099> {
  const result = await postFondoApi(FILINGS_1099_API, filingsData.filing, { taxYear: filingsData.taxYear });
  return result as Filing1099;
}

export async function saveVendor(vendorData: {vendor: Vendor, taxYear: string}): Promise<Vendor> {
  const vendorId = vendorData.vendor.id ? vendorData.vendor.id : "";
  const result = await postFondoApi(
    FILINGS_1099_VENDOR,
    vendorData,
    (vendorId ? { vendorId } : {}),
  );
  return result as Vendor;
}

export async function deleteVendor(vendorId: string): Promise<any> {
  const result = await deleteFondoApi(FILINGS_1099_VENDOR, { vendorId });
  return result;
}
