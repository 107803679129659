import { Badge, Button, Card, Stack } from "react-bootstrap";
import { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { Partner } from "../passport-partners.types";
import { DealDetailsModal } from "../deals/DealDetailsModal";

type FeaturedPartnerBannerProps = {
  partner: Partner;
};

export const FeaturedPartnerBanner = ({ partner }: FeaturedPartnerBannerProps) => {
  const [showDealDetailsModal, setShowDealDetailsModal] = useState(false);
  const { products } = partner;
  const product = products.length ? products[0] : undefined;
  const scoreFormatter = Intl.NumberFormat("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 1 });
  return (
    <>
      <Card
        bg=""
        className="p-4 d-flex flex-row align-items-center"
        style={{ width: "99%", backgroundColor: "#EDF2F9" }}
      >
        <Card className="m-0 p-4 d-flex flex-column justify-content-center" style={{ height: "14rem", width: "14rem" }}>
          <a className="d-block" href={partner.url} target="_blank">
            <img src={partner.logoUrl} alt={partner.name} className="w-100" />
          </a>
        </Card>
        <div className="d-flex flex-column align-items-left justify-content-between ms-4 flex-fill align-self-stretch">
          <Badge bg="primary-soft" className="px-4 py-2 fs-5" style={{ width: "fit-content" }}>
            Featured {partner.hasOneTouchDeal ? "One Click Connect " : ""}Partner
          </Badge>
          <div className="d-flex flex-column">
            <span className="fs-1 fw-bold">{partner.name}</span>
            <span className="text-secondary fs-2 fw-light">{partner.description}</span>
          </div>
          <Stack direction="horizontal">
            {partner.averageScore && (
              <>
                <div className="d-flex px-4 align-items-center">
                  <span className="fs-2 fw-semibold">{scoreFormatter.format(partner.averageScore)}</span>
                  <div className="d-inline-block pb-1 ms-1">
                    <FeatherIcon style={{ strokeWidth: "0" }} icon="star" size={18} fill="black" />
                  </div>
                </div>
                <div className="vr text-secondary" />
              </>
            )}
            <div className="d-flex flex-column align-items-center px-4">
              <span className="fs-3 fw-bold lh-1">{partner.numberUsingPartner}</span>
              <span className="fs-4 fw-light text-secondary">User{partner.numberUsingPartner > 1 ? "s" : ""}</span>
            </div>
          </Stack>
          <Button
            variant="primary"
            size="sm"
            onClick={() => setShowDealDetailsModal(true)}
            className="px-4 mt-3"
            style={{ width: "fit-content" }}
          >
            See Details
          </Button>
        </div>
      </Card>
      <DealDetailsModal
        partner={partner}
        product={product}
        show={showDealDetailsModal}
        onHide={() => setShowDealDetailsModal(false)}
      />
    </>
  );
};
