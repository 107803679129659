import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import AlertModal from "../../fusion-kit/AlertModal";
import { updateTransactionCategory } from "../FinanceDashboard.api";
import {
  mapTransactionCategoryToColor,
  mapTransactionCategoryToDropdownLabel,
  TransactionCategory,
} from "../financeDashboard.type";

type CategoryDropdown = {
  transactionUuid: string;
  category: TransactionCategory;
}

const CategoryDropdown = ({ category, transactionUuid }: CategoryDropdown) => {
  const queryClient = useQueryClient();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const updateTransactionCategoryMutation = useMutation<any, AxiosError<any>, TransactionCategory>(
    (newCategory: TransactionCategory) => updateTransactionCategory(transactionUuid, newCategory),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["transactionsList"],
        });
        queryClient.invalidateQueries({
          queryKey: ["transactionsStats"],
        });
      },
      onError: (error: AxiosError) => {
        // If the user selects the same category the transaction had before
        // it won't be update in the backend, and it will return a 304. 
        if (error.response?.status !== 304) setShowAlertModal(true);
      },
    },
  );

  return (
    <>
      <DropdownButton
        title={
          mapTransactionCategoryToDropdownLabel[
            category as TransactionCategory
            || TransactionCategory.VERIFY
          ]
        }
        size='sm'
        variant={
          mapTransactionCategoryToColor[
            category as TransactionCategory
            || TransactionCategory.VERIFY
          ]
        }
        style={{ position: "static" }}
      >
        {Object.entries(mapTransactionCategoryToDropdownLabel).map(([key, value]) => (
          <Dropdown.Item
            onClick={() => updateTransactionCategoryMutation.mutate(
              key as TransactionCategory,
            )}
          >
            {value}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <AlertModal
        show={showAlertModal}
        title={"Error"}
        handleClose={() => setShowAlertModal(false)}
        error={true}
      />
    </>
  );
};

export default CategoryDropdown;
