import { LineItemAPIData } from "../product/product.type";

export type Product = {
  uuid?: string;
  internalName?: string;
  displayName: string;
  description?: string;
  type: ProductType;
  billingFrequency: string;
  sku?: string;
  allowMultiple?: boolean;
  taxYear?: number;
  nextYearVersion?: Product;
  termLength?: number;
  price?: number;
  originalPriceOverrideText?: string,
  discountPrice?: number;
  helperText?: string;
  discountDuration?: string;
  discountTermText?: string;
};

export enum ProductType {
  PLATFORM = "PLATFORM", // Hardcoded value, doesn't actually exist
  DEPOSIT = "DEPOSIT",
  TAX = "TAX",
  TAX_ADDON = "TAX_ADDON",
  BOOKKEEPING = "BOOKKEEPING",
  ONBOARDING_FEE = "ONBOARDING_FEE"
}

export const mapProductTypeToString: { [key in ProductType]: string } = {
  PLATFORM: "Platform", // Hardcoded value, doesn't actually exist
  DEPOSIT: "Deposit",
  TAX: "Tax",
  TAX_ADDON: "Tax Add-on",
  BOOKKEEPING: "Bookkeeping",
  ONBOARDING_FEE: "Onboarding Fee",
};

export const mapProductTypeToIndexOrder: { [key in ProductType]: number } = {
  PLATFORM: 0,
  BOOKKEEPING: 1,
  TAX_ADDON: 2,
  TAX: 3,
  DEPOSIT: 99,
  ONBOARDING_FEE: 99,
};

export type GroupedByTypeProductsList = {
  type: string,
  oneTimeProducts: boolean,
  products: LineItemAPIData[],
};

export type OrderLineItem = {
  name: string;
  finalPrice: number | string;
  originalPrice: number | string;
  addons?: LineItemAPIData[];
  price?: number;
  displayedUnitPrice?: number;
  textPrice?: string;
  helperText?: string;
}

export type lineItemsGroupedByFrequencyType = {
  annuallyLineItems: OrderLineItem[];
  monthlyLineItems: OrderLineItem[];
  quarterlyLineItems: OrderLineItem[];
  oneTimeLineItems: OrderLineItem[];
}
