import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { DOCUMENT_CENTER_FOLDERS } from "../../constants/network-calls";
import { postFondoApi } from "../../utils/util.api";

interface NewFolderModalProps {
  showModal: boolean;
  handleClose: () => void;
  parentFolderId: number | null | undefined;
  refreshFolders: () => void;
}

const NewFolderModal: React.FC<NewFolderModalProps> = ({
  showModal,
  handleClose,
  parentFolderId,
  refreshFolders,
}) => {
  const [folderName, setFolderName] = useState("");
  const folderNameRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (folderNameRef.current) {
      folderNameRef.current.focus();
    }
  }, [showModal]);

  const handleFolderNameChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setFolderName(e.target.value);
  };
  const handleSave = async () => {
    const requestBody = { parentId: parentFolderId, name: folderName };
    try {
      await postFondoApi(DOCUMENT_CENTER_FOLDERS, requestBody);
      await refreshFolders();
    } catch (error) {
      console.error("Error creating folder:", error);
    } finally {
      setFolderName("");
      handleClose();
    }
  };

  const handleCancel = () => {
    setFolderName("");
    handleClose();
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create a New Folder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId='folderName'>
          <Form.Label>Folder Name</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter folder name'
            value={folderName}
            onChange={handleFolderNameChange}
            ref={folderNameRef}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant='primary' onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewFolderModal;
