import "./passport.css";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import {
  Form, Formik,
} from "formik";
import { Button } from "react-bootstrap";
import {
  PassportField,
  PassportFieldTypes,
} from "./passport.type";
import { copyToClipboard } from "../../utils/utils";
import DataVisibilityToggle from "../fusion-kit/DataVisibilityToggle";
import PassportCardEditField from "./PassportCardEditField";

export type PassportCardProps = {
  passportField: PassportField;
  handleEdit: (passportField: PassportField, onSuccess: () => void, onError: () => void) => void;
  handleDelete: (passportField: PassportField) => void;
};

export default function PassportCard({ passportField, handleEdit, handleDelete }: PassportCardProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [passportFieldValue, setPassportFieldValue] = useState(passportField.value);

  const onEditComplete = ({ [passportField.fieldName]: newValue }: { [key: string]: string }) => {
    setIsEditing(false);
    const origVal = passportField.value;
    passportField.value = newValue;
    handleEdit(
      passportField,
      () => { setPassportFieldValue(newValue); },
      () => { passportField.value = origVal; setIsEditing(true); },
    );
  };

  const onEditCancel = () => {
    setIsEditing(false);
  };

  const getEditView = () => (
    <>
      <Formik
        initialValues={{ [passportField.fieldName]: passportField.value }}
        onSubmit={onEditComplete}
        validate={(values) => {
          if (!values[passportField.fieldName]) {
            return { [passportField.fieldName]: "Required" };
          }
          return {};
        }}
      >
        <Form>
          <PassportCardEditField
            passportField={passportField}
          />
          <Button variant="link" type="submit" className="text-grey ms-2 p-0">
            <FeatherIcon className="cursor-pointer" icon="check" size="1.1em" />
          </Button>
          <Button variant="link" onClick={onEditCancel} className="text-grey ms-2 p-0">
            <FeatherIcon className="cursor-pointer" icon="x" size="1.1em" />
          </Button>
        </Form>
      </Formik>

    </>
  );

  const getDisplayField = () => {
    let valToDisplay = passportFieldValue || "--";
    if (passportFieldValue) {
      if (passportField.fieldType === PassportFieldTypes.ENC_TEXT) {
        return <DataVisibilityToggle data={valToDisplay} additionalIconClasses="text-grey" />;
      }
      if (passportField.fieldType === PassportFieldTypes.URL) {
        const urlToDisplay = valToDisplay.replace(/(^https?:)\/\//i, "");
        return <a href={valToDisplay} target="_blank" rel="noreferrer">{urlToDisplay}</a>;
      }

      const numVal = Number(valToDisplay.replaceAll(",", "")).toLocaleString();
      if (passportField.fieldType === PassportFieldTypes.CURRENCY_AMOUNT) {
        valToDisplay = `$${numVal}`;
      } else if (passportField.fieldType === PassportFieldTypes.NUMBER) {
        valToDisplay = numVal;
      }
    }
    return <span className="passport-card-text">{valToDisplay}</span>;
  };

  const getDisplayView = () => (<>
    {getDisplayField()}
    <div className="d-inline-block">
      <a onClick={() => setIsEditing(true)} className="text-grey ms-2">
        <FeatherIcon className="cursor-pointer" icon="edit" size="1.1em" />
      </a>
      {passportFieldValue
        && (<a onClick={() => (copyToClipboard(passportFieldValue))} className="text-grey ms-2">
          <FeatherIcon className="cursor-pointer" icon="copy" size="1.1em" />
        </a>)}
      {passportField.isCustomField && (
        <a onClick={() => handleDelete(passportField)} className="text-grey ms-2 link-danger">
          <FeatherIcon className="cursor-pointer" icon="trash" size="1.1em" />
        </a>
      )}
    </div>
  </>);

  return (
    <div className={"row mb-3"}>
      <div className="col-12 col-md-12">
        <span className="passport-card-header">{passportField.displayName.toUpperCase()}</span>
      </div>
      <div className="col-12 col-md-12">
        {
          isEditing
            ? getEditView()
            : getDisplayView()
        }
      </div>
    </div>
  );
}
