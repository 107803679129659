import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import FondoLayout from "../fusion-kit/FondoLayout";
import actionItemsPayDeposit from "../../assets/img/illustrations/receipt.svg";

const ActionItemsNoDepositView = () => (
  <FondoLayout
    title="Action Items"
  >
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div
            className="px-6 mx-auto text-center col-12 col-sm-8 col-lg-10 col-xl-8"
            style={{ marginTop: "150px" }}
          >
            <div>
              <img height={60} src={actionItemsPayDeposit} className="mx-auto" alt="..." />
              <h1 className="my-3">
                Just one more thing
              </h1>
              <h2 className="mb-3 fw-normal text-secondary">
                Please pay your deposit to get started.
              </h2>
              <Link to='/onboarding'>
                <Button className="mt-3">
                  Pay Deposit
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FondoLayout>
);

export default ActionItemsNoDepositView;
