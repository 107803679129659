import { useState, useEffect, useRef } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import AlertModal from "../fusion-kit/AlertModal";
import CheckoutForm from "./CheckoutForm";
import { savePayment } from "./stripe-wrapper.api";
import { STRIPE_PUBLISH_KEY } from "../../constants/config";

const stripePromise = loadStripe(STRIPE_PUBLISH_KEY || "pk_123");
export default function StripeWrapper(props: any) {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const depositAmountStripePrev = useRef();

  useEffect(() => {
    if (props.depositAmountStripe !== depositAmountStripePrev.current) {
      const fetchData = async () => {
        try {
          const response = await savePayment({
            acceptedTermsAndConditions: props.acceptedTermsAndConditions,
            depositAmount: props.depositAmountStripe,
            token: clientId,
            description: "payment",
          });
          setClientId(response.metadata.id);
          setClientSecret(response.metadata.secret);
        } catch (error) {
          setShowAlertModal(true);
        }
      };
      fetchData();
      depositAmountStripePrev.current = props.depositAmountStripe;
    }
  }, [props.depositAmountStripe]);

  const options: StripeElementsOptions = {
    clientSecret,
  };
  return (
    <div className={`stripe-container px-0 ${props.acceptedTermsAndConditions ? "" : "disabled"}`}>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm/>
        </Elements>
      ) : null}
      <AlertModal
        show={showAlertModal}
        title={"Error"}
        handleClose={() => setShowAlertModal(false)}
        error={true}
      />
    </div>
  );
}
