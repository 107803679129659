import { Button, Card } from "react-bootstrap";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import FondoLayout from "../fusion-kit/FondoLayout";
import ReportingTable from "./ReportingTable/ReportingTable";
import CreateNewReportModal from "./CreateNewReportModal/CreateNewReportModal";
import { getMonthsFromFoundingDate } from "../finance-dashboard/FinanceDashboard.api";
import { mapMonthDateToMonth } from "../finance-dashboard/constants";
import { DateRangeIntervals, TimeRange } from "../finance-dashboard/financeDashboard.type";
import { getAllReporting } from "./Reporting.api";
import { OpenedReport, ReportingType } from "./Reporting.type";
import RecipientGroupModal from "./RecipientGroupModal/RecipientGroupModal";

const Reporting = () => {
  const [showCreateNewReportModal, setShowCreateNewReportModal] = useState(false);
  const [showRecipientGroupModal, setShowRecipientGroupModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState<OpenedReport>({uuid: "", name: ""});
  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>({
    interval: "month",
  });

  const { data: balanceAvailableMonths } = useQuery<DateRangeIntervals, Error>(
    ["balanceAvailableMonths"],
    () => getMonthsFromFoundingDate(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (dateRange) => {
        if (dateRange.months.length) {
          const [year, month] = dateRange.months[0].split("-");
          setSelectedTimeRange({
            interval: "month",
            year,
            timePeriod: mapMonthDateToMonth[month],
          });
        }
      },
    },
  );

  const { data: reportingData } = useQuery<ReportingType[], Error>(
    ["reportings"],
    getAllReporting,
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <FondoLayout title="Reporting" maxWidth="80%" centerContent={true}>
      <Card>
        <Card.Header>
          <Card.Title as={"h4"} className="mb-0">
            Reports
          </Card.Title>
          <Button onClick={() => setShowCreateNewReportModal(true)}>
            Create New
          </Button>
        </Card.Header>
        <Card.Body className="p-0">
          <ReportingTable
            reportingData={reportingData || []}
            setShowRecipientGroupModal={setShowRecipientGroupModal}
            setSelectedReport={setSelectedReport}
          />
        </Card.Body>
      </Card>
      <CreateNewReportModal
        show={showCreateNewReportModal}
        handleClose={() => setShowCreateNewReportModal(false)}
        balanceAvailableIntervals={balanceAvailableMonths}
        handleApplyInterval={setSelectedTimeRange}
        currentTimeRange={selectedTimeRange}
      />
      <RecipientGroupModal
        show={showRecipientGroupModal}
        handleClose={() => setShowRecipientGroupModal(false)}
        selectedReport={selectedReport}
      />
    </FondoLayout>
  );
};

export default Reporting;
