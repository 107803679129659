import { useState } from "react";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import {
  ForeignSubsidiaryFormFields, MajorityShareholdersFormFields,
} from "../foreignSubsidiary.type";
import DisplaySingleLineAddress from "../../forms/display-helpers/DisplaySingleLineAddress";
import AddMajorityShareholdersModal from "../modals/AddMajorityShareholdersModal";

type MajorityShareholdersProps = {
  arrayHelpers: FieldArrayRenderProps;
}

const MajorityShareholders = ({ arrayHelpers }: MajorityShareholdersProps) => {
  const formikContext = useFormikContext<ForeignSubsidiaryFormFields>();
  const [showAddMajorityShareholdersModal, setShowAddMajorityShareholdersModal] = useState(false);
  const [
    currentlySelectedMajorityShareholdersIndex,
    setCurrentlySelectedMajorityShareholdersIndex,
  ] = useState<number | undefined>();

  const handleCancel = () => {
    setShowAddMajorityShareholdersModal(false);
    setCurrentlySelectedMajorityShareholdersIndex(undefined);
  };

  const handleSave = (majorityShareholdersData: MajorityShareholdersFormFields) => {
    if (currentlySelectedMajorityShareholdersIndex !== undefined) {
      arrayHelpers.replace(currentlySelectedMajorityShareholdersIndex, majorityShareholdersData);
    } else {
      arrayHelpers.push(majorityShareholdersData);
    }
    setCurrentlySelectedMajorityShareholdersIndex(undefined);
    setShowAddMajorityShareholdersModal(false);
  };

  const handleDelete = (index: number, majorityShareholders: MajorityShareholdersFormFields) => {
    if (majorityShareholders.uuid) {
      formikContext.setFieldValue(
        "oldMajorityShareholders",
        [...formikContext.values.oldMajorityShareholders, majorityShareholders.uuid],
      );
    }
    arrayHelpers.remove(index);
  };

  const fieldHasError = (index: number, fields: string[]): boolean => (
    fields.some((field) => !!(formikContext.errors?.majorityShareholders?.[index] as any)?.[field])
  );

  const renderMajorityShareholders = () => (
    formikContext.values.majorityShareholders.map((majorityShareholders, index: number) => (
      <div className="col-lg-6 col-xl-4 mb-5">
        <div className="d-flex justify-content-between">
          <h3 className={`${formikContext.errors?.majorityShareholders?.[index] ? "text-danger" : ""}`}>
            {majorityShareholders.name || ""}
            {formikContext.errors?.majorityShareholders?.[index] && (<i
              className="fe fe-alert-circle text-danger ms-2"
              style={{ marginBottom: "-0.1em" }}
            />)}
          </h3>
          <div>
            <span>
              <i
                className="fe fe-edit-2 mx-2 action-icon text-secondary"
                onClick={() => {
                  setShowAddMajorityShareholdersModal(true);
                  setCurrentlySelectedMajorityShareholdersIndex(index);
                }}
                style={{ cursor: "pointer" }}
                title="Edit"
              ></i>
            </span>
            <span>
              <i
                className="fe fe-trash mx-2 action-icon text-secondary"
                title="Delete"
                style={{ cursor: "pointer" }}
                onClick={() => handleDelete(index, majorityShareholders)}
              ></i>
            </span>
          </div>
        </div>
        <hr className='mt-0' />
        <div className='row'>
          <p
            className={`data-item ${fieldHasError(index, ["name"]) ? "text-danger" : ""}`}
          >
            Name:{" "}
            {majorityShareholders.name}
          </p>
          <p
            className={`data-item ${fieldHasError(index, [
              "country", "streetAddress", "city", "state", "postalCode",
            ]) ? "text-danger" : ""}`}
          >
            Address: <DisplaySingleLineAddress addressable={majorityShareholders} />
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["sharesAtYearStart"]) ? "text-danger" : ""}`}
          >
            Shares at year start:{" "}
            {majorityShareholders?.sharesAtYearStart}
          </p>
          <p
            className={`data-item ${fieldHasError(index, ["sharesAtYearEnd"]) ? "text-danger" : ""}`}
          >
            Shares at year end:{" "}
            {majorityShareholders?.sharesAtYearEnd}
          </p>
        </div>
      </div>
    ))
  );

  return (<>
    {formikContext.values.majorityShareholders?.length > 0 && (
      <div>
        <div className="row mt-5">
          {renderMajorityShareholders()}
        </div>
        <div className="mb-5">
          <button type="button" className="btn btn-primary" onClick={() => {
            setShowAddMajorityShareholdersModal(true);
          }}>Add another</button>
        </div>
      </div>
    )}

    {(formikContext.values.majorityShareholders?.length === 0)
      && <div className='text-align-center' style={{ margin: "200px" }}>
        <h3 className="text-secondary mb-4">Start by adding a new shareholder</h3>
        <button type="button" className="btn btn-primary" onClick={() => {
          setShowAddMajorityShareholdersModal(true);
        }}>Add new</button>
      </div>
    }

    <AddMajorityShareholdersModal
      show={showAddMajorityShareholdersModal}
      handleClose={handleCancel}
      handleSave={handleSave}
      majorityShareholders={currentlySelectedMajorityShareholdersIndex !== undefined
        ? formikContext.values.majorityShareholders[currentlySelectedMajorityShareholdersIndex] : undefined}
      header="Add new shareholder"
    />
  </>);
};

export default MajorityShareholders;
