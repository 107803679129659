import { useMutation } from "@tanstack/react-query";
import FeatherIcon from "feather-icons-react";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { evaluateBotTrack } from "../../utils/util.api";
import { BotTrack } from "../../utils/util.type";
import { copyToClipboard } from "../../utils/utils";

type AIChatBotMessageEvaluateAndCopyProps = {
  botTrack: BotTrack;
  contentToCopy: string;
};

const AIChatBotMessageEvaluateAndCopy = ({ botTrack, contentToCopy }: AIChatBotMessageEvaluateAndCopyProps) => {
  const [botWasAccurate, setBotWasAccurate] = useState(botTrack.isResultAccurate);

  const evaluateBotTrackMutation = useMutation({
    mutationFn: (wasAccurate: boolean | null) => evaluateBotTrack(botTrack.uuid, wasAccurate),
    onSuccess: (data) => {
      setBotWasAccurate(data.isResultAccurate);
    },
  });

  const handleEvaluateClick = (wasAccurate: boolean | null) => {
    let evaluation = wasAccurate;
    if (evaluation === botWasAccurate) {
      evaluation = null;
    }
    evaluateBotTrackMutation.mutate(evaluation);
  };

  return (
    <div className="d-flex gap-2 justify-content-end mt-2">
      <Button
        onClick={() => handleEvaluateClick(true)}
        variant={`${botWasAccurate !== true ? "outline-" : ""}light`}
        className="btn-rounded-circle btn-sm"
      >
        <FeatherIcon icon="thumbs-up" size="1em" className="mt-n1" />
      </Button>
      <Button
        onClick={() => handleEvaluateClick(false)}
        variant={`${botWasAccurate !== false ? "outline-" : ""}light`}
        className="btn-rounded-circle btn-sm"
      >
        <FeatherIcon icon="thumbs-down" size="1em" className="mt-n1" />
      </Button>
      <Button
        onClick={() => copyToClipboard(contentToCopy)}
        variant="outline-light"
        className="btn-rounded-circle btn-sm"
      >
        <FeatherIcon icon="copy" size="1em" className="mt-n1" />
      </Button>
    </div>
  );
};

export default AIChatBotMessageEvaluateAndCopy;
