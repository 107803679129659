export const convertCurrencyToNumber = (currency: string): number => +currency.replace(/[$,]/g, "");

export const convertNumberToRoundedCurrency = (value: number): string =>
  value.toLocaleString("en-US", {
    style: "decimal",
    maximumFractionDigits: 0,
  });

export const convertNumberToCurrency = (value: string | number): string => {
  const valueNumber = Number(value);
  return valueNumber.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const convertPercentageToDecimal = (percentage: string): any => {
  const parsedPercantage = percentage.replace(/[^0-9.]/g, "");
  return +parsedPercantage / 100;
};

export const copyToClipboard = (textToCopy: string) => {
  navigator.clipboard.writeText(textToCopy);
};

export const serializeQueryParamsToRepeated = (queryParams: Record<string, any>): string =>
  Object.entries(queryParams)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((val) => `${key}=${val}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");

export const serializeQueryParamsToCSV = (queryParams: Record<string, any>): string =>
  Object.entries(queryParams)
    .map(([key, value]) => {
      let valueToSerialize = value;
      if (Array.isArray(value)) {
        valueToSerialize = value.join(",");
      }
      return `${key}=${valueToSerialize}`;
    })
    .join("&");
