import { Card, Badge } from "react-bootstrap";
import { convertNumberToCurrency } from "../../../utils/utils";
import { getAmountColorClassName } from "../utils";
import QboNetIncomeRow from "./QboNetIncomeRow";

type QboNetIncomeBreakdownCardProps = {
  historicalProfitAndLoss: any;
}

const QboNetIncomeBreakdownCard = ({ historicalProfitAndLoss }: QboNetIncomeBreakdownCardProps) => {
  const resultIndex = historicalProfitAndLoss.headers.length - 2;
  const netIncomeResult = historicalProfitAndLoss?.results?.find(
    (result: any) => result.name === "Net Income"
  );
  const netIncome = netIncomeResult?.amount?.length > (resultIndex) ?
    netIncomeResult.amount[resultIndex] 
    : 
    0;
  const priorNetIncome = netIncomeResult?.amount?.length > (resultIndex - 1) ?
    netIncomeResult.amount[resultIndex - 1] 
    : 
    0;

  const getNetIncomePercentageChangeComparedToPreviousPeriod = () => {
    const percentageChanged = (
      netIncome - priorNetIncome
    ) / (Math.abs(priorNetIncome));
    return percentageChanged * 100;
  };

  const rows: any[] = [];
  const processRow = (row: any, rowsArr: any[], depth=0, maxDepth=0) => {
    if (row.baseName && depth !== maxDepth && !!row.children) {
      rowsArr.push(<QboNetIncomeRow name={row.baseName} depth={depth} bold={!!row.children}/>);
      rowsArr.push(<hr />);
    }
    if (row.children && depth < maxDepth) {
      row.children.forEach((child: any) => processRow(child, rowsArr, depth + 1));
      rowsArr.push(<QboNetIncomeRow name={row.name} amount={row.amount[resultIndex]} depth={depth} bold={true}/>);
    } else {
      rowsArr.push(
        <QboNetIncomeRow name={row.name} amount={row.amount[resultIndex]} depth={depth} bold={depth === 0}/>
      );
    }
    rowsArr.push(<hr />);
  }

  historicalProfitAndLoss.results.forEach((row: any) => processRow(row, rows));

  return (
    <Card>
      <Card.Body className="p-0">
        <div className="border-bottom p-4 pb-3">
          <Card.Title className="text-uppercase text-muted mb-2">
            <span className="h5 text-secondary">Net Income </span>
          </Card.Title>
          <span className={`h1 mb-0 text-${getAmountColorClassName(netIncome)}`}>
            ${convertNumberToCurrency(netIncome)}
          </span>
          {Number.isFinite(getNetIncomePercentageChangeComparedToPreviousPeriod()) && (
            <Badge
              pill
              bg={`${getAmountColorClassName(getNetIncomePercentageChangeComparedToPreviousPeriod())}-soft`}
              className="ms-2 mt-n2"
              as='span'
            >
              {getNetIncomePercentageChangeComparedToPreviousPeriod().toFixed(1)}%
            </Badge>
          )}
        </div>

        <div className="p-4">
          {rows}
        </div>
      </Card.Body>
    </Card>
  );
};

export default QboNetIncomeBreakdownCard;
