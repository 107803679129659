import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Card, Row, Spinner } from "react-bootstrap";
import { getProgressStatus } from "../../utils/util.api";

type RealTimeProgressManagerStatusCardProps = {
  processingTitle: string;
  elementsToProcessText: string;
  numberOfElementsToProcess?: number | null | undefined;
  elementsProcessedText: string;
  verifyProgress: boolean;
  setVerifyProgress: (value: boolean) => void;
  progressStatusURL: string;
  queriesToRefresh: string[];
  refetchInterval?: number;
}
const RealTimeProgressManagerStatusCard = (
  {
    processingTitle,
    elementsToProcessText,
    numberOfElementsToProcess,
    elementsProcessedText,
    verifyProgress,
    setVerifyProgress,
    progressStatusURL,
    queriesToRefresh,
    refetchInterval = 1000
  } : RealTimeProgressManagerStatusCardProps,
) => {
  const queryClient = useQueryClient();
  const [isInitialFetching, setIsInitialFetching] = useState(true);
  const [elementsToProcess, setElementsToProcess] = useState(numberOfElementsToProcess);

  const { data: progressStatus, isFetching } = useQuery(
    ["progressStatus", verifyProgress],
    async () => getProgressStatus(progressStatusURL),
    {
      enabled: verifyProgress,
      refetchOnWindowFocus: true,
      refetchInterval: verifyProgress ? refetchInterval : false,
      refetchIntervalInBackground: false,
      onError: () => {
        setVerifyProgress(false);
      },
      onSuccess: (response) => {
        setElementsToProcess(response.data.elementsToProcess);
      },
    },
  );

  useEffect(() => {
    if (!isFetching && progressStatus?.status === 200) {
      setVerifyProgress(false);
      for (let i = 0; i < queriesToRefresh.length; i += 1) {
        queryClient.invalidateQueries([queriesToRefresh[i]]);
      }
    }
    if (!isFetching && isInitialFetching) setIsInitialFetching(false);
  }, [isFetching, progressStatus]);

  return (
    <Card className="mb-0">
      <Card.Body className="p-3">
        {!isInitialFetching
          ? (
            <>
              <Row className="text-muted mb-2 me-4">
                <span>{processingTitle}</span>
              </Row>
              <Row>
                <span>
                  {elementsToProcessText}: {elementsToProcess}
                </span>
              </Row>
              <Row>
                <span>
                  {elementsProcessedText}: {progressStatus?.data.elementsProcessed || "0"}
                </span>
              </Row>
            </>
          ) : (
            <div className='text-center' >
              <Spinner animation='border' variant='primary' />
            </div>
          )
        }
      </Card.Body>
    </Card>
  );
};

export default RealTimeProgressManagerStatusCard;
