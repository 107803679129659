import {
  ErrorMessage,
  Field, Formik,
  FormikProps,
} from "formik";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import {
  MajorityShareholderFormFields,
  shareholderFieldToLabelMap,
  shareholderFieldToTypeMap,
} from "../corporateTax.type";
import AddressFieldGroup from "../../forms/formik-components/AddressFieldGroup";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import { post2023Form } from "../../../utils/common.util";

type AddMajorityShareholderModalProps = {
  show: boolean;
  handleClose: () => any;
  majorityShareholder?: MajorityShareholderFormFields;
  header: string;
  handleSave: (shareholder: MajorityShareholderFormFields) => void;
  taxYear?: string;
};

export default function AddMajorityShareholderModal(props: AddMajorityShareholderModalProps) {
  const showCitizenshipTaxId = (formik: FormikProps<MajorityShareholderFormFields>): boolean => (
    !formik.values.countryOfCitizenship
    || formik.values.countryOfTaxResidence !== formik.values.countryOfCitizenship
  );

  const initialValues = props.majorityShareholder || {
    firstName: "",
    lastName: "",
    title: "",
    percentageOwnership: 0,
    phone: "",
    email: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    countryOfTaxResidence: "",
    countryOfTaxResidenceTaxIdEnc: "",
    countryOfCitizenship: "",
    countryOfCitizenshipTaxIdEnc: "",
    officer: "",
    signingOfficer: "",
  };

  return (
    <Modal show={props.show} onHide={() => props.handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
            })}
            onSubmit={(values) => {
              props.handleSave(values);
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label htmlFor="firstName">First Name</label>
                    <Field name="firstName" type="text" className={"form-control"} />
                    <ErrorMessage component="div" className="text-danger" name="firstName" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label htmlFor="lastName">Last Name</label>
                    <Field name="lastName" type="text" className={"form-control"} />
                    <ErrorMessage component="div" className="text-danger" name="lastName" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label htmlFor="title">Title</label>
                    <Field name="title" type="text" className={"form-control"} />
                    <ErrorMessage component="div" className="text-danger" name="title" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label htmlFor="percentageOwnership">Percentage Ownership</label>
                    <Field name="percentageOwnership" type="number" className={"form-control"} />
                    <ErrorMessage component="div" className="text-danger" name="percentageOwnership" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label htmlFor="phone">Phone</label>
                    <Field name="phone" type="text" className={"form-control"} />
                    <ErrorMessage component="div" className="text-danger" name="phone" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label htmlFor="email">Email</label>
                    <Field name="email" type="text" className={"form-control"} />
                    <ErrorMessage component="div" className="text-danger" name="email" />
                  </div>
                </div>
                <AddressFieldGroup
                  formikContext={formik}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="countryOfTaxResidence"
                  labels={shareholderFieldToLabelMap}
                  types={shareholderFieldToTypeMap}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="countryOfTaxResidenceTaxIdEnc"
                  labels={shareholderFieldToLabelMap}
                  types={shareholderFieldToTypeMap}
                  selectedCountry={formik.values.countryOfTaxResidence}
                />
                <FondoFormGroup
                  formikContext={formik}
                  fieldName="countryOfCitizenship"
                  labels={shareholderFieldToLabelMap}
                  types={shareholderFieldToTypeMap}
                />
                {showCitizenshipTaxId(formik) && (
                  <FondoFormGroup
                    formikContext={formik}
                    fieldName="countryOfCitizenshipTaxIdEnc"
                    labels={shareholderFieldToLabelMap}
                    types={shareholderFieldToTypeMap}
                    selectedCountry={formik.values.countryOfCitizenship}
                  />
                )}
                {post2023Form(props.taxYear) && (
                  <>
                    <FondoFormGroup
                      formikContext={formik}
                      fieldName="officer"
                      labels={shareholderFieldToLabelMap}
                      types={shareholderFieldToTypeMap}
                    />
                    <FondoFormGroup
                      formikContext={formik}
                      fieldName="signingOfficer"
                      labels={shareholderFieldToLabelMap}
                      types={shareholderFieldToTypeMap}
                    />
                  </>
                )}
                <div className="row mb-3 mt-2">
                  <div className="d-flex flex-row col-12 col-md-12 justify-content-end">
                    <button className="btn btn-lg btn-primary" type="submit"><div>Save</div></button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
