import { useFormikContext } from "formik";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import {
  Post2023CorpTaxFormFields,
  post2023IncorporationFieldToLabelMap,
  post2023IncorporationFieldToPlaceholderMap,
  post2023IncorporationFieldToTypeMap,
} from "./post2023CorporateTax.type";

export default function Post2023Incorporation() {
  const formikContext = useFormikContext<Post2023CorpTaxFormFields>();

  return (
    <div>
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="incorporation.legalName"
          placeholders={post2023IncorporationFieldToPlaceholderMap}
          labels={post2023IncorporationFieldToLabelMap}
          types={post2023IncorporationFieldToTypeMap}
        />
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="incorporation.name"
          placeholders={post2023IncorporationFieldToPlaceholderMap}
          labels={post2023IncorporationFieldToLabelMap}
          types={post2023IncorporationFieldToTypeMap}
        />
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="incorporation.einEnc"
          placeholders={post2023IncorporationFieldToPlaceholderMap}
          labels={post2023IncorporationFieldToLabelMap}
          types={post2023IncorporationFieldToTypeMap}
        />
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="incorporation.incorporationDate"
          placeholders={post2023IncorporationFieldToPlaceholderMap}
          labels={post2023IncorporationFieldToLabelMap}
          types={post2023IncorporationFieldToTypeMap}
          helperText={{
            type: "whereToFindThis",
            body:
            <>
              You can find your Incorporation Date <a href="https://opencorporates.com" target="_blank">here.</a><br/>
              If you are looking for Delaware Incorporation Date, you can find it on
              <a href="https://icis.corp.delaware.gov/Ecorp/searchfilenumber.aspx" target="_blank">
                Delaware Division of Corporations website.
              </a>
            </>,
          }}
        />
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="incorporation.fiscalYearEnd"
          placeholders={post2023IncorporationFieldToPlaceholderMap}
          labels={post2023IncorporationFieldToLabelMap}
          types={post2023IncorporationFieldToTypeMap}
        />
        <FondoFormGroup
          formikContext={formikContext}
          fieldName="incorporation.incorporationState"
          types={post2023IncorporationFieldToTypeMap}
          labels={post2023IncorporationFieldToLabelMap}
          selectedCountry="US"
        />
    </div>
  );
}
