import { useState } from "react";
import {
  Formik, Field, Form, ErrorMessage,
} from "formik";
import Cleave from "cleave.js/react";
import moment from "moment";
import "./activity.style.css";
import { Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { getActivity2, getCurrentUserCompany, saveActivity } from "./activity.api";
import { TaxYearInfoType } from "../onboarding.type";
import { ActivityApiType, ActivitySection2 } from "./activity.type";
import { CURRENT_USER_COMPANY, TAX_YEAR_INFO } from "../../../constants/network-calls";
import { getTaxYearInfo } from "../onboarding.api";
import { onboardingActivity2Schema } from "../../formSchemas/onboardingActivity2FormSchema";

export default function Activity2() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<ActivitySection2>({
    bookkeepingManagerCurrentYear: "",
    hasForeignBankAccountsCurrentYear: "No",
    hasForeignShareholdersCurrentYear: "No",
    needsFondoBookkeepingCurrentYear: "No",
    needsMonthlyBookkeepingCurrentYear: "No",
    hasInternationalSubsidiariesCurrentYear: "No",
    domesticSubsidiariesCurrentYear: "No",
    needsAssistanceWithTaxPrevYear: "No",
    hasForeignBankAccountsPrevYear: "No",
    hasForeignShareholdersPrevYear: "No",
    needsFondoBookkeepingPrevYear: "No",
    hasInternationalSubsidiariesPrevYear: "No",
    domesticSubsidiariesPrevYear: "No",
    totalExpensesPrevYear: "0",
    stateEmployeesPrevYear: "0",

    needsAssistanceWithTaxNextYear: "No",
    hasForeignBankAccountsNextYear: "No",
    hasForeignShareholdersNextYear: "No",
    needsFondoBookkeepingNextYear: "No",
    hasInternationalSubsidiariesNextYear: "No",
    domesticSubsidiariesNextYear: "No",
    totalExpensesNextYear: "0",
    stateEmployeesNextYear: "0",

    numberOfForeignBankAccountsCurrentYear: 0,
    numberOfForeignShareholdersCurrentYear: 0,
    numberOfInternationalSubsidiariesCurrentYear: 0,
    numberOfForeignBankAccountsPrevYear: 0,
    numberOfForeignShareholdersPrevYear: 0,
    numberOfInternationalSubsidiariesPrevYear: 0,
    numberOfForeignBankAccountsNextYear: 0,
    numberOfForeignShareholdersNextYear: 0,
    numberOfInternationalSubsidiariesNextYear: 0,
  });
  const activityMutation = useMutation<any, AxiosError<any>, ActivityApiType>((data) => saveActivity(data));
  const userCompanyQuery = useQuery<any, AxiosError<any>>([CURRENT_USER_COMPANY], getCurrentUserCompany);
  const taxYearInfoQuery = useQuery<TaxYearInfoType, Error>([TAX_YEAR_INFO], getTaxYearInfo, {});

  useQuery<ActivitySection2, Error>(["getActivity2"], getActivity2, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setFormValues(data);
    },
    onError: (e) => {
      console.error(e);
    },
  });

  const showPrevYearForm = () => {
    if (userCompanyQuery?.data?.incorporationDate && taxYearInfoQuery?.data) {
      const incorporationYear = moment(userCompanyQuery.data.incorporationDate, "YYYY-MM-DD").year();
      return incorporationYear < taxYearInfoQuery?.data.currentYear;
    }
    return false;
  };

  function renderPrevYearForm(formik: any, showAdditionalQuestions: boolean) {
    if (showPrevYearForm()) {
      return (
        <>
          <div className="row mb-3 mt-2">
            <div className="col-12 col-md-12">
              <label htmlFor="needsAssistanceWithTaxPrevYear">
                Do you need assistance with your Tax Year {taxYearInfoQuery?.data?.prevYear} Return?
              </label>
              <br />
              <div className={"form-check form-check-inline"}>
                <Field
                  type="radio"
                  name="needsAssistanceWithTaxPrevYear"
                  value={"Yes"}
                  className={"form-check-input"}
                />
                <label className={"form-check-label"}>Yes</label>
              </div>
              <div className={"form-check form-check-inline"}>
                <Field type="radio" name="needsAssistanceWithTaxPrevYear" value={"No"} className={"form-check-input"} />
                <label className={"form-check-label"}>No</label>
              </div>
              <ErrorMessage component="div" className="text-danger" name="needsAssistanceWithTaxPrevYear" />
            </div>
          </div>
          {showAdditionalQuestions && (
            <>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="totalExpenses">Total Estimated {taxYearInfoQuery?.data?.prevYear} expenses</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text input-side-box">$</span>
                    </div>
                    <Cleave
                      {...formik.getFieldProps("totalExpensesPrevYear")}
                      options={{ numeral: true }}
                      placeholder=""
                      className="form-control"
                    />
                  </div>
                  {formik.touched.totalExpensesPrevYear && formik.errors.totalExpensesPrevYear ? (
                    <div className="text-danger">{formik.errors.totalExpensesPrevYear}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="stateEmployees">
                    How many states does the company have W2 employees or an
                    office in during {taxYearInfoQuery?.data?.prevYear}?
                  </label>
                  <input {...formik.getFieldProps("stateEmployeesPrevYear")} className="form-control" type="number" />
                  {formik.touched.stateEmployeesPrevYear && formik.errors.stateEmployeesPrevYear ? (
                    <div className="text-danger">{formik.errors.stateEmployeesPrevYear}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="hasForeignBankAccountsPrevYear">
                    Do you have any foreign accounts with $10,000 or more in {taxYearInfoQuery?.data?.prevYear}?
                  </label>
                  <br />
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="hasForeignBankAccountsPrevYear"
                      value={"Yes"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>Yes</label>
                  </div>
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="hasForeignBankAccountsPrevYear"
                      value={"No"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>No</label>
                  </div>
                  <ErrorMessage component="div" className="text-danger" name="hasForeignBankAccountsPrevYear" />
                </div>
              </div>
              {formik.values.hasForeignBankAccountsPrevYear === "Yes" && (
                <div className="row mb-3 mt-2">
                  <div className="col-12 col-md-12">
                    <label htmlFor="numberOfForeignBankAccountsPrevYear">How many of them?</label>
                    <input
                      id="numberOfForeignBankAccountsPrevYear"
                      {...formik.getFieldProps("numberOfForeignBankAccountsPrevYear")}
                      className="form-control"
                      type="number"
                    />
                    {formik.touched.numberOfForeignBankAccountsPrevYear
                      && formik.errors.numberOfForeignBankAccountsPrevYear ? (
                      <div className="text-danger">{formik.errors.numberOfForeignBankAccountsPrevYear}</div>
                      ) : null}
                  </div>
                </div>
              )}
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="hasForeignShareholdersPrevYear">
                    Do you have foreign shareholders that own more than 25% in {taxYearInfoQuery?.data?.prevYear}?
                  </label>
                  <br />
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="hasForeignShareholdersPrevYear"
                      value={"Yes"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>Yes</label>
                  </div>
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="hasForeignShareholdersPrevYear"
                      value={"No"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>No</label>
                  </div>
                  <ErrorMessage component="div" className="text-danger" name="hasForeignShareholdersPrevYear" />
                </div>
              </div>
              {formik.values.hasForeignShareholdersPrevYear === "Yes" && (
                <div className="row mb-3 mt-2">
                  <div className="col-12 col-md-12">
                    <label htmlFor="numberOfForeignShareholdersPrevYear">How many of them?</label>
                    <input
                      id="numberOfForeignShareholdersPrevYear"
                      {...formik.getFieldProps("numberOfForeignShareholdersPrevYear")}
                      className="form-control"
                      type="number"
                    />
                    {formik.touched.numberOfForeignShareholdersPrevYear
                      && formik.errors.numberOfForeignShareholdersPrevYear ? (
                      <div className="text-danger">{formik.errors.numberOfForeignShareholdersPrevYear}</div>
                      ) : null}
                  </div>
                </div>
              )}
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="needsFondoBookkeepingPrevYear">
                    Would you like Fondo to complete your {taxYearInfoQuery?.data?.prevYear}
                    &nbsp;bookkeeping and prepare these Financial Statements for taxes?
                  </label>
                  <br />
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="needsFondoBookkeepingPrevYear"
                      value={"Yes"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>Yes</label>
                  </div>
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="needsFondoBookkeepingPrevYear"
                      value={"No"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>No</label>
                  </div>
                  <ErrorMessage component="div" className="text-danger" name="needsFondoBookkeepingPrevYear" />
                </div>
              </div>
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="hasInternationalSubsidiariesPrevYear">
                    Do you have any international subsidiaries in {taxYearInfoQuery?.data?.prevYear}?
                  </label>
                  <br />
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="hasInternationalSubsidiariesPrevYear"
                      value={"Yes"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>Yes</label>
                  </div>
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="hasInternationalSubsidiariesPrevYear"
                      value={"No"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>No</label>
                  </div>
                  <ErrorMessage component="div" className="text-danger" name="hasInternationalSubsidiariesPrevYear" />
                </div>
              </div>
              {formik.values.hasInternationalSubsidiariesPrevYear === "Yes" && (
                <div className="row mb-3 mt-2">
                  <div className="col-12 col-md-12">
                    <label htmlFor="numberOfInternationalSubsidiariesPrevYear">How many of them?</label>
                    <input
                      id="numberOfInternationalSubsidiariesPrevYear"
                      {...formik.getFieldProps("numberOfInternationalSubsidiariesPrevYear")}
                      className="form-control"
                      type="number"
                    />
                    {formik.touched.numberOfInternationalSubsidiariesPrevYear
                      && formik.errors.numberOfInternationalSubsidiariesPrevYear ? (
                      <div className="text-danger">{formik.errors.numberOfInternationalSubsidiariesPrevYear}</div>
                      ) : null}
                  </div>
                </div>
              )}
              <div className="row mb-3 mt-2">
                <div className="col-12 col-md-12">
                  <label htmlFor="domesticSubsidiariesPrevYear">
                    Domestic Subsidiaries in {taxYearInfoQuery?.data?.prevYear}?
                  </label>
                  <br />
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="domesticSubsidiariesPrevYear"
                      value={"Yes"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>Yes</label>
                  </div>
                  <div className={"form-check form-check-inline"}>
                    <Field
                      type="radio"
                      name="domesticSubsidiariesPrevYear"
                      value={"No"}
                      className={"form-check-input"}
                    />
                    <label className={"form-check-label"}>No</label>
                  </div>
                  <ErrorMessage component="div" className="text-danger" name="domesticSubsidiariesPrevYear" />
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={formValues}
        validationSchema={onboardingActivity2Schema}
        onSubmit={(values, { setErrors }) => {
          const activity: ActivityApiType = {
            section: 2,
            activitySection2: values,
          };
          activityMutation.mutate(activity, {
            onError: (err) => {
              const errors = err?.response?.data;
              if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
                setErrors(errors);
              }
            },
            onSuccess: () => {
              navigate("/onboarding/tax-plan");
            },
          });
        }}>
        {(formik) => (
          <Form>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8 card">
                <div className="card-body my-5 mx-3">
                  <div className="row mb-3 mt-2">
                    <div className="col-12 col-md-12">
                      <div className="col-12 col-md-12">
                        <label htmlFor="bookkeepingManagerCurrentYear">Who does the bookkeeping ?</label>
                        <Field type="text" name="bookkeepingManagerCurrentYear" className="form-control" />
                        <ErrorMessage component="div" className="text-danger" name="bookkeepingManagerCurrentYear" />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 mt-2">
                    <div className="col-12 col-md-12">
                      <label htmlFor="hasForeignBankAccountsCurrentYear">
                        Do you have any foreign accounts with $10,000 or more in {taxYearInfoQuery?.data?.currentYear}?
                      </label>
                      <br />
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="hasForeignBankAccountsCurrentYear"
                          value={"Yes"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>Yes</label>
                      </div>
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="hasForeignBankAccountsCurrentYear"
                          value={"No"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>No</label>
                      </div>
                      <ErrorMessage component="div" className="text-danger" name="hasForeignBankAccountsCurrentYear" />
                    </div>
                  </div>
                  {formik.values.hasForeignBankAccountsCurrentYear === "Yes" && (
                    <div className="row mb-3 mt-2">
                      <div className="col-12 col-md-12">
                        <label htmlFor="numberOfForeignBankAccountsCurrentYear">How many of them?</label>
                        <input
                          id="numberOfForeignBankAccountsCurrentYear"
                          {...formik.getFieldProps("numberOfForeignBankAccountsCurrentYear")}
                          className="form-control"
                          type="number"
                        />
                        {formik.touched.numberOfForeignBankAccountsCurrentYear
                          && formik.errors.numberOfForeignBankAccountsCurrentYear ? (
                          <div className="text-danger">{formik.errors.numberOfForeignBankAccountsCurrentYear}</div>
                          ) : null}
                      </div>
                    </div>
                  )}
                  <div className="row mb-3 mt-2">
                    <div className="col-12 col-md-12">
                      <label htmlFor="hasForeignShareholdersCurrentYear">
                        Do you have foreign shareholders that own more
                        than 25% in {taxYearInfoQuery?.data?.currentYear}?
                      </label>
                      <br />
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="hasForeignShareholdersCurrentYear"
                          value={"Yes"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>Yes</label>
                      </div>
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="hasForeignShareholdersCurrentYear"
                          value={"No"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>No</label>
                      </div>
                      <ErrorMessage component="div" className="text-danger" name="hasForeignShareholdersCurrentYear" />
                    </div>
                  </div>
                  {formik.values.hasForeignShareholdersCurrentYear === "Yes" && (
                    <div className="row mb-3 mt-2">
                      <div className="col-12 col-md-12">
                        <label htmlFor="numberOfForeignShareholdersCurrentYear">How many of them?</label>
                        <input
                          id="numberOfForeignShareholdersCurrentYear"
                          {...formik.getFieldProps("numberOfForeignShareholdersCurrentYear")}
                          className="form-control"
                          type="number"
                        />
                        {formik.touched.numberOfForeignShareholdersCurrentYear
                          && formik.errors.numberOfForeignShareholdersCurrentYear ? (
                          <div className="text-danger">{formik.errors.numberOfForeignShareholdersCurrentYear}</div>
                          ) : null}
                      </div>
                    </div>
                  )}
                  <div className="row mb-3 mt-2">
                    <div className="col-12 col-md-12">
                      <label htmlFor="needsFondoBookkeepingCurrentYear">
                        Would you like Fondo to complete your {taxYearInfoQuery?.data?.currentYear} bookkeeping and
                        prepare these Financial Statements for taxes?
                      </label>
                      <br />
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="needsFondoBookkeepingCurrentYear"
                          value={"Yes"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>Yes</label>
                      </div>
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="needsFondoBookkeepingCurrentYear"
                          value={"No"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>No</label>
                      </div>
                      <ErrorMessage component="div" className="text-danger" name="needsFondoBookkeepingCurrentYear" />
                    </div>
                  </div>
                  <div className="row mb-3 mt-2">
                    <div className="col-12 col-md-12">
                      <label htmlFor="needsMonthlyBookkeepingCurrentYear">Interested in Monthly Bookkeeping?</label>
                      <br />
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="needsMonthlyBookkeepingCurrentYear"
                          value={"Yes"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>Yes</label>
                      </div>
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="needsMonthlyBookkeepingCurrentYear"
                          value={"No"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>No</label>
                      </div>
                      <ErrorMessage component="div" className="text-danger" name="needsMonthlyBookkeepingCurrentYear" />
                    </div>
                  </div>
                  <div className="row mb-3 mt-2">
                    <div className="col-12 col-md-12">
                      <label htmlFor="hasInternationalSubsidiariesCurrentYear">
                        Do you have any international subsidiaries in {taxYearInfoQuery?.data?.currentYear}?
                      </label>
                      <br />
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="hasInternationalSubsidiariesCurrentYear"
                          value={"Yes"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>Yes</label>
                      </div>
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="hasInternationalSubsidiariesCurrentYear"
                          value={"No"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>No</label>
                      </div>
                      <ErrorMessage
                        component="div"
                        className="text-danger"
                        name="hasInternationalSubsidiariesCurrentYear"
                      />
                    </div>
                  </div>
                  {formik.values.hasInternationalSubsidiariesCurrentYear === "Yes" && (
                    <div className="row mb-3 mt-2">
                      <div className="col-12 col-md-12">
                        <label htmlFor="numberOfInternationalSubsidiariesCurrentYear">How many of them?</label>
                        <input
                          id="numberOfInternationalSubsidiariesCurrentYear"
                          {...formik.getFieldProps("numberOfInternationalSubsidiariesCurrentYear")}
                          className="form-control"
                          type="number"
                        />
                        {formik.touched.numberOfInternationalSubsidiariesCurrentYear
                          && formik.errors.numberOfInternationalSubsidiariesCurrentYear ? (
                          <div className="text-danger">
                            {formik.errors.numberOfInternationalSubsidiariesCurrentYear}
                          </div>
                          ) : null}
                      </div>
                    </div>
                  )}
                  <div className="row mb-3 mt-2">
                    <div className="col-12 col-md-12">
                      <label htmlFor="domesticSubsidiariesCurrentYear">
                        Domestic Subsidiaries in {taxYearInfoQuery?.data?.currentYear}?
                      </label>
                      <br />
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="domesticSubsidiariesCurrentYear"
                          value={"Yes"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>Yes</label>
                      </div>
                      <div className={"form-check form-check-inline"}>
                        <Field
                          type="radio"
                          name="domesticSubsidiariesCurrentYear"
                          value={"No"}
                          className={"form-check-input"}
                        />
                        <label className={"form-check-label"}>No</label>
                      </div>
                      <ErrorMessage component="div" className="text-danger" name="domesticSubsidiariesCurrentYear" />
                    </div>
                  </div>
                  {renderPrevYearForm(formik, formik.values.needsAssistanceWithTaxPrevYear === "Yes")}

                  {/* Using this to hide the Next Year values until we want to add them in.
                  Currently we have no 2024 Products */}
                  {false && <>
                    <div className="row mb-3 mt-5">
                      <div className="col-12 col-md-12">
                        <label htmlFor="needsAssistanceWithTaxNextYear">
                          Do you need assistance with your Tax Year {taxYearInfoQuery?.data?.nextYear} Return?
                        </label>
                        <br />
                        <div className={"form-check form-check-inline"}>
                          <Field
                            type="radio"
                            name="needsAssistanceWithTaxNextYear"
                            value={"Yes"}
                            className={"form-check-input"}
                          />
                          <label className={"form-check-label"}>Yes</label>
                        </div>
                        <div className={"form-check form-check-inline"}>
                          <Field
                            type="radio"
                            name="needsAssistanceWithTaxNextYear"
                            value={"No"}
                            className={"form-check-input"}
                          />
                          <label className={"form-check-label"}>No</label>
                        </div>
                        <ErrorMessage component="div" className="text-danger" name="needsAssistanceWithTaxNextYear" />
                      </div>
                    </div>
                    {formik.values.needsAssistanceWithTaxNextYear === "Yes" && (
                      <>
                        <div className="row mb-3 mt-2">
                          <div className="col-12 col-md-12">
                            <label htmlFor="totalExpensesNextYear">
                              Total Estimated {taxYearInfoQuery?.data?.nextYear} expenses
                            </label>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text input-side-box">$</span>
                              </div>
                              <Cleave
                                {...formik.getFieldProps("totalExpensesNextYear")}
                                options={{ numeral: true }}
                                placeholder=""
                                className="form-control"
                              />
                            </div>
                            {formik.touched.totalExpensesNextYear && formik.errors.totalExpensesNextYear ? (
                              <div className="text-danger">{formik.errors.totalExpensesNextYear}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3 mt-2">
                          <div className="col-12 col-md-12">
                            <label htmlFor="stateEmployeesNextYear">
                              How many states does the company have W2 employees or an office in during{" "}
                              {taxYearInfoQuery?.data?.nextYear}?
                            </label>
                            <input
                            {...formik.getFieldProps("stateEmployeesNextYear")}
                            className="form-control"
                            type="number"
                            />
                            {formik.touched.stateEmployeesNextYear && formik.errors.stateEmployeesNextYear ? (
                              <div className="text-danger">{formik.errors.stateEmployeesNextYear}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row mb-3 mt-2">
                          <div className="col-12 col-md-12">
                            <label htmlFor="hasForeignBankAccountsNextYear">
                              Do you have any foreign accounts with $10,000
                              or more in {taxYearInfoQuery?.data?.nextYear}?
                            </label>
                            <br />
                            <div className={"form-check form-check-inline"}>
                              <Field
                                type="radio"
                                name="hasForeignBankAccountsNextYear"
                                value={"Yes"}
                                className={"form-check-input"}
                              />
                              <label className={"form-check-label"}>Yes</label>
                            </div>
                            <div className={"form-check form-check-inline"}>
                              <Field
                                type="radio"
                                name="hasForeignBankAccountsNextYear"
                                value={"No"}
                                className={"form-check-input"}
                              />
                              <label className={"form-check-label"}>No</label>
                            </div>
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="hasForeignBankAccountsNextYear"
                            />
                          </div>
                        </div>
                        {formik.values.hasForeignBankAccountsNextYear === "Yes" && (
                          <div className="row mb-3 mt-2">
                            <div className="col-12 col-md-12">
                              <label htmlFor="numberOfForeignBankAccountsNextYear">How many of them?</label>
                              <input
                                id="numberOfForeignBankAccountsNextYear"
                                {...formik.getFieldProps("numberOfForeignBankAccountsNextYear")}
                                className="form-control"
                                type="number"
                              />
                              {formik.touched.numberOfForeignBankAccountsNextYear
                                && formik.errors.numberOfForeignBankAccountsNextYear ? (
                                <div className="text-danger">{formik.errors.numberOfForeignBankAccountsNextYear}</div>
                                ) : null}
                            </div>
                          </div>
                        )}
                        <div className="row mb-3 mt-2">
                          <div className="col-12 col-md-12">
                            <label htmlFor="hasForeignShareholdersNextYear">
                              Do you have foreign shareholders that own more
                              than 25% in {taxYearInfoQuery?.data?.nextYear}?
                            </label>
                            <br />
                            <div className={"form-check form-check-inline"}>
                              <Field
                                type="radio"
                                name="hasForeignShareholdersNextYear"
                                value={"Yes"}
                                className={"form-check-input"}
                              />
                              <label className={"form-check-label"}>Yes</label>
                            </div>
                            <div className={"form-check form-check-inline"}>
                              <Field
                                type="radio"
                                name="hasForeignShareholdersNextYear"
                                value={"No"}
                                className={"form-check-input"}
                              />
                              <label className={"form-check-label"}>No</label>
                            </div>
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="hasForeignShareholdersNextYear"
                            />
                          </div>
                        </div>
                        {formik.values.hasForeignShareholdersNextYear === "Yes" && (
                          <div className="row mb-3 mt-2">
                            <div className="col-12 col-md-12">
                              <label htmlFor="numberOfForeignShareholdersNextYear">How many of them?</label>
                              <input
                                id="numberOfForeignShareholdersNextYear"
                                {...formik.getFieldProps("numberOfForeignShareholdersNextYear")}
                                className="form-control"
                                type="number"
                              />
                              {formik.touched.numberOfForeignShareholdersNextYear
                                && formik.errors.numberOfForeignShareholdersNextYear ? (
                                <div className="text-danger">{formik.errors.numberOfForeignShareholdersNextYear}</div>
                                ) : null}
                            </div>
                          </div>
                        )}
                        <div className="row mb-3 mt-2">
                          <div className="col-12 col-md-12">
                            <label htmlFor="needsFondoBookkeepingNextYear">
                              Would you like Fondo to complete your {taxYearInfoQuery?.data?.nextYear} bookkeeping and
                              prepare these Financial Statements for taxes?
                            </label>
                            <br />
                            <div className={"form-check form-check-inline"}>
                              <Field
                                type="radio"
                                name="needsFondoBookkeepingNextYear"
                                value={"Yes"}
                                className={"form-check-input"}
                              />
                              <label className={"form-check-label"}>Yes</label>
                            </div>
                            <div className={"form-check form-check-inline"}>
                              <Field
                                type="radio"
                                name="needsFondoBookkeepingNextYear"
                                value={"No"}
                                className={"form-check-input"}
                              />
                              <label className={"form-check-label"}>No</label>
                            </div>
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="needsFondoBookkeepingNextYear"
                            />
                          </div>
                        </div>
                        <div className="row mb-3 mt-2">
                          <div className="col-12 col-md-12">
                            <label htmlFor="hasInternationalSubsidiariesNextYear">
                              Do you have any international subsidiaries in {taxYearInfoQuery?.data?.nextYear}?
                            </label>
                            <br />
                            <div className={"form-check form-check-inline"}>
                              <Field
                                type="radio"
                                name="hasInternationalSubsidiariesNextYear"
                                value={"Yes"}
                                className={"form-check-input"}
                              />
                              <label className={"form-check-label"}>Yes</label>
                            </div>
                            <div className={"form-check form-check-inline"}>
                              <Field
                                type="radio"
                                name="hasInternationalSubsidiariesNextYear"
                                value={"No"}
                                className={"form-check-input"}
                              />
                              <label className={"form-check-label"}>No</label>
                            </div>
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="hasInternationalSubsidiariesNextYear"
                            />
                          </div>
                        </div>
                        {formik.values.hasInternationalSubsidiariesNextYear === "Yes" && (
                          <div className="row mb-3 mt-2">
                            <div className="col-12 col-md-12">
                              <label htmlFor="numberOfInternationalSubsidiariesNextYear">How many of them?</label>
                              <input
                                id="numberOfInternationalSubsidiariesNextYear"
                                {...formik.getFieldProps("numberOfInternationalSubsidiariesNextYear")}
                                className="form-control"
                                type="number"
                              />
                              {formik.touched.numberOfInternationalSubsidiariesNextYear
                                && formik.errors.numberOfInternationalSubsidiariesNextYear ? (
                                <div className="text-danger">
                                  {formik.errors.numberOfInternationalSubsidiariesNextYear}
                                </div>
                                ) : null}
                            </div>
                          </div>
                        )}
                        <div className="row mb-3 mt-2">
                          <div className="col-12 col-md-12">
                            <label htmlFor="domesticSubsidiariesNextYear">
                              Domestic Subsidiaries in {taxYearInfoQuery?.data?.nextYear}?
                            </label>
                            <br />
                            <div className={"form-check form-check-inline"}>
                              <Field
                                type="radio"
                                name="domesticSubsidiariesNextYear"
                                value={"Yes"}
                                className={"form-check-input"}
                              />
                              <label className={"form-check-label"}>Yes</label>
                            </div>
                            <div className={"form-check form-check-inline"}>
                              <Field
                                type="radio"
                                name="domesticSubsidiariesNextYear"
                                value={"No"}
                                className={"form-check-input"}
                              />
                              <label className={"form-check-label"}>No</label>
                            </div>
                            <ErrorMessage component="div" className="text-danger" name="domesticSubsidiariesNextYear" />
                          </div>
                        </div>
                      </>
                    )}
                  </>}

                  <hr className="row my-5" />
                  <div className="row mb-3 mt-2">
                    <div className="d-flex flex-row col-12 col-md-12 justify-content-between">
                      <button onClick={() => navigate("/onboarding/activity1")} className="btn btn-lg btn-secondary">
                        <div>Go Back</div>
                      </button>
                      <button className="btn btn-lg btn-primary" type="submit">
                        {activityMutation.isLoading ? <Spinner /> : <div>Continue</div>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
