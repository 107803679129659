import { useState } from "react";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";

type AmountFilterParams = {
  minAmountValue: number|string|undefined;
  maxAmountValue: number|string|undefined;
  setSelectedOption: (value: string|null) => void;
  handleAmountFilters: (
    minAmountValue: number|undefined,
    maxAmountValue: number|undefined,
  ) => void;
}

const AmountFilter = (
  {
    minAmountValue,
    maxAmountValue,
    setSelectedOption,
    handleAmountFilters,
  } : AmountFilterParams,
) => {
  const [minAmount, setMinAmount] = useState<string|number|undefined>(minAmountValue);
  const [maxAmount, setMaxAmount] = useState<string|number|undefined>(maxAmountValue);

  const applyFilters = () => {
    const min: number | undefined = (!!minAmount || minAmount === "0") ? Number(minAmount) : undefined;
    const max: number | undefined = (!!maxAmount || maxAmount === "0") ? Number(maxAmount) : undefined;
    handleAmountFilters(min, max);
    setSelectedOption(null);
  };

  const disableApplyButton = (
    (!!minAmount || minAmount === 0)
    && (!!maxAmount || maxAmount === 0)
    && Number(minAmount) > Number(maxAmount)
  );

  return (
    <Card.Body>
      <Row>
        AMOUNT
        <hr />
        <Form.Group controlId="minAmount" className="mb-3">
          <Form.Label>Minimum</Form.Label>
          <InputGroup>
            <span
              className={"input-group-text input-side-box"}
              style={{ borderRight: "0" }}
            >
              $
            </span>
            <Form.Control
              onChange={(e) => setMinAmount(e.target.value)}
              size="sm"
              value={minAmount}
              htmlSize={1}
              type="number"
              style={{ width: "3em" }}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="maxAmount" className="mb-3">
          <Form.Label>Maximum</Form.Label>
          <InputGroup>
            <span
              className={"input-group-text input-side-box"}
              style={{ borderRight: "0" }}
            >
              $
            </span>
            <Form.Control
              onChange={(e) => setMaxAmount(e.target.value)}
              size="sm"
              value={maxAmount}
              htmlSize={1}
              type="number"
              style={{ width: "3em" }}
            />
          </InputGroup>
        </Form.Group>
      </Row>
      <Row className="my-2 px-3">
        {disableApplyButton && (
          <span style={{ color: "red" } }>
            Minimum must be higher than Maximum
          </span>
        )}
        <Button
          onClick={() => applyFilters()}
          disabled={disableApplyButton}
        >
          Apply
        </Button>
      </Row>
      <Row className="my-2 px-3">
        <Button
          variant="btn btn-outline-secondary"
          onClick={() => setSelectedOption(null)}
        >
          Cancel
        </Button>
      </Row>
    </Card.Body>
  );
};

export default AmountFilter;
