import * as Yup from "yup";
import { FORM_ERROR_MSG } from "../../constants/general";
import { FieldTypes } from "../forms/formik-components/formTypes";

export const getDelawareFranchiseTaxFieldToLabelMap = (taxYear: string|number) => (
  {
    delawareAlreadyFiled: `Has your company already filed Delaware Franchise Tax for ${taxYear}?`,
    fondoHasAccessToAccountingSystem: "Have you given Fondo access to your accounting system (Quickbooks or Puzzle)?",
    fondoHasAccessToCapTable: "Have you given Fondo access to your Cap Table?",
    delawareFileNumber: "Delaware File Number",
    einEnc: "EIN",
    bankName: "Bank Name",
    routingNumberEnc: "Routing Number",
    accountNumberEnc: "Account Number",
    phone: "Phone",
    firstName: "First Name",
    lastName: "Last Name",
    title: "Title",
    taxIdEnc: "SSN",
  }
);

export const delawareFranchiseTaxFieldToTypeMap = {
  delawareAlreadyFiled: FieldTypes.YESNO,
  fondoHasAccessToAccountingSystem: FieldTypes.YESNO,
  fondoHasAccessToCapTable: FieldTypes.YESNO,
  delawareFileNumber: FieldTypes.TEXT,
  einEnc: FieldTypes.EIN,
  bankName: FieldTypes.TEXT,
  routingNumberEnc: FieldTypes.SSN,
  accountNumberEnc: FieldTypes.SSN,
  phone: FieldTypes.TEXT,
  firstName: FieldTypes.TEXT,
  lastName: FieldTypes.TEXT,
  title: FieldTypes.TEXT,
  taxIdEnc: FieldTypes.SSN,
};

export type DelawareFranchiseTaxInfo = {
  uuid?: string;
  delawareAlreadyFiled: "True"|"False";
  fondoHasAccessToAccountingSystem: "True"|"False";
  fondoHasAccessToCapTable: "True"|"False";
  delawareFileNumber: string|number;
  grossAssets: string|number;
  commonSharesApproved: string|number;
  preferredSharesApproved: string|number;
  commonSharesIssued: string|number;
  preferredSharesIssued: string|number;
}

export type OfficerInfo = {
  uuid?: string;
  firstName: string;
  lastName: string;
  title: string;
  signingOfficer: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  taxIdEnc: string;
}

export type IncorporationInfo = {
  uuid?: string;
  einEnc: string;
}

export type PaymentInfo = {
  id?: number;
  bankName: string;
  accountType: string;
  routingNumberEnc: string;
  accountNumberEnc: string;
}

export type AddressInfo = {
  uuid?: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;
}

export type Director = {
  uuid?: string;
  firstName: string;
  lastName: string;
  delete?: boolean;
};

export type DelawareFranchiseTaxFormFields = {
  dftInfo: DelawareFranchiseTaxInfo;
  officerInfo: OfficerInfo;
  incorporationInfo: IncorporationInfo;
  paymentInfo: PaymentInfo;
  addresssInfo: AddressInfo;
  boardOfDirectors: Director[];
  submitted?: boolean;
  markAsSigningOfficer?: boolean;
}

export const dftInfoSchema = Yup.object({
  delawareAlreadyFiled: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  delawareFileNumber: Yup.string().nullable().when("delawareAlreadyFiled", {
    is: "False",
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  fondoHasAccessToAccountingSystem: Yup.string().nullable().when("delawareAlreadyFiled", {
    is: "False",
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  fondoHasAccessToCapTable: Yup.string().nullable().when("delawareAlreadyFiled", {
    is: "False",
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  grossAssets: Yup.string().nullable().when(["fondoHasAccessToAccountingSystem", "delawareAlreadyFiled"], {
    is: (fondoHasAccessToAccountingSystem: string, delawareAlreadyFiled: string) => (
      fondoHasAccessToAccountingSystem === "False" && delawareAlreadyFiled === "False"
    ),
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  commonSharesApproved: Yup.string().nullable().when(["fondoHasAccessToCapTable", "delawareAlreadyFiled"], {
    is: (fondoHasAccessToCapTable: string, delawareAlreadyFiled: string) => (
      fondoHasAccessToCapTable === "False" && delawareAlreadyFiled === "False"
    ),
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  preferredSharesApproved: Yup.string().nullable().when(["fondoHasAccessToCapTable", "delawareAlreadyFiled"], {
    is: (fondoHasAccessToCapTable: string, delawareAlreadyFiled: string) => (
      fondoHasAccessToCapTable === "False" && delawareAlreadyFiled === "False"
    ),
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  commonSharesIssued: Yup.string().nullable().when(["fondoHasAccessToCapTable", "delawareAlreadyFiled"], {
    is: (fondoHasAccessToCapTable: string, delawareAlreadyFiled: string) => (
      fondoHasAccessToCapTable === "False" && delawareAlreadyFiled === "False"
    ),
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
  preferredSharesIssued: Yup.string().nullable().when(["fondoHasAccessToCapTable", "delawareAlreadyFiled"], {
    is: (fondoHasAccessToCapTable: string, delawareAlreadyFiled: string) => (
      fondoHasAccessToCapTable === "False" && delawareAlreadyFiled === "False"
    ),
    then: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  }),
});

export const officerInfoSchema = Yup.object({
  firstName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  lastName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  title: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  city: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  taxIdEnc: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
});

export const incorporationInfoSchema = Yup.object({
  einEnc: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
});

export const addressInfoSchema = Yup.object({
  streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  city: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  phone: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
});

export const paymentInfoSchema = Yup.object({
  bankName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  accountType: Yup.string().nullable().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  routingNumberEnc: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  accountNumberEnc: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
});

export const delawareFranchiseTaxFormSchema = Yup.object({
  dftInfo: dftInfoSchema,
  officerInfo: Yup.mixed().when("dftInfo.delawareAlreadyFiled", {
    is: "True",
    then: Yup.object().notRequired(),
    otherwise: officerInfoSchema,
  }),
  incorporationInfo: Yup.mixed().when("dftInfo.delawareAlreadyFiled", {
    is: "True",
    then: Yup.object().notRequired(),
    otherwise: incorporationInfoSchema,
  }),
  paymentInfo: Yup.mixed().when("dftInfo.delawareAlreadyFiled", {
    is: "True",
    then: Yup.object().notRequired(),
    otherwise: paymentInfoSchema,
  }),
  addressInfo: Yup.mixed().when("dftInfo.delawareAlreadyFiled", {
    is: "True",
    then: Yup.object().notRequired(),
    otherwise: addressInfoSchema,
  }),
});
