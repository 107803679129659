import axios, { AxiosHeaderValue, AxiosResponse, ResponseType } from "axios";
import { OpenAPIClientAxios } from "openapi-client-axios";
import { ProgressManagerStatus, ProgressManagerStatusResponse } from "../common/type";
import { cookies } from "../components/App";
import { BOT_TRACK, REACTIFY_API } from "../constants/network-calls";
import { BotTrack, Country, Subdivision } from "./util.type";
import { API_BASE_URL } from "../constants/config";
import { CSRF_COOKIE_NAME } from "../constants/general";

import openApiRuntimeDefinition from "../assets/schema/openapi-runtime.json";
import type { Client as FondoClient } from "../types/openapi.d";

const getClientHeaders = () => {
  const csrfCookie = cookies.get(CSRF_COOKIE_NAME);
  const headers: { [key: string]: AxiosHeaderValue } = { "X-CSRFToken": csrfCookie };
  const reportUuid = getReportUuidFromUrl(window.location.pathname);
  if (reportUuid) {
    headers.reportUuid = reportUuid;
  }
  return headers;
};

const api = new OpenAPIClientAxios({
  definition: openApiRuntimeDefinition,
  axiosConfigDefaults: {
    withCredentials: true,
  },
  withServer: 0,
});

export const getApiClient = async () => {
  const client = await api.getClient<FondoClient>();
  client.defaults.headers = { ...client.defaults.headers, ...getClientHeaders() };
  return client;
};

export async function getCountries(): Promise<Country[]> {
  const client = await getApiClient();
  const result = await client.listAddressCountries();
  return result.data;
}

export async function getCountrySubdivisions(params: any): Promise<Subdivision[]> {
  const [, countryCode] = params.queryKey;
  const client = await getApiClient();
  const result = await client.listAddressSubdivisions({ cc: countryCode });
  return result.data;
}

export const getProgressStatus = async (url: string): Promise<ProgressManagerStatusResponse> => {
  const result = (await getFondoApi(
    url,
    {},
    {
      ignoreReactifyApi: true,
      returnAxiosResponse: true,
    },
  )) as AxiosResponse<ProgressManagerStatus>;
  return { data: result.data, status: result.status };
};

export const getBotTrack = async (botTrackUuid: string) => {
  const result = await getFondoApi(
    `${BOT_TRACK}${botTrackUuid}`,
  );
  return result as BotTrack;
};

export const evaluateBotTrack = async(botTrackUuid: string, wasAccurate: boolean|null) => {
  const result = await patchFondoApi(
    `${BOT_TRACK}${botTrackUuid}/evaluate/`,
    { wasAccurate }
  );
  return result as BotTrack;
}

// <-------- Central Fondo API -------->

type FondoApiOptions = {
  ignoreReactifyApi?: boolean;
  returnAxiosResponse?: boolean;
  responseType?: ResponseType;
};

type AdditionalApiData = {
  data?: object;
  params?: object;
  options?: FondoApiOptions;
};

const formatUrl = (subdomain: string, endpoint: string) => {
  /*
  Copied from TaskFlow utils. Will remove or add a "/"
  depending of if the subdomain and endpoint both have one or
  neither has one
  */
  if (subdomain.endsWith("/") && endpoint.startsWith("/")) {
    subdomain = subdomain.slice(0, -1);
  } else if (!subdomain.endsWith("/") && !endpoint.startsWith("/")) {
    subdomain += "/";
  }
  return subdomain + endpoint;
};

const createApiEndpoint = (path: string, ignoreBackend?: boolean): string => {
  /*
  Inspired from the TaskFlow `getBackendApiDomain. Gets the Reactify API endpoint and appends the selected url to it.
  We have the REACTIFY_API conditionally added since some endpoints don't hit it.
  */
  let url = `${API_BASE_URL}/`;
  const addReactifyApi = !ignoreBackend;
  if (addReactifyApi) {
    url += REACTIFY_API;
  }
  return formatUrl(url, path);
};

function getReportUuidFromUrl(url: string) {
  const regex = /^\/company\/[^/]+\/reporting\/([^/]+)/;
  const match = url.match(regex);

  if (match && match[1]) {
    const reportUuid = match[1];
    return reportUuid;
  }
}

export const callFondoApi = async (method: string, url: string, { data, params, options }: AdditionalApiData) => {
  url = createApiEndpoint(url, options?.ignoreReactifyApi);
  const responseType = options?.responseType || "json";
  const response = await axios({
    method,
    url,
    data,
    params,
    headers: getClientHeaders(),
    responseType,
    withCredentials: true,
  });
  if (options?.returnAxiosResponse) {
    return response;
  }
  return response.data;
};

export const getFondoApi = (url: string, params?: object, options?: FondoApiOptions): Promise<object> =>
  callFondoApi("GET", url, { params, options });

// Normally you don't want POST, PATCH, or PUT methods to allow query params, but
// some calls we make are using them so we'll allow them... for now. In the future,
// we'll want to change up the endpoints and then remove the query params from here
export const patchFondoApi = (url: string, data: object, params?: object, options?: FondoApiOptions): Promise<object> =>
  callFondoApi("PATCH", url, { data, params, options });

export const postFondoApi = (url: string, data: object, params?: object, options?: FondoApiOptions): Promise<object> =>
  callFondoApi("POST", url, { data, params, options });

export const putFondoApi = (url: string, data: object, params?: object, options?: FondoApiOptions): Promise<object> =>
  callFondoApi("PUT", url, { data, params, options });

export const deleteFondoApi = (url: string, params?: object, options?: FondoApiOptions): Promise<object> =>
  callFondoApi("DELETE", url, { params, options });
