import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { FINANCE_DASHBOARD_TRANSACTIONS } from "../../../constants/network-calls";

export const getVerifyTransactionsOverlay = () => (
  <Link to={FINANCE_DASHBOARD_TRANSACTIONS}>
    <Alert variant="danger" className="fs-5">
      <FeatherIcon
        className="me-2 mt-n1"
        icon="alert-triangle"
        size="1.2em"
      />
      Please verify your transactions in order to see accurate data. Click here.
    </Alert>
  </Link>
);
