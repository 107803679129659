import { CardGroup } from "react-bootstrap";
import LoadingProfitAndLossSparkChartsCards from "./LoadingProfitAndLossSparkChartsCards";

type LoadingProfitAndLossSparkChartsCardRowProps = {
  showGrowthRate?: boolean;
  showCompoundGrowthRate?: boolean;
}

const LoadingProfitAndLossSparkChartsCardRow = ({
  showGrowthRate = true,
  showCompoundGrowthRate = true,
}: LoadingProfitAndLossSparkChartsCardRowProps) => (
  <CardGroup style={ { gap: "1rem" } } className="mb-4">
    <LoadingProfitAndLossSparkChartsCards title="Revenue" />
    <LoadingProfitAndLossSparkChartsCards title="Expenses" />
    <LoadingProfitAndLossSparkChartsCards title="Net Income" />
    {showGrowthRate && (
      <LoadingProfitAndLossSparkChartsCards title="Growth Rate" />
    )}
    {showCompoundGrowthRate && showCompoundGrowthRate && (
      <LoadingProfitAndLossSparkChartsCards title="Compound Growth Rate" />
    )}
  </CardGroup>
);

export default LoadingProfitAndLossSparkChartsCardRow;
