import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

type PercentageFieldProps = {
  value: string | number | string[] | undefined;
  placeholder?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: "sm" | "lg";
}

const PercentageField = (
  {
    value, placeholder, name, onChange, onBlur, size = "sm",
  }: PercentageFieldProps,
) => {
  const defaultMaskOptions = {
    prefix: "",
    suffix: "%",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: false,
    decimalSymbol: ".",
    decimalLimit: 0, // how many digits allowed after the decimal
    integerLimit: 3, // limit length of integer numbers
    // max: 100,
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask(defaultMaskOptions);

  const handleChange = (newValue: React.ChangeEvent<HTMLInputElement>) => {
    const strValue = newValue.target.value;
    const numValue = Number(strValue.replace("%", ""));
    if (numValue >= 100) {
      newValue.target.value = "100%";
    }
    onChange(newValue);
  };

  return (
    <>
      <MaskedInput
        mask={currencyMask}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        className={`form-control ${size === "sm" ? "m-0 p-1" : ""}`}
        max={100}
      />
    </>
  );
};

export default PercentageField;
