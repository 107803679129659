import * as Yup from "yup";
import { FormikProps } from "formik";
import { FieldTypes } from "../forms/formik-components/formTypes";
import { FORM_ERROR_MSG } from "../../constants/general";
import { Post2023CorporateTaxStep } from "./post-2023/post2023CorporateTax.type";

export type CorporateTaxStepProps = {
  stepInfo: CorporateTaxStep | Post2023CorporateTaxStep;
  data?: any;
};

export type CorporateTaxStep = {
  name: string;
  displayLabel: string;
  icon: string;
  enabled: boolean | ((formik: FormikProps<CorpTaxFormFields>) => boolean);
  taxYear?: string;
};

export type CorpTaxFormFields = {
  submitted?: boolean;
  incorporation: IncorporationFormFields;
  address: AddressFormFields;
  officers: OfficerFormFields[];
  companyInfo: CompanyInfoFormFields;
  researchExpenses: ResearchExpenseFormFields;
  majorityShareholders: MajorityShareholderFormFields[];
  usSubs: USSubsidiaryFormFields[];
  foreignBanks: ForeignBankAccountFormFields[];
  // additionalInfo: AdditionalInfoFormFields;
  oldOfficers: string[];
  oldMajorityShareholders: string[];
  oldForeignBanks: string[];
  oldUsSubs: string[];
}

export type IncorporationFormFields = {
  uuid?: string;
  legalName?: string;
  dbaName?: string;
  EIN?: string;
  incorporationDate?: Date;
  fiscalYearEndMonth?: string;
  fiscalYearEndDay?: string;
  businessStartDate?: Date;
  incorporationState?: string;
};

export const incorporationSchema = Yup.object({
  legalName: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  dbaName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  EIN: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  incorporationDate: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  fiscalYearEnd: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  businessStartDate: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  incorporationState: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();

export const incorporationFieldToLabelMap = {
  legalName: "Legal Name:",
  name: "DBA Name (if applicable):",
  einEnc: "EIN:",
  incorporationDate: "Date of Incorporation:",
  fiscalYearEnd: "Fiscal Year-End:",
  businessStartDate: "Date Business Started:",
  incorporationState: "State of Incorporation:",
};

export const incorporationFieldToPlaceholderMap = {
  legalName: "Bloomjoy, Inc.",
  name: "Fondo",
};

export const incorporationFieldToTypeMap = {
  legalName: FieldTypes.TEXT,
  name: FieldTypes.TEXT,
  einEnc: FieldTypes.EIN,
  incorporationDate: FieldTypes.DATE,
  fiscalYearEnd: FieldTypes.DATE_OF_YEAR,
  businessStartDate: FieldTypes.TEXT,
  incorporationState: FieldTypes.STATE,
};

export type AddressFormFields = {
  uuid?: string;
  country?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  phone?: string;
  description?: string;
  stateIdNumber?: string;
};

export const addressSchema = Yup.object({
  streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  city: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  phone: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  description: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  stateIdNumber: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();

export const addressFieldToLabelMap = {
  country: "Country:",
  streetAddress: "Business Street Address (Principal place of business/HQ):",
  city: "City:",
  state: "State:",
  postalCode: "Postal Code:",
  phone: "Business Phone Number:",
  description: "Brief description of what your business does:",
  stateIdNumber: "State (of Business Address) Corporate ID:",
};

export const addressFieldToTypeMap = {
  phone: FieldTypes.TEXT,
  description: FieldTypes.TEXTAREA,
  stateIdNumber: FieldTypes.TEXT,
};

export type OfficerFormFields = {
  uuid?: string;
  id?: number;
  firstName?: string;
  lastName?: string;
  title?: string;
  signingOfficer?: boolean
  streetAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  taxIdEnc?: string;
};

export const officerSchema = Yup.object({
  firstName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  lastName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  title: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  signingOfficer: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  city: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  taxIdEnc: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();

export const officerFieldToLabelMap = {
  firstName: "First Name:",
  lastName: "Last Name:",
  title: "Title:",
  signingOfficer: "Signing officer:",
  taxIdEnc: "Tax ID:",
};

export const officerFieldToTypeMap = {
  firstName: FieldTypes.TEXT,
  lastName: FieldTypes.TEXT,
  title: FieldTypes.TEXT,
  signingOfficer: FieldTypes.CHECKBOX,
  taxIdEnc: FieldTypes.TEXT,
};

export type USSubsidiaryFormFields = {
  uuid?: string;
  id?: number;
  name?: string;
  dateAcquired?: Date;
  taxIdentificationNumberEnc?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  phone?: string;
  owners?: string;
  fiscalYearEnd?: string;
};

export const usSubsidiarySchema = Yup.object({
  name: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  dateAcquired: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  taxIdentificationNumberEnc: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  city: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD).min(5, FORM_ERROR_MSG.REQUIRED_FIELD),
  postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  phone: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  owners: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  fiscalYearEnd: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();

export const usSubsidiaryFieldsToTypeMap = {
  name: FieldTypes.TEXT,
  dateAcquired: FieldTypes.DATE,
  taxIdentificationNumberEnc: FieldTypes.EIN,
  phone: FieldTypes.TEXT,
  owners: FieldTypes.TEXTAREA,
  fiscalYearEnd: FieldTypes.DATE_OF_YEAR,
};

export const usSubsidiaryFieldsToLabelMap = {
  name: "Name:",
  dateAcquired: "Date Acquired:",
  taxIdentificationNumberEnc: "Tax Identification Number:",
  phone: "Phone:",
  owners: "Owners:",
  fiscalYearEnd: "Fiscal Year End:",
};

export type ForeignBankAccountFormFields = {
  uuid?: string;
  bankName: string;
  type: string;
  accountNumber: string;
  country: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  currency: string;
  maxValueDuringYear: number;
  dateOfMaxValue: string;
  openedDuringYear: string;
  closedDuringYear: string;
};

export const foreignBankAccountSchema = Yup.object({
  bankName: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  type: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  accountNumber: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  city: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  currency: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  maxValueDuringYear: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  dateOfMaxValue: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  openedDuringYear: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
  closedDuringYear: Yup.string().min(1, "Too short!").required(FORM_ERROR_MSG.REQUIRED_FIELD),
}).required();

export const foreignBankAccountsFieldToLabelMap = {
  bankName: "Name",
  type: "Type",
  accountNumber: "Account number",
  country: "Country",
  streetAddress: "Street Address",
  city: "City",
  state: "State",
  postalCode: "Postal Code",
  currency: "Currency",
  maxValueDuringYear: "Max value during year",
  dateOfMaxValue: "Date of max value",
  openedDuringYear: "Opened during year",
  closedDuringYear: "Closed during year",
};

export const foreignBankAccountsToTypeMap = {
  bankName: FieldTypes.TEXT,
  type: FieldTypes.TEXT,
  accountNumber: FieldTypes.TEXT,
  country: FieldTypes.TEXT,
  streetAddress: FieldTypes.TEXT,
  city: FieldTypes.TEXT,
  state: FieldTypes.TEXT,
  postalCode: FieldTypes.TEXT,
  currency: FieldTypes.BASIC_SELECTOR,
  maxValueDuringYear: FieldTypes.INTEGER,
  dateOfMaxValue: FieldTypes.DATE,
  openedDuringYear: FieldTypes.YESNO,
  closedDuringYear: FieldTypes.YESNO,
};

export type MajorityShareholderFormFields = {
  uuid?: string;
  id?: number;
  firstName?: string;
  lastName?: string;
  title?: string;
  percentageOwnership?: number;
  phone?: string;
  email?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  countryOfTaxResidence?: string;
  countryOfTaxResidenceTaxIdEnc?: string;
  countryOfCitizenship?: string;
  countryOfCitizenshipTaxIdEnc?: string;
  officer?: string;
  signingOfficer?: string;
};

export const majorityShareholderSchema = Yup.object({
  firstName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  lastName: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  title: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  percentageOwnership: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD).min(20),
  phone: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  email: Yup.string().email().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  streetAddress: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  city: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  state: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  postalCode: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  countryOfTaxResidence: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  countryOfTaxResidenceTaxIdEnc: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  countryOfCitizenship: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  countryOfCitizenshipTaxIdEnc: Yup.string().when(
    ["countryOfTaxResidence", "countryOfCitizenship"],
    {
      is: (countryOfTaxResidence: string, countryOfCitizenship: string) => (
        countryOfCitizenship && (countryOfCitizenship !== countryOfTaxResidence)
      ),
      then: (schema) => schema.required(FORM_ERROR_MSG.REQUIRED_FIELD),
    },
  ),
});

export const shareholderFieldToTypeMap = {
  firstName: FieldTypes.TEXT,
  lastName: FieldTypes.TEXT,
  title: FieldTypes.TEXT,
  percentageOwnership: FieldTypes.TEXT,
  phone: FieldTypes.TEXT,
  email: FieldTypes.TEXT,
  countryOfTaxResidence: FieldTypes.COUNTRY,
  countryOfTaxResidenceTaxIdEnc: FieldTypes.TEXT,
  countryOfCitizenship: FieldTypes.COUNTRY,
  countryOfCitizenshipTaxIdEnc: FieldTypes.TEXT,
  officer: FieldTypes.YESNO,
  signingOfficer: FieldTypes.YESNO,
};

export const shareholderFieldToLabelMap = {
  firstName: "First Name:",
  lastName: "Last Name:",
  title: "Title:",
  percentageOwnership: "Percentage Ownership:",
  phone: "Phone:",
  email: "Email:",
  countryOfTaxResidence: "Country of Tax Residency:",
  countryOfTaxResidenceTaxIdEnc: "Country of Tax Residency - Tax ID:",
  countryOfCitizenship: "Country of Citizenship:",
  countryOfCitizenshipTaxIdEnc: "Country of Citizenship - Tax ID:",
  officer: "Officer:",
  signingOfficer: "Signing Officer:",
};

export type ResearchExpenseFormFields = {
  uuid?: string;
  wages?: string;
  contractorsExpenseDomestic?: string;
  contractorsExpenseInternational?: string;
  officeExpenses?: string;
  suppliesAndMaterials?: string;
  utilities?: string;
};

export const researchExpenseSchema = Yup.object({
  wages: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD).test(
    "testMinValue",
    FORM_ERROR_MSG.NUMBER_GT(0),
    (val) => parseInt(val || "", 10) >= 0,
  ),
  contractorsExpenseDomestic: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD).test(
    "testMinValue",
    FORM_ERROR_MSG.NUMBER_GT(0),
    (val) => parseInt(val || "", 10) >= 0,
  ),
  contractorsExpenseInternational: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD).test(
    "testMinValue",
    FORM_ERROR_MSG.NUMBER_GT(0),
    (val) => parseInt(val || "", 10) >= 0,
  ),
  officeExpenses: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD).test(
    "testMinValue",
    FORM_ERROR_MSG.NUMBER_GT(0),
    (val) => parseInt(val || "", 10) >= 0,
  ),
  suppliesAndMaterials: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD).test(
    "testMinValue",
    FORM_ERROR_MSG.NUMBER_GT(0),
    (val) => parseInt(val || "", 10) >= 0,
  ),
  utilities: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD).test(
    "testMinValue",
    FORM_ERROR_MSG.NUMBER_GT(0),
    (val) => parseInt(val || "", 10) >= 0,
  ),
});

export const researchExpenseFieldToTypeMap = {
  wages: FieldTypes.SELECT_PERCENTAGE,
  contractorsExpenseDomestic: FieldTypes.SELECT_PERCENTAGE,
  contractorsExpenseInternational: FieldTypes.SELECT_PERCENTAGE,
  officeExpenses: FieldTypes.SELECT_PERCENTAGE,
  suppliesAndMaterials: FieldTypes.SELECT_PERCENTAGE,
  utilities: FieldTypes.SELECT_PERCENTAGE,
};

export const researchExpenseFieldToLabelMap = {
  wages: "Wages",
  contractorsExpenseDomestic: "Contractors Expense (Domestic)",
  contractorsExpenseInternational: "Contractors Expense (International)",
  officeExpenses: "Office Expenses (Rent, Postage, R&M, Etc.)",
  suppliesAndMaterials: "Supplies & Materials",
  utilities: "Utilities",
};

export type CompanyInfoFormFields = {
  uuid?: string;
  hasShareholders?: string;
  addressRegistered?: string;
  addressStates?: string;
  employeeRegistered?: string;
  employeeStates?: string;
  sales?: string;
  salesStates?: string;
  conversion?: string;
  currencyFlag?: string;
  currencies?: string;
  employeeExerciseFlag?: string;
  auditFlag?: string;
  hasFiled1099?: string;
  additionalOffices?: string;
  additionalOfficesStates?: string;
  inventory?: string;
  inventoryStates?: string;
  rent?: string;
  rentStates?: string;
  fixedAssets?: string;
  fixedAssetsStates?: string;
  hasSubsidiaries?: string;
  hasForeignSubsidiaries?: string;
  numberOfForeignSubsidiaries?: number;
  hasForeignAccounts?: string;
  numberOfForeignAccounts?: number;
  additionalInfo?: string;
};

// NEED TO WORK ON THIS ONE BEFORE WE OPEN UP 2021/2022!
export const companyInfoSchema = Yup.object({
  hasShareholders: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  addressRegistered: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  // addressStates: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  employeeRegistered: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  // employeeStates: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  sales: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  // salesStates: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  conversion: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  currencyFlag: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  currencies: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  employeeExerciseFlag: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  auditFlag: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasFiled1099: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  additionalOffices: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  // additionalOfficesStates: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  inventory: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  // inventoryStates: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  rent: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  // rentStates: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  fixedAssets: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  // fixedAssetsStates: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasSubsidiaries: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasForeignSubsidiaries: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  numberOfForeignSubsidiaries: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  hasForeignAccounts: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  numberOfForeignAccounts: Yup.number().required(FORM_ERROR_MSG.REQUIRED_FIELD),
  additionalInfo: Yup.string().optional().nullable(),
});

export const companyInfoFieldToTypeMap = {
  hasShareholders: FieldTypes.YESNO,
  addressRegistered: FieldTypes.YESNO,
  addressStates: FieldTypes.TEXTAREA,
  employeeRegistered: FieldTypes.YESNO,
  employeeStates: FieldTypes.TEXTAREA,
  sales: FieldTypes.YESNO,
  salesStates: FieldTypes.TEXTAREA,
  conversion: FieldTypes.YESNO,
  currencyFlag: FieldTypes.YESNO,
  currencies: FieldTypes.TEXTAREA,
  employeeExerciseFlag: FieldTypes.YESNO,
  auditFlag: FieldTypes.YESNO,
  hasFiled1099: FieldTypes.YESNO,
  additionalOffices: FieldTypes.YESNO,
  additionalOfficesStates: FieldTypes.TEXTAREA,
  inventory: FieldTypes.YESNO,
  inventoryStates: FieldTypes.TEXTAREA,
  rent: FieldTypes.YESNO,
  rentStates: FieldTypes.TEXTAREA,
  fixedAssets: FieldTypes.YESNO,
  fixedAssetsStates: FieldTypes.TEXTAREA,
  hasSubsidiaries: FieldTypes.YESNO,
  hasForeignSubsidiaries: FieldTypes.YESNO,
  numberOfForeignSubsidiaries: FieldTypes.INTEGER,
  hasForeignAccounts: FieldTypes.YESNO,
  numberOfForeignAccounts: FieldTypes.INTEGER,
  additionalInfo: FieldTypes.TEXTAREA,
};

export const companyInfoFieldToLabelMap = {
  hasShareholders: "Do you have any shareholders who owned more than 20% in 2022?",
  addressRegistered: "Were you registered in the state where you had your business address in 2022?",
  addressStates: "Please list state and state Tax ID #",
  employeeRegistered: "Were you registered in the states(s) where you had W-2 employees or additional offices in 2022?",
  employeeStates: "Please list the state(s) and state Tax ID #(s)",
  sales: "Did you have sales in any state(s) greater than $500,000 in 2022?",
  salesStates: "Please list state(s) and state Tax ID #(s)",
  conversion: "Did your company convert from an LLC, S-Corp, or any type of entity in 2022?",
  currencyFlag: "Did you do business or hold any other currency other than US dollars in 2022?",
  currencies: "Please list currencies",
  employeeExerciseFlag: "Did any employees of the company exercise their options during 2022?",
  auditFlag: "Have you been audited or received a notification about a potential upcoming audit during 2022?",
  hasFiled1099: "Did you file 1099s for 2022?",
  additionalOffices: "Were you registered in the states(s) where you had additional offices in 2022?",
  additionalOfficesStates: "Please list the state(s) and state Tax ID #(s)",
  inventory: "Were you registered in the states(s) that you had inventory in during 2022?",
  inventoryStates: "Please list the state(s) and state Tax ID #(s)",
  rent: "Were you registered in the states(s) that you paid rent in 2022?",
  rentStates: "Please list the state(s) and state Tax ID #(s)",
  fixedAssets: "Were you registered in the states(s) that you had fixed assets in during 2022?",
  fixedAssetsStates: "Please list the state(s) and state Tax ID #(s)",
  hasSubsidiaries: "Do you have any U.S. subsidiaries for 2022?",
  hasForeignSubsidiaries: "Do you have any foreign subsidiaries for 2022?",
  numberOfForeignSubsidiaries: "How many?",
  hasForeignAccounts: "Do you have any foreign bank accounts 2022?",
  numberOfForeignAccounts: "How many?",
  additionalInfo: "Please let us know if there is any other information that will be "
    + "helpful or necessary to prepare your documents",
};

export const corporateTaxSchema = Yup.object({
  incorporation: incorporationSchema,
  address: addressSchema,
  officers: Yup.array(officerSchema),
  companyInfo: companyInfoSchema,
  researchExpenses: researchExpenseSchema,
  majorityShareholders: Yup.array(majorityShareholderSchema),
  usSubs: Yup.array(usSubsidiarySchema),
  foreignBanks: Yup.array(foreignBankAccountSchema),
});
