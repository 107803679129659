import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Table } from "react-bootstrap";
import { LineItemAPIData, OrderAPIData } from "../../onboarding/product/product.type";
import { markOrderAsVerified } from "../products.api";
import { formatCurrency } from "../../../utils/common.util";

type OrderTableProps = {
  order: OrderAPIData;
}

const OrderTable = ({ order }: OrderTableProps) => {
  const queryClient = useQueryClient();

  const verifyOrderMutation = useMutation({
    mutationFn: markOrderAsVerified,
    onSuccess: () => queryClient.invalidateQueries(["productOpenOrder"]),
  });

  const calculateTotalEstimate = (
    orderData: OrderAPIData,
  ): string => orderData.lineItems.reduce((total: number, lineItem: LineItemAPIData) => {
    const productActualPrice = Number(lineItem.price);
    if (!lineItem.actualUnitPriceTextOverride) {
      return total + productActualPrice;
    }
    return total;
  }, 0).toFixed(2);

  const getDiscountPrice = (lineItem: LineItemAPIData) => {
    const discountPrice = Number(lineItem.displayedUnitPrice) - Number(lineItem.actualUnitPrice);
    return discountPrice !== 0 ? `-$${discountPrice.toFixed(2)}` : "";
  };

  const getDisplayedUnitPrice = (lineItem: LineItemAPIData) => {
    if (lineItem.displayedUnitPriceTextOverride) {
      return lineItem.displayedUnitPriceTextOverride;
    }
    return buildPriceString(lineItem.displayedUnitPrice);
  };

  const getTotalPrice = (lineItem: LineItemAPIData) => {
    if (lineItem.actualUnitPriceTextOverride) {
      return lineItem.actualUnitPriceTextOverride;
    }
    return buildPriceString(lineItem.price);
  };

  const buildPriceString = (price: string) => (
    Number(price) > 0 ? `${formatCurrency(Number(price).toFixed(2))}` : "FREE"
  );

  const getTextOverride = () => order.lineItems.find(
    (lineitem) => lineitem.actualUnitPriceTextOverride,
  )?.actualUnitPriceTextOverride;

  const getTotalEstimate = (orderEstimate: OrderAPIData) => (
      <>
        <span>
          {buildPriceString(calculateTotalEstimate(orderEstimate))}
        </span>
        <br />
        {getTextOverride() && (
          <>
            <span className="fs-5">
              {getTextOverride()}
            </span>
            <br />
          </>
        )}
        {!orderEstimate.customerReviewed && orderEstimate.lineItems.length !== 0 && (
          <Button
            variant="primary"
            className="mt-3"
            as="a"
            onClick={() => verifyOrderMutation.mutate(orderEstimate.uuid)}
          >
            Verify Order
          </Button>
        )}
      </>
  );

  return (
    <Table className="mb-0" size="sm">
      <thead>
        <tr>
          <th>PRODUCT</th>
          <th>QTY</th>
          <th>PRICE</th>
          <th>DISCOUNT</th>
          <th>TOTAL ANNUAL COST</th>
        </tr>
      </thead>
      <tbody>
        {order.lineItems.map((lineItem) => (
          <tr key={lineItem.uuid}>
            <td>{lineItem.product.displayName}</td>
            <td>{lineItem.units > 1 ? lineItem.units : "-"}</td>
            <td>{getDisplayedUnitPrice(lineItem)}</td>
            <td>{getDiscountPrice(lineItem)}</td>
            <td>{getTotalPrice(lineItem)}</td>
          </tr>
        ))}
        <tr className="fw-bold h3">
          <td>Total Estimate</td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            {getTotalEstimate(order)}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default OrderTable;
