import { FormikProps } from "formik";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { Partner, Product, Review, ReviewData } from "../passport-partners.types";
import { UpsertReviewForm } from "./UpsertReviewForm";

type UpsertReviewModalProps = {
  show: boolean;
  onHide: () => void;
  product?: Product;
  partner: Partner;
};

export const UpsertReviewModal = ({ show, onHide, product, partner }: UpsertReviewModalProps) => {
  const existingReview =
    partner.existingReview || ({ partnerId: partner.uuid, productId: product?.uuid } as Review);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const renderModalFooter = (formikContext: FormikProps<ReviewData>) => (
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Cancel
      </Button>
      <Button variant={errorOccurred ? "danger" : "primary"} type="submit" disabled={!formikContext.isValid}>
        {errorOccurred ? "Try Again" : "Submit Review"}
      </Button>
    </Modal.Footer>
  );

  return (
    <Modal className="modal-over-modal" show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title as="h3">Rating and Review</Modal.Title>
      </Modal.Header>
      <UpsertReviewForm
        existingReview={existingReview}
        renderSubmitButtons={renderModalFooter}
        onSubmit={onHide}
        FormWrapper={Modal.Body}
        onUpsertError={() => setErrorOccurred(true)}
        onUpsertSuccess={() => setErrorOccurred(false)}
      />
    </Modal>
  );
};
