import { useState } from "react";
import { RouterProvider } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import FeatherIcon from "feather-icons-react";
import { Button } from "react-bootstrap";
import {
  QueryClient, QueryClientProvider,
} from "@tanstack/react-query";
import Cookie from "universal-cookie";
import { router } from "./router";
import { SidenavCollapsedContext } from "../context/SidenavCollapsedContext";
import { ProgressTimeLineContext } from "../context/ProgressTimelineContext";
import { BentoNoGuideToShowContext } from "../context/BentoNoGuideToShowContext";
import { PassportContext } from "../context/PassportContext";

export const queryClient = new QueryClient();
export const cookies = new Cookie();

function App() {
  const [isSidenavCollapsed, setIsSidenavCollapsed] = useState(false);
  const [noGuideToShow, setNoGuideToShow] = useState(false);

  const [currentStep, setCurrentStep] = useState("");
  const [passportOpen, setPassportOpen] = useState(false);

  return (
    <SidenavCollapsedContext.Provider value={[isSidenavCollapsed, setIsSidenavCollapsed]}>
      <PassportContext.Provider value={{ passportOpen, setPassportOpen }}>
        <ProgressTimeLineContext.Provider value={{ currentStep, setCurrentStep }}>
          <BentoNoGuideToShowContext.Provider value={{ noGuideToShow, setNoGuideToShow }}>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </BentoNoGuideToShowContext.Provider>
        </ProgressTimeLineContext.Provider>
      </PassportContext.Provider>
        <div
          className="position-fixed bottom-0 end-0"
          style={{ marginRight: "10px", marginBottom: "10px" }}
        >
          <Button
            variant="secondary"
            style={{ padding: "8px", borderRadius: "100%" }}
            href="mailto:team@tryfondo.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FeatherIcon icon='help-circle' size='2em' />
          </Button>
        </div>
    </SidenavCollapsedContext.Provider>
  );
}

export default App;
