import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { ProgressManagerStatus } from "../../../common/type";
import { AI_TRANSACTION_CATEGORIZATION, REACTIFY_API } from "../../../constants/network-calls";
import RealTimeProgressManagerStatusCard from "../../fusion-kit/RealTimeProgressManagerStatus";
import { categorizeNonVerifiedTransactionUsingAI } from "../FinanceDashboard.api";

const AITransactionCategorizationButton = () => {
  const [transactionsToCategorize, setTransactionsToCategorize] = useState<number | null>();
  const [verifyAICategorizationProgress, setVerifyAICategorizationProgress] = useState(true);
  const [enableAICategorizationButton, setEnableAICategorizationButton] = useState(true);

  useEffect(() => {
    if (verifyAICategorizationProgress) setEnableAICategorizationButton(false);
    else setEnableAICategorizationButton(true);
  }, [verifyAICategorizationProgress]);

  const categorizeTransactionsMutation = useMutation<any, AxiosError<any>, any>(
    categorizeNonVerifiedTransactionUsingAI,
  );

  const handleCategorizeTransactions = () => {
    setEnableAICategorizationButton(false);
    categorizeTransactionsMutation.mutate(null, {
      onSuccess: (data: ProgressManagerStatus) => {
        setVerifyAICategorizationProgress(true);
        setTransactionsToCategorize(data?.elementsToProcess);
      },
    });
  };

  if (verifyAICategorizationProgress) {
    return (
      <RealTimeProgressManagerStatusCard
        processingTitle={"Categorizing Transactions..."}
        elementsToProcessText={"Transactions to categorize"}
        numberOfElementsToProcess={transactionsToCategorize}
        elementsProcessedText={"Transactions categorized"}
        verifyProgress={verifyAICategorizationProgress}
        setVerifyProgress={setVerifyAICategorizationProgress}
        progressStatusURL={`${REACTIFY_API}${AI_TRANSACTION_CATEGORIZATION}`}
        queriesToRefresh={["transactionsList", "transactionsStats"]}
        refetchInterval={1200}
      />
    );
  }

  return (
    <Button
      onClick={handleCategorizeTransactions}
      className="py-2"
      size="sm"
      disabled={!!verifyAICategorizationProgress || !enableAICategorizationButton}
    >
      <span>
        {enableAICategorizationButton ? "Categorize Non Verified Transactions" : "Categorizing Transactions..."}
      </span>
      <FeatherIcon className="ms-2 mt-n1" icon="refresh-ccw" size="1.2em" />
    </Button>
  );
};

export default AITransactionCategorizationButton;
