import { Modal } from "react-bootstrap";
import { NewBankAccount } from "../finance-dashboard/financeDashboard.type";
import RunwayAuditToolManuallyAddForm from "./RunwayAuditToolManuallyAddForm";

type RunwayAuditToolManuallyAddFormModalProps = {
  show: boolean;
  handleClose: () => any;
  selectedMonth: string;
  handleAddBankAccount: (newBankAccount: NewBankAccount) => void;
};

const RunwayAuditToolManuallyAddFormModal = ({
  show,
  handleClose,
  selectedMonth,
  handleAddBankAccount,
}: RunwayAuditToolManuallyAddFormModalProps) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Add new Bank Account</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <RunwayAuditToolManuallyAddForm
        handleCloseModal={handleClose}
        selectedMonth={selectedMonth}
        handleAddBankAccount={handleAddBankAccount}
      />
    </Modal.Body>
  </Modal>
);

export default RunwayAuditToolManuallyAddFormModal;
