import { PASSPORT } from "../../constants/network-calls";
import { PassportField } from "./passport.type";
import {
  deleteFondoApi, getFondoApi, patchFondoApi, postFondoApi,
} from "../../utils/util.api";

export async function getPassport(): Promise<PassportField[]> {
  const result = await getFondoApi(PASSPORT);
  return result as Promise<PassportField[]>;
}

export async function postPassportField(passportField: PassportField): Promise<PassportField> {
  return await postFondoApi(PASSPORT, passportField) as PassportField;
}

export async function patchPassportField(passportField: PassportField): Promise<void> {
  await patchFondoApi(`${PASSPORT}${passportField.fieldName}/`, passportField);
}

export async function deletePassportField(passportField: PassportField): Promise<void> {
  await deleteFondoApi(`${PASSPORT}${passportField.fieldName}/`);
}

export async function reorderPassportFields(
  fieldName: string,
  newIndex: number,
): Promise<void> {
  await postFondoApi(
    `${PASSPORT}reorder_fields/`,
    { newIndex, fieldName },
  );
}
