import {
  Button, ButtonGroup, Dropdown, Form,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { formatDocumentUpdatedAt } from "./utils";
import { FondoFileType, SelectedItemType } from "./documents.types";

type FileRowProps = {
    file: FondoFileType;
    filesConst: string;
    handleCheckboxChange: (id: number, serviceName: string) => void;
    handleFileDownload: (file: FondoFileType) => void;
    handleAction: (event: any, item: SelectedItemType) => void;
    handleFavorite: (fileId: number) => void;
};

export const FileRow = ({
  file, filesConst, handleCheckboxChange, handleFileDownload, handleAction, handleFavorite,
}: FileRowProps) => {
  const favoriteColor = "#fce668"; // light gold
  const defaultColor = "white";
  const [starFillColor, setStarFillColor] = useState<string>(file.favorite ? favoriteColor : defaultColor);
  return (
        <tr key={`file-${file.id}`}>
            <td>
                <Form.Check
                    inline
                    onChange={() => handleCheckboxChange(file.id, filesConst)}
                    className="m-0"
                />
                <Button
                    variant="link"
                    onClick={() => {
                      setStarFillColor(starFillColor === favoriteColor ? defaultColor : favoriteColor);
                      handleFavorite(file.id);
                    }}
                    style={
                        {
                          color: "rgb(220, 230, 224)", // color matches checkbox color
                        }
                    }
                >
                    <FeatherIcon icon='star' size='24' fill={starFillColor} />
                </Button>
            </td>
            <td>
                <FeatherIcon icon='file' size='16' />
                &nbsp;&nbsp;
                <span
                    className='folder-file-name'
                    onClick={() => handleFileDownload(file)}
                >
                    {file.name}
                </span>
            </td>
            <td>Document</td>
            <td>{file.userName}</td>
            <td>{formatDocumentUpdatedAt(file.updatedAt)}</td>
            <td>
                <Dropdown as={ButtonGroup} style={{ position: "static" }}>
                    <Dropdown.Toggle
                        variant='primary'
                        style={{
                          marginRight: "10px",
                          backgroundColor: "white",
                          color: "black",
                        }}
                        className='vertical-dots-toggle'
                        id={`${file.id}`}
                    ></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={(event) => handleAction(event, {
                              id: file.id,
                              service: filesConst,
                            })
                            }
                        >
                            Rename
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={(event) => handleAction(event, {
                              id: file.id,
                              service: filesConst,
                            })
                            }
                        >
                            Move
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={(event) => handleAction(event, {
                              id: file.id,
                              service: filesConst,
                            })
                            }
                        >
                            Delete
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleFileDownload(file)}>
                            Download
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
  );
};
