import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import { setNestedObjectValues, useFormikContext } from "formik";
import fondoLogo from "../../assets/img/fondo-icon.png";
import SuccessfulSubmissionImg from "../imgs/rnd-submission-successful.png";
import { ForeignSubsidiaryFormFields } from "./foreignSubsidiary.type";
import { updateForeignSubsidiaryForms } from "./foreignSubsidiary.api";
import VideoExplanationButton, { HelpVideoType } from "../fondo-components/VideoExplanationButton";

type ForeignSubsidiaryFormHeaderProps = {
  taxYear: string;
  foreignSubsidiaryUuid: string;
  setIsValidating: (isSaving: boolean) => void;
  isValidating: boolean;
}

const ForeignSubsidiaryFormHeader = ({
  taxYear,
  foreignSubsidiaryUuid,
  setIsValidating,
  isValidating,
}: ForeignSubsidiaryFormHeaderProps) => {
  const formikContext = useFormikContext<ForeignSubsidiaryFormFields>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const formMutation = useMutation(
    (values: ForeignSubsidiaryFormFields) => updateForeignSubsidiaryForms(values, foreignSubsidiaryUuid),
    {
      onSuccess: (data: ForeignSubsidiaryFormFields) => {
        if (data.submitted && !formikContext.values.submitted) {
          setShowSubmittedModal(true);
        } else {
          setShowSavedProgressModal(true);
        }
        formikContext.resetForm({
          values: data,
        });
        setIsSaving(false);
        if (isValidating) {
          formikContext.validateForm().then((errors) => {
            formikContext.setTouched(setNestedObjectValues(errors, true));
          });
        }
        queryClient.invalidateQueries({
          queryKey: ["foreignSubsidiaryFormsData"],
        });
      },
      onError: () => {
        setShowSavedProgressModal(true);
        setProgressModalText(errorSubmittingText);
        setIsSaving(false);
      },
    },
  );

  const handleSaveProgress = () => {
    setProgressModalText(progressSavedText);
    formMutation.mutate(formikContext.values);
  };

  const handleSubmit = () => {
    setIsValidating(true);
    setIsSaving(true);
    formikContext.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        // Not submitting, still have errors
        formMutation.mutate(formikContext.values);
        setProgressModalText(errorSubmittingText);
      } else {
        // Submitting, no errors
        setProgressModalText(progressSavedText);
        formMutation.mutate({ ...formikContext.values, submitted: true });
      }
    });
  };

  const [showSavedProgressModal, setShowSavedProgressModal] = useState(false);
  const [showSubmittedModal, setShowSubmittedModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const errorSubmittingText = <>
    Errors were found in the form.<br/>
    However, your progress was saved.<br />
    Please correct the errors and submit again.
  </>;

  const progressSavedText = <>Progress saved!</>;
  const [progressModalText, setProgressModalText] = useState(progressSavedText);

  const handleModalClose = () => {
    setShowSubmittedModal(false);
    queryClient.invalidateQueries({
      queryKey: ["sidenavInfo"],
    });
    navigate(`/foreign_subsidiary/${taxYear}`);
  };

  return (
    <div className="py-4">
      <h1 className="header-title">
        <a
          className="navbar-brand me-5 d-inline d-sm-inline d-md-none"
          style={{ verticalAlign: "5px" }}
          href="/dashboard"
        >
          <img src={fondoLogo} alt="Fondo" className="navbar-brand-img mx-auto" />
        </a>
        Foreign Subsidiary for {taxYear}
        <VideoExplanationButton videoType={HelpVideoType.FSUB} />
        <button
          onClick={handleSubmit}
          id="submit-btn"
          disabled={isSaving}
          className="btn btn-outline-primary float-end btn-sm navigation-btn mx-2" value="Submit"
        >Submit</button>
        {!formikContext.values.submitted && (
          <button
            className="btn btn-primary btn-sm float-end"
            disabled={isSaving}
            onClick={handleSaveProgress}
          >Save progress</button>
        )}
      </h1>
      <Modal
        show={showSavedProgressModal}
        onHide={() => setShowSavedProgressModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{progressModalText}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button onClick={() => setShowSavedProgressModal(false)}>Ok</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showSubmittedModal}
        onHide={() => setShowSubmittedModal(false)}
      >
        <Modal.Body style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <img
            src={SuccessfulSubmissionImg}
            className="img-fluid" alt="Submission Successful"
            style={{ width: "50%" }}
          />
          <h3 className="mt-4 mb-3 text-center">Thank you!</h3>
          <h5 className="text-center text-secondary">
            If we need anything else, we will reach out.
          </h5>
          <Button className="mt-3 mb-3" size="sm" onClick={handleModalClose}>Close</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ForeignSubsidiaryFormHeader;
