import { FormikContextType } from "formik";
import {
  delawareFranchiseTaxFieldToTypeMap,
  getDelawareFranchiseTaxFieldToLabelMap,
} from "../DelawareFranchiseTax.type";
import FondoFormGroup from "../../forms/formik-components/FondoFormGroup";
import AddressFieldGroup from "../../forms/formik-components/AddressFieldGroup";

type SigningOfficerSectionProps = {
  formikContext: FormikContextType<any>;
  taxYear: string|number;
}

const SigningOfficerSection = ({ formikContext, taxYear }: SigningOfficerSectionProps) => (
  <>
    <div className="mt-5">
      <h2 className="m-0">Signing officer</h2>
    </div>
    <hr />
    <FondoFormGroup
      formikContext={formikContext}
      fieldName="officerInfo.firstName"
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
    />
    <FondoFormGroup
      formikContext={formikContext}
      fieldName="officerInfo.lastName"
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
    />
    <FondoFormGroup
      formikContext={formikContext}
      fieldName="officerInfo.title"
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
    />
    <AddressFieldGroup formikContext={formikContext} baseName="officerInfo"/>
    <FondoFormGroup
      formikContext={formikContext}
      fieldName="officerInfo.taxIdEnc"
      types={delawareFranchiseTaxFieldToTypeMap}
      labels={getDelawareFranchiseTaxFieldToLabelMap(taxYear)}
      enablePasswordType
      helperText={{
        type: "whatIsThis",
        body:
        <>
          Social Security Number. If no SSN, please provide Tax ID
        </>,
      }}
    />
  </>
);

export default SigningOfficerSection;
