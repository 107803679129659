import {
  Button, Card, Form, InputGroup, Table,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { FieldArray, useFormikContext } from "formik";
import CurrencyField from "../../forms/formik-components/CurrencyField";
import { RnDSectionProps } from "./RDPage2";

const RnDSuppliesSection = ({ setCurrentRowData, setShowDeleteModal }: RnDSectionProps) => {
  const formikContext = useFormikContext<any>();

  const addNewSupply = (pushFunc: (value: any) => void) => {
    pushFunc(
      {
        supplyName: "",
        description: "",
        cost: "",
      },
    );
  };

  return (
    <FieldArray
    name="rndRelatedSupplies"
    render={(arrayHelpers) => (
      <Card>
        <Card.Header>
          <h3 className='card-header-title'>R&D-Related Supplies</h3>
          <div className='ms-5'>
            <Button size="sm" className="ps-3 pe-3" onClick={() => addNewSupply(arrayHelpers.push)}>Add</Button>
          </div>
        </Card.Header>
        <Card.Body className='p-0'>
          <>
            {formikContext.values.rndRelatedSupplies?.length > 0 && (
              <Table className='table-nowrap mb-0 p-1'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th className="text-wrap" style={{ maxWidth: "120px" }}>Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {formikContext.values.rndRelatedSupplies.map((supply: any, index: number) => (
                    <tr className="p-1" key={index}>
                      <td className="m-5">
                        <Form.Control
                          value={supply.supplyName}
                          onChange={formikContext.handleChange}
                          onBlur={formikContext.handleBlur}
                          name={`rndRelatedSupplies[${index}].supplyName`}
                          className="m-2 p-1"
                        />
                      </td>
                      <td>
                        <Form.Control
                          value={supply.description}
                          onChange={formikContext.handleChange}
                          onBlur={formikContext.handleBlur}
                          name={`rndRelatedSupplies[${index}].description`}
                          className="m-2 p-1"
                        />
                      </td>
                      <td style={{ maxWidth: "140px" }}>
                        <InputGroup className="p-0 m-2" style={{ maxWidth: "140px" }}>
                          <CurrencyField
                            value={supply.cost}
                            onChange={formikContext.handleChange}
                            onBlur={formikContext.handleBlur}
                            name={`rndRelatedSupplies[${index}].cost`}
                            inline={true}
                          />
                        </InputGroup>
                      </td>
                      <td>
                      <FeatherIcon
                        onClick={() => {
                          setCurrentRowData({
                            arrayHelperFunc: arrayHelpers.remove,
                            index,
                            rndSupply: supply,
                          });
                          setShowDeleteModal(true);
                        }}
                        icon='trash'
                        size='17'
                        className='ms-2'
                        style={{ cursor: "pointer" }}
                      />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </>
        </Card.Body>
      </Card>
    )} />
  );
};

export default RnDSuppliesSection;
