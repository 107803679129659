import { Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FondoPartnerLogos } from "../FondoPartnerLogos";

type ConnectingModalProps = {
  show: boolean;
  onHide: () => void;
  partnerLogoUrl: string;
  isComplete: boolean;
};

export const ConnectingModal = ({ show, onHide, partnerLogoUrl, isComplete }: ConnectingModalProps) => {
  const [reactToComplete, setReactToComplete] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (isComplete) {
      timeout = setTimeout(() => {
        setReactToComplete(true);
      }, 2000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isComplete]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title as="h3">Creating Account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center p-6">
        <FondoPartnerLogos
          icon={reactToComplete ? "check-circle" : "arrow-right"}
          iconClassName={reactToComplete ? "text-success" : "text-secondary"}
          partnerLogoUrl={partnerLogoUrl}
          width="80%"
        />
        <p className="text-center fw-bold mt-5">
          {reactToComplete ? "Connection Initiated!" : "Connection in Progress"}
        </p>
        <p className="text-center">Once completed, the details will be sent to your email.</p>
        <div className="d-flex flex-no-wrap">
          <Button size="sm" variant="white" onClick={onHide}>
            Close
          </Button>
          {reactToComplete && (
            <Button size="sm" variant="primary" className="ms-2" onClick={onHide}>
              Done
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
