import { useState } from "react";
import { Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import TaxStatusCard from "./taxStatusCard";
import FondoLayout from "../fusion-kit/FondoLayout";
import { TaxStatusType } from "./taxStatus.type";

type TaxStatusProps = {
    taxStatusData: TaxStatusType[];
    isLoading: boolean;
    showAsWidget: boolean;
}

export default function TaxStatus({ taxStatusData, isLoading, showAsWidget }: TaxStatusProps) {

  const getYears = () => (
    Array.from(new Set(taxStatusData.map((item) => item.taxYear)))
  );

  const getProducts = () => (
    Array.from(new Set(taxStatusData.map((item) => item.serviceName)))
  );

  const completionStatusMap: { [key: string]: boolean | null } = {
    "Completed": true,
    "In Progress": false,
    "All": null,
  };

  const [selectedYear, setSelectedYear] = useState<string | null>(Math.max(...getYears()).toString());
  const [selectedCompletionStatus, setSelectedCompletionStatus] = useState<boolean | null>(false);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);


  const filteredTaxStatusData = taxStatusData?.filter((obj: TaxStatusType) => {
    if (selectedYear && obj.taxYear.toString() !== selectedYear) {
      return false;
    }
    if (selectedCompletionStatus !== null && obj.complete !== selectedCompletionStatus) {
      return false;
    }
    if (selectedProduct && obj.serviceName !== selectedProduct) {
      return false;
    }
    return true;
  });

  const getSelectedCompletionStatusName = () => {
    if (selectedCompletionStatus === null) {
      return "All";
    }
    return selectedCompletionStatus ? "Completed" : "In Progress";
  }

  function renderTaxStatusCards() {
    return (filteredTaxStatusData.length ? (
        <>
          {filteredTaxStatusData?.map((obj: TaxStatusType, index: number) => (
            <div className={index > 0 ? "border-top" : ""}>
              <div className={index > 0 ? "mt-3" : ""}>
                <TaxStatusCard taxStatus={obj} />
              </div>
            </div>
          ))}
        </>
        ):
        <span className="text-center text-muted">
          No results found. Try using fewer filters for more results.
        </span>);
  }

  function renderComponent() {
    return (<>
            <div className="card">
                <div className="card-header">
                    <h2 className="card-header-title">Status</h2>
                    <DropdownButton
                      variant="white"
                      size="sm"
                      title={selectedYear || "Tax Year"}
                      className="me-2"
                      onSelect={(year) => {
                        setSelectedYear(year);
                      }}
                    >
                      {getYears().map((year) => (
                        <Dropdown.Item key={year} eventKey={year}>
                          {year}
                        </Dropdown.Item>
                      ))}
                    <Dropdown.Item key="allYears">All</Dropdown.Item>
                    </DropdownButton>

                    <DropdownButton
                      variant="white"
                      size="sm"
                      title={getSelectedCompletionStatusName() || "Status"}
                      className="me-2"
                      onSelect={(status) => {
                        setSelectedCompletionStatus(completionStatusMap[status as string]);
                      }}
                    >
                      <Dropdown.Item key="completedStatus" eventKey={"Completed"}>
                          Completed
                      </Dropdown.Item>
                      <Dropdown.Item key="inProgressStatus" eventKey={"In Progress"}>
                          In Progress
                      </Dropdown.Item>
                      <Dropdown.Item key="allStatuses" eventKey={"All"}>
                        All
                      </Dropdown.Item>
                    </DropdownButton>

                    <DropdownButton
                      variant="white"
                      size="sm"
                      title={selectedProduct || "Product"}
                      onSelect={(product) => {
                        setSelectedProduct(product);
                      }}
                    >
                      {getProducts().map((product) => (
                        <Dropdown.Item key={product} eventKey={product}>
                          {product}
                        </Dropdown.Item>
                      ))}
                      <Dropdown.Item key="allProducts">All</Dropdown.Item>
                    </DropdownButton>
                </div>
                <div className="card-body">
                    <div className="list-group list-group-flush list-group-activity">
                        {isLoading
                          ? <Spinner className="text-primary" style={{ alignSelf: "center" }} />
                          : renderTaxStatusCards()}
                    </div>
                </div>
            </div>
        </>);
  }

  return (
        <>
            {showAsWidget ? (
              renderComponent()
            ) : (
                <FondoLayout title="Status">
                    {renderComponent()}
                </FondoLayout>
            )}
        </>
  );
}
