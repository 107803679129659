import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Cleave from "cleave.js/react";
import "./activity.style.css";
import { Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { getActivity1, saveActivity } from "./activity.api";
import { TaxYearInfoType } from "../onboarding.type";
import { FORM_ERROR_MSG } from "../../../constants/general";
import { ActivityApiType, ActivitySection1 } from "./activity.type";
import { TAX_YEAR_INFO } from "../../../constants/network-calls";
import { getTaxYearInfo } from "../onboarding.api";

export default function Activity1() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<ActivitySection1>({
    totalExpenses: "0",
    stateEmployees: "0",
  });
  const activityMutation = useMutation<any, AxiosError<any>, ActivityApiType>((data) => saveActivity(data));
  const taxYearInfoQuery = useQuery<TaxYearInfoType, Error>([TAX_YEAR_INFO], getTaxYearInfo, {});
  useQuery<ActivitySection1, Error>(["getActivity1"], getActivity1, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setFormValues(data);
    },
    onError: (e) => {
      console.error(e);
    },
  });

  return (
        <div>
            <Formik
                initialValues={formValues}
                enableReinitialize={true}
                validationSchema={Yup.object({
                  totalExpenses: Yup.string().required(FORM_ERROR_MSG.REQUIRED_FIELD),
                  stateEmployees: Yup.number()
                    .required(FORM_ERROR_MSG.REQUIRED_FIELD)
                    .min(0, FORM_ERROR_MSG.NUMBER_GT(0))
                    .max(50, FORM_ERROR_MSG.MAX_LENGTH(50)),
                })}
                onSubmit={(values, { setErrors }) => {
                  const activity: ActivityApiType = {
                    section: 1,
                    activitySection1: values,
                  };
                  activityMutation.mutate(activity, {
                    onError: (err) => {
                      const errors = err?.response?.data;
                      if (errors && Object.keys(errors).some((e) => Object.keys(values).indexOf(e) >= 0)) {
                        setErrors(errors);
                      }
                    },
                    onSuccess: () => {
                      navigate("/onboarding/activity2");
                    },
                  });
                }}>
                {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10 col-xl-8 card">
                                    <div className="card-body my-5 mx-3">
                                        <div className="row mb-3 mt-2">
                                            <div className="col-12 col-md-12">
                                                <label htmlFor="totalExpenses">
                                                  Total Estimated {taxYearInfoQuery?.data?.currentYear} expenses
                                                </label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text input-side-box">$</span>
                                                    </div>
                                                    <Cleave
                                                        {...formik.getFieldProps("totalExpenses")}
                                                        options={{ numeral: true }}
                                                        placeholder=""
                                                        className="form-control"
                                                    />
                                                </div>
                                                {formik.touched.totalExpenses && formik.errors.totalExpenses ? (
                                                    <div className="text-danger">{formik.errors.totalExpenses}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row mb-3 mt-2">
                                            <div className="col-12 col-md-12">
                                                <label htmlFor="stateEmployees">
                                                    How many states does the company have W2 employees
                                                    or an office in during{" "}
                                                    {taxYearInfoQuery?.data?.currentYear}?
                                                </label>
                                                <input
                                                  {...formik.getFieldProps("stateEmployees")}
                                                  className="form-control"
                                                  type="number" />
                                                {formik.touched.stateEmployees && formik.errors.stateEmployees ? (
                                                    <div className="text-danger">{formik.errors.stateEmployees}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <hr className="row my-5" />
                                        <div className="row mb-3 mt-2">
                                            <div className="d-flex flex-row col-12 col-md-12 justify-content-between ">
                                                <button
                                                  onClick={() => navigate("/onboarding/company2")}
                                                  className="btn btn-lg btn-secondary">
                                                    <div>Go Back</div>
                                                </button>
                                                <button className="btn btn-lg btn-primary" type="submit">
                                                    {activityMutation.isLoading ? <Spinner /> : <div>Continue</div>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                )}
            </Formik>
        </div>
  );
}
