import { useState } from "react";
import {
  Card,
  Row,
  Col,
  Nav,
  Spinner,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import CircleProgress from "../fondo-components/CircleProgress/CircleProgress";
import { ActionItemsService, ActionItemsUserServicesData } from "./actionItems.type";
import greenCheckCircle from "../../assets/img/icons/greenCheckCircle.png";
import {
  calculatePercentageOfActionItemServiceCompletion,
  isActionItemCompleted,
  isActionItemNotStarted,
} from "./utils";
import ActionItemServiceView from "./ActionItemServiceView";
import CircleBadge from "../fondo-components/CircleProgress/CircleBadge";

type ActionItemsOnboardingGuidedViewCardProps = {
  actionItemsUserServicesData: ActionItemsUserServicesData;
  switchToListView: () => void;
  actionItemsUserServicesDataIsLoading: boolean;
};

const ActionItemsOnboardingGuidedViewCard = ({
  actionItemsUserServicesData,
  switchToListView,
  actionItemsUserServicesDataIsLoading,
}: ActionItemsOnboardingGuidedViewCardProps) => {
  const [actionItemOnDisplayIndex, setActionItemOnDisplayIndex] = useState<number>(0);

  const handleShowPreviousActionItemService = () => {
    if (actionItemOnDisplayIndex > 0) {
      setActionItemOnDisplayIndex(actionItemOnDisplayIndex - 1);
    }
  };

  const handleShowNextActionItemService = () => {
    if (actionItemOnDisplayIndex < actionItemsUserServicesData.services.length - 1) {
      setActionItemOnDisplayIndex(actionItemOnDisplayIndex + 1);
    }
  };

  if (actionItemsUserServicesDataIsLoading) {
    return (
      <div className="row justify-content-center mt-5">
        <Spinner variant='primary' animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const getNumberOfCurrentActionItemOnDisplay = () => actionItemOnDisplayIndex + 1;

  return (
    <Card className="p-0">
      <Card.Header className="mb-0 py-4" style={{ height: "120px" }}>
        <div className="d-flex">
          <div className="me-3" style={{
            width: "70px", height: "70px", flexGrow: "0", flexShrink: "0",
          }}>
            {isActionItemCompleted(actionItemsUserServicesData.services[actionItemOnDisplayIndex]) ? (
              <img src={greenCheckCircle} style={{ width: "70px", height: "70px" }} />
            ) : (
              <CircleBadge
                text={`${actionItemOnDisplayIndex + 1}`}
                value={calculatePercentageOfActionItemServiceCompletion(
                  actionItemsUserServicesData.services[actionItemOnDisplayIndex],
                )}
              />
            )}
          </div>
          <div>
            <h4 className="text-secondary mb-3">
              {`ONBOARDING ${getNumberOfCurrentActionItemOnDisplay()}
              OF ${actionItemsUserServicesData?.services.length}`}
            </h4>
            <h1
              className={`${isActionItemCompleted(actionItemsUserServicesData.services[actionItemOnDisplayIndex])
                && "text-decoration-line-through"} mb-1`}>
              {actionItemsUserServicesData.services[actionItemOnDisplayIndex]?.name}
            </h1>
            <p className={`${actionItemsUserServicesData.services[
              actionItemOnDisplayIndex
            ]?.description ? "text-muted" : "text-white"} mb-0 pe-5`}>
              {actionItemsUserServicesData.services[
                actionItemOnDisplayIndex
              ]?.description || actionItemsUserServicesData.services[actionItemOnDisplayIndex]?.name}
            </p>
          </div>
        </div>
        <div>
          <h4 className="text-primary cursor-pointer my-0" onClick={switchToListView}>
            View All
          </h4>
        </div>
      </Card.Header>
      <Card.Body>
        <ActionItemServiceView
          actionItemComponentServiceId={actionItemsUserServicesData.services[actionItemOnDisplayIndex]?.id.toString(10)}
        />
      </Card.Body>
      <Card.Footer className="p-0">
        <Row className="px-3">
          <Col
            role="button"
            onClick={handleShowPreviousActionItemService}
            className="border-end d-flex justify-content-center align-items-center"
          >
            <span className="text-muted align-middle">
              <FeatherIcon icon="arrow-left" size="17" className="me-2 text-muted" />
              Prev
            </span>
          </Col>
          <Col className="col-xl-9 col-lg-8 col-6">
            <Nav variant="tabs" className="nav-tabs justify-content-center border-bottom-0">
              {actionItemsUserServicesData?.services?.map((actionItemsService: ActionItemsService, index: number) => (
                <Nav.Item key={index} className="mx-0">
                  {isActionItemNotStarted(actionItemsService) ? (
                    <Nav.Link
                      active={actionItemsUserServicesData.services[actionItemOnDisplayIndex]?.id
                        === actionItemsService.id}
                      onClick={() => setActionItemOnDisplayIndex(index)}
                      className="px-2"
                    >
                      <div className="text-center pt-1" style={{
                        width: "30px", height: "30px", flexGrow: "0", flexShrink: "0",
                      }}>
                        {index + 1}
                      </div>
                    </Nav.Link>
                  ) : (
                    <Nav.Link
                      active={actionItemsUserServicesData.services[actionItemOnDisplayIndex]?.id
                        === actionItemsService.id}
                      onClick={() => setActionItemOnDisplayIndex(index)}
                      className="px-2"
                    >
                      <div style={{
                        width: "30px", height: "30px", flexGrow: "0", flexShrink: "0",
                      }}>
                        {isActionItemCompleted(actionItemsService) ? (
                          <img
                            src={greenCheckCircle}
                            style={{ width: "30px", height: "30px" }}
                          />
                        ) : (
                          <CircleProgress
                            value={calculatePercentageOfActionItemServiceCompletion(actionItemsService)}
                            showValue={false}
                            centerText={`${index + 1}`}
                          />
                        )}
                      </div>
                    </Nav.Link>
                  )}
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col
            role="button"
            onClick={handleShowNextActionItemService}
            className="border-start d-flex justify-content-center align-items-center"
          >
            <span className="text-muted align-middle">Next</span>
            <FeatherIcon icon="arrow-right" size="17" className="ms-2 text-muted" />
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default ActionItemsOnboardingGuidedViewCard;
