import "./login.style.css";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { LoginInput } from "./login.type";
import * as utils from "../../utils/common.util";
import { login } from "./login.api";
import fondoLogo from "../../assets/img/fondo-icon.png";
import { RESET_PASSWORD } from "../../constants/network-calls";

const DEFAULT_WRONG_EMAIL_PASSWORD_RESPONSE = "Access denied: wrong username or password.";

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<LoginInput>();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const onSubmit: SubmitHandler<LoginInput> = (data) => {
    setLoading(true);
    clearErrors();
    login(data)
      .then(() => {
        navigate("/dashboard");
      })
      .catch((e) => {
        if (e.response?.data?.nonFieldErrors?.[0] === DEFAULT_WRONG_EMAIL_PASSWORD_RESPONSE) {
          setError("email", { type: "custom", message: "Incorrect Email or Password." });
        } else {
          console.log(e);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
        <div className="d-flex row vh-100 justify-content-center">
            <div className="d-flex flex-column col-12 col-md-6 col-xl-4 justify-content-center px-4">
                <div className="row mb-5">
                    <div className="d-flex col justify-content-center">
                        <img className="logo-img" src={fondoLogo} />
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="d-flex col justify-content-center">
                        <h1 className="display-4 mb-3">Welcome!</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="d-flex col justify-content-center">
                        <p className="text-muted mb-5">Please sign in.</p>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col">
                            <label className="form-label">Email Address</label>
                            <input
                                {...register("email", {
                                  required: "Email is required",
                                  validate: (email) => (utils.isEmailValid(email) ? true : "Email not valid"),
                                })}
                                className={`form-control ${errors.email && "is-invalid"}`}
                                placeholder="name@example.com"
                            />
                            {errors.email && (
                                <small className="text-danger" role="alert">
                                    {errors.email?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col justify-content-between">
                            <div className="row">
                                <div className="d-flex flex-row col justify-content-between ">
                                    <label className="form-label">Password</label>
                                    <div className="col-auto">
                                        <Link to={RESET_PASSWORD} className="form-text small text-muted">
                                            Forgot password?
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="input-group">
                                <input
                                    type={isPasswordVisible ? "text" : "password"}
                                    {...register("password", { required: "Password is required" })}
                                    className={`form-control ${errors.password ? "is-invalid" : ""}`}
                                    placeholder="Enter your password"
                                />
                                <div
                                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                    role="button"
                                    className={"input-group-append"}>
                                    <span className="input-group-text">
                                        <i className="fe fe-eye eye-icon"></i>
                                    </span>
                                </div>
                            </div>

                            {errors.password && (
                                <small className="text-danger" role="alert">
                                    {errors.password?.message}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col d-grid">
                            <button disabled={isLoading} type="submit" className="btn btn-primary btn-lg">
                                Sign in
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="d-flex flex-row col justify-content-center">
                            <small className="text-muted">
                                Don't have an account yet?{" "}
                                <Link to="/onboarding/signup">Sign up</Link>
                            </small>
                        </div>
                    </div>
                </form>
            </div>
        </div>
  );
}
