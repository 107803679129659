import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import NetIncomeBreakdownCard from "./NetIncomeBreakdownCard";
import {
  getHistoricalCompoundGrowthRate,
  getHistoricalGrowthRate,
  getHistoricalProfitAndLoss,
  getQboPnLReport,
} from "../FinanceDashboard.api";
import {
  CompoundGrowthRatePeriod,
  GrowthRatePeriod,
  ProfitAndLossPeriod,
  TimeRange,
} from "../financeDashboard.type";
import ProfitAndLossChartCard from "./ProfitAndLossChartCard";
import ProfitAndLossSparkChartsCardRow from "./ProfitAndLossSparkChartsCardRow";
import BlurWrapper from "../../fondo-components/BlurWrapper";
import { getVerifyTransactionsOverlay } from "../verify-transactions/VerifyTransactionsOverlay";
import { ConditionalWrapper } from "../../../utils/common.util";
import LoadingNetIncomeBreakdownCard from "./loading-state-components/LoadingNetIncomeBreakdownCard";
import LoadingProfitAndLossSparkChartsCardRow from "./loading-state-components/LoadingProfitAndLossSparkChartsCardRow";
import LoadingProfitAndLossChartCard from "./loading-state-components/LoadingProfitAndLossChartCard";
import { CompanyLevelSettingsContext } from "../../../context/FeatureFlagsContext";
import { FinanceDashboardContext } from "../FinanceDashboardContext";
import { QBOReportingData } from "../../reporting/Reporting.type";
import { createHistoricalProfitAndLossFromQboData, getNetIncomeFromQboData } from "../../reporting/utils";
import QboNetIncomeBreakdownCard from "./QboNetIncomeBreakdownCard";

type ProfitAndLossProps = {
    selectedMonth: string;
    selectedTimeRange: TimeRange;
    hasTransactionsToVerify: boolean;
}

const ProfitAndLoss = ({ selectedMonth, selectedTimeRange, hasTransactionsToVerify }: ProfitAndLossProps) => {
  const companyLevelSettings = useContext(CompanyLevelSettingsContext);
  const { useQboSource } = useContext(FinanceDashboardContext);
  const { interval } = selectedTimeRange;

  const qboPnLReportQuery = useQuery<QBOReportingData, Error>(
    ["qboBalanceSheet", selectedMonth],
    () => getQboPnLReport(selectedMonth, interval),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedMonth && useQboSource,
    },
  );

  const {
    data: historicalProfitAndLoss,
    isLoading: historicalProfitAndLossIsLoading,
  } = useQuery<ProfitAndLossPeriod[], Error>(
    ["historicalProfitAndLoss", selectedMonth, interval],
    () => getHistoricalProfitAndLoss(selectedMonth, interval),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedMonth && !useQboSource,
    },
  );

  const { data: historicalGrowthRate } = useQuery<GrowthRatePeriod[], Error>(
    ["historicalGrowthRate", selectedMonth, interval],
    () => getHistoricalGrowthRate(selectedMonth, interval),
    {
      enabled: companyLevelSettings.showGrowthRateCardOnProfitAndLossPage && !!selectedMonth,
      refetchOnWindowFocus: false,
    },
  );

  const { data: historicalCompoundGrowthRate } = useQuery<CompoundGrowthRatePeriod[], Error>(
    ["historicalCompoundGrowthRate", selectedMonth, interval],
    () => getHistoricalCompoundGrowthRate(selectedMonth, interval),
    {
      enabled: companyLevelSettings.showCompoundGrowthRateCardOnProfitAndLossPage && !!selectedMonth,
      refetchOnWindowFocus: false,
    },
  );

  const isLoading = (): boolean => {
    if (useQboSource) {
      return qboPnLReportQuery.isLoading;
    }
    return historicalProfitAndLossIsLoading;
  }

  const hasPnLData = useQboSource ? !!qboPnLReportQuery.data : !!historicalProfitAndLoss
  const pnlData: ProfitAndLossPeriod[] | undefined = (useQboSource && qboPnLReportQuery.data)
    ? createHistoricalProfitAndLossFromQboData(qboPnLReportQuery.data  as QBOReportingData)
    : historicalProfitAndLoss;

  return (
    <div className="mt-4">
        <>
          <ConditionalWrapper
            condition={hasTransactionsToVerify && !useQboSource}
            wrapper={(children: React.ReactNode) => (
              <BlurWrapper overlayNode={getVerifyTransactionsOverlay()}>
                {children}
              </BlurWrapper>
            )}
          >
            {isLoading() ? (
              <LoadingNetIncomeBreakdownCard />
            ) : (
              <>
                {historicalProfitAndLoss && !useQboSource && (
                  <NetIncomeBreakdownCard
                    historicalProfitAndLoss={historicalProfitAndLoss}
                    selectedTimeRange={selectedTimeRange}
                  />
                )}
                {useQboSource && qboPnLReportQuery.data && (
                  <QboNetIncomeBreakdownCard
                    historicalProfitAndLoss={qboPnLReportQuery.data as QBOReportingData}
                  />
                )}
              </>
            )}
          </ConditionalWrapper>

          <ConditionalWrapper
            condition={hasTransactionsToVerify}
            wrapper={(children: React.ReactNode) => (
              <BlurWrapper overlayNode={getVerifyTransactionsOverlay()}>
                {children}
              </BlurWrapper>
            )}
          >
            {isLoading() ? (
              <LoadingProfitAndLossSparkChartsCardRow
                showGrowthRate={companyLevelSettings.showGrowthRateCardOnProfitAndLossPage}
                showCompoundGrowthRate={companyLevelSettings.showCompoundGrowthRateCardOnProfitAndLossPage}
              />
            ) : (
              <>
                {pnlData && (
                  <ProfitAndLossSparkChartsCardRow
                    historicalProfitAndLoss={pnlData}
                    historicalGrowthRate={historicalGrowthRate}
                    historicalCompoundGrowthRate={historicalCompoundGrowthRate}
                    qboNetIncome={
                      useQboSource && qboPnLReportQuery.data ?
                      getNetIncomeFromQboData(qboPnLReportQuery.data) :
                      undefined
                    }
                    showGrowthRate={companyLevelSettings.showGrowthRateCardOnProfitAndLossPage && !useQboSource}
                    showCompoundGrowthRate={
                      companyLevelSettings.showCompoundGrowthRateCardOnProfitAndLossPage && !useQboSource
                    }
                  />
                )}
              </>
            )}
          </ConditionalWrapper>

          <ConditionalWrapper
            condition={hasTransactionsToVerify && !useQboSource}
            wrapper={(children: React.ReactNode) => (
              <BlurWrapper overlayNode={getVerifyTransactionsOverlay()}>
                {children}
              </BlurWrapper>
            )}
          >
            {isLoading() ? (
              <LoadingProfitAndLossChartCard />
            ) : (
              <>
                {hasPnLData && pnlData && (
                  <ProfitAndLossChartCard historicalProfitAndLoss={pnlData} />
                )}
              </>
            )}
          </ConditionalWrapper>
        </>
    </div>
  );
};

export default ProfitAndLoss;
