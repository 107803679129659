import { useQuery } from "@tanstack/react-query";
import { Card, Spinner } from "react-bootstrap";
import FondoLineChart from "../fondo-components/Charts/FondoLineChart";
import { getRunwayChartData, getRunwayLabels } from "./utils";
import { getHistoricalMonthlyBalances } from "../finance-dashboard/FinanceDashboard.api";
import { convertNumberToCurrency } from "../../utils/utils";
import { CashBalanceOverTimeResponse } from "../finance-dashboard/financeDashboard.type";

type CashBalanceOverTimeCardProps = {
  disabledState?: boolean;
  endMonth: string;
  interval: string;
};

const CashBalanceOverTimeCard = ({ disabledState = false, endMonth, interval }: CashBalanceOverTimeCardProps) => {
  const {
    data: historicalMonthlyBalances,
    isLoading: historicalMonthlyBalancesIsLoading,
  } = useQuery<CashBalanceOverTimeResponse, Error>(
    ["historicalMonthlyBalances", endMonth, interval],
    () => getHistoricalMonthlyBalances(endMonth, interval),
    {
      refetchOnWindowFocus: false,
      enabled: !!endMonth,
    },
  );

  if (disabledState) {
    return (
      <Card>
        <div className="ps-4 pt-4">
          <Card.Title className="h5 text-uppercase text-muted mb-2">
            Cash Balance
          </Card.Title>
          <span className="text-muted mb-0">
            No bank connected
          </span>
        </div>
        <Card.Body>
          <FondoLineChart
            minYAxisValue={0}
            maxYAxisValue={1000}
            labels={getRunwayLabels(interval, endMonth)}
            chartData={[]}
            noDataState={true}
          />
        </Card.Body>
      </Card>
    );
  }

  if (historicalMonthlyBalancesIsLoading) {
    return (
      <Card>
        <div className="ps-4 pt-4">
          <Card.Title className="h5 text-uppercase text-muted mb-2">
            Cash Balance
          </Card.Title>
          <span className="h1 mb-0">
            <Spinner variant="primary" animation="border" role="status" />
          </span>
        </div>
        <Card.Body>
          <FondoLineChart
            minYAxisValue={0}
            maxYAxisValue={1400000}
            labels={getRunwayLabels(interval, endMonth)}
            chartData={[]}
            noDataState={true}
          />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <div className="ps-4 pt-4">
        <Card.Title className="h5 text-uppercase text-muted mb-2">
          Cash Balance
        </Card.Title>
        <span className="h1 mb-0">
          ${convertNumberToCurrency(historicalMonthlyBalances?.cashBalance || 0)}
        </span>
      </div>
      <Card.Body>
        {historicalMonthlyBalances && (
          <FondoLineChart
            minYAxisValue={0}
            labels={getRunwayLabels(interval, endMonth)}
            chartData={getRunwayChartData(historicalMonthlyBalances, interval, endMonth, true) || []}
            stacked
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default CashBalanceOverTimeCard;
