import { useQuery } from "@tanstack/react-query";
import { Button, Card, Modal, Spinner } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Review } from "../passport-partners.types";
import { PartnerCardTopBar } from "../PartnerCardTopBar";
import { FiveStars } from "../../fondo-components/FiveStars";
import { getReviews } from "../passport-partners.api";

type ReviewContentProps = {
  partnerId: string;
  showAllReviews: boolean;
  setShowAllReviews: (showAllReviews: boolean) => void;
};

const getReviewCard = (review: Review, dropdownItems?: JSX.Element[] | JSX.Element) => (
  <Card className="w-100" key={`review-card-${review.uuid}`}>
    <PartnerCardTopBar dropdownItems={dropdownItems} />
    <Card.Body>
      <Card.Title as="h4">{(!review.anonymous && review.userName) || "Anonymous"}</Card.Title>
      <FiveStars stars={review.score} className="mb-2" />
      <Card.Text className="text-secondary">{review.text}</Card.Text>
    </Card.Body>
  </Card>
);

export const ReviewContent = ({ partnerId, showAllReviews, setShowAllReviews }: ReviewContentProps) => {
  // add pagination/infinite query later
  const reviewsQuery = useQuery(["passportPartnersPartnerReviews", partnerId], () => getReviews(partnerId), {
    refetchOnWindowFocus: false,
  });

  if (!showAllReviews) {
    const noReviewsCardBody = reviewsQuery.isLoading ? (
      <Spinner variant="primary" animation="border" />
    ) : (
      <div>No reviews available at the moment.</div>
    );
    const cardButton = (
      <Button
        variant="link"
        size="sm"
        onClick={() => {
          setShowAllReviews(true);
        }}
      >
        View All
      </Button>
    );
    const review = reviewsQuery.data?.results?.[0];
    return review ? (
      getReviewCard(review, cardButton)
    ) : (
      <Card className="w-100">
        <Card.Body className="text-center">{noReviewsCardBody}</Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title as="h3">Reviews</Modal.Title>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => {
            setShowAllReviews(false);
          }}
        >
          <FeatherIcon icon="chevron-left" size={18} className="mb-1" />
          Back
        </Button>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "80vh", overflowY: "scroll" }}>
        {reviewsQuery.isLoading || !reviewsQuery.data || !reviewsQuery.data.results.length ? (
          <div>No reviews available at the moment.</div>
        ) : (
          reviewsQuery.data.results.map((review) => getReviewCard(review))
        )}
      </Modal.Body>
    </>
  );
};
