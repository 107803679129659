import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

type CircleProgressProps = {
  value: number;
  centerText?: string;
  showValue?: boolean;
  notStarted?: boolean;
}

const CircleProgress = ({
  value, showValue = true, notStarted = false, centerText,
}: CircleProgressProps) => {
  let centerValue = centerText;
  if (centerValue === undefined && showValue) {
    centerValue = `${value}%`;
  }

  return (
    <CircularProgressbar
      value={value}
      text={centerValue}
      background
      backgroundPadding={6}
      styles={buildStyles({
        backgroundColor: notStarted ? "#99A9C6" : "#2968ED",
        textColor: "#fff",
        pathColor: "#fff",
        trailColor: notStarted ? "#B8C3D7" : "#5b8af1",
        textSize: "40px",
      })}
    />
  );
};

export default CircleProgress;
