import { useQuery } from "@tanstack/react-query";
import { Card, Spinner } from "react-bootstrap";
import { getRunwayLabels, runwayReferencesColors } from "./utils";
import FondoBarChart from "../fondo-components/Charts/FondoBarChart";
import { getHistoricalBurnRate } from "../finance-dashboard/FinanceDashboard.api";
import { convertNumberToCurrency } from "../../utils/utils";

type BurnRateCardProps = {
  disabledState?: boolean;
  endMonth: string;
  interval: string;
};

const BurnRateCard = ({ disabledState = false, endMonth, interval }: BurnRateCardProps) => {
  const {
    data: burnRate,
    isLoading: burnRateIsLoading,
  } = useQuery<any, Error>(
    ["burnRate", endMonth, interval],
    () => getHistoricalBurnRate(endMonth, interval),
    {
      refetchOnWindowFocus: false,
      enabled: !!endMonth,
    },
  );

  if (disabledState) {
    return (
      <Card className="mb-0">
        <div className="ps-4 pt-4">
          <Card.Title className="h5 text-uppercase text-muted mb-2">
            Cash Balance
          </Card.Title>
          <span className="text-muted mb-0">
            No bank connected
          </span>
        </div>
        <Card.Body>
          <FondoBarChart
            labels={getRunwayLabels(interval, endMonth)}
            chartData={[]}
            noDataState={true}
            reverse={true}
          />
        </Card.Body>
      </Card>
    );
  }

  if (burnRateIsLoading) {
    return (
      <Card>
        <div className="ps-4 pt-4">
          <Card.Title className="h5 text-uppercase text-muted mb-2">
            Cash Balance
          </Card.Title>
          <span className="h1 mb-0">
            <Spinner variant="primary" animation="border" role="status" />
          </span>
        </div>
        <Card.Body>
          <FondoBarChart
            labels={getRunwayLabels(interval, endMonth)}
            chartData={[]}
            noDataState={true}
            reverse={true}
          />
        </Card.Body>
      </Card>
    );
  }

  const processBurnRateData = () => (
    [{
      data: burnRate.map((dataPoint: any) => dataPoint.burnRate),
      color: runwayReferencesColors[0],
    }]
  );

  return (
    <Card className="mb-0">
      <div className="ps-4 pt-4">
        <Card.Title className="h5 text-uppercase text-muted mb-2">
          Burn Rate
        </Card.Title>
        {burnRate.length > 0 ? (
          <span className={`h1 mb-0 ${burnRate[burnRate.length - 1].burnRate >= 0 ? "text-success" : "text-danger"}`}>
            ${convertNumberToCurrency(burnRate[burnRate.length - 1].burnRate)}
          </span>
        ) : (
          <span className="h1 mb-0 text-success">
            $0
          </span>
        )}
      </div>
      <Card.Body>
        {burnRate && (
          <FondoBarChart
            labels={burnRate.map((dataPoint: any) => dataPoint.period)}
            chartData={processBurnRateData() || []}
            useAmountSemanticColors
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default BurnRateCard;
