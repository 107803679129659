import { useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import CircleReferenceColor from "../fondo-components/CircleReferenceColor";
import {
  BalanceAccount,
  BalanceAccountChartData,
  EndingBalanceUpdated,
} from "../finance-dashboard/financeDashboard.type";
import { formatDateToWords, removeCommas } from "../../utils/common.util";
import { InstitutionSource } from "../integrations/integrations.type";
import FondoCurrencyField from "../forms/formik-components/FondoCurrencyField";
import { convertNumberToCurrency } from "../../utils/utils";

type RunwayAuditToolTableRowProps = {
  balance: BalanceAccountChartData,
  handleRowUpdate: (updatedRowData: EndingBalanceUpdated) => any;
  handleRemoveNewBankAccount: (newBankAccount: BalanceAccount) => void;
}

function RunwayAuditToolTableRow({ balance, handleRowUpdate }: RunwayAuditToolTableRowProps) {
  const [editingValue, setEditingValue] = useState(false);
  const [manualBalance, setManualBalance] = useState(removeCommas(balance.balance));

  useEffect(() => {
    setManualBalance(removeCommas(balance.balance));
  }, [balance]);

  const handleManualBalanceSave = () => {
    handleRowUpdate({
      uuid: balance.uuid,
      manuallyEditedBalance: manualBalance,
      accountName: balance.account.name,
      institutionName: balance.account.bankName,
    });
    setEditingValue(false);
  };

  const getBalanceActionsButtons = () => {
    if (editingValue) {
      return (
        <>
          <Button
            onClick={() => setEditingValue(false)}
            variant="outline-danger"
            size="sm"
            className="ms-2 py-0 px-2"
          >
            <FeatherIcon icon="x" size="0.9em" />
          </Button>
          <Button
            onClick={() => handleManualBalanceSave()}
            variant="outline-success"
            size="sm"
            className="ms-2 py-0 px-2"
          >
            <FeatherIcon icon="check" size="0.9em" />
          </Button>
        </>
      );
    }
    return (
      <Button
        onClick={() => setEditingValue(true)}
        variant="outline-primary"
        size="sm"
        className="ms-2 py-0 px-2 mt-n1"
      >
        <FeatherIcon icon="edit-2" size="0.9em" />
      </Button>
    );
  };

  return (
    <tr>
      <td>
        <CircleReferenceColor
          color={balance.referenceColor}
          text={balance.account.name + (balance.account.mask ? ` - ${balance.account.mask}` : "")}
        />
      </td>
      <td className="text-start">
        {balance.account.bankName}
        {balance.account.source === InstitutionSource.MANUAL && (
          <Badge
            bg="light"
            className="text-secondary ms-2"
          >
            Manually Added
          </Badge>
        )}
      </td>
      <td>{balance.account.type}</td>
      <td>{formatDateToWords(balance.date)}</td>
      <td className="d-flex justify-content-between">
        {editingValue ? (
          <div className="d-flex">
            <FondoCurrencyField
              value={manualBalance}
              onChange={(e) => setManualBalance(removeCommas(e.target.value))}
              size="sm"
              className="mb-0"
              isInvalid={!manualBalance && manualBalance !== "0" }
              errorMessage={"Value must not be empty"}
              allowNegative={true}
            />
            {getBalanceActionsButtons()}
          </div>
        ) : (
          <>
            {convertNumberToCurrency(balance.balance || 0)}{getBalanceActionsButtons()}
          </>
        )}
      </td>
    </tr>
  );
}

export default RunwayAuditToolTableRow;
