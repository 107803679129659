import { Field, FormikContextType } from "formik";
import { Row } from "react-bootstrap";

type DateInYearFieldProps = {
  formikContext: FormikContextType<any>;
  fieldName: string;
  value: string;
  invalid: boolean;
}

const DateInYearField = ({
  formikContext,
  fieldName,
  value,
  invalid,
}: DateInYearFieldProps) => {
  const monthValue = value ? value.slice(0, 2) : "";
  const dayValue = value ? value.slice(2) : "";

  const renderDropdownList = (list: any[]) => (
    list.map((element) => (
      <option key={element.value} value={element.value}>
        {element.label || element.value}
      </option>
    ))
  );

  const monthsOfYear = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const monthToDaysMap: any = {
    "01": 31,
    "02": 29,
    "03": 31,
    "04": 30,
    "05": 31,
    "06": 30,
    "07": 31,
    "08": 31,
    "09": 30,
    10: 31,
    11: 30,
    12: 31,
  };

  const daysOfMonthArray = () => {
    const numOfDays = monthToDaysMap[monthValue] || 31;
    return Array.from({ length: numOfDays }, (_, i) => {
      if ((i + 1) < 10) {
        return { value: `0${(i + 1).toString()}` };
      }
      return { value: (i + 1).toString() };
    });
  };

  const handleMonthChange = (newMonthValue: string) => {
    const newDayValue = newMonthValue ? dayValue : "";
    const newValue = `${newMonthValue}${newDayValue}`;
    formikContext.setFieldValue(fieldName, newValue);
  };

  const handleDayChange = (newDayValue: string) => {
    const newValue = `${monthValue || "00"}${newDayValue}`;
    formikContext.setFieldValue(fieldName, newValue);
  };

  return (
    <Row>
      <div className="col-12 col-md-6 col-sm-12">
        <Field
          name={fieldName}
          as="select"
          className={`form-control form-select ${invalid ? "is-invalid" : ""}`}
          value={monthValue}
          onChange={(e: any) => handleMonthChange(e.target.value)}>
          <option key="emptyValue" value={""}>--------</option>
          {renderDropdownList(monthsOfYear)}
          isInvalid={invalid}
        </Field>
      </div>
      <div className="col-12 col-md-6 col-sm-12">
        <Field
          name={fieldName}
          as="select"
          className={`form-control form-select ${invalid ? "is-invalid" : ""}`}
          value={dayValue}
          onChange={(e: any) => handleDayChange(e.target.value)}>
          <option key="emptyValue" value={""}>--------</option>
          {renderDropdownList(daysOfMonthArray())}
          isInvalid={invalid}
        </Field>
      </div>
    </Row>
  );
};

export default DateInYearField;
