import { getFondoApi } from "../../utils/util.api";

type GetUserViewedResponse = {
  viewed: boolean;
};

export async function getUserViewed(key: string): Promise<GetUserViewedResponse> {
  const result: any = await getFondoApi("user-viewed/", { key });
  return result as GetUserViewedResponse;
}
