import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { AxiosError } from "axios";
import FeatherIcon from "feather-icons-react";
import {
  Button,
  Card,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FondoLayout from "../fusion-kit/FondoLayout";
import { addForeignSubsidiary, getForeignSubsidiariesInfo, markForeignSubYearComplete } from "./foreignSubsidiary.api";
import { ForeignSubsidiaryInfo } from "./foreignSubsidiary.type";
import ForeignSubsidiariesInfoGrid from "./ForeignSubsidiariesInfoGrid";
import SuccessfulSubmissionImg from "../imgs/rnd-submission-successful.png";

const ForeignSubsidiary = () => {
  const { taxYear } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    data: foreignSubsidiariesInfo,
    isLoading: foreignSubsidiariesInfoIsLoading,
  } = useQuery<ForeignSubsidiaryInfo[], Error>(
    ["foreignSubsidiariesInfo", taxYear],
    () => getForeignSubsidiariesInfo(taxYear || ""),
    { enabled: !!taxYear },
  );

  const cannotSubmitYear = () => foreignSubsidiariesInfo?.some((foreignSub) => foreignSub.complete === false);
  const [showSubmittedModal, setShowSubmittedModal] = useState(false);

  const addForeignSubsidiaryMutation = useMutation<any, AxiosError<any>>(
    () => addForeignSubsidiary(taxYear || ""),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: ["foreignSubsidiariesInfo"],
        });
        navigate(`/foreign_subsidiary/${taxYear}/${data.uuid}`);
      },
    },
  );

  const submitYearMutation = useMutation(
    () => markForeignSubYearComplete(taxYear || ""),
    {
      onSuccess: () => {
        setShowSubmittedModal(true);
      },
    },
  );

  const handleModalClose = () => {
    setShowSubmittedModal(false);
    queryClient.invalidateQueries({
      queryKey: ["sidenavInfo"],
    });
    navigate("/dashboard");
  };

  return (
    <FondoLayout title={`${taxYear} Foreign Subsidiaries Tax Information`}>
      <Card>
        <Card.Body>
          <p>
            Please click on the
            <FeatherIcon
              icon="edit-2"
              size="17"
              className="mt-n1 mx-2 text-secondary"
            />
            button below to complete your foreign subsidiary onboarding.
            This information is important for filing your tax return.
            If this information is not provided on your return you may receive a $10,000 penalty
            from the IRS. Once you are finished with all foreign subsidiaries for this year,
            please click on the "Complete" button.
          </p>
          {foreignSubsidiariesInfoIsLoading && (
            <div className="row justify-content-center mt-5">
              <Spinner variant="primary" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          {foreignSubsidiariesInfo && (
           <ForeignSubsidiariesInfoGrid foreignSubsidiariesInfo={foreignSubsidiariesInfo} />
          )}
          <Button
            onClick={() => addForeignSubsidiaryMutation.mutate()}
            size="sm"
          >
            Add another
          </Button>
          <div className="mt-5 text-center">
            <Button
              disabled={cannotSubmitYear()}
              onClick = {() => submitYearMutation.mutate()}
            >Complete</Button>
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={showSubmittedModal}
        onHide={() => setShowSubmittedModal(false)}
      >
        <Modal.Body style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <img
            src={SuccessfulSubmissionImg}
            className="img-fluid" alt="Submission Successful"
            style={{ width: "50%" }}
          />
          <h3 className="mt-4 mb-3 text-center">Thank you!</h3>
          <h5 className="text-center text-secondary">
            If we need anything else, we will reach out.
          </h5>
          <Button className="mt-3 mb-3" size="sm" onClick={handleModalClose}>Go to Dashboard</Button>
        </Modal.Body>
      </Modal>
    </FondoLayout>
  );
};

export default ForeignSubsidiary;
