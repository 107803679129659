import { useState } from "react";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Dropdown } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import CloseAccountModal from "./CloseAccountModal";
import { AccountStatus } from "./integrations.type";
import { deleteBankAccount } from "./integrations.api";
import ConfirmationModal from "../fusion-kit/ConfirmationModal";
import FondoToast from "../fusion-kit/FondoToast";

type AccountActionDropdownProps = {
  accountUuid: string;
  accountStatus: AccountStatus;
}

const AccountActionDropdown = ({
  accountUuid,
  accountStatus,
}: AccountActionDropdownProps) => {
  const queryClient = useQueryClient();
  const [showCloseAccountModal, setShowCloseAccountModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const deleteAccountMutation = useMutation<any, AxiosError<any>>(
    () => deleteBankAccount(accountUuid),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["itemAccounts"]);
        setShowConfirmationModal(false);
      },
      onError: () => {
        setShowConfirmationModal(false);
        setShowErrorToast(true);
      },
    },
  );

  return (
    <>
      <Dropdown align='start' className='align-middle' data-bs-boundary="viewport">
        <Dropdown.Toggle
          as='span'
          className='dropdown-ellipses'
          role='button'
        >
          <FeatherIcon
            icon='more-vertical'
            size='17'
            className='mx-3 text-muted'
          />
        </Dropdown.Toggle>
        <Dropdown.Menu >
          <Dropdown.Item
            onClick={() => setShowConfirmationModal(true)}
          >
            Delete
          </Dropdown.Item>
          {accountStatus === AccountStatus.ACTIVE
            && (
              <Dropdown.Item
                onClick={() => setShowCloseAccountModal(true)}
              >
                Mark as closed
              </Dropdown.Item>
            )
          }
        </Dropdown.Menu>
      </Dropdown>
      <CloseAccountModal
        show={showCloseAccountModal}
        handleClose={() => setShowCloseAccountModal(false)}
        accountUuid={accountUuid}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleConfirm={() => deleteAccountMutation.mutate()}
        bodyText={"Please confirm you want to remove this bank account."}
      />
      <FondoToast
        show={showErrorToast}
        handleClose={() => setShowErrorToast(false)}
        message={"Error removing bank account"}
        error={true}
      />
    </>
  );
};

export default AccountActionDropdown;
