import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { ChartData } from "./types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
);

type FondoSparkLineChartProps = {
  minYAxisValue?: number;
  maxYAxisValue?: number;
  chartData: ChartData[];
  noDataState?: boolean;
  stacked?: boolean;
  customWidth?: string;
}

const FondoSparkLineChart = ({
  chartData,
  noDataState = false,
  customWidth,
}: FondoSparkLineChartProps) => {
  const options = {
    responsive: true,
    maintainAspectRatio: true,

    elements: {
      line: {
        tension: 0.4,
        borderWidth: 3,
      },
      point: {
        radius: 0,
      },
    },

    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const datasets = chartData.map((object: any) => {
    const newObject = { ...object };
    newObject.borderColor = object.color;
    return newObject;
  });

  const data = {
    labels: new Array(datasets[0].data.length).fill("Label"),
    datasets,
  };

  return (
    <>
      {noDataState ? (
        <Line options={options} data={ { datasets: [] } } width={customWidth || "100px"} height="75px" />
      ) : (
        <Line options={options} data={data} width={customWidth || "100px"} height="75px" />
      )}
    </>
  );
};

export default FondoSparkLineChart;
