import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { ReactNode } from "react";

type PassportDragSortContextProps = {
    children: ReactNode,
    sortableIds: string[],
    handleDragEnd: (e: DragEndEvent) => void,
};

export const PassportDragSortContext = ({ children, sortableIds, handleDragEnd }: PassportDragSortContextProps) => (
    <DndContext
        modifiers={[restrictToVerticalAxis]}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}>
        <SortableContext
            items={sortableIds}
            strategy={verticalListSortingStrategy}>
            {children}
        </SortableContext>
    </DndContext>
);
