import { useLocation } from "react-router";
import { ACH_SETTINGS, SETTINGS } from "../../constants/network-calls";
import UserSettings from "./UserSettings";
import CompanyPaymentInfoSettings from "./CompanyPaymentInfoSettings";
import FondoLayout from "../fusion-kit/FondoLayout";

export default function Settings() {
  const location = useLocation();
  const settingsPageTabs = [
    {
      name: "General",
      url: SETTINGS,
    },
    {
      name: "ACH",
      url: ACH_SETTINGS,
    },
  ];

  return <FondoLayout
        title="Settings"
        tabs={settingsPageTabs}
    >
        {location.pathname === SETTINGS && <UserSettings />}
        {location.pathname === ACH_SETTINGS && <CompanyPaymentInfoSettings />}
    </FondoLayout>;
}
