import React from "react";
import { Form } from "react-bootstrap";
import { sectionOptionDescriptions, sectionOptionNames } from "../utils";
import { DescriptionType } from "../Reporting.type";

type ReportSectionFormProps = {
  options: { [key: string]: DescriptionType | boolean };
  handleChange: (key: string) => void;
  setOptions: React.Dispatch<React.SetStateAction<object>>;
};

const ReportSectionForm = ({ options, handleChange, setOptions }: ReportSectionFormProps) => {
  const optionKeys = Object.keys(options);

  return (
    <>
      {optionKeys.map((key) => {
        const description = sectionOptionDescriptions[key as keyof typeof sectionOptionDescriptions];
        const name = sectionOptionNames[key as keyof typeof sectionOptionNames];
        if (typeof options[key] === "boolean") {
          return (
            <div key={key} className="mt-3 pt-3 border-top">
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-grow-1">
                  <Form.Label>{name}</Form.Label>
                  <p className="text-muted" style={{ fontSize: "0.8rem" }}>{description}</p>
                </div>
                <Form.Check
                  type="switch"
                  aria-label={name}
                  checked={options[key] as boolean}
                  onChange={() => handleChange(key)}
                  id={`${key}-switch`}
                />
              </div>
            </div>
          );
        } 
        return (
          <div key={key}>
            <div className="d-flex justify-content-between align-items-center">
              <Form.Label>{name}</Form.Label>
              <Form.Check
                type="switch"
                aria-label={name}
                checked={!!(options[key] && (options[key] as DescriptionType).enabled)}
                onChange={() =>
                  setOptions({
                    ...options,
                    [key]: { 
                      ...(options[key] as DescriptionType), 
                      enabled: !(options[key] as DescriptionType)?.enabled 
                    },
                  })
                }
                id={`${key}-switch`}
              />
            </div>
            <Form.Control
              as="textarea"
              rows={3}
              value={(options[key] as DescriptionType).text as string}
              onChange={(e) =>
                setOptions({ ...options, [key]: {...(options[key] as DescriptionType), text: e.target.value} })
              }
            />
          </div>
        );
      })}
    </>
  );
  
};

export default ReportSectionForm;
