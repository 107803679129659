import { ONBOARDING_TAX_PLAN } from "../../../constants/network-calls";
import { OnboardingTaxPlanInfo } from "./taxPlan.type";
import { getFondoApi, postFondoApi } from "../../../utils/util.api";

export async function getOnboardingTaxPlanInfo(): Promise<OnboardingTaxPlanInfo> {
  const res = await getFondoApi(ONBOARDING_TAX_PLAN);
  return res as OnboardingTaxPlanInfo;
}

export async function saveTaxPlanOnboardingInformation(data: any): Promise<string> {
  const result: any = await postFondoApi(ONBOARDING_TAX_PLAN, data);
  return result;
}
